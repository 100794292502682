import { mutationDeleteAssociatedJob } from "../../../graphql/mutations";
import _ from 'lodash';

export const handleDeleteSingleAssociatedJob = async(jobId) =>{
  const DeleteJob_Input ={
    jobid: jobId
  }
  const jobDeleted = await mutationDeleteAssociatedJob(DeleteJob_Input);

  if (jobDeleted) {
    return "done";
  }
  else{
    return "error";
  }
}

export const handleGetRemainingJobsAfterJobDelete = (remainingJobs, groups, items) =>{
    let itemsCopy = [...items];
    const currentJobsInSlot = items.filter(item=>item.resource_slotid === remainingJobs[0].resource_slotid);
    const jobToRemove = _.differenceBy(currentJobsInSlot,remainingJobs, "jobid");
    const finalItems = _.remove(itemsCopy, function(item) {
      return jobToRemove[0].jobid === item.jobid
    });
  
    return itemsCopy;
}