
const MESSAGES_CHAT = [
  ["x", "messages"],
  [0, 0],
  [1, 100],
  [2, 500],
  [3, 540],
  [4, 470],
  [5, 500],
  [6, 888],
  [7, 732],
  [8, 812],
  [9, 319],
  [10, 1000],
  [11, 359],
]

const USERS_BY_ROLE = [
  ['day', 'a', 'b', 'c', 'd'],
  ['Mon', 20, 28, 38, 45],
  ['Tue', 31, 38, 55, 66],
  ['Wed', 50, 55, 77, 80],
  ['Thu', 77, 77, 66, 50],
  ['Fri', 68, 66, 22, 15],
]

const USERS_BY_DEPARTMENT = [
  [
    "Element",
    "Density",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Transporter", 125, "#0095FF", null],
  ["EVS Disp.", 10, "#0095FF", null],
  ["EVS Manager", 1, "#0095FF", null],
  ["Admins", 24, "#0095FF", null],
  ["Pharma Tech", 9, "#0095FF", null],
  ["Housekeeper", 10, "#0095FF", null],
];

module.exports={
  MESSAGES_CHAT,
  USERS_BY_ROLE,
  USERS_BY_DEPARTMENT
}
