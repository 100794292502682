const socketPath={
socketChatPath:{
    https:"/chat/socket.io",
    http:"/socket.io"
},
socketLocationPath:{
    cloud:{
        https:"/prod/socket.io",
        http:"/socket.io",
    },
    local:{
        https:"/internal/socket.io",
        http:"/socket.io",
    } 
},
}

export{
    socketPath
}