import React from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";

import { connect } from "react-redux";
import { changeView } from "../../../actions/views/viewsActions";
import {
  directContacts,
  directCounter,
  refreshUser
} from "../../../actions/contactEvent/contactEventActions";
import { selectUser, setNames } from '../../../actions/contacts/contactActions';
import AwesomeSpinner from "../../utilities/spinner";

import PropTypes from "prop-types";
import { dateFormat } from '../contactUtils';
import DirectContacts from '../directContacts/directContacts.component'
import _ from "lodash";
import { EditorFormatColorReset } from "material-ui/svg-icons";
import Autocomplete from './Autocomplete';


const TABLE_SORT = (data, colIndex, order) => {
  if (colIndex === 0 || colIndex === 2 || colIndex === 6) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  }
};

class AllContatcs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: this.props.allContacts,
      showNameTable: false,
      userSelected: {},
      columnsForName: [],
      directContacts: false,
      options: {},
      showSpinner: true
    };
  }
  startLoop = true;
  elements = ["default", "hidden"];
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];
  usersName = [];
  usersNameList = [];
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "635px !important",
          },
          paper: {
            boxShadow: "0 4px 2px -2px gray",
            paddingTop: "1px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: "1%",
          },
          toolButton: {
            display: "flex",
            justifyContent: "center",
            color: "#7D7D7D",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            border: "1px solid #ddd",
            padding: "4px 56px 4px 56px",
            textAlign: "center",
          },
        },
      },
    });

  componentDidMount = () => {
  };

  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  }

  getList = () => {
    const newUsers = this.props.allContacts.map(user => user.userId);
    this.usersNameList = this.props.users.map(user => { return { name: `${user.first_name} ${user.last_name}`, id: user._id } });
    const aux = this.props.users.filter(user => newUsers.includes(user._id));

    this.usersName = aux.map(user => { return { 'first_name': user.first_name, 'last_name': user.last_name } });


    this.usersNameList = aux.map(user => { return { name: `${user.first_name} ${user.last_name}`, id: user._id } });

  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  autoComplete = (users, userSelected, singleNameTable) => {
    return (
      <div className="autocomplete-box">
        <h2 style={{ 'marginRight': '50px' }}>Contact</h2>
        <Autocomplete
          allContacts={users}
          onChange={(event, newValue) => {
            userSelected = newValue;
            const completeNames = users.map(user => `${user.first_name} ${user.last_name}`)
            if (completeNames.includes(userSelected)) {
              singleNameTable(newValue)
            }
          }}
          style={{ 'flex': '1' }}
          renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
        />
      </div>);
  }

  render() {
    let data = [];
    let newData = [];
    this.getList();
    let nameSelected = '';
    this.props.allContacts.map((contact) => {
      this.props.refreshUser(contact.userId);
      if (contact.userId !== 'undefined') {
        data.push({
          id: contact.userId,
          directcontacts: contact.directContacts,
          secondarycontacts: contact.secondaryContacts,
          tertiarycontacts: contact.tertiaryContacts
        });
      }
      this.showTable = true;
      if (this.state.showSpinner)
        this.setState({ showSpinner: false });
    });

    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          customFilterListOptions: { render: v => `ID: ${v}` },
        }
      },
      {
        name: "directcontacts",
        label: "Direct Contacts",
        options: {
          filter: false,
          customFilterListOptions: { render: v => `Direct Contacts: ${v}` }
        }
      },
      {
        name: "secondarycontacts",
        label: "Secondary Contacts",
        options: {
          filter: false,
          customFilterListOptions: { render: v => `Direct Contacts: ${v}` }
        }
      },
      {
        name: "tertiarycontacts",
        label: "Tertiary Contacts",
        options: {
          filter: false,
          customFilterListOptions: { render: v => `Tertiary Contacts: ${v}` }
        }
      },
      {
        name: "lastcontact",
        label: "Last Contact",
        options: {
          filter: false,
          customFilterListOptions: { render: v => `Last Contact: ${v}` }
        }
      }
    ];

    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 30, 50, 100],
      selectableRowsHeader: false,
      selectableRows: "none",
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        return TABLE_SORT(data, colIndex, order);
      },
      toolbar: {
        filterTable: "Filter Table",
      },
    };
    const singleNameTable = (userName) => {
      const columns = [
        {
          name: "id",
          label: "ID",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `ID: ${v}` },
          }
        },
        {
          name: "name",
          label: "Name",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `Last Connection: ${v}` }
          }
        },
        {
          name: "directcontacts",
          label: "Direct Contacts",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `Direct Contacts: ${v}` }
          }
        },
        {
          name: "secondarycontacts",
          label: "Secondary Contacts",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `Direct Contacts: ${v}` }
          }
        },
        {
          name: "tertiarycontacts",
          label: "Tertiary Contacts",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `Tertiary Contacts: ${v}` }
          }
        },
        {
          name: "lastcontact",
          label: "Last Contact",
          options: {
            filter: false,
            customFilterListOptions: { render: v => `Last Connection: ${v}` }
          }
        }
      ]
      const newOptions = {
        ...options,
        onRowClick: (rowData, rowMeta) => {
          this.props.directContacts(rowData[0]);
          this.props.changeView('direct_contact')
        }
      };
      const contact = this.usersNameList.filter(user => user.name == userName)
      const newUser = this.props.allContacts.filter(item => item.userId === contact[0].id)
      this.props.selectUser({ ...newUser[0], name: contact[0].name });
      this.props.setNames(this.usersNameList);
      this.setState({
        showNameTable: true,
        userSelected: {
          id: contact[0].id,
          name: contact[0].name,
          directcontacts: newUser[0].directContacts,
          secondarycontacts: newUser[0].secondaryContacts,
          tertiarycontacts: newUser[0].tertiaryContacts
        },
        columnsForName: [...columns],
        options: { ...newOptions }
      });
    };
    const newLocal = !this.state.showNameTable ? <MUIDataTable title={this.autoComplete(this.usersName, nameSelected, singleNameTable)} data={data} columns={columns} options={options} /> : <MUIDataTable title={this.autoComplete(this.usersName, nameSelected, singleNameTable)} data={[this.state.userSelected]} columns={this.state.columnsForName} options={this.state.options} />;
    let l = this.props.open ? '23%' : '4%';
    let w = this.props.open ? '75.5%' : '95%';
    return (
      <div style={{ marginTop: '5%', marginLeft: l, width: w }}>
        <div
          id="webNavv-contactPanel-contacts-1.0"
          style={{ 'marginTop': '56px' }}
        >
          {this.showTable && (
            <MuiThemeProvider theme={this.getMuiTheme()}>
              {newLocal}
            </MuiThemeProvider>
          )}
          <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open} />
        </div>
      </div>
    );
  }
}


AllContatcs.propTypes = {
  directContactsforAUser: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  directContactsforAUser: state.contactEvent.directContacts,
  userSelected: state.contact.userSelected,
  allContacts: state.contactUserList.usersList,
  nameList: state.contact.names,
  open: state.views.drawerOpen
});

export default connect(mapStateToProps, { directContacts, directCounter, refreshUser, selectUser, setNames, changeView })(withRouter(AllContatcs));
// export default (withRouter(AllContatcs));

