import React, {useState} from "react";
import moment from "moment";

import MasterButton from "../../../utilities/button.component";

import { Timeline, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import Map from "@material-ui/icons/Map";
import { CheckCircle, RadioButtonUnchecked, AccessTime, Error, Flag} from "@material-ui/icons";

import { useStyles, CustomTimelineItem} from "./jobHistory.styles";
import "./jobHistory.css";
import { statusStyles, ROUTE_INFO, JOBNAME, JOBID, WORKER, JOBSTATUS, JOBTYPE, JOBDATE, JOBSTARTDATE, JOBENDDATE, DURATION, SPECIMEN_RETRIEVAL, ERROR_TYPE, ERROR_DETAILS, START_POINT, ERROR_TASKS } from "./jobHistory.constants";
import { taskStatusTypesGraphQL, STATUS_STYLES } from "../../../workflowAutomation/jobsBoard/jobsBoard.constants";
import SimpleModalPop from "../../../utilities/modalSimple.component";
import { getFloorColorByOrdinal } from "../../../map/floorColors";


const JobHistory = (props) =>{
    const classes = useStyles();
    const {jobInfo} = props;
    const [openModal, setOpenModal] = useState(false);
    const [errorDescription, setErrorDescription] = useState(null);

    const handleCloseModal = () =>{
        setOpenModal(false);
    }

    const handleErrorDetails = (step) =>{
        const description = handleErrorDescription(step.job_error_number);
        setErrorDescription(description);
        setOpenModal(true);
    }

    const handleErrorDescription = (errorNumber) =>{
        switch (errorNumber) {
            case ERROR_TASKS.scanOverride.key:
                return ERROR_TASKS.scanOverride.description;
            case ERROR_TASKS.locationSkipped.key:
                return ERROR_TASKS.locationSkipped.description;
            case ERROR_TASKS.jobCancelled.key:
                return ERROR_TASKS.jobCancelled.description;
            case ERROR_TASKS.jobStartDelay.key:
                return ERROR_TASKS.jobStartDelay.description;
            case ERROR_TASKS.scanError.key:
                return ERROR_TASKS.scanError.description;
            default:
                return errorNumber
        }
    }

    const handleRenderJobInfo = () =>{
        return(
            <div className="JobHistory-container">
                <div className="JobHistory-nameLabel">{JOBNAME}</div>
                <div className="JobHistory-name">{jobInfo[0].jobName}</div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBID}</div>
                    <div className="JobHistory-text" style={{left:"240px"}}>{jobInfo[0].jobNumber}</div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{WORKER}</div>
                    <div className="JobHistory-text">{jobInfo[0].messenger} </div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBSTATUS}</div>
                    <div className="JobHistory-text">{jobInfo[0].status}</div>
                </div>
                {jobInfo[0].status === taskStatusTypesGraphQL.error && (
                    <div className="JobHistory-row">
                        <div className="JobHistory-label">{ERROR_TYPE}</div>
                        <div className="JobHistory-text">{handleErrorDescription(jobInfo[0].errorNumber)}</div>
                    </div>
                )}
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBTYPE}</div>
                    <div className="JobHistory-text">{SPECIMEN_RETRIEVAL}</div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBDATE}</div>
                    <div className="JobHistory-text">{moment(jobInfo[0].date).format('MMM DD YYYY')}</div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBSTARTDATE}</div>
                    <div className="JobHistory-text">{moment(jobInfo[0].startTime).format("hh:mm A")}</div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{JOBENDDATE}</div>
                    <div className="JobHistory-text">{moment(jobInfo[0].endTime).format("hh:mm A")}</div>
                </div>
                <div className="JobHistory-row">
                    <div className="JobHistory-label">{DURATION}</div>
                    <div className="JobHistory-text">{jobInfo[0].duration.humanize()}</div>
                </div>
            </div>
        )
    }

    const handleRenderFloorLevel = (step) =>{
        const { mapConfigs } = props;

        const currentFloor = !!mapConfigs.floors ? mapConfigs.floors.find(floor=>floor.label === step.floor_name) : undefined;
        const style = !!currentFloor? {backgroundColor: getFloorColorByOrdinal(currentFloor.ordinal), color: "white"} : {backgroundColor: 'lightgray'};
        const floorClass = !!step.floor_name ? classes.JobHistory_stepperFloor : classes.JobHistory_stepperFloorNA;
        const floorLabel = !!currentFloor ? `L-${currentFloor.label}` : 'NA';
        return(
            <React.Fragment>
                <Typography  
                  style={style}
                  className={floorClass}>
                    {floorLabel}
                </Typography>
            </React.Fragment>
        )
    }

    const handleRenderTaskIcons = (step) =>{
        if (step.task_status === taskStatusTypesGraphQL.complete){
            return(
                <TimelineDot className={classes.JobHistory_timelineDot}>
                    <CheckCircle style={{fill: statusStyles.complete.color}}/>
                </TimelineDot>
            )
        }
        else if (step.task_status === taskStatusTypesGraphQL.inProgress){
            return(
                <TimelineDot className={classes.JobHistory_timelineDot}>
                    <AccessTime style={{fill: statusStyles.inProgress.color}}/>
                </TimelineDot>
            )
        }
        else if (!!step.job_error_number) {
            return(
                <TimelineDot className={classes.JobHistory_timelineDot}>
                    <Error style={{fill: statusStyles.error.color}}/>
                </TimelineDot>
            )
        }
        else {
            return(
                <TimelineDot className={classes.JobHistory_timelineDot}>
                    <RadioButtonUnchecked style={{fill: statusStyles.unassigned.color}}/>
                </TimelineDot>
            )
        }
    }

    const handleRenderFloorName = (step) =>{
        return(
            <Typography className={classes.JobHistory_stepperLabel}>
                {step.location_name}
                {(!!step.job_error_number) && (
                    <MasterButton
                    keyLbl={'export-job-tasks'}
                    label={ERROR_DETAILS}
                    buttonType={"success"}
                    isDisabled={false}
                    handleClick={() => handleErrorDetails(step)}
                    class={classes.JobHistory_errorDetailButton}
                    size={"small"}/>
                )}
            </Typography>
        )
    }

    const handleRenderTaskTime = (step) =>{
        if (!!step.job_error_number){
            return(
                <Typography className={classes.JobHistory_stepperContent}>
                    {!!step.error_date ? moment(step.error_date).format("hh:mm:ss A") : 'GPS'}
                </Typography>
            )
        }
        else{
            return(
                <Typography className={classes.JobHistory_stepperContent}>
                    {!!step.task_start_date ? moment(step.task_start_date).format("hh:mm:ss A") : 'TBA'}
                </Typography>
            )
        }

    }

    const handleRenderStartPointErrorDescription = () =>{
        return(
            <Chip
            label={ERROR_TASKS.jobStartDelay.description}
            className={classes.RouteInfoCard_ErrorChip}
            style={{backgroundColor: STATUS_STYLES.error.backgroundColor, color: STATUS_STYLES.error.color}}
            icon={<ErrorIcon style={{fill: STATUS_STYLES.error.color}}/>}/>
        )
    }

    const handleFloorName=(startPoint)=>{
        if(startPoint.geolocation.floor!==-100){
            return `L-${startPoint.geolocation.floor}`;
        }
        return `GPS`;
    }

    const handleRenderStartPointInfo = () =>{
        const { historyTracesForCSV, mapConfigs } = props;
        const startPoint = historyTracesForCSV[0];
        const floorLabel = !!startPoint && !!startPoint.geolocation.floor ?handleFloorName(startPoint): 'GPS';
        const currentFloor = !!mapConfigs.floors ? mapConfigs.floors.find(floor=> !!startPoint && floor.ordinal === startPoint.geolocation.floor) : undefined;
        const style = !!currentFloor? {backgroundColor: getFloorColorByOrdinal(currentFloor.ordinal), color: "white"} : {backgroundColor: '#2843A3'};
        let flagColor = {fill: statusStyles.complete.color};
        let startPointError = false;
        if ( jobInfo[0].errorNumber === ERROR_TASKS.jobStartDelay.key || (!!jobInfo[0].job_errors && jobInfo[0].job_errors.includes(ERROR_TASKS.jobStartDelay.key)) ){
            flagColor = {fill: statusStyles.error.color};
            startPointError = true;
        }

        return(
            <CustomTimelineItem key={'startPoint'}>
                <TimelineSeparator>
                    <TimelineDot className={classes.JobHistory_timelineDot}>
                        <Flag style={flagColor}/>
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography  
                        style={style}
                        className={classes.JobHistory_stepperFloor}>
                            {floorLabel}
                        </Typography>
                        <Typography className={classes.JobHistory_stepperLabel}>
                            {START_POINT}
                        </Typography>
                        <Typography className={classes.JobHistory_stepperContent}>
                            {!!jobInfo[0].startTime ? moment(jobInfo[0].startTime).format("hh:mm:ss A") : 'GPS'}
                        </Typography>
                        { startPointError && handleRenderStartPointErrorDescription()}
                    </TimelineContent>
            </CustomTimelineItem>
        )
    }

    const handleRenderTaskList = () =>{
        const {jobTasks} = props;
        return(
            <div className="JobHistory-taskListContainer">
                <div style={{display:"flex"}}>
                    <div className="JobHistory-taskListTitle">{ROUTE_INFO}</div>
                    {props.featureFlags.SpecimenCollection.ExportTraces ? (
                        <div style={{display:'flex', width:"200px"}}>
                            <MasterButton
                            keyLbl={'export-job-tasks'}
                            label={"Export"}
                            buttonType={"success"}
                            isDisabled={false}
                            handleClick={props.generateTasksPDF}
                            size={"medium"}
                            class={classes.JobHistory_exportButton}
                            startIcon={"download"}/>
                            <Tooltip title={"Export Traces"}>
                            <IconButton style={{marginTop:"15px", marginLeft:"10px"}} onClick={props.generateCSVFile} >
                                <Map style={{fill:"blue"}}/>
                            </IconButton>
                            </Tooltip>
                        </div>
                    ):(
                        <MasterButton
                        keyLbl={'export-job-tasks'}
                        label={"Export"}
                        buttonType={"success"}
                        isDisabled={false}
                        handleClick={props.generateTasksPDF}
                        size={"medium"}
                        class={classes.JobHistory_exportButton}
                        startIcon={"download"}/>
                    )}
                </div>
                <div className="JobHistory_Timeline" style={{paddingTop: "20px", paddingLeft: "20px"}}>
                    <Timeline position="alternate">
                        {handleRenderStartPointInfo()}
                        {jobTasks.map((step, index)=>(
                        <CustomTimelineItem key={index}>
                            <TimelineSeparator>
                            {handleRenderTaskIcons(step)}
                            {index !== jobTasks.length-1 && (<TimelineConnector />)}
                            </TimelineSeparator>
                            <TimelineContent>
                                {handleRenderFloorLevel(step)}
                                {handleRenderFloorName(step)}
                                {handleRenderTaskTime(step)}
                            </TimelineContent>
                        </CustomTimelineItem>
                        ))}
                    </Timeline>
                </div>
            </div>
        )
    }

    return(
        <div>
            {handleRenderJobInfo()}
            {handleRenderTaskList()}
            <SimpleModalPop
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              icon={<Error style={{fill: "#CE4947", fontSize:"120px", marginLeft:"35%"}}/>}
              title={ERROR_DETAILS}
              subtitle={errorDescription}
              buttonLabel={"Ok"}
              handleOption={handleCloseModal}
            />
        </div>
    )
}

export default JobHistory;