//IMDFArchive.js
export const UNIT="unit";
export const LEVEL="level";
export const OPENING="opening";
export const VENUE="venue";
export const EXCLUDE_CATEGORIES=["walkway", "nonpublic", "opentobelow"];
export const ROOM="room";
export const FEATURE="Feature";
export const ANCHOR="anchor";
export const FILES=["levels","units","openings","venues"];

//indoorMap.js
export const ZOOM_INFO ={
    0: {lat: 0.0006,  lon:0.0006},
    1: {lat: 0.0016, lon:0.0016},
    2: {lat: 0.0026, lon:0.0026},
    3: {lat: 0.0036, lon:0.0036},
    4: {lat: 0.0046, lon:0.0046}
};
export const CENTER_DEFAULT={lat:39.158039,lon: -99.247453};
export const SPAN_DEFAULT={lat:20, lon:20};
export const PREVIEW_MAP="previewMap";
export const HIDDEN="hidden";

//levelpicker.js
export const PREVIEW_LEVELPICKER="preview-levelpicker";
export const UL='ul';
export const LI='li';
export const NONE='none';
export const BLOCK='block';
export const CLICK='click';
export const PREVIEW_CONTAINER="previewContainer";
export const FLOOR_SELECTED="floorSelected";
export const LEVEL_ORDINAL="level-";
export const CLASS_SELECTED=".selected";
export const SELECTED="selected";
export const LEVEL_ORDINAL_SELECTED='.level-';