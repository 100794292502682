import React from 'react';

import TextField from 'material-ui/TextField';
import Box from '@material-ui/core/Box';
import Divider from 'material-ui/Divider';
import ChatIcon from '@material-ui/icons/Chat';
import SvgIcon from '@material-ui/core/SvgIcon';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupIcon from '@material-ui/icons/Group';
import RestoreIcon from '@material-ui/icons/Restore';
import AppsIcon from '@material-ui/icons/Apps';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TableChartIcon from '@material-ui/icons/TableChart';
import LockIcon from '@material-ui/icons/Lock';
import Paper from '@material-ui/core/Paper';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import './accesscontrol.component.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {saveACL,updateACL,deleteACL,fetchACLS} from '../../../actions/acl/aclActions';
import {getACL} from '../../../actions/auth/authActions';
import {createLog} from '../../../actions/logs/logsActions';
import {getPhones} from "../../../actions/phones/phonesActions";
import {getEquipment} from '../../../actions/equipment/equipmentActions';
import MasterAlert from '../../utilities/alert.component';
import AwesomeSpinner from '../../utilities/spinner';
import MasterButton from '../../utilities/button.component';
import wayfinding from "../../../img/wayfinding_blue.png";
import contactIcon from "../../../img/contactIconAC.png";
import broadcastIcon from "../../../img/broadcastAC.png";
import workflowAutomationIcon from "../../../img/workflowAutomationAC.svg";
import {styles} from './accesscontrol.styles';
import RoleList from './roleList/roleList.component';
import PermissionsList from './permissions/permissions.component';
import TrackingOption from './track/trackingOption.component';
import ContactOption from './contacts/contacts.component';
import ModalPop from '../../utilities/modal.component';
import * as CONSTS from './accesscontrol.constants';

class AccessControlComponent extends React.Component{
    state={
        menuValue:-1,
        roles:[],
        views:CONSTS.DEFAULT_VIEWS,
        create:false,
        roleName:'',
        updateRole:false,
        roleId:-1,
        teamSelected:'',
        editTeam: false,
        showTeamForm: false,
        trackRole:false,
        trackEquipment:false,
        openSnack:false,
        snackMsg:'',
        snackType: CONSTS.SUCCESS,
        openModal: false,
        viewPermissions:CONSTS.DEFAULT_VIEWS,
        showSpinner:false,
        isActionInProgress: false,
    }
    currentTeamName='';
    selectedviews=[];
    selectedRolesChat=[];
    teamUsers={};
    teamPhones={};
    teamEquipment={};
    teamTotal=0;
    updateList=false;
    updateListAfterDelete=false;
    save=false;

    componentDidMount(){
        if (this.props.roles === null || this.props.roles === undefined || this.props.roles.length === 0){
            this.props.fetchACLS();
        }
        this.props.getPhones();
        this.props.getEquipment(this.props.campusId);
        this.setState({roles:this.props.roles});
        this.selectedviews=[];
        this.selectedRolesChat=[];
    }

    componentDidUpdate(){
        if(this.updateList || this.updateListAfterDelete ){
            this.updateRolesData();
        }
        if(this.save && this.teamTotal !== this.props.roles.length){
            this.updateContactList();
        }
    }

    getCurrentRoleACL = async () =>{
        await this.props.getACL(this.props.user.id);
    };

    updateContactList = ()=>{
        let roleInfo = this.props.roles.filter(item=>item.role === this.state.roleName);
        if(!_.isEmpty(roleInfo)){
            let contacts = roleInfo[0].contact;
            if(!contacts.includes(roleInfo[0].id)) {
              contacts.push(roleInfo[0].id);
              roleInfo[0].contact = contacts;
              this.props.updateACL(roleInfo[0]);
              this.save=false;
              this.teamTotal=0;
              this.updateList=true;
            }
        }
    };

    updateRolesData = async() =>{
        await this.props.fetchACLS();
        this.setState({roles:this.props.roles});
        this.updateList = false;
        this.updateListAfterDelete = false;
    };

    handleOpenSnackbar = (type,msg) => {
        this.setState({snackType:type,snackMsg:msg,openSnack: true});
    };

    handleCloseSnackbar = () => {
        this.setState({openSnack: false});
    };

    handleOpenModal = () => {
        this.setState({openModal: true, isActionInProgress: true});
    };

    handleCloseModal = () => {
        this.setState({openModal: false, isActionInProgress: false});
    };

    createRoleLog = (data) =>{
        const {user, userId} = this.props;
        let performedBy = '';
        if(user && user.id) {
            performedBy = user.id;
        } else if (userId) {
            performedBy = userId;
        }
        let log={
            campusId: this.props.campusId,
            userId: performedBy,
            action: data.action,
            description: data.description,
            timestamp: new Date()
        };
        this.props.createLog(log);
    };

    handleToggle = (e,check)=>{
        let auxViews = this.state.viewPermissions;
        this.state.viewPermissions.forEach((item,i)=>{
            if(item.id === parseInt(e.target.name)) {
                auxViews[i].access=check;
            }
        });
        this.setState({viewPermissions:auxViews});
    }

    handleDuplicateRoles=(roleName)=>{
        let roleInfo = this.props.roles.find(role=> role.role.toUpperCase() === roleName.toUpperCase()) || {};
        if(roleInfo.hasOwnProperty(CONSTS.ROLE))
            return true
        else
            return false
    }

    handlePerformedBy = ()  => {
        const {user, userId} = this.props;
        let performedBy = '';
        if(user && user.name) {
            performedBy = user.name;
        } else if(user && user.id) {
            performedBy = user.id;
        } else if (userId) {
            performedBy = userId;
        }
        return performedBy;
    }

    handleSaveRole(props){
        const {classes} = props;
        this.setState({showSpinner: true, isActionInProgress: true});
        if(this.state.roleName.length>0){
            this.selectedviews=this.state.viewPermissions;
            let enableTableView = false;
            this.selectedviews=this.selectedviews.map(e=>{
                if((e.id=== 4 || e.id ===5 || e.id===8) && e.access===true)
                    enableTableView = true;
                if(e.access==null)
                    e.access=false;
                return e;
            });

            if(enableTableView)
                this.selectedviews[2].access= enableTableView;

            if(this.selectedRolesChat.length>0){
                if(!this.state.updateRole){
                    this.handleSaveAccess();
                } else {
                    this.handleUpdateAccess();
                }
            }else{
                this.setState({showSpinner: false, isActionInProgress:false});
                let msg= <div className={classes.inlineContainer}>Select <div className={classes.boldSnackMsg}>at least one</div> Team to chat with</div>
                this.handleOpenSnackbar(CONSTS.WARNING, msg);
            }
        }else{
            this.setState({showSpinner: false, isActionInProgress:false});
            let msg= <div className={classes.inlineContainer}>Enter a <div className={classes.boldSnackMsg}>Name</div> for the Team</div>
            this.handleOpenSnackbar(CONSTS.WARNING, msg)
        }
    }

    handleUpdateAccess = () =>{
        const {classes} = this.props;
        let role={
            'role':this.state.roleName.trim(),
            'views':this.selectedviews,
            'contact':this.selectedRolesChat,
            'campusId':this.props.campusId,
            'track':this.state.trackRole,
            'last_update': new Date()
        }
        let roleDuplicated= false;
        let changeInName=false;
        let performedBy= this.handlePerformedBy();
        if(this.state.roleName!==this.currentTeamName){
            changeInName = true;
            roleDuplicated = this.handleDuplicateRoles(this.state.roleName);
        }
        if(!roleDuplicated){
            role.id=this.state.roleId;
            this.save=false;
            this.props.updateACL(role).then(res=>{
              if(changeInName)
                this.createRoleLog({action: CONSTS.UPDATE_ACTION, description: `Team '${this.currentTeamName}' renamed to '${this.state.roleName}', updated by "${performedBy}"`});
              else
                this.createRoleLog({action: CONSTS.UPDATE_ACTION, description: `Team "${role.role}" updated by "${performedBy}"`});
              this.setState({showSpinner: false, isActionInProgress:false});
              let msg= <div className={classes.inlineContainer}>The Team "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" was updated successfully!</div>
              this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
              this.updateList = true;
              if(this.props.user && this.props.user.role === this.state.roleId){
                this.getCurrentRoleACL();
              }
            }).catch(err=>{
                this.setState({showSpinner: false, isActionInProgress:false});
                let msg= <div className={classes.inlineContainer}>The Team "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" couldn't be updated!</div>
                this.handleOpenSnackbar(CONSTS.WARNING, msg);
                this.createRoleLog({action: CONSTS.UPDATE_ACTION_ERROR, description: `Team "${role.role}" couldn't be updated by "${performedBy}" due endpoint ${err}`});
            });
            this.props.fetchACLS();

            this.handleResetRoles();
            this.setState({create:false});
        }else{
            this.setState({showSpinner: false, isActionInProgress:false});
            let msg= <div className={classes.inlineContainer}>The Team "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" already exists!</div>
            this.handleOpenSnackbar(CONSTS.WARNING, msg);
        }
    };

    handleSaveAccess = ()=>{
        const {classes} = this.props;
        let role={
            'role':this.state.roleName.trim(),
            'views':this.selectedviews,
            'contact':this.selectedRolesChat,
            'campusId':this.props.campusId,
            'track':this.state.trackRole,
            'create_date': new Date(),
            'last_update': new Date()
        }
        let roleDuplicated = this.handleDuplicateRoles(this.state.roleName);
        let performedBy= this.handlePerformedBy();
        if(!roleDuplicated){
            this.props.saveACL(role).then(res=>{
              this.setState({showSpinner: false, isActionInProgress: false});
              let msg = <div className={classes.inlineContainer}>The Team "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" was created successfully!</div>
              this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
              this.updateList = true;
              this.createRoleLog({action: CONSTS.CREATE_ACTION, description: `Team "${role.role}" created by "${performedBy}"`});
            }).catch(err=>{
                this.setState({showSpinner: false, isActionInProgress: false});
                let msg = <div className={classes.inlineContainer}>The Team "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" couldn't be saved!</div>
                this.handleOpenSnackbar(CONSTS.WARNING, msg);
                this.createRoleLog({action: CONSTS.CREATE_ACTION_ERROR, description: `Team "${role.role}" couldn't be created by "${performedBy}" due endpoint ${err}`});
            });
            this.save= true;
            this.teamTotal=this.props.roles.length;

            this.handleResetRoles();
            this.setState({create:false});
        }else{
            this.setState({showSpinner: false, isActionInProgress: false});
            let msg= <div className={classes.inlineContainer}>The Team name "<div className={classes.boldSnackMsg}>{this.state.roleName}</div>" already exists!</div>
            this.handleOpenSnackbar(CONSTS.WARNING, msg);
        }
    };

    handleDelete = () => {
        const {classes} = this.props;
        const {roleName} = this.state;
        let teamInfo = this.props.roles.find(role=>role.role === roleName) || {}
        let accountsLinked = false;
        if(!_.isEmpty(teamInfo)){
            if(this.teamUsers[teamInfo.id]>0 || this.teamPhones[teamInfo.id]>0 || this.teamEquipment[teamInfo.id]>0) accountsLinked= true;
        }
        if(accountsLinked){
            let msg= <div className={classes.inlineContainer}>{CONSTS.ACCOUNTS_ASSOCIATED}</div>
            this.handleOpenSnackbar(CONSTS.ERROR, msg);
        }else {
            this.handleDeleteRole();
        }
        this.handleCloseModal();
    }

    handleDeleteRole=()=>{
        const {classes} = this.props;
        const {roleId} = this.state;
        let performedBy= this.handlePerformedBy();
        this.props.deleteACL(roleId).then(res=>{
            let msg= <div className={classes.inlineContainer}>{CONSTS.DELETE_MSG}</div>
            this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
            this.updateListAfterDelete = true;
            this.createRoleLog({action: CONSTS.DELETE_ACTION, description: `Team "${this.state.roleName}" deleted by "${performedBy}"`});
        }).catch(err=>{
            let msg= <div className={classes.inlineContainer}>{CONSTS.DELETE_MSG_ERROR}</div>
            this.handleOpenSnackbar(CONSTS.WARNING, msg);
            this.createRoleLog({action: CONSTS.DELETE_ACTION_ERROR, description: `Team "${this.state.roleName}" couldn't be deleted by "${performedBy}" due endpoint ${err}`});
        });
        this.handleResetRoles();
        this.setState({create:false, isActionInProgress:false});
    };

    handleRolesCheck = (ev,checked) => {
        if(checked===true){
            if(!this.selectedRolesChat.includes(ev.target.value))
                this.selectedRolesChat.push(ev.target.value);
        }else{
            this.selectedRolesChat=this.selectedRolesChat.filter(e=>e !== ev.target.value);
        }
    }

    getUsersPerTeam=()=>{
        const {users,roles} = this.props;
        roles.forEach(role => {
            this.teamUsers[role._id]= users.filter(user=>user.role===role._id).length || 0;
        });
    }

    getPhonesPerTeam=()=>{
        const {phones,roles} = this.props;
        roles.forEach(role => {
            this.teamPhones[role._id] = phones.filter(p=>p.teams.includes(role._id)).length || 0;
        });
    }

    getEquipmentPerTeam=()=>{
        const {equipmentList,roles} = this.props;
        roles.forEach(role => {
            this.teamEquipment[role._id] = equipmentList.filter(e=>e.team === role._id).length || 0;
        });
    }

    handleResetRoles=()=>{
        this.selectedviews=[];
        this.selectedRolesChat=[];
        this.setState({
            menuValue:-1,
            // roleName:'',
            create:true,
            updateRole:false,
            showTeamForm:false,
            teamSelected:'',
            trackRole:false,
            editTeam:false});
    }

    handleResetRolesNewTeam=()=>{
        this.selectedviews=[];
        this.selectedRolesChat=[];
        let cleanViews = CONSTS.DEFAULT_VIEWS;
        cleanViews.forEach(view=>view.access = false);
        this.setState({
            viewPermissions: cleanViews,
            menuValue:-1,
            roleName:'',
            create:true,
            updateRole:false,
            showTeamForm:true,
            teamSelected:'',
            editTeam:false,
            trackRole:false
        });
    }

    handleSelectTeam=(value)=>{
        let role=this.props.roles.filter(role=>role._id===value) || [];
        if(role.length>0){
            if(!!role[0].contact)
                this.selectedRolesChat=role[0].contact;

            let auxViewArray = role[0].views;
            if(auxViewArray[8]===undefined){
                auxViewArray.push({id:9, name:CONSTS.VIEWS.equipmentMap, access:false});
            }
            if(auxViewArray[9]===undefined){
                auxViewArray.push({id:10, name:CONSTS.VIEWS.contactPanel, access:false});
            }
            if(auxViewArray[10]===undefined){
                auxViewArray.push({id:11, name:"Configuration Panel", access:false});
            }
            if(auxViewArray[11]===undefined){
                auxViewArray.push({id:12, name:"Wayfinding", access:false});
            }
            // if(auxViewArray[12]===undefined){
            //     auxViewArray.push({id:12, name:"Multicampus Panel", access:false});
            // }
            let isRoleTracked = false;
            if(!!role[0].track)
              isRoleTracked = role[0].track;
            this.selectedviews=auxViewArray;
            let permissionsList = auxViewArray;

            this.setState({
                teamSelected: value,
                menuValue:value,
                roleName:role[0].role,
                create:true,
                updateRole:true,
                roleId:role[0]._id,
                showTeamForm: false,
                editTeam:false,
                trackRole: isRoleTracked,
                viewPermissions: permissionsList});
        }
    }

    handleAccessIcon=(index)=>{
        const {classes} = this.props;
        switch (index){
        case 0:
            return(
            <SvgIcon className={classes.roleIcon}>
                <path d={CONSTS.ROLEICON} />
            </SvgIcon>)
        case 1:
            return <LocationOnIcon className={classes.roleIcon}/>
        case 2:
            return <TableChartIcon className={classes.roleIcon}/>
        case 3:
            return <GroupIcon className={classes.roleIcon}/>
        case 4:
            return <RestoreIcon className={classes.roleIcon}/>
        case 5:
            return <HourglassEmptyIcon className={classes.roleIcon}/>
        case 6:
            return <ChatIcon className={classes.roleIcon}/>
        case 7:
            return <AppsIcon className={classes.roleIcon}/>
        case 8:
            return <BusinessCenterIcon className={classes.roleIcon}/>
        case 9:
            return  <img src={contactIcon} className={classes.roleIcon} alt="contactIconAC"/>
        case 10:
            return <SettingsIcon className={classes.roleIcon}/>
        case 11:
            return <img src={wayfinding} className={classes.rolePng} alt="wayfindingIconAC"/>
        case 12:
            return <img src={broadcastIcon} className={classes.rolePng} alt="broadcastIconAC"/>
        case 13:
            return <img src={workflowAutomationIcon} className={classes.rolePng} alt="workflowAutomationIconAC"/>
        // case 13:
        //     return <LanguageIcon className={classes.roleIcon/>
        default:
            break;
        }


    }

    handleCancel= ()=>{
        this.setState({
            editTeam:false,
            showTeamForm:false,
            teamSelected:'',
            trackRole:false});
    }

    handleEditTeam = ()=>{
        this.setState({editTeam:true});
    }

    displayButtons=()=>{
        const {classes} = this.props;
        const {editTeam, showTeamForm, isActionInProgress} = this.state;
       return(
        <React.Fragment>
        {   editTeam || showTeamForm?
            <React.Fragment>
                <div style={{display:'flex'}}>
                    <div className={classes.inlineContainer}>
                    <MasterButton
                      keyLbl={'delete-team'}
                      label={'Delete'}
                      buttonType={'error'}
                      isDisabled={isActionInProgress}
                      handleClick={this.handleOpenModal}
                      size={"medium"}
                      endIcon={'trashCan'}
                    />
                    </div>
                    <div className={classes.displayButton}>
                    <MasterButton
                      keyLbl={'cancel-team'}
                      label={'Cancel'}
                      buttonType={'default'}
                      isDisabled={isActionInProgress}
                      handleClick={this.handleCancel}
                      size={"medium"}
                    />
                    </div>
                    <div className={classes.displayButton}>
                    <MasterButton
                      keyLbl={'save-team'}
                      label={'Save'}
                      buttonType={'success'}
                      isDisabled={isActionInProgress}
                      handleClick={()=>this.handleSaveRole(this.props)}
                      size={"medium"}
                      class={classes.containedBtn}
                    />
                    </div>
                </div>
            </React.Fragment>
            :<React.Fragment>{
                this.state.teamSelected!==''?
                <div className={classes.displayButtonRegular}>
                    <div className={classes.inlineContainer}>
                    <MasterButton
                      keyLbl={'delete-team'}
                      label={'Delete'}
                      buttonType={'error'}
                      isDisabled={isActionInProgress}
                      handleClick={this.handleOpenModal}
                      size={"medium"}
                      endIcon={'trashCan'}
                    />
                    </div>
                    <div className={classes.displayButton}>
                    <MasterButton
                      keyLbl={'edit-team'}
                      label={'Edit'}
                      buttonType={'success'}
                      isDisabled={isActionInProgress}
                      handleClick={this.handleEditTeam}
                      size={"medium"}
                      class={classes.containedBtn}
                      styles={{float:'right'}}
                    />
                    </div>
                </div>
                :<MasterButton
                  keyLbl={'editDisabled-team'}
                  label={'Edit'}
                  buttonType={'success'}
                  isDisabled={true}
                  size={"medium"}
                  styles={{float:'right'}}
                />}
            </React.Fragment>
        }
        </React.Fragment>
       )
    }

    handleShowTeamForm=()=>{
        return(
            <Box
            component="div"
            display="block"
            bgcolor= "white"
            p={1}
            m={2}
            ml={5}
            height='20%'
            width='70%'
            borderRadius='4px'
            boxShadow=' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'>
                <div style={{display:'block'}}>
                    <TextField
                        style={{width:'90%', marginLeft:'5%'}}
                        id="new-team"
                        hintText="Team Name"
                        onChange={(e)=>{this.setState({roleName:e.target.value})}}
                        value={this.state.roleName}
                        ref={this.textInput}
                    />
                </div>
            </Box>
        )
    }

    changeRoleName = (value) =>{
        this.setState({roleName:value.trim()});
    };

    handleChangeTrackStatus = () =>{
        this.setState({trackRole: !this.state.trackRole});
    };

    updateCurrentTeamName = (value) =>{
        this.currentTeamName = value;
    };

    render(){
        const {classes} = this.props;
        const {snackType, snackMsg, openSnack, openModal, showTeamForm} = this.state;
        this.getUsersPerTeam();
        this.getPhonesPerTeam();
        this.getEquipmentPerTeam();
        return(
            <React.Fragment>
            <div className="acl-header" id="webNavv-adminPanel-accessControl-1.0">
              <div className={classes.iconDiv}>
                <LockIcon className={classes.headerIcon}/>
              </div>
            <h2 className="acl-headerTitle">{CONSTS.ACCESS_CONTROL}</h2>
            </div>
            <div className="acl-container">
            <Box
            component="div"
            p={2}
            marginLeft={"20px"}
            width='40%'
            height='100%'
            borderRadius='4px'
            border='1px solid #E5E5E5'
            >
            <Box
                component="div"
                textAlign="left"
                display="block">
                <div className={classes.flexContainer}>
                    <div className={classes.flexSubContainer}>
                        <p style={{fontSize:'20px'}}>{CONSTS.TEAMS}</p>
                    </div>
                <div className={classes.newTeamContainer}>
                    <MasterButton
                      id="webNavv-adminPanel-accessControl-teamsManager-newTeamButton-1.0"
                      keyLbl={'add-team'}
                      label={'New Team'}
                      buttonType={'success'}
                      isDisabled={false}
                      handleClick={this.handleResetRolesNewTeam}
                      size={"medium"}
                      class={classes.containedBtn}
                      styles={{width:'160px'}}
                    />
                    </div>
                </div>
            </Box>
            <Divider/>
                <div className={classes.blockContainer}>
                    {showTeamForm && this.handleShowTeamForm()}
                </div>
                <div className={classes.blockContainer}>
                <Paper className={classes.paperTeam}>
                    <RoleList
                      roles={this.props.roles}
                      editTeam={this.state.editTeam}
                      teamSelected={this.state.teamSelected}
                      teamUsers={this.teamUsers}
                      teamPhones={this.teamPhones}
                      teamEquipment={this.teamEquipment}
                      handleSelectTeam={this.handleSelectTeam}
                      updateCurrentTeamName={this.updateCurrentTeamName}
                      roleName={this.state.roleName}
                      changeRoleName={this.changeRoleName}
                      roleNameInput={this.roleNameInput}/>
                </Paper>
                </div>
            </Box>
                <Box
                p={3}
                marginLeft={"40px"}
                textAlign="center"
                width='60%'
                height='100%'
                borderRadius='4px'
                border='1px solid #E5E5E5'>
                    <Box
                    textAlign="left"
                    width='100%'>
                        <div className={classes.flexContainer}>
                            <div className={classes.accessPermissionDiv}>
                            </div>
                            <div className={classes.buttonDiv}>
                                {this.displayButtons()}
                            </div>
                         </div>
                     </Box>
                    <div style={{overflow:'auto', maxHeight: "65vh", overflowX:'hidden'}}>
                     <Box
                    textAlign="left"
                    width='100%'>
                        <div className={classes.flexContainer}>
                            <div className={classes.accessPermissionDiv}>
                                <p style={{fontSize:'20px'}}>{CONSTS.ACCESS_PERMISSIONS}</p>
                            </div>
                         </div>
                     </Box>
                     <Box
                      textAlign="left"
                      width='100%'>
                         <div className={classes.flexContainer}>
                            <div className={classes.actionDiv}>
                                <p className={classes.actionLbl}>{CONSTS.ACTION}</p>
                            </div>
                            <div className={classes.permissionDiv}>
                                <p className={classes.permissionLbl}>{CONSTS.PERMISSION}</p>
                            </div>
                         </div>
                     </Box>
                     <Divider/>
                     <PermissionsList
                     featureFlags={this.props.featureFlags}
                     username={this.props.userInfo.username}
                     roles={this.props.roles}
                     teamSelected={this.state.teamSelected}
                     views={this.state.views}
                     showTeamForm={this.state.showTeamForm}
                     editTeam={this.state.editTeam}
                     viewPermissions={this.state.viewPermissions}
                     selectedviews={this.selectedviews}
                     handleToggle={this.handleToggle}
                     handleAccessIcon={this.handleAccessIcon}/>
                    <Box
                      textAlign="left"
                      width='100%'
                      mt={5}>
                         <div className={classes.flexContainer}>
                            <div className={classes.trackDiv}>
                                <p style={{fontSize:'20px'}}>{CONSTS.TRACK_PERMISSIONS}</p>
                            </div>
                         </div>
                     </Box>
                     <Divider/>
                     <TrackingOption
                       teamSelected={this.state.teamSelected}
                       trackRole={this.state.trackRole}
                       editTeam={this.state.editTeam}
                       showTeamForm={this.state.showTeamForm}
                       roles={this.props.roles}
                       handleChangeTrackStatus={this.handleChangeTrackStatus}/>
                    <Divider/>
                    <Box
                      textAlign="left"
                      width='100%'
                      mt={5}>
                         <div className={classes.flexContainer}>
                            <div className={classes.compositionDiv}>
                                <p style={{fontSize:'20px'}}>{CONSTS.TEAM_COMPOSITION}</p>
                            </div>
                         </div>
                     </Box>
                     <Divider/>
                     <div style={{marginTop:'20px'}}>
                        <ContactOption
                        teamSelected={this.state.teamSelected}
                        editTeam={this.state.editTeam}
                        showTeamForm={this.state.showTeamForm}
                        roles={this.props.roles}
                        selectedRolesChat={this.selectedRolesChat}
                        handleRolesCheck={this.handleRolesCheck}/>
                     </div>
                    </div>
                </Box>

                <ModalPop
                  handleCloseModal={this.handleCloseModal}
                  openModal={openModal}
                  icon={<ReportProblemIcon  className={classes.problemIcon}/>}
                  title={"Are you sure?"}
                  subtitle={`You are going to delete "${this.state.roleName}" team!`}
                  handleFirstOption={this.handleDelete}
                  handleSecondOption={this.handleCloseModal}
                  button1Label={"Delete"}
                  button2Label={"Cancel"}
                />

                <MasterAlert
                  snackStyle={{marginTop:'5vh'}}
                  anchor={{ vertical: 'top', horizontal: 'right' }}
                  openSnack={openSnack}
                  handleClose={this.handleCloseSnackbar}
                  alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
                  elevation={6}
                  variant={"filled"}
                  type={snackType}
                  message={snackMsg}
                />
                <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open}/>
            </div>
            </React.Fragment>
        )
    }

}

AccessControlComponent.propTypes = {
    saveACL: PropTypes.func.isRequired,
    updateACL: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    deleteACL: PropTypes.func.isRequired,
    fetchACLS: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    access_token:state.auth.token,
    roles: state.acl.acls,
    users:state.user.users,
    campusId:state.auth.userInfo.campusId,
    open:state.views.drawerOpen,
    phones: state.phones.phones,
    equipmentList: state.equipments.equipmentList,
    user: state.user.user,
    userId: state.auth.userId,
    userInfo: state.auth.userInfo,
    featureFlags: state.auth.featureFlags
})


export default connect(mapStateToProps,{
    getPhones,
    getEquipment,
    saveACL,
    updateACL,
    deleteACL,
    fetchACLS,
    getACL,
    createLog})(withStyles(styles)(AccessControlComponent));