import React from "react";
import moment from "moment";

import LocationOnIcon from '@material-ui/icons/LocationOn';
import Chip from '@material-ui/core/Chip';
import { Checkbox } from 'material-ui';

import {
    getWindowNewDistances,
    getTracesPerJob,
    handleSortJobDates,
    getJobStateList,
    calculateHistoryRoute,
    getWorkerName} from "../historyMap.constants";
import MasterButton from '../../../utilities/button.component';
import CustomizedExpansionPanel from '../expansionPanel/expansionPanel.component';
import TimeWindowSlider from '../timeWindowSlider/timeWindowSlider.component';
import JobHistoryTraces from '../jobTraces/jobTraces.component';

const UserJobHistory = (props) =>{
    const { historyRoute, jobInfo, users, animationDates, windowDates, windowDistance, index, classes, animationView } = props;
    let windowDistances = [];

    const handleRenderInfo = () =>{
        if (historyRoute) {
            // let distanceLbl = jobInfo[0].distance >= 0 ? `${jobInfo[0].distance} miles` : ` N/A`;
            if (windowDistance) {
              windowDistances = getWindowNewDistances(windowDates, animationDates, historyRoute, animationView);
            } else {
              let tracesPerJobListAux = getTracesPerJob(historyRoute);
              windowDistances = calculateHistoryRoute(tracesPerJobListAux);
            }
            const [jobs, workers, jobTraces] = getWorkerName(users, historyRoute);
            const startDate = moment(jobInfo[0].startDate).format('MM/DD/YYYY HH:mm:ss');
            const endDate = moment(jobInfo[jobInfo.length - 1].endDate).format('MM/DD/YYYY HH:mm:ss');
            const [newStartDate, newEndDate] = handleSortJobDates(jobInfo);
            props.handleUpdateTimeWindows(newStartDate, newEndDate);
            const checkBoxJob = getJobStateList(jobs);
            // const percentageLabel = jobInfo[0].percentage >= 0 && jobInfo[0].traceHealingJob ? ` ${Number(jobInfo[0].percentage).toPrecision(3)}%` : ' none';
            // let windowDistancesAvailable = false;
            // if (windowDistances.length > 0) {
            //   windowDistancesAvailable = !!windowDistances[0][1] ? true : false;
            // }
            let jobDevice = 'n/a';
            if (workers.length === 1) {
              jobDevice = jobInfo[0].device;
            }
            // let appInfo = '';
            // const appData = historyRoute[0]['User-Agent'].split(" ");
            // if(workers.length===1)
            //     appInfo = `${appData[1]}  ${appData[2]}`;
      
            return (
              <React.Fragment>
                <div style={{ display: 'flex' }}>
                  <div id="jobTraces">
                    <JobHistoryTraces 
                      jobs={jobs}
                      checkBoxJob={checkBoxJob}
                      updateJobsShown={props.handleUpdateJobsShown}
                      resetJobsShown={props.resetJobsShown}
                      jobTotal={jobInfo.length}
                      handleChangeCardInfo={props.handleChangeCardInfo}
                      currentSelectedJob={props.handleCurrentSelectedJob} />
                  </div>
                </div>
                {workers.length === 1 ?
                  <div style={{ display: 'block', backgroundColor: '#2843A3' }}>
                    <div style={{ paddingTop: '10px', marginLeft: '80px' }}>
                      <p className={classes.nestedTxt} style={{ marginTop: '0' }}><b style={{ marginRight: '100px' }}>Job ID:</b> {historyRoute[0].jobId}</p>
                      <p className={classes.nestedTxt}><b style={{ marginRight: '97px' }}>Worker:</b> {workers[0][0].first_name} {workers[0][0].last_name}</p>
                      <p className={classes.nestedTxt}><b style={{ marginRight: '110px' }}>Role:</b> {workers[0][0].role_name}</p>
                      <p className={classes.nestedTxt}><b style={{ marginRight: '97px' }}>Device:</b> {jobDevice}</p>
                      <p className={classes.nestedTxt}><b style={{ marginRight: '97px' }}>Status:</b> {jobInfo[0].status}</p>
                      {/* <p className={classes.nestedTxt}><b style={{ marginRight: '53px'}}>Total Distance:</b> {distanceLbl}</p> */}
                      {/* {windowDistancesAvailable && <p className={classes.nestedTxt}><b style={{ marginRight: '40px'}}>Current Distance:</b>{windowDistances[0][1].toFixed(3)} miles</p>}
                      {!windowDistancesAvailable && !windowDistance && <p className={classes.nestedTxt} ><b style={{ marginRight: '40px'}}>Current Distance:</b>{distanceLbl}</p>}
                      {!windowDistancesAvailable && windowDistance && <p className={classes.nestedTxt} ><b style={{ marginRight: '35px'}}>Current Distance:</b> 0 miles</p>} */}
                      <p className={classes.nestedTxt}><b style={{ marginRight: '77.5px' }}>Start Date:</b> {startDate}</p>
                      <p className={classes.nestedTxt}><b style={{ marginRight: '82px' }}>End Date:</b> {endDate}</p>
                      {/* <p className={classes.nestedTxt}><b style={{ marginRight:'112px'}}>App:</b> {appInfo}</p> */}
                      {/* <p className={classes.nestedTxt}  style={{marginBottom: '0px'}}><b style={{ marginRight: '49px'}}>Healing Status:</b> {percentageLabel}</p> */}
                    </div>
                  </div>
                  :
                  <div style={{ backgroundColor: '#2843A3' }}>
                    <React.Fragment>
                      <CustomizedExpansionPanel
                        workers={workers}
                        historyRoute={jobTraces[index]}
                        index={index}
                        windowDistances={windowDistances}
                        windowDistance={windowDistance}
                        jobInfo={jobInfo} />
                    </React.Fragment>
                  </div>
                }
                <div
                  id="webNavv-history-traceViewer-timeWindow-1.0"
                  style={{ borderRadius: '25px 25px 0px 0px', width: '100%', backgroundColor: 'white', height: '70vh' }}
                >
                  <div><h3 style={{ color: '#2843A3', marginLeft: '20px', paddingTop: '20px' }}>Time Window</h3></div>
                  <div id="timeWindow">
                    <TimeWindowSlider
                      timeWindow={props.setTimeWindow}
                      resetHistoryTrace={props.resetHistoryTrace}
                      defaultValues={{ "startDate": newStartDate, "endDate": newEndDate }}
                      setWindowDistanceFlag={props.setWindowDistanceFlag}
                      animationView={props.animationView} />
                  </div>
                  <div><h3 style={{ color: '#2843A3', marginLeft: '20px' }}>Map Information</h3></div>
                  <div>
                    <div style={{ display: 'block' }}><b className={classes.nestedSubtitle}>ANNOTATIONS:</b></div>
                    <div style={{ width: '15vw', marginTop: '1vw', marginBottom: '1vw', marginLeft: '2vw', display: 'block' }}>
                      {props.animationView ?
                        <React.Fragment>
                          <Chip
                            className={classes.nestedChip}
                            icon={<LocationOnIcon className={classes.icon} />}
                            label="Job start"
                          />
                          <Chip
                            className={classes.nestedChip}
                            icon={<LocationOnIcon className={classes.iconEnd} />}
                            label="Job end"
                          />
                        </React.Fragment>
                        : <React.Fragment>
                          <Chip
                            className={classes.nestedChip}
                            icon={<LocationOnIcon className={classes.icon} />}
                            onClick={() => props.handleCenterStartAnnotation()}
                            label="Job start"
                          />
                          <Chip
                            className={classes.nestedChip}
                            icon={<LocationOnIcon className={classes.iconEnd} />}
                            onClick={() => props.handleCenterEndAnnotation()}
                            label="Job end"
                          />
                        </React.Fragment>
                      }
                    </div>
                    <div style={{ display: 'block' }}><b className={classes.nestedSubtitle}>FILTERS:</b></div>
                    <div style={{ width: '30vw', marginTop: '1vw', marginBottom: '1vw', marginLeft: '2vw', display: 'flex' }}>
                      <div style={{ display: 'inline', paddingTop: '7px' }}><Checkbox onCheck={props.handleActiveFloor} checked={props.activeFloor} /></div>
                      <div style={{ display: 'inline' }}><p style={{ fontSize: '13px' }}>Current Floor Only</p></div>
                      <div style={{ display: 'inline', marginLeft: '20px', paddingTop: '7px' }}><Checkbox onCheck={props.handleWifiFilter} checked={props.wifi} /></div>
                      <div style={{ display: 'inline' }}><p style={{ fontSize: '13px' }}>Connection</p></div>
                    </div>
                  </div>
                  <div ><h3 style={{ color: '#2843A3', marginLeft: '20px' }}>Downloads</h3></div>
                  <div style={{ width: '30vw', marginLeft: '1vw', display: 'flex', height: '6vh' }}>
                    <div style={{ display: 'inline' }}>
                      <MasterButton
                        keyLbl={'download-geojson'}
                        label={'GEOJSON'}
                        buttonType={'success'}
                        isDisabled={false}
                        handleClick={props.copyGeoJSON}
                        size={"medium"}
                        startIcon={'copy'}
                        class={classes.containedBtn}
                      />
                    </div>
                    <div style={{ display: 'inline', marginLeft: '20px' }}>
                      <MasterButton
                        keyLbl={'download-geojson'}
                        label={'CSV'}
                        buttonType={'success'}
                        isDisabled={false}
                        handleClick={props.generateCSVFile}
                        size={"medium"}
                        startIcon={'download'}
                        class={classes.containedBtn}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          } else {
              return null;
          }
    }

    return(
        <div>
            {handleRenderInfo()}
        </div>
    )
}

export default UserJobHistory;