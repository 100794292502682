export const SUCCESS='success';
export const WARNING='warning';
export const DEVICE_CATEGORY='deviceCategory';
export const TEAM='team';
export const EMPTY_FIELD_MSG="Field can't be empty!";
export const KEYS= ['deviceCategory', 'controlNum', 'tagNum', 'model', 'maker', 'team', 'department'];
export const UPDATE_MSG='Equipment updated successfully!';
export const UPDATE_MSG_ERROR="Equipment couldn't be updated!";
export const UPDATE_ACTION='Update Equipment';
export const UPDATE_ACTION_ERROR='Update Equipment Error';
export const BACK_MSG="Back to Equipment table";
export const TAG_NUMBER='Tag Number:';
export const LOCATION="Location:";
export const CSV_CONFIGS={
    fileName:'EquipmentInfo',
    defaultName:'EquipmentInfo.csv',
    extension:'.csv',
    blobType:'text/csv;charset=utf-8;'
};