import React from 'react';
import './resetPassword.css';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from 'axios';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

class ResetPassword extends React.Component {
    state={
        email:'',
        status: 0,
        invalidEmail: false,
    }
    
    setInputState = (e,input) =>{
        this.setState(
            input==='email'?{email:e.target.value}:{}
        );
    }

    render(){
        return (
            <div className="reset-form">
                <p className="reset-header">Insert your email to send you the reset url</p>
                <div>
                    {this.state.invalidEmail &&
                        <TextField tabIndex="1" hintText="Reset Address" floatingLabelText="Email Address" 
                        onChange={(e) => {this.setInputState(e, 'email'); this.setState({status:0});}} 
                        errorText="Invalid email"/>
                    }
                    {!this.state.invalidEmail &&
                        <TextField tabIndex="1" hintText="Reset Address" floatingLabelText="Email Address" 
                        onChange={(e) => {this.setInputState(e, 'email'); this.setState({status:0});}} />
                    }
                    <br />
                    <RaisedButton className="right-mid-margin" onClick={()=>this.sendEmail()}>Send!</RaisedButton>
                    {this.state.status === 1 &&
                        <p className="errorParagraph">There was an error!</p>
                    }
                    {this.state.status === 2 &&
                        <p className="successParagraph">Email sent to {this.state.email}!</p>
                    }
                </div>
            </div>
        );
    }
    sendEmail(){
        if(this.state.email.length>0){
            this.setState({status:0});
            if(this.validateEmail(this.state.email)){
                this.setState({invalidEmail:false})
                try{
                    axios({
                        method:'post',
                        url:secureUrl.hostUrl+'/api/accounts/reset',
                        data:{
                            email:this.state.email
                        }
                    }).then((response)=>{
                        if(response.status >= 200 && response.status <= 300){
                            this.setState({status:2});
                        }
                    })
                }catch(error){
                    this.setState({status:1});
                    console.error("[RESETPASSWORD] An error ocurred in sendEmail: ", error.message);
                }
            } else {
                this.setState({invalidEmail:true})
            }
        }
    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
export default ResetPassword;