import React, {useState, useEffect} from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from "@material-ui/core/Tooltip";
import { jobTracesStyles, CustomCheckbox } from '../styles';

export default function JobHistoryTraces(props) {
  const classes = jobTracesStyles();
  const [state, setState] = useState(props.checkBoxJob);
  const [selectedJob, setSelectedJob]= useState(0);
  const [fromJob, setFromJob]= useState(0);
  const [toJob, setToJob]= useState(5);
  const [showJobButtons, setShowJobButtons] = useState(false);
  let jobList=[];

  useEffect(()=>{
    if(props.jobTotal<=5) setShowJobButtons(false)
    else setShowJobButtons(true)
  });

  useEffect(()=>{
    hideJobTrace();
  },[state]);

  const hideJobTrace=()=>{
    let jobsToHide=[];
    state.map(job=>{
        if(job[1]===false) {
          jobsToHide.push(job[0]);
        }
        return null; 
    });
    
    if(jobsToHide.length === 0)
      props.resetJobsShown();
    else
      props.updateJobsShown(jobsToHide);
  }

  const resetJobTraces=()=>{
    setState(props.checkBoxJob);
    props.resetJobsShown();
  }

  const handleChange = (i,event) => {
    let newArr = [...state];
    newArr[i] = [state[i][0], event.target.checked];
    setState(newArr);
  };

  const handleChangeJobinfo = (job)=>{
    props.handleChangeCardInfo(job);
    setSelectedJob(job);
    props.currentSelectedJob(props.jobs[job]);
  }

  const handleJobList =()=>{
    jobList= [];
    props.jobs.map((job, i)=>{
        jobList.push(
          <div key={i} style={{display:'flex'}}>
            <CustomCheckbox color="primary" checked={state[i][1]} onChange={(e)=>handleChange(i,e)} name={state[i][0]} />
            <p className={selectedJob===i? classes.labelLinkSelected:classes.labelLink} onClick={()=>{handleChangeJobinfo(i)}} style={{fontSize:'13px'}}>{"Job "+(i+1)}</p>
          </div>
        )
        return null;
    })
  }

  const renderProperJobAmount=()=>{
    handleJobList();
    let list= [];
    for (let i = fromJob; i < toJob; i++) {
      list.push(jobList[i]);
    }
    return list;
  }

  const changeForward =()=>{
    let newFrom = fromJob+5;
    let newTo = toJob+5;
    if(newFrom >props.jobTotal){
      setToJob(5)
      setFromJob(0)
    }else{
      setFromJob(newFrom);
      setToJob(newTo);
    }
    renderProperJobAmount();
  }

  const changeBackwards =()=>{
    let newFrom = fromJob-5;
    let newTo = newFrom + 5;
    if(newFrom < 0){
      let diff = props.jobTotal%5;
      let toUpdate = props.jobTotal;
      let fromUpdate = props.jobTotal- diff;
      setToJob(toUpdate);
      setFromJob(fromUpdate);
    }else{
      setFromJob(newFrom);
      setToJob(newTo);
    }
    renderProperJobAmount();
  }

  return (
    <React.Fragment>
    <div className={classes.root}>
      {showJobButtons?
      <React.Fragment>
        <IconButton className={classes.iconButton} onClick={()=>changeBackwards()}>
          <ArrowBackIosIcon className={classes.iconJob} />
        </IconButton>
        <div className={classes.multipleJobBar}>
        {renderProperJobAmount()}
        </div>
          <IconButton className={classes.iconButton} onClick={()=>changeForward()}>
          <ArrowForwardIosIcon className={classes.iconJob} />
        </IconButton>
      </React.Fragment>
      :
      <div className={classes.defaultJobBar}>
      {renderProperJobAmount()}
      </div>
     }
      <Tooltip title={"Reset Job Data"}>
        <IconButton onClick={()=>resetJobTraces()}>
          <CachedIcon className={classes.iconRefresh} />
        </IconButton>
      </Tooltip>
    </div>
   </React.Fragment>
  );
}
