import {
  LIST_USER,
  LIST_USER_INFO,
  USER_ERROR,
  CREATE_USER,
  PATCH_USER,
  DELETE_USER,
  LOGGED_USER,
  UPDATE_USER,
  SAVE_USER,
  LIST_USER_PAGINATION, 
  GET_USERS} from './types';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

const USER_INFO = "userInfo";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (count) => {
    console.info(`numbers of attempts: ${count}`);
    return count * 2000;
  }
});
export const listUsersPagination = (page,rows) => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
    try{
      axios.get(secureUrl.hostUrl + '/api/accounts/usersPagination?page='+page+'&rows='+rows+'&campusId=' + encodeURIComponent(`${getState().auth.userInfo.campusId}`) + '&access_token=' + getState().auth.token)
      .then((resp)=>{
        dispatch({
          type: LIST_USER_PAGINATION,
          payload: resp.data.users
        });
        resolve({success:true})
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in listUsersPagination: ", error);
      reject({success:false})
    }
  });
};

export const listUsers = () => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
      const {campusId} = JSON.parse(localStorage.getItem(USER_INFO)) || getState().auth.userInfo;
      if(!!campusId){
        try{
          axios.get(secureUrl.hostUrl + '/api/accounts/getUsersByCampus?campusId=' + encodeURIComponent(`${campusId}`) + '&access_token=' + getState().auth.token)
          .then((resp)=>{
            //let users=resp.data.users.filter(u=>u.enabled==true);
            handleCreateObjectUser(resp.data.users, dispatch);
            dispatch({
              type: LIST_USER,
              payload: resp.data.users
            });
            resolve({success:true})
          });
        }catch(error){
          console.error("[USER ACTIONS] An error ocurred in listUsers: ", error);
          reject({success:false})
        }
      }else{
        console.error("[USER ACTIONS] Couldn't get usersInfo due campusId is undefined");
        //window.location.href = "/"; 
        reject({success:false});
      }
  });
};

export const listUserInfo = (userId) => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
    try{
      axios.get(secureUrl.hostUrl + '/api/accounts/' + encodeURIComponent(`${userId}`) + '?access_token=' + getState().auth.token)
      .then((resp)=>{
        dispatch({
          type: LIST_USER_INFO,
          payload: resp.data[0]
        });
        resolve({success:true})
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in listUserInfo: ", error);
      reject({success:false})
    }
  });
};

export const getAllUsers = () => (dispatch, getState) => {
  return new Promise((resolve,reject)=>{
    try{
      axios.get(secureUrl.hostUrl + '/api/accounts?access_token=' + getState().auth.token)
      .then((resp)=>{
        dispatch({
          type: GET_USERS,
          payload: resp.data
        });
        resolve({success:true})
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in getAllUsers: ", error);
      window.location.href = "/";
      reject({success:false})
    }
  })
};

const handleCreateObjectUser = (users, dispatch)=>{
  let usersObj = {};
  users.forEach(user=>{
    let userId = !user.id ? user._id : user.id;
    user.name = `${user.first_name} ${user.last_name}`;
    usersObj[userId] = {...user};
  });
  dispatch({
    type: SAVE_USER,
    payload: usersObj
  });
};

export const createUser = (user) => async (dispatch, getState) => {
  try {
    let webToken = getState().auth.token || getState().auth.tokenMK;
    let checkDup = await checkDupUser(user.email, webToken);
    let create = !checkDup.success;
    return new Promise((resolve, reject) => {
      if (create) {
        axios.post(`${secureUrl.hostUrl}/api/accounts?access_token=${webToken}`, user).then(resp => {
          let users = getState().user.users;
          users.push(resp.data);
          dispatch({
            type: CREATE_USER,
            payload: users
          });
          resolve(true);
        }).catch((error) => {
          console.error("[USER ACTIONS] An error ocurred in createUser: ", error);
          dispatch({
            type: USER_ERROR,
            payload: {
              dialogError: `${error}`
            }
          });
          reject(false);
        });
      } else {
        resolve({ message: 'duplicated user' });
      }
    });
  }
  catch (error) {
    console.error("[USER ACTIONS] An error ocurred in createUser: ", error);
    dispatch({
      type: USER_ERROR,
      payload: {
        dialogError: `${error}`
      }
    });
  }
};

const checkDupUser = (email, token)=>{
  return new Promise((resolve, reject)=>{
    try{
      axios.get(`${secureUrl.hostUrl}/api/accounts/searchByEmail?email=${email}&webtoken=${token}`)
      .then((resp)=>{
        let {data} = resp.data;
        resolve({success: !!data && !!Object.keys(data).length, data});
      }).catch(e=>{
        console.error("[USER ACTIONS] An error ocurred in checkDupUser: ", e);
        reject(e);
      });
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in checkDupUser: ", error);
      reject(error);
    }
  });
};

export const patchUser = (userUpdateId, user) => async(dispatch, getState) => {
  let webToken = getState().auth.token || getState().auth.tokenMK;
  let checkDup = await checkDupUser(user.email, webToken);
  let update = (!checkDup.success || (checkDup.success && checkDup.data.user.id == userUpdateId) );
  return new Promise((resolve, reject)=>{
    if (update) {
      try{
        axios.patch(secureUrl.hostUrl + '/api/accounts/' + userUpdateId + '?access_token=' + webToken, user).then(resp=>{
          if (resp.data.id.length > 0) {
            let users = getState().user.users.map(e=>{
              if (e._id === resp.data._id) {
                return {...resp.data};
              }
              return e;
            });
            dispatch({
              type: PATCH_USER,
              payload: users
            });
            resolve(true);
          }
        })
      }catch(error){
        console.error("[USER ACTIONS] An error ocurred in patchUser: ", error);
        reject(error);
      }
    } else {
      resolve({message: 'duplicated user'});
    }
  });
};

export const deleteUser = (userId, campusId) => (dispatch, getState) => {
  let webToken = getState().auth.token || getState().auth.tokenMK;
  return new Promise((resolve, reject)=>{
    try{
      axios.delete(secureUrl.hostUrl + '/api/accounts/deactivate?userId=' + userId + '&enable=false&campusId=' + campusId + '&access_token=' + webToken).then(resp=>{
        let users = getState().user.users;
        users = users.filter(u=>u._id !== userId);
        dispatch({
          type: DELETE_USER,
          payload: users
        });
        resolve(true);
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in deleteUser: ", error);
      reject(error);
    }
  });
};

export const setAccountStatus = (userId, user) => (dispatch, getState) => {
  return new Promise((resolve, reject)=>{
    try{
      axios.patch(secureUrl.hostUrl + '/api/accounts/' + userId + '?access_token=' + getState().auth.token, user).then(resp=>{
        let users = getState().user.users;
        let objIndex = users.findIndex((obj => obj._id == userId));
        if (objIndex !== -1) {
          users[objIndex].enabled = user.enabled;
          dispatch({
            type: PATCH_USER,
            payload: users
          });
        }
        resolve(true);
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in setAccountStatus: ", error);
      reject(error);
    }
  });
};

export const loggedUser = (user)=>((dispatch, getState)=>{
  dispatch({
    type: LOGGED_USER,
    payload: user
  });
});

export const forceLogout = (userId,expiredBy)=>(dispatch, getState)=>{
  return new Promise((resolve, reject)=>{
    try{
      axios.post(secureUrl.hostUrl + '/api/accounts/forceLogout?userId=' + userId + '&actionUserId=' + expiredBy + '&access_token=' + getState().auth.token
      ).then(resp=>{
        if (resp !== null)
          resolve(true);
      })
    }catch(error){
      console.error("[USER ACTIONS] An error ocurred in forceLogout: ", error);
      reject(error);
    }
  });
};

export const updateUser = (user)=>((dispatch)=>{
  dispatch({
    type: UPDATE_USER,
    payload: user
  });
});