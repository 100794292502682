import React, {useEffect, useState, useRef} from 'react';
import { connect } from "react-redux";
import IDMMaps  from './IDM_mapkit/indoorMap';
import AwesomeSpinner from '../../../utilities/spinner';
import _ from 'lodash';

function PreviewMap(props) {
  const mounted = useRef();
  const [mapkit, setMapkit] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (!mounted.current) {
    //componentDidMount
      setMapkit(new IDMMaps());
    }
    mounted.current = true;
  });

  useEffect(()=>{
    if (_.isEmpty(props.campusSelected) && !_.isEmpty(mapkit))
      mapkit.handleMapDefault();
  }, [props.newCampus]);

  useEffect(()=>{
    if (!_.isEmpty(props.campusSelected)  && !_.isEmpty(mapkit)) {
      let allFiles = false;
      if(props.campusSelected.venue && props.campusSelected.unit && props.campusSelected.opening && props.campusSelected.level)
        allFiles = true;
      if(allFiles && !props.updateSelected){
        setShowSpinner(true);
        let config = {
          floor: props.campusSelected.config.default_floor,
          token: props.token,
          campusId: props.campusSelected.id,
          filesAvailable: allFiles
        };
        mapkit.handleConfigMap(config);
         setTimeout(()=>{
            mapkit.updateRotation(parseFloat(props.campusRotation));
            setShowSpinner(false);
            props.setShowButtons(true);
          }, 4000);
      }else{
        props.setShowButtons(true);
      }
    }
    if (_.isEmpty(props.campusSelected) && !_.isEmpty(mapkit)) {
      mapkit.handleMapDefault();
    }
  }, [props.campusSelected]);

  useEffect(()=>{
    if (!!props.campusZoom && !_.isEmpty(mapkit) && (!_.isEmpty(props.campusSelected) || props.newCampus))
      mapkit.updateSpan(props.campusZoom, props.campusRotation);
  }, [props.campusZoom]);

  useEffect(()=>{
    if (!!props.campusFloor && !_.isEmpty(mapkit) && !_.isEmpty(props.campusSelected) && !props.newCampus) {
      if (document.getElementById(`preview-${props.campusFloor}`))
        document.getElementById(`preview-${props.campusFloor}`).click();
    }
  }, [props.campusFloor]);

  useEffect(()=>{
    if (!!props.campusLatitude && !!props.campusLongitude && !_.isEmpty(mapkit) && (!_.isEmpty(props.campusSelected) || props.newCampus)) {
      mapkit.updateCoordinates(props.campusLatitude, props.campusLongitude, props.campusRotation);
    }
  }, [props.campusLatitude, props.campusLongitude]);

  useEffect(()=>{
    if (!!props.campusRotation && !_.isEmpty(mapkit) && (!_.isEmpty(props.campusSelected) || props.newCampus))
      mapkit.updateRotation(props.campusRotation);
  }, [props.campusRotation]);

  return (
    <div id="webNavv-settings-campusSettings-mapPreview-1.0">
      <div id="previewMap" style={{height: '65vh', width: '70vh', display: 'block'}}/>
      <AwesomeSpinner config={'default'} loading={showSpinner} navOpen={props.open}/>
    </div>
  );
};

const mapStateToProps = state => ({
  access_token: state.auth.token,
  campusInfo: state.auth.userInfo.campusInfo,
  webAccessTK: state.auth.tokenMK,
  token: state.auth.token,
  open:state.views.drawerOpen
});

export default connect(mapStateToProps, {})(PreviewMap);
