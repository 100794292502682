import _ from "lodash";
import { mutationUpdateResourceSlot } from "../../../graphql/mutations";
import { jobStatusTypesGraphQL } from "../../jobsBoard.constants";
import { handleRemoveFirstRowFromGroups, handleUpdateGroupIdPosition, handleSortRowsByUserName } from "../jobsBoard";

export const handleAssignUserToSlot = async(items, groups, groupId, assignedTo, jobStatusList, tenantId) =>{
  const currentSlot = groups.find(group=>group.id === groupId);
  const userAssignedToSlot = await mutationUpdateResourceSlot(currentSlot.resource_slotid, assignedTo);

  if (userAssignedToSlot) {
    return "done";
  }
  else {
    return "error";
  }
}

export const handleAfterAssignUserToSlot = (groups, updateSlot, editBoard) =>{
  const newArray = groups.map(
    group =>
      group.resource_slotid === updateSlot.resource_slotid
        ? Object.assign({}, group, {
            title: updateSlot.assigned_user_name,
            assigned: true,
            assignedId: updateSlot.assigned_userid,
            status: jobStatusTypesGraphQL.assigned,
          })
        : group
  );

  if (editBoard) {
    let firstRow = newArray[0];
    const groupsWithoutFirstRow = handleRemoveFirstRowFromGroups(newArray);
    const groupsSortedByUserName =  handleSortRowsByUserName(groupsWithoutFirstRow);
    const groupsWithFirstRow = [firstRow, ...groupsSortedByUserName];
    const groupsCopy = handleUpdateGroupIdPosition(groupsWithFirstRow, editBoard);
    return groupsCopy;
  }
  else{
    const groupsSortedByUserName =  handleSortRowsByUserName(newArray);
    const groupsCopy = handleUpdateGroupIdPosition(groupsSortedByUserName, editBoard);
    return groupsCopy;
  }
}
