import React from 'react';

import Subheader from 'material-ui/Subheader';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';

import {filterStyles} from '../styles';
import * as CONSTS from "./filters.constants";

const EquipmentFilters = (props) =>{

    return(
    <React.Fragment>
        <Subheader>{CONSTS.FILTERS}</Subheader>
        <Divider/>
        <Subheader>{CONSTS.ACTIONS}</Subheader>

    </React.Fragment>
    );
}


export default withStyles(filterStyles)(EquipmentFilters);