export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const GET_ACL = 'GET_ACL';
export const TOKEN = 'TOKEN';
export const SOCKETIO = 'SOCKETIO';
export const CAMPUS_LOGIN = 'CAMPUS_LOGIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const ADMIN_TOKEN = 'ADMIN_TOKEN';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const LOAD_CONFIG = 'LOAD_CONFIG';
export const SET_FEATURE_FLAGS  = 'SET_FEATURE_FLAGS';
export const LASTLOCATION_CONN = "LASTLOCATION_CONN";