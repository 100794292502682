import React from "react";

import { withStyles } from '@material-ui/core/styles';

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import isEmpty from 'lodash/isEmpty';


import Button from "@material-ui/core/Button";
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';

import miniLogo from "./hospital-icons/Logo.png";
import campusIcon from './hospital-icons/hosp-btn-blue.png';
import { ReactComponent as SuperBarIcon } from './hospital-icons/superBarIcon.svg';
import "./superAdminSelect.css";
import { CAMPUS_LIST } from './superAdmin.constants';


import { campusLogin } from "../../actions/auth/authActions";
import { sendLoginAdminParams, allUsers, getSuperAdmins, setAdminToken } from '../../actions/superAdmin/superAdminActions';
import { createLog } from '../../actions/logs/logsActions';
import { getCampuses } from "../../actions/campus/campusActions";
import { fetchAllACLS, fetchRoles } from '../../actions/acl/aclActions';
import { handleGetUser } from '../../actions/superAdmin/superAdminActions'
import { dispatch } from 'rxjs/internal/observable/pairs';
import { SET_USER } from './../../actions/superAdmin/types';

const ADMIN_TOKEN = "adminToken";
const ADMIN_INFO = "adminInfo";

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    //position: 'absolute',
    minHeight: 400,
    //justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 220,
  },
  logo: {
    width: 300,
    height: 100
  },
  campusContainer: {
    display: 'grid',
  },
  buttonCampus: {
    margin: theme.spacing(1),
    background: '#F8F8F8',
    border: '0.5px solid #979797',
    borderRadius: 10,
    height: 47,
    width: 317,
    textTransform: 'none',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 15
    },
    '& .MuiIcon-root': {
      width: 30,
      height: 30,
    },
    '&:hover': {
      backgroundColor: '#2196F3',
      color: '#F8F8F8',
    }
  },
  buttonPanel: {
    margin: theme.spacing(1),
    backgroundColor: '#2843A3',
    width: 317,
    height: 47,
    color: '#F8F8F8',
    border: '0.5px solid #979797',
    borderRadius: 10,
    textTransform: 'none',
    marginBottom: 30,
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 15
    },
    '&:hover': {
      backgroundColor: '#2843A3',
      color: '#F8F8F8',
    }
  },
  divider: {
    marginTop: 30,
  },
  superSelect: {
    display: 'flex',
    justifyContent: 'center',
    width: 414,
    minHeight: 600,
    position: 'absolute',
    top: 40,
    background: '#FFFFFF',
    border: '1px solid #EFF4FA',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8
  }
});

class SuperAdminSelect extends React.Component {

  state = {
    superAdminParam: false,
    readyToLoad: false
  }

  componentDidMount() {
    const { match, history } = this.props;
    
    if (Object.values(match.params).length > 0
      && !!match.params.id) {
      const { id, token } = match.params;
      if (!!token) {
        localStorage.setItem("adminToken", token);
        localStorage.setItem("adminId", id);
        if (!!id) {
          this.setAdminData(id);
        }
      }
    }
    else if (localStorage.getItem("adminId")) {
      const adminId = localStorage.getItem("adminId");
      this.setAdminData(adminId );
    }
    else {
      history.push('superAdminLogin');
    }
  }

  setAdminData(id) {
    const { getCampuses, allUsers, fetchAllACLS, getSuperAdmins, fetchRoles, handleGetUser } = this.props;
    handleGetUser({ data: { userId: id } }).then(res => {
      console.log(`[SUPERADMINSELECT][componentDidMount] Super admin user updated in the store: ${res}`);
      Promise.all([getCampuses(), allUsers(), fetchAllACLS(), getSuperAdmins(), fetchRoles()]).then(values => {
        console.log(`[SUPERADMINSELECT][componentDidMount] Super admin data updated in the store: ${res}`);
        this.setState({ readyToLoad: true });
      });
    });
  }

  handlebtn = (account) => {
    this.props.campusLogin(account).then(result => {
      let userName = account.username.substring(0, account.username.lastIndexOf("_"));
      let campus = this.props.campusList.find(c => c.id === account.campusId);
      let campusName = campus ? campus.alias : '';
      let logData = {
        campusId: account.campusId,
        action: 'Login',
        description: `Web - Successful autologin using username: ${userName}, campus: ${campusName}, device_id: desktop`,
        timestamp: new Date(),
        userId: account.id
      };
      this.props.createLog(logData);
      this.props.history.push('/dashboardCampus');
    });
  };
  componentDidCatch(error, info) {
    console.error("[SUPERADMINSELECT]",error);
    this.props.history.push('/superAdminLogin');
  }

  isCampusListIsEmpty = () => {
    this.props.getCampuses();
  }

  render() {
    if (this.state.readyToLoad) {
      const hospitals = this.props.user.accounts;
      const { classes, campusList } = this.props;
      const { accounts } = this.props.user;
      const { selectTitle, panelTitle, navvTrackTitle, panelPath } = CAMPUS_LIST;

      if (!!this.props.campusList && !this.props.campusList.length) {
        this.isCampusListIsEmpty();
      }

      const getCampusName = (id) => {
        const name = campusList.filter((campus) => campus.id === id)[0].name;
        return name;
      }

      const renderHospitals = (accounts) => {
        let hospitalButtons = null;

        if (!!hospitals && !!this.props.campusList.length) {
          hospitalButtons = accounts.filter(acc => campusList.filter(c => c.id === acc.campusId).length > 0).map((account, i) => {
            const name = getCampusName(account.campusId);

            return (
              <Button
                key={`campus-selector-key-${i}`}
                className={classes.buttonCampus}
                startIcon={<Icon><img src={campusIcon} height={30} width={30} /></Icon>}
                onClick={() => this.handlebtn(account)}
              >
                <span>{` ${name} `}</span>
              </Button>
            );
          });
        }
        return hospitalButtons;
      };

      const userReady = !isEmpty(this.props.user);
      return (
        <div className="superAdmin">
          <div
            id="webNavv-superAdmin-menu-1.0"
            className={classes.superSelect}
          >
            <img src={miniLogo} className="logo" />
            <p className="select-hospital"> {selectTitle} </p>
            <div className={classes.buttonContainer}>
              <div
                id="webNavv-superAdmin-menu-selectHospital-1.0"
                className={classes.campusContainer}
              >
                {userReady ? renderHospitals(accounts) : null}
              </div>
              <Button
                id="webNavv-superAdmin-menu-adminPanelButton-1.0"
                key="keyPanel"
                //style={{visibility:'hidden'}}
                className={classes.buttonPanel}
                startIcon={<SuperBarIcon style={{ margin: '0 20 0 20' }} />}
                onClick={() => this.props.history.push(panelPath)}
              >
                {panelTitle}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    else return null;
  }
}

const mapStateToProps = (state) => ({
  user: state.superAdmin.user,
  campusList: state.campus.campusList,
  auth: state.auth
});

export default withStyles(styles)(
  connect(mapStateToProps, { campusLogin, createLog, getCampuses, allUsers, fetchAllACLS, getSuperAdmins, sendLoginAdminParams, setAdminToken, handleGetUser, getCampuses, fetchRoles })(withRouter(SuperAdminSelect))
);
