import React from 'react';
import { connect } from "react-redux";

import PhoneModels from './phoneModels/modelPhones.component';
import MapConfig from './map/mapConfig.component';
import UserAccount from './account/account.component';
import EquipmentCategory from './equipment/equipment.component';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F9FBFE',
    display: 'flex',
    height: '100%',
  },
  tabs: {
    marginLeft: '10%',
    marginTop: '10%',
    height: '80vh',
    alignItems: 'left'
  },
  indicator: {
    backgroundColor: '#2196F3'
  },
  wrapper: {
    alignItems: "flex-start"
  },
  selected: {
    color: '#2196F3'
  },
  tabPanel: {
    borderLeft: '1.5px solid #E5E5E5',
    width: '80%',
    marginTop: '5%'
  }
}));

function SettingsMenu(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userHasAccessToEquipment = props.aclViews[8].access && props.featureFlags.Equipment.EquipmentCategories;

  return (
    <div
      id="webNavv-settings-mainSettingsNav-1.0"
      className={classes.root}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        classes={{ indicator: classes.indicator }}
        className={classes.tabs}
      >
        <Tab
          id="webNavv-settings-mainSettingsNav-campusTab-1.0"
          label="Campus"
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected}}/>
        <Tab
          id="webNavv-settings-mainSettingsNav-accoutTab-1.0"
          label="Account"
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected
          }} />
        {userHasAccessToEquipment && (
          <Tab
          id="webNavv-settings-mainSettingsNav-equipmentTab-1.0"
          label="Equipment"
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected}}/>
        )}
        <Tab
          id="webNavv-settings-mainSettingsNav-phonesTab-1.0"
          label="Phones"
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected
          }} />
      </Tabs>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <MapConfig />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <UserAccount />
      </TabPanel>
      {userHasAccessToEquipment && (
        <TabPanel className={classes.tabPanel} value={value} index={2}>
          <EquipmentCategory />
        </TabPanel>
      )}
      <TabPanel className={classes.tabPanel} value={value} index={userHasAccessToEquipment? 3 : 2}>
        <PhoneModels />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = state => ({
  users: state.user.users,
  aclViews: state.auth.acl.views,
  featureFlags: state.auth.featureFlags
});

export default connect(mapStateToProps, {})(SettingsMenu);