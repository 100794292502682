import moment from "moment";
import { mutationUpdateAssociatedJob } from "../../../graphql/mutations";
import { jobStatusTypesGraphQL} from "../../jobsBoard.constants";
import _ from 'lodash';
import { Done } from "@material-ui/icons";

export const handleItemMove = async (itemId, dragTime, newGroupOrder, items, groups, tenantId) => {

  const item = items.find(item=> item.id === itemId);
  const timeCanBeMoved = checkIfItemCanBeMoved(item, dragTime, newGroupOrder, items, groups);

  if (timeCanBeMoved) {
    const group = groups[newGroupOrder];

    const endTime = dragTime + (item.end_time - item.start_time);
    const newStartDate = moment(dragTime).utc().format();
    const newEndDate = moment(endTime).utc().format();
    
    const UpdateAssociatedJob_Input = {
      resource_slotid: group.resource_slotid,
      job_start_date: newStartDate,
      job_end_date: newEndDate,
      jobid:  item.jobid,
      tenantid: tenantId
    }
    const jobUpdated = await mutationUpdateAssociatedJob(UpdateAssociatedJob_Input);

    if (jobUpdated) {
      return { moveStatus: "done", jobMoved: true};
    }
    else {
      return { moveStatus: "error", jobMoved: true};
    }
  }

  return { moveStatus: "done", jobMoved: false}
};

export const checkIfItemCanBeMoved = (item, time, newGroupOrder, items, groups) =>{
    const currentSlot = groups[newGroupOrder];
    const currentItems = items.filter(i=> i.group === currentSlot.id);
    let canItemMove = true;

    currentItems.sort((a,b) => a.start_time > b.start_time ? 1 : -1);
    
    for (let index = 0; index < currentItems.length; index++) {
      if (currentItems[index].id !== item.id) {
        canItemMove = checkJobDragNDropScenarios(currentItems[index], item, time, currentSlot);
        if (!canItemMove){
          break;
        }
      }
    }

    if (canItemMove) {
      return true;
    }
    else {
      return false;
    }
}

export const checkJobDragNDropScenarios = (currentItem, item, time, currentSlot) =>{
    let newMoveDoesNotOverlapAnExistingStartDate = checkNewMoveDoesNotOverlapAnExistingStartDate(currentItem, time);
    let newMoveDoesNotOvelapAJob = checkNewMoveDoesNotOvelapAJob(currentItem, time);
    let newMoveDoesNotOverlapAJobEvenWithAGap = checkNewMoveDoesNotOverlapAJobEvenWithAGap(currentItem, time, item);
    // let newGroupToMoveIsNotBlocked = checkIfNewGroupToMoveIsBlocked(currentSlot);

    const canItemMove = newMoveDoesNotOverlapAnExistingStartDate && newMoveDoesNotOvelapAJob && newMoveDoesNotOverlapAJobEvenWithAGap;
    if (canItemMove) {
      return true;
    }
    else {
      return false;
    }
}

export const checkIfNewGroupToMoveIsBlocked = (slotToMove) =>{
  if (slotToMove.status === jobStatusTypesGraphQL.inProgress || slotToMove.status === jobStatusTypesGraphQL.inProgressWithError) {
    return false
  } 
  else {
    return true
  }
}

export const checkNewMoveDoesNotOverlapAnExistingStartDate = (currentItem, time) =>{
    if (currentItem.start_time === time){
      return false
    } 
    else {
      return true
    }
}

export const checkNewMoveDoesNotOvelapAJob = (currentItem, time) =>{
    let isNewTimeInRange = moment(time).isBetween(currentItem.start_time, currentItem.end_time);
    if (isNewTimeInRange){
      return false
    }
    else{
      return true
    }
}

export const checkNewMoveDoesNotOverlapAJobEvenWithAGap = (currentItem, time, item) =>{
    const endTime = time + (item.end_time - item.start_time);
    let startDateInTimeRange = moment(currentItem.start_time).isBetween(time,endTime);
    let endDateInTimeRange = moment(currentItem.end_time).isBetween(time,endTime);

    if (startDateInTimeRange || endDateInTimeRange){
      return false
    }
    else {
      return true
    }
}