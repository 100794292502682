import React from 'react';
import {useStyles} from './phoneComments.styles';
import moment from "moment";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SendIcon from '@material-ui/icons/Send';
import IconButton from 'material-ui/IconButton';
import Paper from '@material-ui/core/Paper';

import MasterButton from '../../../utilities/button.component';

function PhoneComments (props){
  const classes = useStyles();

  const renderComments=()=>{
    return(
        <React.Fragment>
            {props.phoneComments.length> 0 && !!props.users ?
            props.phoneComments.map((element,i) => {
                const userData = props.users.filter(user=>user._id === element.user_Id || user.id === element.user_Id);
                const initials = props.getUserInitials(userData[0].first_name,userData[0].last_name);
                let createDate = moment(element.date).format("MM/DD/YYYY HH:mm");

                return(
                <div className={classes.commentsSectionMainDiv} key={i}>
                    <div className={classes.commentTitleContainer}>
                        <div className={classes.commentInitialsContainer}>
                            <div className={classes.commentInitialsDot}>
                                <div><p className={classes.commentTitleInitialsBold}>{initials}</p></div>
                            </div>
                        </div>
                        <div className={classes.commentsSectionNameContainer}>
                            <p className={classes.commentUserName}>{userData[0].first_name+" "+userData[0].last_name}</p>
                        </div>
                        <div className={classes.commentsSectionDateContainer}>
                            <p className={classes.commentDate}>{createDate}</p>
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <p className={classes.comment}>{element.comment}</p>
                    </div>
                </div>
                )
            })
            :<div></div>
            }
        </React.Fragment>
    )
  }

  return(
    <React.Fragment>
    <div className={classes.commentContainer}>
        <div className={classes.commentTitleContainer}>
            <div className={classes.commentInitialsContainer}>
                <div className={classes.commentInitialsDot}>
                    <div><p className={classes.commentTitleInitials}>{props.initials}</p></div>
                </div>
            </div>
            <div className={classes.commentNameContainer}>
                <p className={classes.commentUserName}>{props.userInfo.first_name+" "+props.userInfo.last_name}</p>
            </div>
        </div>
       <TextareaAutosize
        rowsMax={6}
        className={classes.commentTextArea}
        aria-label="maximum height"
        placeholder="Add a comment..."
        onChange={(e)=>props.handleCommentOnChange(e.target.value)}
        value={props.comment}
        />
        <IconButton
          className={classes.sendIconButton}
          onClick={()=>{props.handleSendComment()}}>
            <SendIcon style={{fill:'#039be5'}}/>
        </IconButton>
     </div>
    <div className={classes.commentsSectionContainer}>
    <Paper className={classes.paper}>
       {renderComments()}
    </Paper>
    </div>
    <div className={classes.cancelBtnContainer}>
     <MasterButton
       keyLbl={'cancel-comment'}
       label={'Cancel'}
       buttonType={'default'}
       isDisabled={false}
       handleClick={props.handleCloseCommentsDialog}
       size={"medium"}
       class={classes.cancelBtn}
      />
    </div>
    </React.Fragment>
  );
}

export default (PhoneComments);