import React, { useState } from 'react';
import moment from "moment";

import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import FloatingActionButton from "material-ui/FloatingActionButton";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Drawer from 'material-ui/Drawer';

import { connect } from 'react-redux';
import AnimationPanel from './animationPanel/animationPanel.component';
import {
  changeAllCallouts,
  changeSmallCallouts,
  changeAllTraces,
  changeActiveFloor,
  changeActiveUser,
  changeTimeWindow,
  changeHideJobs,
  changeWifiFilter,
  changeCenterHistoryMap,
  changeView,
  setCurrentMenuValue,
  changeDrawer
} from '../../../actions/views/viewsActions';
import { generateUserJobCSV, generateJobCSV } from "./historyMap.constants";
import { historyMapStyles } from './styles';
import UserJobHistory from './userJobHistory/userJobHistory.component';
import JobHistory from './jobHistory/jobHistory.component';
import generatePDF from '../../utilities/generatePDF';
import { ERROR_TASKS } from './jobHistory/jobHistory.constants';

const HistoryInfo = (props) => {
  const { classes } = props;
  const [index, setIndex] = useState(0);
  const [showPanel, setShowPanel] = useState(true);
  const [windowDistance, setWindowDistance] = useState(false);
  const [windowDates, setWindowDates] = useState([]);
  const [currentJob, setCurrentJob] = useState(props.viewProps === 'history'? props.historyRoute[0].jobId : "");
  const [animationView, setAnimationView] = useState(false);
  const [animationDates, setAnimationDates] = useState([]);
  const [closeHistoryMap, setCloseHistoryMap] = useState(false);

  let windowStartDate = "";
  let windowEndDate = "";

  const handleActiveFloor = (ev, checked) => {
    props.changeActiveFloor(checked);
  };

  const handleWifiFilter = (ev, checked) => {
    props.changeWifiFilter(checked);
  };

  const handleCurrentSelectedJob = (jobId) => {
    setCurrentJob(jobId);
  };

  const handleCenterStartAnnotation = () => {
    const jobStartAnnotation = 'startTag';
    props.changeCenterHistoryMap({ type: jobStartAnnotation, jobId: currentJob });
  };

  const handleCenterEndAnnotation = () => {
    const jobEndAnnotation = 'endTag';
    props.changeCenterHistoryMap({ type: jobEndAnnotation, jobId: currentJob });
  };

  const handleChangeCardInfo = (jobIndex) => {
    setIndex(jobIndex);
  };

  const setTimeWindow = (dates) => {
    setWindowDates(dates);
    props.changeTimeWindow(dates);
  };

  const resetHistoryTrace = () => {
    setWindowDates([]);
    props.changeTimeWindow([]);
  };

  const handleUpdateJobsShown = (jobsToHide) => {
    props.changeHideJobs(jobsToHide);
  };

  const resetJobsShown = () => {
    props.changeHideJobs([]);
    props.changeCenterHistoryMap('');
  };

  const copyGeoJSON = () => {
    navigator.clipboard.writeText(JSON.stringify(props.geoJSONJob, undefined, 4));
    props.handleOpenSnackBar();
  };

  const setWindowDistanceFlag = (value) => {
    setWindowDistance(value);
  };

  const handleUpdateTimeWindows = (newStartDate, newEndDate) =>{
    windowStartDate = newStartDate;
    windowEndDate = newEndDate;
  }

  const generateCSVFile = () =>{
    const {historyTracesForCSV, jobInfo, viewProps} = props;

    if ( viewProps === 'history') {
      generateUserJobCSV(historyTracesForCSV, jobInfo);
    }
    else {
      generateJobCSV(historyTracesForCSV, jobInfo);
    }
  }

  const handleErrorDescription = (errorNumber) =>{
    switch (errorNumber) {
        case ERROR_TASKS.scanOverride.key:
            return ERROR_TASKS.scanOverride.description;
        case ERROR_TASKS.locationSkipped.key:
            return ERROR_TASKS.locationSkipped.description;
        case ERROR_TASKS.jobCancelled.key:
            return ERROR_TASKS.jobCancelled.description;
        case ERROR_TASKS.jobStartDelay.key:
            return ERROR_TASKS.jobStartDelay.description;
        case ERROR_TASKS.scanError.key:
            return ERROR_TASKS.scanError.description;
        default:
            return errorNumber
    }
  }

  const generateTasksPDF = () =>{
    const { jobTasks, jobInfo } = props;
    let data = [];

    jobTasks.forEach(task=>{
      let taskTimestamp = !!task.task_start_date ? moment(task.task_start_date).format("hh:mm:ss A") : null;
      let taskDate = !!task.task_start_date ? moment(task.task_start_date).format("MMMM DD, YYYY") : null;

      if (!!task.job_error_number && task.job_error_number === ERROR_TASKS.jobCancelled.key) {
        taskTimestamp = moment(jobInfo[0].endTime).format("hh:mm:ss A");
        taskDate = moment(jobInfo[0].endTime).format("MMMM DD, YYYY");
      }
      
      const taskInfo = {
        jobName: jobInfo[0].jobName,
        jobNumber: jobInfo[0].jobNumber,
        locationName: task.location_name,
        timestamp: taskTimestamp,
        visited: task.task_status === 'Complete' || (task.task_status === 'Error' && !!task.job_error_number && task.job_error_number === ERROR_TASKS.scanError.key) ? true : false,
        employee: jobInfo[0].messenger,
        error: !!task.job_error_number? handleErrorDescription(task.job_error_number) : null,
        date: taskDate
      };

      data.push(taskInfo);
    });

    generatePDF('JH', data);
  }

  const returnToTableView = () => {
    if (props.viewProps === 'history') {
      setCloseHistoryMap(true);
      props.setCurrentMenuValue('HT');
      props.changeView('table', 'history');
    }else {
      setCloseHistoryMap(true);
      props.setCurrentMenuValue('WF');
      props.changeView('workflowAutomation', 'jobsHistory');
      props.changeDrawer();
    }
  };

  const redirectToActiveMap = () => {
    setCloseHistoryMap(true);
    props.changeView('map', '');
  };

  const hidePanel = (value) => {
    setShowPanel(value);
  };


  const panelVisible = () => {
    const { historyRoute, jobInfo, users, classes, jobTasks, wifi, activeFloor, viewProps, historyTracesForCSV, featureFlags, mapConfigs } = props;

    const jobInfoContainerStyle = { height: '100%', top: 0, left: 0, width: '500px', overflowX: 'hidden', backgroundColor: '#2843A3' };
    const jobsHistoryContainerStyle = { height: '100%', top: 0, left: 0, width: '500px', overflowX: 'hidden', overflowY: 'hidden', backgroundColor: '#2843A3' }
    const sidebarTitle = props.viewProps === 'history' ? 'Job Information' : 'Jobs History';
    const containerStyles = props.viewProps === 'history' ? jobInfoContainerStyle : jobsHistoryContainerStyle;
    
    return (
      <React.Fragment>
        <div
          id="webNavv-history-traceViewer-jobInformation-1.0"
          className={classes.mapFilterDiv}
        >
          <div style={{ display: 'inline', width: '500px' }}>
            <Drawer open={true} containerStyle={containerStyles}>
              <div>
                <div style={{ display: 'flex', backgroundColor: '#2843A3' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'inline' }}>
                      <Tooltip title="Back to Table">
                        <ArrowBackIcon className={classes.backIcon} onClick={() => { returnToTableView() }} />
                      </Tooltip>
                    </div>
                    <div style={{ display: 'inline', marginLeft: '10px' }}><h2 style={{ color: 'white' }}>{sidebarTitle}</h2></div>
                    <div style={{ display: 'inline', marginLeft: '22vh' }}>
                      <Tooltip title="Hide Panel">
                        <DehazeIcon className={classes.backIcon} onClick={() => { hidePanel(false) }} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {viewProps === 'history' ? 
                  <UserJobHistory
                    handleUpdateTimeWindows={handleUpdateTimeWindows}
                    generateCSVFile={generateCSVFile}
                    copyGeoJSON={copyGeoJSON}
                    resetJobsShown={resetJobsShown}
                    handleUpdateJobsShown={handleUpdateJobsShown}
                    setTimeWindow={setTimeWindow}
                    handleCurrentSelectedJob={handleCurrentSelectedJob}
                    handleWifiFilter={handleWifiFilter}
                    handleActiveFloor={handleActiveFloor}
                    handleChangeCardInfo={handleChangeCardInfo}
                    handleCenterStartAnnotation={handleCenterStartAnnotation}
                    handleCenterEndAnnotation={handleCenterEndAnnotation}
                    resetHistoryTrace={resetHistoryTrace}
                    setWindowDistanceFlag={setWindowDistanceFlag}
                    windowStartDate={windowStartDate}
                    windowEndDate={windowEndDate}
                    animationView={animationView}
                    classes={classes}
                    windowDates={windowDates}
                    windowDistance={windowDistance}
                    index={index}
                    animationDates={animationDates}
                    historyRoute={historyRoute}
                    jobInfo={jobInfo}
                    users={users}
                    activeFloor={activeFloor}
                    wifi={wifi}
                  /> 
                : <JobHistory
                    generateCSVFile={generateCSVFile}
                    generateTasksPDF={generateTasksPDF}
                    jobInfo={jobInfo}
                    jobTasks={jobTasks}
                    historyTracesForCSV={historyTracesForCSV}
                    featureFlags={featureFlags}
                    mapConfigs={mapConfigs}
                  />
                }
              </div>
            </Drawer>
          </div>
          <div className={!animationView ? classes.exitIconDiv : classes.exitIconAnimationDiv}>
            <Tooltip title="Back to Active Map">
              <CloseIcon className={classes.exitIcon} onClick={() => redirectToActiveMap()} />
            </Tooltip>
          </div>
          {!animationView && (
            <div
              id="webNavv-history-tracePlayer-collapsed-1.0"
              className={classes.animationIconDiv}
            >
              <FloatingActionButton backgroundColor={'#039be5'} className="add-button" onClick={() => setAnimationView(true)} >
                <SubscriptionsIcon />
              </FloatingActionButton>
            </div>
          )}
          {animationView && (
            <AnimationPanel
              viewProps={props.viewProps}
              showPanel={true}
              setAnimationView={setAnimationView}
              resetHistoryTrace={resetHistoryTrace}
              defaultValues={{ "startDate": windowStartDate, "endDate": windowEndDate }}
              setWindowDistanceFlag={setWindowDistanceFlag}
              setAnimationDates={setAnimationDates}
              closeHistoryMap={closeHistoryMap} />
          )}
        </div>
      </React.Fragment>
    )
  };

  const panelHidden = () => {
    return (
      <React.Fragment>
        <div className={classes.mapFilterDiv}>
          <div style={{ display: 'inline', width: '50px' }}>
            <div style={{ marginTop: '10vh' }}>
              <FloatingActionButton backgroundColor={'#2843A3'} className="add-button" onClick={() => hidePanel(true)} >
                <DehazeIcon />
              </FloatingActionButton>
            </div>
          </div>
          {!animationView && (
            <div className={classes.animationIconDiv}>
              <FloatingActionButton backgroundColor={'#039be5'} className="add-button" onClick={() => setAnimationView(true)} >
                <SubscriptionsIcon />
              </FloatingActionButton>
            </div>
          )}
          {animationView && (
            <AnimationPanel
              showPanel={false}
              setAnimationView={setAnimationView}
              resetHistoryTrace={resetHistoryTrace}
              defaultValues={{ "startDate": windowStartDate, "endDate": windowEndDate }}
              setWindowDistanceFlag={setWindowDistanceFlag}
              setAnimationDates={setAnimationDates}
              closeHistoryMap={closeHistoryMap} />
          )}
        </div>
      </React.Fragment>
    )
  };

  return (
    <div id="webNavv-history-traceViewer-1.0">
      {showPanel ? panelVisible() : panelHidden()}
    </div>
  );
};

const mapStateToProps = state => ({
  traces: state.views.traces,
  allCallouts: state.views.allCallouts,
  smallCallouts: state.views.smallCallouts,
  activeFloor: state.views.activeFloor,
  activeUser: state.views.activeUser,
  viewProps: state.views.viewProps,
  historyRoute: state.views.historyRoute,
  jobTasks: state.views.jobTasks,
  timeWindow: state.views.timeWindow,
  users: state.user.users,
  jobsToHide: state.views.jobsToHide,
  wifi: state.views.wifi,
  geoJSONJob: state.views.geoJSONJob,
  phones: state.phones.phones,
  transporterFound: state.views.transporterFound,
  jobInfo: state.views.jobInfo,
  historyTracesForCSV: state.views.historyTracesForCSV,
  featureFlags: state.auth.featureFlags,
  mapConfigs: state.views.mapConfigs
});

export default connect(mapStateToProps, {
  changeAllCallouts,
  changeSmallCallouts,
  changeAllTraces,
  changeActiveFloor,
  changeActiveUser,
  changeTimeWindow,
  changeHideJobs,
  changeWifiFilter,
  changeCenterHistoryMap,
  changeView,
  setCurrentMenuValue,
  changeDrawer
})(withStyles(historyMapStyles)(HistoryInfo));
