import React from "react";

import SendIcon from "material-ui/svg-icons/content/send";

import Moment from "react-moment";
import clsx from "clsx";

import { withStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import MinimizeIcon from "@material-ui/icons/Minimize";
import CloseIcon from "@material-ui/icons/Close";

import { openBroadcastChat, unreadBroadcastChat } from "../../../actions/views/viewsActions";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import broadcastConst from "./broadcast.constants";

let socket;
class Broadcast extends React.Component {
  constructor(props) {
    super(props);
    socket = this.props.socket;
    this.state = {
      open: true,
      curroentMessage: "",
      messages: [],
      dialogOpen: false,
      dialogError: "",
      unconfirmedMessages: [],
      loadingMessages: false,
      currentMessage: ''
    };
    this.handleEmitBroadcast();
    this.handleListenBroadcast();
    // this.handleRefreshBdcst();
    this.myRef = React.createRef();
  }

  handleEmitBroadcast = () => {
    this.props.socketChat.emit("bcst-messages", {
      destiny: broadcastConst.destiny.id,
      origin: broadcastConst.origin.id,
      socketId: this.props.socketChat.id
    });
  };

  handleListenBroadcast = () => {
    this.props.socketChat.on("bcst-messages", (broadcast) => {
      if (broadcast.messages) {
        this.handleDisplayBroadcast(broadcast.messages);
      } else {
        this.handleDisplaySingleBroadcastMessage(broadcast.message);
      }
    });
  };

  /*
  handleRefreshBdcst = () => {
    this.props.socketChat.on("broadcast-susbcribe", () => {
      this.handleEmitBroadcast();
    });
  }
  */

  scrollToBottom() {
    const scrollHeight = this.el && this.el.scrollHeight;
    const height = this.el && this.el.clientHeight;
    const maxScrollTop = scrollHeight - height;
    if (this.el) {
      this.el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  handleDisplayBroadcast = (broadcastMessage) => {
    if (!!broadcastMessage && !!broadcastMessage.length) {
      this.scrollToBottom();
      this.setState({ messages: [...broadcastMessage] });
    }
  };

  handleDisplaySingleBroadcastMessage = (message) => {
    if (!!message) {
      this.scrollToBottom();
      this.setState(state => ({
        messages: [...state.messages, message], open: true
      }));
    }
  }

  toggleClass() {
    if (!this.state.open) {
      this.confirmSeenMessages();
    }
    this.setState({ open: !this.state.open });
  }

  confirmSeenMessages() { }

  sendChatMessage() {
    let objUserInfo = {
      channel: broadcastConst.origin.channel,
      id: broadcastConst.origin.id,
      name: broadcastConst.origin.name,
      role: this.props.userInfo.role,
      role_name: this.props.userInfo.role_name,
      status: "broadcast",
      unreadMsgs: 0,
    };

    let message = {
      text: this.state.currentMessage,
      origin: objUserInfo.id,
      destiny: broadcastConst.destiny.id,
      status: 0,
      channel: `${objUserInfo.id}-${broadcastConst.destiny.id} `,
      creationDate: new Date(),
      campusId: this.props.userInfo.campusId
    };

    //sending broadcast notification to all the users of the team composition
    this.props.socketChat.emit("bcst-messages", { message: { broadcastUser: objUserInfo, user: this.props.userInfo, message: message, date: new Date() }, type: "broadcast" })
    //sending message to all the users of the team composition and save it in collection
    this.props.socketChat.emit("send message", message);
    //this.handleEmitBroadcast();
    this.setState({ currentMessage: "" });
  }

  renderMinizeOrClose() {
    const { classes } = this.props;

    if (this.state.open) {
      return (
        <div className={classes.broadcastAction}>
          <div
            id="webNavv-navvCast-expanded-header-minimize-1.0"
            onClick={() => {
              this.toggleClass();
            }}
          >
            <MinimizeIcon style={{ fontSize: 15 }} />
          </div>
          <div
            id="webNavv-navvCast-expanded-header-close-1.0"
            className={classes.closeAction}
            onClick={() => {
              this.props.openBroadcastChat(false);
              if (this.props.unreadBroadcast) {
                this.props.unreadBroadcastChat(false);
              }
            }}
          >
            <CloseIcon style={{ fontSize: 15 }} />
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={classes.broadcastAction}>
          <div
            id="webNavv-navvCast-expanded-header-launch-1.0"
            onClick={() => {
              this.toggleClass();
            }}
          >
            <LaunchIcon style={{ fontSize: 15 }} />
          </div>
          <div
            id="webNavv-navvCast-expanded-header-close-1.0"
            className={classes.closeAction}
            onClick={() => {
              this.props.openBroadcastChat(false);
              if (this.props.unreadBroadcast) {
                this.props.unreadBroadcastChat(false);
              }
            }}
          >
            <CloseIcon style={{ fontSize: 15 }} />
          </div>
        </div>
      );
    }

  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.sendChatMessage();
    }
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  sortMsgsDate(acc, cont) {
    return new Date(acc.creationDate) - new Date(cont.creationDate);
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        id="webNavv-navvCast-expanded-1.0"
        className={classes.broadcastWindow}
      >
        <div
          id="webNavv-navvCast-expanded-header-1.0"
          className={classes.broadcastNormal}
        >
          <p id="webNavv-navvCast-expanded-header-title-1.0">NavvCast</p>
          {this.renderMinizeOrClose()}
        </div>
        <div
          id="webNavv-navvCast-expanded-messages-1.0"
          ref={el => {
            this.el = el;
          }}
          className={this.state.open ? classes.broadcastOpen : classes.broadcastClosed}
        >
          <div
            id="webNavv-navvChat-expanded-messages-history-1.0"
            className={classes.messageView}
          >
            {this.state.messages.sort(this.sortMsgsDate).map((msg) => {
              let bdcstUser = !!msg.isBroadcast && !!msg.isBroadcast.name ? msg.isBroadcast.name : 'Unknown';
              return (
                <div className={classes.bubbleContainer}>
                  <div className={clsx(classes.messageBubble, classes.messageSent)} style={{ backgroundColor: '#197d95' }}>
                    {msg.text}
                  </div>
                  <div className={classes.timeSent}>
                    {msg.seen ? "Seen " : ""}
                    <Moment format="MM/DD/YYYY hh:mm a" date={msg.creationDate} />
                  </div>
                  <div className={classes.timeSent}>
                    sent by:{bdcstUser}
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.aclViews[12].access && (
            <div className={classes.messageBox}>
              <div className={clsx(classes.broadcastWrapper, classes.broadcastWindowSize)}>
                <input
                  id="webNavv-navvCast-expanded-messages-editor-1.0"
                  placeholder="Type Message"
                  type="text"
                  className={classes.input}
                  onChange={(input) =>
                    this.setState({ currentMessage: input.target.value })
                  }
                  value={this.state.currentMessage}
                  onKeyPress={this.enterPressed.bind(this)}
                />
              </div>
              <div
                id="webNavv-navvCast-expanded-messages-sender-1.0"
                className={classes.messageSendButton}
                onClick={this.sendChatMessage.bind(this)}
              >
                <SendIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  broadcastWindow: {
    backgroundColor: '#FFF',
    width: 264,
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2)',
    alignSelf: 'end',
    direction: 'ltr',
    borderRadius: '10px 10px 0px 0px'
  },
  broadcastOpen: {
    height: 448,
    overflowY: 'auto',
    marginBottom: 40
  },
  broadcastClosed: {
    display: 'none',
  },
  broadcastNormal: {
    height: 48,
    backgroundColor: '#197D95',
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'left',
    borderRadius: '10px 10px 0px 0px',
    fontSize: 16,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'grid',
    gridTemplateColumns: '190px 40px',
    //gridColumnGap: 10,
    paddingLeft: 16,
    cursor: 'pointer'
  },
  closeAction: {
    marginLeft: 10
  },
  broadcastAction: {
    display: 'flex',
    cursor: 'pointer',
    paddingTop: 15
  },
  messageView: {
    backgroundColor: '#FFF',
    overflowY: 'auto',
    paddingTop: 10,
    paddingRight: 5,
  },
  messageBox: {
    backgroundColor: '#FFF',
    height: 32,
    padding: 8,
    position: 'fixed',
    bottom: 0
  },
  bubbleContainer: {
    bottom: 20
  },
  messageBubble: {
    minWidth: 40,
    maxWidth: 200,
    bordeRadius: '10px!important',
    padding: 10,
    overflowWrap: 'break-word',
    marginBottom: 8,
  },
  messageSent: {
    marginLeft: 30,
    backgroundColor: '#197D95',
    color: '#FFF',
    position: 'relative',
    borderRadius: 25,
    "&:before": {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 180,
      width: 0,
      borderTop: '7px solid #197D95',
      borderLeft: '7px solid transparent',
      borderright: '7px solid transparent',
    }
  },
  timeSent: {
    fontSize: 11,
    marginBottom: 8,
    textAlign: 'right',
    marginRight: 8,
    color: 'gray'
  },
  typing: {
    fontSize: 11,
    marginBottom: 8,
    textAlign: 'right',
    marginRight: 8,
    color: 'gray',
    float: 'left',
    marginLeft: 10
  },
  searchBox: {
    backgroundColor: '#FFF',
    height: 32,
    padding: 8,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  searchInput: {
    backgroundColor: 'rgba(0,0,0,0.04)',
    width: 246,
    height: '100%',
    float: 'left'
  },
  messageInput: {
    backgroundColor: 'rgba(0,0,0,0.04)',
    width: 200,
    height: '115%',
    float: 'left',
  },
  messageSendButton: {
    backgroundColor: 'rgba(0,0,0,0.0)',
    width: 30,
    height: '91%',
    float: 'left',
    marginLeft: 10,
    paddingLeft: 6.8,
    paddingTop: 7,
    cursor: 'pointer'
  },
  broadcastWrapper: {
    width: 190,
    float: 'left',
    height: 36,
    backgroundColor: '#f4f4ff',
    borderRadius: 10,
    color: '#000',
    display: 'flex',
    alignItems: 'center'
  },
  broadcastWindowSize: {
    paddingLeft: 10
  },
  input: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0,
    height: 30,
    "&:focus": {
      outline: 'none'
    }
  }
});

Broadcast.propTypes = {
  userId: PropTypes.string.isRequired,
  access_token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  access_token: state.auth.token,
  socketChat: state.auth.socketChat,
  aclViews: state.auth.acl.views,
  unreadBroadcast: state.views.unreadBroadcast
});

export default connect(mapStateToProps, { openBroadcastChat, unreadBroadcastChat })(withStyles(styles)(Broadcast));