import {
    newAssociatedJob_Template,
    newResource_Slot,
    modifyAssociatedJob_Template,
    modifyResource_Slot,
    modifyResource_SlotUnassignUser,
    dropAssociatedJob,
    dropResource_Slot,
    dropResource_SlotJobs,
    modifyTask,
    modifyJobInerror,
    modifyDelayedJobs} from "../../../graphql/subscriptions";
import { API, graphqlOperation } from 'aws-amplify';

export const subscriptionModifyDelayedJobs= async(handleFormatJobDelayed) =>{
  const subscription = API.graphql(
      graphqlOperation(modifyDelayedJobs)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - modifyDelayedJobs]", { provider, value });
        if (value.data.modifyDelayedJobs.length !== 0) {
          handleFormatJobDelayed(value.data.modifyDelayedJobs);
        }
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionModifyJobInError= async(handleFormatJobTemplateUpdateInError) =>{
  const subscription = API.graphql(
      graphqlOperation(modifyJobInerror)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - modifyJobInerror]", { provider, value });
        handleFormatJobTemplateUpdateInError(value.data.modifyJobInerror);
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionModifyTask = async(handleFormatModifyTask) =>{
  const subscription = API.graphql(
      graphqlOperation(modifyTask)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - modifyTask]", { provider, value });
        handleFormatModifyTask(value.data.modifyTask);
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionDropResourceSlotJobs = async(handleFormatRemoveJobsFromSlot) =>{
  const subscription = API.graphql(
      graphqlOperation(dropResource_SlotJobs)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - dropResource_SlotJobs]", { provider, value });
        handleFormatRemoveJobsFromSlot(value.data.dropResource_SlotJobs);
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionDropResourceSlot = async(handleFormatRemoveSlot) =>{
  const subscription = API.graphql(
      graphqlOperation(dropResource_Slot)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - dropResource_Slot]", { provider, value });
        handleFormatRemoveSlot(value.data.dropResource_Slot);
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionDropAssociatedJob = async(handleFormatRemoveJobFromSlot) =>{
  const subscription = API.graphql(
      graphqlOperation(dropAssociatedJob)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - dropAssociatedJob]", { provider, value });
        handleFormatRemoveJobFromSlot(value.data.dropAssociatedJob);
      },
      error: error => console.warn(error)
  });
  return subscription;
}

export const subscriptionNewAssociatedJobTemplate = async(handleFormatNewAssociatedJobTemplate) =>{
    const subscription = API.graphql(
        graphqlOperation(newAssociatedJob_Template)
      ).subscribe({
        next: ({ provider, value }) => {
          console.info("[Subscription - newAssociatedJob_Template]", { provider, value });
          handleFormatNewAssociatedJobTemplate(value.data.newAssociatedJob_Template);
        },
        error: error => console.warn(error)
    });
    return subscription;
}

export const subscriptionNewResourceSlot = async(handleFormatNewResourceSlotTemplate) =>{
    const subscription = API.graphql(
      graphqlOperation(newResource_Slot)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - newResource_Slot]",{ provider, value });
        handleFormatNewResourceSlotTemplate(value.data.newResource_Slot);
      },
      error: error => console.warn(error)
    });
    return subscription;
}

export const subscriptionModifyAssociatedJobTemplate = async(handleFormatJobTemplateUpdate) =>{
    const subscription = API.graphql(
      graphqlOperation(modifyAssociatedJob_Template)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - modifyAssociatedJob_Template]",{ provider, value });
        if (!!value.data.modifyAssociatedJob_Template[0]){
          handleFormatJobTemplateUpdate(value.data.modifyAssociatedJob_Template[0]);
        }
      },
      error: error => console.warn(error)
    });
    return subscription;
}

export const subscriptionModifyResourceSlot = async(handleFormatSlotUpdate) =>{
    const subscription =  API.graphql(
      graphqlOperation(modifyResource_Slot)
    ).subscribe({
      next: ({ provider, value }) => {
        console.info("[Subscription - modifyResource_Slot]",{ provider, value });
        handleFormatSlotUpdate(value.data.modifyResource_Slot);
      },
      error: error => console.warn(error)
    });
    return subscription;
}

export const subscriptionModifyResourceSlotUnassignUser = async(handleFormatSlotUnassignUpdate) =>{
    const subscription = API.graphql(
       graphqlOperation(modifyResource_SlotUnassignUser)
    ).subscribe({
       next: ({ provider, value }) => {
         console.info("[Subscription - modifyResource_SlotUnassignUser]",{ provider, value });
         handleFormatSlotUnassignUpdate(value.data.modifyResource_SlotUnassignUser[0]);
      },
       error: error => console.warn(error)
    });
    return subscription;
}