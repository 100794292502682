export const mapSettings = () => {
    let settings = {
        version: "1.16.7  09/19/2022 AA",
        users: {
            location_Annotation: {
                active: {
                    cursor: "pointer",
                    display: "flex",
                    width: "10px",
                    height: "10px",
                    top: "5px",
                    border: "2px solid #D3D3D3",
                    borderRadius: "50%",
                    background: "#0EC41A"
                },
                inactive: {
                    cursor: "pointer",
                    display: "flex",
                    width: "10px",
                    height: "10px",
                    top: "5px",
                    border: "2px solid #D3D3D3",
                    borderRadius: "50%",
                    background: "orange"
                },
                offline: {
                    cursor: "pointer",
                    display: "flex",
                    width: "10px",
                    height: "10px",
                    top: "5px",
                    border: "2px solid #D3D3D3",
                    borderRadius: "50%",
                    background: "#9e9e9e"
                }
            },
            location_Callout: {
                main_Container: {
                    height: "45px",
                    width: "170px",
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "5px",
                    fontSize: "100px",
                    textAlign: "center",
                    background: "rgba(255,255,255,.95)",
                    border: "2px solid rgb(193,193,193,.30)",
                    position: "absolute",
                    transform: "translate(2px,-30px)"
                },
                floor_Container: {
                    fontSize: "12px",
                    color: "white",
                    fontWeight: "bold",
                    padding: "16px 0px 8px 0px",
                    width: "35px",
                    height: "21px",
                    borderRadius: "0px  5px 5px 0px",
                    float: "right"
                },
                date_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "9px",
                    paddingTop: "5px",
                    color: "#2843A3",
                    display: "block"
                }
            },
            small_location_Callout: {
                main_Container: {
                    color: "white",
                    fontWeight: "bold",
                    height: "35px",
                    width: "35px",
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "5px",
                    fontSize: "100px",
                    textAlign: "center",
                    border: "2px solid rgb(193,193,193,.60)",
                    transform: "translate(1px,-30px)"
                }
            },
            pointer: {
                fontSize: "10px",
                padding: "10px 8px 8px 10px",
                float: "left"
            }
        },
        equipment: {
            location_Annotation: {
                width: "10px",
                height: "10px",
                display: "flex",
                top: "5px",
                left: "60px",
                border: "2px solid rgb(193,193,193,.60)",
                borderRadius: "50%",
                cursor: "pointer"
            },
            location_Callout: {
                main_Container: {
                    height: "60px",
                    width: "176px",
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "5px",
                    fontSize: "100px",
                    textAlign: "center",
                    background: "rgba(255,255,255,.85)",
                    border: "2px solid rgb(193,193,193,.30)",
                    zIndex: "2",
                    transform: "translate(10px,-25px)"
                },
                floor_Container: {
                    fontSize: "12px",
                    color: "white",
                    fontWeight: "bold",
                    padding: "20px 0px 8px 0px",
                    width: "45px",
                    height: "31px",
                    borderRadius: "0px  5px 5px 0px",
                    float: "right"
                },
                date_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "10px",
                    color: "#2843A3",
                    display: "block"
                },
                deviceCategory_Container: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555555",
                    display: "block"
                },
                controlNumberTitle_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    color: "#757575",
                    display: "inline"
                },
                controlNumber_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    color: "#757575",
                    display: "inline"
                },
                pointer: {
                    fontSize: "10px",
                    padding: "10px 8px 8px 10px",
                    float: "left",
                    display: "block"
                }
            },
            small_location_Callout: {
                main_Container: {
                    color: "white",
                    fontWeight: "bold",
                    height: "35px",
                    width: "35px",
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "5px",
                    fontSize: "100px",
                    textAlign: "center",
                    border: "2px solid rgb(193,193,193,.60)",
                    transform: "translate(1px,-30px)"
                },
                pointer: {
                    padding: "1px 1px 2px 8px",
                    float: "left",
                    marginTop: "5px",
                    width: "20px",
                    height: "20px"
                }

            }
        },
        history: {
            location_Callout: {
                main_Container: {
                    height: "60px",
                    width: "176px",
                    display: "block",
                    margin: "0 auto",
                    borderRadius: "5px",
                    fontSize: "100px",
                    textAlign: "center",
                    background: "rgba(255,255,255,.85)",
                    border: "2px solid rgb(193,193,193,.30)",
                    zIndex: "2",
                    transform: "translate(10px,-42px)"
                },
                title_Container: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#555555",
                    display: "block"
                },
                tag_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "10px",
                    color: "#757575",
                    display: "inline"
                },
                date_Container: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "10px",
                    color: "#2843A3",
                    display: "block"
                },
                floor_Container: {
                    fontSize: "12px",
                    color: "white",
                    fontWeight: "bold",
                    padding: "20px 0px 8px 0px",
                    width: "45px",
                    height: "31px",
                    borderRadius: "0px  5px 5px 0px",
                    float: "right"
                },
                pointer: {
                    fontSize: "10px",
                    padding: "10px 8px 8px 10px",
                    float: "left",
                    display: "block"
                },
                current_location_HistoryPin: {
                    width: "20px",
                    height: "20px",
                    top: "-5px",
                    display: "flex",
                    position: "relative",
                    border: "2px solid #FFF",
                    borderRadius: "80% 0 55% 50% / 55% 0 80% 50%",
                    transform: "rotate(130deg)",
                    cursor: "pointer",
                    background: "#E3F2FD"
                },
                start_HistoryPin: {
                    width: "20px",
                    height: "20px",
                    top: "-5px",
                    display: "flex",
                    position: "relative",
                    border: "2px solid #FFF",
                    borderRadius: "80% 0 55% 50% / 55% 0 80% 50%",
                    transform: "rotate(130deg)",
                    cursor: "pointer",
                    background: "#0277bd"
                },
                end_HistoryPin: {
                    width: "20px",
                    height: "20px",
                    top: "-5px",
                    display: "flex",
                    position: "relative",
                    border: "2px solid #FFF",
                    borderRadius: "80% 0 55% 50% / 55% 0 80% 50%",
                    transform: "rotate(130deg)",
                    cursor: "pointer",
                    background: "#757575"
                }
            }
        },
        anchors: {
            location_Annotation: {
                border: "5px solid #2843A3",
                backgroundColor: "#2843A3",
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                cursor: "pointer",
                display: "flex",
                borderBottom: "5px solid #2843A3"
            },
            location_Callout: {
                main_Container: {
                    color: "black",
                    backgroundColor: "white",
                    fontWeight: "bold",
                    width: "130px",
                    display: "block",
                    borderRadius: "10px",
                    textAlign: "center",
                    border: "1px solid #D6D6D6",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)",
                    position: "absolute",
                    transform: "translate(-2px,-30px)"
                },
                text_Container: {
                    width: "130px",
                    textAlign: "center",
                    paddingTop: "4px",
                    paddingRight: "5px"
                },
                callout_pointer: {
                    display: "flex",
                    fontSize: "10px",
                    padding: "10px 8px 8px 10px",
                    zIndex: "1000"
                }
            },
            location_error_Callout: {
                main_Container: {
                    color: "black",
                    backgroundColor: "white",
                    fontWeight: "bold",
                    width: "150px",
                    display: "block",
                    borderRadius: "10px",
                    textAlign: "center",
                    border: "1px solid #D6D6D6",
                    boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)",
                    position: "absolute",
                    transform: "translate(-2px,-30px)"
                },
                text_Container: {
                    width: "130px",
                    textAlign: "center",
                    paddingTop: "4px",
                    paddingRight: "5px"
                },
                callout_pointer: {
                    display: "flex",
                    fontSize: "10px",
                    padding: "10px 8px 8px 10px",
                    zIndex: "1000"
                },
                icon: {
                    filter: "invert(40%) brightness(102%) sepia(100%) hue-rotate(-50deg) saturate(6481%) contrast(143%)"
                }
            }
        }
    }
    return settings;
}