import React from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import './details.component.css';

import { createMuiTheme, MuiThemeProvider, createGenerateClassName } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import SearchIcon from '@material-ui/icons/Search';
import MUIDataTable from "mui-datatables/dist/index";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import AwesomeSpinner from "../../utilities/spinner";
import PropTypes from "prop-types";
import { changeView } from "../../../actions/views/viewsActions";
import { registerLastLocation } from '../../../actions/contacts/contactActions';
import { connect } from "react-redux";

import { dateFormat } from '../contactUtils';

import _ from "lodash";

const TABLE_SORT = (data, colIndex, order) => {
  if (colIndex === 0 || colIndex === 2 || colIndex === 6) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  }
};

class DetailsContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: this.props.allContacts,
      filterBtn: null,
      showSpinner: true
    };
  }
  startLoop = true;
  elements = ["default", "hidden"];
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "635px !important",
          },
          paper: {
            boxShadow: "none",
            left: "0%",
            right: "0%",
            top: "0%",
            bottom: "-5.17%",
            background: "#FFFFFF",
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: "4px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: "1%",
          },
          toolButton: {
            display: "flex",
            justifyContent: "center",
            color: "#7D7D7D",
          },
          sortActive: {
            color: "#666 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            border: "none",
            color: "#666 !important",
            padding: "13px 25px 13px 25px !important",
            textAlign: "center",
          },
        },
        MuiTablePagination: {
          toolbar: {
            color: "#666",
          },
        },
        MuiPaper: {
          root: {
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: "4px",
            boxShadow: "none !important",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#fff",
          },
        },
      },
    });

  componentDidMount = () => {
  };

  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  };

  handleRenderLastLocation = (value) => {
    let lastGeolocation={
            latitude: value.latitude,
            longitude: value.longitude,
            floorOrdinal: value.floor,
            userLastLocationDate: value.date,
            id: value.id,
            name: value.name,
            date: value.date
          };
    this.props.registerLastLocation(lastGeolocation)
    return (
      <Tooltip>
        <Button variant="contained" color="primary"
            onClick={() => {this.handleChangeToMap(lastGeolocation)}}>
            {value.unitName}
        </Button>
      </Tooltip>
    );
  };

  handleChangeToMap = (location) =>{
    this.props.changeView("map", "contact_location");
  };

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };


  handleClick = (e) => {
    const btnClick = e.target.value;

    if(btnClick === this.state.filterBtn){
        this.setState( { filterBtn : null } )
     }else {
        this.setState( {filterBtn : btnClick} )
     }
}

  render() {
    let data = [];
    const { allContacts } = this.props
      this.props.detailContacts.map(contact =>{
      const date = new Date(contact.dateTime);
      const dateexposed = dateFormat(date);
      let location = {};

      if(contact.location) {
        location.latitude = contact.location[0];
        location.longitude = contact.location[1];
        location.floor = contact.floor;
        location.date = contact.dateTime;
        // location.id = key;
        location.name = this.props.contact;
        location.unitName = 'Locate';
      }
      data.push({
        contactlevel:'Direct',
        date: dateexposed,
        location,
        contactduration: `${contact.contactTime/60} min`
      });

      this.showTable = true;
      if(this.state.showSpinner){
        this.setState({showSpinner: false});
      }
    });



    const columns = [
      {
        name: "contactlevel",
        label: "Contact Level",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Contact Level: ${v}` }
        }
      },
      {
        name: "location",
        label: "Location",
          options: {
          filter:false,
          customBodyRender: v => this.handleRenderLastLocation(v)
        }
      },
      {
        name: "contactduration",
        label: "Contact Duration",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Total Exposure: ${v}` }
        }
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `ID: ${v}` }
        }
      },
    ];
    const goBack = () =>{
      this.props.changeView('contact')
    }
    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      rowsPerPage: 8,
      rowsPerPageOptions: [10, 30, 50, 100],
      selectableRowsHeader: false,
      selectableRows: "none",
      customSort: (data, colIndex, order) => {
        return TABLE_SORT(data, colIndex, order);
      },
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
    };
    let l= this.props.open? '23%': '4%';
    let w= this.props.open? '75.5%': '95%';

    return (
      <div style={{marginTop:'5%',marginLeft:l, width:w}}>
        <div style={{'marginTop':'56px'}}>
          {this.showTable && (
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable title={title(this.props.user, this.props.contact)} data={this.state.filterBtn === null ? data : data.filter(element => element.contactlevel === this.state.filterBtn)} columns={columns} options={options} />
            </MuiThemeProvider>
          )}
          <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open}/>
        </div>
      </div>
    );
  }
}

const title = (user, contact) => {
  return(
  <div className="card">
    <div className="users">
      <h1 className="user-name">{user.name}</h1>
    </div>
    <ArrowForwardIcon className="arrow-icon" style={{'height':'auto'}}/>
    <div className="users">
      <h1 className="user-name">{contact}</h1>
    </div>
  </div>)
};

DetailsContact.propTypes = {
  detailContacts: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  detailContacts: state.contact.detailContacts,
  open:state.views.drawerOpen,
  user:state.contact.userSelected,
  contact:state.contact.nameDetail
});

export default connect(mapStateToProps,{changeView, registerLastLocation})(withRouter(DetailsContact));