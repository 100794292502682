import {POST_JOB,DAY_JOBS, ERROR, CLEAR_JOBS} from '../actions/jobs/types';

const initialState = {
    jobs:[],
    requested:false,
}

export default function( state = initialState, action){
    switch(action.type){
        case CLEAR_JOBS:
            return initialState;
        case DAY_JOBS:
            return{
                ...state,
                jobs:action.payload.jobs,
                requested:true,
            };
        case ERROR:
            return{
                ...state,
                dialogError:action.payload.dialogError
            }
        case POST_JOB:
        default:
            return state;
    }
}
