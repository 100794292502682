import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import lightBlue from "@material-ui/core/colors/lightBlue";

import MasterButton from './button.component';
function ModalPop(props){
    const classes = useStyles();

    return(
       <Dialog
        className={classes.dialog}
        open={props.openModal}
        onClose={props.handleCloseModal}>
        <div className={classes.deleteMsgContainer}>
          {props.icon}
          <h2 className={classes.errorText}>{props.title}</h2>
          <p className={classes.errorText}>{props.subtitle}</p>
          <div style={{display:'flex', marginLeft: "5%"}}>
            <MasterButton
              keyLbl={'default-btn'}
              label={props.button2Label}
              buttonType={'error'}
              isDisabled={false}
              handleClick={props.handleSecondOption}
              size={"medium"}
              styles={props.styleSecondOption}
              class={classes.secondOption}/>
            <MasterButton
              keyLbl={'success-btn'}
              label={props.button1Label}
              buttonType={'success'}
              isDisabled={false}
              handleClick={props.handleFirstOption}
              size={"medium"}
              styles={props.styleFirstOption}
              class={classes.firstOption}/>
          </div>
        </div>
       </Dialog>
    );
};

export const useStyles = makeStyles((theme) => ({
    dialog: {
        marginLeft: '50vh',
        width:"700px"
    },
    deleteMsgContainer: {
        display:'inline-block',
        width:'400px',
        marginTop:'20px',
        marginBottom:'20px'
    },
    errorText: {
        textAlign:'center'
    },
    secondOption:{
      marginLeft:'90px',
      textTransform: "none !important",
    },
    firstOption: {
        textTransform: "none !important",
        marginLeft:'30px',
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    }
}));

export default ModalPop;