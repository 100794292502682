// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

/*
const appConfig = {
    apiGatewayInvokeUrl: 'https://caucv082m6.execute-api.us-east-1.amazonaws.com/Stage/',
    cognitoUserPoolId: 'us-east-1_hVnynWkvV',
    cognitoAppClientId: '4f0bff1qr45oh11nga6jl6riv9',
    cognitoIdentityPoolId: 'us-east-1:ba5a4eae-ddf8-4613-a435-88a8a85f5658',
    appInstanceArn: 'arn:aws:chime:us-east-1:590019635610:app-instance/165ca41b-76e8-4507-b8ff-14574fdd3a71',
    region: 'us-east-1',// Only supported region for Amazon Chime SDK Messaging as of this writing
    attachments_s3_bucket_name: 'chime-sdk-chat-demo-chatattachmentsbucket-1qo29gepbr3ja'
};
*/
const appConfig = {
    apiGatewayInvokeUrl: 'https://17r97m00x7.execute-api.us-east-1.amazonaws.com/Stage/',
    cognitoUserPoolId: 'us-east-1_U7g3fiSEB',
    cognitoAppClientId: '6130jgfuil7hu5lebcd6jd1ck',
    cognitoIdentityPoolId: 'us-east-1:65c7834b-9202-4fb8-bcc1-e3bdca5193a7',
    appInstanceArn: 'arn:aws:chime:us-east-1:590019635610:app-instance/439b34cc-2c66-4b77-a803-8c2cbccfec66',
    region: 'us-east-1',// Only supported region for Amazon Chime SDK Messaging as of this writing
    attachments_s3_bucket_name: 'chime-demo-stack-chatattachmentsbucket-163chtzqwz6ue'
  };
export default appConfig;
