import React from "react";
import "./phones.component.css";

import Dialog from "@material-ui/core/Dialog";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppsIcon from '@material-ui/icons/Apps';
import SearchIcon from '@material-ui/icons/Search';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
//third party libs

import momentLib from "moment";
import Moment from "react-moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import { styles } from './phones.styles';

import { changeView } from "../../actions/views/viewsActions";
import {handleCreateToken,handleFindToken} from "../../actions/auth/authActions";
import { getModels } from '../../actions/modelPhone/modelPhoneActions';
import { filterUsers } from "../../filters/aclUsersFilter";
import { fetchACLS } from "../../actions/acl/aclActions";
import { FORMAT_FILTER_LABELDATES,
  FILTER_LOGIC_FM,
  FILTER_LOGIC_CHECKOUT,
  FILTER_LOGIC_CHECKIN,
  UPDATE_FILTERS, STRING_FILTER_LOGIC} from "../utilities/common_tableFilters";
import {
  getPhones,
  getPhoneInfo,
  phoneToUpdateInfo,
  cleanCommentsByPhoneList
} from "../../actions/phones/phonesActions";
import {
  listUsers,
  forceLogout,
  updateUser
} from "../../actions/user/userActions";
import {createLog} from '../../actions/logs/logsActions';
import * as CONSTS from "./phones.constants";
import AddPhone from "./add-phone/addPhone.component";
import CustomToolbar from  '../utilities/refreshBtn.component';
import AwesomeSpinner from '../utilities/spinner';
import dialogVector from '../../img/dialogVector.png';
import AssingUser from './assingUser.component';
import MasterTable from '../utilities/table.component';
import MasterAlert from '../utilities/alert.component';
import MasterButton from '../utilities/button.component';
import In from '../../img/In.png';
import Out from '../../img/Out.png';
import InRed from '../../img/In_Red.png';
import OutGreen from '../../img/Out_Green.png';
//Utility
import _ from 'lodash';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

const PHONE_CHECK_LIST_URL = `${secureUrl.hostUrl}/api/phone_checklists`;

class PhonesControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config:{
        refreshFM: true,
        order: "desc",
        orderBy: "timestamp",
        open: false,
        refreshCounter: false,
        dialogTitle: "",
        active: true,
        isUpdate:false
      },
      openSnack: false,
      snackMsg: '',
      users: [],
      phones: [],
      phoneInfo: {
        model: "none",
        teams: [],
        team: '',
        assignValue: "none",
        aassignId: 0,
        newId: "",
        newUUID: "",
        newAssetTag: "",
        newUsed: "",
        newBattery: "",
        newSSID: "",
        newLabel: "",
        phoneNum: "",
      },
      hiddenRows:{
        number:true,
        findPhone:true,
        identifier:true,
        model:true,
        team:true,
        teams:true,
        usedBy:true,
        status:true,
        in:true,
        out:true,
        lastUsed:true,
        battery:true,
        batteryState:false,
        network:true,
        label:true,
        active:false,
        location:false,
        locationServices:false,
        socketIO:false,
        background:false,
        connected:false,
        assign:false,
        networkAvailable:false,
        validNetwork:false,
        phoneNumber:false,
        silentMode: false,
        notificationStatus: false,
        appVersion:false
      },
      tableRowsPerPage: 10,
      showArchiveInfo: false,
      timestamp: "",
      timetoken: "",
      intervalFn: undefined,
      showSpinner: true,
      showTable: false,
      isSearchClosed: false,
      showSpinnerAddPhone: false,
      settingUpdate: false,
      disableSaveButton: false,
      statusOfDevice:'',
    };
    this.handleClose = this.handleClose.bind(this);
    this.startLoop = true;
    this.workerList=[];
    this.devicesList=[];
    this.appVersionList=[];
    this.modelsList=[];
    this.teamsList=[];
    this.networksList=[];
    this.labelList=[];
    this.usedByList=[];
    this.assignedToList=[];
    this.interval=null;
    this.headerKey=1;
    this.filterBy=[];
    this.onFilter=false;
    this.onSearch=false;
    this.searchBy='';
    this.buttonDisabled={};
    this.teamListId=0;
  }

  static getDerivedStateFromProps(props) {
    let returnObject = { phones: null, filteredUsers: null };
    if (props.phones.length > 0) {
      returnObject.phones = props.phones;
    }
    if (props.users) {
      let filtered = filterUsers(props.users, props.acl.contact);
      returnObject.users = filtered;
    }
    return returnObject;
  }

  componentDidUpdate(props) {
    if (Object.keys(props.roles).length < 1) {
      props.fetchACLS();
    }
    if(!this.state.config.refreshFM){
      this.addButtonStatus('none');
    }else{
      this.addButtonStatus('block');
    }
    if(this.state.isSearchClosed){
      this.setState({isSearchClosed: false});
    }
  }

  componentDidMount = () => {
    this.props.getPhones()
    .then(res=>
       this.setState({showSpinner: false, showTable: true}))
    .catch(err=>
      this.setState({showSpinner: false, showTable: true})
    );
    this.props.getModels(this.props.campusInfo.id);
    this.props.fetchACLS();

    this.props.socketChat.on(CONSTS.JOINED_CHANNEL,user=>{
      if(!!this.props.users && !!this.props.campusInfo){
        let currentUser = this.props.usersObj[user.id];
        if(!!currentUser && currentUser.campusId === this.props.campusInfo.id){
          this.handleGetPhones();
        }
      }
    });

    this.props.socketChat.on(CONSTS.LOGOUT_USER_CHANNEL,user=>{
      if(!!this.props.users && !!this.props.campusInfo){
        let userId = !!user._id? user._id : user.id;
        let currentUser = this.props.usersObj[userId];
        if(!!currentUser && currentUser.campusId === this.props.campusInfo.id){
          this.handleGetPhones();
        }
      }
    });

    this.props.socketChat.on(CONSTS.UPDATE_CICO_CHANNEL,user=>{
        this.handleGetPhones();
    });
  }

  geolocationColumnsVisible = ()=>{
    if(this.state.hiddenRows.location || this.state.hiddenRows.locationServices || this.state.hiddenRows.socketIO
    || this.state.hiddenRows.background || this.state.hiddenRows.connected || this.state.hiddenRows.networkAvailable
    || this.state.hiddenRows.validNetwork){
      return true
    }else{
      return false
    }
  }

  handleCleanUserJoined=async()=>{
    return new Promise((resolve,reject)=>{
      setTimeout(()=>{
        resolve({userJoined:{},isUserJoined:false})
    },6000)
    })

}

componentWillUnmount(){
  clearInterval(this.interval)
}


  handleUpdateTable=()=>{
    this.setState({settingUpdate: !this.state.settingUpdate});
  }

  handleGetPhoneInfo=async(userId)=>{
    if (!!userId && typeof userId=='string') {
      await this.props.getPhoneInfo(userId);
    }
  }

  handleGetPhones=async()=>{
    await this.props.getPhones();
    await this.props.listUsers();
  }

  handleNewEditPhone=async()=>{
    await this.props.getPhones();
    await this.props.listUsers();
    this.setState({showSpinner: false, showSpinnerAddPhone: false});
  }

  addButtonStatus=(state)=>{
    if(document.querySelector(CONSTS.ADD_BUTTON)){
      let addBtn = document.querySelector(CONSTS.ADD_BUTTON);
      addBtn.style.display=state;
    }
  }

  handleChangeSwitch=()=>{
    this.filterBy=[];
    this.onFilter=false;
    this.onSearch=false;
    this.searchBy='';
    this.handleGetPhones();
    this.setState(prevState =>({
      config:{
        ...prevState.config,
        refreshFM: true}
    }));
  };

  handleShowArchive=()=>{
    this.setState({showArchiveInfo: !this.state.showArchiveInfo});
  }

  renderLastLogout({ timestamp, last_login }) {
    if (!timestamp) {return "-"};
    if (!isNaN(new Date(timestamp)) && new Date(timestamp) !== CONSTS.INVALID_DATE){
      return <Moment format={CONSTS.DATE_FORMAT}>{timestamp}</Moment>;
    }
    if (timestamp.status === CONSTS.ONLINE){
      return [
        <Tooltip
          title={
            <React.Fragment>
              {CONSTS.LOGGEDIN_LBL}
              <Moment format={CONSTS.DATE_FORMAT}>{last_login}</Moment>
              {last_login}
            </React.Fragment>
          }
        >
          <div className={`user-status ${timestamp.status}`}>
            {timestamp.status}
          </div>
        </Tooltip>
      ];
    }
    return [
      <Tooltip
        title={
          <React.Fragment>
            <Moment format={CONSTS.DATE_FORMAT}>
              {timestamp.lastLocationDate}
            </Moment>
            {CONSTS.AROUND_LBL}
            <Moment fromNow>{timestamp.lastLocationDate}</Moment>
          </React.Fragment>
        }
      >
        <div className={`user-status ${timestamp.status}`}>
          {timestamp.status}
        </div>
      </Tooltip>
    ];
  }
  
  handleModifiedBy = ()  => {
    const {user, userId} = this.props;
    let modifiedBy = '';
    if(user && user.id) {
        modifiedBy = user.id;
    } else if (userId) {
        modifiedBy = userId;
    }
    return modifiedBy;
  }

  createPhoneExpireLog = (data) =>{
    let modifiedBy = this.handleModifiedBy();
    let log={
        campusId: this.props.campusInfo.campusId,
        userId: modifiedBy,
        action: data.action,
        description: data.description,
        timestamp: new Date()
    };
    // this.props.createLog(log);
  };

  handleForceToLogout=(userId)=> {
    let idUserForcedlogout = [];
    idUserForcedlogout = this.props.users.filter(user => user._id === userId);
    const actionPerformedBy = this.handleModifiedBy();
    this.props.forceLogout(userId,actionPerformedBy)
    .then(resp=>{
      this.createPhoneExpireLog({
        action: CONSTS.EXPIRE_PHONE_ACTION,
        description:`Phone "${idUserForcedlogout[0].device_id}" expired by "${actionPerformedBy}"`});
    }).catch(error=>{
      this.createPhoneExpireLog({
        action: CONSTS.EXPIRE_PHONE_ACTION_ERROR,
        description:`Phone "${idUserForcedlogout[0].device_id}" couldn't be expired by "${actionPerformedBy}" due endpoint ${error}`});
      console.error(`[PHONESCONTROL] An error has occurred forcing logout ${error}`);
    });
  }

  handleLoopPhone(fn, idUserForcedlogout) {
    return this.state.phones.filter(phone => {
      return fn(phone, idUserForcedlogout);
    });
  }

  handleOptimisticUserLogout(idUserForcedlogout) {
    const handleConditionUser = (phone, idUserForcedlogout) => {
      if (
        phone.usedBy === idUserForcedlogout.id &&
        idUserForcedlogout.device_id === phone.UUID
      ) {
        return phone;
      }
    };
    const foundPhone = this.handleLoopPhone(
      handleConditionUser,
      idUserForcedlogout
    );
    if (foundPhone.length > 0) {
      idUserForcedlogout.online = false;
      idUserForcedlogout.status = CONSTS.LOGGED_OUT;
      foundPhone[0].last_logout = new Date().toJSON();
      this.props.updateUser(idUserForcedlogout);
    }
  }

  handleCloseSnackbar = () => {
      this.setState({openSnack: false});
  };

  handleOpenSnackbar = (desc) => {
    this.setState({openSnack: true, snackMsg: desc});
  };

  handleClose() {
    this.setState({
      disableSaveButton: false,
      newPhone: {
        model: "none",
        teams: [],
        assignValue: "none",
        aassignId: 0,
        newId: "",
        newUUID: "",
        newAssetTag: "",
        newUsed: "",
        newBattery: "",
        newSSID: "",
        newLabel: "",
        timestamp: ""
      },
      config:{
        refreshFM: this.state.config.refreshFM,
        open: false
      },
      showSpinner:true,
      showSpinnerAddPhone:true,
      statusOfDevice: ''
    });
    this.props.cleanCommentsByPhoneList();
    this.handleNewEditPhone();
  }

  statusCustomRender(status) {
    const {CHAT} = CONSTS;
    let colorLbl = CHAT.Offline.color;
    const {value} = status;
    if (value === CHAT.Online.label){
      colorLbl = CHAT.Online.color;
    }else if (value === CHAT.Inactive.label) {
      colorLbl = CHAT.Inactive.color;
    }
    return (
      <div className="statusMainDiv">
        <div className="statusContainer" style={{ backgroundColor: colorLbl}}></div>
        {value === CHAT.Inactive.label? CHAT.Online.label: value}
      </div>
    );
  }

  resetButton({index,checkList}) {
    this.handleAutoReset(checkList);
    return (
      <MasterButton
        keyLbl={'reset-FM'}
        label={CONSTS.RESET_BTN_LBL}
        buttonType={CONSTS.BUTTON_TYPE.default}
        isDisabled={false}
        handleClick={async() => {
          checkList.reset=true;
          await this.updatePhoneCheck({ ...checkList});
          this.handleGetPhones()
        }}
        size={CONSTS.BUTTON_SIZE.small}
        styles={{
          backgroundColor: "#2843A3",
          color: "white",
          fontSize: "12px"
        }}
      />);
  }

  handleAutoReset=async(checkList)=>{
    const { CHECK_BUTTONS_CONFIG:{checkInField, checkoutField}} = CONSTS;
    if(!!checkList[checkInField]
    && !!checkList[checkoutField]
    && checkList[CONSTS.RESET]===false){
      checkList.reset=true;
      await this.updatePhoneCheck({ ...checkList});
      this.handleGetPhones();
    }
  }

  disableButtonOnClick=(action, key)=>{
    this.buttonDisabled[key].disabled=true;
    action();
  };

  renderLogButton=(action, config)=> {
    let disabled = config.disabled===true? config.disabled : this.buttonDisabled[config.key].disabled;
    return (
      <MasterButton
        keyLbl={'checkIn-out-FM'}
        label={config.content}
        buttonType={CONSTS.BUTTON_TYPE.default}
        isDisabled={disabled}
        handleClick={() => this.disableButtonOnClick(action,config.key)}
        size={CONSTS.BUTTON_SIZE.small}
        styles={{
          ...config.style,
          color: "white",
          fontSize: "12px",
          marginTop:"5px",
          marginBottom:"5px",
          height: "36px",
          minWidth: "36px"
        }}
      />
    );
  }

  customIdenfitierRender({UUID, included, status}){
    return(
      UUID && (
        <MasterButton
        keyLbl={CONSTS.UUID_FM}
        label={UUID}
        buttonType={CONSTS.BUTTON_TYPE.defaultFlat}
        handleClick={() => this.handleUpdatePhoneInfo(UUID, included, status)}
        styles={{ color:CONSTS.BUTTON_COLORS.defaultGray, textTransform: "none"}}
        />
      )
    )
  };

  handleUpdatePhoneInfo=(phone, included,status)=>{
    let phoneList = this.state.phones;
    let deviceInfo = phoneList.find(p=>p.UUID.trim()===phone.trim());
    let device = '';
    if(deviceInfo.hasOwnProperty('_id')){
      device = deviceInfo._id
    }
    if(deviceInfo.hasOwnProperty('id')){
      device = deviceInfo.id
    }
    this.props.phoneToUpdateInfo({
      phoneId: device,
      newSSID: deviceInfo.SSID,
      newBattery: deviceInfo.battery,
      newAssetTag: deviceInfo.assetTag,
      newUUID: deviceInfo.UUID,
      teams: deviceInfo.teams,
      team: deviceInfo.phone_user.role,
      assignValue: deviceInfo.assign,
      model:deviceInfo.model,
      newUsed: deviceInfo.usedBy,
      timestamp: deviceInfo.timestamp,
      newLabel: deviceInfo.label,
      active: deviceInfo.active,
      phoneNum: !!deviceInfo.phoneNumber? deviceInfo.phoneNumber : ''
    });
    if (!included) {
      this.setState(
        {
          statusOfDevice: status,
          disableSaveButton: true,
          config:{
            open: true,
            isUpdate: true,
            dialogTitle: CONSTS.EDIT_PHONE,
            refreshFM: true
          },
          phoneInfo: {
            phoneId: device,
            newSSID: deviceInfo.SSID,
            newBattery: deviceInfo.battery,
            newAssetTag: deviceInfo.assetTag,
            newUUID: deviceInfo.UUID,
            teams: deviceInfo.teams,
            team: deviceInfo.phone_user.role,
            assignValue: deviceInfo.assign,
            model:deviceInfo.model,
            newUsed: deviceInfo.usedBy,
            timestamp: deviceInfo.timestamp,
            newLabel: deviceInfo.label,
            active: deviceInfo.active,
            phoneNum: !!deviceInfo.phoneNumber? deviceInfo.phoneNumber : ''
          }
        });
    } else {
      this.setState(
        {
          statusOfDevice: status,
          config:{
            open: true,
            isUpdate: true,
            dialogTitle: CONSTS.EDIT_PHONE,
            refreshFM: true
          },
          phoneInfo: {
            phoneId: device,
            newSSID: deviceInfo.SSID,
            newBattery: deviceInfo.battery,
            newAssetTag: deviceInfo.assetTag,
            newUUID: deviceInfo.UUID,
            teams: deviceInfo.teams,
            team: deviceInfo.phone_user.role,
            assignValue: deviceInfo.assign,
            model:deviceInfo.model,
            newUsed: deviceInfo.usedBy,
            timestamp: deviceInfo.timestamp,
            newLabel: deviceInfo.label,
            active: deviceInfo.active,
            phoneNum: !!deviceInfo.phoneNumber? deviceInfo.phoneNumber : ''
          }
        });
    }
  }

  renderTeamList=(teams)=>{
    this.teamListId++;
    return(
      <Autocomplete
         id={`TeamList-${this.teamListId}`}
         options={teams}
         getOptionLabel={(option) => option}
         getOptionSelected={(option, value)=>option === value}
         defaultValue={teams[0]}
         style={{ width: 220 }}
         disableClearable
         renderInput={(params) => <TextField {...params}/>}
       />
    );
  };

  renderTeam = ({role, included}) =>{
    if (included) {
      return (
      <div style={{textAlign:'left', paddingLeft:'5px'}}>
        <div style={{color:'gray'}}>{role}</div>
      </div>
      )
    }
    else {
      return (
      <div style={{textAlign:'left', paddingLeft:'5px'}}>
        <Tooltip title={"Team not included in Phone Team list"}>
          <p style={{color:'red'}}>{role}</p>
        </Tooltip>
      </div>
      )
    }
  };

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type={CONSTS.TEXTFIELD_TYPE_DATETIME}
          format={CONSTS.TEXTFIELD_DATE_FORMAT}
          InputLabelProps={{
            shrink: true
          }}
          value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  checkinButtonRender(value) {
    const { CHECK_BUTTONS_CONFIG:{checkInBtnColor, disabledBtnColor}} = CONSTS;
    const checkInDisabled = <img
    src={In}
    style={{
    height: "15px",
    width: "20px",
    marginLeft: "2px",
    display: "table-cell",
    }}
    alt="checkInIcon"
    />;
    const checkInEnabled =<img
    src={InRed}
    style={{
    height: "15px",
    width: "20px",
    marginLeft: "2px",
    display: "table-cell",
    }}
    alt="checkInIcon"
    />;
    //Found an error with index mechanism,the object moves out according the status online/offline.
    //TODO: Indexing an array of objects (lookup table) for this.props.phones ( redux )
    //const index = _.findIndex(this.props.phones, {UUID: value.UUID})
    let key = value.UUID;
    const phone = this.props.phones.find(p => p.UUID === value.UUID);
    let showButtonCheckOut=false; 
    let disabled = true
    //Remote login auth
    if(this.isRemoteLoginCheckInStep1(value)){
        disabled=false;
        showButtonCheckOut=true
    }

    //basic auth
    if(this.isCheckInBasicAuth(value)){
      disabled=false;
      showButtonCheckOut=true
    }

    //basic auth-disabled button
    if(this.isCheckInBasicAuthDisabled(value) && disabled){
      showButtonCheckOut=true;
    }

    const style = { backgroundColor: disabled ? disabledBtnColor : checkInBtnColor };
    const action = async () => { //checkin action
      let type = 'CI';
      await this.updatePhoneCheck(phone, type);
      await this.handleForceToLogout(value.userID);   
      if(!!value.remoteLogin){
        value.remoteLogin=false;
      }
      await this.handleGetPhones();
    };

    if(!this.buttonDisabled.hasOwnProperty(key) && !disabled){
      this.buttonDisabled[key]={disabled: disabled, type: 'in'};
    }
   
    const content = disabled ? checkInDisabled : checkInEnabled;
    if (showButtonCheckOut) {
      return this.renderLogButton(action, {
        style,
        content,
        disabled,
        key
      });
    }else{
      if(this.buttonDisabled.hasOwnProperty(key) && this.buttonDisabled[key].type==='in'){
        delete this.buttonDisabled[key];
      }
      let checkinDateIso = momentLib(value.checkIn).toISOString();
      return momentLib(checkinDateIso).format(CONSTS.DATE_FORMAT);
    }
  }

  checkoutButtonRender(value) {
    const { checkOutBtnColor, disabledBtnColor } = CONSTS.CHECK_BUTTONS_CONFIG;
    //Found an error with index mechanism,the object moves out according the status online/offline.
    //TODO: Indexing an array of objects (lookup table) for this.props.phones ( redux )
    //const index = _.findIndex(this.props.phones, {UUID: value.UUID})
    let key = value.UUID;
    const phone = this.props.phones.find(p => p.UUID === value.UUID);
    let disabled = true
    const checkOutDisabled = <img
      src={Out}
      style={{
      height: "15px",
      width: "20px",
      marginLeft: "2px",
      display: "table-cell",
      }}
      alt="checkOutIcon"
    />;
    const checkOutEnabled = <img
      src={OutGreen}
      style={{
      height: "15px",
      width: "20px",
      marginLeft: "2px",
      display: "table-cell",
      }}
      alt="checkOutIcon"
    />;
    let showButtonCheckOut=false;

    if(this.isRemoteLoginCheckOutStep2(value)){
      disabled=true;
      showButtonCheckOut=false;
    }

    if(this.isCheckOutBasicAuth(value)){
      disabled=false;
      showButtonCheckOut=true;
    }

    if(this.isCheckOutBasicAuthDisabled(value)){
      disabled=true;
      showButtonCheckOut=true;
    }

    const action = async() => {
      if(this.isRemoteLoginAlreadySent(value) || this.isCheckOutBasicAuth(value)){
        let type = 'CO';
        await this.updatePhoneCheck(phone, type);
        await this.handleGetPhones();
      }
    };

    if(!this.buttonDisabled.hasOwnProperty(key) && !disabled){
      this.buttonDisabled[key]={disabled: disabled, type: 'out'};
    }
    if (showButtonCheckOut) {
      let style = { backgroundColor: disabled ? disabledBtnColor : checkOutBtnColor };
      const content = disabled? checkOutDisabled: checkOutEnabled;
      return this.renderLogButton(action, {
        style,
        content,
        disabled,
        key
      });
    }else{
      if(this.buttonDisabled.hasOwnProperty(key) && this.buttonDisabled[key].type==='out'){
        delete this.buttonDisabled[key];
      }
      let checkoutDateIso = momentLib(value.checkOut).toISOString();
      return momentLib(checkoutDateIso).format(CONSTS.DATE_FORMAT);
    }

  }
/**
 * First step to remote login (checkin/checkout)
 * @param {remoteLogin} true
 * @param {checkOut} undefined
 * @param {status} Offline
 * @param {return} boolean
 * Description: Checkout button should be active until the user recieve the silence notification
 * 
 */
  isRemoteLoginCheckOutStep1({remoteLogin,checkOut,status}){
    if(!!remoteLogin && (!!checkOut || !checkOut) && status===CONSTS.CHAT.Offline.label){
      return true;
    }

      return false
  }
  isRemoteLoginCheckOutStep2=({remoteLogin,checkOut,status})=>{
    if(!!remoteLogin && !!checkOut && (status===CONSTS.CHAT.Online.label || status===CONSTS.CHAT.Inactive.label)){
      return true;
    }
      return false
  }
  isRemoteLoginAlreadySent=({remoteLogin,checkOut,checkIn,status})=>{
    if(!!checkOut && !checkIn && !!remoteLogin && status===CONSTS.CHAT.Offline.label){
      return true;
    }
  }
  isRemoteLoginCheckInStep1=({remoteLogin,checkOut,checkIn,status})=>{
    if(!!remoteLogin && !!checkOut && !checkIn && status===CONSTS.CHAT.Online.label){
      return true;
    }    
  }

  /**
   * First step to basic auth (checkin/checkout)
   * @param {checkIn} string 
   * @param {checkOut} string
   * @param {remoteLogin} boolean
   * @param {status} string
   */
  handleCheckInBasicAuth=({remoteLogin,checkOut,checkIn,status})=>{
    if(!remoteLogin && !!checkOut && !!checkIn && status===CONSTS.CHAT.Online.label){
      return true;
    }
    return false
  }
  
  isCheckOutBasicAuth=({remoteLogin,checkOut,checkIn,status})=>{
    if(!!checkOut && !!checkIn && !remoteLogin && status===CONSTS.CHAT.Online.label){
      return true;
    }
    if(checkOut === undefined && checkIn=== undefined && remoteLogin === undefined && status===CONSTS.CHAT.Online.label){
      return true;
    }
    return false;
  }

  isCheckOutBasicAuthDisabled=({remoteLogin,checkOut,checkIn,status,reset})=>{
    if(checkOut===undefined && checkIn === undefined && !remoteLogin && status===CONSTS.CHAT.Offline.label && reset === undefined){
      return true;
    }
    return false;
  }

  isCheckInBasicAuth=({remoteLogin,checkOut,checkIn,status})=>{
    if(!!checkOut && !checkIn && !remoteLogin && status===CONSTS.CHAT.Online.label){
      return true;
    }
    return false;
  }

  isCheckInBasicAuthDisabled=({remoteLogin,checkOut,checkIn,status,reset})=>{
    if(checkOut===undefined && checkIn === undefined && !remoteLogin && status===CONSTS.CHAT.Offline.label && reset === undefined){
      return true;
    }
    if(!!checkOut && checkIn===undefined && !remoteLogin && status===CONSTS.CHAT.Offline.label){
      return true;
    }
    if(checkOut === undefined && checkIn === undefined && remoteLogin === undefined && status===CONSTS.CHAT.Online.label && reset === undefined){
      return true;
    }
    return false;
  }

  updatePhoneCheck= async (phone, type)=> {
    return new Promise(async(resolve,reject)=>{
      const { CHECK_BUTTONS_CONFIG:{checkInField, checkoutField}} = CONSTS;
      let checkIn = phone.CICO? phone.CICO[checkInField] : undefined;
      let checkOut = phone.CICO? phone.CICO[checkoutField] : undefined;
      if (type === 'CO') {
        checkOut = new Date();
        checkIn = undefined;
      } else {
        checkIn = new Date();
      }
      let idCheckList = null;
      let phoneId = phone.id? phone.id : phone._id;

      if (phone.CICO === undefined || type === 'CO') {
        let CO = new Date();
        let phoneUpdated = await axios.post(`${PHONE_CHECK_LIST_URL}`, {
          UUID:phone.UUID,
          manager_id: this.props.userId,
          [checkoutField]: CO,
          reset:false
        });

        let currentPhone = {...phone};
        let CICO = {
          UUID:phone.UUID,
          manager_id: this.props.userId,
          [checkoutField]: CO,
          reset:false
        }
        let deviceUser = phone.phone_user._id;
        currentPhone.CICO = CICO;
        delete currentPhone._id;
        delete currentPhone.id;
        delete currentPhone.phone_user;
        delete currentPhone.n;
        await axios.put(secureUrl.hostUrl+'/api/Phones/'+phoneId,currentPhone)
        .then(resp=>{
          this.handleEmitCICOUpdate(deviceUser);
          resolve({sucess:true,phone:phoneUpdated});
        });
      } else {
        let checkListUpdate={};
        await axios.get(`${PHONE_CHECK_LIST_URL}/findCheckList?UUID=${phone.UUID}`)
        .then(async (resp) => {
          if (!_.isEmpty(resp.data)) {
            idCheckList = resp.data.checkList._id;
            let CICO_Completed = !!resp.data.checkList[checkInField] && !!resp.data.checkList[checkoutField] ? true : false;

            if (CICO_Completed) {
              checkListUpdate.UUID=resp.data.checkList.UUID;
              checkListUpdate[checkInField] = checkIn;
              checkListUpdate[checkoutField] = checkOut;
              checkListUpdate.reset=resp.data.checkList.reset;
              checkListUpdate.manager_id = this.props.userId;
            } else {
              checkListUpdate.UUID=resp.data.checkList.UUID;
              checkListUpdate[checkInField] = !!resp.data.checkList[checkInField]?resp.data.checkList[checkInField]:checkIn;
              checkListUpdate[checkoutField] = !!resp.data.checkList[checkoutField]?resp.data.checkList[checkoutField]:checkOut;
              checkListUpdate.reset=resp.data.checkList.reset;
              checkListUpdate.manager_id = this.props.userId;
            }

          } else {
            console.warn(CONSTS.UPDATE_PHONE_CHECK_WARN);
            reject({sucess:false,phone:null})
          }
        });
        if (!!idCheckList) {
          //Update phone_checklist collection
          let phoneUpdated= await axios.put(`${PHONE_CHECK_LIST_URL}/${idCheckList}`, {
            ...checkListUpdate
          });

          let currentPhone = {...phone};
          let deviceUser = phone.phone_user._id;
          currentPhone.CICO = checkListUpdate;
          delete currentPhone._id;
          delete currentPhone.id;
          delete currentPhone.phone_user;
          delete currentPhone.n;
          //Update CICO attribute in Phones collection
          await axios.put(secureUrl.hostUrl+'/api/Phones/'+phoneId,currentPhone)
          .then(resp=>{
            this.handleEmitCICOUpdate(deviceUser);
            resolve({sucess:true,phone:phoneUpdated});
          });
         }else {
          console.warn(CONSTS.UPDATE_PHONE_CHECK_WARN);
          reject({sucess:false,phone:null})
        }
      }
    })
    
  }

  handleEmitCICOUpdate=(deviceUser)=>{
    this.props.socketChat.emit(CONSTS.CICO_CHANNEL,deviceUser);
  }

  async handleRenderCIO(){
    await this.props.getPhones();
    await this.props.listUsers();
  }

  handleChangeToMap=async(userId, deviceId)=>{
    if (!!userId && !!deviceId) {
      this.props.changeView(CONSTS.MAP, CONSTS.TRANSPORTER_PHONE, {userId: userId, deviceId: deviceId});
    }
  }

  streamingCustomRender=value=>{
    const {CHAT, YES}= CONSTS;
    const {classes} = this.props;
    let colorLbl = value===YES? CHAT.Online.color: CHAT.Offline.color;
    let triangle = colorLbl===CHAT.Offline.color? CHAT.Inactive.color: CHAT.Online.color;
    return(
      <div className={classes.streamingMainDiv} style={{color:colorLbl}}>
        <div className={classes.streamingContainer}>
          {triangle===CHAT.Inactive.color?
            <div className={classes.inactiveStreaming}></div>
          :
            <div className={classes.activeStreaming}></div>
          }
        </div>
        {value}
    </div>
    )
  }

  handleRenderAutologon=(phone)=>{
          return (
          <div style={{width: '220px'}}>
            <AssingUser
             users={this.props.usersObj}
             isDisabled={!!phone.user?true:false}
             handleUpdateAssingUser={this.handleUpdateAssingUser}
             phone={phone}
             updatePhoneCheck={this.updatePhoneCheck}
             remoteLogin={phone.phone.remoteLogin}
            />
          </div>
          );
  }

  handleUpdateAssingUser=async (phoneId,phone,userId)=>{
    return new Promise(async(resolve,reject)=>{
    let clonePhones = {};
    clonePhones={...this.state.phones};
    delete phone._id
    delete phone.id
    delete phone.checklist;
    phone.assign=userId;
    let updatePhone=await axios.put(secureUrl.hostUrl+'/api/Phones/'+phoneId,phone);
    resolve(updatePhone);
    Object.values(clonePhones).some(phone=>{
      if(phone.UUID===updatePhone.data.UUID){
        phone.assign=userId;
        phone._id=!!phone._id?phone._id:updatePhone.data.id;
        this.setState({ phones: clonePhones});
      }
      return null
    })
    }).catch(error=>{
        console.error(`[PHONESCONTROL] An error has ocurred Updating the phone ${phone.UUID} , Error: ${error}`);  
    })
}

  handleAssingPhoneToUser=async(user,phoneId,phone)=>{
    try{
      if(!!user){
        let webToken={}
        let userToken = await this.props.handleFindToken(user);
        if(!userToken.tokenFound){
          let newToken = await this.props.handleCreateToken(user);
          webToken=newToken.data.token.id
        }else{
          webToken=userToken.webtoken[0]._id;
        }
        
        let options ={
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'key=AAAApZvDrIA:APA91bEgEBQ2a9dBzIe1e9H1u-RfXw4zyPjdmFfKrRDlTW_uAC9_EgV3MvKgf007Hf44RmhIp27MQxw9bpF28_BoTz93kB4T7azN6BSEldjeTx2yGlKi0LSoGPWdxzIRmLcbNW22lBBy'
            }        
        }
        let data={
            "to": user.phoneToLogon.firebasetoken,
            "content_available": true,
            "apns-priority": 5,
            "apns-push-type": "background",
            "data":{
              "userId":user._id,
              "token":webToken,
              "type":"forceLogin"}
        }        
          axios.post("https://fcm.googleapis.com/fcm/send",data,options).then(res=>{
              console.info(`[PHONESCONTROL] Autologon sent successfully ${JSON.stringify(res.data)}`);
              this.setState({openSnack: true, snackMsg: `Autologon sent successfully`});
            }).catch(error=>{
            console.error(`[PHONESCONTROL] An error has ocurred in Logon user ${error}`);
            this.setState({openSnack: true, snackMsg: `Autologon was unable to be sent`});
        })
      }
    }catch(error){
      console.error(`[PHONESCONTROL] An error has ocurred in trying to auto-login the user ${JSON.stringify(user)}${error}`);
    }
}


  handleRenderFindPhone=(value)=>{
    let toolTag = CONSTS.FIND;
    return (
      <Tooltip title={toolTag}>
        <IconButton onClick={() => {this.handleChangeToMap(value.userId,value.deviceId)}}>
          <SearchIcon style={{color: '#2F80ED'}}/>
        </IconButton>
      </Tooltip>
    );
  }

  stringFieldsCustomRender = (filterList, onChange, index, column,key)=>{
    const {STRING_FILED_LBL, STRING_FILED_TYPE, CHAT} = CONSTS;
    let inputLabel = "";
    let optionList = [];
    if (key===STRING_FILED_TYPE.device) {
     inputLabel = STRING_FILED_LBL.device;
     optionList = this.devicesList.sort();
    }
    if (key===STRING_FILED_TYPE.model) {
      inputLabel = STRING_FILED_LBL.model;
      optionList = this.modelsList.sort();
    }
    if (key===STRING_FILED_TYPE.team) {
      inputLabel = STRING_FILED_LBL.team;
      let noAdminList = this.teamsList.filter(role=>role !== 'Admin');
      optionList = noAdminList.sort();
    }
    if (key===STRING_FILED_TYPE.teams) {
      inputLabel = STRING_FILED_LBL.teams;
      optionList = this.teamsList.sort();
    }
    if (key===STRING_FILED_TYPE.network) {
      inputLabel = STRING_FILED_LBL.network;
      optionList = this.networksList.sort();
    }
    if (key===STRING_FILED_TYPE.label) {
      inputLabel = STRING_FILED_LBL.label;
      optionList = this.labelList.sort();
    }
    if (key===STRING_FILED_TYPE.assigned) {
      inputLabel = STRING_FILED_LBL.assigned;
      optionList = this.assignedToList.sort();
    }
    if (key===STRING_FILED_TYPE.used) {
      inputLabel = STRING_FILED_LBL.used;
      optionList = this.usedByList.sort();
    }
    if(key===STRING_FILED_TYPE.status){
      inputLabel = STRING_FILED_LBL.status;
      optionList = [CHAT.Online.label, CHAT.Inactive.label, CHAT.Offline.label];
    }
    if (key===STRING_FILED_TYPE.appVersion) {
      inputLabel = STRING_FILED_LBL.appVersion;
      optionList = this.appVersionList.sort();
    }
    const onTagsChange = (event, values) => {
       filterList[index][0] = values;
       onChange(filterList[index], index, column);
    }
     return(
       <Autocomplete
         id={key}
         options={optionList}
         getOptionLabel={(option) => option}
         style={{ width: 320 }}
         onChange={onTagsChange}
         renderInput={(params) => <TextField {...params} label={inputLabel} InputLabelProps={{shrink: true}}/>}
       />
     );
   }

   handleTableFilters = (column)=>{
    let filter = this.filterBy.length ? this.filterBy[column] : null
    return filter;
  };

  filterPhonesTeams=(team, teams)=>{
    if(!!team && !!teams) {
      const { acl } = this.props;
      let validTeamList = false;
      let validUserTeam = false;
  
      if(acl.contact.find(role=>role === team)){
        validUserTeam = true;
      }
      teams.forEach(item => {
        if(acl.contact.find(role=>role === item)){
          validTeamList = true;
        }
      });
  
      if(validTeamList || validUserTeam){
        return true
      }
    }
    return false
  };

  handleWorkerNameRender = value => {
    if (value.length >= 17) {
      return (<p style={{textAlign: 'left', paddingLeft:'5px', fontSize: '12px'}}>{value}</p>)
    } else {
      return (<p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>)
    }
  };

  handleFilterTeams = () =>{
    const { roles } = this.props;
    roles.forEach(role=>{
      if(!this.teamsList.includes(role.role) && !!role.role)
      this.teamsList.push(role.role);
    });
  };

  handleFilterPhones = () =>{
    const { phones } = this.props;
    let currentPhones = [];
    if (!!phones) {
      currentPhones = phones.filter(phone=> !!phone.phone_user);;
      if (!this.state.showArchiveInfo && !!phones) {
        currentPhones = phones.filter(phone=>phone.active===true && !!phone.phone_user);
      }
    }
    return currentPhones;
  }

  handleTableColumns = () =>{
    const { hiddenRows } = this.state;
    const { COLUMNS,CUSTOM_FILTER_TYPE,TEXTFIELD_FILTER_TYPE,STRING_FILED_TYPE,USER_TEAM_FILTER } = CONSTS;
    const { classes } = this.props;

    const columns = [
      {
        name: COLUMNS.number.id,
        label: COLUMNS.number.label,
        options: {
          display: hiddenRows.number,
          customHeadRender: (value, tableMeta)=>{
            return (<th key={tableMeta.rowIndex+1} className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <p className={classes.numHeader}>#</p>
            </th>)
          },
          sort:false,
          filter:false,
          customBodyRender: (value, tableMeta) => {
            return <p>{tableMeta.rowIndex+1}</p>;
          }
        }
      },
      {
        name: COLUMNS.findPhone.id,
        label: COLUMNS.findPhone.label,
        options: {
          display:hiddenRows.findPhone,
          filter:false,
          sort:false,
          customHeadRender: (value, tableMeta)=>{
            return (<th key='findPhone' className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <PhoneIphoneIcon className={classes.findPhoneIcon} />
            </th>)
          },
          customBodyRender: value =>{
           return this.handleRenderFindPhone(value);
          }
        }
      },
      {
        name: COLUMNS.identifier.id,
        label: COLUMNS.identifier.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.identifier,
          customBodyRender: p => {
            return this.customIdenfitierRender(p);
          },
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(2),
          customFilterListOptions: {
            render: device => `${COLUMNS.identifier.label}: ${device}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(device, filters) {
              return STRING_FILTER_LOGIC(device.UUID, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.device)
          }
        }
      },
      {
        name: COLUMNS.active.id,
        label: COLUMNS.active.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.active,
          customFilterListOptions: { render: v => `${COLUMNS.active.label}: ${v}` },
          filterList: this.handleTableFilters(3),
          filterOptions: {
            names: COLUMNS.active.filter
          },
          customBodyRender: active => <p>{active}</p>
        }
      },
      {
        name: COLUMNS.location.id,
        label: COLUMNS.location.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.location,
          customFilterListOptions: { render: v => `${COLUMNS.location.label}: ${v}` },
          filterList: this.handleTableFilters(4),
          filterOptions: {
            names: COLUMNS.location.filter
          },
          customBodyRender: (value) => {
            return this.streamingCustomRender(value)
          }
        }
      },
      {
        name: COLUMNS.locationServices.id,
        label: COLUMNS.locationServices.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.locationServices,
          customFilterListOptions: { render: v => `${COLUMNS.locationServices.label}: ${v}` },
          filterList: this.handleTableFilters(5),
          filterOptions: {
            names: COLUMNS.locationServices.filter
          },
          customBodyRender: location=> <p>{location}</p>
        }
      },
      {
        name: COLUMNS.socketIO.id,
        label: COLUMNS.socketIO.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.socketIO,
          customFilterListOptions: { render: v => `${COLUMNS.socketIO.label}: ${v}` },
          filterList: this.handleTableFilters(6),
          filterOptions: {
            names: COLUMNS.socketIO.filter
          },
          customBodyRender: socket=> <p>{socket}</p>
        }
      },
      {
        name: COLUMNS.background.id,
        label: COLUMNS.background.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.background,
          customFilterListOptions: { render: v => `${COLUMNS.background.label}: ${v}` },
          filterList: this.handleTableFilters(7),
          filterOptions: {
            names: COLUMNS.background.filter
          },
          customBodyRender: background=> <p>{background}</p>
        }
      },
      {
        name: COLUMNS.connected.id,
        label: COLUMNS.connected.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.connected,
          customFilterListOptions: { render: v => `${COLUMNS.connected.label}: ${v}` },
          filterList: this.handleTableFilters(8),
          filterOptions: {
            names: COLUMNS.connected.filter
          },
          customBodyRender: value=> <p>{value}</p>
        }
      },
      {
        name: COLUMNS.model.id,
        label: COLUMNS.model.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.model,
          customBodyRender: value => <p>{value}</p>,
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(9),
          customFilterListOptions: {
            render: model => `${COLUMNS.model.label}: ${model}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(model, filters) {
              return STRING_FILTER_LOGIC(model, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.model)
          }
        }
      },
      {
        name: COLUMNS.team.id,
        label: COLUMNS.team.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.team,
          setCellProps: () => ({ style: {width:'120px'}}),
          customBodyRender: value => ( 
            this.renderTeam(value)
          ),
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(10),
          customFilterListOptions: {
            render: team => `${COLUMNS.team.label}: ${team}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(team, filters) {
              return USER_TEAM_FILTER(team, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.team)
          }
        }
      },
      {
        name: COLUMNS.usedBy.id,
        label: COLUMNS.usedBy.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.usedBy,
          customBodyRender: (value) => this.handleWorkerNameRender(value),
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(11),
          customFilterListOptions: {
            render: used => `${COLUMNS.usedBy.label}: ${used}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(used, filters) {
              return STRING_FILTER_LOGIC(used, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.used)
          }
        }
      },
      {
        name: COLUMNS.status.id,
        label: COLUMNS.status.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.status,
          customFilterListOptions: {
            render: status => `${COLUMNS.status.label}: ${status}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(12),
          filterOptions: {
            names: [],
            logic(status, filters) {
              return STRING_FILTER_LOGIC(status.value, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.status)
          },
          customBodyRender: value => (value && <div>
            {this.statusCustomRender(value)}
          </div>)
        }
      },
      {
        name: COLUMNS.out.id,
        label: COLUMNS.out.label,
        options: {
          sort:false,
          customHeadRender: (value, tableMeta)=>{
            return (<th key={`${this.headerKey++}out`} className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <p style={{marginLeft:"35%", marginTop:"15px", fontSize:"14px", color:"gray"}}>{COLUMNS.out.label}</p>
            </th>)
          },
          display:hiddenRows.out,
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(13),
          filterOptions: {
            names: [],
            logic(date, filters) {
              return FILTER_LOGIC_CHECKOUT(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, COLUMNS.out.label, COLUMNS.out.id)
          },
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], COLUMNS.out.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          customBodyRender: value => (value && <div>
            {this.checkoutButtonRender(value)}
          </div>)
        }
      },
      {
        name: COLUMNS.in.id,
        label: COLUMNS.in.label,
        options: {
          sort:false,
          customHeadRender: (value, tableMeta)=>{
            return (<th key={`${this.headerKey++}in`} className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <p style={{marginLeft:"35%", marginTop:"15px", fontSize:"14px", color:"gray"}}>{COLUMNS.in.label}</p>
            </th>)
          },
          display:hiddenRows.in,
          filterType:CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(14),
          filterOptions: {
            names: [],
            logic(date, filters) {
              return FILTER_LOGIC_CHECKIN(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, COLUMNS.in.label, COLUMNS.in.id)
          },
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], COLUMNS.in.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          customBodyRender: value => (value && <div>
            {this.checkinButtonRender(value)}
          </div>)
        }
      },
      {
        name: COLUMNS.lastUsed.id,
        label: COLUMNS.lastUsed.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.lastUsed,
          filterType:CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(15),
          filterOptions: {
            names: [],
            logic(date, filters) {
              return FILTER_LOGIC_FM(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, COLUMNS.lastUsed.label, COLUMNS.lastUsed.id)
          },
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], COLUMNS.lastUsed.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          customBodyRender: value => (value && <div>
            {this.renderLastLogout(value)}
          </div>)
        }
      },
      {
        name: COLUMNS.battery.id,
        label: COLUMNS.battery.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.battery,
          filterType:TEXTFIELD_FILTER_TYPE,
          customBodyRender: value => !!value? <p>{`${value}%`}</p> : <p>{}</p> ,
          filterList: this.handleTableFilters(16),
          customFilterListOptions: { render: v => `${COLUMNS.battery.label}: ${v}` }
        }
      },
      {
        name: COLUMNS.batteryState.id,
        label: COLUMNS.batteryState.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.batteryState,
          customBodyRender: value => <p>{value}</p>,
          filterList: this.handleTableFilters(17),
          filterOptions: {
            names: COLUMNS.batteryState.filter
          },
          customFilterListOptions: { render: v => `${COLUMNS.batteryState.label}: ${v}` }
        }
      },
      {
        name: COLUMNS.networkAvailable.id,
        label: COLUMNS.networkAvailable.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.networkAvailable,
          customFilterListOptions: { render: v => `${COLUMNS.networkAvailable.label}: ${v}` },
          filterList: this.handleTableFilters(18),
          filterOptions: {
            names: COLUMNS.networkAvailable.filter
          },
          customBodyRender: network=> <p>{network}</p>
        }
      },
      {
        name: COLUMNS.validNetwork.id,
        label: COLUMNS.validNetwork.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.validNetwork,
          customFilterListOptions: { render: v => `${COLUMNS.validNetwork.label}: ${v}` },
          filterList: this.handleTableFilters(19),
          filterOptions: {
            names: COLUMNS.networkAvailable.filter
          },
          customBodyRender: net=> <p>{net}</p>
        }
      },
      {
        name: COLUMNS.network.id,
        label: COLUMNS.network.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.network,
          customBodyRender: value => <p>{value}</p>,
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(20),
          customFilterListOptions: {
            render: network => `${COLUMNS.network.label}: ${network}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(network, filters) {
              return STRING_FILTER_LOGIC(network, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, COLUMNS.network.id)
          }
        }
      },
      {
        name: COLUMNS.label.id,
        label: COLUMNS.label.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.label,
          customBodyRender: value => <p>{value}</p>,
          filterType: CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(21),
          customFilterListOptions: {
            render: label => `${COLUMNS.label.label}: ${label}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(label, filters) {
              return STRING_FILTER_LOGIC(label, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, COLUMNS.label.id)
          }
        }
      },
      {
        name: COLUMNS.phoneNumber.id,
        label: COLUMNS.phoneNumber.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.phoneNumber,
          filterList: this.handleTableFilters(22),
          customFilterListOptions: { render: v => `${COLUMNS.phoneNumber.label}: ${v}` }
        }
      },
      {
        name: COLUMNS.silentMode.id,
        label: COLUMNS.silentMode.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.silentMode,
          customFilterListOptions: { render: v => `${COLUMNS.silentMode.label}: ${v}` },
          filterList: this.handleTableFilters(23),
          filterOptions: {
            names: COLUMNS.silentMode.filter
          }
        }
      },
      {
        name: COLUMNS.notificationStatus.id,
        label: COLUMNS.notificationStatus.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.notificationStatus,
          customFilterListOptions: { render: v => `${COLUMNS.notificationStatus.label}: ${v}` },
          filterList: this.handleTableFilters(24),
          filterOptions: {
            names: COLUMNS.notificationStatus.filter
          }
        }
      },
      {
        name: COLUMNS.appVersion.id,
        label: COLUMNS.appVersion.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.appVersion,
          filterList: this.handleTableFilters(25),
          filterType: CUSTOM_FILTER_TYPE,
          customBodyRender: value => <p>{value[0]}</p>,
          customFilterListOptions: {
            render: appVersion => `${COLUMNS.appVersion.label}: ${appVersion}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(appVersion, filters) {
              return STRING_FILTER_LOGIC(appVersion[0], filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, STRING_FILED_TYPE.appVersion)
          }
        }
      },
    ];
    return columns;
  };

  handleTableOptions = (data) =>{
    const tableOptions= {
      print: false,
      filter: true,
      filterType: CONSTS.FILTER_TYPE,
      responsive: CONSTS.RESPONSIVE,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.ROWS_PER_PAGE,
      selectableRowsHeader: false,
      selectableRows: CONSTS.SELECTABLE_ROWS,
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.map((col, i) => {
          let b = searchQuery.toLowerCase();
          if (i === 2){
            let a = col.UUID.toLowerCase();
            if (a.toString().includes(b)) {
              isFound = true;
            }
          }else if (i === 10){
            let a = col.role.toLowerCase();
            if (a.toString().includes(b)) {
              isFound = true;
            }
          }else{
            if (!!col) {
              let a = col.toString();
              if (a.toLowerCase().indexOf(b) >= 0) {
                isFound = true;
              }
            }
          }
          return null;
        });
        return isFound;
      },
      customSort: (data, colIndex, order) => {
        let sortType = order=== CONSTS.DEFAULT_SORT? true:false;
        return CONSTS.TABLE_SORT(data, colIndex, sortType);
      },
      downloadOptions: {
        filename: CONSTS.CSV_FILE_NAME,
        separator: CONSTS.CSV_SEPARATOR,
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true
        }
      },
      setRowProps: (row, dataIndex, rowIndex) =>{
        if (row[5].props.children !== CONSTS.AUTHORIZED_ALWAYS && row[5].props.children !== CONSTS.ALWAYS){
          return {
            style: { background: CONSTS.ROW_COLOR }
          };
        }
      },
      onTableChange:(action,tableState)=>{
        switch(action){
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({tableRowsPerPage: tableState.rowsPerPage});
            break
          case CONSTS.TABLE_EVENTS.viewColumnsChange:
            tableState.columns.forEach(col=>{
              if(this.state.hiddenRows.hasOwnProperty(col.name)){
                if(this.state.hiddenRows[col.name].toString()!== col.display){
                  let update = col.display===CONSTS.TRUE? true:false;
                  this.setState(prevState =>({
                  hiddenRows:{
                    ...prevState.hiddenRows,
                    [col.name]: update}
                  }));
                }
              }
            });
            break
          case CONSTS.TABLE_EVENTS.propsUpdate:
            if(this.filterBy.length!==0 && this.onFilter)
            tableState.filterList= this.filterBy;
            break
          case CONSTS.TABLE_EVENTS.search:
            if(tableState.searchText){
              this.searchBy = tableState.searchText;
              this.onSearch = true;
              this.handleChangeSwitch();
            }
            break
          case CONSTS.TABLE_EVENTS.filterChange:
            this.filterBy = tableState.filterList;
            this.onFilter = true;
            break
          case CONSTS.TABLE_EVENTS.onSearchClose:
            this.searchBy = '';
            this.onSearch = false;
            this.setState({isSearchClosed: true});
            break
          case CONSTS.TABLE_EVENTS.resetFilters:
            this.handleChangeSwitch();
            break
          default:
            break
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return CONSTS.CUSTOM_CSVDOWNLOAD(buildHead, buildBody, columns, data);
      },
      customToolbar: () => {
        return (
          <CustomToolbar
          handleChangeSwitch={this.handleChangeSwitch}
          handleShowArchive={this.handleShowArchive}
          table={CONSTS.TABLE_ID}
          data={data}
          refresh={this.state.config.refreshFM}
          filterChips={this.filterBy}/>
        );
      }
    };
    return tableOptions;
  };

  handleFillTableRows = (currentPhones) =>{
    const { PHONES_MAPPER } = CONSTS;
    const { roles, acl } = this.props;

    const tableData = currentPhones
      ? currentPhones
        .filter((phone) => {
          let userTeam = phone.phone_user? phone.phone_user.role: '';
          if (this.filterPhonesTeams(userTeam, phone.teams)) {
            return true;
          } else {
            return false;
          }
        }).map((phone, index) =>
          PHONES_MAPPER(phone, roles, index, acl,this.props.models))
      : [];

      return tableData;
  };

  handleFillListsFromTableData = (tableData) =>{
    tableData.forEach(row=>{
      if (!!row.UUID && !this.devicesList.includes(row.UUID)) {
        this.devicesList.push(row.UUID);
      }
      if (!!row.model && !this.modelsList.includes(row.model) ) {
        this.modelsList.push(row.model);
      }
      if (!!row.network && !this.networksList.includes(row.network) && row.network!=="-") {
        this.networksList.push(row.network);
      }
      if (!!row.label && !this.labelList.includes(row.label) && row.label!=="-") {
        this.labelList.push(row.label);
      }
      if (!!row.assign && !this.assignedToList.includes(row.assign.userName)  && row.assign!=="-" && row.assign!=="" && row.assign!=="none" && row.assign.hasOwnProperty('userName')) {
        this.assignedToList.push(row.assign.userName);
      }
      if (!!row.usedBy && !this.usedByList.includes(row.usedBy) && row.usedBy!=="-") {
        this.usedByList.push(row.usedBy);
      }
      if (!!row.versionAndBuild && !this.appVersionList.includes(row.versionAndBuild) && row.versionAndBuild!=="-") {
        this.appVersionList.push(row.versionAndBuild);
      }
    });
  };

  handleSortTableData = (tableData) =>{
    const { CHAT } = CONSTS;

    let tableSorted = tableData.sort((a, b) => {
      let dateA = '', dateB = '';
      if(a.lastUsed.timestamp === undefined) {dateA = undefined;}
      if(b.lastUsed.timestamp === undefined) {dateB = undefined;}
      if(dateA === '' && dateB === ''){
        dateA = a.lastUsed.timestamp;
        dateB = b.lastUsed.timestamp;
      }
      if (CHAT[a.status.value].value > CHAT[b.status.value].value) {return -1}
      if (CHAT[a.status.value].value < CHAT[b.status.value].value) {return 1}
      else {return (dateB === undefined) - (dateA === undefined) || new Date(dateA) - new Date(dateB)}
    });

    return tableSorted.reverse();
  };

  determineIfCurrentDeviceIsStreamingLocation = (userId, deviceId) =>{
    const { lastlocationSocket } = this.props;
    const { YES,NO,} = CONSTS;
    
    let isDeviceStreamingLoc = NO;
    let locationKeys = Object.keys(lastlocationSocket);
    locationKeys.forEach(location=>{
      if (userId === lastlocationSocket[location].userId && deviceId === lastlocationSocket[location].dId && lastlocationSocket[location].status === 'online'){
        isDeviceStreamingLoc = YES;
      }
    });

    return isDeviceStreamingLoc;
  }

  handleUpdateRowsFromStream = (data) =>{
    let tableData = [...data];
    tableData.map((row, index)=>{
      row.number = ++index;
      row.location = this.determineIfCurrentDeviceIsStreamingLocation(row.findPhone.userId, row.identifier.UUID);

      return null;
    });
    return tableData;
  };

  handleApplyTableFilters = () =>{
    let applyFilters = false;
    if (this.filterBy.length !== 0){
      this.filterBy.forEach(filter=>{
        if(filter.length){
          applyFilters = true
        }
      });
      if(!applyFilters){
        this.handleChangeSwitch();
      }
    }
  };

  handleReturnTableData = (tableData) =>{
    let data = [];
    if (!!tableData) {
      this.handleFillListsFromTableData(tableData);
      let dataSorted = this.handleSortTableData(tableData);
      data = this.handleUpdateRowsFromStream(dataSorted);
      this.handleApplyTableFilters();
    }
    return data;
  };

    /**
  * Loads and formats data to be displayed on table
  */
  handleLoadTableData = () => {
    this.handleFilterTeams();
    const currentPhones = this.handleFilterPhones();
    const tableData = this.handleFillTableRows(currentPhones);
    const data = this.handleReturnTableData(tableData);
    const columns = this.handleTableColumns();
    const options = this.handleTableOptions(data);
    return {data, columns, options}
  };

  tableTitle=()=>{
    const {classes} = this.props;
    return(
      <div className={classes.titleContainer}>
          <div className={classes.titleIconContainer}>
            <AppsIcon className={classes.titleIcon}/>
          </div>
          <h2 className={classes.titleLbl}>{CONSTS.TABLE_TITLE}</h2>
      </div>)
  }

  render() {
    const { classes, users } = this.props;
    const {data, columns, options} = this.handleLoadTableData();

    let l= this.props.open? '23%': '4%';
    let w= this.props.open? '75.5%': '95%';
    let dialogTitle = this.state.config.dialogTitle;
    return (
      <div id="webNavv-tableView-fleetManager-1.0" style={{marginTop:'5%',marginLeft:l, width:w}}>
        {this.state.showTable && (
        <div>
          <div>
            <MasterTable
              title={this.tableTitle()}
              data={data}
              columns={columns}
              options={options}
            />
          </div>
          <div id="webNavv-tableView-fleetManager-newPhone-1.0">
            <Dialog
              style={{left:0,top:0, width:"700px"}}
              classes={{ paper: classes.paper}}
              open={this.state.config.open}
              onClose={this.handleClose}
            >
            <div className={classes.dialogTitleMainDiv}>
             <img src={dialogVector} className="d-vector" alt="d-Vector" />
               <div className={classes.dialogContainer}>
                <PhoneIphoneIcon className={classes.dialogIcon}/>
                <h3 className={classes.dialogTitleLbl}>{dialogTitle}</h3>
               </div>
            </div>
            <AddPhone
              classes={classes}
              roles={this.props.roles}
              users={users}
              handleCloseDialog={this.handleClose}
              handleOpenSnackbar={this.handleOpenSnackbar}
              phones={this.props.phones}
              isUpdate={this.state.config.isUpdate}
              getPhones={this.props.getPhones}
              phoneModels={this.props.phoneModels}
              disableSaveButton={this.state.disableSaveButton}
              statusOfDevice={this.state.statusOfDevice}
            />
            </Dialog>
          </div>
          <div id="webNavv-tableView-fleetManager-alert-1.0">
            <MasterAlert
            snackStyle={{marginTop:'5vh'}}
            anchor={{ vertical: 'top', horizontal: 'right' }}
            openSnack={this.state.openSnack}
            handleClose={this.handleCloseSnackbar}
            alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
            elevation={6}
            variant={CONSTS.ALERT_VARIANT_FILLED}
            type={CONSTS.ALERT_SUCCESS_TYPE}
            message={this.state.snackMsg}
            />
          </div>
          <div id="webNavv-tableView-fleetManager-addPhoneButton-1.0" className="add-button">
            <Tooltip title={CONSTS.ADD_BUTTON_TITLE}>
              <FloatingActionButton
                  backgroundColor={'#039be5'}
                  onClick={() =>
                    this.setState({
                     config:{
                      open: true,
                      isUpdate: false,
                      dialogTitle: CONSTS.NEW_PHONE,
                      refreshFM:this.state.config.refreshFM
                     },
                      phoneInfo: {
                        phoneId: "",
                        newSSID: "",
                        newBattery: "",
                        newAssetTag: "",
                        newUUID: "",
                        teams: [],
                        team:'',
                        assignValue: "",
                        model: "",
                        newUsed: "",
                        timestamp: "",
                        newLabel: "",
                        active: true,
                        phoneNum: ""
                      }
                    })
                  }
              >
              <ContentAdd />
              </FloatingActionButton>
            </Tooltip>
          </div>
        </div>
        )}
        {this.state.showSpinner && (
          <div id="webNavv-tableView-fleetManager-spinner-1.0">
            <AwesomeSpinner config={CONSTS.SPINNER_CONFIG} loading={this.state.showSpinner} navOpen={this.props.open} />
          </div>
        )}
      </div>
    );
  }
}

PhonesControl.propTypes = {
  access_token: PropTypes.string.isRequired,
  phones: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  changeView: PropTypes.func.isRequired,
  fetchACLS: PropTypes.func.isRequired,
  getPhones: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired,
  forceLogout: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  phoneToUpdateInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  access_token: state.auth.token,
  phones: state.phones.phones,
  users: state.user.users,
  usersObj: state.user.usersObj,
  loggedUser: state.user.user,
  acl: state.auth.acl,
  userId: state.auth.userId,
  user: state.user.user,
  roles: state.acl.acls,
  isUserOnline: state.phones.pubnubSub,
  socketChat:state.auth.socketChat,
  open:state.views.drawerOpen,
  phoneModels:state.modelPhones.models,
  campusInfo: state.auth.userInfo.campusInfo,
  models: state.modelPhones.models,
  lastlocationSocket: state.lastlocation.lastlocationSocket
});
export default connect(mapStateToProps, {
  changeView,
  fetchACLS,
  getPhones,
  getPhoneInfo,
  listUsers,
  forceLogout,
  updateUser,
  phoneToUpdateInfo,
  handleCreateToken,
  handleFindToken,
  getModels,
  createLog,
  cleanCommentsByPhoneList
})(withStyles(styles)(PhonesControl));