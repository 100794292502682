import {loadConfig} from '../../actions/auth/authActions';
import {socketPath} from '../utilities/constants';
class hostName{
    constructor(){
        this.host= process.env.NODE_ENV == 'development' ? window.location.hostname : `services-${window.location.hostname}`;
        this.protocol = window.location.protocol;     
    }

    get hostUrl(){
        return this._host;
    }
    get cloudPath(){
        return this._cloudPath;
    }
    get localPath(){
        return this._localPath;
    }
    get chatPath(){
        return this._socketChatPath;
    }    
    
     initHostName(){                
        if(this.isSecure(this.protocol)){
            this._host=`${this.protocol}//${this.host}`                       
        }else{
            this._host=`${this.protocol}//${this.host}:3050`
        }

    }

    initPath(){
        return new Promise(async(resolve,reject)=>{
            //const socketPath = await loadConfig();
            const {socketChatPath,socketLocationPath} = socketPath;
            this.isSecurePath({socketLocationPath});
            this.isSecurePathChat({socketChatPath});
            resolve(true);
        }).catch(error=>`An error has ocurred trying to initialize socket path, Error:${error}`)
    }

    isSecure(){
        return this.protocol.includes("https");
    }
    isSecurePath({socketLocationPath}){
        this._localPath =(this.isSecure())?socketLocationPath.local.https:socketLocationPath.local.http;
        this._cloudPath =(this.isSecure())?socketLocationPath.cloud.https:socketLocationPath.cloud.http;
    }
    isSecurePathChat({socketChatPath}){
        this._socketChatPath=(this.isSecure())?socketChatPath.https:`${socketChatPath.http}`;
    }
}

export default hostName
