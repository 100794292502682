import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = () => ({
  JobsBoard_JobRouteIcon:{
    color:"#D6D6D6",
    fontSize: "16px",
    marginTop:"6px"
  },
  JobsBoard_blueButton: {
    fontSize: '12px',
    marginLeft:"20px",
    color: "white",
    textTransform: "none !important",
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  JobsBoard_Container:{
    marginTop:"100px",
    marginLeft: "100px",
    marginRight:"50px"
  },
  JobsBoard_ProblemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  }
});
