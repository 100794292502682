import { STATUS_STYLES } from "../jobsBoard.constants";
import { jobStatusTypesGraphQL } from "../jobsBoard.constants";

export const  handleGetJobColor = (jobStatus) =>{
    switch(jobStatus){
      case jobStatusTypesGraphQL.inProgress:
        return STATUS_STYLES.inProgress.color;
      case jobStatusTypesGraphQL.inProgressWithError:
        return STATUS_STYLES.inProgressWithError.color;
      case jobStatusTypesGraphQL.complete:
        return STATUS_STYLES.complete.color;
      case jobStatusTypesGraphQL.completeWithError:
        return STATUS_STYLES.completeWithError.color;
      case jobStatusTypesGraphQL.error:
        return STATUS_STYLES.error.color;
      default:
        return STATUS_STYLES.unassigned.color;
    }
}