import { createMuiTheme } from "@material-ui/core/styles";

export const TABLE_TITLE = "LOGS";
export const FROM = 'From:';
export const TO = "To:";
export const START = 'start';
export const END = 'end';
export const START_DATE = "Start Date";
export const END_DATE = "End Date";
export const SIMPLE_DATE = "MM/DD/YYYY";
export const FILTERED = 'filtered';
export const ALL = 'all';
//export const DATE_FORMAT="MM/DD/YYYY HH:mm:ss";
export const DATE_FORMAT = "MM/DD/YYYY";
export const INPUT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const CAMPUS = 'Campus';
export const SEARCH = 'Search';
export const CANCEL = 'Cancel';
export const APPLY = 'Apply';
export const EXPORT = 'Export';
export const DEFAULT_DIFF_DATE = 7;
export const EMPTY_VALUE = "";
export const TABLE_EVENTS = {
  changeRowsPerPage: 'changeRowsPerPage'
};
export const TABLE_CONFIG = {
  filterType: "dropdown",
  responsive: "vertical",
  rowsPerPageOptions: [7, 10, 20, 30, 50, 100, 500, 1000],
  selectableRows: 'none',
  filterTable: 'Filter Table'
};
export const COLUMNS = {
  action: {
    no: 0,
    id: 'action',
    label: 'Action'
  },
  description: {
    no: 1,
    id: 'description',
    label: 'Description'
  },
  performedBy: {
    no: 2,
    id: 'performedBy',
    label: 'ID'
  },
  timestamp: {
    no: 3,
    id: 'timestamp',
    label: 'Timestamp'
  },
  id: {
    no: 4,
    id: 'id',
    label: 'ID'
  }
};
export const CSV_CONFIGS = {
  fileName: 'Logs-SuperAdmin',
  defaultName: 'Logs-SuperAdmin.csv',
  extension: '.csv',
  blobType: 'text/csv;charset=utf-8;'
};

export const TABLE_SORT = (data, colIndex, order) => {
  if (colIndex === 0 || colIndex === 1) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? -1 : 1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order === "desc" ? -1 : 1);
    });
  }
};

export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: "70vh !important",
        },
        paper: {
          boxShadow: "0 4px 2px -2px gray",
          paddingTop: "1px",
        },
      },
      MUIDataTableToolbar: {
        left: {
          width: '100%'
        }
      },
      MuiButton: {
        text: {
          padding: '6px 8px',
          display: 'flex',
          marginLeft: '30px !important'
        }
      },
      MuiTableCell: {
        root: {
          padding: 'none'
        }
      },
      MUIDataTableHeadCell: {
        toolButton: {
          color: "#7D7D7D",
          float: 'none !important'
        },
        contentWrapper: {
          justifyContent: "center",
          display: "flex",
        }
      },
      MUIDataTableBodyCell: {
        root: {
          textAlign: "center"
        },
      },
    },
  });