/**
 * This method filter the given users based on the allowed roles list
 * @param {Array} users 
 * @param {Array} allowedRoles 
 * @returns {Array} filtered
 */
export const filterUsers = (users,allowedRoles) => {
    let filtered=[];

    if (users !== undefined && allowedRoles !== undefined) {
        filtered = users.filter(user => allowedRoles.includes(user.role));
    }
    return filtered;
}

export const filterOnlyTransporters =(users,acls)=>{
    try{
        return users.filter(user=>
            acls.find(acl=>user.role === acl.id && acl.transporter === true) !== undefined)
    }catch(error){
        console.error("[FILTERONLYTRANPOSRTERS] an error in filter 'OnlyTransporters' ocurred", error);
    }


}