import moment from "moment";
import { exportCSVFile } from "../../utilities/exportCSV";

export const handleTracesWithTimeWindow = (dates, historyRoute) => {
    let historyRouteWindow = [];
    let startDate = moment(moment(dates[0]).toISOString());
    let endDate = moment(moment(dates[1]).toISOString());
    historyRoute.map(trace => {
      let date = moment(trace.create_date);
      if (date >= startDate && date <= endDate){
        historyRouteWindow.push(trace);
      }
      return null;
    });
    return historyRouteWindow;
};

export const getWindowNewDistances = (windowDates, animationDates, historyRoute, animationView) => {
    let tracesWithWindow = [];
    if (animationView) {
      tracesWithWindow = handleTracesWithTimeWindow(animationDates, historyRoute);
    }
    else {
      tracesWithWindow = handleTracesWithTimeWindow(windowDates, historyRoute);
    }
    let tracesPerJobList = getTracesPerJob(tracesWithWindow);
    const windowDistances = calculateHistoryRoute(tracesPerJobList);

    return windowDistances;
};

export  const getTracesPerJob = (traceSegment) => {
    let grouped = Object.create(null);
    traceSegment.forEach(function (a) {
      grouped[a.jobId] = grouped[a.jobId] || [];
      grouped[a.jobId].push(a);
    });
    return Object.values(grouped);
};

export const handleSortJobDates = (jobInfo) => {
    let startDates = [];
    let endDates = [];

    jobInfo.map(job => {
      startDates.push(moment(job.startDate).format("YYYY-MM-DDTHH:mm:ss"));
      endDates.push(moment(job.endDate).format("YYYY-MM-DDTHH:mm:ss"));
      return null;
    });
    startDates = startDates.sort();
    endDates = endDates.sort();
    const startDate = startDates[0];
    const endDate = endDates[endDates.length - 1];
    return [startDate, endDate];
};

export const getJobStateList = (jobs) => {
    let list = [];
    jobs.map(job => {
      list.push([job, true]);
      return null;
    });
    return list;
};

export const calculateHistoryRoute = (jobs) => {
    let windowDistances = [];
    jobs.forEach(traces => {
      let totalDistance = [];
      traces.forEach((distance, index) => {
        let initialDistance = (index === 0) ? index : index + 1;
        let nextDistance = initialDistance + 1;
        if (traces[nextDistance] !== undefined) {
          let shortDistance = calculateDistance(traces[initialDistance].geolocation.latitude, traces[initialDistance].geolocation.longitude, traces[nextDistance].geolocation.latitude, traces[nextDistance].geolocation.longitude);
          totalDistance.push(shortDistance);
        }
      });
      windowDistances.push([traces[0].jobId, totalDistance.reduce((a, b) => a + b, 0)]);
    });
    return windowDistances;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d / 1.6;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};


export  const getWorkerName = (users, historyRoute) => {
    let grouped = Object.create(null);
    historyRoute.forEach(function (a) {
      grouped[a.jobId] = grouped[a.jobId] || [];
      grouped[a.jobId].push(a);
    });
    const jobs = Object.values(grouped);
    let historyUser = "";
    let historyJobIds = [];
    let historyUsers = [];
    let historyUserInfo = [];

    jobs.map((job, i) => {
      if (!historyJobIds.includes(job[0].jobId)) {
        historyJobIds.push(job[0].jobId);
      }
      historyUser = job[0].user_id;
      historyUserInfo = users.filter(res => res._id === historyUser);
      historyUsers.push(historyUserInfo);
      return null;
    });
    return (historyUsers.length > 0) ? [historyJobIds, historyUsers, jobs] : { error_message: "There are not users that make match" };
};

export const CSV_CONFIGS={
  fileName:'HistoryTraces',
  defaultName:'HistoryTraces.csv',
  extension:'.csv',
  blobType:'text/csv;charset=utf-8;'
};

export const generateUserJobCSV = (historyTracesForCSV, jobInfo) => {
    let itemsCopy = [];
    historyTracesForCSV.map(item => {
      let currentJobInfo = jobInfo.find(element => element.job === item.jobId);
      let madeforTH = (item.madeForTraceHealing !== '' && item.madeForTraceHealing !== null && item.madeForTraceHealing !== undefined) ? 'TRUE' : 'FALSE';
      itemsCopy.push({
        id: item._id,
        jobId: item.jobId,
        logId: item.log_id,
        chunkId: item.chunkId,
        userId: item.user_id,
        latitude: item.geolocation.latitude,
        longitude: item.geolocation.longitude,
        floor: item.geolocation.floor,
        isNetworkAvailable: item.isNetworkAvailable,
        isSocketIOEnabled: item.isSocketIOEnabled,
        deviceId: currentJobInfo.device,
        create_date: item.create_date,
        madeForTraceHealing: madeforTH
      });
      return null;
    });
    let headers = Object.keys(itemsCopy[0]);
    let fileTitle = 'History-Traces';
    exportCSVFile(headers, itemsCopy, fileTitle, CSV_CONFIGS);
};

export const generateJobCSV = (historyTracesForCSV, jobInfo) => {
  let itemsCopy = [];
  historyTracesForCSV.map(item => {
    let madeforTH = (item.madeForTraceHealing !== '' && item.madeForTraceHealing !== null && item.madeForTraceHealing !== undefined) ? 'TRUE' : 'FALSE';
    itemsCopy.push({
      id: item._id,
      jobId: item.jobId,
      logId: item.log_id,
      chunkId: item.chunkId,
      userId: item.user_id,
      latitude: item.geolocation.latitude,
      longitude: item.geolocation.longitude,
      floor: item.geolocation.floor,
      isNetworkAvailable: item.isNetworkAvailable,
      isSocketIOEnabled: item.isSocketIOEnabled,
      deviceId: jobInfo.device,
      create_date: item.create_date,
      madeForTraceHealing: madeforTH
    });
    return null;
  });
  let headers = Object.keys(itemsCopy[0]);
  let fileTitle = 'History-Traces';
  exportCSVFile(headers, itemsCopy, fileTitle, CSV_CONFIGS);
};

export const CSV_JOB_HISTORY_CONFIGS={
  fileName:'JobHistory',
  defaultName:'JobHistory.csv',
  extension:'.csv',
  blobType:'text/csv;charset=utf-8;'
};