export const styles = () =>({
  problemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  },
  inlineContainer: {
    display: 'inline'
  },
  titleContainer:{
    display:'flex',
    marginTop:"20px"
  },
  titleIcon:{
    fill:'#2843A3',
    fontSize:'50px',
    opacity:0.7
  },
  titleIconDiv:{
    marginTop:'1%'
  },
  titleLbl:{
    color:'#2843A3',
    marginLeft:'1%'
  },
  boldSnackMsg: {
    display:'inline',
    fontWeight:'bold'
  },
  addButton: {
    position: 'sticky',
    marginLeft: '95%',
    bottom:'20vh'
  }
});