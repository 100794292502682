import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { sendLoginAdminParams, allUsers, getSuperAdmins, setAdminToken } from '../../actions/superAdmin/superAdminActions';
import { getCampuses } from '../../actions/campus/campusActions';
import { adminACLS, fetchAllACLS, fetchRoles } from '../../actions/acl/aclActions';
import { setFeatureFlags } from '../../actions/auth/authActions';

import VerticalTabs from './sideBar.component';
import MasterTab from './../dashboard/leftNav/tabs/tab.component';
import UserAdmin from './users/userAdmin.component';
import LogsTable from './logs/logsTable.component';
import SuperDashboard from './dashboard/superDashboard.component';
import SuperStatistics from './statistics/superStatistics.component';
import SuperAdminToolbar from './toolbar.component';
import miniLogo from "../../img/ntrackLogo.png";
import RemoteConfig from './remoteConfig.component';
import { TABS_TITLE, SET_TABS, DEFAULT_OPTION, DRAWER_WIDTH, DEFAULT_SELECTOR } from './superAdmin.constants';

const ADMIN_TOKEN = "adminToken";
const ADMIN_INFO = "adminInfo";

const drawerWidth = DRAWER_WIDTH;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidth,
        zIndex: 999,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: '0%',
        marginTop: 50
    },
    minilogo: {
        height: "30%",
        width: "10%",
        marginTop: "3%",
        marginLeft: "4%",
    },
    navvTrackTitle: {
        marginLeft: "5%",
        color: "#2843A3",
    },
}));



function SuperPanel(props) {
    const classes = useStyles();
    const theme = useTheme();
    //state
    const [open, setOpen] = useState(true);
    const [optionMenu, setOptionMenu] = useState(DEFAULT_OPTION);
    const [id, setId] = useState(props.user.id || null);
    const [tabsInfo, setTabsInfo] = useState(SET_TABS(props));

    const { first_name, last_name } = props.user;
    const ADMIN_TOKEN = "adminToken";
    const ADMIN_INFO = "adminInfo";

    useMemo(() => {
        if (!id) {
            let adminToken = localStorage.getItem(ADMIN_TOKEN);
            if (adminToken) {
                let adminInfo = JSON.parse(localStorage.getItem(ADMIN_INFO));
                props.sendLoginAdminParams(adminToken, adminInfo).then(async (result) => {
                    await props.getCampuses();
                    await props.allUsers();
                    await props.adminACLS();
                    await props.getSuperAdmins();
                    await props.fetchRoles();
                    await props.fetchAllACLS();
                    setId(adminInfo.id);
                }).catch(err => {
                    props.history.push('/');
                })
            }
            else {
                props.history.push('/');
            }
        }
    }, []);

    useMemo(() => {
        if (!props.auth.tokenMK) {
            let adminToken = localStorage.getItem(ADMIN_TOKEN);
            props.setAdminToken(adminToken).then((result) => {
            });
        }
    }, [props.auth.tokenMK]);

    useMemo(() => {
        const { featureFlags } = props;
        const allTabs = SET_TABS(props);
        const flagTabs = allTabs.filter((tab) => !!featureFlags.Superadmin[tab.ff]);
        setTabsInfo(flagTabs);
    }, [props.featureFlags]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const changeOptionMenu = (view, viewProp, label) => {
        setOptionMenu(label);
    };

    const renderMainContent = () => {
        switch (optionMenu) {
            case 'DA':
                //section for Dashboard
                return <SuperDashboard />
                break;
            case 'ST':
                //section for Statistics
                //return <SuperStatistics />;
                return null
                break;
            case 'US':
                //section for Users
                return (<UserAdmin />);
                break;
            case 'EQ':
                //section for Equipment
                return null
                break;
            case 'DT':
                //section for Debug Tool
                return null;
                break;
            case 'LO':
                //section for Logs
                return <LogsTable campusInfo={DEFAULT_SELECTOR} />
                break;
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <RemoteConfig setFeatureFlags={props.setFeatureFlags} />
            <VerticalTabs toggleDrawer={toggleDrawer} />
            <div className={classes.root}>
                <SuperAdminToolbar isOpen={open} first_name={first_name} last_name={last_name} toggleState={open} />
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.hide]: !open
                        })
                    }}
                >
                    <div className={classes.toolbar}>
                        <div style={{ display: "flex" }}>
                            <img
                                src={miniLogo}
                                className={classes.minilogo}
                                alt="miniLogo"
                            />
                            <h3 className={classes.navvTrackTitle}>NavvTrack</h3>
                            <IconButton
                                style={{ marginLeft: "25%", backgroundColor: 'transparent' }}
                                onClick={toggleDrawer}
                            >
                                <MenuIcon style={{ fill: "#2843A3" }} />
                            </IconButton>
                        </div>
                    </div>
                    <Divider />
                    <div
                        id="webNavv-superAdmin-navigation-1.0"
                        style={{ marginLeft: 45 }}
                    >
                        <MasterTab
                            tabTitle={TABS_TITLE}
                            tabs={tabsInfo}
                            itemSelected={optionMenu}
                            handleChangeView={changeOptionMenu}
                        />
                    </div>
                </Drawer>
                <main className={classes.content}>
                    {id && renderMainContent()}
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    users: state.user.users,
    user: state.superAdmin.user,
    auth: state.auth,
    featureFlags: state.auth.featureFlags
});

export default connect(mapStateToProps, { sendLoginAdminParams, allUsers, getSuperAdmins, getCampuses, adminACLS, fetchAllACLS, setAdminToken, fetchRoles, setFeatureFlags })(SuperPanel);
