export const GET_PHONES = 'GET_PHONES';
export const GET_PHONE = 'GET_PHONE';
export const GET_ALL_PHONES = 'GET_ALL_PHONES';
export const UPDATE_PHONES = 'UPDATE_PHONES';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const GET_PUBNUB_SUBSCR = 'GET_PUBNUB_SUBSCR';
export const PHONE_TO_UPDATE = 'PHONE_TO_UPDATE';
export const CREATE_PHONE_COMMENT = 'CREATE_PHONE_COMMENT';
export const GET_PHONE_COMMENTS = 'GET_PHONE_COMMENTS';
export const CLEAN_PHONE_COMMENTS = 'CLEAN_PHONE_COMMENTS';
export const DUPLICATED_PHONE = 'DUPLICATED_PHONE';