import {
  CHANGE_DASHBOARD_CONTENT,
  CHANGE_CALLOUTS,
  CHANGE_TRACES,
  CHANGE_ACTIVE_FLOOR,
  DRAWER_CHANGE,
  DEFAULT_VIEW_LOADED,
  CHANGE_ACTIVE_USER,
  CHANGE_TIME_WINDOW,
  CHANGE_HIDE_JOB,
  CHANGE_WIFI,
  CHANGE_GEOJSON_JOB,
  CHANGE_CENTER_HISTORY_MAP,
  OPEN_HISTORY_TABLE,
  CHANGE_SMALL_CALLOUTS,
  STORE_HISTORY_TRACES,
  CHANGE_PEOPLE_FILTER,
  CHANGE_EQUIPMENT_FILTER,
  SET_MENU_VALUE, ZOOM_ON_USER,
  USER_CHAT,
  USER_CHAT_UPDATED,
  UNREAD_MSG_LIST,
  ZOOM_ON_EQUIPMENT,
  OPEN_BROADCAST_CHAT,
  UNREAD_BROADCAST_CHAT,
  ANIMATION_SHOW_TRACES,
  ANIMATION_SHOW_CALLOUTS,
  ANIMATION_CURRENT_FLOOR_ONLY,
  CHANGE_INDOOR_ONLY,
  CENTER_MAP,
  REMOTE_SELECT_TAB,
  SET_LAST_VIEWPROPS,
  SET_MAP_CONFIGS} from '../actions/views/types';

const initialState = {
  currentView: "map",
  historyRoute: false,
  jobTasks: false,
  transporterFound: false,
  transporterPhone: false,
  allCallouts: true,
  smallCallouts: false,
  traces: false,
  activeFloor: false,
  activeUser: false,
  drawerOpen: true,
  isDefaultViewLoaded: false,
  viewProps: "",
  timeWindow: [],
  jobsToHide: [],
  wifi: false,
  geoJSONJob: {},
  centerHistoryMap: {},
  jobInfo: [],
  historyTracesForCSV: [],
  peopleMapFilter: true,
  equipmentMapFilter: false,
  currentMenuValue: "",
  userChat: "",
  userChatUpdated: undefined,
  unreadMsgsList: [],
  openBroadcast: false,
  unreadBroadcast: false,
  equipmentSelected: "",
  directContactsLocation: false,
  animationShowTrace: true,
  animationShowCallouts: true,
  animationCurrentFloorOnly: true,
  indoorOnly: false,
  centerActiveMapFlag: '',
  remoteSelectTab: {},
  lastViewProps: "",
  mapConfigs: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DRAWER_CHANGE:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case CHANGE_DASHBOARD_CONTENT:
      let open = true; //false
      // if(action.payload.currentView=="map")
      //     open=true
      return {
        ...state,
        currentView: action.payload.currentView,
        historyRoute: action.payload.historyRoute,
        jobTasks: action.payload.jobTasks,
        transporterFound: action.payload.transporterFound,
        transporterPhone: action.payload.transporterPhone,
        jobInfo: action.payload.jobInfo,
        allCallouts: action.payload.allCallouts,
        viewProps: action.payload.viewProps,
        drawerOpen: open,
        timeWindow: action.payload.timeWindow,
        jobsToHide: action.payload.jobsToHide,
        wifi: action.payload.wifi,
        geoJSONJob: action.payload.geoJSONJob,
        centerHistoryMap: action.payload.centerHistoryMap,
        openHistoryTable: action.payload.openHistoryTable,
        directContactsLocation: action.payload.directContactsLocation,
        //pagination: action.payload.pagination
      };

    case SET_MENU_VALUE:
      return {
        ...state,
        currentMenuValue: action.payload,
      };
    case CHANGE_CALLOUTS:
      return {
        ...state,
        allCallouts: action.payload,
      };
    case CHANGE_SMALL_CALLOUTS:
      return {
        ...state,
        smallCallouts: action.payload,
      };
    case CHANGE_TRACES:
      return {
        ...state,
        traces: action.payload,
      };
    case CHANGE_ACTIVE_FLOOR:
      return {
        ...state,
        activeFloor: action.payload,
      };
    case CHANGE_EQUIPMENT_FILTER:
      return {
        ...state,
        equipmentMapFilter: action.payload,
      };
    case CHANGE_PEOPLE_FILTER:
      return {
        ...state,
        peopleMapFilter: action.payload,
      };
    case CHANGE_TIME_WINDOW:
      return {
        ...state,
        timeWindow: action.payload,
      };
    case CHANGE_HIDE_JOB:
      return {
        ...state,
        jobsToHide: action.payload,
      };
    case CHANGE_WIFI:
      return {
        ...state,
        wifi: action.payload,
      };
    case CHANGE_GEOJSON_JOB:
      return {
        ...state,
        geoJSONJob: action.payload,
      };
    case STORE_HISTORY_TRACES:
      return {
        ...state,
        historyTracesForCSV: action.payload,
      };
    case CHANGE_CENTER_HISTORY_MAP:
      return {
        ...state,
        centerHistoryMap: action.payload,
      };
    case CHANGE_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload,
      };
    case DEFAULT_VIEW_LOADED:
      return {
        ...state,
        isDefaultViewLoaded: true,
      };
    case OPEN_HISTORY_TABLE:
      return {
        ...state,
        viewProps: action.payload,
      };
    case ZOOM_ON_EQUIPMENT:
      return {
        ...state,
        equipmentSelected: action.payload.equipmentSelected,
        // allCallouts: action.payload.allCallouts,
      };
    case ZOOM_ON_USER:
      return {
        ...state,
        transporterFound: action.payload.transporterFound,
        transporterPhone: action.payload.transporterPhone,
        allCallouts: action.payload.allCallouts,
      };
    case USER_CHAT:
      return {
        ...state,
        userChat: action.payload.userChat,
      };
    case USER_CHAT_UPDATED:
      return {
        ...state,
        userChatUpdated: action.payload.userChatUpdated,
      };   
    case UNREAD_MSG_LIST:
      return {
        ...state,
        unreadMsgsList: action.payload.unreadMsgsList,
      };
    case OPEN_BROADCAST_CHAT:
      return {
        ...state,
        openBroadcast: action.payload,
      };
    case UNREAD_BROADCAST_CHAT:
      return {
        ...state,
        unreadBroadcast: action.payload,
      };  
    case ANIMATION_SHOW_TRACES:
      return {
        ...state,
        animationShowTrace: action.payload
      };
    case ANIMATION_SHOW_CALLOUTS:
      return {
        ...state,
        animationShowCallouts: action.payload
      };
    case ANIMATION_CURRENT_FLOOR_ONLY:
      return {
        ...state,
        animationCurrentFloorOnly: action.payload
      };
    case CHANGE_INDOOR_ONLY:
      return {
        ...state,
        indoorOnly: action.payload
      };
    case CENTER_MAP:
      return {
        ...state,
        centerActiveMapFlag: action.payload
      };
    case REMOTE_SELECT_TAB:
      return {
        ...state,
        remoteSelectTab: action.payload
      };
    case SET_LAST_VIEWPROPS:
      return {
        ...state,
        lastViewProps: action.payload
      };
    case SET_MAP_CONFIGS:
      return {
        ...state,
        mapConfigs: action.payload
      };
    default:
      return state;
  }
}