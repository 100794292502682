/*
See LICENSE folder for this sample’s licensing information.
Abstract:
The IMDF archive class contains the indoor mapping data organized by feature types and implements level-based feature filtering.
    Levels model floors in a building. The IMDF features this sample uses include openings, amenities, anchors,
    and occupants. The methods are filters that find the features by floor.
*/
import axios from 'axios';
import * as CONSTS from './IMDF.constants';
import hostName from '../../utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

class ImdfArchive {
    constructor(features) {
        this.features = features;
        this.featuresByType = {};
        this.features.forEach((feature) => {
            if (!!feature) {
                this.featuresByType[feature.feature_type] = !!this.featuresByType[feature.feature_type] ? this.featuresByType[feature.feature_type] : [];
                this.featuresByType[feature.feature_type].push(feature);
            }
        });
    }
    levels = (ordinal) => {
        console.info(`[MAP] Drawing levels`)
        return this.featuresByType[CONSTS.LEVEL].filter((feature) => {
            return feature.properties.ordinal === ordinal;
        });
    }

    unitsOnLevel = (levelId) => {
        console.info(`[MAP] Drawing Units`)
        return this.featuresByType[CONSTS.UNIT].filter((feature) => {
            return feature.properties.level_id.toLowerCase() === levelId.toLowerCase();
        });
    }

    unitsByIdOnLevel = (levelId) => {
        console.info(`[MAP] Drawing units by ID`)
        // Cache the units (such as rooms or walkways) by their ID per level, so this data can be reused.
        this._unitsByIdOnLevelCache = this._unitsByIdOnLevelCache || {};
        if (!(levelId in this._unitsByIdOnLevelCache)) {
            var units = {};
            this.featuresByType[CONSTS.UNIT].map(unit => {
                if (unit.properties.level_id.toLowerCase() === levelId) {
                    let key = unit.hasOwnProperty('id') ? unit.id.toLowerCase() : unit.properties.id.toLowerCase();
                    units[key] = unit;
                }
            });
            this._unitsByIdOnLevelCache[levelId] = units;
        }
        return this._unitsByIdOnLevelCache[levelId];
    }

    openingsOnLevel = (levelId) => {
        console.info(`[MAP] Drawing Opening`)
        return this.featuresByType[CONSTS.OPENING].filter((feature) => {
            return feature.properties.level_id.toLowerCase() === levelId.toLowerCase();
        });
    }

    occupantsWithAnchorsOnLevel = (levelId) => {
        console.info(`[MAP] Drawing occupants with anchors`)
        var unitsById = this.unitsByIdOnLevel(levelId.toLowerCase());
        var result = [];
        Object.values(unitsById).forEach(function (unit) {
            var name = (unit.properties.alt_name || {}).en || (unit.properties.name || {}).en
            if (unit.properties.hasOwnProperty('alt_name.en')) {
                name = unit.properties['alt_name.en'];
            }
            var excludedCategories = CONSTS.CATEGORIES_TO_EXCLUDE;
            if (!excludedCategories.includes(unit.properties.category) && (name != null || unit.properties.category != CONSTS.ROOM)) {
                var occupant = {
                    properties: {
                        name: { en: name },
                        category: unit.properties.category,
                    }
                };
                let coords = {};
                if (unit.properties.hasOwnProperty('display_point')) {
                    coords = unit.properties.display_point;
                }
                if (unit.properties.hasOwnProperty('display_point.x')) {
                    coords = {
                        "type": "Point",
                        "coordinates": [
                            parseFloat(unit.properties['display_point.x']),
                            parseFloat(unit.properties['display_point.y'])
                        ]
                    }
                }
                var anchor = {
                    geometry: coords,
                    type: CONSTS.FEATURE,
                    feature_type: CONSTS.ANCHOR
                };
                result.push({ occupant: occupant, anchor: anchor });
            }
        });

        return result;
    }

}

const ImdfArchiveLoad = async (token, guidCampus) => {
    var files = CONSTS.IMDF_FILES;
    var features = [];
    var getArray = [];
    console.info(`[IMDFAchive][ImdfArchiveLoad] Start loading IMDFArchive`);
    const hostname = window.location.hostname === 'localhost' ? 'dev.navvtrack.com' : window.location.hostname;
    files.map(fileName=>getArray.push(axios.get(`https://maps-${hostname}/${guidCampus}/IMDF/${fileName}.geojson`)));
    return new Promise((resolve, reject) => {
        try {
            Promise.all(getArray).then(res => {
                console.log(`[IMDFAchive][ImdfArchiveLoad] IMDFArchive loaded, res geojson:${JSON.stringify(res)}`);
                res.map(res => features = !!res.data.response ? features.concat(res.data.response[0].features) : features.concat(res.data.features));
                let IMDFLoaded = new ImdfArchive(features);
                console.log(`[IMDFAchive][ImdfArchiveLoad] IMDF parsed:${JSON.stringify(IMDFLoaded)}`);
                resolve(IMDFLoaded);
            });
        } catch (error) {
            console.error("[IMDFAchive][ImdfArchiveLoad] An error ocurred fetching IMDF files: ", error);
        }
    });
}


export default ImdfArchiveLoad