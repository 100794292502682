import React from 'react';

import { SET_INPUTS } from './filterForm.constants';
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

function FilterForm (props){
    let inputData = SET_INPUTS(props);

    const inputTextRender = (data)=>{
        return(
          <TextField
            key={'input-'+data.id}
            style={data.style}
            id={data.id}
            type={data.type}
            label={data.label}
            variant="filled"
            value={data.value || ''}
            onChange={(e)=>props.handleInputChange(e.target.value,data.key)}
          />
        );
    };

    const showInputs = () =>{
      return(
        <React.Fragment>
            { inputData.map(item =>{
              switch (item.input){
                case 'text':
                    return(
                      <Grid item xs={item.grid} key={item.key}>
                        {inputTextRender({
                          key: item.key,
                          style: item.style,
                          id: item.id,
                          label: item.label,
                          value: item.value,
                          type: item.input})}
                      </Grid>
                    );
                case 'select':
                  return renderAutocomplete(item);
              }
              })
            }
        </React.Fragment>
      );
    };

    const renderAutocomplete = (item)=>{
      return(
        <Grid item xs={item.grid} key={item.key}>
          <Autocomplete
            id={item.id}
            options={item.list}
            getOptionLabel={(option) => option[item.listKey]}
            getOptionSelected={(option, value)=>option[item.listKey] === value[item.listKey]}
            onChange={(event, newValue)=> !!newValue && props.handleInputChange(newValue[item.listKey], item.key)}
            renderInput={(params) =>
              <TextField
                style={item.style}
                label={item.label}
                variant="filled"
                {...params}/>
            }
          />
        </Grid>
      );
    };

    return(
      <React.Fragment>
       {showInputs()}
      </React.Fragment>
    );
};

export default FilterForm;