import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener  from "@material-ui/core/ClickAwayListener";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import MasterButton from "../../utilities/button.component";
import ModalPop from '../../utilities/modal.component';
import JobsCalendar from "./calendar/jobsCalendar.component";
import UsersList from "./usersList/usersList.component";
import AwesomeSpinner from "../../utilities/spinner";
import TimeWindowControls from "./timeWindowControls/timeWindowControls.component";
import JobInfo from "./jobInfo/jobInfo.component";
import BoardCheckBoxes from "./checkBoxes/boardCheckBoxes.component";
import Briefcase from '../../../img/briefcaseBold.svg';
import ZoomControls from "./zoomButtons/zoomButtons.component";
import DeleteRowMessage from "./deleteRow/deleteRowMessage.component";
import HeaderButtons from "./headerButtons/headerButtons.component";
import JobTemplatesSwitch from "./jobTemplatesSwitch/jobTemplatesSwitch.component";

import {changeView, updateLastViewProps} from '../../../actions/views/viewsActions';

import {
  queryJobStatusList,
  queryListUsersByTeam,
  queryResourceSlotsByDateRange,
  queryListJobTasks,
  queryListStandaloneJobTemplates,
  queryListTeams} from "../graphql/queries";
import {
    subscriptionModifyAssociatedJobTemplate,
    subscriptionModifyResourceSlot,
    subscriptionNewAssociatedJobTemplate,
    subscriptionNewResourceSlot,
    subscriptionModifyResourceSlotUnassignUser,
    subscriptionDropAssociatedJob,
    subscriptionDropResourceSlot,
    subscriptionDropResourceSlotJobs,
    subscriptionModifyTask,
    subscriptionModifyJobInError,
    subscriptionModifyDelayedJobs} from "../graphql/subscriptions";
import { handleFormatSlotsByDateRange } from './utils/slot/slot-FormatBoard';
import { handleDeleteSlot } from "./utils/slot/slot-Delete";
import { handleUnassignUserToSlot, handleAfterUnassignUserToSlot } from "./utils/slot/slot-UnassignUser";
import { handleAssignUserToSlot, handleAfterAssignUserToSlot } from "./utils/slot/slot-AssignUser";
import { handleAddNewSlot, handleFormatNewSlot } from "./utils/slot/slot-New";
import { handleRemoveJobsFromSlot } from "./utils/slot/slot-RemoveJobs";
import { handleCreateNewJobFromJobTemplate } from "./utils/job/job-NewFromTemplate";
import { handleUpdateTaskAndJobOnTaskChange } from "./utils/job/job-UpdateOnTaskChange";
import { handleUnassignUserToJob } from "./utils/job/job-UnassignUser";
import { handleAssignUserToJob } from "./utils/job/job-assignUser";
import { handleDeleteSingleAssociatedJob, handleGetRemainingJobsAfterJobDelete } from "./utils/job/job-Delete";
import { handleSetJobStatusAsError } from "./utils/job/job-Cancel";
import { handleItemMove } from "./utils/job/job-Move";
import { handleFormatNewJob } from './utils/job/job-New';
import {
  handleUpdateItemGroupId,
  handleFormatUpdatedJob,
  handleFormatUpdatedJobInError,
  handleFormatDelayedJob
} from "./utils/job/job-Update";
import {
  handleUpdateGroupIdPosition,
  handleAssignPositionToUsersList,
  handleCheckStatusAccordingToCheckBtn,
  handleFormatUsersList,
  handleModalButtonAction,
  handleModalLabels, 
  hideUsersListElement,
  handleAddDeleteRowToGroups,
  handleAddJobTemplatesRowToGroups,
  handleRemoveFirstRowFromGroups,
  handleLoadJobTemplates,
  handleAddJobTemplatesToItems,
  handleRemoveJobTemplatesFromItems,
  handleGetTeamUsers,
  handleGetCurrentTeam,
  formatRangeDatesWithOffsetAndTimezone} from "./utils/jobsBoard";
  import RouteInfoCard from "./routeInfo/routeInfo.component";
import { ACTION_STATUS, EDIT_LABEL, JOBS_BOARD_TITLE, JOB_ROUTE_SUBTITLE, JOB_ROUTE_TITLE, MODAL_TITLE } from "./jobsBoard.constants";
import {getJobHistoryTraces} from "./../../../actions/directions/directionsActions";

import {styles} from './jobsBoard.styles';
import './jobsBoard.css';
import 'react-calendar-timeline/lib/Timeline.css';

class JobsBoard extends React.Component{
    constructor(props){
        super(props);
        this.state={
            nowTime: null,
            groups: [],
            items: [],
            editBoard: false,
            openModal: false,
            modalMsg: "",
            actionBtnLabel: "",
            openUsersModal: false,
            currentGroupToAssignUser: null,
            users: [],
            usersList: [],
            jobStatusList: [],
            tenantId: "",
            currentJobAssignFlag: false,
            assignFlag: false,
            today: moment().startOf('day'),
            inputDate: moment().startOf('day'),
            inputDateError: false,
            calendarStartDate: moment(moment().startOf('day')).add(8,"hours"),  // Visible start time
            calendarEndDate: moment(moment().startOf('day')).add(21,"hours"), // Visible end time
            currentItemSelected: null,
            startDate: moment().startOf('day'), // Start of current day
            endDate: moment().endOf('day'), // End of current day
            showSpinner: true,
            timeWindowDateChanged: false,
            timeWindowMenuVisible: false,
            showJobInfoView: false,
            showRowButtons: false,
            jobTasks: [],
            actionStatus: "",
            disableZoomIn: false,
            disableZoomOut: false,
            showJobTemplates: false,
            jobTemplates: [],
            usersModalStyle: {}
        }
        this.nowInterval = null;
        this.currentJobSelected = null;
        this.showJobsCalendar = false;
        this.newAssociatedJob_Template_Subscription = null;
        this.newResourceSlot_Template_Subscription =  null;
        this.modifyAssociatedJob_Template_Subscription = null;
        this.modifyResource_Slot_Subscription = null;
        this.modifyResource_SlotUnassignUser_Subscription = null;
        this.subscriptionDropAssociatedJob_Subscription = null;
        this.subscriptionDropResourceSlot_Subscription = null;
        this.subscriptionDropResourceSlotJobs_Subscription = null;
        this.subscriptionModifyTask_Subscription = null;
        this.subscriptionModifyJobInError_Subscription = null;
        this.subscriptionModifyJobDelayed_Subscription = null;
        this.getSlotNJobInfo = false;
        this.currentTeamId =  [];
        this.campusId= this.props.campusId === "5efe07aee72d619780372441" ? "de047d5f-a1e4-4328-b5e0-11b160248cbc": "";
        this.contactsMongoDB = [];
        this.contactsGraphQL = [];
    }

    componentDidMount = async() =>{
        await this.getTeamsList();
        await this.getJobStatusList();
        await this.getListUsersByTeam();
        await this.getJobTemplates();
        await this.getResourceSlotsByDateRange();
        this.handleSubscriptions();
        this.startInterval();
    }

    componentDidUpdate(){
      const {currentJobAssignFlag, assignFlag} = this.state;
      if (currentJobAssignFlag !== assignFlag){
        this.handleGetUsersList();
        this.setState({currentJobAssignFlag: assignFlag});
      }
      if (this.getSlotNJobInfo) {
        this.getResourceSlotsByDateRange();
        this.getSlotNJobInfo = false;
      }
    }

    componentWillUnmount(){
      clearInterval(this.nowInterval);
    }

    startInterval = () =>{
      this.nowInterval = setInterval(() =>{
          this.setState({nowTime: new Date()});
      },5000);
    };

    //queries
    getTeamsList = async() =>{
      const {currentUserContacts, contacts, currentTeam} = this.props;
      const response = await queryListTeams(this.campusId);

      if (!!response){
        const {currentContactsMongoDB, currentContactsGraphQL, currentUserTeamGraphQL} = handleGetCurrentTeam(response, currentUserContacts, contacts, currentTeam);
        this.contactsMongoDB = currentContactsMongoDB;
        this.contactsGraphQL = currentContactsGraphQL;
        this.currentTeamId = currentUserTeamGraphQL;
      }
    }

    getListUsersByTeam = async () =>{
      let data = [];
      for (const contact of this.contactsGraphQL) {
        const response = await queryListUsersByTeam(contact.teamid);
        if (!!response){
          data.push(...response);
        }
      }
      if (!!data){
          this.setState({users: data});
          this.handleGetUsersList(); 
      }
    }

    getJobTemplates =  async() =>{
      const {calendarStartDate} = this.state;

      const response = await queryListStandaloneJobTemplates();
      if (!!response){
        let arrayOfTemplates = handleLoadJobTemplates(response, calendarStartDate);
        this.setState({jobTemplates: arrayOfTemplates});
      }
    }

    getResourceSlotsByDateRange = async() => {
      const {startDate, endDate} = this.state;

      let data = [];
      // const {minDate, maxDate} = formatRangeDatesWithOffsetAndTimezone(startDate, endDate);
      const minDate = moment(startDate).format('YYYY-MM-DD');
      const maxDate = null;
      console.log("Show Board from:", minDate);

      for (const contact of this.contactsGraphQL) {
        const response = await queryResourceSlotsByDateRange(contact.teamid, minDate, maxDate);
        if (!!response) {
          data.push(...response);
        }
      }

      if(!!data) {
        this.handleFormatRowsByDateRange(data);
        this.handleGetUsersList();
      }
      else {
        this.setState({
          showSpinner: false,
          groups: [],
          items: []
        });
      }
    }

    getJobStatusList = async () =>{
      const response = await queryJobStatusList();
      if (!!response) {
        this.setState({
          jobStatusList: response,
          tenantId: response[0].tenantid
        });
      }
    }

    //mutations

    handleAddRow = async() =>{
      const { tenantId, startDate, endDate } = this.state;
      this.handleUpdateActionStatus(ACTION_STATUS.inProgress.id);
      const addRowStatus = await handleAddNewSlot(tenantId, this.currentTeamId.teamid, startDate, endDate);
      this.handleUpdateActionStatus(addRowStatus);
    }

    onItemMove = async (itemId, dragTime, newGroupOrder) =>{
      const {items, groups, tenantId, showJobTemplates, jobTemplates, jobStatusList} = this.state;

      this.handleUpdateActionStatus(ACTION_STATUS.inProgress.id);
      const itemToMove = items.find(item =>item.id === itemId);
      this.handleHideItemToMove(itemId, itemToMove.template);

      if (newGroupOrder === 0 && !showJobTemplates) {
        const itemToDelete = items.find(item=>item.id === itemId);
        this.handleDeleteJob(itemToDelete.jobid);
      }
      else if (newGroupOrder === 0 && showJobTemplates) {
        this.handleUpdateActionStatus(ACTION_STATUS.done.id);
      }
      else if (itemToMove.template) {
        const moveStatus = await handleCreateNewJobFromJobTemplate(items, itemId, dragTime, newGroupOrder, groups, jobTemplates, tenantId, jobStatusList);
        this.handleUpdateActionStatus(moveStatus);
      }
      else {
        const { moveStatus, jobMoved } = await handleItemMove(itemId, dragTime, newGroupOrder, items, groups, tenantId);
        if (!jobMoved) {
          this.handleShowItemToMove(itemId, itemToMove.template);
        }
        this.handleUpdateActionStatus(moveStatus);
      }
    }

    handleHideItemToMove = (itemId, template) =>{
      const {items} = this.state;
      if (!template) {
        const itemToMoveIndex = items.findIndex(item =>item.id === itemId);
      
        let itemsCopy = [...items];
        itemsCopy[itemToMoveIndex].visible = false;
        this.setState({
          items: items.map(
            item =>
              item.id === itemId
                ? Object.assign({}, item, {
                    visible: false
                  })
                : item
          )
        });
      }
    }

    handleShowItemToMove = (itemId, template) =>{
      const {items} = this.state;
      if (!template) {
        const itemToMoveIndex = items.findIndex(item =>item.id === itemId);
      
        let itemsCopy = [...items];
        itemsCopy[itemToMoveIndex].visible = false;
        this.setState({
          items: items.map(
            item =>
              item.id === itemId
                ? Object.assign({}, item, {
                    visible: true
                  })
                : item
          )
        });
      }
    }

    handleCancelJob = async() =>{
      const {items, currentItemSelected, jobTasks} = this.state;
      const cancelJobStatus = await handleSetJobStatusAsError(items, currentItemSelected, jobTasks);
      this.handleUpdateActionStatus(cancelJobStatus);
      this.setState({
        showJobInfoView: false,
        currentItemSelected: null,
        openModal: false
      });
    }

    handleUpdateAssignToSlot = async(groupId, assignedTo) =>{
      const {items, groups, jobStatusList, tenantId} = this.state;
      const assignStatus = await handleAssignUserToSlot(items, groups, groupId, assignedTo, jobStatusList, tenantId);
      this.handleUpdateActionStatus(assignStatus);
    }

    handleUnassignSlot = async(groupId) =>{
      const {items, groups, jobStatusList, tenantId} = this.state;
      const unassignStatus = await handleUnassignUserToSlot(items, groups, groupId, null, jobStatusList, tenantId);
      this.handleUpdateActionStatus(unassignStatus);
    }

    handleDeleteRow = async() =>{
      const {groups} = this.state;
      for (let index = 1; index < groups.length; index++) {
        if(groups[index].checked){
          const deleteRowStatus = await handleDeleteSlot(groups[index]);
          this.handleUpdateActionStatus(deleteRowStatus);
        }
      }
      
      this.setState({
        openModal: false,
        showRowButtons: false
      });
    }

    handleClearRow = async() =>{
      const {groups} = this.state;

      for (let index = 0; index < groups.length; index++) {
        if(groups[index].checked){
          const clearRowStatus = await handleRemoveJobsFromSlot(groups[index]);
          this.handleUpdateActionStatus(clearRowStatus);
        }
      }
      
      this.setState({openModal: false});
    }

    //Subscriptions
    handleSubscriptions = async() =>{
      this.modifyResource_SlotUnassignUser_Subscription = await subscriptionModifyResourceSlotUnassignUser(this.handleFormatSlotUnassignUpdate);
      this.modifyResource_Slot_Subscription = await subscriptionModifyResourceSlot(this.handleFormatSlotUpdate);
      this.modifyAssociatedJob_Template_Subscription = await subscriptionModifyAssociatedJobTemplate(this.handleFormatJobTemplateUpdate);
      this.newResourceSlot_Template_Subscription = await subscriptionNewResourceSlot(this.handleFormatNewResourceSlotTemplate);
      this.newAssociatedJob_Template_Subscription = await subscriptionNewAssociatedJobTemplate(this.handleFormatNewAssociatedJobTemplate);
      this.subscriptionDropAssociatedJob_Subscription = await subscriptionDropAssociatedJob(this.handleFormatRemoveJobFromSlot);
      this.subscriptionDropResourceSlot_Subscription = await subscriptionDropResourceSlot(this.handleFormatRemoveSlot);
      this.subscriptionDropResourceSlotJobs_Subscription = await subscriptionDropResourceSlotJobs(this.handleFormatRemoveJobsFromSlot);
      this.subscriptionModifyTask_Subscription = await subscriptionModifyTask(this.handleFormatModifyTask);
      this.subscriptionModifyJobInError_Subscription = await subscriptionModifyJobInError(this.handleFormatJobTemplateUpdateInError);
      this.subscriptionModifyJobDelayed_Subscription = await subscriptionModifyDelayedJobs(this.handleFormatJobDelayed);
    }

    handleFormatModifyTask = async(updatedTask) =>{
      const {jobTasks, items} = this.state;
      const {jobTasksCopy, jobsCopy} = await handleUpdateTaskAndJobOnTaskChange(updatedTask[0], jobTasks, items);

      this.setState({
        jobTasks: jobTasksCopy,
        items: jobsCopy
      });
    }

    handleFormatRemoveJobsFromSlot = (jobsToRemoveFromSlot) =>{
      const {items, groups} = this.state;
      const currentGroup = groups.find(group =>group.resource_slotid === jobsToRemoveFromSlot[0].resource_slotid);
      const itemsCopy = items.filter(item=>item.group !== currentGroup.id);
      
      this.setState({items: itemsCopy});
    }

    handleFormatRemoveSlot = async(slotToRemove) =>{
      const {groups, items, editBoard} = this.state;
      const groupsWithoutSlotToRemove = groups.filter(group=>group.resource_slotid !== slotToRemove.resource_slotid);
      const groupsCopy = await handleUpdateGroupIdPosition(groupsWithoutSlotToRemove, editBoard);
      const itemsCopy = await handleUpdateItemGroupId(items, groupsCopy);
      
      this.setState({
        groups: groupsCopy,
        items: itemsCopy
      });
      this.handleGetUsersList();
    }

    handleFormatRemoveJobFromSlot = (jobToRemove) =>{
      const {groups, items} = this.state;
      const itemsCopy = handleGetRemainingJobsAfterJobDelete(jobToRemove, groups, items);

      this.setState({items: itemsCopy});
    }

    handleFormatNewResourceSlotTemplate = (newSlot) => {
      const {startDate, groups} = this.state;
      const slots = handleFormatNewSlot(startDate, groups, newSlot);
      this.setState({groups: slots});
    }

    handleFormatNewAssociatedJobTemplate = (newJob) =>{
      const {groups, items} = this.state;
      const jobs = handleFormatNewJob(groups, items, newJob[0]);

      this.setState({items: jobs});
    }

    handleFormatSlotUnassignUpdate = async(updateSlot) =>{
      const {groups, items, editBoard} = this.state;
      const jobsCopy = await handleUnassignUserToJob(items, updateSlot);
      const groupsCopy = await handleAfterUnassignUserToSlot(groups, updateSlot, editBoard);
      const itemsCopy = await handleUpdateItemGroupId(jobsCopy, groupsCopy);

      this.setState({
        items: itemsCopy,
        groups: groupsCopy
      });
      this.handleGetUsersList();
    }

    handleFormatSlotUpdate = async(updateSlot) =>{
      const {groups, items, editBoard} = this.state;
      const jobsCopy = await handleAssignUserToJob(items, updateSlot[0]);
      const groupsCopy = await handleAfterAssignUserToSlot(groups, updateSlot[0], editBoard);
      const itemsCopy = await handleUpdateItemGroupId(jobsCopy, groupsCopy);

      this.setState({
        groups: groupsCopy,
        items: itemsCopy
      });
      this.handleGetUsersList();
    }

    handleFormatJobTemplateUpdateInError = (updateJob) =>{
      this.setState({showSpinner: true});
      const {groups, items, jobTasks} = this.state;

      let {jobsCopy, groupsCopy, jobTasksCopy} = handleFormatUpdatedJobInError(groups,items,updateJob, jobTasks);

      this.setState({
        items: jobsCopy,
        groups: groupsCopy,
        jobTasks: jobTasksCopy,
        showSpinner: false
      });
    }

    handleFormatJobDelayed = (updateJob) =>{
      this.setState({showSpinner: true});
      const {groups, items} = this.state;

      let {jobsCopy, groupsCopy} = handleFormatDelayedJob(groups,items,updateJob);

      this.setState({
        items: jobsCopy,
        groups: groupsCopy,
        showSpinner: false
      });
    }

    handleFormatJobTemplateUpdate = (updateJob) =>{
      this.setState({showSpinner: true});
      const {groups, items, jobTasks} = this.state;

      let {jobsCopy, groupsCopy} = handleFormatUpdatedJob(groups,items,updateJob);

      this.setState({
        items: jobsCopy,
        groups: groupsCopy,
        showSpinner: false
      });
    }

    handleUnsubscribeToNewAssociatedJobTemplate = () =>{
      this.newAssociatedJob_Template_Subscription.unsubscribe();
    }

    handleFormatRowsByDateRange = (response) =>{
      const {slotsCopy, jobsCopy} = handleFormatSlotsByDateRange(response);
      this.setState({
        groups: slotsCopy,
        items: jobsCopy,
        showSpinner: false
      });
    }

    handleGetUsersList = () =>{
      const {users, groups} = this.state;
      const usersCopy = handleFormatUsersList(users,groups);
      this.setState({usersList: usersCopy});
    }

    handleUpdateGroupCheckStatus = (newList) =>{
      this.setState({groups: newList});
    }

    handleEditBoard = () => {
      const {groups} = this.state;
      const groupsCopy = handleAddDeleteRowToGroups(groups);
      this.setState({
        editBoard: true,
        showSpinner: true,
        groups: groupsCopy,
      });

      setTimeout(() => {
        this.setState({showSpinner: false});
      }, 500);
    };

    handleCloseEdit = () => {
      const {groups, items, showJobTemplates, jobTemplates} = this.state;
      const groupsCopy = handleRemoveFirstRowFromGroups(groups);
      let itemsCopy = items;
      if (showJobTemplates) {
        itemsCopy = handleRemoveJobTemplatesFromItems(jobTemplates, items);
      }
      this.setState({
        showSpinner: true,
        editBoard: false,
        showRowButtons: false,
        groups: groupsCopy,
        items: itemsCopy,
        showJobTemplates: false
      });
      
      setTimeout(() => {
        this.setState({showSpinner: false});
      }, 500);
    }

    handleOpenModal = (type, groupId) =>{
      let {message, buttonLabel} = handleModalLabels(type, groupId);

      this.setState({
        openModal: true,
        modalMsg: message,
        actionBtnLabel: buttonLabel,
      });
    }

    handleActionButton = () =>{
      const {actionBtnLabel} = this.state;
      this.handleUpdateActionStatus(ACTION_STATUS.inProgress.id);
      handleModalButtonAction(actionBtnLabel, this.handleClearRow, this.handleDeleteRow, this.handleCancelJob);
    }

    handleCloseModal = () =>{
      this.setState({openModal: false});
    }

    handleStoreAssignUser = (userId) =>{
      const {currentGroupToAssignUser, currentJobAssignFlag} = this.state;
      this.handleUpdateActionStatus(ACTION_STATUS.inProgress.id);
      this.handleUpdateAssignToSlot(currentGroupToAssignUser, userId);
      this.setState({
        openUsersModal: false,
        usersModalStyle: {},
        currentGroupToAssignUser: null,
        assignFlag: !currentJobAssignFlag
      });
    }

    handleShowUsersList = (groupId, buttonId) =>{
      const style = handleAssignPositionToUsersList(buttonId);
      this.setState({
        openUsersModal: true,
        usersModalStyle: style,
        currentGroupToAssignUser: groupId
      });
    }

    handleUnassign = (groupId) =>{
      const {currentJobAssignFlag} = this.state;
      this.handleUpdateActionStatus(ACTION_STATUS.inProgress.id);
      this.handleUnassignSlot(groupId);
      this.setState({
        openUsersModal: false,
        usersModalStyle: {},
        currentGroupToAssignUser: null,
        assignFlag: !currentJobAssignFlag
      });
    }

    handleUserClicked = (userName, userId) =>{
      hideUsersListElement();
      this.handleStoreAssignUser(userName, userId);
    }

    handleHideUsersList = () =>{
      hideUsersListElement();
      this.setState({
        openUsersModal: false,
        usersModalStyle: {},
        currentGroupToAssignUser: null
      });
    }

    updateTimeWindowFromScroll = (newStartDate, newEndDate) =>{
      this.setState({
        calendarStartDate: newStartDate,
        calendarEndDate: newEndDate
      });
    }

    handleZoomIn = () =>{
      const {calendarEndDate, calendarStartDate, disableZoomOut} = this.state;
      const newEndTime = moment(calendarEndDate).subtract(1, "hours");
      const newDuration = moment.duration(newEndTime.diff(calendarStartDate));
      const anHour = 3600000;

      if (disableZoomOut) {
        this.setState({disableZoomOut: false});
      }

      if (newDuration > anHour){
        this.setState({calendarEndDate: newEndTime});
      }
      else {
        this.setState({
          calendarEndDate: newEndTime,
          disableZoomIn: true
        });
      }
    }

    handleZoomOut = () =>{
      const {calendarEndDate, disableZoomIn, startDate} = this.state;
      const newEndTime = moment(calendarEndDate).add(1, "hours");
      const newDuration = moment.duration(newEndTime.diff(startDate));
      const aDay = 86400000;

      if (disableZoomIn) {
        this.setState({disableZoomIn: false});
      }
      
      if(newDuration < aDay){
        this.setState({calendarEndDate: newEndTime});
      }
      else {
        this.setState({
          calendarEndDate: newEndTime,
          disableZoomOut: true
        });
      }
    }

    handleCenterBoard = () =>{
      const visibleStartTime = moment(this.state.today).add(8,"hours");
      const visibleEndTime = moment(this.state.today).add(21,"hours");

      this.setState({
        disableZoomIn: false,
        disableZoomOut: false,
        calendarStartDate:visibleStartTime,
        calendarEndDate: visibleEndTime
      })
    }

    handleShowJobInfo = async(item) =>{
      const response = await queryListJobTasks(item.jobid);
      if (!!response) {
        this.setState({
          jobTasks: response,
          currentItemSelected: item.id,
          showJobInfoView: true
        });
      } else {
        this.setState({
          currentItemSelected: item.id,
          showJobInfoView: true
        });
      }
    };

    handleUpdateActionStatus = (actionStatus) =>{
      if (actionStatus === ACTION_STATUS.done.id || actionStatus === ACTION_STATUS.error.id) {
        setTimeout(() => {
        this.setState({actionStatus: ""});
        }, 2000);
      }

      this.setState({actionStatus: actionStatus});
    }

    handleDeleteJob = async(jobId) =>{
      const deleteStatus = await handleDeleteSingleAssociatedJob(jobId);
      this.handleUpdateActionStatus(deleteStatus);
    }

    redirectTrackUserMap = (job) =>{
      const {users} = this.props;
      if (!!job) {
        let currentUser = users.find(user=>`${user.first_name} ${user.last_name}` === job.job_assigned_user_name);
        this.props.updateLastViewProps('jobsBoard');
        this.props.changeView('map', 'transporter', {userId: currentUser._id, deviceId: currentUser.device_id});
      }
    }

    redirectJobHistoryMap = async(currentJob) =>{
      await this.props.getJobHistoryTraces(currentJob.job_mobile_sessionid, currentJob.job_started_date, currentJob.job_ended_date)
      .then(res=>{
        const startDate = moment(currentJob.job_started_date);
        const endDate = moment(currentJob.job_ended_date);
        const jobDuration = moment.duration(startDate.diff(endDate));
  
        let job = [{
          device: this.getUserDevice(currentJob),
          date: currentJob.job_start_date,
          duration: jobDuration,
          endTime: currentJob.job_ended_date,
          jobId: currentJob.jobid,
          jobName: currentJob.title,
          jobSessionId: currentJob.job_mobile_sessionid,
          messenger: currentJob.job_assigned_user_name,
          startTime: currentJob.job_started_date,
          status: currentJob.status,
          errorNumber: currentJob.job_error_number,
          unstarted_taskid: currentJob.unstarted_taskid,
          jobNumber: currentJob.job_number,
          job_errors: currentJob.job_errors
        }];
  
        this.props.changeView('map', 'workflowAutomation', {tasks:this.state.jobTasks, jobInfo:job });
      });
    }

    getUserDevice = (job) =>{
      const { users } = this.props;
      const currentUserInfo = users.find(user=> `${user.first_name} ${user.last_name}`=== job.job_assigned_user_name) || null;

      if (!!currentUserInfo) {
        return currentUserInfo.device_id;
      }
      return ""
    }

    handleSubtractDay = () =>{
      const {startDate} = this.state;
      const yesterday = moment(startDate).subtract(1,"days");

      this.handleUpdateCalendarTimeWindow(yesterday, true);
    }

    handleAddDay = () =>{
      const {startDate} = this.state;
      const tomorrow = moment(startDate).add(1,"days");
      
      this.handleUpdateCalendarTimeWindow(tomorrow, true);
    }

    handleChangeInputDate = (newDate) =>{
      const {today} = this.state;
      const aYearAgo = moment(today).subtract(1,"years");

      if (moment(newDate).isAfter(aYearAgo)) {
        this.setState({inputDateError: false});
        this.handleUpdateCalendarTimeWindow(newDate, true);
      }
      else {
        this.setState({inputDateError: true});
      }
    }

    handleUpdateCalendarTimeWindow = (inputDate, timeWindowDateChanged) =>{
      if(timeWindowDateChanged){
        this.getSlotNJobInfo = true;
      }

      this.setState({
        showSpinner: true,
        inputDate: inputDate,
        calendarStartDate: moment(inputDate).add(8,"hours"),
        calendarEndDate: moment(inputDate).add(21,"hours"),
        startDate: inputDate,
        endDate: moment(inputDate).endOf('day'),
        timeWindowMenuVisible: false,
      });

      setTimeout(() => {
        this.setState({
          timeWindowDateChanged: false,
          showSpinner: false,
        });
      }, 3000);
    }

    handleTimeWindowVisibility = () =>{
      const {timeWindowMenuVisible} = this.state;
      this.setState({timeWindowMenuVisible: !timeWindowMenuVisible});
    }

    handleCancelCalendarTimeWindow = () =>{
      this.setState({
        timeWindowMenuVisible: false,
        timeWindowDateChanged: false,
        showSpinner: false,
        inputDate: moment().startOf('day')
      });
    }

    displayRowOptions = (key) =>{
      const {groups} = this.state;
      const showButtons =  handleCheckStatusAccordingToCheckBtn(key, groups, this.handleUpdateGroupCheckStatus);

      this.setState({showRowButtons: showButtons});
    };

    handleJobTemplatesChange = (event) =>{
      if (!this.state.showJobTemplates) {
        this.handleRemoveDeleteRowAndShowJobTemplatesRow();
      }
      else {
        this.handleRemoveJobTemplatesRowAndShowDeleteRow();
      }
    }

    handleRemoveDeleteRowAndShowJobTemplatesRow = () =>{
      const {groups, items, jobTemplates} = this.state;
      const groupsWithoutFirstRow = handleRemoveFirstRowFromGroups(groups);
      const groupsCopy = handleAddJobTemplatesRowToGroups(groupsWithoutFirstRow);
      const itemsCopy = handleAddJobTemplatesToItems(jobTemplates, items);

      this.setState({
        showJobTemplates: true,
        showSpinner: true,
        groups: groupsCopy,
        items: itemsCopy
      });
      
      setTimeout(() => {
        this.setState({showSpinner: false});
      }, 500);
    }

    handleRemoveJobTemplatesRowAndShowDeleteRow = () =>{
      const {groups, jobTemplates, items} = this.state;
      const groupsWithoutFirstRow = handleRemoveFirstRowFromGroups(groups);
      const itemsWithoutJobTemplates = handleRemoveJobTemplatesFromItems(jobTemplates, items);
      const groupsCopy = handleAddDeleteRowToGroups(groupsWithoutFirstRow);

      this.setState({
        showJobTemplates: false,
        showSpinner: true,
        groups: groupsCopy,
        items: itemsWithoutJobTemplates
      });
      
      setTimeout(() => {
        this.setState({showSpinner: false});
      }, 500);
    }

    handleRenderBoardDefaultView = () =>{
      const {open, featureFlags} = this.props;
      const {
        editBoard,
        timeWindowMenuVisible,
        inputDate,
        usersList,
        groups,
        items,
        showSpinner,
        currentItemSelected,
        calendarStartDate,
        calendarEndDate,
        startDate,
        endDate,
        showRowButtons,
        today,
        actionStatus,
        inputDateError,
        disableZoomIn,
        disableZoomOut,
        showJobTemplates,
        openUsersModal,
        usersModalStyle
      } = this.state;

      const isEditDisabled =  moment(startDate).isBefore(today);
      return(
        <React.Fragment>
          <HeaderButtons
            handleCloseEdit={this.handleCloseEdit}
            handleEditBoard={this.handleEditBoard}
            classes={this.props.classes}
            editBoard={editBoard}
            isEditDisabled={isEditDisabled}
            showSpinner={showSpinner}
          />
          <div className="JobsBoard_TitleContainer"> 
            <div className="JobsBoard_TitleIcon">
              <InsertInvitationIcon  style={{fill: "#2843A3"}}/>
            </div>
            <div className="JobsBoard_Title">{JOBS_BOARD_TITLE}</div>
          </div>
          {editBoard && featureFlags.SpecimenCollection.AddJobs && (
            <JobTemplatesSwitch
            handleJobTemplatesChange={this.handleJobTemplatesChange}
            showJobTemplates={showJobTemplates}/>
          )}
          <TimeWindowControls
            handleTimeWindowVisibility={this.handleTimeWindowVisibility}
            handleChangeInputDate={this.handleChangeInputDate}
            handleCancelCalendarTimeWindow={this.handleCancelCalendarTimeWindow}
            handleUpdateCalendarTimeWindow={this.handleUpdateCalendarTimeWindow}
            handleSubtractDay={this.handleSubtractDay}
            handleAddDay={this.handleAddDay}
            timeWindowMenuVisible={timeWindowMenuVisible}
            startDate={startDate}
            inputDate={inputDate}
            inputDateError={inputDateError}
            editBoard={editBoard}
            showSpinner={showSpinner}
          />
          {editBoard && (<div className="JobsBoard_EditLabel">{EDIT_LABEL}</div>)}
          {!showJobTemplates && (
            <DeleteRowMessage
            editBoard={editBoard}
            showSpinner={showSpinner}
            />
          )}
          {openUsersModal && (
            <ClickAwayListener onClickAway={()=>this.handleHideUsersList()}>
              <div>
                <UsersList
                  handleHideUsersList={this.handleHideUsersList}
                  handleUserClicked={this.handleUserClicked}
                  users={usersList}
                  usersModalStyle={usersModalStyle}
                />
              </div>
            </ClickAwayListener>
          )}
          {!showSpinner && (
            <ZoomControls
              handleZoomIn={this.handleZoomIn}
              handleZoomOut={this.handleZoomOut}
              handleCenterBoard={this.handleCenterBoard}
              disableZoomIn={disableZoomIn}
              disableZoomOut={disableZoomOut}
              actionStatus={actionStatus}
            />
          )}
          {editBoard && !showSpinner && (
            <BoardCheckBoxes
              groups={groups}
              displayRowOptions={this.displayRowOptions}
            />
          )}
          {showSpinner? 
          (
            <AwesomeSpinner
              id="webNavv-workflowAutomation-jobsBoard-spinner-1.0"
              config="default"
              loading={showSpinner}
              navOpen={open}
            />
          ):( 
            <JobsCalendar
              handleDeleteJob={this.handleDeleteJob}
              handleShowJobInfo={this.handleShowJobInfo}
              handleUnassign={this.handleUnassign}
              updateTimeWindowFromScroll={this.updateTimeWindowFromScroll}
              handleShowUsersList={this.handleShowUsersList}
              handleAddRow={this.handleAddRow}
              handleOpenModal={this.handleOpenModal}
              onItemMove={this.onItemMove}
              currentItemSelected={currentItemSelected}
              items={items}
              groups={groups}
              calendarStartDate={calendarStartDate}
              calendarEndDate={calendarEndDate}
              editBoard={editBoard}
              startDate={startDate}
              endDate={endDate}
              showRowButtons={showRowButtons}
              open={open}
            />
          )}
        </React.Fragment>
      )
    }

    handleRenderJobInfoView = () =>{
      const {currentItemSelected, groups, items, jobTasks} = this.state;
      return(
        <React.Fragment>
          <div id="webNavv-workflowAutomation-jobsBoard-jobRoute-1.0" style={{height:"40px"}}>
            <div style={{float:"right", marginRight:"50px"}}>
              <MasterButton
                id="webNavv-workflowAutomation-jobsBoard-jobRoute-backButton-1.0" 
                keyLbl={'return-jobs-board'}
                label={"Return to Jobs Board"}
                buttonType={"success"}
                isDisabled={false}
                handleClick={()=>this.setState({showJobInfoView: false})}
                size={"medium"}
                class={this.props.classes.JobsBoard_blueButton}
                startIcon={"back"}/>
            </div>
          </div>
          <div id="webNavv-workflowAutomation-jobsBoard-jobRoute-titleContainer-1.0" className="JobsBoard_TitleContainer"> 
            <div className="JobsBoard_JobInfoViewTitleIcon">
              <img alt="briefcase-icon" src={Briefcase}/>
            </div>
            <div className="JobsBoard_Title">{JOB_ROUTE_TITLE}</div>
          </div>
          <div className="JobsBoard_JobInfoViewContainer">
            <JobInfo
              handleOpenModal={this.handleOpenModal}
              redirectTrackUserMap={this.redirectTrackUserMap}
              redirectJobHistoryMap={this.redirectJobHistoryMap}
              groups={groups}
              items={items}
              currentItemSelected={currentItemSelected}
              jobTasks={jobTasks}
            />
            <RouteInfoCard
              jobTasks={jobTasks}
              currentItemSelected={currentItemSelected}
              items={items}
              mapConfigs={this.props.mapConfigs}
            />
          </div>
        </React.Fragment>
      )
    }

    render(){
      const {classes} = this.props;
      const {showJobInfoView, openModal, modalMsg, actionBtnLabel} = this.state;
        return(
          <div id="webNavv-workflowAutomation-jobsBoard-1.0">
            <div id="jobsBoardMainContainer" className={classes.JobsBoard_Container}>
              {showJobInfoView? (this.handleRenderJobInfoView()):(this.handleRenderBoardDefaultView())}
              <ModalPop
                handleCloseModal={this.handleCloseModal}
                openModal={openModal}
                icon={<ReportProblemIcon  className={this.props.classes.JobsBoard_ProblemIcon}/>}
                title={MODAL_TITLE}
                subtitle={modalMsg}
                handleFirstOption={this.handleActionButton}
                handleSecondOption={this.handleCloseModal}
                button1Label={actionBtnLabel}
                button2Label={"Back"}
                styleSecondOption={{backgroundColor:"#FFFFFF", color: "#919191", textTransform: "none !important"}}
                styleFirstOption={{backgroundColor:"#2196F3", textTransform: "none !important"}}
              />
            </div>
          </div>
        )
    }
}

const mapStateToProps = state => ({
  users: state.user.users,
  open: state.views.drawerOpen,
  featureFlags: state.auth.featureFlags,
  users: state.user.users,
  currentUserContacts: state.auth.acl.contact,
  campusId: state.auth.userInfo.campusId,
  currentTeam: state.auth.acl.role,
  contacts: state.acl.acls,
  mapConfigs: state.views.mapConfigs
});

export default connect(mapStateToProps, {changeView, updateLastViewProps, getJobHistoryTraces})(withStyles(styles)(JobsBoard));