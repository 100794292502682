import  {
    GET_EQUIPMENT,
    EQUIPMENT_CROWD,
    EQUIPMENT_LOCATION,
    GET_BEACON_LOCATION,
    EQUIPMENT_CATEGORIES,
    EQUIPMENT_LOCATION_KIO,
    EQUIPMENT_LOCATION_LOGS,
    EQUIPMENT_LOCATION_LOGS_PAGINATION} from '../actions/equipment/types';

const initialState = {
    equipmentList:[],
    equipmentCrowd:[],
    equipmentLocation:{},
    equipmentHistory:[],
    equipmentHistoryPagination:[],
    equipmentHistoryPaginationTotal:0,
    beaconLocation:[],
    equipmentCategories:[],
    success: false
}

export default function( state = initialState, action){
    switch(action.type){
        case GET_EQUIPMENT:
            return{
                ...state,
                equipmentList:action.payload,
            };
        case EQUIPMENT_CROWD:
            return{
                ...state,
                equipmentCrowd:action.payload,
            };
        case EQUIPMENT_LOCATION:
            return{
                ...state,
                equipmentLocation:action.payload,
            };
        case GET_BEACON_LOCATION:
            return{
                ...state,
                beaconLocation:action.payload,
            };
        case EQUIPMENT_LOCATION_KIO:
            return{
                ...state,
                success:action.payload,
            };
        case EQUIPMENT_LOCATION_LOGS:
            return{
                ...state,
                equipmentHistory:action.payload,
            };
        case EQUIPMENT_LOCATION_LOGS_PAGINATION:
            return{
                ...state,
                equipmentHistoryPagination:action.payload.data,
                equipmentHistoryPaginationTotal:action.payload.total
            };
        case EQUIPMENT_CATEGORIES:
            return{
            ...state,
            equipmentCategories:action.payload,
        };
        default:
            return state;
    }
}