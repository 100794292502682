import lightBlue from "@material-ui/core/colors/lightBlue";
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const CustomSwitch = withStyles({
    switchBase: {
      color: 'white',
      '&$checked': {
        color: lightBlue[500],
      },
      '&$checked + $track': {
        backgroundColor: lightBlue[500],
      },
    },
    checked: {},
    track: {},
})(Switch);

export const useStyles = makeStyles((theme) => ({
    blockContainer: {
        display: 'block'
    },
    showAccessCloseIcon: {
        fontSize:'30px',
        fill:'gray'
    },
    showAccessCheckIcon: {
        fontSize:'30px',
        fill:'#2196F3'
    },
    showAccessPermission: {
        display:'inline-block',
        height:'30%',
        float:'right',
        paddingTop:'20px',
        marginRight:'60px'
    },
    showAccessDesc: {
        fontSize:'11px',
        paddingBottom:'5px'
    },
    showAccessItemName: {
        display:'block',
        width:'100%',
        textAlign:'left'
    },
    showAccessDescContainer: {
        display:'inline-block',
        height:'30%',
        marginLeft:'15px'
    },
    showAccessIconContainer: {
        display:'inline-block',
        height:'30%',
        paddingTop:'10px'
    }
}));