import React from 'react';
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
    cardRoot: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        border: '1px solid #EFF4FA',
        minWidth: 261,
        maxWidth: 350,
        height: 90,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        '&:hover': {
            border: '1px solid #2196F3',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }
    },
    cardTitle: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginTop: 50,
    },
    number: {
        marginLeft: 35,
        marginTop: 8,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 20,
        color: '#222B45',
    },
    numberof: {
        fontSize: 13,
        fontStyle: 'normal',
        fontweight: 400,
        color: '#8F9BB3',
    },
    hospital: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 13,
        textAlign: 'right',
        color: '#2196F3',
    },
    selector: {
        color: '#2196F3',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            '& .MuiListItemText-primary': {
                //color: '#FFF',
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
            alignSelf: 'baseline',
        },
        '& .MuiListItemText-primary': {
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
        },
    },
}))(MenuItem);

const getAcronym = (name) => {
    //words = s.split(/\s/);
    return name.match(/\b(\w)/g).join("").toUpperCase();
};

function ReportCard(props) {
    const { title, reportData = [], campusList } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [campus, setCampus] = React.useState(campusList[0].alias);
    const [number, setNumber] = React.useState('207');

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onOpenSelector = () => {
        setExpanded(!expanded);
    };

    const handleSelectCampus = (c) => {
        handleClose();
        setCampus(c);
    };

    return (
        <div
            id="webNavv-superAdmin-dashboard-card-1.0"
            className={classes.cardRoot}
        >
            <div className={classes.cardTitle}>
                <p className={classes.numberof}>{title}</p>
                <div style={{ display: 'flex' }}>
                    <p className={classes.hospital}>{campus}</p>
                    <IconButton
                        disableRipple
                        className={classes.selector}
                        size="small"
                        aria-controls="simple-menu"
                        onClick={handleClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                transform: 'translateX(10px) translateY(30px)',
                            }
                        }}
                        anchorEl={anchorEl}
                        //keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {campusList.map((c, index) => {
                            const campusName = c.alias;
                            const isSelected = campusName === campus;

                            return (
                                <StyledMenuItem
                                    key={`superadmin-dashboard-menu-${index}`}
                                    onClick={() => handleSelectCampus(campusName)}
                                    style={{ color: isSelected ? '#2196F3' : '#000000' }}
                                >
                                    {campusName}
                                </StyledMenuItem>
                            )
                        }
                        )}
                    </Menu>
                </div>
            </div>
            <p className={classes.number}>{reportData[campus]}</p>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.superAdmin.user,
    auth: state.auth,
    campusList: state.campus.campusList,
})

export default connect(mapStateToProps, {})(ReportCard);
