import { jobStatusTypesGraphQL } from "../jobsBoard.constants";

export const handleCheckRowPosition = (item) =>{
    if (item.group > 4){
        return true
    }
    return false
}

export const handleGetJobStyle = (item) =>{
    switch(item.status){
        case jobStatusTypesGraphQL.inProgress:
            return {
                color: "black",
                background: !!item.progress? `linear-gradient(to right, #A2C796 ${item.progress}%, #DAE8D5 ${item.progress}% ${100-item.progress}%)`: "#DAE8D5",
                border: "1px solid #A2C796",
                borderRadius: 10,
            }
        case jobStatusTypesGraphQL.inProgressWithError:
            return {
                color: "black",
                background: !!item.progress? `linear-gradient(to right, #D8726F ${item.progress}%, #F4D7D6 ${item.progress}% ${100-item.progress}%)`: "#F4D7D6",
                border: "1px solid #D8726F",
                borderRadius: 10,
            }
        case jobStatusTypesGraphQL.complete:
            return{
                color: "black",
                background: "#A2C796",
                border: "1px solid #A2C796",
                borderRadius: 10,
            }
        case jobStatusTypesGraphQL.completeWithError:
            return{
                color: "black",
                background: !!item.progress? `linear-gradient(to right, #D8726F ${item.progress}%, #F4D7D6 ${item.progress}% ${100-item.progress}%)`: "#F4D7D6",
                border: "1px solid #D8726F",
                borderRadius: 10,
            }
        case jobStatusTypesGraphQL.error:
            return{
                color: "black",
                background: !!item.progress? `linear-gradient(to right, #D8726F ${item.progress}%, #F4D7D6 ${item.progress}% ${100-item.progress}%)`: "#F4D7D6",
                border: "1px solid #D8726F",
                borderRadius: 10,
            }
        default:
            return{
                color: "black",
                background: "#DAE8D5",
                border: "1px solid #A2C796",
                borderRadius: 10,
            }
    }
}