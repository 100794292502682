

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

import _ from "lodash";

import PropTypes from "prop-types";

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Icon } from "@material-ui/core";



import { fetchRoles, fetchAllACLS } from "../../../actions/acl/aclActions";
import { listUsers } from '../../../actions/user/userActions';
import { allUsers, getSuperAdmins } from '../../../actions/superAdmin/superAdminActions';

import MUIDataTable from "mui-datatables/dist/index";
import { styles } from './styles';

import {
  EMPTY_STRING,
  USER_COLUMNS,
  DEFAULT_DATE_FORMAT,
  BACK_TITLE,
  PHONE_COLUMNS,
} from './superDashboard.constants';
import campusIcon from './../img/multicampus.png';

class PhoneAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: EMPTY_STRING,
      last_name: EMPTY_STRING,
      email: EMPTY_STRING,
      password: EMPTY_STRING,
      confirmPassword: EMPTY_STRING,
      open: false,
      openSnack: false,
      snackMsg: EMPTY_STRING,
      snackType: EMPTY_STRING,
    };
  }

  startLoop = true;
  elements = ["default", "hidden"];
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];
  usersName = [];
  usersNameList = [];

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "700px !important",
          },
          paper: {
            boxShadow: "0 4px 2px -2px gray",
            paddingTop: "1px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 15,
          },
          toolButton: {
            display: "flex",
            justifyContent: "center",
            color: "#7D7D7D",
          },
        },
      },
    });

  componentDidMount = () => {
    this.props.fetchRoles();
    this.props.fetchAllACLS();
    this.props.allUsers();
    this.props.getSuperAdmins();
  };

  componentDidUpdate(prevProps, prevState) {
  }

  UNSAFE_componentWillMount = () => {
  };

  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  backToDashboard = () => {
    this.props.goBackToDashboard('d');
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnack: false, snackMsg: EMPTY_STRING });
  };

  getCampusName = (id) => {
    const { campusList } = this.props;
    const campus = campusList.find(campus => campus.id === id);
    const campusName = campus ? campus.name : EMPTY_STRING;
    return campusName;
  };

  getRole = (id) => {
    const { users } = this.props;
    const user = users.find(u => u.id === id);
    const role = user ? user.role_name : EMPTY_STRING;
    return role;
  };

  getUserName = (id) => {
    const { users } = this.props;
    const user = users.find(u => u.id === id);
    const name = user ? user.username : EMPTY_STRING;
    return name;
  };

  render() {
    const { classes, allPhones } = this.props;
    let phoneData = allPhones.map((phone) => ({
      identifier: phone.UUID,
      model: phone.model,
      campus: this.getCampusName(phone.campusId),
      usedBy: this.getUserName(phone.usedBy),
      role: this.getRole(phone.usedBy),
      lastLogin: phone.last_login ? moment(phone.last_login).format(DEFAULT_DATE_FORMAT) : EMPTY_STRING,
      lastLogout: phone.last_logout ? moment(phone.last_logout).format(DEFAULT_DATE_FORMAT) : EMPTY_STRING
    }));

    let data = [...phoneData];

    this.showTable = true;

    const columns = [
      {
        name: "identifier",
        label: PHONE_COLUMNS.id,
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "model",
        label: PHONE_COLUMNS.model,
        options: {
          filter: true,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "campus",
        label: PHONE_COLUMNS.campus,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "usedBy",
        label: PHONE_COLUMNS.user,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "role",
        label: PHONE_COLUMNS.role,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "lastLogin",
        label: PHONE_COLUMNS.lastLogin,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "lastLogout",
        label: PHONE_COLUMNS.lastLogout,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      }
    ];

    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 30, 50, 100],
      selectableRowsHeader: false,
      selectableRows: "none",
      search: true,
      print: false,
      filter: false,
      viewColumns: false,
      toolbar: {
        filterTable: "Filter Table",
      },
      textLabels: {
        toolbar: {
          downloadCsv: "Download",
        },
      },
      downloadOptions: {
        filename: 'Phones.csv',
      },
    };

    return (
      <div>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex' }}>
            <Icon style={{ width: 45, height: 35 }}>
              <img src={campusIcon} height={35} width={35} />
            </Icon>
            <span className={classes.titleText}>Phones by Campus and Role</span>
          </div>
          <p className={classes.back} onClick={() => this.backToDashboard()}>{BACK_TITLE}</p>
        </div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>

      </div>
    );
  }

}

PhoneAdmin.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  user: state.superAdmin.user,
  users: state.superAdmin.allUsers,
  campusList: state.campus.campusList,
  allPhones: state.phones.allPhones,
  superAdminList: state.superAdmin.superAdminList,
});

export default withStyles(styles)(
  connect(mapStateToProps, { fetchRoles, fetchAllACLS, listUsers, allUsers, getSuperAdmins })(withRouter(PhoneAdmin))
);
