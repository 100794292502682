import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { useStyles } from './styles';
import MasterButton from '../../utilities/button.component';
import MasterAlert from '../../utilities/alert.component';
import AwesomeSpinner from '../../utilities/spinner';
import { convertLowerCase, validateEmail } from '../../utilities/formValidation';
import { patchUser } from '../../../actions/user/userActions';
import { createLog } from '../../../actions/logs/logsActions';
import * as CONSTS from './account.constants';

import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';


function UserAccount(props) {
  const classes = useStyles();
  const [changePassword, setChangePassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState('');
  const [cancel, setCancel] = useState(false);
  const [show, setShow] = useState({
    password: false,
    pass1: false,
    pass2: false
  });
  const [formError, setFormError] = useState({
    isError: false,
    firstName: false,
    lastName: false,
    email: false,
    emailType: '',
    pass1: false,
    pass2: false
  })
  const [snack, setSnack] = useState({
    open: false,
    msg: '',
    severity: ''
  });
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    pass1: '',
    pass2: '',
    basucAuth: true
  });
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      //componentDidMount
      getUserInfo();
    }
    mounted.current = true;
  });

  useEffect(() => {
    let [email, errorType] = checkEmail();
    if (userInfo.firstName.length > 0 && formError.isError && formError.firstName)
      setFormError({ ...formError, isError: false, firstName: false });
    if (userInfo.lastName.length > 0 && formError.isError && formError.lastName)
      setFormError({ ...formError, isError: false, lastName: false });
    if (userInfo.email.length > 0 && !email && formError.isError && formError.email)
      setFormError({ ...formError, isError: false, email: false });
    if (userInfo.pass1.length > 0 && formError.isError && formError.pass1)
      setFormError({ ...formError, isError: false, pass1: false });
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.pass2 === userInfo.pass1)
      setFormError({ ...formError, pass2: false, isError: false });
    else
      setFormError({ ...formError, pass2: true, isError: true });
  }, [userInfo.pass2, userInfo.pass1]);

  useEffect(() => {
    if (cancel) {
      setUserInfo({ ...userInfo, pass1: '', pass2: '' });
      setCancel(false);
    }
  }, [cancel]);

  const getUserInfo = () => {
    let currentUser = props.users.filter(user => user._id === props.userId);
    setUserInfo({
      ...userInfo,
      firstName: currentUser[0].first_name,
      lastName: currentUser[0].last_name,
      email: currentUser[0].email,
      password: currentUser[0].password,
      basicAuth: currentUser[0].basic_auth
    });
    setDefaultEmail(currentUser[0].email);
  };

  const handleInputChange = (e, type) => {
    switch (type) {
      case 1:
        setUserInfo({ ...userInfo, firstName: e.target.value });
        break;
      case 2:
        setUserInfo({ ...userInfo, lastName: e.target.value });
        break;
      case 3:
        setUserInfo({ ...userInfo, email: e.target.value });
        break;
      case 5:
        setUserInfo({ ...userInfo, pass1: e.target.value });
        break;
      case 6:
        setUserInfo({ ...userInfo, pass2: e.target.value });
        break;
    }
  };

  const handleChangePassword = (action) => {
    if (action === CONSTS.KEEP_PASS) {
      setUserInfo({ ...userInfo, pass1: '', pass2: '' });
      setFormError({ ...formError, pass1: false, pass2: false });
    }
    setChangePassword(!changePassword);
  };

  const handleClickShowPassword = (type) => {
    if (type === 4)
      setShow({ ...show, password: !show.password });
    if (type === 5)
      setShow({ ...show, pass1: !show.pass1 });
    if (type === 6)
      setShow({ ...show, pass2: !show.pass2 });
  };

  const handleOpenSnackbar = (type, ms) => {
    setSnack({ open: true, msg: ms, severity: type });
  };

  const handleCloseSnackbar = () => {
    setSnack({ open: false, msg: '', severity: '' });
    getUserInfo();
    setChangePassword(false);
  };

  const handleCancel = () => {
    setFormError({
      isError: false,
      firstName: false,
      lastName: false,
      email: false,
      emailType: '',
      pass1: false,
      pass2: false
    });
    setChangePassword(false);
    setCancel(true);
    getUserInfo();
  };

  const createAccountLog = (data) => {
    let log = {
      campusId: props.campusId,
      userId: props.userInfo.id,
      action: data.action,
      description: data.description,
      timestamp: new Date()
    };
    props.createLog(log);
  };

  const handleSubmit = () => {
    let formChecked = checkForm();
    if (formChecked.formError) {
      setFormError({
        isError: true,
        firstName: formChecked.firstNameError,
        lastName: formChecked.lastNameError,
        email: formChecked.emailError,
        emailType: formChecked.emailErrorType,
        pass1: formChecked.passError
      });
    } else {
      setShowSpinner(true);
      handleUpdate();
      handleCancel();
    }
  };

  const handleUpdate = () => {
    let user = {};
    const lowerCaseEmail = convertLowerCase(userInfo.email);
    if (changePassword) {
      user = {
        first_name: userInfo.firstName.trim(),
        last_name: userInfo.lastName.trim(),
        email: userInfo.email.trim(),
        password: userInfo.pass1.trim(),
        username: handleCreateUserName(lowerCaseEmail)
      };
    } else {
      user = {
        first_name: userInfo.firstName.trim(),
        last_name: userInfo.lastName.trim(),
        email: userInfo.email.trim(),
        username: handleCreateUserName(lowerCaseEmail)
      };
    }
    props.patchUser(props.userId, user).then(res => {
      if (!res.hasOwnProperty(CONSTS.MSG)) {
        createAccountLog({
          action: CONSTS.UPDATE_ACCOUNT,
          description: `Account updated by "${props.userInfo.first_name} ${props.userInfo.last_name}"`
        });
        let msg = <div className={classes.snackMsg}>{CONSTS.UPDATE_ACCOUNT_MSG}<div style={{ display: 'inline', fontWeight: 'bold' }}>{CONSTS.UPDATE_ACCOUNT_MSG_BOLD}</div>.</div>
        handleOpenSnackbar(CONSTS.SUCCESS, msg);
        setShowSpinner(false);
      } else {
        setShowSpinner(false);
        setFormError({ ...formError, email: true, emailType: CONSTS.EMAIL_ERROR.duplicated.id });
      }
    }).catch(err => {
      createAccountLog({
        action: CONSTS.UPDATE_ACCOUNT_ERROR,
        description: `User "${props.userInfo.first_name} ${props.userInfo.last_name}" couldn't update account due enpoint ${err}`
      });
      let msg = <div className={classes.snackMsg}>{CONSTS.UPDATE_ACCOUNT_ERROR_MSG}</div>
      handleOpenSnackbar(CONSTS.WARNING, msg);
      setShowSpinner(false);
    });
  }

  const checkEmail = () => {
    const { EMAIL_ERROR: { duplicated, empty, incorrectFormat } } = CONSTS;
    let email = false;
    let errorType = '';

    if (userInfo.email === '') {
      email = true;
      errorType = empty.id;
    } else if (!validateEmail(userInfo.email)) {
      email = true;
      errorType = incorrectFormat.id;
    } else if (checkDuplicatedEmail(userInfo.email)) {
      email = true;
      errorType = email ? duplicated.id : '';
    }

    return [email, errorType];
  }

  const checkForm = () => {
    let [email, errorType] = checkEmail();
    let firstNameEmpty = userInfo.firstName === '' ? true : false;
    let lastNameEmpty = userInfo.lastName === '' ? true : false;
    let passError = false;
    if (changePassword) {
      passError = userInfo.pass1 !== '' ? false : true;
    }
    let form = email || firstNameEmpty || lastNameEmpty || passError ? true : false;

    return {
      formError: form,
      emailError: email,
      emailErrorType: errorType,
      firstNameError: firstNameEmpty,
      lastNameError: lastNameEmpty,
      passError: passError
    }
  };

  const checkDuplicatedEmail = (email) => {
    let emailDuplicated = false;
    if (email !== defaultEmail) {
      props.users.filter(e => {
        if (e.email === email)
          emailDuplicated = true;
      });
    }
    return emailDuplicated;
  }

  const handleCreateUserName = (email) => email.replace(/@.+/, "")

  const getErrorMsg = (type) => {
    const { ERROR_INPUT: { firstName, lastName, email, pass, confirmPass }, EMAIL_ERROR: { duplicated, empty, incorrectFormat } } = CONSTS;
    switch (type) {
      case firstName.id:
        return firstName.msg;
      case lastName.id:
        return lastName.msg;
      case email.id:
        if (formError.emailType === duplicated.id)
          return duplicated.msg;
        if (formError.emailType === incorrectFormat.id)
          return incorrectFormat.msg;
        if (formError.emailType === empty.id)
          return empty.msg;
      case pass.id:
        return pass.msg;
      case confirmPass.id:
        return confirmPass.msg
    }
  };

  const inputRender = (data) => {
    return (
      <TextField
        key={'input-' + data.id}
        style={data.style}
        id={data.id}
        type={data.inputType}
        label={data.label}
        variant={CONSTS.FILLED}
        value={data.value || ''}
        onChange={(e) => handleInputChange(e, data.type)}
        error={formError[data.error] ? true : false}
        helperText={formError[data.error] ? getErrorMsg(data.error) : ''}
      />
    );
  };

  const passInputRender = (data) => {
    let buttonLbl = changePassword ? CONSTS.KEEP_PASS : CONSTS.CHANGE_PASS;
    return (
      <React.Fragment>
        <React.Fragment>
          <MasterButton
            keyLbl={CONSTS.CHANGE_PASS_LBL}
            label={buttonLbl}
            buttonType={CONSTS.SUCCESS}
            isDisabled={false}
            handleClick={() => handleChangePassword(buttonLbl)}
            size={CONSTS.BTN_SIZE.medium}
            class={classes.containedBtnPass} />
        </React.Fragment>
      </React.Fragment>
    );
  };

  const changePassInputRender = (data) => {
    return (
      <FormControl className={classes.formControlTop}>
        <InputLabel className={formError[data.error] ? classes.passInputError : classes.passInput}>{data.label}</InputLabel>
        <FilledInput
          error={formError[data.error] ? true : false}
          style={data.style}
          id={data.id}
          type={data.show ? CONSTS.TEXT : CONSTS.PASSWORD}
          value={data.value || ''}
          onChange={(e) => handleInputChange(e, data.type)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleClickShowPassword(data.type)}
              >
                {data.show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formError[data.error] &&
          <FormHelperText id={`${data.id}-error`} style={{ color: 'red' }}>{getErrorMsg(data.error)}</FormHelperText>
        }
      </FormControl>
    );
  };


  const buttonRender = () =>{
    return(
      <React.Fragment>
       <MasterButton
         id="webNavv-settings-accountForm-cancelButton-1.0"
         keyLbl={CONSTS.CHANGE_PASS_LBL}
         label={CONSTS.CANCEL}
         isDisabled={false}
         buttonType={CONSTS.DEFAULT_FLAT}
         handleClick={handleCancel}
         size={CONSTS.BTN_SIZE.medium}
         styles={{marginLeft: '15px'}}
         />
       <MasterButton
         id= "webNavv-settings-accountForm-submitButton-1.0"
         keyLbl={CONSTS.CHANGE_PASS_LBL}
         label={CONSTS.SAVE}
         buttonType={CONSTS.SUCCESS}
         isDisabled={formError.isError}
         handleClick={handleSubmit}
         size={CONSTS.BTN_SIZE.medium}
         styles={{marginLeft: '15px'}}
         class={classes.containedBtn}/>
      </React.Fragment>
     );
  };

  const renderSecurity = () =>{
    return (
      <React.Fragment>
        <Grid item xs={12}>
            <div className={classes.securityContainer}>
              <div className={classes.titleContainer}>
                <LockIcon className={classes.iconTitle}/>
              </div>
              <div  className={classes.inlineContainer}>
                <h3 className={classes.titleLbl}>{CONSTS.SECURITY}</h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            {passInputRender({
              style: {width:'100%'},
              id: "webNavv-settings-accountForm-passwordButton-1.0",
              label: CONSTS.PASS_LBL,
              value: userInfo.password,
              show: show.password,
              type: 4
            })}
          </Grid>
          {changePassword && (
          <React.Fragment>
            <Grid item xs={6}>
            {changePassInputRender({
              style: {width:'155%'},
              id:"webNavv-settings-accountForm-newPasswordInput-1.0",
              label:CONSTS.NEW_PASS_LBL,
              value: userInfo.pass1,
              error: CONSTS.ERROR_INPUT.pass.id,
              inputType: CONSTS.PASSWORD,
              show: show.pass1,
              type: 5})}
            </Grid>
            <Grid item xs={6}>
            {changePassInputRender({
              style: {width:'155%'},
              id:"webNavv-settings-accountForm-reTypePasswordInput-1.0",
              label:CONSTS.RETYPE_PASS_LBL,
              value: userInfo.pass2,
              error: CONSTS.ERROR_INPUT.confirmPass.id,
              inputType: CONSTS.PASSWORD,
              show: show.pass2,
              type: 6})}
            </Grid>
          </React.Fragment>
          )}
      </React.Fragment>
    );
  };

  return(
    <div className={classes.container} id="webNavv-settings-accountForm-1.0">
      <div className={classes.mainDiv}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <div className={classes.titleContainer}>
                <PersonIcon className={classes.iconTitle} />
              </div>
              <div className={classes.inlineContainer}>
                <h3 className={classes.titleLbl}>{CONSTS.ACCOUNT_INFO}</h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
          {inputRender({
            style: {width:'80%'},
            id:"webNavv-settings-accountForm-firstNameInput-1.0",
            label:CONSTS.FIRST_NAME,
            value: userInfo.firstName,
            error: CONSTS.ERROR_INPUT.firstName.id,
            inputType: CONSTS.TEXT,
            type: 1})}
          </Grid>
          <Grid item xs={6} >
          {inputRender({
            style: {width:'80%'},
            id:"webNavv-settings-accountForm-lastNameInput-1.0",
            label:CONSTS.LAST_NAME,
            value: userInfo.lastName,
            error: CONSTS.ERROR_INPUT.lastName.id,
            inputType: CONSTS.TEXT,
            type: 2})}
          </Grid>
          <Grid item xs={12}>
          {inputRender({
            style: {width:'40%', marginTop: '30px'},
            id:"webNavv-settings-accountForm-emailInput-1.0",
            label:CONSTS.EMAIL,
            value: userInfo.email,
            error: CONSTS.ERROR_INPUT.email.id,
            inputType: CONSTS.TEXT,
            type: 3})}
          </Grid>
          {userInfo.basicAuth && (renderSecurity())}
        </Grid>
      </div>
      <div className={classes.buttonContainer}>
        {buttonRender()}
      </div>
      <AwesomeSpinner config={'default'} loading={showSpinner} navOpen={props.open} />
      <MasterAlert
        snackStyle={{ marginTop: '5vh' }}
        anchor={{ vertical: 'top', horizontal: 'right' }}
        openSnack={snack.open}
        handleClose={handleCloseSnackbar}
        alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
        variant={CONSTS.FILLED}
        type={snack.severity}
        message={snack.msg}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  users: state.user.users,
  userId: state.auth.userId,
  open: state.views.drawerOpen,
  campusId: state.auth.userInfo.campusId,
  userInfo: state.auth.userInfo
});

export default connect(mapStateToProps, { patchUser, createLog })(UserAccount);