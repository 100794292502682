/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResource_Slot = /* GraphQL */ `
  mutation CreateResource_Slot($input: CreateResource_Slot_Input!) {
    createResource_Slot(input: $input) {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const updateResource_Slot = /* GraphQL */ `
  mutation UpdateResource_Slot($input: UpdateResource_Slot_Input!) {
    updateResource_Slot(input: $input) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const updateResource_SlotUnassignUser = /* GraphQL */ `
  mutation UpdateResource_SlotUnassignUser(
    $input: UpdateResource_Slot_UnassignUserInput!
  ) {
    updateResource_SlotUnassignUser(input: $input) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const deleteResource_Slot = /* GraphQL */ `
  mutation DeleteResource_Slot($input: DeleteResource_Slot_Input!) {
    deleteResource_Slot(input: $input) {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const deleteResource_SlotJobs = /* GraphQL */ `
  mutation DeleteResource_SlotJobs($input: DeleteResource_Slot_Input!) {
    deleteResource_SlotJobs(input: $input) {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const updateAssociatedJob = /* GraphQL */ `
  mutation UpdateAssociatedJob($input: UpdateAssociatedJob_Input!) {
    updateAssociatedJob(input: $input) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const createAssociatedJob = /* GraphQL */ `
  mutation CreateAssociatedJob($input: CreateAssociatedJob_Input!) {
    createAssociatedJob(input: $input) {
      jobid
      job_name
      job_type
      job_description
      job_start_date
      job_end_date
      job_typeid
      description
      issequence_required
      job_statusid
      job_status
      resource_slotid
      modified_by
      job_percentage_complete
      job_number
      counterdoy
    }
  }
`;
export const deleteAssociatedJob = /* GraphQL */ `
  mutation DeleteAssociatedJob($input: DeleteJob_Input!) {
    deleteAssociatedJob(input: $input) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const createJob_Template = /* GraphQL */ `
  mutation CreateJob_Template($input: CreateJob_TemplateInput!) {
    createJob_Template(input: $input) {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const updateJob_Template = /* GraphQL */ `
  mutation UpdateJob_Template($input: UpdateJob_TemplateInput!) {
    updateJob_Template(input: $input) {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const deleteJob_Template = /* GraphQL */ `
  mutation DeleteJob_Template($input: DeleteJob_TemplateInput!) {
    deleteJob_Template(input: $input) {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const createTask_Template = /* GraphQL */ `
  mutation CreateTask_Template($input: CreateTask_TemplateInput!) {
    createTask_Template(input: $input) {
      task_templateid
      task_template_name
      description
      special_instructions
      job_templateid
      task_template_start_date
      task_template_end_date
      task_typeid
      task_locationid
      task_statusid
      task_actionid
      suggested_sequence
      tenantid
    }
  }
`;
export const updateTask_Template = /* GraphQL */ `
  mutation UpdateTask_Template($input: UpdateTask_TemplateInput!) {
    updateTask_Template(input: $input) {
      task_templateid
      task_template_name
      description
      special_instructions
      job_templateid
      task_template_start_date
      task_template_end_date
      task_typeid
      task_locationid
      task_statusid
      task_actionid
      suggested_sequence
      tenantid
    }
  }
`;
export const deleteTask_Template = /* GraphQL */ `
  mutation DeleteTask_Template($input: DeleteTask_TemplateInput!) {
    deleteTask_Template(input: $input) {
      task_templateid
      task_template_name
      description
      special_instructions
      job_templateid
      task_template_start_date
      task_template_end_date
      task_typeid
      task_locationid
      task_statusid
      task_actionid
      suggested_sequence
      tenantid
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask($input: UpdateTask_Input!) {
    updateTask(input: $input) {
      taskid
      floor
      task_name
      jobid
      job_statusid
      job_status
      task_description
      special_instructions
      task_typeid
      task_type
      task_locationid
      location_name
      task_statusid
      task_status
      task_actionid
      task_action
      task_start_date
      task_end_date
      suggested_sequence
      job_percentage_complete
      floor_name
      distance_traveled
      unstarted_taskid
    }
  }
`;
export const updateJob_Inerror = /* GraphQL */ `
  mutation UpdateJob_Inerror($input: UpdateJob_Error!) {
    updateJob_Inerror(input: $input) {
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      jobid
      taskid
      tenantid
      job_name
      job_type
      job_description
      job_start_date
      job_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
      modified_by
      job_percentage_complete
      job_number
      job_started_date
      job_ended_date
      job_mobile_sessionid
    }
  }
`;
export const updateJobBoardSchedule = /* GraphQL */ `
  mutation UpdateJobBoardSchedule(
    $futuredayscount: Int!
    $resetcounter: Boolean!
  ) {
    updateJobBoardSchedule(
      futuredayscount: $futuredayscount
      resetcounter: $resetcounter
    ) {
      success
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const updateDelayed_Jobs = /* GraphQL */ `
  mutation UpdateDelayed_Jobs {
    updateDelayed_Jobs {
      success
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
