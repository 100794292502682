import ChkBox from "@material-ui/core/Checkbox";
import { withStyles} from '@material-ui/core/styles';

export const CustomCheckbox = withStyles({
    root: {
        color: '#2196F3',
      "&$checked": {
        color: '#2196F3'
      },
      "&$disabled": {
        color: '#2196F3'
      }
    },
    checked: {},
    disabled: {}
})(ChkBox)

export const DisabledCheckbox = withStyles({
    root: {
        color: '#757575',
      "&$checked": {
        color: '#757575'
      },
      "&$disabled": {
        color: '#757575'
      }
    },
    checked: {},
    disabled: {}
})(ChkBox)
