import ErrorIcon from '../../../../img/error.svg';
export const handlePaintAnchorLocationsOnMap = (locations, mapkit, mapConfigs) =>{
    locations.forEach(location =>{
        let coords = new window.mapkit.Coordinate(location.geometry.coordinates[1],location.geometry.coordinates[0]);
        let optionsLocation = {
            calloutEnabled: false,
            title: location.properties.id,
            subtitle: "anchorLocation",
            data: {
                    name: location.properties.name,
                    ordinal: location.properties.ordinal
            },
            enabled: true,
            visible: false
        };

        let optionsCallout = {
            calloutEnabled: false,
            title: location.properties.id,
            subtitle: "anchorCallout",
            data: {
                    name: location.properties.name,
                    ordinal: location.properties.ordinal
            },
            enabled: true,
            visible: false
        };

        let optionsErrorCallout = {
            calloutEnabled: false,
            title: location.properties.id,
            subtitle: "anchorCalloutError",
            data: {
                    name: location.properties.name,
                    ordinal: location.properties.ordinal
            },
            enabled: true,
            visible: false
        };
        mapkit = createAnchorAnnotations(mapkit, coords, optionsLocation, optionsCallout, optionsErrorCallout, mapConfigs);
    });
    return mapkit;
}

const createAnchorAnnotations = (mapkit, coords, optionsLocation, optionsCallout, optionsErrorCallout, mapConfigs) =>{
    const locationAnnotation = createAnchorLocationAnnotation(coords,optionsLocation, mapConfigs);
    mapkit.addAnnotation(locationAnnotation);

    const callout = createAnchorCalloutAnnotation(coords,optionsCallout, mapConfigs);
    mapkit.addAnnotation(callout);

    const errorCallout = createAnchorErrorCalloutAnnotation(coords,optionsErrorCallout, mapConfigs);
    mapkit.addAnnotation(errorCallout);

    return mapkit;
}

const createAnchorLocationAnnotation = (coords, calloutOptions, mapConfigs)=>{
    let calloutFactory = handleFactoryAnchorLocationAnnotation(coords, calloutOptions, mapConfigs);
    var callout = new window.mapkit.Annotation(coords, calloutFactory, calloutOptions);
    
    return callout;
};

const createAnchorCalloutAnnotation = (coords, calloutOptions, mapConfigs)=>{
    let calloutFactory = handleFactoryAnchorLocationCallout(coords, calloutOptions, mapConfigs);
    var callout = new window.mapkit.Annotation(coords, calloutFactory, calloutOptions);
    
    return callout;
};

const createAnchorErrorCalloutAnnotation = (coords, calloutOptions, mapConfigs)=>{
    let calloutFactory = handleFactoryAnchorLocationErrorCallout(coords, calloutOptions, mapConfigs);
    var callout = new window.mapkit.Annotation(coords, calloutFactory, calloutOptions);
    
    return callout;
};

const handleFactoryAnchorLocationAnnotation= (coordinate, options, mapConfigs)=>{
    return (coordinate, options) =>{
      let divContainer = document.createElement("div");
      let divPin = document.createElement("div");
  
      let calloutStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_Callout.main_Container : {};
      let calloutProperties = Object.keys(calloutStyles);
      calloutProperties.forEach(property=>{
          divPin.style[property] = calloutStyles[property];
      });
  
      divContainer.append(divPin);
      return divContainer;
    };
  };

const handleFactoryAnchorLocationCallout = (coordinate, options, mapConfigs)=>{
    return (coordinate, options) =>{
        let nameLblSize = "12px";
        const locationLbl = options.data.name.trim();
      
        let divCallout = document.createElement("div"),id="marker";
        divCallout.className = "anchor-callout";
        let pinStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_Annotation : {};
        let pinProperties = Object.keys(pinStyles);
        pinProperties.forEach(property=>{
            divCallout.style[property] = pinStyles[property];
        });
      
        let divPointer = document.createElement("div");
        let calloutPointerStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_Callout.callout_pointer : {};
        let calloutPointerProperties = Object.keys(calloutPointerStyles);
        calloutPointerProperties.forEach(property=>{
            divPointer.style[property] = calloutPointerStyles[property];
        });
        divPointer.style.fontSize = nameLblSize;

        let textContainer = document.createElement("div");
        let textContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_Callout.text_Container : {};
        let textContainerProperties = Object.keys(textContainerStyles);
        textContainerProperties.forEach(property=>{
            textContainer.style[property] = textContainerStyles[property];
        });
        textContainer.append(locationLbl);

        divPointer.append(textContainer);
        divCallout.append(divPointer);
      
        return divCallout;
    };
};

const handleFactoryAnchorLocationErrorCallout = (coordinate, options, mapConfigs)=>{
    return (coordinate, options) =>{
        let nameLblSize = "12px";
        const locationLbl = options.data.name.trim();
      
        let divCallout = document.createElement("div"),id="marker";
        divCallout.className = "anchor-error-callout";
        let calloutStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_error_Callout.main_Container : {};
        let calloutProperties = Object.keys(calloutStyles);
        calloutProperties.forEach(property=>{
            divCallout.style[property] = calloutStyles[property];
        });
      
        let divPointer = document.createElement("div");
        let calloutPointerStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_error_Callout.callout_pointer : {};
        let calloutPointerProperties = Object.keys(calloutPointerStyles);
        calloutPointerProperties.forEach(property=>{
            divPointer.style[property] = calloutPointerStyles[property];
        });
        divPointer.style.fontSize = nameLblSize;

        let textContainer = document.createElement("div");
        let textContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_error_Callout.text_Container : {};
        let textContainerProperties = Object.keys(textContainerStyles);
        textContainerProperties.forEach(property=>{
            textContainer.style[property] = textContainerStyles[property];
        });
        textContainer.append(locationLbl);

        let image = document.createElement("img");
        let errorIconStyles = Object.keys(mapConfigs).length ? mapConfigs.anchors.location_error_Callout.icon : {};
        let errorIconProperties = Object.keys(errorIconStyles);
        errorIconProperties.forEach(property=>{
            image.style[property] = errorIconStyles[property];
        });
        image.setAttribute('src', ErrorIcon);
        image.setAttribute('alt', 'location-errorIcon');

        divPointer.append(textContainer);
        divPointer.append(image);
        divCallout.append(divPointer);
      
        return divCallout;
    };
};