import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from "@material-ui/core";
import logoutIcon from './img/out_grey.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: 120,
        '& > *': {
            margin: theme.spacing(0),
            padding: 0,
        },
        '& p': {
            fontFamily: 'sans-serif',
            fontSize: 10,
            margin: 0,
            padding: 0,
        },
    },
    logoutContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    iconItem: {
       lineHeight: 'normal',
    }
}));

export default function DashboardMenu(props) {
    const { logout, version, online } = props;
    const classes = useStyles();

    return (
        <Menu>
            <MenuItem style={{ backgroundColor: '#FFF', marginTop: '50px' }} disabled>{version}</MenuItem>
            <MenuItem style={{ backgroundColor: '#FFF' }}  onClick={logout}>
                <div className={classes.logoutContainer}>
                    <Icon className={classes.iconItem}>
                        <img src={logoutIcon} height={20} width={20} />
                    </Icon>
                <ListItemText primary="Logout" />
                </div>
            </MenuItem>
        </Menu>
    );
}