import React from 'react';
import {useStyles} from './styles';
import moment from "moment";

import Box from '@material-ui/core/Box';
import TextField from 'material-ui/TextField';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

function RoleList(props){
    const classes = useStyles();

    const renderTeamToUpdate=(roleInfo,createDate,lastUpdate,users,phones,equipment)=>{
        props.updateCurrentTeamName(roleInfo.role);
        return(
            <Box
            component="div"
            display="block"
            bgcolor= "white"
            p={1}
            m={2}
            ml={5}
            height='35%'
            width='80%'
            borderRadius='4px'
            boxShadow=' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'>
                <div style={{display:'block'}}>
                    <TextField
                        style={{width:'90%', marginLeft:'5%'}}
                        id="new-team"
                        hintText="Team Name"
                        // defaultValue={roleInfo.role}
                        onChange={(e)=>{props.changeRoleName(e.target.value)}}
                        value={props.roleName ||roleInfo.role}
                        ref={props.roleNameInput}
                    />
                </div>
                <div className={classes.regularContainer}>
                    <div className={classes.containerInlineBlock}><p className='team-date'>Users:</p></div>
                    <div className={classes.containerInlineBlock}>
                        <p className='team-data' style={{marginLeft:'10px'}}>{users}</p>
                    </div>
                </div>
                <div className={classes.regularContainer}>
                    <div className={classes.containerInlineBlock}><p className='team-date'>Phones:</p></div>
                    <div className={classes.containerInlineBlock}>
                        <p className='team-data' style={{marginLeft:'10px'}}>{phones}</p>
                    </div>
                </div>
                <div className={classes.regularContainer}>
                    <div className={classes.containerInlineBlock}><p className='team-date'>Equipment:</p></div>
                    <div className={classes.containerInlineBlock}>
                        <p className='team-data' style={{marginLeft:'10px'}}>{equipment}</p>
                    </div>
                </div>
                <div className={classes.regularContainer}>
                    <div className={classes.containerInlineBlock}><p className='team-date'>Create date:</p></div>
                                <div className={classes.containerInlineBlock}>
                        <p className='team-data' style={{marginLeft:'10px'}}>{createDate}</p>
                    </div>
                </div>
                <div className={classes.regularContainer}>
                     <div className={classes.containerInlineBlock}><p className='team-date'>Track:</p></div>
                    <div className={classes.roleToUpdateVariant}><p className='team-data'>{roleInfo.track? 'Yes': 'No'}</p></div>
                </div>
                <div style={{display:'block',height:'15px', paddingBottom:'20px', marginBottom:'10px'}}>
                    <div className={classes.containerInlineBlock}><p className='team-date'>Last update:</p></div>
                     <div className={classes.roleToUpdateVariant}>
                         <p className='team-data' style={{marginLeft:'10px'}}>{lastUpdate}</p>
                    </div>
                </div>
            </Box>
        )
    }
    return (
        <div id="webNavv-adminPanel-accessControl-teamsManager-1.0">
            {props.roles.sort().map((role,index)=>{
                let createDate = 'n/a';
                let lastUpdate = 'n/a';
                if(role.hasOwnProperty("create_date"))
                    createDate = moment(role.create_date).format("MM/DD/YYYY HH:mm");
                if(role.hasOwnProperty("last_update"))
                    lastUpdate = moment(role.last_update).format("MM/DD/YYYY HH:mm");
                return(
                <div key={index} id={`${index}-webNavv-adminPanel-accessControl-teamsManager-teamsCard-1.0`}>
                    {props.editTeam && props.teamSelected===role._id ?
                        renderTeamToUpdate(role,createDate,lastUpdate,props.teamUsers[role._id], props.teamPhones[role._id], props.teamEquipment[role._id])
                    :
                    <Box
                    component="div"
                    display="block"
                    key={index}
                    bgcolor= {props.teamSelected===role._id? "#E3F2FD":"white"}
                    p={1}
                    m={2}
                    ml={5}
                    height='110px'
                    width='80%'
                    borderRadius='4px'
                    boxShadow=' 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
                    className={classes.teamBtn}
                    onClick={()=>{props.handleSelectTeam(role._id)}}>
                        <div className={classes.regularContainerVariant}>
                            <div className={classes.showTeamsRoleContainer}><p className={classes.showTeamRoleLbl}>{role.role}</p></div>
                        </div>
                        <div className={classes.regularContainerComplex}>
                            <div className={classes.containerInlineBlock}><p className='team-date'>Create date:</p></div>
                            <div className={classes.containerInlineBlock}>
                                <p className='team-data' style={{marginLeft:'10px'}}>{createDate}</p>
                            </div>
                        </div>
                        <div className={classes.regularContainer}>
                            <div className={classes.containerInlineBlock}><p className='team-date'>Track:</p></div>
                            <div className={classes.regularContainerMini}><p className='team-data'>{role.track? 'Yes': 'No'}</p></div>
                        </div>
                        <div className={classes.regularContainer}>
                            <div className={classes.containerInlineBlock}><p className='team-date'>Last update:</p></div>
                            <div className={classes.regularContainerMini}>
                                <p className='team-data' style={{marginLeft:'10px'}}>{lastUpdate}</p>
                            </div>
                        </div>
                        <div className={classes.chipContainer}>
                          <div style={{display:'block'}}>
                            <Chip label={props.teamUsers[role._id]}  className={classes.showTeamChip} icon={<PersonIcon style={{fill:'#2843A3'}}/>} />
                          </div>
                          <div style={{display:'block'}}>
                            <Chip label={props.teamPhones[role._id]}  className={classes.showTeamChip} icon={<PhoneIphoneIcon style={{fill:'#2843A3'}}/>}/>
                          </div>
                          <div style={{display:'block'}}>
                            <Chip label={props.teamEquipment[role._id]}  className={classes.showTeamChip} icon={<BusinessCenterIcon style={{fill:'#2843A3'}}/>}/>
                          </div>
                        </div>
                    </Box>
                    }
                </div>
                )}
            )}
        </div>
    );
}

export default RoleList;