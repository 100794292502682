import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { AccessTime, ArrowForwardIos} from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { changeView } from "../../../actions/views/viewsActions";

import MasterButton from "../../utilities/button.component";
import AwesomeSpinner from "../../utilities/spinner";
import MasterTable from "../../utilities/table.component";
import JobHistoryToolbar from "./customTableToolbar/customToolbar.component";
import HistoryTimeWindowControls from "./timeWindowControls/historyTmeWindowControls.component";
import { OPTIONS, TABLE_COLUMNS, FROMINPUT, EXPORT, JOBS_HISTORY, WORKFLOW_AUTOMATION, SEARCH, TABLE_SORT, DEFAULT_SORT } from "./utils/jobsHistory.constants";
import { handleExportTable, handleFormatTableData, handleTableSearch, handleGetCurrentTeam, handleFilterJobsByContacts } from "./utils/jobsHistory";
import { queryListJobHistoryByDateRange, queryListJobTasks, queryListTeams, queryListUsersByTeam } from "../graphql/queries";
import {getJobHistoryTraces} from "./../../../actions/directions/directionsActions";
import {formatRangeDatesWithOffsetAndTimezone} from "../jobsBoard/utils/jobsBoard";

import {styles} from './jobsHistory.styles';
import "./jobsHistory.css";
class JobsHistory extends React.Component{
    constructor(props){
        super(props);
        this.state={
            searchValue: '',
            isSearch: false,
            today: moment().startOf('day'),
            endOfToday: moment().endOf('day'),
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day'),
            minDate: moment(moment().startOf('day')).subtract(1, 'years'),
            maxDate: moment().endOf('day'),
            fromInputError: false,
            toInputError: false,
            jobs: [],
            tableData: [],
            showSpinner: true,
            fromInputDate: moment().startOf('day'),
            toInputDate: moment().endOf('day'),
            showTimeWindowControls: false,
            timeWindowDateChanged: false,
            startDateError: false,
            users: [],
            currentRowsPerPage: 10
        }
        this.currentTeamId =  [];
        this.campusId= this.props.campusId === "5efe07aee72d619780372441" ? "de047d5f-a1e4-4328-b5e0-11b160248cbc": "";
        this.contactsMongoDB = [];
        this.contactsGraphQL = [];
    }

    componentDidMount = async() =>{
      await this.getTeamsList();
      await this.getListUsersByTeam();
      await this.handleGetTableData();
    }

    componentDidUpdate(){
      if (this.state.timeWindowDateChanged) {
        this.handleGetTableData();
        this.setState({timeWindowDateChanged: false})
      }
    }

    getTeamsList = async() =>{
      const {currentUserContacts, contacts, currentTeam} = this.props;
      const response = await queryListTeams(this.campusId);

      if (!!response){
        const {currentContactsMongoDB, currentContactsGraphQL, currentUserTeamGraphQL} = handleGetCurrentTeam(response, currentUserContacts, contacts, currentTeam);
        this.contactsMongoDB = currentContactsMongoDB;
        this.contactsGraphQL = currentContactsGraphQL;
        this.currentTeamId = currentUserTeamGraphQL;
      }
    }

    getListUsersByTeam = async () =>{
      let data = [];
      for (const contact of this.contactsGraphQL) {
        const response = await queryListUsersByTeam(contact.teamid);
        if (!!response){
          data.push(...response);
        }
      }
      if (!!data){
        this.setState({users: data});
      }
    }

    handleGetTableData = async() =>{
      const {startDate, endDate, users} = this.state;

      const {minDate, maxDate} = formatRangeDatesWithOffsetAndTimezone(startDate, endDate);
      console.log("Date range, get date from:", minDate, "to:", maxDate);
      const response = await queryListJobHistoryByDateRange(minDate, maxDate);

      if(!!response){
        const jobsInContacts = handleFilterJobsByContacts(response, users);
        this.handleFormatData(jobsInContacts);
      }else{
        this.setState({showSpinner: false});
      }
    }

    handleFormatData = (jobs) =>{
      const data = handleFormatTableData(jobs);

      this.setState({
        tableData: data,
        jobs: data,
        showSpinner: false
      });
    }

    handleSearch = (search) =>{
      if (search !== '') {
        this.setState({
          isSearch: true,
          searchValue: search
        });
        this.handleFilterSearch(search);
      } else {
        this.setState({ 
          isSearch: false,
          searchValue: "",
          tableData: this.state.jobs
        });
      }
    }

    handleFilterSearch = (search) => {
      const {tableData} = this.state;
      const filtered = handleTableSearch(search, tableData);
      
      this.setState({tableData: filtered});
    };

    handleChangeInputDate = (type, newValue) =>{
      const {endDate, minDate, maxDate, fromInputDate, fromInputError} = this.state;
      
      if (type === FROMINPUT) {
        if (moment(newValue).isBetween(minDate, endDate)) {
          this.setState({ 
            startDate: moment(newValue),
            fromInputDate: moment(newValue),
            fromInputError: false 
          });
        } else {
          this.setState({ 
            fromInputDate: moment(newValue),
            fromInputError: true
          });
        }
      } else {
        if (moment(newValue).isBetween(fromInputDate, maxDate) || moment(newValue).isSame(fromInputDate)) {
          if ( (moment(newValue).isAfter(fromInputDate) || moment(newValue).isSame(fromInputDate) ) && fromInputError) {
            this.setState({ 
              fromInputError: false,
              startDate: fromInputDate
            });
          }
          this.setState({ 
            endDate: moment(newValue).add(23,"hours"),
            toInputDate: moment(newValue).add(23,"hours"),
            toInputError: false
          });
        } else {
          this.setState({ 
            toInputDate: moment(newValue).add(23,"hours"),
            toInputError: true
          });
        }
      }
    }

    handleUpdateCalendarTimeWindow = () =>{
      const {fromInputDate, toInputDate} = this.state;

      this.setState({
        showSpinner: true,
        startDate: fromInputDate,
        endDate: toInputDate,
        showTimeWindowControls: false,
        timeWindowDateChanged: true
      });

      setTimeout(() => {
        this.setState({
          showSpinner: false,
        });
      }, 2000);
    }


    handleCancelCalendarTimeWindow = () =>{
      const {today, endOfToday} = this.state;

      this.setState({
        showTimeWindowControls: false,
        timeWindowDateChanged: false,
        showSpinner: false,
        fromInputDate: today,
        toInputDate: endOfToday,
        fromInputError: false,
        toInputError: false
      });
    }

    handleTimeWindowVisibility = () =>{
      this.setState({showTimeWindowControls: true});
    }

    handleOpenJobOnMap = async (job) =>{
      this.setState({showSpinner: true});
      this.getJobLocations(job);
      this.getJobTasks(job);
    }

    getJobLocations = (job) =>{
      this.props.getJobHistoryTraces(job[0].jobSessionId, job[0].startTime, job[0].endTime);
    }

    getUserDevice = (job) =>{
      const { users } = this.props;
      const currentUserInfo = users.find(user=> `${user.first_name} ${user.last_name}`=== job[0].messenger) || null;

      if (!!currentUserInfo) {
        return currentUserInfo.device_id;
      }
      return ""
    }

    getJobTasks = async(job) =>{
      const response = await queryListJobTasks(job[0].jobId);
      job[0].device = this.getUserDevice(job);
      job[0].status = job[0].status.status;
      
      if(!!response){
        this.props.changeView('map', 'workflowAutomation', {tasks:response, jobInfo:job });
      }else{
        this.setState({showSpinner: false});
      }
    }

    handleReturnTableConfigs = () =>{
      const {tableData, currentRowsPerPage} = this.state;
      const {open} = this.props;

      const options ={
        filterType: OPTIONS.filterType,
        responsive: OPTIONS.responsive,
        rowsPerPage: currentRowsPerPage,
        rowsPerPageOptions: OPTIONS.rowsPerPageOptions,
        selectableRowsHeader: false,
        selectableRows: OPTIONS.selectableRows,
        selectableRowsOnClick: true,
        search: false,
        print: false,
        filter: false,
        download: false,
        viewColumns: false,
        toolbar: {
          filterTable: OPTIONS.filterTable,
        },
        customSort: (data, colIndex, order) => {
          let sortType = order=== DEFAULT_SORT? true:false;
          return  TABLE_SORT(data, colIndex, sortType);
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
          <JobHistoryToolbar
          selectedRows={selectedRows}
          data={tableData}
          setSelectedRows={setSelectedRows}
          handleOpenJobOnMap={this.handleOpenJobOnMap}
          leftNavOpen={open}
          // getTraces={this.spaghetti}
          // jobData={this.tableJobData}
          />
        ),
        onTableChange:(action,tableState)=>{
          switch(action){
             case "changeRowsPerPage":
               this.setState({currentRowsPerPage: tableState.rowsPerPageOptions});
               break
             default:
               break
          }
         },
      }

      return options;
    }

    renderTableHeader = () => {
      const {classes} = this.props;
      const {searchValue, startDate, endDate, jobs, showTimeWindowControls, fromInputDate, toInputDate, minDate, maxDate, fromInputError, toInputError} = this.state;

        return (
          <div>
            <Input
              id="webNavv-workflowAutomation-jobsHistory-searchBar-1.0"
              className={classes.JobsHistory_SearchBar}
              placeholder={SEARCH}
              disableUnderline={true}
              value={searchValue}
              onChange={e => { this.handleSearch(e.target.value) }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              } 
            />
            <HistoryTimeWindowControls
            handleChangeInputDate={this.handleChangeInputDate}
            handleCancelCalendarTimeWindow={this.handleCancelCalendarTimeWindow}
            handleUpdateCalendarTimeWindow={this.handleUpdateCalendarTimeWindow}
            handleTimeWindowVisibility={this.handleTimeWindowVisibility}
            startDate={startDate}
            endDate={endDate}
            showTimeWindowControls={showTimeWindowControls}
            fromInputDate={fromInputDate}
            toInputDate={toInputDate}
            minDate={minDate}
            maxDate={maxDate}
            fromInputError={fromInputError}
            toInputError={toInputError}
            />
            <MasterButton
              keyLbl={'export-jobs-history'}
              label={EXPORT}
              buttonType={'success'}
              isDisabled={false}
              handleClick={() =>handleExportTable(jobs, startDate)}
              size={"medium"}
              class={classes.JobsHistory_blueButton}/>
          </div>
        );
    }

    render(){
      const {showSpinner, tableData} = this.state;
      const {open, classes} = this.props;
      const tableConfigs = this.handleReturnTableConfigs();

        return(
            <React.Fragment>
                <div id="webNavv-workflowAutomation-jobsHistory-1.0">
                    <div id="webNavv-workflowAutomation-jobsHistory-titleContainer-1.0" className="jobsHistory-TitleContainer"> 
                        <div className="jobsHistory-JobInfoViewTitleIcon">
                          <AccessTime />
                        </div>
                        <div className="jobsHistory-Title">{JOBS_HISTORY}</div>
                        {/* <div className="jobsHistory-PathContainer">
                          <div className="jobsHistory-Path1">{WORKFLOW_AUTOMATION}</div>
                          <div><ArrowForwardIos className={classes.JobsHistory_JobRouteIcon}/></div>
                          <div className="jobsHistory-Path2">{JOBS_HISTORY}</div>
                        </div> */}
                    </div>
                    {showSpinner? 
                    (
                      <AwesomeSpinner
                        id="webNavv-workflowAutomation-jobsBoard-spinner-1.0"
                        config="default"
                        loading={showSpinner}
                        navOpen={open}
                      />
                    ):(
                      <div className="jobsHistory-container">
                        <MasterTable
                          title={this.renderTableHeader()}
                          data={tableData}
                          columns={TABLE_COLUMNS}
                          options={tableConfigs}
                        />
                      </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
  open:state.views.drawerOpen,
  users: state.user.users,
  currentUserContacts: state.auth.acl.contact,
  currentTeam: state.auth.acl.role,
  contacts: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
});

export default connect(mapStateToProps, {
  changeView,
  getJobHistoryTraces
})(withStyles(styles)(JobsHistory));