export const SET_INPUTS = (props) =>{
    let data = [
        {
          id:"webNavv-tableView-equipmentTable-filterForm-deviceCategory-1.0",
          key: "deviceCategory",
          label: "Device Category",
          value: props.filter.deviceCategory,
          input: 'select',
          grid: 6,
          list: props.equipmentCategories,
          listKey: 'name',
          style: {width:'70%', marginTop: '20px', marginLeft:'30px'}
        },
        {
          id:"webNavv-tableView-equipmentTable-filterForm-controlNumber-1.0",
          key: "controlNum",
          label: "Control Number",
          value: props.filter.controlNum,
          input: 'text',
          grid: 6,
          style: {width:'70%', marginTop: '20px', marginLeft:'30px'}
        },
        {
          id:"webNavv-tableView-equipmentTable-filterForm-model-1.0",
          key: "model",
          label: "Model",
          value: props.filter.model,
          input: 'text',
          grid: 6,
          style: {width:'70%', marginLeft:'30px'}
        },
        {
          id:"webNavv-tableView-equipmentTable-filterForm-maker-1.0",
          key: "maker",
          label: "Maker",
          value: props.filter.maker,
          input: 'text',
          grid: 6,
          style: {width:'70%', marginLeft:'30px'}
        },
        {
          id:"webNavv-tableView-equipmentTable-filterForm-team-1.0",
          key: "team",
          label: "Team",
          value: props.filter.team,
          input: 'select',
          grid: 6,
          list: props.roles,
          listKey: 'role',
          style: {width:'70%', marginLeft:'30px'}
        },
        {
          id:"webNavv-tableView-equipmentTable-filterForm-department-1.0",
          key: "department",
          label: "Department",
          value: props.filter.department,
          input: 'text',
          grid: 6,
          style: {width:'70%', marginLeft:'30px'}
        }
    ];
  
    return data;
  };