import React from 'react';
import {CustomCheckbox, DisabledCheckbox} from './styles';

import Grid from '@material-ui/core/Grid';
import Checkbox from 'material-ui/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function ContactOptions(props){
    return(
      <div id="webNavv-adminPanel-accessControl-accessManager-teamComposition-1.0">
        <Grid container spacing={1}>
        {props.roles.map((e, index)=>{
        let checked=props.selectedRolesChat.includes(e._id);
        let defaultCkd = checked?true:false;
        return (
          <Grid item md={6} key={index}>
          <div className="section">
          {props.teamSelected && !props.editTeam && !defaultCkd &&
             <FormControlLabel
                  style={{float:'left'}}
                  control={<DisabledCheckbox
                      value={e._id}
                      disabled
                  /> }
                  label={e.role}
              />
          }
          {props.teamSelected && !props.editTeam && defaultCkd &&
              <FormControlLabel
                  style={{float:'left'}}
                  control={<CustomCheckbox
                      value={e._id}
                      checked
                      disabled
                  /> }
                  label={e.role}
              />
          }
          {props.teamSelected && props.editTeam &&
              <Checkbox
              iconStyle={{fill: '#2196F3'}}
              style={{textAlign:'left'}}
              value={e._id}
              label={e.role}
              onCheck={props.handleRolesCheck}
              defaultChecked={defaultCkd}/>
          }
          {!props.teamSelected && props.showTeamForm &&
              <Checkbox
              iconStyle={{fill: '#2196F3'}}
              style={{textAlign:'left'}}
              value={e._id}
              label={e.role}
              onCheck={props.handleRolesCheck}
              defaultChecked={false}/>
          }
          {!props.teamSelected && !props.showTeamForm &&
             <FormControlLabel
             style={{float:'left'}}
             control={<DisabledCheckbox
                value={e._id}
                disabled
             /> }
             label={e.role}
            />
          }
          </div>
          </Grid>)
        })}
        </Grid>
      </div>
    );
};

export default ContactOptions;