import moment from "moment";

export const JOINED_CHANNEL = 'joined';
export const LOGOUT_USER_CHANNEL ='logoutUser';
export const SETTINGS_CHANNEL= 'Settings-channel-susbcribe';
export const CICO_CHANNEL = 'CICO';
export const GEOLOCATION_CHANNEL='geolocation-channel-susbcribe';
export const UPDATE_CICO_CHANNEL='update cico';
export const EXPIRE_PHONE_ACTION='Expire Phone';
export const EXPIRE_PHONE_ACTION_ERROR='Expire Phone Error';
export const EMPTY_USER_ID = '12345';
export const EMPTY_USER_LBL = 'None';
export const LOCATION_SETTINGS_TYPE = 'location-settings';
export const SECONDS='seconds';
export const ADD_BUTTON=".add-button";
export const INVALID_DATE="Invalid Date";
export const ONLINE='online';
export const LOGGED_OUT="logged out";
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const LOGGEDIN_LBL="logged in: ";
export const AROUND_LBL=" around ";
export const UUID_FM='UUID-FM';
export const TEXTFIELD_DATE_FORMAT="MM/dd/yyyy hh:mm";
export const TEXTFIELD_TYPE_DATETIME="datetime-local";
export const TRUE='true';
export const CELLULAR='cellular';
export const WIFI='wifi';
export const WIFI_LBL = 'Wifi';
export const CELLULAR_LBL='Cellular';
export const CSV_FILE_NAME='FleetManager.csv';
export const CSV_SEPARATOR=';'
export const AUTHORIZED_ALWAYS='authorizedAlways';
export const ALWAYS='Always';
export const ROW_COLOR="#fbe9e7";
export const RESET_BTN_LBL='Reset';
export const RESET='reset';
export const EDIT_PHONE="EDIT PHONE";
export const UPDATE_PHONE_CHECK_WARN=`[CHECKIN-CHECKOUT] Checklist id couldnt be empty/undefined/null`;
export const MAP="map";
export const TRANSPORTER_PHONE="transporterPhone";
export const LAST_LOCATION='Last Location';
export const FIND='Find';
export const TIMESTAMP='timestamp';
export const FILTER_TYPE= "dropdown";
export const RESPONSIVE="standard";
export const SELECTABLE_ROWS="none";
export const DEFAULT_SORT='asc';
export const YES='Yes';
export const NO='No';
export const CUSTOM_FILTER_TYPE='custom';
export const TEXTFIELD_FILTER_TYPE='textField';
export const TABLE_ID='FM';
export const TABLE_TITLE='Fleet Manager';
export const ALERT_VARIANT_FILLED='filled';
export const ALERT_SUCCESS_TYPE='success';
export const ADD_BUTTON_TITLE="Add phone";
export const NEW_PHONE='NEW PHONE';
export const SPINNER_CONFIG='default';
export const LOCATION_NOT_AVAILABLE='Location not available';
export const SETTINGS={
  batteryState:{
    key:'batteryState',
    type: 'batteryState'
  },
  battery:{
    key:'batteryPorcentage',
    type:'batteryPorcentage',
  },
  locationServices:{
    key:'locationAccess',
    type:'location-settings',
  },
  silentMode:{
    key:'isPhoneInSilentMode',
    type:'silentMode',
  },
  notificationsStatus: {
    key:'notificationsStatus',
    type:'notificationsStatus',
  },
  versionAndBuild: {
    key:'versionAndBuild',
    type: 'versionAndBuild'
  }
};
export const CHAT={
  Online:{
    label:'Online',
    key:'online',
    color:"#3FA33E",
    value: 1
  },
  Inactive:{
    label:'Inactive',
    key:'inactive',
    color:'orange',
    value: 2
  },
  Offline:{
    label:'Offline',
    key:'offline',
    color:'red',
    value: 3
  }
};
export const STATUS_MAP = new Map([[CHAT.Online.label, 0], [CHAT.Inactive.label, 1], [CHAT.Offline.label, 2]]);
export const BUTTON_TYPE={
  default:'default',
  defaultFlat:'default-flat'
};
export const BUTTON_SIZE={
  small: 'small',
  medium: 'medium'
};
export const CHECK_BUTTONS_CONFIG = {
  checkInField: "check-in",
  checkoutField: "check-out",
  disabledBtnColor: "grey",
  checkInBtnColor: "rgba(255, 0, 0, 0.2)",
  checkOutBtnColor: "rgba(116, 191, 91, 0.2)"
};
export const BUTTON_COLORS={
  defaultGray: "#757575"
};
export const STRING_FILED_TYPE={
  device: 'device',
  model: 'model',
  team: 'team',
  teams: 'teams',
  network: 'network',
  label: 'label',
  assigned: 'assigned',
  used: 'used',
  status: 'status',
  appVersion: 'appVersion'
};
export const STRING_FILED_LBL={
  device: 'Identifier',
  model: 'Model',
  teams: 'Team',
  team: "User's Team",
  network: 'Network',
  label: 'Label',
  assigned: 'Assigned To',
  used: 'Used By',
  status: 'Chat Status',
  appVersion: 'App Version'
};
export const COLUMNS={
  number:{
    no:0,
    id:"number",
    label:"Number"
  },
  findPhone: {
    no:1,
    id:"findPhone",
    label:"Find Phone"
  },
  identifier: {
    no:2,
    id:"identifier",
    label:"Identifier"
  },
  active: {
    no:3,
    id:"active",
    label:"Active",
    filter:[YES, NO]
  },
  location:{
    no:4,
    id:"location",
    label:"Location Streaming",
    filter:[YES, NO]
  },
  locationServices:{
    no:5,
    id:"locationServices",
    label:"Location Permissions",
    filter:["notDetermined", "restricted", "denied","authorizedAlways","authorizedWhenInUse"]
  },
  socketIO:{
    no:6,
    id:"socketIO",
    label:"SocketIO",
    filter:[YES, NO]
  },
  background:{
    no:7,
    id:"background",
    label:"Background",
    filter:[YES, NO]
  },
  connected:{
    no:8,
    id:"connected",
    label:"Connected By",
    filter:["Wifi", "Cellular","None"]
  },
  model:{
    no:9,
    id:'model',
    label:'Model'
  },
  // teams:{
  //   no:10,
  //   id:'teams',
  //   label:'Teams'
  // },
  team:{
    no:10,
    id:'team',
    label:"User's Team"
  },
  // assign:{
  //   no:11,
  //   id:"assign",
  //   label:"Assign To"
  // },
  usedBy:{
    no:11,
    id:"usedBy",
    label:"Used By"
  },
  status:{
    no:12,
    id:"status",
    label:"Chat Status",
    filter:[CHAT.Online.label, CHAT.Inactive.label, CHAT.Offline.label]
  },
  out:{
    no:13,
    id:'out',
    label:'Out'
  },
  in:{
    no:14,
    id:'in',
    label:'In'
  },
  lastUsed:{
    no:15,
    id:"lastUsed",
    label:"Last Used"
  },
  battery:{
    no:16,
    id:'battery',
    label:"Battery %"
  },
  batteryState:{
    no:17,
    id:"batteryState",
    label:"Battery State",
    filter:["unknown", "unplugged","charging","full"]
  },
  networkAvailable:{
    no:18,
    id: "networkAvailable",
    label:"Network Available",
    filter: [YES, NO]
  },
  validNetwork:{
    no:19,
    id:"validNetwork",
    label:"Valid Network",
    filter: [YES, NO]
  },
  network:{
    no:20,
    id:'network',
    label:'Network'
  },
  label:{
    no:21,
    id:'label',
    label:'Label'
  },
  phoneNumber:{
    no:22,
    id:'phoneNumber',
    label:"Phone Number"
  },
  silentMode:{
    no:23,
    id:'silentMode',
    label:'Silent Mode',
    filter:[YES, NO]
  },
  notificationStatus:{
    no:24,
    id:'notificationStatus',
    label:'Notification Status',
    filter:["authorized", "denied", "notDetermined"]
  },
  appVersion:{
    no:25,
    id:'appVersion',
    label: 'App Version'
  }
};
export const COLUMN_KEYS={
  0: COLUMNS.number.id,
  1: COLUMNS.findPhone.id,
  2: COLUMNS.identifier.id,
  3: COLUMNS.active.id,
  4: COLUMNS.location.id,
  5: COLUMNS.locationServices.id,
  6: COLUMNS.socketIO.id,
  7: COLUMNS.background.id,
  8: COLUMNS.connected.id,
  9: COLUMNS.model.id,
  // 10: COLUMNS.teams.id,
  10: COLUMNS.team.id,
  //11: COLUMNS.assign.id,
  11: COLUMNS.usedBy.id,
  12: COLUMNS.status.id,
  13: COLUMNS.out.id,
  14: COLUMNS.in.id,
  15: COLUMNS.lastUsed.id,
  16: COLUMNS.battery.id,
  17: COLUMNS.batteryState.id,
  18: COLUMNS.networkAvailable.id,
  19: COLUMNS.validNetwork.id,
  20: COLUMNS.network.id,
  21: COLUMNS.label.id,
  22: COLUMNS.phoneNumber.id,
  23: COLUMNS.silentMode.id,
  24: COLUMNS.notificationStatus.id,
  25: COLUMNS.appVersion.id
};
export const LOCSTREAMING={
  isAppInBackground:"isAppInBackground",
  conectedBy:"conectedBy",
  isConnectedToValidNetwork:"isConnectedToValidNetwork",
  isSocketIOEnabled:"isSocketIOEnabled",
  isNetworkAvailable:"isNetworkAvailable",
  phoneNumber:"phoneNumber",
  locationPermissions:"locationPermissions",
  SSID:"SSID",
  silenced:'silenced',
  notificationStatus:'notificationStatus',
  batteryState:'batteryState',
  versionAndBuild:'versionAndBuild'
};
export const TABLE_EVENTS={
  changeRowsPerPage:'changeRowsPerPage',
  viewColumnsChange:'viewColumnsChange',
  propsUpdate:'propsUpdate',
  search:'search',
  filterChange:'filterChange',
  onSearchClose:'onSearchClose',
  resetFilters:'resetFilters',
  changePage:'changePage',
  onFilterDialogOpen: 'onFilterDialogOpen',
  onFilterDialogClose: 'onFilterDialogClose'
};
export const ROWS_PER_PAGE=[10, 30, 50, 100];
export const ADD_PHONE = {
  DEACTIVATE_PHONE: {
    SELECT_DEFAULT_VALUE: "Select an option"
  }
};
//let usersState = null;
export const PHONES_MAPPER = (phone, roles, index, acl, phone_models) => {
  if (!phone || !roles) {
    return false;
  }
  // if (!usersState) {
  //   usersState = users;
  // }
  const { checkInField, checkoutField } = CHECK_BUTTONS_CONFIG;
  const usedBy = phone.phone_user; //&& user.online==true//&& user.online==true
  //const userAssign = users.find(user => user._id === phone.assign) || {};
  let team = null;
  if (usedBy !== null) {
    team = roles.find(role => role._id === usedBy.role) || null;
  }
  let inList = false;
  const userTeam = team !== null? team.role : '-';
  if (team === null) {
    inList = true;
  } else {
    inList = phone.teams.includes(team._id);
  }  
  let teamList = [];
  phone.teams.forEach(team => {
    let currentRole = roles.find(role=> role._id === team) || null;
    if (!!currentRole) {
      teamList.push(currentRole.role);
    }
  });
  const timestamp = getTimeStamp(phone, usedBy);
  const status = getUserStatus(usedBy, phone);
  const checklistValues = { ...getCheckList(phone, usedBy._id), status, index, userID: usedBy._id, remoteLogin: phone.remoteLogin };
  const activePhone = phone.active ? YES : NO;

  let locationPermissions = "-";
  let socketIO = "-";
  let background = "-";
  let connected = "-";
  let networkAvailable = "-";
  let validNetwork = "-";
  let phoneNumber = '-';
  let batteryState = '-';
  let silenced = '-';
  let notificationStatus = '-';
  let appVersion = ['-', 0];
  let networkSSID = '-';
  let checklist = {};
  if (phone.hasOwnProperty(LOCSTREAMING.phoneNumber)) {
    phoneNumber = phone.phoneNumber!== ''? phone.phoneNumber : '-';
  }
  if (phone.hasOwnProperty(LOCSTREAMING.locationPermissions)) {
    locationPermissions = phone.locationPermissions;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.isSocketIOEnabled)) {
    socketIO = phone.isSocketIOEnabled !== true ? NO : YES;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.isAppInBackground)) {
    background = phone.isAppInBackground !== true ? NO : YES;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.SSID)) {
    networkSSID = phone.SSID!==''? phone.SSID: '-';
  }
  if (phone.hasOwnProperty(LOCSTREAMING.conectedBy)){
    connected = WIFI_LBL;
    if (connected === CELLULAR) {
      networkSSID= CELLULAR_LBL;
    }
  }
  if (phone.hasOwnProperty(LOCSTREAMING.isNetworkAvailable)) {
    networkAvailable = phone.isNetworkAvailable !== true ? NO : YES;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.isConnectedToValidNetwork)) {
    validNetwork = phone.isConnectedToValidNetwork !== true ? NO : YES;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.batteryState)) {
    batteryState = phone.batteryState;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.silenced)) {
    silenced = phone.silenced !== true? NO : YES;
  }
  if (phone.hasOwnProperty(LOCSTREAMING.notificationStatus)) {
    notificationStatus = phone.notificationStatus;
  }

  let versionAndBuild = "";
  let phoneBattery = Math.abs(phone.battery.toFixed());
  if (!!phone.versionAndBuild) {
    [versionAndBuild, phoneBattery, batteryState, networkSSID, networkAvailable, silenced, connected, locationPermissions, notificationStatus ] = getTelemetryDataFromDB(phone.versionAndBuild);
  }
  if (phone.hasOwnProperty(LOCSTREAMING.versionAndBuild)) {
    const version = getNumberFromAPPVersion(versionAndBuild);
    let regexVersion = /^([0-9]{6,})$/;
    if (!!version && regexVersion.test(version)) {
      appVersion = [versionAndBuild, version];
    } else {
      appVersion = [versionAndBuild, 0];
    }
  }
  if (phone.hasOwnProperty('CICO')) {
    checklist = phone.CICO;
  }
  let phoneModel = '';
  let modelInfo = phone_models.filter(model =>model.id === phone.model) || [];
  if (modelInfo.length > 0) {
    phoneModel = modelInfo[0].model;
  }

  return {
    ...phone,
    number: 0,
    findPhone: {userId: usedBy._id, status: status, deviceId: phone.UUID.trim()},
    identifier: {UUID: phone.UUID, included: inList, status: status},
    // teams: teamList,
    team: {role: userTeam, included: inList},
    model: phoneModel,
    usedBy: usedBy.first_name ? `${usedBy.first_name} ${usedBy.last_name}` : usedBy._id || "-",
    //assign: userAssign.first_name ? `${userAssign.first_name} ${userAssign.last_name}` : phone.assign || "-",
    //assign: userAssign.first_name ? {user: {userId: phone.assign, online: userAssign.online},userName:`${userAssign.first_name} ${userAssign.last_name}`, phoneId: phone.UUID, phone: phone, firebasetoken: phone.tokenFirebase} : {user: null,usedBy, phoneId: phone.UUID, firebasetoken: phone.tokenFirebase, phone: phone,userName:``},
    label: phone.label !== undefined ? phone.label : "-",
    active: activePhone,
    location: NO,
    locationServices: locationPermissions,
    socketIO: socketIO,
    background: background,
    connected: connected,
    networkAvailable: networkAvailable,
    validNetwork: validNetwork,
    network: networkSSID,
    lastUsed: timestamp ? { timestamp, last_logout: phone.last_logout } : '',
    battery: !!phoneBattery ? phoneBattery : null,
    batteryState: batteryState,
    phoneNumber: phoneNumber,
    silentMode: silenced,
    notificationStatus: notificationStatus,
    status: {
      value: status,
      index,
      checkIn: checklist[checkInField],
      checkOut: checklist[checkoutField],
      checkList: checklist

    },
    in: { ...checklistValues, checkIn: checklist[checkInField], checkOut: checklist[checkoutField], reset: checklist.reset, status: status},
    out: { ...checklistValues, checkIn: checklist[checkInField], checkOut: checklist[checkoutField], reset: checklist.reset, status: status},
    // terminateSession: timestamp ? { timestamp, usedBy: phone.usedBy } : null,
    appVersion: appVersion
  };
};

const getTelemetryDataFromDB = (telemetry) =>{
  let phoneData = telemetry.split("|");
  const versionAndBuild = `v${phoneData[0]} b${phoneData[1]}`;
  const batteryPorcentage = (phoneData[3] * 100).toFixed(0);
  const batteryState = phoneData[4];
  const networkSSID = phoneData[5];
  const networkStatus = phoneData[6];
  const isPhoneInSilentMode = phoneData[7];
  const appNetworkConnectionBy = phoneData[8];
  const locationAccess = phoneData[12];
  const notificationsStatus = phoneData[13];

  return [versionAndBuild, batteryPorcentage, batteryState, networkSSID, networkStatus, isPhoneInSilentMode, appNetworkConnectionBy, locationAccess, notificationsStatus];
}

const getNumberFromAPPVersion = (v) =>{
  try {
    let dot = v.indexOf(".");
    let build = v.indexOf("b"); 
    let part1 = v.slice(1, dot);
    let part2 = v.slice(dot+1,build-1);
    let part3 = v.slice(build+1,v.length);
    if (part1.length === 1) part1 = "0" + part1;
    if (part2.length === 1) part2 = "0" + part2;
    if (part3.length === 1) part3 = "0" + part3;
    let version = part1+part2+part3;
  
    return version
  }catch(error){
    console.error("[PHONESCONTROL] An error ocurred in getNumberFromAPPVersion: ", error);
    return null;
  }
}

export const workerStatus = (status, online) => {
  if (!status) {
    return CHAT.Offline.label;
  }
  if (status === LOGGED_OUT || online === false){
    return CHAT.Offline.label;
  }
  if (status === CHAT.Inactive.key && online === true){
    return CHAT.Inactive.label;
  }
  return CHAT.Online.label;
};

const getUserStatus = (usedBy, phone) => {
  if (usedBy.device_id === phone.UUID) {
    return workerStatus(usedBy.status, usedBy.online);
  }
  return workerStatus(null, usedBy.online);
};

const getTimeStamp = (phone, usedBy) => {
  let timestamp;
  const lastLocationDate =  phone.last_logout;
  if (usedBy && usedBy.device_id !== undefined) {
    if (usedBy.status === CHAT.Inactive.key) {
      timestamp = { status: CHAT.Inactive.key };
      timestamp.lastLocationDate = lastLocationDate;
    }
    if (usedBy.status === CHAT.Online.key && usedBy.online === true) {
      timestamp = { status: CHAT.Online.key };
    } else{
      timestamp = lastLocationDate;
    }
  }
  return timestamp;
};

const getCheckList = (phone, userID) => {
  return {
    UUID: phone.UUID,
    worker_id: userID,
  };
};

export const CUSTOM_CSVDOWNLOAD = (buildHead, buildBody, columns, data) => {
  let csvBody = [];
  let finalColumns = [];
  let statusIndex = -1;
  let checkInIndex = -1;
  let checkOutIndex =-1;
  let lastUsedIndex=-1;
  //let assignIndex=-1;
  let findPhoneIndex=-1;
  let numIndex=-1;
  let identifierIndex=-1;
  let versionIndex=-1;
  let teamIndex=-1;
  columns.forEach((column, i)=>{
    if(column.name===COLUMNS.number.id){
      numIndex=i;
    }
    if(column.name===COLUMNS.identifier.id){
      identifierIndex=i;
    }
    if(column.name===COLUMNS.team.id){
      teamIndex=i;
    }
    if(column.name===COLUMNS.findPhone.id){
      findPhoneIndex=i;
    }
    if(column.name===COLUMNS.status.id){
      statusIndex=i;
    }
    if(column.name===COLUMNS.out.id){
      checkInIndex=i;
    }
    if(column.name===COLUMNS.in.id){
      checkOutIndex=i;
    }
    if(column.name===COLUMNS.lastUsed.id){
      lastUsedIndex=i;
    }
    // if(column.name===COLUMNS.assign.id){
    //   assignIndex=i;
    // }
    if(column.name===COLUMNS.appVersion.id){
      versionIndex=i;
    }
    if(column.name!==COLUMNS.findPhone.id && column.name!==COLUMNS.number.id){
      finalColumns.push(column);
    }
  });
  data.forEach(p => {
    let csvData = [];
    for (let i = 0; i <=finalColumns.length+1; i++) {
      if (i === statusIndex) {
        csvData.push(p.data[i].value)
      }else if (i === checkOutIndex){
        csvData.push(p.data[i].checkOut)
      }else if (i === checkInIndex){
        csvData.push(p.data[i].checkIn)
      }else if (i === identifierIndex){
        csvData.push(p.data[i].UUID)
      }else if (i === teamIndex){
        csvData.push(p.data[i].role)
      }else if (i === lastUsedIndex){
        csvData.push(p.data[i].last_logout)
      }else if (i === versionIndex){
        csvData.push(p.data[i][0])
      // }else if(i === assignIndex){
      //   csvData.push(p.data[i].userName)
      }else if(i !== findPhoneIndex && i !== numIndex){
        csvData.push(p.data[i])
      }
    }
    csvBody.push({ data: csvData });
  });
  return `${buildHead(finalColumns)}${buildBody(csvBody)}`.trim();
};

let sortOrderDirection = 0;
export function TABLE_SORT(data, columnIndex, sortOrder) {
  sortOrderDirection = (sortOrder ? 1 : -1);
  switch (columnIndex) {
    case COLUMNS.lastUsed.no:
      return data.sort(dateSort(columnIndex, TIMESTAMP));
    case COLUMNS.status.no:
      return data.sort(statusSort(columnIndex));
    case COLUMNS.team.no:
      return data.sort(teamSort(columnIndex));
    case COLUMNS.identifier.no:
      return data.sort(identifierSort(columnIndex));
    case COLUMNS.label.no:
      return data.sort(labelSort(columnIndex));
    case COLUMNS.battery.no:
      return data.sort(stringSort(columnIndex));
    case COLUMNS.batteryState.no:
      return data.sort(stringSort(columnIndex));
    // case COLUMNS.assign.no:
    //   return data.sort(assingSort(columnIndex));
    case COLUMNS.appVersion.no:
      return data.sort(versionSort(columnIndex));
    default:
      return data.sort(genericSort(columnIndex));
  }
};

// const assingSort = (columnIndex) =>{
//   return (a, b) => {
//    return (a.data[columnIndex].userName < b.data[columnIndex].userName ? -1 : 1) * sortOrderDirection;
//   }
// }

const versionSort = (columnIndex)=>{
  return (a, b) => {
    if (a.data[columnIndex][1] === 0) {
      return 1;
    }
    if (b.data[columnIndex][1] === 0) {
      return -1;
    }
    return (a.data[columnIndex][1] < b.data[columnIndex][1] ? -1 : 1) * sortOrderDirection;
  };
}

const labelSort = (columnIndex, fieldIndex) => {
  return (a, b) => {
    if (a.data[columnIndex] === "-" || a.data[columnIndex] === '') {
      return 1;
    }
    if (b.data[columnIndex] === "-" || b.data[columnIndex] === '') {
      return -1;
    }
    return (a.data[columnIndex].toLowerCase() < b.data[columnIndex].toLowerCase() ? -1 : 1) * sortOrderDirection;
  };
}

const stringSort = (columnIndex, fieldIndex) => {
  return (a, b) => {
    return (a.data[columnIndex] < b.data[columnIndex] ? -1 : 1) * sortOrderDirection;
  };
}

const teamSort = (columnIndex, fieldIndex) => {
  return (a, b) => {
    return (a.data[columnIndex].role < b.data[columnIndex].role ? -1 : 1) * sortOrderDirection;
  };
}

const identifierSort = (columnIndex, fieldIndex) => {
  return (a, b) => {
    return (a.data[columnIndex].UUID < b.data[columnIndex].UUID ? -1 : 1) * sortOrderDirection;
  };
}

const genericSort = (columnIndex, fieldIndex) => {
  if (fieldIndex !== undefined)
    return (a, b) => {
      if (a.data[columnIndex] === "-") {
        return 1;
      }
      if (b.data[columnIndex] === "-") {
        return -1;
      }
      return (a.data[columnIndex][fieldIndex].toLowerCase() < b.data[columnIndex][fieldIndex].toLowerCase() ? -1 : 1) * sortOrderDirection;
    };
  return (a, b) => {
    if (a.data[columnIndex] === "-") {
      return 1;
    }
    if (b.data[columnIndex] === "-") {
      return -1;
    }
    if (a.data[columnIndex].length > 24 && (!a.data[columnIndex].includes(" ")))
      return 1;
    if (b.data[columnIndex].length > 24 && (!b.data[columnIndex].includes(" ")))
      return -1;
    return (a.data[columnIndex].toLowerCase() > b.data[columnIndex].toLowerCase() ? 1 : -1) * sortOrderDirection;
  };
};

const dateSort = (columnIndex, fieldIndex) => {
  return (a, b) => {
    const aa = new Date(a.data[columnIndex][fieldIndex]);
    const bb = new Date(b.data[columnIndex][fieldIndex]);
    if (!a.data[columnIndex][fieldIndex]) {
      return 1;
    }
    if (!b.data[columnIndex][fieldIndex]) {
      return -1;
    }
    return (aa.valueOf() - bb.valueOf()) * sortOrderDirection;
  };
}

const statusSort = (columnIndex) => {
  let aa, bb;
  return (rowA, rowB) => {
    aa = STATUS_MAP.get(rowA.data[columnIndex].value);
    bb = STATUS_MAP.get(rowB.data[columnIndex].value);
    return (aa - bb) * sortOrderDirection;
  };
}

export const table_filter_refresh = (filterBy, data)=>{
  let filtered = [];
  let finalFilter = [];
  let dataList = {};

  filterBy.forEach((d, i)=>{
    if (d.length !== 0 && i > 1) {
      switch (i) {
        case COLUMNS.status.no:
          dataList[i] = data.filter(row=> row.status.value === d[0]);
          break
        case COLUMNS.lastUsed.no:
          dataList[i] = data.filter(row=> moment(row.out.checkOut).format(DATE_FORMAT) === moment(d[0]).format(DATE_FORMAT));
          break
        case COLUMNS.battery.no:
          dataList[i] = data.filter(row=> moment(row.in.checkIn).format(DATE_FORMAT) === moment(d[0]).format(DATE_FORMAT));
          break
        case COLUMNS.label.no:
          dataList[i] = data.filter(row=> moment(row.lastUsed.timestamp).format(DATE_FORMAT) === moment(d[0]).format(DATE_FORMAT));
          break
        default:
          dataList[i] = data.filter(row=> row[COLUMN_KEYS[i]] === d[0]);
          break
      }
    }
  });
  for (let i = 0; i < 25; i++) {
    if (dataList[i] !== undefined)
      filtered = filtered.concat(dataList[i]);
  }
  if (filtered.length > 1) {
    let duplicates = filtered.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
    }, []);
    finalFilter = duplicates.length === 0? filtered : duplicates;
  } else {
    finalFilter = filtered;
  }

  return finalFilter;
};

export  const  USER_TEAM_FILTER = (label, filters) => {
  if (filters) {
    if(label.role!==filters)
      return true;
  } else
    return false;
};

export  const  TEAM_FILTER = (label, filters) => {
  if (filters) {
    if(!label.includes(filters))
      return true;
  } else
    return false;
};

export  const  IDENTIFIER_FILTER = (label, filters) => {
  if (filters) {
    if(label.UUID!==filters)
      return true;
  } else
    return false;
};