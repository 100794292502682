import { createMuiTheme } from "@material-ui/core/styles";

export const ADD='ADD';
export const EDIT='EDIT';
export const CANCEL='CANCEL';
export const ELEMENTS=["default", "hidden"];
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const SUCCESS='success';
export const WARNING='warning';
export const DEFAULT='default';
export const DELETE_ACTION='Delete Phone Model';
export const DELETE_ACTION_ERROR='Delete Phone Model Error';
export const DUP_MODEL_MSG='Model already exists!';
export const EMPTY_MODEL_MSG='Model cannot be empty!';
export const CREATE_ACTION='Create Phone Model';
export const CREATE_ACTION_ERROR='Create Phone Model Error';
export const UPDATE_ACTION='Update Phone Model';
export const UPDATE_ACTION_ERROR='Update Phone Model Error';
export const PHONE_MODELS='PHONE MODELS';
export const MODEL='Model';
export const BRAND='Brand';
export const OWNER="Owner";
export const FILLED='filled';
export const BRAND_ERROR_MSG="Brand cannot be empty!";
export const MODAL_TITLE="Are you sure?";
export const MODAL_CANCEL='Cancel';
export const MODAL_DELETE='Delete';
export const BTN_SIZE={
    medium: 'medium'
};
export const DATE_INPUT={
    type:"datetime-local",
    format:"MM/dd/yyyy hh:mm"
};
export const TABLE_EVENTS={
  changeRowsPerPage: 'changeRowsPerPage'
};
export const TABLE_OPTIONS={
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 30],
    selectableRows: 'single',
    defaultSort: 'asc',
    filterTable: 'Filter Table'
}
export const COLUMNS={
    model:{
        no:0,
        id:'model',
        label:'Model'
    },
    brand:{
        no:1,
        id:'brand',
        label:'Brand'
    },
    owner:{
        no:2,
        id:'owner',
        label:'Owner'
    },
    phones:{
        no:3,
        id:'phones',
        label:'Phones'
    },
    dateCreated:{
        no:4,
        id:'dateCreated',
        label:'Date Created'
    },
    lastModification:{
        no:5,
        id:'lastModification',
        label:'Last Modification'
    }
};

export const TABLE_SORT = (data, colIndex, order) => {
    if (colIndex === 0 || colIndex === 2) {
      return data.sort((a, b) =>{
        return a.data[colIndex].localeCompare(b.data[colIndex]) * (order? 1 : -1);
      });
    } else if (colIndex === 1 || colIndex === 3) {
        return data.sort((a, b) => {
          return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order? 1 : -1);
        });
    } else {
      return data.sort((a, b) => {
        return (a.data[colIndex][1] < b.data[colIndex][1]? -1 : 1) * (order? 1 : -1);
      });
    }
};

export const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "635px !important",
          },
          paper: {
            boxShadow: "0 4px 2px -2px gray",
            paddingTop: "1px",
          },
        },
        MuiButton:{
          text:{
            padding: '6px 16px',
            display: 'flex',
            marginLeft: '16px !important'
          }
        },
        MUIDataTableHeadCell: {
          toolButton: {
            color: "#7D7D7D",
            float: 'none !important'
          },
          contentWrapper:{
            justifyContent: "center",
            display: "flex",
          }
        },
        MUIDataTableBodyCell: {
          root: {
            textAlign: "center",
            padding: "0 !important"
          },
        },
      },
});