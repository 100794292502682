import { jobStatusTypesGraphQL } from "../../jobsBoard.constants";
import _ from 'lodash';

export const handleUnassignUserToJob = async (items, currentSlot) => {
    const jobsCopy = [...items];
    // Unassign user from job (if applies)
    items.forEach((job, i)=>{
      if (job.resource_slotid === currentSlot.resource_slotid && (job.status === jobStatusTypesGraphQL.unassigned || job.status === jobStatusTypesGraphQL.assigned)) {
        jobsCopy[i].job_assigned_user_name = "";
        jobsCopy[i].job_assigned_userid = "";
      }
    });
  
    return jobsCopy;
}