import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TimeLineIcon from "@material-ui/icons/Timeline";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    width:'100vh',
    display:'flex !important'
  },
  iconContainerFull: {
    width:'120vh',
    display:'flex !important'
  },
};

class JobHistoryToolbar extends React.Component {
  state={}

  handleShowTraces = () => {
    const {data, selectedRows} = this.props;
    
    const currentJob = data[selectedRows.data[0].dataIndex];
    this.props.handleOpenJobOnMap([currentJob]);
  };


  render() {
    const { classes, leftNavOpen} = this.props;
    const toolbarHeaderStyle = leftNavOpen? classes.iconContainer: classes.iconContainerFull;
    const iconButtonStyle = leftNavOpen? '88%' : '90%';

    return (
      <div className={toolbarHeaderStyle}>
        <div style={{marginLeft:iconButtonStyle}}>
        <Tooltip title={"Show Job"}>
          <IconButton className={classes.iconButton} onClick={this.handleShowTraces}>
            <TimeLineIcon style={{fill:'#2196F3'}} className={classes.icon} />
          </IconButton>
        </Tooltip>
        </div>
      </div>
    );
  }
}

export default (withStyles(defaultToolbarSelectStyles)(JobHistoryToolbar));