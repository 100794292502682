import React from 'react';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import CachedIcon from '@material-ui/icons/Cached';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBack from '@material-ui/icons/ArrowBack';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ErrorIcon from '../../img/errorIcon.svg';

/**
 * Returns a button depending of it's type.
 * @param {string} id - Button ID
 * @param {string} keyLbl - Button key.
 * @param {string} label - Button label.
 * @param {string} buttonType - Button type to return.
 * @param {boolean} isDisabled - Button disabled status.
 * @param {string} size - Button size.
 * @param {function} handleClick - (optional) Fuction to execute on click.
 * @param {object} class - (optional) Button class name.
 * @param {object} styles - (optional) Button styles (to override default).
 * @param {component} startIcon - (optional) Icon to display on the left of the button.
 * @param {component} endIcon - (optional)Icon to display on the right of the button.
 */
function MasterButton(props) {
  const buttonIcons = {
    'trashCan': <DeleteIcon/>,
    'download': <GetAppIcon/>,
    'refresh': <CachedIcon/>,
    'copy':<FileCopyIcon/>,
    'upload':  <PublishIcon/>,
    'add': <AddIcon/>,
    'edit': <EditIcon/>,
    'save': <SaveIcon/>,
    'more': <MoreVertIcon/>,
    'error': <img alt="error-icon-button" src={ErrorIcon}/>,
    'back': <ArrowBack/>,
    'location': <LocationOnIcon/>
  };

  const renderButton = () =>{
    switch (props.buttonType) {
      case 'default-flat': //Flat gray button
        return (
          <Button
            id={props.id}
            disabled={props.isDisabled}
            style={props.styles}
            className={props.class}
            key={props.keyLbl}
            onClick={()=>props.handleClick()}
            size={props.size}
            endIcon={props.endIcon? buttonIcons[props.endIcon]:null}
            startIcon={props.startIcon? buttonIcons[props.startIcon]:null}>{props.label}</Button>
        );
      case 'success-flat': //Flat primary button (blue)
        return (
          <Button
            id={props.id}
            disabled={props.isDisabled}
            style={props.styles}
            className={props.class}
            key={props.keyLbl}
            onClick={()=>props.handleClick()}
            size={props.size}
            endIcon={props.endIcon? buttonIcons[props.endIcon]:null}
            startIcon={props.startIcon? buttonIcons[props.startIcon]:null}
            color="primary">{props.label}</Button>
        );
      case 'error': //Normal secondary color button (red)
        return (
          <Button
            id={props.id}
            disabled={props.isDisabled}
            key={props.keyLbl}
            style={props.styles}
            className={props.class}
            onClick={()=>props.handleClick()}
            size={props.size}
            endIcon={props.endIcon? buttonIcons[props.endIcon]:null}
            startIcon={props.startIcon? buttonIcons[props.startIcon]:null}
            variant="contained"
            color="secondary">{props.label}</Button>
        );
      case 'default': //Normal button (gray)
        return (
          <Button
            id={props.id}
            disabled={props.isDisabled}
            key={props.keyLbl}
            style={props.styles}
            className={props.class}
            onClick={()=>props.handleClick()}
            size={props.size}
            endIcon={props.endIcon? buttonIcons[props.endIcon]:null}
            startIcon={props.startIcon? buttonIcons[props.startIcon]:null}
            variant="contained"
            color="default">{props.label}</Button>
        );
      case 'success': //Normal primary color button (blue)
        return (
          <Button
            id={props.id}
            disabled={props.isDisabled}
            key={props.keyLbl}
            style={props.styles}
            className={props.class}
            onClick={()=>props.handleClick()}
            size={props.size}
            endIcon={props.endIcon? buttonIcons[props.endIcon]:null}
            startIcon={props.startIcon? buttonIcons[props.startIcon]:null}
            variant="contained"
            color="primary">{props.label}</Button>
        );
    }
  };

  return (
    <React.Fragment>
      {renderButton()}
    </React.Fragment>
  );
};

export default (MasterButton);
