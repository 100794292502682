import React, {useState} from 'react';
import moment from "moment";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/core";
import {
  LOG_DATE_FORMAT,
  NOT_AVAILABLE,
  ROOMID,
  TIMESTAMP,
  LOCATION,
  FLOOR,
  GPS,
  NO_LOGS
} from './locationlog.constants';
import { getFloorColorByOrdinal } from '../../../map/floorColors';

function LocationLog (props){
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const override = css`
    position: absolute;
    left: 40%;
    top: 55%;
    transform: translate(-50%, -50%);
    `;

    const overrideOpen = css`
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    `;

    const handlePageChange = (_, page) => {
        setPage(page);
        props.handlePagination(page, rowsPerPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getFloorInfo = (userFloor) => {
      const { mapConfigs } = props;

      let floorColor = '#757575';
      let actualFloor = GPS;
      let floor = !!mapConfigs.floors ? mapConfigs.floors.find(f=>f.ordinal === userFloor) : undefined;
      if (!!floor) {
        //floorColor = floor.color;
        floorColor = getFloorColorByOrdinal(floor.ordinal);
        actualFloor = floor.label;
      }
      if (actualFloor !== GPS) {
        let aux = actualFloor;
        actualFloor = `L${aux}`;
      }
      return [floorColor, actualFloor];
    };

    const renderLocationTable = () =>{
      const nextPageEnabled = props.equipmentHistoryPagination.length === rowsPerPage && props.equipmentHistoryPaginationTotal > rowsPerPage?false:true;
      return(
        <Paper style={{width: '100%', marginTop:'10px'}}>
        <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{LOCATION}</TableCell>
              <TableCell align="center">{FLOOR}</TableCell>
              <TableCell align="center">{TIMESTAMP}</TableCell>
              <TableCell align="center">{ROOMID}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.equipmentHistoryPagination
                .map((row) => {
                    let [floorColor, floorLabel] = getFloorInfo(row.ordinal);
                  return(
                   <TableRow key={row.id}>
                    <TableCell align="left">{row.roomName? row.roomName : NOT_AVAILABLE}</TableCell>
                    <TableCell align="center" >
                        <div style={{color: floorColor, fontWeight: 'bold'}}>{floorLabel}</div>
                    </TableCell>
                    <TableCell align="center">{moment(row.date).format(LOG_DATE_FORMAT)}</TableCell>
                    <TableCell align="center">{row.roomId? row.roomId : NOT_AVAILABLE}</TableCell>
                  </TableRow>
                    );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={props.equipmentHistoryPagination.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        nextIconButtonProps={{disabled: nextPageEnabled}}
        labelDisplayedRows={() => {
          return `Page ${page+1}`;
        }}
      />
      </Paper> 
      );
    };

    const renderEmptyTable = ()=>{
      return(
        <Paper style={{width: '100%', border:'none', marginTop:'10px'}}>
          <div
          style={{
            textAlign: 'center',
            color: '#757575',
            fontSize: '17px'
          }}
          >
          {NO_LOGS}
          </div>
        </Paper>
      );
    };

    const renderSpinner = ()=>{
      const styleSpinner = props.open? overrideOpen : override;
      return(
      <HashLoader
        css={styleSpinner}
        size={100}
        color={"#3352AD"}
        loading={props.showSpinner}
       />
      );
    }

    return(
    <React.Fragment id="navv-tableView-equipment-locationLog-1.0">
      {props.showSpinner
      ?(renderSpinner())
      :(!!props.equipmentHistoryPagination && !!props.equipmentHistoryPagination[0]
          ?(renderLocationTable())
          :(renderEmptyTable())
      )}
    </React.Fragment>
    );
};

export default LocationLog;