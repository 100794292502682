import React, {useState, useEffect, useRef} from 'react';

import {List} from 'material-ui/List';
import {withStyles} from '@material-ui/core/styles';

import {connect} from 'react-redux';
import { changeAllCallouts,
  changeSmallCallouts,
  changeAllTraces,
  changeActiveFloor,
  changeActiveUser,
  changeTimeWindow,
  changeHideJobs,
  changeCenterHistoryMap,
  changeIndoorOnly,
  centerActiveMap } from '../../actions/views/viewsActions';
import { getPhones } from '../../actions/phones/phonesActions';
import { getModels } from '../../actions/modelPhone/modelPhoneActions';
import ActiveMapFilters from './filters/activeMapFilters.component';
import TrackDeviceFilters from './filters/trackDeviceFilters.component';
import TrackUserFilters from './filters/trackUserFilters.component';
import EquipmentFilters from './filters/equipmentFilters.component';
import TrackUserInfo from './trackInfo/trackUserInfo.component';
import TrackDeviceInfo from './trackInfo/trackDeviceInfo.component';
import {
  USER_TRACK_KEY,
  DEVICE_TRACK_KEY,
  EQUIPMENT_TRACK_KEY,
  LASTLOCATION,
  CENTER,
  NOT_AVAILABLE
} from './filterlist.constants';
import {filterStyles} from './styles';
import _ from 'lodash';

const FilterList = (props) => {
  const [showUserTrackedInfo, setShowUserTrackedInfo] = useState(true);
  const [showPhoneTrackedList, setShowPhoneTrackedList] = useState(false);
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
    //componentDidMount
      props.getModels(props.campusId);
      if (props.viewProps === USER_TRACK_KEY || props.viewProps === DEVICE_TRACK_KEY || props.viewProps === EQUIPMENT_TRACK_KEY) {
        props.changeSmallCallouts(false);
        props.changeAllCallouts(false);
        props.changeAllCallouts(true);
      };
      if (props.viewProps === DEVICE_TRACK_KEY) {
        setShowUserTrackedInfo(false);
        setShowPhoneTrackedList(true);
      }
      props.getPhones();
      mounted.current = true;
    }
  });

  const handleTraces = (event)=>{
    props.changeAllTraces(event.target.checked);
  };

  const handleIndoorOnly = (event)=>{
    props.changeIndoorOnly(event.target.checked);
  };

  const handleCallouts = (event)=>{
    if (!event.target.checked) {
      props.changeSmallCallouts(event.target.checked);
      props.changeAllCallouts(event.target.checked);
    }
    props.changeAllCallouts(event.target.checked);
  };

  const handleSmallCallouts = (event)=>{
    props.changeSmallCallouts(event.target.checked);
  };

  const handleActiveFloor = (event) =>{
    props.changeActiveFloor(event.target.checked);
  };

  const handleActiveUser = (event) =>{
    props.changeActiveUser(event.target.checked);
  };

  const getDeviceTrackedInfo = (deviceTracked)=>{
    let assigName = NOT_AVAILABLE;
    let usedName = NOT_AVAILABLE;

    let userAssigned = props.users.find((user)=>user._id === deviceTracked.assign) || {};
    let usedByPhone = props.users.find((user)=>user._id === deviceTracked.usedBy) || {};
    if (!_.isEmpty(userAssigned))
      assigName = `${userAssigned.first_name} ${userAssigned.last_name}`;
    if (!_.isEmpty(usedByPhone))
      usedName = `${usedByPhone.first_name} ${usedByPhone.last_name}`;

    return [assigName, usedName];
  };

  const handleCenterMap = ()=>{
    props.centerActiveMap(CENTER);
  };

  if (props.viewProps !== USER_TRACK_KEY && props.viewProps !== DEVICE_TRACK_KEY && props.viewProps !== EQUIPMENT_TRACK_KEY){
    props.changeActiveUser(false);
  }

  return (
    <List>
      {props.viewProps === USER_TRACK_KEY&&(
      <React.Fragment>
        <TrackUserFilters
        smallCallouts={props.smallCallouts}
        handleSmallCallouts={handleSmallCallouts}
        allCallouts={props.allCallouts}
        handleCallouts={handleCallouts}
        traces={props.traces}
        handleTraces={handleTraces}
        activeUser={props.activeUser}
        handleActiveUser={handleActiveUser}
        />
        <TrackUserInfo
        transporterFound={props.transporterFound}
        users={props.users}
        phones={props.phones}
        models={props.models}
        getDeviceTrackedInfo={getDeviceTrackedInfo}
        setShowPhoneTrackedList={setShowPhoneTrackedList}
        setShowUserTrackedInfo={setShowUserTrackedInfo}
        showUserTrackedInfo={showUserTrackedInfo}
        showPhoneTrackedList={showPhoneTrackedList}
        />
      </React.Fragment>
      )}
      {props.viewProps === DEVICE_TRACK_KEY&& (
        <React.Fragment>
          <TrackDeviceFilters
          traces={props.traces}
          handleTraces={handleTraces}
          allCallouts={props.allCallouts}
          handleCallouts={handleCallouts}
          activeUser={props.activeUser}
          handleActiveUser={handleActiveUser}
          />
          <TrackDeviceInfo
          transporterFound={props.transporterFound}
          transporterPhone={props.transporterPhone}
          phones={props.phones}
          users={props.users}
          models={props.models}
          getDeviceTrackedInfo={getDeviceTrackedInfo}
          setShowUserTrackedInfo={setShowUserTrackedInfo}
          setShowPhoneTrackedList={setShowPhoneTrackedList}
          showUserTrackedInfo={showUserTrackedInfo}
          showPhoneTrackedList={showPhoneTrackedList}
          />
        </React.Fragment>
      )}
      {/* {props.viewProps === EQUIPMENT_TRACK_KEY&& (
        <React.Fragment>
          <EquipmentFilters/>
        </React.Fragment>
      )} */}
      {props.viewProps !== LASTLOCATION && props.viewProps !== USER_TRACK_KEY && props.viewProps !== DEVICE_TRACK_KEY && props.viewProps !== EQUIPMENT_TRACK_KEY && (
        <ActiveMapFilters
        handleCenterMap={handleCenterMap}
        transporterFound={props.transporterFound}
        smallCallouts={props.smallCallouts}
        handleSmallCallouts={handleSmallCallouts}
        allCallouts={props.allCallouts}
        handleCallouts={handleCallouts}
        indoorOnly={props.indoorOnly}
        handleIndoorOnly={handleIndoorOnly}
        traces={props.traces}
        handleTraces={handleTraces}
        activeFloor={props.activeFloor}
        handleActiveFloor={handleActiveFloor}
      />
      )}
    </List>
  );
};

const mapStateToProps = state => ({
  traces: state.views.traces,
  allCallouts: state.views.allCallouts,
  smallCallouts: state.views.smallCallouts,
  activeFloor: state.views.activeFloor,
  activeUser: state.views.activeUser,
  viewProps: state.views.viewProps,
  historyRoute: state.views.historyRoute,
  timeWindow: state.views.timeWindow,
  users: state.user.users,
  jobsToHide: state.views.jobsToHide,
  wifi: state.views.wifi,
  geoJSONJob: state.views.geoJSONJob,
  phones: state.phones.phones,
  transporterFound: state.views.transporterFound,
  transporterPhone: state.views.transporterPhone,
  jobInfo: state.views.jobInfo,
  historyTracesForCSV: state.views.historyTracesForCSV,
  indoorOnly: state.views.indoorOnly,
  models: state.modelPhones.models,
  campusId: state.auth.userInfo.campusId,
});

export default connect(mapStateToProps, {
  getPhones,
  getModels,
  changeAllCallouts,
  changeSmallCallouts,
  changeAllTraces,
  changeActiveFloor,
  changeActiveUser,
  changeTimeWindow,
  changeHideJobs,
  changeCenterHistoryMap,
  changeIndoorOnly,
  centerActiveMap})(withStyles(filterStyles)(FilterList));