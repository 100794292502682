import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PropTypes from "prop-types";
import moment from "moment";

import CustomToolbarSelect from '../utils/toolbarSelect.component.js'
import { addModelPhone, getModels, putModelPhone, deleteModelPhone } from '../../../actions/modelPhone/modelPhoneActions';
import { createLog } from '../../../actions/logs/logsActions';
import AwesomeSpinner from '../../utilities/spinner';
import MasterAlert from '../../utilities/alert.component';
import ModalPop from '../../utilities/modal.component';
import MasterButton from '../../utilities/button.component';
import { styles } from './modelPhones.styles';
import * as CONSTS from './modelPhones.constants';

class PhoneModels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: '',
      currentModel: '',
      brand: '',
      owner: '',
      buttonName: CONSTS.ADD,
      creationDate: '',
      id: '',
      update: false,
      deleted: false,
      action: false,
      openSnack: false,
      snackMsg: '',
      snackSeverity: '',
      openModal: false,
      showSpinner: false,
      modelError: false,
      modelErrorDesc: '',
      brandError: false,
      tableRowsPerPage: 5,
    };
  }
  startLoop = true;
  devicesPerModel = {};
  elements = CONSTS.ELEMENTS;
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];
  usersName = [];
  usersNameList = [];

  componentDidMount = () => {
    this.props.getModels(this.props.campusInfo.id);
  };
  UNSAFE_componentWillMount = () => {
    this.props.getModels(this.props.campusInfo.id);
  }
  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  }
  componentDidUpdate() {
    if (this.state.action) {
      this.props.getModels(this.props.campusInfo.id);
      this.setState({ action: false });
    }
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type={CONSTS.DATE_INPUT.type}
          format={CONSTS.DATE_INPUT.format}
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  getDevicesPerModel = () => {
    const { phones, models } = this.props;
    models.forEach(m => {
      let devicesCount = 0;
      phones.forEach(p => {
        if (p.model === m.id) devicesCount++
      })
      this.devicesPerModel[m.id] = devicesCount;
    });
  };

  handleOpenSnackbar = (type, ms) => {
    this.setState({ openSnack: true, snackMsg: ms, snackSeverity: type });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnack: false, snackMsg: '', snackSeverity: CONSTS.SUCCESS, model: '', brand: '' });
  };

  handleOpenModal = (data) => {
    this.setState({ openModal: true, model: data.model, id: data.id });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, model: '', brand: '', owner: '' });
  };

  enabledButton = () => {
    if (this.state.model === '' && this.state.brand === '' && this.state.owner === '') {
      return true;
    } else {
      return false;
    }
  };

  handleCancel = () => {
    this.setState({
      model: '',
      brand: '',
      owner: '',
      modelError: false,
      modelErrorDesc: '',
      brandError: false,
      id: '',
      buttonName: CONSTS.ADD
    });
  };

  createModelLog = (data) => {
    let modifiedBy = this.handleModifiedBy();
    let log = {
      campusId: this.props.campusInfo.id,
      userId: modifiedBy,
      action: data.action,
      description: data.description,
      timestamp: new Date()
    };
    this.props.createLog(log);
  };

  validateDeletion = (id) => {
    let error = false;
    if (this.devicesPerModel[id] !== 0)
      error = true;
    return error;
  };

  handlePerformedBy = () => {
    const { user, userId } = this.props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  handleModifiedBy = () => {
    const { user, userId } = this.props;
    let modifiedBy = '';
    if (user && user.id) {
      modifiedBy = user.id;
    } else if (userId) {
      modifiedBy = userId;
    }
    return modifiedBy;
  }

  handleDelete = (e) => {
    const { classes } = this.props;
    if (!this.validateDeletion(this.props.models[e].id)) {
      this.handleOpenModal(this.props.models[e]);
    } else {
      let msg = <div className={classes.inlineContainer}>{`Model couldn't be deleted due devices (${this.devicesPerModel[this.props.models[e].id]}) have this model assigned!`}</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
      this.setState({ model: '', id: '' });
    }
  };

  deleteModel = () => {
    const { classes } = this.props;
    this.setState({ showSpinner: true });
    let model = this.state.model;
    let performedBy = this.handlePerformedBy();
    this.handleCloseModal();
    this.props.deleteModelPhone(this.state.id).then(resp => {
      this.createModelLog({
        action: CONSTS.DELETE_ACTION,
        description: `Model "${model}" deleted by "${performedBy}"`
      });
      this.setState({ deleted: true, showSpinner: false, action: true });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{model}</div>" deleted successfully!</div>
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      this.createModelLog({
        action: CONSTS.DELETE_ACTION_ERROR,
        description: `Model "${model}" couldn't be deleted by "${performedBy}" due endpoint ${err}`
      });
      this.setState({ deleted: true, showSpinner: false });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{model}</div>" couldn't be deleted!</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  }

  editModel = (e) => {
    this.setState({
      model: this.props.models[e].model,
      brand: this.props.models[e].brand,
      owner: this.props.models[e].owner,
      currentModel: this.props.models[e].model,
      id: this.props.models[e].id,
      creationDate: this.props.models[e].creationDate,
      buttonName: CONSTS.EDIT
    });
  }

  validateData = () => {
    let error = false;
    let modelFound = this.props.models.find(m => m.model === this.state.model);
    if (modelFound && this.state.buttonName === CONSTS.ADD) {
      error = true;
      this.setState({ modelError: true, modelErrorDesc: CONSTS.DUP_MODEL_MSG });
    }
    if (modelFound && this.state.buttonName === CONSTS.EDIT && modelFound.model === this.state.currentModel) {
      error = true;
      this.setState({ modelError: true, modelErrorDesc: CONSTS.DUP_MODEL_MSG });
    }
    if (this.state.brand === '') {
      this.setState({ brandError: true });
      error = true;
    }
    if (this.state.model === '') {
      this.setState({ modelError: true, modelErrorDesc: CONSTS.EMPTY_MODEL_MSG });
      error = true;
    }
    return error;
  };

  onClickModifier = () => {
    let formError = this.validateData();
    if (!formError) {
      this.setState({ showSpinner: true, modelError: false, brandError: false });
      if (this.state.buttonName === CONSTS.ADD)
        this.storeData();
      else
        this.updateData();
    }
  };

  storeData = () => {
    let { classes } = this.props;
    let model = this.state.model;
    let performedBy = this.handlePerformedBy();
    let modifiedBy = this.handleModifiedBy();
    this.props.addModelPhone({
      model: this.state.model.trim(),
      brand: this.state.brand.trim(),
      campusId: this.props.campusInfo.id,
      creationDate: new Date(),
      modificationDate: new Date(),
      modifiedBy: modifiedBy
    }).then(resp => {
      this.createModelLog({
        action: CONSTS.CREATE_ACTION,
        description: `Model "${model}" created by "${performedBy}"`
      });
      this.setState({ model: '', brand: '', owner: '', showSpinner: false, action: true });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{model}</div>" created successfully!</div>
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(error => {
      console.error("[PHONEMODELS] An error ocurred in storeData: ", error)
      this.createModelLog({
        action: CONSTS.CREATE_ACTION_ERROR,
        description: `Model "${model}" couldn't be created by "${performedBy}" due endpoint ${error}`
      });
      this.setState({ model: '', brand: '', owner: '', showSpinner: false });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{model}</div>" couldn't be created!</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  };

  updateData = () => {
    let { classes } = this.props;
    let performedBy = this.handlePerformedBy();
    let modifiedBy = this.handleModifiedBy();
    this.props.putModelPhone({
      id: this.state.id,
      model: this.state.model.trim(),
      brand: this.state.brand.trim(),
      campusId: this.props.campusInfo.id,
      creationDate: this.state.creationDate,
      modificationDate: new Date(),
      modifiedBy: modifiedBy
    }).then(resp => {
      this.createModelLog({
        action: CONSTS.UPDATE_ACTION,
        description: `Model "${this.state.model}" updated by "${performedBy}"`
      });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{this.state.model}</div>" updated successfully!</div>
      this.setState({ model: '', brand: '', owner: '', buttonName: CONSTS.ADD, showSpinner: false, action: true });
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      this.createModelLog({
        action: CONSTS.UPDATE_ACTION_ERROR,
        description: `Model "${this.state.model}" couldn't be updated by "${performedBy}" due endpoint ${err}`
      });
      let msg = <div className={classes.inlineContainer}>Model "<div className={classes.boldMsg}>{this.state.model}</div>" couldn't be updated!</div>
      this.setState({ model: '', brand: '', owner: '', buttonName: CONSTS.ADD, showSpinner: false });
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  };

  modelPhonesTitle = () => {
    let { classes } = this.props;
    return (
      <div>
        <div className={classes.titleContainer}>
          <PhoneIphoneIcon className={classes.iconTitle} />
          <h3 className={classes.titleLbl}>{CONSTS.PHONE_MODELS}</h3>
        </div>
        <div className={classes.formContainer}>
          <TextField
            error = {this.state.modelError}
            helperText={this.state.modelError ? this.state.modelErrorDesc:''}
            style={{width: '60%'}}
            id="webNavv-settings-equipmentForm-phoneModelInput-1.0"
            label={CONSTS.MODEL}
            value={this.state.model}
            onChange={e => { this.setState({ model: e.target.value }) }}
            variant={CONSTS.FILLED} />
          <TextField
            error={this.state.brandError}
            helperText={this.state.brandError ? CONSTS.BRAND_ERROR_MSG : ''}
            className={classes.inputModel}
            id="webNavv-settings-equipmentForm-phoneBrandInput-1.0"
            label={CONSTS.BRAND}
            value={this.state.brand}
            onChange={e => { this.setState({ brand: e.target.value }) }}
            variant={CONSTS.FILLED} />
          <TextField
            disabled
            className={classes.inputModel}
            id="webNavv-settings-equipmentForm-phoneOwner-1.0"
            label={CONSTS.OWNER}
            value={this.props.campusInfo.alias}
            variant={CONSTS.FILLED}
          />
        </div>
        <div>
          <MasterButton
            id="webNavv-settings-equipmentForm-addButton-1.0"
            keyLbl={'phoneModel-btn'}
            label={this.state.buttonName}
            buttonType={CONSTS.SUCCESS}
            isDisabled={this.enabledButton()}
            handleClick={() => { this.onClickModifier() }}
            size={CONSTS.BTN_SIZE.medium}
            class={classes.containedBtn} />
          {!this.enabledButton() ?
            <MasterButton
              id="webNavv-settings-equipmentForm-cancelButton-1.0"
              keyLbl={'phoneModel-cancel-btn'}
              label={CONSTS.CANCEL}
              buttonType={CONSTS.DEFAULT}
              isDisabled={false}
              handleClick={() => this.handleCancel()}
              size={CONSTS.BTN_SIZE.medium}
              class={classes.cancelBtn} /> : null}
        </div>
      </div>
    );
  }

  render() {
    let data = [];
    this.getDevicesPerModel();
    this.props.models.forEach((model) => {
      let dateCretedFormated = moment(model.creationDate).format(CONSTS.DATE_FORMAT);
      let lastModificationFormated = moment(model.modificationDate).format(CONSTS.DATE_FORMAT)
      data.push({
        model: model.model,
        brand: model.brand,
        owner: this.props.campusInfo.alias,
        phones: this.devicesPerModel[model.id],
        dateCreated: [dateCretedFormated, model.creationDate],
        lastModification: [lastModificationFormated, model.modificationDate]
      });
      this.showTable = true;
    });

    const columns = [
      {
        name: CONSTS.COLUMNS.model.id,
        label: CONSTS.COLUMNS.model.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: value => {
            return (
              <div className={this.props.classes.phoneModel}>{value}</div>
            );
          }
        }
      },
      {
        name: CONSTS.COLUMNS.brand.id,
        label: CONSTS.COLUMNS.brand.label,
        options: {
          sortThirdClickReset: true,
          filter: false
        }
      },
      {
        name: CONSTS.COLUMNS.owner.id,
        label: CONSTS.COLUMNS.owner.label,
        options: {
          sortThirdClickReset: true,
          filter: false
        }
      },
      {
        name: CONSTS.COLUMNS.phones.id,
        label: CONSTS.COLUMNS.phones.label,
        options: {
          sortThirdClickReset: true,
          filter: false
        }
      },
      {
        name: CONSTS.COLUMNS.dateCreated.id,
        label: CONSTS.COLUMNS.dateCreated.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => {
            return <p>{value[0]}</p>
          }
        }
      },
      {
        name: CONSTS.COLUMNS.lastModification.id,
        label: CONSTS.COLUMNS.lastModification.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => {
            return <p>{value[0]}</p>
          }
        }
      }
    ];

    const options = {
      filterType: CONSTS.TABLE_OPTIONS.filterType,
      responsive: CONSTS.TABLE_OPTIONS.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.TABLE_OPTIONS.rowsPerPageOptions,
      selectableRowsHeader: false,
      selectableRows: CONSTS.TABLE_OPTIONS.selectableRows,
      selectableRowsOnClick: true,
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        let sortType = order === CONSTS.TABLE_OPTIONS.defaultSort ? true : false;
        return CONSTS.TABLE_SORT(data, colIndex, sortType);
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({ tableRowsPerPage: tableState.rowsPerPageOptions });
            break
          default:
            break
        }
      },
      toolbar: {
        filterTable: CONSTS.TABLE_OPTIONS.filterTable,
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={this.tableData}
          setSelectedRows={setSelectedRows}
          edit={this.editModel}
          workerInfo={this.workerInfo}
          showDelete={this.handleDelete} />
      )
    };

    let { classes } = this.props;
    return (
      <div
        id="webNavv-settings-phonesForm-1.0"
        style={{ marginLeft: '3%' }}
      >
        <div>
          <MuiThemeProvider theme={CONSTS.getMuiTheme()}>
            <MUIDataTable title={this.modelPhonesTitle()} data={data} columns={columns} options={options} />
          </MuiThemeProvider>
        </div>
        <AwesomeSpinner config={CONSTS.DEFAULT} loading={this.state.showSpinner} navOpen={this.props.open} />
        <MasterAlert
          snackStyle={{ marginTop: '5vh' }}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          openSnack={this.state.openSnack}
          handleClose={this.handleCloseSnackbar}
          alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
          variant={CONSTS.FILLED}
          type={this.state.snackSeverity}
          message={this.state.snackMsg}
        />
        <ModalPop
          handleCloseModal={this.handleCloseModal}
          openModal={this.state.openModal}
          icon={<ReportProblemIcon className={classes.problemIcon} />}
          title={CONSTS.MODAL_TITLE}
          subtitle={`You are going to delete "${this.state.model}"!`}
          handleFirstOption={this.deleteModel}
          handleSecondOption={this.handleCloseModal}
          button1Label={CONSTS.MODAL_DELETE}
          button2Label={CONSTS.MODAL_CANCEL}
        />
      </div>
    );
  }
}


PhoneModels.propTypes = {
  models: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  models: state.modelPhones.models,
  currentModel: state.modelPhones.model,
  campusInfo: state.auth.userInfo.campusInfo,
  phones: state.phones.phones,
  open: state.views.drawerOpen,
  user: state.user.user,
  userId: state.auth.userId
});

export default connect(mapStateToProps,{addModelPhone,getModels,putModelPhone,deleteModelPhone, createLog}) (withStyles(styles)(PhoneModels));
