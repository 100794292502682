
import { makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  commentContainer:{
   width:'100%',
   display:'inline-block',
   marginTop:'20px',
   boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
   borderRadius: "4px"
  },
  commentTitleContainer:{
    display:'flex',
    height:'50px'
  },
  commentInitialsContainer:{
    display:'inline',
    height:'50px',
    width:'10%'
  },
  commentInitialsDot:{
    backgroundColor:'#C4C4C4',
    height:'35px',
    width:'35px',
    marginTop:'10px',
    marginLeft:'10px',
    borderRadius:'50%'
  },
  commentTitleInitials:{
    paddingTop:'10px',
    paddingLeft:'7px',
    fontSize:'14px'
  },
  commentTitleInitialsBold:{
    paddingTop:'10px',
    paddingLeft:'7px',
    fontSize:'14px',
    fontWeight:'bold',
    color:'white'
  },
  commentNameContainer:{
    display:'inline',
    width:'40%'
  },
  commentsSectionNameContainer:{
    display:'inline',
    width:'35%'
  },
  commentsSectionDateContainer:{
    display:'inline',
    width:'50%'
  },
  commentDate:{
    float:'right',
    color:"#C4C4C4",
    fontSize:'14px',
    fontStyle:'italic',
    fontWeight:'normal',
    marginTop:'25px'
  },
  comment:{
    marginLeft:"20px",
    fontWeight:'500',
    fontSize:'13px'
  },
  commentUserName:{
    fontSize:'14px',
    fontWeight:'lighter',
    marginTop:'25px'
  },
  commentTextArea:{
    marginLeft:'20px',
    marginTop:'20px',
    maxWidth:"90%",
    minWidth:"90%",
    maxHeight:'10%',
    minHeight:'10%',
    borderColor:'transparent'
  },
  sendIconButton:{
    marginLeft:'1%',
    float:'right'
  },
  commentsSectionContainer:{
    marginTop:'10px',
    height:'40vh'
  },
  paper:{
    maxHeight: "38vh",
    overflow: 'auto',
    boxShadow:'none',
    border: 'none'
  },
  cancelBtnContainer:{
    marginTop:'10px'
  },
  cancelBtn:{
    position: 'sticky',
    bottom:'30vh',
    left: '35vw'
  },
  commentsSectionMainDiv:{
    display:'block',
    marginTop:'5px'
  }
}));