
import { makeStyles, withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import lightBlue from "@material-ui/core/colors/lightBlue";

export const CustomSwitch = withStyles({
    switchBase: {
      color: lightBlue[400],
      '&$checked': {
        color: lightBlue[600],
      },
      '&$checked + $track': {
        backgroundColor: lightBlue[600],
      },
    },
    checked: {},
    track: {},
})(Switch);

export const useStyles = makeStyles((theme) => ({
  containedBtn: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
},
}));