import React from 'react';

import Checkbox from "@material-ui/core/Checkbox";
import Subheader from "@material-ui/core/ListSubheader";
import {withStyles} from '@material-ui/core/styles';

import {filterStyles} from '../styles';
import * as CONSTS from "./filters.constants";
import "./filters.css";

export const CustomCheckbox = withStyles((theme) => ({
    root: {
      "&$checked": {
        color: "#2196F3"
      }
    },
    checked: {}
}))(Checkbox);

const TrackDeviceFilters = (props) =>{
    return(
    <div id="webNavv-tableView-teamManager-trackDeviceFilters-1.0">
        <Subheader >{CONSTS.FILTERS}</Subheader>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.CURRENT_DEVICE}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackDeviceFilters-currentDeviceOnly-1.0"
              onChange={props.handleActiveUser}
              checked={props.activeUser}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.SHOW_TRACES}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackDeviceFilters-showTraces-1.0"
              onChange={props.handleTraces}
              checked={props.traces}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.ALL_CALLOUTS}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackDeviceFilters-allCallouts-1.0"
              onChange={props.handleCallouts}
              checked={props.allCallouts}/>
          </div>
        </div>
    </div>
    );
}


export default withStyles(filterStyles)(TrackDeviceFilters);