import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { SETTINGS, LABEL } from './users.constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3),
    display: "contents"
  },
  headerTitle: {
    fontfamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
    color: "rgba(0, 0, 0, 0.87)",
    //margin: '10px 0',
  },
  settingSelector: {
    "& .MuiFormControlLabel-label": {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        letterspacing: 0.1,
        color: 'rgba(0, 0, 0, 0.54)',
    }
  }
}));


export default function SettingSelector(props) {
  const classes = useStyles();
  const { superAdmin = false } = props;
  const { dashboard, users, logs, statistics, debug } = SETTINGS;
  const settingsTitle = LABEL.adminSetting;

  return (
    <>
      <div className={classes.headerTitle}>{settingsTitle}</div>
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked name="check-dashboard" />}
              label={dashboard}
              disabled={superAdmin}
              className={classes.settingSelector}
            />
            <FormControlLabel
              control={<Checkbox checked name="check-users" />}
              label={users}
              disabled={superAdmin}
              className={classes.settingSelector}
            />
            <FormControlLabel
              control={<Checkbox checked name="check-logs" />}
              label={logs}
              disabled={superAdmin}
              className={classes.settingSelector}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked name="check-statistics" />}
              label={statistics}
              disabled={superAdmin}
              className={classes.settingSelector}
            />
            <FormControlLabel
              control={<Checkbox checked name="check-debug" />}
              label={debug}
              disabled={superAdmin}
              className={classes.settingSelector}
            />
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
}