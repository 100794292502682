import React from "react";

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <p> Super Admin Panel </p>
      </div>
    );
  }
};

export default AdminDashboard;