import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  userList = [];


  handleEdit = () => {
    this.props.edit(this.props.selectedRows.data[0].dataIndex);
  };

  handleDelete=()=>{
    this.props.showDelete(this.props.selectedRows.data[0].dataIndex);
  }


  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        {this.props.selectedRows.data.length===1 &&
        <Tooltip title={"Edit"}>
          <IconButton className={classes.iconButton} onClick={this.handleEdit} >
            <EditIcon className={classes.icon}/>
          </IconButton>
        </Tooltip>
        }
        <Tooltip title={"Delete"}>
          <IconButton className={classes.iconButton} onClick={this.handleDelete} >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default (withStyles(defaultToolbarSelectStyles)(CustomToolbarSelect));