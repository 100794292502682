import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import CheckboxCore from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';

export const CustomCheckbox = withStyles({
    root: {
      color: 'white',
      '&$checked': {
        color: 'white',
      },
    },
    checked: {},
  })((props) => <CheckboxCore color="default" {...props} />);

export const CustomSlider = withStyles({
    root: {
      color: '#2196F3',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

export const styles = theme => ({
    whiteColor: {
      color: "white"
    },
    closeAnimationPanel: {
      display: 'inline',
      paddingTop: '4.5vh',
      marginLeft: '1vw'
    },
    dateDiv: {
      display: 'inline',
      paddingTop: '4.5vh',
      marginLeft: '20px',
      width: '60px'
    },
    dateLbl: {
      color: 'white',
      fontSize: '13px'
    },
    speedDiv: {
      display: 'inline',
      paddingTop: '4.2vh',
      marginLeft: '10px',
      color: 'white !important'
    },
    checkboxDiv: {
      display: 'inline',
      paddingTop: '4.5vh',
      marginLeft: '10px'
    },
    filtersDiv: {
      width: '12vw',
      marginBottom: '1vw',
      marginLeft: '2vw',
      display: 'flex'
    },
    jobHistoryFiltersDiv: {
      width: '50vw',
      marginBottom: '1vw',
      marginLeft: '2vw',
      display: 'flex'
    },
    filtersLbl: {
      fontSize: '13px',
      color: 'white'
    },
    forwardDiv: {
      display: 'inline-table',
      marginLeft: '15px',
      paddingTop: '12px'
    },
    timeSliderDiv: {
      display: 'inline-table',
      width: '48vw',
      marginLeft: '20px',
      marginTop: '7px'
    },
    timeSliderDivHidden: {
      display: 'inline-table',
      width: '68vw',
      marginLeft: '20px',
      marginTop: '7px'
    },
    forwardAnimationIcon: {
      height: '12px',
      width: '18px',
      marginTop: '0.6vh',
      "&:hover": {
        cursor: 'pointer'
      }
    },
    playAnimationIcon: {
      paddingTop: '2px',
      paddingLeft: '1vw',
      fill: 'white',
      fontSize: '39px',
      "&:hover": {
        cursor: 'pointer'
      }
    },
    hideAnimationCtrl: {
      fill: 'white',
      marginTop: '10px',
      "&:hover": {
        cursor: 'pointer'
      }
    },
    animationTime: {
      display: 'inline',
      color: 'white',
      weight: 'bold',
      fontSize: '18px',
      marginLeft: '1.5vw',
      width: '60px',
      marginTop: ' 9px',
      paddingTop: '4.5vh'
    },
    animationHeader: {
      height: "5vh",
      width: "35vw",
      marginLeft: "-1.5vw",
      display: 'flex',
      borderRight: '9vh solid transparent',
      borderBottom: '4vh solid #2843A3'
    },
    animationHeaderHidden: {
      height: "5vh",
      width: "49vw",
      marginLeft: "-4vw",
      display: 'flex',
      borderRight: '9vh solid transparent',
      borderBottom: '4vh solid #2843A3'
    },
    controlLbl: {
      color: 'white',
    },
    animationBottom: {
      display: 'flex',
      backgroundColor: "#2843A3",
      height: "6.1vh",
      width: "55vw",
      marginLeft: "-3vh",
      paddingRight: '20px'
    },
    animationBottomHidden:{
      display: 'flex',
      backgroundColor: "#2843A3",
      height: "6.1vh",
      width: "85vw",
      marginLeft: "-7.5vh",
      paddingRight: '20px'
    },
    animationControl: {
      display: 'inline',
      zIndex: "1000",
      height: '68px',
      width: '30px',
      transform: 'translate(0.4vh,85vh)',
    },
    animationIconDiv: {
      display: 'inline',
      zIndex: "1000",
      height: '58px',
      width: '1px',
      transform: 'translate(-1vh,90vh)'
    },
    exitIconAnimationDiv: {
      display: 'inline',
      zIndex: "1000",
      backgroundColor: '#2843A3',
      height: '58px',
      width: '30px',
      transform: 'translate(1vh,0vh)'
    }
  });