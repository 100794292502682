import React from "react";

import './usersList.css';

const UsersList = (props) =>{
    const {users, usersModalStyle} = props;
    const handleRenderItems = () =>{
        return(
            <div className="UsersList_List">
                {users.map((user,i)=>{
                    if (!!user.disabled) {
                        return(
                            <div key={i} className="UsersList_ItemDisabled">
                                <div>
                                    {user.name}
                                </div>
                            </div>
                            
                        )
                    }else{
                        return(
                            <div key={i} className="UsersList_Item">
                                <div   
                                onClick={()=>props.handleUserClicked(user.userid)}>
                                    {user.name}
                                </div>
                            </div>
                            
                        )
                    }
                })}
            </div>
        );
    }


    return(
        <div id="webNavv-workflowAutomation-jobsBoard-assignMenu-1.0" className="UsersList_Container" style={usersModalStyle}>
            {handleRenderItems()}
        </div>
    )
}

export default UsersList;