import React from "react";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import {jobStatusTypesGraphQL} from '../jobsBoard.constants';
import { handleGetJobColor } from "./jobInfoCard";
import {styles} from '../jobsBoard.styles';
import "./jobsInfoCard.css";

class JobInfoCard extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    handleIconClick = (status) =>{
        if (status === jobStatusTypesGraphQL.inProgress || status === jobStatusTypesGraphQL.inProgressWithError) {
            this.props.redirectTrackUserMap();
        }
    }

    handleCancelJob = () =>{
        this.props.cancelJob();
    }

    handleRenderCard= () =>{
      const {items, currentItemSelected} = this.props;
      let currentItem = items.filter(item=>item.id.toString() === currentItemSelected.toString()) || null;
      let color = handleGetJobColor(currentItem[0].status);

      return(
        <div
        id="webNavv-workflowAutomation-jobsBoard-jobsCard-1.0"
        className={this.props.lastRowItem ? "JobInfoCard_ContainerTop" : "JobInfoCard_Container" }
        >
          <div id="webNavv-workflowAutomation-jobsBoard-jobsCard-infoCardSubtitle-1.0" className="JobInfoCard_SecondRow" style={currentItem[0].title.length > 16 ? {fontSize: "17px"}: {fontSize: "20px"}}>
            {currentItem[0].title}
          </div>
            <div id="webNavv-workflowAutomation-jobsBoard-jobsCard-time-1.0">
              <div id="jobInfoCard-infoCardTimeMonth" className="JobInfoCard_Time">
                {`${moment(currentItem[0].start_time).format("hh:mm")} ${moment(currentItem[0].start_time).format("MMM Do")} - ${moment(currentItem[0].end_time).format("hh:mm")} ${moment(currentItem[0].end_time).format("MMM Do")}`}
              </div>
            </div>
            <div id="webNavv-workflowAutomation-jobsBoard-jobsCard-progress-1.0" className="JobInfoCard_Progress" style={{color}}>{`${currentItem[0].progress}%`}</div>
            <div
              id="webNavv-workflowAutomation-jobsBoard-jobsCard-assignedTo-1.0"
              style={!! currentItem[0].job_assigned_user_name && currentItem[0].job_assigned_user_name.length < 15? {fontSize:"12px"}:{fontSize:"10px"}}
              className="JobInfoCard_AssignToText">
                {!!currentItem[0].job_assigned_user_name ? currentItem[0].job_assigned_user_name : "Unassigned" }
            </div>
        </div>
      )
    }

    render(){
        return(
            <React.Fragment>
                {this.handleRenderCard()}
            </React.Fragment>
        );
    }
}

export default (withStyles(styles)(JobInfoCard));