import lightBlue from "@material-ui/core/colors/lightBlue";

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from "@material-ui/core/styles";

export const CustomLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5
    },
}))(LinearProgress);

export const styles = () => ({
    JobInfo_defaultProgressBar:{
        "& .MuiLinearProgress-bar":{
          backgroundColor:'#838383',
        }
    },
    JobInfo_CardChip:{
        position: "absolute",
        top: "12%",
        left: "73%",
        textTransform: "uppercase"
    },
    JobInfo_CardChipInProgress:{
        position: "absolute",
        top: "12%",
        left: "73%",
        textTransform: "uppercase"
    },
    JobInfo_CardChipInProgressWithErrors:{
      position: "absolute",
      top: "12%",
      left: "60%",
      textTransform: "uppercase"
    },
    JobInfo_CardChipComplete:{
        position: "absolute",
        top: "12%",
        left: "75%",
        textTransform: "uppercase"
    },
    JobInfo_CardChipCompleteWithErrors:{
      position: "absolute",
      top: "12%",
      left: "63%",
      textTransform: "uppercase"
  },
    JobInfo_CardChipError:{
        position: "absolute",
        top: "12%",
        left: "80%",
        textTransform: "uppercase"
    },
    JobInfo_blueBtn: {
        position: "absolute",
        top: "75%",
        left: "3%",
        fontSize: '12px',
        marginLeft:"20px",
        color: "white",
        textTransform: "none !important",
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    JobInfo_blueBtnError: {
      position: "absolute",
      top: "76%",
      left: "3%",
      fontSize: '12px',
      marginLeft:"20px",
      color: "white",
      textTransform: "none !important",
      backgroundColor: lightBlue[600],
      "&:hover": {
        backgroundColor: lightBlue[800],
        "@media (hover: none)": {
          backgroundColor: lightBlue[600]
        }
      }
  },
    JobInfo_cancelBtn: {
        position: "absolute",
        top: "75%",
        left: "30%",
        fontSize: '12px',
        marginLeft:"20px",
        textTransform: "none !important",
    },
    JobInfo_cancelBtnDelay: {
      position: "absolute",
      top: "75%",
      left: "3%",
      fontSize: '12px',
      marginLeft:"20px",
      textTransform: "none !important",
  },
    JobInfo_inProgressProgressBar:{
        "& .MuiLinearProgress-bar":{
          backgroundColor:'#1a90ff',
        }
    },
    JobInfo_doneProgressBar:{
        "& .MuiLinearProgress-bar":{
          backgroundColor:'#589A42',
        }
    },
    JobInfo_errorProgressBar:{
        "& .MuiLinearProgress-bar":{
          backgroundColor:'#D8726F',
        }
    },
});
