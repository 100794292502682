import { mutationUpdateJobInError } from "../../../graphql/mutations";
import { ERROR_CANCEL_JOB, taskStatusTypesGraphQL } from "../../jobsBoard.constants";
import _ from 'lodash';

const handleFindFirstUnstartedTaskInList = (jobTasks) =>{
    let unstartedTaskIndex = 0;
    let shouldSkip = false;
  
    for (let index = 0; index < jobTasks.length; index++) {
      if (jobTasks[index].task_status === taskStatusTypesGraphQL.unstarted){
        unstartedTaskIndex = index;
        shouldSkip = true;
      }
      if (shouldSkip){
        break;
      }
    }
  
    return jobTasks[unstartedTaskIndex].taskid
}
  
export const handleSetJobStatusAsError = async(items, currentItemSelected, jobTasks) =>{
  const currentJob = items.find(item=>item.id === currentItemSelected);
  const unstartedTask = handleFindFirstUnstartedTaskInList(jobTasks);

  const UpdateJob_Error = {
    job_error_numberid : ERROR_CANCEL_JOB,
    jobid: currentJob.jobid,
    taskid: unstartedTask,
    id: ""
  }

  const jobUpdatedInError = await mutationUpdateJobInError(UpdateJob_Error);

  if (jobUpdatedInError){
    return "done";
  }
  else {
    return "error";
  }
}