export const KEEP_PASS="Keep Password";
export const CHANGE_PASS='Change Password';
export const CHANGE_PASS_LBL='change-password';
export const MSG="message";
export const UPDATE_ACCOUNT='Update Account';
export const UPDATE_ACCOUNT_MSG="Account updated successfully! ";
export const UPDATE_ACCOUNT_MSG_BOLD="Log in again to see the changes";
export const UPDATE_ACCOUNT_ERROR='Update Account Error';
export const UPDATE_ACCOUNT_ERROR_MSG="Account couldn't be updated";
export const SUCCESS='success';
export const WARNING='warning';
export const FILLED="filled";
export const TEXT='text';
export const PASSWORD='password';
export const CANCEL='Cancel';
export const SAVE='Save';
export const DEFAULT_FLAT='default-flat';
export const SECURITY='SECURITY';
export const PASS_LBL='Password';
export const NEW_PASS_LBL="New Password";
export const RETYPE_PASS_LBL="Retype Password";
export const ACCOUNT_INFO='ACCOUNT INFORMATION';
export const FIRST_NAME="First Name";
export const LAST_NAME="Last Name";
export const EMAIL="Email";
export const BTN_SIZE={
    medium: 'medium'
};
export const EMAIL_ERROR={
    duplicated:{
        id: 'duplicated',
        msg: 'Email already registered!'
    },
    empty:{
        id: 'empty',
        msg: 'Email cannot be empty!'

    },
    incorrectFormat:{
        id: 'incorrect format',
        msg: 'Incorrect email format!'
    }
};
export const ERROR_INPUT={
    email:{
        id: 'email'
    },
    firstName:{
        id: 'firstName',
        msg: 'First Name cannot be empty!'
    },
    lastName:{
        id: 'lastName',
        msg: 'Last Name cannot be empty!'
    },
    pass:{
        id: 'pass1',
        msg: 'Incorrect Entry!'
    },
    confirmPass:{
        id: 'pass2',
        msg: "Passwords don't match!"
    }
}