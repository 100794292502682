import React from "react";
import moment from "moment";

import Chip from '@material-ui/core/Chip';
import { withStyles } from "@material-ui/core/styles";

import MasterButton from "../../../utilities/button.component";
import {styles, CustomLinearProgress} from './jobInfo.styles';
import "./jobInfo.css";
import { CANCEL_JOB_BTN_LBL, ERROR_DETAILS, JOB_NUMBER, SPECIMEN_RETRIEVAL_LBL, VIEW_HISTORY_BTN_LBL, VIEW_MAP_BTN_LBL } from "./jobInfo.constants";
import {handleJobConfigurations, handleErrorDescription, isJobComplete, isJobInProgress, isJobCancelled, isJobDelayedButNotStarted, checkIfJobHasErrors} from "./jobInfo";

class JobInfo extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    handleCancelJob = () =>{
        this.props.cancelJob();
    }

    handleButtonsToRender = (currentItem) =>{
      const { classes } = this.props;

      if (isJobComplete(currentItem[0].status)) {
        return(
          <MasterButton
          id="webNavv-workflowAutomation-jobsBoard-jobInfo-viewHistoryBtn-1.0"
          keyLbl={'jobInfo-viewHistory-button'}
          label={VIEW_HISTORY_BTN_LBL}
          buttonType={"success"}
          isDisabled={false}
          handleClick={()=>this.props.redirectJobHistoryMap(currentItem[0])}
          size={"medium"}
          class={classes.JobInfo_blueBtn}
          startIcon={"refresh"}/>
        )
      }
      else if (isJobCancelled(currentItem[0])) {
        return(
          <MasterButton
          id="webNavv-workflowAutomation-jobsBoard-jobInfo-viewHistoryBtn-1.0"
          keyLbl={'jobInfo-viewHistory-button'}
          label={VIEW_HISTORY_BTN_LBL}
          buttonType={"success"}
          isDisabled={false}
          handleClick={()=>this.props.redirectJobHistoryMap(currentItem[0])}
          size={"medium"}
          class={classes.JobInfo_blueBtnError}
          startIcon={"refresh"}/>
        )
      }
      else if (isJobDelayedButNotStarted(currentItem[0])) {
        return(
          <MasterButton
          id="webNavv-workflowAutomation-jobsBoard-jobInfo-cancelJobBtn-1.0"
          keyLbl={'jobInfo-cancel-button'}
          label={CANCEL_JOB_BTN_LBL}
          buttonType={"error"}
          isDisabled={false}
          handleClick={()=>this.props.handleOpenModal('cancel', currentItem[0].title)}
          size={"medium"}
          class={classes.JobInfo_cancelBtnDelay}
          startIcon={"error"}/>
        )
      }
      else if (isJobInProgress(currentItem[0].status)) {
        return(
          <div>
            <MasterButton
            id="webNavv-workflowAutomation-jobsBoard-jobInfo-viewMapBtn-1.0"
            keyLbl={'jobInfo-viewMap-button'}
            label={VIEW_MAP_BTN_LBL}
            buttonType={"success"}
            isDisabled={false}
            handleClick={()=>this.props.redirectTrackUserMap(currentItem[0])}
            size={"medium"}
            class={classes.JobInfo_blueBtn}
            startIcon={"location"}/>
            <MasterButton
            id="webNavv-workflowAutomation-jobsBoard-jobInfo-cancelJobBtn-1.0"
            keyLbl={'jobInfo-cancel-button'}
            label={CANCEL_JOB_BTN_LBL}
            buttonType={"error"}
            isDisabled={false}
            handleClick={()=>this.props.handleOpenModal('cancel', currentItem[0].title)}
            size={"medium"}
            class={classes.JobInfo_cancelBtn}
            startIcon={"error"}/>
          </div>
        )
      }
    }

    handleRenderCard = () =>{
          const { items, currentItemSelected, classes } = this.props;
          let currentItem = items.filter(item=>item.id.toString() === currentItemSelected.toString()) || null;
          let [configuration, iconButton] = handleJobConfigurations(currentItem[0].status, classes);
          const showErrorDetails = checkIfJobHasErrors(currentItem[0].status);
          return(
            <div
            id="webNavv-workflowAutomation-jobsBoard-jobInfo-1.0"
            className="JobInfo_Container"
            >
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-jobTitle-1.0" className="JobInfo_Title">
                  {currentItem[0].title}
                </div>
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-jobChipStatus-1.0">
                      <Chip 
                        id="jobInfo-chip"
                        style={{backgroundColor: configuration.chipColor, color: configuration.color}}
                        label={configuration.label} 
                        className={configuration.chipClass}
                        icon={iconButton} />
                </div>
                <div
                  id="webNavv-workflowAutomation-jobsBoard-jobInfo-assignToText-1.0"
                  className="JobInfo_AssignTo">
                  {!!currentItem[0].job_assigned_user_name? currentItem[0].job_assigned_user_name : "Unassigned"}
                </div>
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-time-1.0">
                  <div id="jobInfo-infoCardTime" className="JobInfo_CardTime"
                  style={{color: configuration.color}}>
                    {moment(currentItem[0].start_time).format("hh:mm")}
                  </div>
                  <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardTimeMonth-1.0" className="JobInfo_CardTimeMonth">
                    {moment(currentItem[0].start_time).format("MMM Do")}
                  </div>
                  <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardTimeDivider-1.0" className="JobInfo_CardTimeDivider">
                    -
                  </div>
                  <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardEndTime-1.0" className="JobInfo_CardEndTime" style={{color: configuration.color}}>
                    {moment(currentItem[0].end_time).format("hh:mm")
                  }</div>
                  <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardTimeEndMonth-1.0" className="JobInfo_CardTimeEndMonth">
                    {moment(currentItem[0].end_time).format("MMM Do")}
                  </div>
                </div>
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-specimenRetrieval-1.0" className="JobInfo_SpecimenTrackingBox">
                    {SPECIMEN_RETRIEVAL_LBL}
                </div>
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardProgress-1.0" className="JobInfo_CardProgress">{`${currentItem[0].progress}% Complete`}</div>
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-infoCardProgressBar-1.0" className="JobInfo_CardProgressBar">
                    <CustomLinearProgress className={configuration.progressBarClass} variant="determinate" value={currentItem[0].progress} />
                </div>
                {showErrorDetails && (
                  <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-errorDetails-1.0" className="JobInfo_ErrorDetailsContainer">
                       <div className="JobInfo_ErrorDetailsTitle">{ERROR_DETAILS}</div>
                       <div className="JobInfo_ErrorDetailsDescription">{handleErrorDescription(currentItem[0].job_error_number)}</div>
                  </div>
                )}
                {this.handleButtonsToRender(currentItem)}
                <div id="webNavv-workflowAutomation-jobsBoard-jobInfo-jobId-1.0" className="JobInfo_JobIdContainer">
                    <p className="JobInfo_JobIdTitle">{JOB_NUMBER}</p>
                    <p className="JobInfo_JobIdDescription">{currentItem[0].job_number}</p>
                </div>
            </div>
          )
    }

    render(){
        return(
            <React.Fragment>
                {this.handleRenderCard()}
            </React.Fragment>
        );
    }
}

export default (withStyles(styles)(JobInfo));