export const emailValidation = (email)=> {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const passwordMatch = (pass, confirm) => {
    let matching = pass === confirm;
    return matching;
}

export const noEmptyValidation = (value)=> {
    return value !== null && value.length !== 0;
}