import React from "react";

import MasterButton from "../../../utilities/button.component";

const HeaderButtons = (props) =>{
    const {editBoard, classes, isEditDisabled, showSpinner} = props;
    return(
    <div style={{height:"40px"}}>
        {editBoard
        ? (
          <div style={{float:"right", marginRight:"50px"}}>
              <MasterButton
              keyLbl={'return-jobs-board-edit'}
              label={"Return to Jobs Board"}
              buttonType={"success"}
              isDisabled={showSpinner}
              handleClick={props.handleCloseEdit}
              size={"medium"}
              class={classes.JobsBoard_blueButton}
              startIcon={"back"}/>
          </div>
        ) :(
          <div style={{float:"right", marginRight:"50px"}}>
              <MasterButton
              keyLbl={'edit-jobs-board'}
              label={"Edit Board"}
              buttonType={"success"}
              isDisabled={isEditDisabled || showSpinner}
              handleClick={props.handleEditBoard}
              size={"medium"}
              class={classes.JobsBoard_blueButton}
              startIcon={"edit"}/>
          </div>
        )}
    </div>
    );
}

export default HeaderButtons;