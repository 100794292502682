import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FlatButton from "material-ui/FlatButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GroupIcon from '@material-ui/icons/Group';

import { connect } from "react-redux";
import { changeView } from '../../actions/views/viewsActions';
import { getPhones } from '../../actions/phones/phonesActions';
import {listUsers, forceLogout, updateUser} from "../../actions/user/userActions";
import {createLog} from '../../actions/logs/logsActions';
import * as CONSTS from "./teamManager.constants";
import {
  FORMAT_FILTER_LABELDATES,
  DATE_FILTER_LOGIC,
  UPDATE_FILTERS,
  STRING_FILTER_LOGIC} from "../utilities/common_tableFilters";
import AwesomeSpinner from '../utilities/spinner';
import CustomToolbar from  '../utilities/refreshBtn.component';
import MasterTable from '../utilities/table.component';
import MasterAlert from '../utilities/alert.component';
import {styles} from './teamManager.styles';
import PropTypes from "prop-types";
import moment from "moment";
import _ from 'lodash';

class TeamManager extends React.Component {
  constructor(props){
  super(props);
    this.state = {
      users: [],
      joinUser:'',
      loggedoutUser:'',
      openSnack:false,
      snackType:'',
      snackMsg:'',
      expireUserList:{},
      isSearchClosed: false,
      showSpinner: true,
      showTable: false,
      tableRowsPerPage: 10,
      hiddenRows:{
        num: true,
        findWorker: true,
        worker: true,
        userId: false,
        email: true,
        role: true,
        status: true,
        location: true,
        device: true,
        lastlogin: true,
        lastlogout: true,
        duration: true
      }
  }
  };
  onSearch = false;
  searchBy = '';
  onFilter = false;
  filterBy = [];
  startLoop = true;
  elements = CONSTS.DEFAULT_ELEMENTS
  expire = false;
  tableData = [];
  workersList = [];
  devicesList = [];
  rolesList = [];
  interval = null;
  data = [];
  durationUpdateInterval = null;

  componentDidMount = () => {
      this.props.listUsers().then(resp=>{
        this.props.getPhones();
        this.setState({showTable: true, showSpinner: false});
      });
      // this.props.socketChat.on(CONSTS.JOINED_CHANNEL,user => {
      //   if (!!this.props.users && !!this.props.campusId) {
      //     let userId = !!user._id ? user._id : user.id;
      //     let currentUser = this.props.users.find(u=>u._id === userId);
      //     if (!!currentUser && currentUser.campusId === this.props.campusId) {
      //       let userJoined = {}
      //       let isUserJoined = false;
      //       userJoined[user.id] !== true && this.props.listUsers();
      //       if(userJoined[user.id] !== true) {userJoined[user.id]=true};
      //       if(Object.keys(userJoined).length!==0 && !isUserJoined){
      //          isUserJoined = true;
      //          this.handleCleanUserJoined().then(res=>{
      //            userJoined = res.userJoined;
      //            isUserJoined = res.isUserJoined;
      //          });
      //       }
      //     }
      //   }
      //  });

      //  this.props.socketChat.on(CONSTS.LOGOUT_USER_CHANNEL,user => {
      //   if (!!this.props.users && !!this.props.campusId) {
      //     let userId = !!user._id ? user._id : user.id;
      //     let currentUser = this.props.users.find(u=>u._id === userId);
      //     if (!!currentUser && currentUser.campusId === this.props.campusId) {
      //       this.props.listUsers();
      //     }
      //   }
      //  });

      this.durationUpdateInterval = setInterval( () => {
        this.data.forEach((row, index)=>{
          row.number = ++index;
          if (row.status !== 'Offline' && row.hasOwnProperty('lastlogin') && row.hasOwnProperty('lastlogout')) {
            let dateDiff = CONSTS.DATE_DIFFERENCE(row.lastlogin[1], row.lastlogout[1]);
            let key = row.duration[2];
            row.duration = [dateDiff.difference, dateDiff.sortDifference, key];
          }
        });
      },60000);
  }

   handleCleanUserJoined = async () => {
    return new Promise((resolve,reject)=>{
      setTimeout(()=>{
        resolve({userJoined:{},isUserJoined:false})
    },6000)
    })

}
componentWillUnmount(){
  clearInterval(this.interval);
  clearInterval(this.durationUpdateInterval);
}
componentDidUpdate(){
  if (this.state.isSearchClosed) {
    this.setState({isSearchClosed: false});
  }
}

  getData = () => {
    this.props.getPhones();
    this.props.listUsers();
  }

  handleChangeSwitch = () => {
    this.filterBy=[];
    this.onFilter=false;
    this.onSearch=false;
    this.searchBy='';
    this.getData();
  };

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    if (this.filterBy.length !== 0 && this.onFilter) {
      filterList = this.filterBy;
    }
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type={CONSTS.TEXTFIELD_TYPE_DATETIME}
          format={CONSTS.TEXTFIELD_DATE_FORMAT}
          InputLabelProps={{
            shrink: true
          }}
          value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  deviceFilterCustomRender = (filterList, onChange, index, column, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={CONSTS.DEVICE_INPUT_LBL}
          value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  handleModifiedBy = ()  => {
    const {user, userId} = this.props;
    let modifiedBy = '';
    if(user && user.id) {
        modifiedBy = user.id;
    } else if (userId) {
        modifiedBy = userId;
    }
    return modifiedBy;
  }

  createTMLog = data => {
    let modifiedBy = this.handleModifiedBy();
    let log = {
        campusId: this.props.acl.campusId,
        userId: modifiedBy,
        action: data.action,
        description: data.description,
        timestamp: new Date()
    };
    // this.props.createLog(log);
  };

  expireDesktopUsers = (userToExpire,key) => {
    const {classes } = this.props;
    const actionPerformedBy = this.handleModifiedBy();
    this.props.forceLogout(userToExpire,actionPerformedBy);
    this.props.listUsers();
    let userInfo = this.tableData.find(user => user.worker.id === userToExpire);
    let msg = <div className={classes.msgContainer}><div className={classes.msgDiv}>{userInfo.worker.workerName}</div> expired successfully!</div>
    this.createTMLog({
      action:'Expire Desktop User',
      description: `"${userInfo.worker.workerName}" expired by "${actionPerformedBy}"`
    });
    this.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCESS, msg);
  }

  expirePhoneUsers (userToExpire,key) {
    const {classes} = this.props;
    const actionPerformedBy = this.handleModifiedBy();
    this.props.forceLogout(userToExpire,actionPerformedBy)
    .then(res=>{
      this.props.listUsers();
      let userInfo = this.tableData.find(user => user.worker.id === userToExpire);
      let msg = <div className={classes.msgContainer}><div className={classes.msgDiv}>{userInfo.worker.workerName}</div> expired successfully!</div>
      this.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCESS, msg);
      this.createTMLog({
        action:'Expire Mobile User',
        description: `"${userInfo.worker.workerName}" expired by "${actionPerformedBy}"`
      });
    }).catch(err=>{
      let userInfo = this.tableData.find(user => user.worker.id === userToExpire);
      let msg = <div className={classes.msgContainer}><div className={classes.msgDiv}>{userInfo.worker.workerName}</div> couldn't be expired!</div>
      this.handleOpenSnackbar(CONSTS.SNACKBAR_WARINING, msg);
      this.createTMLog({
        action:'Expire Mobile User Error',
        description: `"${userInfo.worker.workerName}" expired by "${actionPerformedBy}"`
      });
    });
  }

  statusComponents = ([btnTitle, deviceFound, key, userId, colorLbl, status, columnIndex,type]) => {
    const {DEVICE_TYPE, CHAT, DEVICE_COLOR_CODE} = CONSTS;
    const {classes} = this.props;
    let defaultStatus= CHAT.Offline.label;
    return(
      <React.Fragment>
          <Tooltip title={btnTitle}>
          <FlatButton
            id={"sbtn-"+key}
            onClick={
              type===DEVICE_TYPE.phone?
              () => { deviceFound === DEVICE_COLOR_CODE.inDB ?  this.expirePhoneUsers(userId,key) : this.handleOpenDialog()}
              : () => {this.expireDesktopUsers(userId,key)}}
            value={status}
            index={columnIndex}
            style={{display:"inline-block"}}
            textcontent={status}
            disabled={false}
          >
           <div className={classes.expireUserContainer}>
              <div className={classes.expireUserDiv} style={{backgroundColor: colorLbl}}></div>
              {status===CHAT.Inactive.label ? CHAT.Online.label : status}
          </div>
          </FlatButton>
        </Tooltip>
        <div id={"slbl-"+key} className={classes.defaultStatusContainer}>
              <div className={classes.defaultStatusDiv}></div>
              <div style={{float:'left'}}>{defaultStatus}</div>
        </div>
      </React.Fragment>
    );
  }

  statusCustomRender = ([status,userId,mobileUser,online,deviceFound,key], tableMeta) => {
    const {CHAT, CHAT_BTN_LBLS, DEVICE_TYPE, DEVICE_COLOR_CODE} = CONSTS;
    const {classes} = this.props;
    let colorLbl = CHAT.Offline.color;
    if (status === CHAT.Online.label) {
      colorLbl = CHAT.Online.color;
    }
    if (status === CHAT.Inactive.label){
      colorLbl = CHAT.Inactive.color;
    }

    let desktopConfig = !mobileUser && online ? true : false;
    let phoneConfig = mobileUser && online ? true : false;
    let btnPhoneTitle = mobileUser && deviceFound === DEVICE_COLOR_CODE.inDB ? CHAT_BTN_LBLS.expire : CHAT_BTN_LBLS.unknown;
    let btnDesktopTitle = CHAT_BTN_LBLS.expire;

    if(desktopConfig){
      let buttonInfo = [btnDesktopTitle,deviceFound,key,userId,colorLbl,status,tableMeta.columnIndex,DEVICE_TYPE.desktop];
      return this.statusComponents(buttonInfo);
    }
    if(phoneConfig){
      let buttonInfo = [btnPhoneTitle,deviceFound,key,userId,colorLbl,status,tableMeta.columnIndex,DEVICE_TYPE.phone]
      return this.statusComponents(buttonInfo);
    }
    else return (
      <div className={classes.statusLblContainer}>
        <div className={classes.statusLblDiv} style={{backgroundColor: colorLbl}}></div>
        {status}
    </div>)
  };

  handleOpenDialog = () => {
    let msg = <div style={{display:'inline'}}>{CONSTS.UNKNOWN_DEVICE_MSG}</div>
    this.handleOpenSnackbar(CONSTS.SNACKBAR_WARINING, msg);
  }

  handleOpenSnackbar = (type,msg) => {
    this.setState({snackType:type,snackMsg:msg,openSnack: true});
  };

  handleCloseSnackbar = () => {
    this.setState({openSnack: false});
  };

  deviceCustomRender = value => {
    const {CHAT_BTN_LBLS, DEVICE_COLOR_CODE, CHAT} = CONSTS;
    let deviceInfo = this.tableData.find(row=>row.device[0] === value);
    if (deviceInfo.device[1] === DEVICE_COLOR_CODE.inDB){
      return <p style={{ color: "#757575" }}>{value}</p>;
    }
    if (deviceInfo.device[1] === DEVICE_COLOR_CODE.notInDB) {
      return (<Tooltip title={CHAT_BTN_LBLS.unknown}><p style={{ color: CHAT.Offline.color }}>{value}</p></Tooltip>);
    }
    if (deviceInfo.device[1] === DEVICE_COLOR_CODE.notInCurrentCampus) {
      return (<Tooltip title={CHAT_BTN_LBLS.otherCampus}><p style={{ color: CHAT.Inactive.color }}>{value}</p></Tooltip>);
    }
  };

  logoutCustomRender = value => {
    if (value[2]!== "") {
      return(<div>
        <p id={"outDate-"+value[2]} style={{display:"block"}}>{value[0]}</p>
        <p id={"outDateHidden-"+value[2]} style={{display:"none"}}></p>
      </div>);
    } else {
      return <p>{value[0]}</p>;
    }
  }

  loginCustomRender = value => {
    if (value[2]!== "") {
      return<p id={"inDate-"+value[2]}>{value[0]}</p>;
    } else {
      return <p>{value[0]}</p>;
    }
  }

  durationCustomRender = value => {
    if (value[2]!== "") {
      return(<div>
        <p id={"duration-"+value[2]} style={{display:"block"}}>{value[0]}</p>
        <p id={"durationHidden-"+value[2]} style={{display:"none"}}></p>
      </div>);
    } else {
      return <p>{value[0]}</p>;
    }
  }

  streamingCustomRender = value => {
    const {CHAT, YES}= CONSTS;
    const {classes} = this.props;
    let colorLbl = value === YES ? CHAT.Online.color : CHAT.Offline.color;
    let triangle = colorLbl === CHAT.Offline.color ? CHAT.Inactive.color : CHAT.Online.color;
    return(
      <div  className={classes.streamingContainer} style={{color:colorLbl }}>
        <div className={classes.streamingDiv}>
          {triangle===CHAT.Inactive.color?
            <div className={classes.inactiveStreaming}></div>
          :
            <div className={classes.activeStreaming}></div>
          }
        </div>
        {value}
    </div>
    )
  }

  handleChangeToMap = async(userId, deviceId) => {
    if (!!userId && !!deviceId) {
      this.props.changeView(CONSTS.MAP, CONSTS.TRANSPORTER, {userId: userId, deviceId: deviceId});
    }
  }

  handleRenderFindWorker = value => {
    if (value.deviceId === CONSTS.DESKTOP_LBL) {
      return (
        <IconButton disabled>
          <SearchIcon style={{color: '#6F6F6F'}}/>
        </IconButton>
      )
    } else {
      let toolTag = CONSTS.WORKER_LBL.find;
      return (
        <Tooltip title={toolTag}>
          <IconButton onClick={() => {this.handleChangeToMap(value.userId,value.deviceId)}}>
            <SearchIcon style={{color: '#2F80ED'}}/>
          </IconButton>
        </Tooltip>
      );
    }
  };

  handleEmailRender = value => {
    if (value.length >= 30) {
      return (<p style={{textAlign: 'left', paddingLeft:'5px', fontSize: '12px'}}>{value}</p>)
    } else {
      return (<p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>)
    }
  };

  handleWorkerNameRender = value => {
    if (value.length >= 17) {
      return (<p style={{textAlign: 'left', paddingLeft:'5px', fontSize: '12px'}}>{value}</p>)
    } else {
      return (<p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>)
    }
  };

  deviceTag = device_id => {
    let deviceFound = true;
    if (device_id !== CONSTS.DEVICE_TYPE.desktop && !!device_id) {
      let device = this.props.phones.find(phone=> phone.UUID.trim() === device_id.trim()) || [];
      if (_.isEmpty(device)) {
        deviceFound = false;
      }
    }
    return ([device_id, deviceFound]);
  };

  stringFieldsCustomRender = (filterList, onChange, index, column,key) => {
    const {STRING_FIELD_TYPE}= CONSTS;
    let inputLabel = "";
    let optionList = [];
    if (key===STRING_FIELD_TYPE.worker.id) {
     inputLabel = STRING_FIELD_TYPE.worker.label;
     optionList = this.workersList.sort();
    }
    if (key===STRING_FIELD_TYPE.role.id) {
      inputLabel = STRING_FIELD_TYPE.role.label;
      let noAdminList = this.rolesList.filter(role=>role !== 'Admin');
      optionList = noAdminList.sort();
    }
    if (key===STRING_FIELD_TYPE.device.id) {
     inputLabel = STRING_FIELD_TYPE.device.label;
     optionList = this.devicesList.sort();
    }
    const onTagsChange = (event, values) => {
       filterList[index][0] = values;
       onChange(filterList[index], index, column);
    }
     return(
       <Autocomplete
         id={key}
         options={optionList}
         getOptionLabel={(option) => option}
         style={{ width: 320 }}
         onChange={onTagsChange}
         renderInput={(params) => <TextField {...params} label={inputLabel} InputLabelProps={{shrink: true}} />}
       />
     );
  };

  handleTableFilters = column => {
    let filter = this.filterBy.length ? this.filterBy[column] : null
    return filter;
  };

  /**
  * Returns list with the Team contacts
  */
  handleGetTeamComposition = () => {
    const { teamComposition } = this.props;

    teamComposition.forEach(role=>{
      if (!this.rolesList.includes(role.role) && !!role.role) {
        this.rolesList.push(role.role);
      }
    });
  };

  /**
  * Filters Users list to return only the 'Enabled' user accounts
  */
  handleGetUsersEnabled = () => {
    const { users } = this.props;
    return (users.filter(u=> !!u && !!u.enabled && u.role_name !== 'Admin'));
  };

  determineIfCurrentUserIsStreamingLocation = (userId, deviceId) =>{
    const { lastlocationSocket } = this.props;
    const { YES,NO,} = CONSTS;
    
    let isDeviceStreamingLoc = NO;
    let locationKeys = Object.keys(lastlocationSocket);
    locationKeys.forEach(location=>{
      if (userId === lastlocationSocket[location].userId && deviceId === lastlocationSocket[location].dId && lastlocationSocket[location].status === 'online'){
        isDeviceStreamingLoc = YES;
      }
    });

    return isDeviceStreamingLoc;
  }

  /**
  * Assigns User data to each column
  */
  handleFillRowsWithData = (usersEnabled) => {
    const { expireUserList } = this.state;
    let data = [];
    let counter = 1;
    let logout = "";
    let device = "";
    let deviceFound = "";

    usersEnabled.forEach(p => {
      if (p._id !== this.props.userId && this.props.acl.contact.includes(p.role) && !!p.last_login) {
        let key ="";
        let lastLoginFormat = moment(p.last_login).format(CONSTS.DATE_FORMAT);
        let workerName = `${p.first_name} ${p.last_name}`;
        let trackUser = CONSTS.TRACK_USER_BUTTON(p.online, p.device_id);
        let mobileUser = CONSTS.WORKER_TYPE(p);
        device = p.device_id;
        deviceFound = p.deviceInDB;

        if (!mobileUser) {
          device = CONSTS.DESKTOP_LBL;
        }
        if (p.hasOwnProperty(CONSTS.USER_PROPERTIES.lastLogout)){
          logout = p.lastLogout;
        }

        let lastLogoutFormat = moment(logout).format(CONSTS.DATE_FORMAT);
        let dateDiff = CONSTS.DATE_DIFFERENCE(p.last_login, logout);

        if(mobileUser && p.online && deviceFound === CONSTS.DEVICE_COLOR_CODE.inDB){
          key = Date.now() + counter++;
        }

        if(!this.workersList.includes(workerName) && workerName!==undefined){
          this.workersList.push(workerName);
        }
        if(!this.devicesList.includes(device) && device!==undefined){
          this.devicesList.push(device);
        }

        let status = CONSTS.WORKER_STATUS(p.status, p.online);
        let locStream = this.determineIfCurrentUserIsStreamingLocation(p._id, device);

        let finalStatus = [status, p._id, mobileUser, p.online, deviceFound,key];
        let finalLogout =  [lastLogoutFormat, logout, key];
        let finalDuration = [dateDiff.difference, dateDiff.sortDifference, key];
        let finalLocStream = locStream;

        if (status === CONSTS.CHAT.Offline.label && expireUserList.hasOwnProperty(p._id) ){
          let list = expireUserList;
          delete list[p._id];
          this.setState({expireUserList: list});
        }

        if(expireUserList.hasOwnProperty(p._id)){
          finalStatus = [CONSTS.CHAT.Offline.label, p._id, mobileUser, false, deviceFound,key];
          let expiredAt = expireUserList[p._id].expiredAt;
          let logoutFormat = moment(expiredAt).format(CONSTS.DATE_FORMAT);
          let expireDiff = CONSTS.DATE_DIFFERENCE(p.last_login,expiredAt);
          finalLogout = [logoutFormat, expiredAt, key];
          finalDuration = [expireDiff.difference, expireDiff.sortDifference, key];
          finalLocStream = CONSTS.NO;
        }

        this.tableData.push({
          worker:{
            id:p._id,
            workerName:workerName,
            trackUser:trackUser,
            key:key,
            status:status,
            mobileUser:mobileUser,
            locStream:locStream
          },
          device: [device, deviceFound]
        });

        data.push({
          no: 0,
          findWorker: {userId: p._id, status: status, deviceId: device.trim()},
          worker: workerName,
          userId: p._id,
          email: p.email,
          role: p.role_name,
          status: finalStatus,
          location: finalLocStream,
          lastlogin: [lastLoginFormat, p.last_login, key],
          lastlogout: finalLogout,
          duration: finalDuration,
          device: device
        });
      }
    });

    return data;
  };

  /**
  * Sets the 'default' sort for table
  */
  handleSortTableDefault = data => {
    let tableSort = data.sort((a, b) => {
      let dateA = '', dateB = '';

      if(a.lastlogout[1] === undefined) {
        dateA = undefined;
      }
      if(b.lastlogout[1] === undefined) {
        dateB = undefined;
      }

      if (a.status[0]===CONSTS.CHAT.Offline.label && b.status[1]===CONSTS.CHAT.Offline.label) {
        if(dateA === '' && dateB === ''){
          dateA = a.lastlogout[1];
          dateB = b.lastlogout[1];
        }
      }
      else if (dateA === '' && dateB === '')  {
        dateA = a.lastlogin[1];
        dateB = b.lastlogin[1];
      }

      if (CONSTS.CHAT[a.status[0]].value > CONSTS.CHAT[b.status[0]].value) {
        return -1
      }
      if (CONSTS.CHAT[a.status[0]].value < CONSTS.CHAT[b.status[0]].value) {
        return 1
      }
      else {
        return (dateB === undefined) - (dateA === undefined) || new Date(dateA) - new Date(dateB);
      }
    });

    this.data = tableSort.reverse();
  };

  /**
  * Checks if filters apply to a column
  */
  handleCheckIfFiltersApply = () => {
    let applyFilters = false;

    if (this.filterBy.length !== 0) {
      this.filterBy.forEach(filter=>{
        if (filter.length) {
          applyFilters = true
        }
      });
      if (!applyFilters) {
        this.handleChangeSwitch();
      }
    }
  };

  /**
  * Returns column configuration
  */
  handleTableColumns = () => {
    const { hiddenRows } = this.state;
    const { classes }= this.props;
    const columns = [
      // {
      //   name: CONSTS.COLUMNS.num.id,
      //   label: CONSTS.COLUMNS.num.label,
      //   options: {
      //     display: hiddenRows.num,
      //     customHeadRender: (value, tableMeta)=>{
      //       return (<th key={tableMeta.rowIndex+1} className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
      //         <p className={classes.numHeader}>Num</p>
      //       </th>)
      //     },
      //     sort:false,
      //     filter:false,
      //     customBodyRender: (value, tableMeta) => {
      //       return <p>{tableMeta.rowIndex+1}</p>;
      //     }
      //   }
      // },
      {
        name: CONSTS.COLUMNS.findWorker.id,
        label: CONSTS.COLUMNS.findWorker.label,
        options: {
          display:hiddenRows.findWorker,
          filter:false,
          sort:false,
          customHeadRender: (value, tableMeta)=>{
            return (<th key='findWorker' className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <p className={classes.numHeader}>Find</p>
            </th>)
          },
          customBodyRender: value =>this.handleRenderFindWorker(value)
        }
      },
      {
        name: CONSTS.COLUMNS.worker.id,
        label: CONSTS.COLUMNS.worker.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.worker,
          customBodyRender: (value) => this.handleWorkerNameRender(value),
          filterType: CONSTS.CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(1),
          customFilterListOptions: {
            render: worker => `${CONSTS.COLUMNS.worker.label}: ${worker}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(worker, filters) {
              return STRING_FILTER_LOGIC(worker, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FIELD_TYPE.worker.id)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.userId.id,
        label: CONSTS.COLUMNS.userId.label,
        options: {
          display: hiddenRows.userId,
          sort:false,
          customBodyRender: (value) => <p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>,
        }
      },
      {
        name: CONSTS.COLUMNS.email.id,
        label: CONSTS.COLUMNS.email.label,
        options: {
          display: hiddenRows.email,
          sortThirdClickReset: true,
          customBodyRender: (value) =>this.handleEmailRender(value)
        }
      },
      {
        name: CONSTS.COLUMNS.role.id,
        label: CONSTS.COLUMNS.role.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.role,
          filterType: CONSTS.CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(4),
          customFilterListOptions: {
            render: role => `${CONSTS.COLUMNS.role.label}: ${role}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(role, filters) {
              return STRING_FILTER_LOGIC(role, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FIELD_TYPE.role.id)
          },
          customBodyRender: (value) => <p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>,
        }
      },
      {
        name: CONSTS.COLUMNS.status.id,
        label: CONSTS.COLUMNS.status.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.status,
          customFilterListOptions: { render: v => `${CONSTS.COLUMNS.status.label}: ${v}` },
          filterOptions: {
            names: CONSTS.COLUMNS.status.filter
          },
          customBodyRender: (value, tableMeta) => this.statusCustomRender(value, tableMeta)
        }
      },
      {
        name: CONSTS.COLUMNS.location.id,
        label: CONSTS.COLUMNS.location.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.location,
          customFilterListOptions: { render: v => `${CONSTS.COLUMNS.location.label}: ${v}` },
          filterOptions: {
            names: CONSTS.COLUMNS.location.filter
          },
          customBodyRender: (value) =>this.streamingCustomRender(value)
        }
      },
      {
        name: CONSTS.COLUMNS.device.id,
        label: CONSTS.COLUMNS.device.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.device,
          customBodyRender: (value) =>this.deviceCustomRender(value),
          filterType: CONSTS.CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(7),
          customFilterListOptions: {
            render: device => `${CONSTS.COLUMNS.device.label}: ${device}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(device, filters) {
              return STRING_FILTER_LOGIC(device, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FIELD_TYPE.device.id)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.lastlogin.id,
        label: CONSTS.COLUMNS.lastlogin.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.lastlogin,
          sort: true,
          filterType: CONSTS.CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(8),
          customBodyRender: (value) =>this.loginCustomRender(value),
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], CONSTS.COLUMNS.lastlogin.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return DATE_FILTER_LOGIC(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.lastlogin.label, CONSTS.START_DATE)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.lastlogout.id,
        label: CONSTS.COLUMNS.lastlogout.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.lastlogout,
          filterType: CONSTS.CUSTOM_FILTER_TYPE,
          filterList: this.handleTableFilters(9),
          customBodyRender: (value) =>this.logoutCustomRender(value),
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], CONSTS.COLUMNS.lastlogout.label),
            update: (filterList, filterPos, index) =>
            UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return DATE_FILTER_LOGIC(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.lastlogout.label, CONSTS.FINISH_DATE)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.duration.id,
        label: CONSTS.COLUMNS.duration.label,
        options: {
          sortThirdClickReset: true,
          display: hiddenRows.duration,
          filter: false,
          customBodyRender: (value) =>this.durationCustomRender(value)
        }
      }
    ];
    return columns;
  };

  /**
  * Return table options
  */
  handleTableOptions = (data) => {
    const options = {
      searchText: this.searchBy,
      print: false,
      filter: true,
      filterType: CONSTS.FILTERTYPE,
      responsive: CONSTS.RESPONSIVE,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.ROWS_PER_PAGE,
      selectableRowsHeader: false,
      selectableRows: "none",
      customSort: (data, colIndex, order) => {
        let sortType = order=== CONSTS.DEFAULT_SORT? true:false;
        return  CONSTS.TABLE_SORT(data, colIndex, sortType);
      },
      downloadOptions: {
        filename: CONSTS.CSV_FILE_NAME,
        separator: CONSTS.CSV_SEPARATOR,
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true
        }
      },
      onTableChange:(action,tableState)=>{
       switch(action){
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({tableRowsPerPage: tableState.rowsPerPageOptions});
            break
          case CONSTS.TABLE_EVENTS.propsUpdate:
            if(this.filterBy.length!==0 && this.onFilter){
              tableState.filterList= this.filterBy;
            }
            break
          case CONSTS.TABLE_EVENTS.search:
            if(tableState.searchText){
              this.searchBy = tableState.searchText;
              this.onSearch = true;
            }
            break
          case CONSTS.TABLE_EVENTS.filterChange:
            this.filterBy = tableState.filterList;
            this.onFilter = true
            break
          case CONSTS.TABLE_EVENTS.onSearchClose:
            this.searchBy = '';
            this.onSearch = false;
            this.setState({isSearchClosed: true});
            this.handleChangeSwitch();
            break
          case CONSTS.TABLE_EVENTS.resetFilters:
            this.handleChangeSwitch();
            break
          case CONSTS.TABLE_EVENTS.viewColumnsChange:
            tableState.columns.forEach(col=>{
              if(this.state.hiddenRows.hasOwnProperty(col.name)){
                if(this.state.hiddenRows[col.name].toString()!== col.display){
                  let update = col.display==='true'? true:false;
                  this.setState(prevState =>({
                  hiddenRows:{
                    ...prevState.hiddenRows,
                    [col.name]: update}
                  }));
                }
              }
            });
            break
          default:
            break
       }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return CONSTS.CUSTOM_CSVDOWNLOAD(buildHead, buildBody, columns, data);
      },
      customToolbar: () => {
        return (
          <CustomToolbar
          handleChangeSwitch={this.handleChangeSwitch}
          table={CONSTS.TABLE_ID}
          data={data}
          filterChips={this.filterBy}/>
        );
      }
    };
    return options;
  };

  /**
  * Loads and formats data to be displayed on table
  */
  handleLoadTableData = () => {
    this.tableData = [];
    this.handleGetTeamComposition();
    const usersEnabled = this.handleGetUsersEnabled();
    const data = this.handleFillRowsWithData(usersEnabled);
    this.handleSortTableDefault(data);
    this.handleCheckIfFiltersApply();
    const columns = this.handleTableColumns();
    const options = this.handleTableOptions(data);
    return {columns, options}
  };

  /**
  * Returns table title with label, icon and format
  */
  handleRenderTableTitle = () => {
    const { classes } = this.props;
    return(
      <div className={classes.tableTitleDiv}>
          <div className={classes.tableTitleIconDiv}>
            <GroupIcon className={classes.tableTitleIcon}/>
          </div>
          <h2 className={classes.tableTitleLbl}>{CONSTS.TABLE_TITLE}</h2>
      </div>)
  };

  render() {
    const { openSnack, snackType, snackMsg, showSpinner, showTable } = this.state;
    const { open } = this.props;
    const {columns, options} = this.handleLoadTableData();

    let l= open? '23%': '4%';
    let w= open? '75.5%': '95%';
    return (
      <div
        id="webNavv-tableView-teamManager-1.0"
        style={{marginTop:'5%',marginLeft:l, width:w}}>
        {showTable && (
          <div id="webNavv-tableView-teamManager-teamManagerTable-1.0">
            <MasterTable
              title={this.handleRenderTableTitle()}
              data={this.data}
              columns={columns}
              options={options}
            />
          </div>
        )}
        {showSpinner && (
          <AwesomeSpinner id="webNavv-tableView-teamManager-spinner-1.0" config={CONSTS.SPINNER_CONFIG} loading={showSpinner} navOpen={open} />
        )}
        <div id="webNavv-tableView-teamManager-alert-1.0">
        <MasterAlert
          snackStyle={{marginTop:'5vh'}}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          openSnack={openSnack}
          handleClose={this.handleCloseSnackbar}
          alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
          elevation={6}
          variant={CONSTS.ALERT_VARIANT}
          type={snackType}
          message={snackMsg}
        />
        </div>
      </div>
    );
  }
}

TeamManager.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  changeView: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired,
  getPhones: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  forceLogout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  users: state.user.users,
  phones: state.phones.phones,
  acl: state.auth.acl,
  userId:state.auth.userId,
  user: state.user.user,
  socketChat:state.auth.socketChat,
  open:state.views.drawerOpen,
  teamComposition: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
  lastlocationSocket: state.lastlocation.lastlocationSocket
});

export default connect(mapStateToProps, {
  changeView,
  getPhones,
  listUsers,
  updateUser,
  forceLogout,
  createLog})(withStyles(styles)(TeamManager));