import React from 'react';
import { useStyles, CustomSwitch } from './phoneForm.styles';
import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MasterButton from '../../../utilities/button.component';

const PhoneForm = (props) => {
  const classes = useStyles();

  const handleRenderAssignTo = () => {
    let usersList = [];
    props.users.forEach(user => {
      let nameLbl = user.first_name + " " + user.last_name;
      usersList.push({ name: nameLbl, id: user._id });
    });

    return (
      <Autocomplete
        disableClearable
        disabled={!props.switchValue}
        style={{ zIndex: "998", width: '260px', marginLeft: '5%' }}
        id={"webNavv-tableView-fleetManager-assignToInput-1.0"}
        options={usersList}
        defaultValue={props.isUpdate ? (!_.isEmpty(props.assignInfo) && { name: `${props.assignInfo[0].first_name} ${props.assignInfo[0].last_name}`, id: props.assignInfo.id }) : null}
        getOptionLabel={(option) => option.name}
        onChange={props.handleChangeUser}
        renderInput={(params) => <TextField
          error={props.formValidation.assigned}
          helperText={props.formValidation.assigned ? 'Field cannot be empty!' : null}
          {...params}
          label={"Assign To"}
          variant='filled' />}
      />
    )
  };

  return (
    <div style={{ marginTop: '2%' }}>
      <FormControlLabel
        control={
          <CustomSwitch
            id="webNavv-tableView-fleetManager-deactivatePhone-1.0"
            checked={props.switchValue}
            onChange={props.handleChangeSwitch}
            name="enabledPhone"
            color="primary"
          />}
        label={<p style={{ fontSize: '14px', color: props.switchcolorLbl, fontWeight: 'bold' }}>ENABLE PHONE</p>}
      />
      <React.Fragment>
        <div id="webNavv-tableView-fleetManager-phoneAssignation-1.0">
          <h4 style={{ color: 'gray' }}>Phone Assignation</h4>
          <div style={{ display: 'flex' }}>
            <TextField
              error={props.formValidation.assetTag.error}
              helperText={props.formValidation.assetTag.error ? props.formValidation.assetTag.msg : null}
              disabled={!props.deviceOffline ? true : !props.switchValue}
              name="newAssetTag"
              style={{ width: '40%', marginLeft: '5%' }}
              id="webNavv-tableView-fleetManager-assetTagInput-1.0"
              label="Asset Tag #"
              variant="filled"
              value={props.phoneInfo.newAssetTag}
              onChange={props.inputChange}
            />
            <TextField
              error={props.formValidation.assetTag.error}
              helperText={props.formValidation.assetTag.error ? props.formValidation.assetTag.msg : null}
              disabled={true}
              name="newUUID"
              style={{ width: '40%', marginLeft: '5%' }}
              id="webNavv-tableView-fleetManager-identifierInput-1.0"
              label="Identifier"
              variant="filled"
              value={props.phoneInfo.newAssetTag}
              onChange={props.inputChange}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <TextField
              error={props.phoneNumError ? true : false}
              helperText={props.phoneNumError ? 'Incomplete phone number' : null}
              disabled={!props.switchValue}
              name="phoneNum"
              style={{ width: '40%', marginLeft: '5%' }}
              id="webNavv-tableView-fleetManager-phoneNumberInput-1.0"
              label="Phone Number"
              variant="filled"
              InputProps={{
                inputComponent: props.TextMaskCustom,
                value: props.textmask,
                onChange: props.handleChangePhoneNumber,
              }}
            />
            {handleRenderAssignTo()}
          </div>
          <div style={{ marginTop: '20px' }}>
            <TextField
              disabled={!props.switchValue}
              name="newLabel"
              style={{ width: '40%', marginLeft: '5%' }}
              id="webNavv-tableView-fleetManager-phoneLabelInput-1.0"
              label="Label"
              variant="filled"
              value={props.phoneInfo.newLabel}
              onChange={props.inputChange}
            />
          </div>
        </div>
        <div
          id="webNavv-tableView-fleetManager-phoneModels-1.0"
          style={{ marginTop: '30px' }}
        >
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: 'gray' }}>Phone Model</h4>
            {props.formValidation.model &&
              <div style={{ paddingTop: '21px', color: 'red', paddingLeft: '15px' }}>Model is required!</div>
            }
          </div>
          <div style={{ display: 'flex' }}>
            {props.handleRenderPhoneModelChips(props.switchValue)}
          </div>
        </div>
        <div
          id="webNavv-tableView-fleetManager-team-1.0"
          style={{ marginTop: '30px' }}>
          <div style={{ display: 'flex' }}>
            <h4 style={{ color: 'gray' }}>Team</h4>
            {props.formValidation.teams &&
              <div style={{ paddingTop: '21px', color: 'red', paddingLeft: '15px' }}>Team is required!</div>
            }
          </div>
          <div style={{ display: 'inline-block' }}>
            {props.handleRenderTeams(props.switchValue)}
          </div>
        </div>
      </React.Fragment>
      <div style={{ display: 'flex', marginTop: '5%' }}>
        <MasterButton
          id="webNavv-tableView-fleetManager-cancelButton-1.0"
          keyLbl={'cancel-phoneForm'}
          label={'Cancel'}
          buttonType={'default'}
          isDisabled={props.isActionInProgress}
          handleClick={props.handleCloseDialog}
          size={"medium"}
          styles={{ marginLeft: '370px' }}
        />
        <MasterButton
          id="webNavv-tableView-fleetManager-saveButton-1.0"
          keyLbl={'save-phoneForm'}
          label={'Save'}
          buttonType={'success'}
          isDisabled={props.isActionInProgress || props.disableSaveButton ? true : false}
          handleClick={props.handleSubmit}
          size={"medium"}
          class={classes.containedBtn}
          styles={{ marginLeft: '30px' }}
        />
      </div>
    </div>
  )
}

export default (PhoneForm);