import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AccessControl extends React.Component{

    validateAccess(){
        if(this.props.acl!=null && this.props.acl.views!=undefined){
            let access=this.props.acl.views.filter(v=>{
                //console.log(v.name,this.props.title,v.name===this.props.title,v.access)
                if(v.name===this.props.title && v.access==true)
                    return true;
                else return false;
            })
            if(access.length>0){
                /*let props = Object.assign({}, this.props);
                delete props.wrapped;
                delete props.acl;*/
                let result = <this.props.wrapped {...this.props}/>;
                return(
                    result
                )
            }
        }
    }

    render(){
        if (this.validateAccess()) {
            return(
                this.validateAccess()
            )
        } else {
            return null;
        }
        
    }
};
AccessControl.propTypes = {
    wrapped:PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    acl:PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    acl:state.auth.acl,
})

export default connect(mapStateToProps,{})(AccessControl);
