import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  userList = [];

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleEditUsers = () => {
    let userInfo={};
    this.props.selectedRows.data.map(row=>{
        userInfo = this.props.workerInfo.find(user=>user.id === this.props.data[row.dataIndex].id);
    });
    this.props.editUser(userInfo.id);
  };

  handleAction=(action)=>{
    let index = this.props.selectedRows.data[0].dataIndex;
    let data = this.props.data[index];

    switch (action) {
      case "Delete":
        this.props.showDeleteModal(data);
        break;
      case "Restore":
        this.props.showRestoreModal(data);
        break;
      default:
        break;
    }
  }


  render() {
    const { classes } = this.props;
    let index = this.props.selectedRows.data[0].dataIndex;
    let data = this.props.data[index];
    const { enabled } = data;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Deselect ALL"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll} >
            <IndeterminateCheckBoxIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        {this.props.selectedRows.data.length===1 &&
        <Tooltip title={"Edit account"}>
          <IconButton className={classes.iconButton} onClick={this.handleEditUsers} >
            <EditIcon className={classes.icon}/>
          </IconButton>
        </Tooltip>
        }
        <Tooltip title={enabled ? "Delete account" : "Restore account"}>
          <IconButton className={classes.iconButton} onClick={() => { this.handleAction(enabled ? "Delete" : "Restore") }}>
            {enabled ? <DeleteIcon className={classes.icon} /> : <RestoreIcon className={classes.icon} />}
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default (withStyles(defaultToolbarSelectStyles)(CustomToolbarSelect));