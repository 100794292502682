import moment from "moment";
import _ from "lodash";
import { jobStatusTypesGraphQL, MULTIPLE_ERRORS } from "../../jobsBoard.constants";


// export const handleUpdateJobStatus = async (slotJobs, status, jobStatusList, tenantId) => {
//   const currentStatus = jobStatusList.find(s => s.job_status === status);
//   // Set job status as assigned
//   slotJobs.map(job=>{
//     const UpdateAssociatedJob_Input = {
//       job_statusid: currentStatus.job_statusid,
//       jobid:  job.jobid,
//       tenantid: tenantId
//     }
//     handleUpdateCurrentJobStatus(UpdateAssociatedJob_Input);
//     return null;
//   });
// }

const formatUnassignedSlot = (item, initialSlotIndex) => {
  return {
    id: initialSlotIndex,
    title: "",
    assignedId: "",
    checked: false,
    template: false,
    assigned: false,
    canEdit: true,
    status: jobStatusTypesGraphQL.unassigned,
    resource_slotid: item.resource_slotid
  };
}

const formatAssignedSlot = (item, initialSlotIndex) =>{
  return {
    id: initialSlotIndex,
    title: item.assigned_user_name,
    assignedId: item.assigned_userid,
    checked: false,
    template: false,
    canEdit: true,
    assigned: !!item.assigned_user_name? true : false,
    status: !!item.assigned_user_name? jobStatusTypesGraphQL.assigned : jobStatusTypesGraphQL.unassigned,
    resource_slotid: item.resource_slotid
  };
}

const checkIfSlotHasNotStarted = (item) =>{
  return item.job_status === jobStatusTypesGraphQL.unassigned || item.job_status === jobStatusTypesGraphQL.assigned 
}

const formatJob = (item, initialJobIndex, slotIds, slotIdList) =>{
  const jobStartDate = moment(item.job_start_date).valueOf();
  const jobEndDate = moment(item.job_end_date).valueOf();
  const jobCanMove = checkIfSlotHasNotStarted(item) ? true : false;
  const jobUserName = checkIfSlotHasNotStarted(item) ? item.assigned_user_name : item.job_assigned_user_name;
  const jobUserId = checkIfSlotHasNotStarted(item) ? item.assigned_userid : item.job_assigned_userid;

  return {
    id: initialJobIndex,
    jobid: item.jobid,
    progress: item.job_percentage_complete,
    group: slotIds[slotIdList.indexOf(item.resource_slotid)],
    title: item.job_name,
    template: false,
    specimensRetrieved: 0,
    status: item.job_status,
    canMove: jobCanMove,
    start_time: jobStartDate,
    end_time: jobEndDate,
    job_start_date: item.job_start_date,
    job_end_date: item.job_end_date,
    job_started_date: item.job_started_date,
    job_ended_date: item.job_ended_date,
    resource_slotid: item.resource_slotid,
    job_assigned_user_name: jobUserName,
    job_assigned_userid: jobUserId,
    job_error_number: !!item.job_error_number ? item.job_error_number : null,
    job_errors: [],
    job_mobile_sessionid: item.job_mobile_sessionid,
    unstarted_taskid: item.unstarted_taskid,
    job_number: item.job_number,
    visible: true
  }
}

export const handleFormatSlotsByDateRange = (response) =>{
  let slots =[];
  let jobsCopy = [];
  let slotIdList = [];
  let slotIds = [];
  let jobIdList = [];
  let initialJobIndex = 10;
  let initialSlotIndex = 1;
  const slotsSorted = handleSortGroupsResponse(response);

  slotsSorted.forEach((row,i)=>{

    if (row.assigned_user_name === null && !slotIdList.includes(row.resource_slotid)) {  // Empty slot
      const slot = formatUnassignedSlot(row, initialSlotIndex);

      slots.push(slot);
      slotIdList.push(row.resource_slotid);
      slotIds.push(slot.id);
      initialSlotIndex++;
    }
    else if ( !slotIdList.includes(row.resource_slotid) ){
      const slot = formatAssignedSlot(row, initialSlotIndex);

      slots.push(slot);
      slotIdList.push(row.resource_slotid);
      slotIds.push(slot.id);
      initialSlotIndex++;
    }

    const jobExists = !!row.job_end_date && !!row.job_start_date ? true : false;

    if (jobExists){
      if (!jobIdList.includes(row.jobid)){
        const job = formatJob(row, initialJobIndex, slotIds, slotIdList);

        jobsCopy.push(job);
        jobIdList.push(row.jobid);
        initialJobIndex++;
      }
      else{
        const jobInListIndex = jobsCopy.findIndex(item=> item.jobid === row.jobid);
        if (jobsCopy[jobInListIndex].job_errors.length === 0){
          jobsCopy[jobInListIndex].job_errors = [jobsCopy[jobInListIndex].job_error_number];
        }
        jobsCopy[jobInListIndex].job_errors.push(row.job_error_number);
        jobsCopy[jobInListIndex].job_error_number = MULTIPLE_ERRORS;
      }
      
    }
    
  });
  
  const slotsCopy = handleCheckSlotStatus(slots,jobsCopy);

  return {slotsCopy, jobsCopy};
}

export const handleCheckSlotStatus = (slots, jobs) =>{
  const slotsCopy = [...slots];

  slots.forEach((slot,i)=>{
    const slotJobsInProgresss = jobs.filter(job=>job.resource_slotid === slot.resource_slotid && job.status === jobStatusTypesGraphQL.inProgress);
    const slotJobsCompleted = jobs.filter(job=>job.resource_slotid === slot.resource_slotid && job.status === jobStatusTypesGraphQL.complete);
    const slotJobsError = jobs.filter(job=>job.resource_slotid === slot.resource_slotid && job.status === jobStatusTypesGraphQL.error);
    const slotJobsInProgressWithErrors = jobs.filter(job=>job.resource_slotid === slot.resource_slotid && job.status === jobStatusTypesGraphQL.inProgressWithError);
    const slotJobsCompletedWithErrors = jobs.filter(job=>job.resource_slotid === slot.resource_slotid && job.status === jobStatusTypesGraphQL.completeWithError);

    if (slotJobsCompleted.length !== 0 || slotJobsError.length !== 0 || slotJobsCompletedWithErrors.length !== 0 ) {
      // For Closed / Error / Closed with error jobs
      slotsCopy[i].canEdit = false;
    }
    if (slotJobsInProgresss.length !== 0 || slotJobsInProgressWithErrors.length !== 0 ) {
      // For In Progress / In Progress with errors jobs
      slotsCopy[i].status = jobStatusTypesGraphQL.inProgress;
      slotsCopy[i].canEdit = false;
    }
  });

  return slotsCopy;
}



const handleSortGroupsResponse = (response) =>{
  return response.sort((a, b)=> {
    if (a.assigned_user_name === b.assigned_user_name) return 0
    else if (a.assigned_user_name === null) return 1
    else if (b.assigned_user_name === null) return -1
    else  return a.assigned_user_name.localeCompare(b.assigned_user_name) * 1
  });
}

