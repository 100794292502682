/* eslint-disable no-undef */
import {
  GET_PHONES,
  GET_PHONE,
  GET_ALL_PHONES,
  GET_PUBNUB_SUBSCR,
  PHONE_TO_UPDATE,
  CREATE_PHONE_COMMENT,
  GET_PHONE_COMMENTS,
  CLEAN_PHONE_COMMENTS,
  DUPLICATED_PHONE } from './types';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

axiosRetry(axios, {
  retries: 3,
  retryDelay: (count) => {
    console.error(`[PHONE ACTIONS] numbers of attempts: ${count}`);
    return count * 2000;
  }
});

export const getPhoneInfo = (userId) => (dispatch, getState)=> {
  return new Promise((resolve,reject)=>{
    try{
      let campusId = getState().auth.userInfo.campusId;
      if (!!campusId && !!userId) {
        axios.get(secureUrl.hostUrl + '/api/Phones/getPhoneInfoByCampus?campusId=' + encodeURIComponent(`${campusId}`) +'&userId=' + encodeURIComponent(`${userId}`) + '&access_token=' + getState().auth.token)
        .then(async resp => {
          if (resp.data.phone.length > 0){
            dispatch({
              type: GET_PHONE,
              payload: resp.data.phone[0],
              userId: userId
            });
            resolve({success:true,phone:resp.data.phone});
          }else{
            resolve({success:true,phone:null});
          }
        });
      }
    }catch(error){
      console.error("[PHONE ACTIONS] An error ocurred in getPhoneInfo: ", error);
      reject(error);
    }
  });
};

export const getPhones = () => (dispatch, getState)=> {
  return new Promise((resolve,reject)=>{
    try{
      let campusId = getState().auth.userInfo.campusId;
      if (!!campusId) {
        axios.get(secureUrl.hostUrl + '/api/Phones/getPhonesByCampus?campusId=' + encodeURIComponent(`${campusId}`) + '&access_token=' + getState().auth.token)
        .then(async resp => {
          //let checkList = await setCheckList(resp.data.phones);
          if (!!resp.data.phones && resp.data.phones.length > 0){
            dispatch({
              type: GET_PHONES,
              payload: resp.data.phones
            });
            resolve({success:true,phone:resp.data.phones})
          }else{
            resolve({success:true,phone:null})
          }
        });
      }
    }catch(error){
      console.error("[PHONE ACTIONS] An error ocurred in getPhones: ", error);
      reject(error);
    }
  });
};

export const checkDuplicatePhone = (UUID) => (dispatch, getState)=> {
  return new Promise((resolve,reject)=>{
    try {
      let lowercase = UUID.toLowerCase();
      let uppercase = UUID.toUpperCase();
      axios.get(`${secureUrl.hostUrl}/api/Phones?filter={"where":{"UUID":"${lowercase}"}}&access_token=${getState().auth.token}`)
      .then(async resp => {
        if (resp.data.length === 0) {
  
          axios.get(`${secureUrl.hostUrl}/api/Phones?filter={"where":{"UUID":"${uppercase}"}}&access_token=${getState().auth.token}`)
          .then(async resp => {
            if (resp.data.length === 0) {
              dispatch({
                type: DUPLICATED_PHONE,
                payload: false
              });
              resolve({duplicate: false, id: ''});
            } else {//Duplicate!!
              dispatch({
                type: DUPLICATED_PHONE,
                payload: true
              });
              resolve({duplicate: true, id: resp.data[0].id});
            }
          }).catch(err=>{
            reject(err);
          });
          
        } else { //Duplicate!!
          dispatch({
            type: DUPLICATED_PHONE,
            payload: true
          });
          resolve({duplicate: true, id: resp.data[0].id});
        }
      })
    }catch(error){
      console.error("[PHONE ACTIONS] An error ocurred in checkDuplicatePhone: ", error);
      reject(error);
    }
  })
};

export const getAllPhones = () => (dispatch, getState)=> {
  return new Promise((resolve,reject)=>{
    try{
      axios.get(secureUrl.hostUrl + '/api/Phones')
      .then(async resp => {
          dispatch({
            type: GET_ALL_PHONES,
            payload: resp.data
          });
          resolve({success:true});
      })
    }catch(error){
      console.error("[PHONE ACTIONS] An error ocurred in getAllPhones: ", error);
      reject({success:false});
    }
  })
};


export const getSubscr = (susb) => dispatch => {
  dispatch({
    type: GET_PUBNUB_SUBSCR,
    payload: susb
  });
};

export const phoneToUpdateInfo = (phone) => dispatch => {
  dispatch({
    type: PHONE_TO_UPDATE,
    payload: phone
  });
};

export const sendComment = (comment) => (dispatch, getState) => {
  try{
    axios.post(secureUrl.hostUrl + '/api/phone_comments', comment)
    .then(resp=>{
      let comments = getState().phones.phoneComments;
      comments.push(resp.data);
      dispatch({
        type: CREATE_PHONE_COMMENT,
        payload: comments
      });
    })
  }catch(error){
    console.error("[PHONE ACTIONS] An error ocurred in sendComment: ", error);
  }
};

export const getCommentsByPhone = (phoneId, token) =>dispatch=>{
  try{
    axios.get(secureUrl.hostUrl + `/api/phone_comments/commentsByPhone?phoneId=${phoneId}&access_token=${token}`)
    .then(async resp => {
      if (resp.data.response.length > 0)
        dispatch({
          type: GET_PHONE_COMMENTS,
          payload: resp.data.response
        });
    })
  }catch(error){
    console.error("[PHONE ACTIONS] An error ocurred in getCommentsByPhone: ", error);
  }
};

export const cleanCommentsByPhoneList = () =>dispatch=>{
  dispatch({
    type: CLEAN_PHONE_COMMENTS,
    payload: []
  });
};