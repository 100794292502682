import {
    updateResource_Slot,
    updateResource_SlotUnassignUser,
    updateAssociatedJob,
    createResource_Slot,
    deleteResource_Slot,
    deleteResource_SlotJobs,
    deleteAssociatedJob,
    updateJob_Inerror,
    createAssociatedJob} from "../../../graphql/mutations";
import { API } from 'aws-amplify';

export const mutationCreateAssociatedJob = async (CreateAssociatedJob_Input) =>{
    const data = {query: createAssociatedJob, variables:{ input: CreateAssociatedJob_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - createAssociatedJob]",  apiData.data.createAssociatedJob);
            resolve(true);
        }catch(err){
            console.log("[ Mutation - createAssociatedJob] couldn't create a job from template:", err);
            reject(false);
        }
    });
}

export const mutationUpdateJobInError = async (UpdateJob_Error) =>{
    const data = {query: updateJob_Inerror, variables:{ input: UpdateJob_Error}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - updateJob_Inerror]",  apiData.data.updateJob_Inerror);
            resolve(true);
        }catch(err){
            console.log("[Mutation - updateJob_Inerror] couldn't cancel job:", err);
            reject(false);
        }
    });
}

export const mutationUpdateResourceSlot = async (resource_slotid, assignedTo) =>{
    const UpdateResource_Slot_Input = {
        resource_slotid: resource_slotid,
        assigned_userid: assignedTo
    };
    const data = {query: updateResource_Slot, variables:{ input: UpdateResource_Slot_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - updateResource_Slot]",  apiData.data.updateResource_Slot);
            resolve(true);
        }catch(err){
            console.log("[Mutation - updateResource_Slot] couldn't assign user to slot:", err);
            reject(false);
        }
    });
}

export const mutationUpdateResourceSlotUnassignUser = async(resource_slotid) =>{
    const UpdateResource_Slot_UnassignUserInput = {
        resource_slotid: resource_slotid
    };
    const data = {query: updateResource_SlotUnassignUser, variables:{ input: UpdateResource_Slot_UnassignUserInput}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - updateResource_SlotUnassignUser]",  apiData.data.updateResource_SlotUnassignUser);
            resolve(true);
        }catch(err){
            console.log("[Mutation - updateResource_SlotUnassignUser] couldn't unassign slot:", err);
            reject(false);
        }
    });
}

export const mutationUpdateAssociatedJob = async (UpdateAssociatedJob_Input) =>{
    const data = {query: updateAssociatedJob, variables:{ input: UpdateAssociatedJob_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - updateAssociatedJob]",  apiData.data.updateAssociatedJob);
            resolve(true);
        }catch(err){
            console.log("[Mutation - updateAssociatedJob] couldn't update job:", err);
            reject(false);
        }
    });
}

export const mutationCreateResourceSlot = async (CreateResource_Slot_Input) =>{
    const data = {query: createResource_Slot, variables:{ input: CreateResource_Slot_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - createResource_Slot]",  apiData.data.createResource_Slot);
            resolve(true);
        }catch(err){
            console.log("[Mutation - createResource_Slot] couldn't add a slot:", err);
            reject(false);
        }
    });
}

export const mutationDeleteResourceSlot = async (DeleteResource_Slot_Input) =>{
    const data = {query: deleteResource_Slot, variables:{ input: DeleteResource_Slot_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - deleteResource_Slot]",  apiData.data.deleteResource_Slot);
            resolve(true);
        }catch(err){
            console.log("[Mutation - deleteResource_Slot] couldn't delete slot:", err);
            reject(false);
        }
    });
}

export const mutationDeleteResourceSlotJobs = async(DeleteResource_Slot_Input) =>{
    const data = {query: deleteResource_SlotJobs, variables:{ input: DeleteResource_Slot_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - deleteResource_SlotJobs]",  apiData.data.deleteResource_SlotJobs);
            resolve(true);
        }catch(err){
            console.log("[Mutation - deleteResource_SlotJobs] couldn't clear jobs in slot:", err);
            reject(false);
        }
    });
}

export const mutationDeleteAssociatedJob = async(DeleteJob_Input) =>{
    const data = {query: deleteAssociatedJob, variables:{ input: DeleteJob_Input}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Mutation - deleteAssociatedJob]",  apiData.data.deleteAssociatedJob);
            resolve(true);
        }catch(err){
            console.log("[Mutation - deleteAssociatedJob] couldn't delete associated job:", err);
            reject(false);
        }
    });
}