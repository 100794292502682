import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TimelineItem} from "@material-ui/lab";

export const CustomTimelineItem = withStyles((theme) => ({
    missingOppositeContent:{
        "&:before": {
            flex: "none !important"
        }
    },
}))(TimelineItem);

export const useStyles = makeStyles((theme) => ({
    RouteInfoCard_stepperElement: {
        border: "none !important"
    },
    RouteInfoCard_stepperContent:{
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        color: "#2843A3",
    },
    RouteInfoCard_stepperLabel:{
        fontWeight: "700 !important",
        fontSize: "17px !important",
        lineHeight: "26px !important",
        letterSpacing: "0.2px !important",
        color: "#000000 !important",
    },
    RouteInfoCard_stepperFloor:{
        position: "absolute",
        right: "95%",
        top: "10%",
        height: "30px",
        width: "38px",
        textAlign: "center",
        borderRadius: "3px",
        paddingTop: "5px",
        fontWeight: "bold"
    },
    RouteInfoCard_stepperFloorNA:{
        position: "absolute",
        right: "95%",
        top: "10%",
        height: "30px",
        width: "35px",
        textAlign: "center",
        borderRadius: "3px",
        paddingTop: "5px",
        color: "white"
    },
    RouteInfoCard_timelineFloor:{
        marginTop: "3px",
        position: "absolute",
        right:"95%"
    },
    RouteInfoCard_timelineDot:{
        backgroundColor: "transparent",
        boxShadow: "none !important"
    }
}));