import React from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import './directContacts.component.css';

import { createMuiTheme, MuiThemeProvider, createGenerateClassName } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import SearchIcon from '@material-ui/icons/Search';
import MUIDataTable from "mui-datatables/dist/index";

import AwesomeSpinner from "../../utilities/spinner";
import PropTypes from "prop-types";
import { changeView } from "../../../actions/views/viewsActions";
import { registerLastLocation, detailsContact, setDetailsName } from '../../../actions/contacts/contactActions';
import { connect } from "react-redux";

import { dateFormat } from '../contactUtils';

import _ from "lodash";

const TABLE_SORT = (data, colIndex, order) => {
  if (colIndex === 0 || colIndex === 2 || colIndex === 6) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  }
};

class DirectContatcs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: this.props.allContacts,
      filterBtn: null,
      showSpinner: true
    };
  }
  startLoop = true;
  elements = ["default", "hidden"];
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "635px !important",
          },
          paper: {
            boxShadow: "none",
            left: "0%",
            right: "0%",
            top: "0%",
            bottom: "-5.17%",
            background: "#FFFFFF",
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: "4px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: "1%",
          },
          toolButton: {
            display: "flex",
            justifyContent: "center",
            color: "#7D7D7D",
          },
          sortActive: {
            color: "#666 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            border: "none",
            color: "#666 !important",
            padding: "13px 25px 13px 25px !important",
            textAlign: "center",
          },
        },
        MuiTablePagination: {
          toolbar: {
            color: "#666",
          },
        },
        MuiPaper: {
          root: {
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            borderRadius: "4px",
            boxShadow: "none !important",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundColor: "#fff",
          },
        },
      },
    });

  componentDidMount = () => {
  };

  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  };

  handleRenderLastLocation = (value) => {
    let lastGeolocation={
            latitude: value.latitude,
            longitude: value.longitude,
            floorOrdinal: value.floor,
            userLastLocationDate: value.date,
            id: value.id,
            name: value.name,
            date: value.date
          };
    this.props.registerLastLocation(lastGeolocation)
    return (
      <Tooltip>
        <Button variant="contained" color="primary"
            onClick={() => {this.handleChangeToMap(lastGeolocation)}}>
            {value.unitName}
        </Button>
      </Tooltip>
    );
  };

  handleChangeToMap = (location) =>{
    this.props.changeView("map", "contact_location");
  };

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };


  handleClick = (e) => {
    const btnClick = e.target.value;

    if(btnClick === this.state.filterBtn){
        this.setState( { filterBtn : null } )
     }else {
        this.setState( {filterBtn : btnClick} )
     }
}

  render() {
    let data = [];
    const { allContacts } = this.props
      Object.keys(allContacts).map((key) =>{
      let name='';
       this.props.names.forEach(item=>{ 
        if(item.id===key){
          name=item.name;
        }
      })
      const date = new Date(allContacts[key].dateTime);
      const dateexposed = dateFormat(date);
      let location = {};

      if(allContacts[key].location) {
        location.latitude = allContacts[key].location[0];
        location.longitude = allContacts[key].location[1];
        location.floor = allContacts[key].floor;
        location.date = allContacts[key].dateTime;
        location.id = key;
        location.name = name;
        location.unitName = 'Locate';
      }
      data.push({
        id: key,
        name,
        contactlevel: allContacts[key].contactLevel[0],
        lastcontact: dateexposed,
        location,
        totalexposure: `${allContacts[key].contactTime/60} min`,
        totalcontacts: allContacts[key].totalContacts
      });

      this.showTable = true;
      if(this.state.showSpinner){
        this.setState({showSpinner: false});
      }
    });



    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `ID: ${v}` }
        }
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Name: ${v}` }
        }
      },
      {
        name: "contactlevel",
        label: "Contact Level",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Contact Level: ${v}` }
        }
      },
      {
        name: "lastcontact",
        label: "Last Contact",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Last Contact: ${v}` }
        }
      },
      {
        name: "totalexposure",
        label: "Total Exposure",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Total Exposure: ${v}` }
        }
      },{
        name: "totalcontacts",
        label: "Total Contacts",
        options: {
          filter:false,
          customFilterListOptions: { render: v => `Total Exposure: ${v}` }
        }
      },
    ];
    const goBack = () =>{
      this.props.changeView('contact')
    }
    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 30, 50, 100],
      selectableRowsHeader: false,
      selectableRows: "none"
      ,search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        return TABLE_SORT(data, colIndex, order);
      },
      onRowClick: (rowData,rowMeta) => {
        this.props.detailsContact(rowData[0],this.props.user.userId);
        this.props.setDetailsName(rowData[1]);
        this.props.changeView('details_contact')
      },
    };
    let l= this.props.open? '23%': '4%';
    let w= this.props.open? '75.5%': '95%';

    return (
      <div style={{marginTop:'5%',marginLeft:l, width:w}}>
        <div style={{'marginTop':'56px'}}>
          {this.showTable && (
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable title={title(this.props.user, data, this.handleClick, this.state.filterBtn)} data={this.state.filterBtn === null ? data : data.filter(element => element.contactlevel === this.state.filterBtn)} columns={columns} options={options} />
            </MuiThemeProvider>
          )}
          <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open}/>
        </div>
      </div>
    );
  }
}

const title = (user, data, handleClick, filterBtn) => {
  return(
  <div className="card">
    <div className="users">
      <h1 className="user-name">{user.name}</h1>
      <h3 className="user-id">ID: {user.userId}</h3>
    </div>
    <div className="contact-level">
      <div className="direct-space">
        <button  className={filterBtn !== null && filterBtn === "Direct" ? "direct" : "defaultDirect" } value="Direct" onClick={handleClick}> 
          <div className={filterBtn !== null && filterBtn === "Direct" ? "select-red" : "noSelect-red" }/>
          Direct Contacts
        </button>
      </div>
      <div className="secondary-space">
        <button className={filterBtn !== null && filterBtn === "Secondary" ? "secondary" : "defaultSecondary"} value="Secondary" onClick={handleClick}> 
          <div className={filterBtn !== null && filterBtn === "Secondary" ? "select-yellow" : "noSelect-yellow" }/>
          Secondary Contacts
        </button>
      </div>
      <div className="tertiary-space">
        <button className={filterBtn !== null && filterBtn === "Tertiary" ? "tertiary" : "defaultTertiary"} value="Tertiary" onClick={handleClick}> 
          <div className={filterBtn !== null && filterBtn === "Tertiary" ? "select-gray" : "noSelect-gray" }/>
          Tertiary Contacts
        </button>
      </div>
    </div>
      <div className="dates">
        <p className="date"></p>
        <p className="date-filter"></p>
      </div>  
  </div>)
};

DirectContatcs.propTypes = {
  contacts: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  contacts: state.user.contacts,
  open:state.views.drawerOpen,
});

export default connect(mapStateToProps,{changeView, registerLastLocation, detailsContact,setDetailsName})(withRouter(DirectContatcs));