export const DATE_FORMAT="MM/DD/YYYY HH:mm:ss";
export const FILTER_INPUTS=['deviceCategory','controlNum','model','maker','team','department'];
export const MAP ='map';
export const PREVIEWEQUIPMENT='previewEquipment';
export const DEFAULT_FLOOR_COLOR ='#757575';
export const KIOLOC ='kioLoc';
export const FLOOR='floor';
export const LOCATION='location';
export const ROOMID='roomId';
export const POINT='point';
export const TIMESTAMP='timestamp';
export const EQUIPMENT='Equipment';
export const CANCEL='Cancel';
export const APPLY='Apply'

export const TABLE_OPTIONS={
    filterType:'dropdown',
    responsive:'standard',
    responsiveMain:'standard',
    selectableRows:'none',
    rowsPerPage: 5,
    rowsPerPageOptions:[5, 10, 30, 50]
};

export const MAIN_COLUMNS={
    deviceCategory:{
        no:0,
        id: 'deviceCategory',
        label: 'Device Category'
    }
};

export const INNER_COLUMNS={
    details:{
        no:0,
        id: 'id',
        label: 'Details'
    },
    deviceCategory:{
        no:1,
        id:'deviceCategory',
        label:'Device Category'
    },
    find:{
        no:2,
        id:'find',
        label: 'Find'
    },
    controlNum:{
        no:3,
        id:'controlNum',
        label:'Control Number'
    },
    location:{
        no:4,
        id:'location',
        label:'Location'
    },
    tagNumber:{
        no:5,
        id:'tagNumber',
        label:'Tag Number'
    },
    floor:{
        no:6,
        id:'floor',
        label:'Floor'
    },
    timestamp:{
        no:7,
        id:'timestamp',
        label:'Timestamp'
    },
    model:{
        no:8,
        id:'model',
        label:'Model'
    },
    maker:{
        no:9,
        id:'maker',
        label:'Maker'
    },
    team:{
        no:10,
        id:'team',
        label:'Team'
    },
    department:{
        no:11,
        id:'department',
        label:'Department'
    }
}

export const  TABLE_SORT = (data, colIndex, order) => {
    if (colIndex === 7) {
      return data.sort((a, b) => {
        return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order === "desc" ? -1 : 1);
      });
    } else {
      return data.sort((a, b) => {
        return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? -1 : 1);
      });
    }
};