import React from 'react';
import {useStyles, CustomSwitch} from './styles';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TimelineIcon from '@material-ui/icons/Timeline';

function TrackingOption(props){
    const classes = useStyles();
    let trackRole = false;
    let teamInfo = props.roles.find(role=> role._id === props.teamSelected) || {};
    if(!_.isEmpty(teamInfo)) {
        if(!!teamInfo.track)
          trackRole = teamInfo.track;
        else
          trackRole= false;
    }
    return(
    <div id="webNavv-adminPanel-accessControl-accessManager-trackPermission-1.0">
      <Box textAlign='left' bgcolor='white'>
        <div className={classes.blockContainer}>
            <div className={classes.showAccessIconContainer}>
            <TimelineIcon className={classes.roleIcon}/>
            </div>
            <div className={classes.showAccessDescContainer}>
                <div className={classes.showAccesItemName}>
                    <div style={{color: '#2843A3'}}>Show Team History</div>
                </div>
                <div className={classes.blockContainer}>
                    <p className={classes.showAccessDesc}>Shows the team's geolocation info in History Table.</p>
                </div>
            </div>
            <div className={classes.showAccessPermission}>
            {props.teamSelected && props.editTeam &&
            <CustomSwitch
                checked={props.trackRole || false}
                onChange={()=>props.handleChangeTrackStatus()}
                name="EditTrack"
            />
            }
            {!props.teamSelected && props.showTeamForm &&
            <CustomSwitch
                checked={props.trackRole || false}
                onChange={()=>props.handleChangeTrackStatus()}
                name="NewTeamTrack"
            />
            }
            {props.teamSelected && !props.editTeam && trackRole &&
                <CheckIcon className={classes.showAccessCheckIcon}/>
            }
            {props.teamSelected && !props.editTeam && !trackRole &&
                <CloseIcon className={classes.showAccessCloseIcon}/>
            }
            {!props.showTeamForm && !props.teamSelected && !props.editTeam &&
            <CustomSwitch
                checked={false}
                name="DisabledTrack"
            />
            }
            </div>
        </div>
      </Box>
    </div>
  );
};

export default TrackingOption;