import moment from 'moment';
import {REMOVE_JOB_ROW, JOB_TEMPLATES_ROW, TIMEOFFSET, TIMEZONE} from '../jobsBoard.constants'


export const handleSortRowsByUserName = (groups) =>{
  return groups.sort((a, b)=> !a.title - !b.title || a.title.localeCompare(b.title));
}

export const handleUpdateGroupIdPosition = (groups, editBoard) =>{
  let index = editBoard? 0 : 1;
  groups.forEach(group=>{
    group.id = index;
    index++;
  });
  return groups;
}

export const handleFormatUsersList = (users, groups) =>{
    let usersCopy = [];
    users.map(user =>{
      let disabled = false;
      if (groups.find(group => group.assignedId === user.userid)) {
        disabled = true;
      }
      let userInfo = {
        name: `${user.first_name} ${user.last_name}`,
        team_name: user.team_name,
        teamid: user.teamid,
        userid: user.userid,
        disabled: disabled
      }
      usersCopy.push(userInfo);
      return null;
    });
    usersCopy.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    return usersCopy;
}

export const handleModalLabels = (type, groupId) =>{
    switch(type){
      case "clear":
        return ({
          message: `This will clear all the jobs in the selected row(s)`,
          buttonLabel: "Clear"
        })
      case "cancel":
        return({
          message: `This will end ${groupId} job`,
          buttonLabel: "Ok"
        })
      default:
        return({
          message: "This will delete the selected row(s)",
          buttonLabel: "Delete"
        })
    }
}

export const handleModalButtonAction = (actionBtnLabel, handleClearRow, handleDeleteRow, handleCancelJob) =>{
    switch(actionBtnLabel){
      case "Clear":
        handleClearRow();
        break;
      case "Delete":
        handleDeleteRow();
        break;
      case "Ok": //Cancel Job
        handleCancelJob();
        break;
      default:
        break;
    }
}

export const getElementPosition = (el) =>{
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

export const handleAssignPositionToUsersList = (buttonId) =>{
    let itemElement = document.getElementById(buttonId);
      if (itemElement) {
        const {left, top} = getElementPosition(itemElement);
        return { top: `${+top + 40}px`, left:`${+left + 140}px`, visibility: "visible" };
      }
      else{
        return {}
      }
}

export const hideUsersListElement = () =>{
    let card = document.getElementById("webNavv-workflowAutomation-jobsBoard-assignMenu-1.0");
    card.style.visibility = "hidden";
}

export const handleCheckStatusAccordingToCheckBtn = (key, groups, handleUpdateGroupCheckStatus) =>{
    const position = groups.findIndex(group => group.id === key);
    let showButtons = true;
    let copyList = [...groups];
    
    if (groups[position].checked === true) {
        copyList[position].checked = false;
    } else {
        copyList[position].checked = true;
    }

    const checkSelectedItem = copyList.find(group=>group.checked === true) || null;
    if (checkSelectedItem === null){
      showButtons = false;
    }

    handleUpdateGroupCheckStatus(copyList);
    return showButtons;
}

export const handleGroupIdArrayAccordingToCheckBtn = (key, showButtons, currentGroupId) =>{
    let currentKeys = currentGroupId;
    if (!currentKeys.includes(key)) {
        currentKeys.push(key);
    }
    if (!showButtons) {
        currentKeys = currentGroupId.filter(groupId => groupId !== key);
    }
    return currentKeys;
}


export const handleAddDeleteRowToGroups = (groups) => {
  return [...REMOVE_JOB_ROW, ...groups];
}

export const handleAddJobTemplatesRowToGroups = (groups) =>{
  return [...JOB_TEMPLATES_ROW, ...groups];
}

export const handleRemoveFirstRowFromGroups = (groups) => {
  let groupsCopy = [...groups];
  groupsCopy.shift();

  return groupsCopy;
}

export const handleAddJobTemplatesToItems = (jobTemplates, items) =>{
  return [...jobTemplates, ...items];
}

export const handleRemoveJobTemplatesFromItems = (jobTemplates, items) =>{
  const slicedItems = items.slice(jobTemplates.length);
  return slicedItems;
}

export const handleLoadJobTemplates = (jobTemplates, calendarStartDate) =>{
  const arrayOfTemplates = [];
  let newStartTime = moment(calendarStartDate).add(20, 'minutes');
  jobTemplates.forEach((template, index)=>{
    const startTime = newStartTime;
    const endTime = moment(startTime).add(template.job_duration,"minutes");
    newStartTime = moment(endTime).add(30,"minutes");

    let job = {
      id: index,
      group: 0,
      title: template.job_template_name,
      template: true,
      progress: 0,
      status: "Unassigned",
      canMove: true,
      start_time: moment(startTime).valueOf(),
      end_time: moment(endTime).valueOf(),
      duration: template.job_duration,
      templateid: template.job_templateid,
      description: template.description,
      visible: true
    }
    arrayOfTemplates.push(job);
  });

  return arrayOfTemplates;
}

export const handleGetCurrentTeam = (teamsList, currentUserContacts, contacts, currentTeam) =>{
  const currentContactsMongoDB = [];
  const currentContactsGraphQL = [];
  contacts.forEach(contact=>{
    if (currentUserContacts.includes(contact._id)){
      currentContactsMongoDB.push(contact);
    }
  });
  
  currentContactsMongoDB.forEach(contact=>{
    const teamInGraphQL = teamsList.find(team=> team.team_name === contact.role);
    if(!!teamInGraphQL){
      currentContactsGraphQL.push(teamInGraphQL);
    }
  });
  const currentUserTeamGraphQL = teamsList.find(team=> team.team_name === currentTeam);

  return {currentContactsMongoDB, currentContactsGraphQL, currentUserTeamGraphQL}
}

export const formatRangeDatesWithOffsetAndTimezone = (startDate, endDate) =>{
  let maxDate = null;
  let minDate = null;

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (browserTimeZone !== 'America/New_York') {

    if (TIMEOFFSET < 0) {
      let startWithTimeOffset = moment(startDate).subtract(Math.abs(TIMEOFFSET), "hours");
      let endWithTimeOffset = moment(endDate).subtract(Math.abs(TIMEOFFSET), "hours");

      minDate = moment(startWithTimeOffset).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
      maxDate = moment(endWithTimeOffset).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    else {
      let startWithTimeOffset = moment(startDate).add(TIMEOFFSET, "hours");
      let endWithTimeOffset = moment(endDate).add(TIMEOFFSET, "hours");

      minDate = moment(startWithTimeOffset).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
      maxDate = moment(endWithTimeOffset).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
    }

  }
  else {
    minDate = moment(startDate).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
    maxDate =moment(endDate).utcOffset(TIMEZONE).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  return {minDate, maxDate};
}