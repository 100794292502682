export const MAP = ()=>{
    window.mappedin = {
        miKey:{
            id:"601c2371fac662001be42493",
            key:"raW1AeQ5WikKMflCbX5GAozTA0kcGsgl99HUIJHr1B6wwgoG"
        },
        searchKey:{
            id:"ohNgugiephah9Zeefee4noe3quo9iema",
            secret:"AChoh2AeShee9ohm"
        }
    }
}