import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import Subheader from "@material-ui/core/ListSubheader";

import {filterStyles} from '../styles';
import * as CONSTS from "./filters.constants";
import "./filters.css";

export const CustomCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: "#2196F3"
    }
  },
  checked: {}
}))(Checkbox);

const TrackUserFilters = (props) =>{
    return(
    <div id="webNavv-tableView-teamManager-trackUserFilters-1.0">
        <Subheader >{CONSTS.FILTERS}</Subheader>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.CURRENT_USER}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackUserFilters-currentUserOnly-1.0"
              onChange={props.handleActiveUser}
              checked={props.activeUser}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.SHOW_TRACES}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackUserFilters-showTraces-1.0"
              onChange={props.handleTraces}
              checked={props.traces}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.ALL_CALLOUTS}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-tableView-teamManager-trackUserFilters-allCallouts-1.0"
              onChange={props.handleCallouts}
              checked={props.allCallouts}/>
          </div>
        </div>
        {props.allCallouts &&
          <div className='Filters_Container'>
            <div className='Filters_Text' >{CONSTS.MINIMIZE_CALLOUT}</div>
            <div className='Filters_Checkbox'>
              <CustomCheckbox
                id="webNavv-tableView-teamManager-trackUserFilters-minimizeCallouts-1.0"
                onChange={props.handleSmallCallouts}
                checked={props.smallCallouts}/>
            </div>
          </div>
        }
    </div>
    );
}


export default withStyles(filterStyles)(TrackUserFilters);