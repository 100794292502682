import { makeStyles } from '@material-ui/core/styles';
import lightBlue from "@material-ui/core/colors/lightBlue";

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display:'flex',
    flexDirection:'row',
    marginTop: '10px'
  },
  problemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  },
  msg: {
    display: 'inline',
    color: 'white'
  },
  msgBold: {
    display: 'inline',
    fontWeight: 'bold'
  },
  createRotationDiv: {
    display: 'inline',
    paddingTop: '10px',
    paddingLeft: '30px',
    fontSize: '14px'
  },
  filesBtnDiv: {
    display: 'block',
    marginLeft: '35px',
    marginTop: '4px',
    justifyContent: 'center'
  },
  editDiv: {
    display: 'block',
    marginTop: '10px'
  },
  previewMapDiv: {
    marginLeft: '20px',
    height: '65vh',
    width: '70vh'
  },
  newBtnDiv: {
    display: 'flex',
    marginTop: '30px',
    justifyContent: 'flex-end'
  },
  editBtnDiv: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  mapHeaderDiv: {
    width: '50%',
    padding: '10px'
  },
  addHospitalDiv: {
    display: 'inline',
    marginLeft: '100px',
    marginTop: '10px'
  },
  titleLbl: {
    color:'rgba(0, 0, 0, 0.6)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    marginLeft: '10px',
    fontSize: '18px'
  },
  iconTitle: {
    color:'rgba(0, 0, 0, 0.6)',
    fontSize: '30px',
    marginTop: '15px'
  },
  headerDiv: {
    display: 'flex',
    padding: '10px'
  },
  mainDiv: {
    width: '40%',
    padding: '10px'
  },
  container: {
    backgroundColor: 'white',
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
    height: '87vh'
  },
  alert: {
    backgroundColor: '#2843A3',
    borderBottom: '7px solid #B5C5FF'
  },
  containedBtn: {
    marginBottom: '5px',
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  addCampus: {
    fontSize: '12px',
    marginTop: '10px',
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  Btnlist: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  BtnUp: {
    color: '#8bc34a'
  },
}));
