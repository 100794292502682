import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './users.component.css';

import FlatButton from "material-ui/FlatButton";
import TextField from "@material-ui/core/TextField";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from 'material-ui/svg-icons/content/add';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PersonIcon from '@material-ui/icons/Person';

import AwesomeSpinner from '../../utilities/spinner';
import CustomToolbarSelect from './toolbarSelect.component';
import CustomToolbar from '../../utilities/refreshBtn.component';
import { listUsers, deleteUser, setAccountStatus, getAllUsers } from '../../../actions/user/userActions';
import { createLog } from '../../../actions/logs/logsActions';
import { styles } from './users.styles';
import MasterTable from '../../utilities/table.component';
import MasterAlert from '../../utilities/alert.component';
import moment from "moment";
import {
  FORMAT_FILTER_LABELDATES,
  DATE_FILTER_LOGIC,
  UPDATE_FILTERS,
  STRING_FILTER_LOGIC
} from "../../utilities/common_tableFilters";
import * as CONSTS from "./users.constants";
import AddUsers from "./addUsers/addUsers.component";
import ModalProp from '../../utilities/modal.component';

//from here
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}
//to here

class UsersControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: CONSTS.DEFAULT_SORT,
      orderBy: CONSTS.FIRST_NAME,
      open: false,
      openModal: false,
      data: [],
      roles: [],
      modalType: '',
      isUpdate: false,
      currentRoleName: '',
      currentRoleId: '',
      newFirstName: '',
      newLastName: '',
      newEmail: '',
      badge: '',
      picture: null,
      userUpdateId: '',
      currentBasicAuth: false,
      snackMsg: '',
      snackType: '',
      showSpinner: true,
      showTable: false,
      isSearchClosed: false,
      tableRowsPerPage: 10
    }
    this.tableData = [];
    this.rolesList = [];
    this.workersList = [];
    this.emailsList = [];
    this.users = [];
    this.onSearch = false;
    this.searchBy = '';
    this.onFilter = false;
    this.filterBy = [];
    this.refreshUT = true;
    this.user = null;
  }

  componentDidMount() {
    this.props.getAllUsers()
      .then(resp => {
        if (this.props.users.length > 0) {
          this.setState({ showSpinner: false, showTable: true, roles: this.props.roles });
        } else {
          this.props.listUsers().then(resp => {
            this.setState({ showSpinner: false, showTable: true, roles: this.props.roles });
          })
        }
      }).catch(error => {
        console.error("[USERSCONTROL] Couldn't get all users data", error);
      });
  }

  componentDidUpdate() {
    // if(this.props.success || this.props.users===undefined){
    //   this.props.listUsers();
    // }
    if (this.state.isSearchClosed) {
      this.setState({ isSearchClosed: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.refreshUT) {
      return false
    } else {
      return true
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ openModal: false, open: false, isUpdate: false, pass1: '', pass2: '' });
    this.props.listUsers();
    this.props.getAllUsers();
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.user = null;
    this.setState({ modalType: '', openModal: false });
  };

  handleOpenSnackbar = (type, msg) => {
    this.setState({ openSnack: true, snackMsg: msg, snackType: type });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnack: false, snackMsg: '' });
  };

  handlePerformedBy = () => {
    const { user, userId } = this.props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  handleModifiedBy = () => {
    const { user, userId } = this.props;
    let modifiedBy = '';
    if (user && user.id) {
      modifiedBy = user.id;
    } else if (userId) {
      modifiedBy = userId;
    }
    return modifiedBy;
  }

  /*
  handleDelete = () => {
    const {classes, users}= this.props;
    let userInfo = users.find(user=>user._id === this.deleteUser);
    let performedBy= this.handlePerformedBy();
    this.props.deleteUser(this.deleteUser,this.props.campusId).then(res => {
      this.createUserLog({
        action: CONSTS.DELETE_USER_ACTION,
        description: `User "${userInfo.first_name} ${userInfo.last_name}" deleted by "${performedBy}"`});
      this.setState({modalType: ''});
      this.handleClose();
      let msg = <div className={classes.snackMsg}>User "<div className={classes.boldSnackMsg}>{`${userInfo.first_name} ${userInfo.last_name}`}</div>" deleted successfully!</div>
      this.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCESS, msg);
      this.props.socketChat.emit(CONSTS.REFRESH_CHAT_CHANNEL,this.props.userId);
    }).catch(err=>{
      this.createUserLog({
        action: CONSTS.DELETE_USER_ACTION_ERROR,
        description: `User "${userInfo.first_name} ${userInfo.last_name}" couldn't be deleted by "${performedBy}" due endpoint ${err}`});
      this.setState({modalType: ''});
      this.handleClose();
      let msg = <div className={classes.snackMsg}>User "<div className={classes.boldSnackMsg}>{`${userInfo.first_name} ${userInfo.last_name}`}</div>" couldn't be deleted!</div>
      this.handleOpenSnackbar(CONSTS.SNACKBAR_WARNING, msg);
      this.props.socketChat.emit(CONSTS.REFRESH_CHAT_CHANNEL,this.props.userId);
    });
  }
  */

  handleAction = () => {
    const { classes, users } = this.props;
    let userInfo = users.find(user => user._id === this.user.id);
    let performedBy = this.handlePerformedBy();
    const bodyInfo = {
      enabled: !this.user.enabled
    };
    const textAction = bodyInfo.enabled ? "restored" : "deleted";
    this.props.setAccountStatus(this.user.id, bodyInfo).then(res => {
      this.createUserLog({
        action: bodyInfo.enabled ? CONSTS.RESTORE_USER_ACTION : CONSTS.DELETE_USER_ACTION,
        description: `User "${userInfo.first_name} ${userInfo.last_name}" ${textAction} by "${performedBy}"`
      });
      this.props.listUsers();
      this.props.getAllUsers();
      let msg = <div className={classes.snackMsg}>User "<div className={classes.boldSnackMsg}>{`${userInfo.first_name} ${userInfo.last_name}`}</div>" {textAction} successfully!</div>
      this.props.socketChat.emit(CONSTS.REFRESH_CHAT_CHANNEL, this.user.id);
      this.user = null;
      this.setState({ openModal: false, modalType: '', openSnack: true, snackMsg: msg, snackType: CONSTS.SNACKBAR_SUCCESS });
    }).catch(err => {
      this.createUserLog({
        action: CONSTS.DELETE_USER_ACTION_ERROR,
        description: `User "${userInfo.first_name} ${userInfo.last_name}" couldn't be ${textAction} by "${performedBy}" due endpoint ${err}`
      });
      let msg = <div className={classes.snackMsg}>User "<div className={classes.boldSnackMsg}>{`${userInfo.first_name} ${userInfo.last_name}`}</div>" couldn't be {textAction}!</div>
      this.props.socketChat.emit(CONSTS.REFRESH_CHAT_CHANNEL, this.user.id);
      this.user = null;
      this.setState({ openModal: false, modalType: '', openSnack: true, snackMsg: msg, snackType: CONSTS.SNACKBAR_WARNING });
    });

  }

  handleShowDeleteModal = (user) => {
    this.refreshUT = true;
    this.user = { ...user };
    this.setState({ openModal: true, modalType: CONSTS.DELETE });
  }

  handleShowRestoreModal = (user) => {
    this.refreshUT = true;
    this.user = { ...user };
    this.setState({ openModal: true, modalType: CONSTS.RESTORE });
  }

  createUserLog = (data) => {
    let modifiedBy = this.handleModifiedBy();
    let log = {
      campusId: this.props.campusId,
      userId: modifiedBy,
      action: data.action,
      description: data.description,
      timestamp: new Date()
    };
    this.props.createLog(log);
  };

  editUser = (id) => {
    this.refreshUT = true;
    let user = this.props.users.find(e => e._id === id) || null;
    if (!!user) {
      //this.refs.firstName.input.value=user[0].first_name;
      let auth = true;
      if (user.hasOwnProperty('basic_auth')) {
        auth = user.basic_auth;
      }
      this.setState({
        isUpdate: true,
        open: true,
        currentBasicAuth: auth,
        currentRoleName: user.role_name,
        currentRoleId: user.role,
        newFirstName: user.first_name,
        newLastName: user.last_name,
        newEmail: user.email,
        badge: user.badge ? user.badge : '',
        picture: user.profile_picture ? user.profile_picture : null,
        userUpdateId: user._id
      });
    }
  }

  datesCustomRender = (filterList, onChange, index, column, label) => {
    const { classes } = this.props;
    return (
      <div>
        <TextField
          label={label}
          type={CONSTS.DATETIME}
          format={CONSTS.DATETIME_FORMAT}
          InputLabelProps={{
            shrink: true
          }}
          value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          className={classes.textInput}
        />
      </div>
    );
  };

  stringFieldsCustomRender = (filterList, onChange, index, column, key) => {
    const { STRING_FILED_TYPE } = CONSTS;
    let inputLabel = "";
    let optionList = [];
    if (key === STRING_FILED_TYPE.name.id) {
      inputLabel = STRING_FILED_TYPE.name.label;
      optionList = this.workersList.sort();
    } else if (key === STRING_FILED_TYPE.role.id) {
      inputLabel = STRING_FILED_TYPE.role.label;
      optionList = this.rolesList.sort();
    } else {
      inputLabel = STRING_FILED_TYPE.email.id;
      optionList = this.emailsList.sort();
    }
    const onTagsChange = (event, values) => {
      filterList[index][0] = values;
      onChange(filterList[index], index, column);
    }
    return (
      <Autocomplete
        id={key}
        options={optionList}
        getOptionLabel={(option) => option}
        style={{ width: 320 }}
        onChange={onTagsChange}
        renderInput={(params) => <TextField {...params} label={inputLabel} InputLabelProps={{ shrink: true }} />}
      />
    );
  }

  handleChangeSwitch = () => {
    this.filterBy = [];
    this.onFilter = false;
    this.onSearch = false;
    this.searchBy = '';
    this.refreshUT = true;
  }

  handleTableFilters = (column) => {
    let filter = this.filterBy.length ? this.filterBy[column] : null
    return filter;
  };

  handleOpenUsersForm = () => {
    this.setState({
      open: true,
      isUpdate: false,
      newFirstName: '',
      newLastName: '',
      newEmail: '',
      badge: '',
      picture: null,
      userUpdateId: 0
    });
  };

  accountStatusRender = (value, tableMeta, updateValue) => {
    const { classes } = this.props;

    return (
      <>
        <FlatButton
          id={"accSt-" + tableMeta.rowIndex}
          value={value}
          index={1}
          style={{ display: "inline-block" }}
          disabled={false}
        >
          <div className={classes.statusLblContainer}>
            <div className={classes.statusLblDiv} style={{ backgroundColor: value ? "green" : "red" }}></div>
            {value ? "Enabled" : "Disabled"}
          </div>
        </FlatButton>
      </>
    )
  };

  render() {
    const { order, openModal, orderBy } = this.state;
    const { classes, users, roles } = this.props;
    this.workerInfo = [];
    this.tableData = [];
    let alertColor = { backgroundColor: '#00C614', borderBottom: '7px solid #008B0E' };
    if (this.state.snackType === CONSTS.SNACKBAR_WARNING) {
      alertColor = { backgroundColor: '#f44336', borderBottom: '7px solid #aa2e25' };
    }

    roles.forEach(role => {
      if (!this.rolesList.includes(role.role) && !!role.role) {
        this.rolesList.push(role.role);
      }
    })

    const usersEnabled = users.filter(u => !u.isSuperadmin);
    //stableSort(usersEnabled, getSorting(order, orderBy));
    usersEnabled.forEach((e, index) => {
      let fullName = e.first_name + ' ' + e.last_name
      let lastLogIn = !!e.last_login ? moment(e.last_login).format(CONSTS.DEFAULT_DATE_FORMAT) : "";
      if (!this.workersList.includes(fullName) && !!fullName)
        this.workersList.push(fullName);
      if (!this.emailsList.includes(e.email) && !!e.email)
        this.emailsList.push(e.email);

      this.workerInfo.push({
        id: e._id,
        workerName: fullName,
      });

      this.tableData.push({
        enabled: e.enabled,
        num: 0,
        name: fullName,
        email: e.email,
        role: e.role_name,
        lastlogin: [lastLogIn, e.last_login],
        id: e._id
      });
    });

    this.tableData.sort(((a, b) => !!a.enabled && !b.name.enabled ? -1 : 1));

    let applyFilters = false;
    if (this.filterBy.length !== 0) {
      this.filterBy.forEach(filter => {
        if (filter.length) {
          applyFilters = true
        }
      });
      if (!applyFilters) {
        this.handleChangeSwitch();
      }
    }

    const columns = [
      {
        name: CONSTS.COLUMNS.status.id,
        label: CONSTS.COLUMNS.status.label,
        options: {
          sortThirdClickReset: true,
          display: true,
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return this.accountStatusRender(value, tableMeta, updateValue);
          }
        }
      },
      {
        name: CONSTS.COLUMNS.num.id,
        label: CONSTS.COLUMNS.num.label,
        options: {
          /*
          customHeadRender: (value, tableMeta) => {
            return (<td key={`${CONSTS.COLUMNS.num.id}-${CONSTS.COLUMNS.num.label}`}>{CONSTS.COLUMNS.num.label}</td>)
          },
          */
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return <p style={{ textAlign: 'left', paddingLeft: '25px' }}>{tableMeta.rowIndex + 1}</p>;
          }
        }
      },
      {
        name: CONSTS.COLUMNS.name.id,
        label: CONSTS.COLUMNS.name.label,
        options: {
          sortThirdClickReset: true,
          customBodyRender: (value) => <p style={{ textAlign: 'center', paddingLeft: '5px' }}>{value}</p>,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(1),
          customFilterListOptions: {
            render: value => `${CONSTS.COLUMNS.name.label}: ${value}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(value, filters) {
              return STRING_FILTER_LOGIC(value, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FILED_TYPE.name.id)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.email.id,
        label: CONSTS.COLUMNS.email.label,
        options: {
          sortThirdClickReset: true,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(2),
          customBodyRender: (value) => <p style={{ textAlign: 'center', paddingLeft: '5px' }}>{value}</p>,
          customFilterListOptions: {
            render: email => `${CONSTS.COLUMNS.email.label}: ${email}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(email, filters) {
              return STRING_FILTER_LOGIC(email, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FILED_TYPE.email.id)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.role.id,
        label: CONSTS.COLUMNS.role.label,
        options: {
          sortThirdClickReset: true,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(3),
          customBodyRender: (value) => <p style={{ textAlign: 'center', paddingLeft: '5px' }}>{value}</p>,
          customFilterListOptions: {
            render: role => `${CONSTS.COLUMNS.role.label}: ${role}`,
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(role, filters) {
              return STRING_FILTER_LOGIC(role, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FILED_TYPE.role.id)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.lastlogin.id,
        label: CONSTS.COLUMNS.lastlogin.label,
        options: {
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return <p>{value[0]}</p>
          },
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(4),
          customFilterListOptions: {
            render: v => FORMAT_FILTER_LABELDATES(v[0], CONSTS.LASTLOGIN),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return DATE_FILTER_LOGIC(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.lastlogin.label, CONSTS.STARTDATE)
          }

        }
      }
    ];
    const options = {
      searchText: this.searchBy,
      print: false,
      filter: true,
      filterType: CONSTS.TABLE_OPTIONS.filtertype,
      responsive: CONSTS.TABLE_OPTIONS.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.ROWS_PER_PAGE,
      selectableRows: CONSTS.TABLE_OPTIONS.selectableRows,
      selectableRowsHeader: false,
      selectableRowsOnClick: true,
      downloadOptions: {
        filename: CONSTS.TABLE_OPTIONS.filename,
        separator: CONSTS.TABLE_OPTIONS.separator,
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true
        }
      },
      customSort: (data, colIndex, order) => {
        let sortType = order === CONSTS.DEFAULT_SORT ? true : false;
        return CONSTS.TABLE_SORT(data, colIndex, sortType);
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return CONSTS.CUSTOM_CSV_DOWNLOAD(buildHead, buildBody, columns, data);
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({ tableRowsPerPage: tableState.rowsPerPage });
            break
          case CONSTS.TABLE_EVENTS.propsUpdate:
            if (this.filterBy.length !== 0 && this.onFilter)
              tableState.filterList = this.filterBy;
            break
          case CONSTS.TABLE_EVENTS.rowsSelect:
            this.refreshUT = false;
            break
          case CONSTS.TABLE_EVENTS.search:
            if (tableState.searchText) {
              this.searchBy = tableState.searchText;
              this.onSearch = true;
            }
            break
          case CONSTS.TABLE_EVENTS.filterChange:
            this.filterBy = tableState.filterList;
            this.onFilter = true;
            break
          case CONSTS.TABLE_EVENTS.onSearchClose:
            this.searchBy = '';
            this.onSearch = false;
            this.setState({ isSearchClosed: true });
            break
          case CONSTS.TABLE_EVENTS.resetFilters:
            this.handleChangeSwitch();
            break;
          default:
            break;
        }
      },
      customToolbar: () => {
        return (
          <CustomToolbar
            handleChangeSwitch={this.handleChangeSwitch}
            table={CONSTS.TABLE_ID}
            data={this.tableData}
            filterChips={this.filterBy} />
        );
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={this.tableData}
          setSelectedRows={setSelectedRows}
          editUser={this.editUser}
          workerInfo={this.workerInfo}
          showDeleteModal={this.handleShowDeleteModal}
          showRestoreModal={this.handleShowRestoreModal}
        />
      )
    };
    const tableTitle = () => {
      return (
        <div className={classes.titleContainer}>
          <div className={classes.titleIconDiv}>
            <PersonIcon className={classes.titleIcon} />
          </div>
          <h2 className={classes.titleLbl}>{CONSTS.TABLE_TITLE}</h2>
        </div>)
    }
    let l = this.props.open ? '23%' : '4%';
    let w = this.props.open ? '75.5%' : '95%';

    return (
      <div
        id="webNavv-adminPanel-users-1.0"
        style={{ marginTop: '5%', marginLeft: l, width: w }}
      >
        {this.state.showTable && !this.state.open &&
          <React.Fragment >
            <div id="webNavv-adminPanel-users-usersTable-1.0">
              <MasterTable
                title={tableTitle()}
                data={this.tableData}
                columns={columns}
                options={options}
              />
              <FloatingActionButton backgroundColor={'#039be5'} className="add-button" onClick={() => this.handleOpenUsersForm()}>
                <ContentAdd />
              </FloatingActionButton>
              <MasterAlert
                snackStyle={{ marginTop: '5vh' }}
                anchor={{ vertical: 'top', horizontal: 'right' }}
                openSnack={this.state.openSnack}
                handleClose={this.handleCloseSnackbar}
                alertStyle={alertColor}
                variant={CONSTS.FILLED}
                type={this.state.snackType}
                message={this.state.snackMsg}
              />
            </div>
          </React.Fragment>
        }
        {this.state.open &&
          <AddUsers
            rolesList={this.rolesList}
            isUpdate={this.state.isUpdate}
            currentBasicAuth={this.state.currentBasicAuth}
            currentRoleName={this.state.currentRoleName}
            currentRoleId={this.state.currentRoleId}
            newFirstName={this.state.newFirstName}
            newLastName={this.state.newLastName}
            newEmail={this.state.newEmail}
            badge={this.state.badge}
            picture={this.state.picture}
            userUpdateId={this.state.userUpdateId}
            handleOpenModal={this.handleOpenModal}
            handleShowDeleteModal={this.handleShowDeleteModal}
            handleOpenSnackbar={this.handleOpenSnackbar}
            handleClose={this.handleClose}
            createUserLog={this.createUserLog} />
        }
        <ModalProp
          handleCloseModal={this.handleCloseModal}
          openModal={openModal}
          icon={<ReportProblemIcon className={classes.problemIcon} />}
          title={CONSTS.DIALOG_TITLE}
          subtitle={this.state.modalType == CONSTS.DELETE ? CONSTS.DIALOG_DELETE_MSG : CONSTS.DIALOG_RESTORE_MSG}
          handleFirstOption={this.handleAction}
          handleSecondOption={this.handleCloseModal}
          button1Label={this.state.modalType == CONSTS.DELETE ? CONSTS.DELETE_BTN : CONSTS.RESTORE_BTN}
          button2Label={CONSTS.CANCEL_BTN}
        />
        <AwesomeSpinner config={CONSTS.SPINNER_CONFIG} loading={this.state.showSpinner} navOpen={this.props.open} />
      </div>
    );
  }
}

UsersControl.propTypes = {
  users: PropTypes.array.isRequired,
  success: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  campusId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  listUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  users: state.user.users,
  success: state.user.success,
  roles: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
  open: state.views.drawerOpen,
  user: state.user.user,
  userId: state.auth.userId,
  socketChat: state.auth.socketChat
})

export default connect(mapStateToProps, { listUsers, deleteUser, setAccountStatus, getAllUsers, createLog })(withStyles(styles)(UsersControl));