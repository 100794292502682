import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function MasterAlert(props) {
  return (
    <Snackbar
      style={props.snackStyle}
      className={props.snackClass}
      anchorOrigin={props.anchor}
      open={props.openSnack}
      onClose={props.handleClose}
      autoHideDuration={6000}>
      <MuiAlert
        style={props.alertStyle}
        className={props.alertClass}
        elevation={props.elevation}
        variant={props.variant}
        onClose={props.handleClose}
        severity={props.type}>{props.message}</MuiAlert>
    </Snackbar>
  );
};

export default (MasterAlert);
