import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import SpeedIcon from '@material-ui/icons/Speed';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { connect } from 'react-redux';
import moment from 'moment';
import arrowVector from '../../../../img/arrowVector.png';
import AnimationSlider from './animationSlider.component';
import { changeTimeWindow, showAnimationTrace, showAnimationCallouts, showAnimationCurrentFloorOnly } from '../../../../actions/views/viewsActions';
import _ from 'lodash';
import { styles, CustomCheckbox } from './styles';

const AnimationPanel = (props) => {
  const { classes } = props;
  const [animationDateTime, setAnimationDateTime] = useState(props.viewProps === 'history'? props.jobInfo[0].startDate : props.jobInfo[0].startTime);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [defaultPlayFlag, setDefaultPlayFlag] = useState(0);
  const [intervalTime, setIntervalTime] = useState(null);
  const [startDate, setStartDate] = useState(moment(props.viewProps === 'history'? props.jobInfo[0].startDate: props.jobInfo[0].startTime));
  const [addProgress, setAddProgress] = useState(0);
  const [cleanSlider, setCleanSlider] = useState(false);
  const [secondsForward, setSecondsForward] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(1000);
  const [menuValue, setMenuValue] = useState(null);
  const [timerList, setTimerList] = useState([]);
  const endDate = moment(props.viewProps === 'history'? props.jobInfo[props.jobInfo.length - 1].endDate : props.jobInfo[0].endTime);
  const defaultStartDate = moment(props.viewProps === 'history'? props.jobInfo[0].startDate : props.jobInfo[0].startTime);
  let timeInterval = null;
  let duration = moment.duration(endDate.diff(defaultStartDate));
  let totalDuration = duration.asSeconds();
  let progressAux = 0;

  useEffect(() => {
    //On Unmount
    return () => {
      for (const timer of timerList) {
        clearInterval(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (playAnimation === true && defaultPlayFlag === 1)
      handleDefaultAnimationPlay();
    setDefaultPlayFlag(defaultPlayFlag + 1);
  }, [playAnimation]);

  useEffect(() => {
    if (!playAnimation)
      handleToggleAnimation(true);
  }, [animationSpeed]);

  const handleCallouts = (ev, checked) => {
    props.showAnimationCallouts(checked);
  };

  const handleTraces = (ev, checked) => {
    props.showAnimationTrace(checked);
  };

  const handleCurrentFloorOnly = (ev, checked) =>{
    props.showAnimationCurrentFloorOnly(checked);
  };

  const timeWindowDates = (currentDateTime) => {
    let windowTime = [defaultStartDate, currentDateTime, 'animation'];
    props.setWindowDistanceFlag(true);
    props.changeTimeWindow(windowTime);
  };

  const clearTimeWindow = () => {
    props.setWindowDistanceFlag(false);
    props.resetHistoryTrace();
  };

  const timer = (intervalName) => {
    let aux = startDate.add(1, 'seconds').format('MM/DD/YYYY HH:mm:ss');
    let current = moment(aux);
    if (!current.isBefore(endDate)) {
      clearInterval(intervalName);
      setAnimationCompleted(true);
    } else {
      setAnimationDateTime(aux);
      props.setAnimationDates([defaultStartDate, aux]);
      let duration = moment.duration(endDate.diff(aux));
      let seconds = duration.asSeconds();
      let currentProgress = (totalDuration - seconds);
      if (currentProgress.toFixed(0) !== progressAux) {
        setAddProgress(currentProgress.toFixed(0));
        timeWindowDates(aux);
        progressAux = currentProgress.toFixed(0);
      }
    }
  };

  const handlePlayAnimation = (play) => {
    let isPaused = play ? false : true;
    if (!isPaused) {
      clearInterval(timeInterval);
      timeInterval = null;
      timeInterval = setInterval(() => {
        timer(timeInterval);
      }, animationSpeed);
      let list = timerList;
      list.push(timeInterval)
      setTimerList(list);
      setIntervalTime(timeInterval);
    } else {
      clearInterval(intervalTime);
      setStartDate(moment(animationDateTime));
    }
  };

  const handleToggleAnimation = (play) => {
    setPlayAnimation(play);
    if (defaultPlayFlag > 1)
      handlePlayAnimation(play);
  };

  const handleDefaultAnimationPlay = () => {
    clearInterval(timeInterval);
    timeInterval = null;
    timeInterval = setInterval(() => {
      timer(timeInterval);
    }, animationSpeed);
    let list = timerList;
    list.push(timeInterval)
    setTimerList(list);
    setIntervalTime(timeInterval);
    if (cleanSlider) setCleanSlider(false);
  };

  const handleClosePanel = () => {
    props.setAnimationView(false);
    props.setWindowDistanceFlag(false);
    props.resetHistoryTrace();
  };

  const cleanAnimationPanel = () => {
    setAnimationCompleted(false);
    setStartDate(defaultStartDate);
    setAnimationDateTime(defaultStartDate);
    props.setAnimationDates([defaultStartDate, endDate]);
    setDefaultPlayFlag(0);
    setAddProgress(0);
    setPlayAnimation(false);
    setCleanSlider(true);
    clearTimeWindow();
  };

  const handleUserProgress = (seconds) => {
    if (secondsForward)
      setSecondsForward(false);
    clearInterval(intervalTime);
    setAnimationCompleted(false);
    progressAux = seconds;
    if (playAnimation) {
      setStartDate(defaultStartDate);
      let aux = startDate.add(seconds, 'seconds').format('MM/DD/YYYY HH:mm:ss');
      timeWindowDates(aux);
      setAnimationDateTime(aux);
      props.setAnimationDates([defaultStartDate, aux]);
      timeInterval = null;
      timeInterval = setInterval(() => {
        timer(timeInterval);
      }, animationSpeed);
      let list = timerList;
      list.push(timeInterval)
      setTimerList(list);
      setIntervalTime(timeInterval);
    } else {
      let start = moment(defaultStartDate);
      let aux = start.add(seconds, 'seconds').format('MM/DD/YYYY HH:mm:ss');
      setStartDate(aux);
      timeWindowDates(aux);
      setAnimationDateTime(aux);
      props.setAnimationDates([defaultStartDate, aux]);
      setStartDate(moment(aux));
    }
  };

  const handleMoveForward = () => {
    let duration = moment.duration(endDate.diff(animationDateTime));
    let seconds = duration.asSeconds();
    if (seconds >= 10)
      setSecondsForward(true);
  };

  const handleChangeAnimationSpeed = (value) => {
    clearInterval(timeInterval);
    handleToggleAnimation(false);
    setMenuValue(null);
    setAnimationSpeed(value);
  };

  const handleCloseMenu = () => {
    setMenuValue(null);
  };
  const handleClickMenu = (event) => {
    setMenuValue(event.currentTarget);
  };

  const handleRenderDateTime = () =>{
    const date = moment(animationDateTime).format('MM/DD/YYYY');
    const time = moment(animationDateTime).format('HH:mm:ss');
    return(
      <React.Fragment>
        <div className={classes.dateDiv}>
          <p className={classes.dateLbl}>{date}</p>
        </div>
        <div className={classes.animationTime} >{time}</div>
      </React.Fragment>
    )
  };

  const handleRenderFilters = () =>{
    return(
      <div className={classes.checkboxDiv}>
        {props.viewProps === 'history' ? (
          <div className={classes.filtersDiv}>
            <div style={{ display: 'inline' }}>
              <FormControlLabel
                control={<CustomCheckbox checked={props.animationShowTrace} onChange={handleTraces} name="checkedTraces" />}
                label={<span className={classes.filtersLbl}>Traces</span>} />
            </div>
            <div style={{ display: 'inline' }}>
              <FormControlLabel
                control={<CustomCheckbox checked={props.animationShowCallouts} onChange={handleCallouts} name="checkedCallout" />}
                label={<span className={classes.filtersLbl}>Callouts</span>} />
            </div>
          </div>
        ):(
          <div className={classes.jobHistoryFiltersDiv}>
            {/* <div style={{ display: 'inline' }}>
              <FormControlLabel
                control={<CustomCheckbox checked={props.animationCurrentFloorOnly} onChange={handleCurrentFloorOnly} name="checkedCurrentFloorOnly" />}
                label={<span className={classes.filtersLbl}>Current floor only</span>} />
            </div> */}
            <div style={{ display: 'inline' }}>
              <FormControlLabel
                control={<CustomCheckbox checked={props.animationShowCallouts} onChange={handleCallouts} name="checkedAllCallouts" />}
                label={<span className={classes.filtersLbl}>All Callouts</span>} />
            </div>
          </div>
        )}
      </div>
    )
  };

  const handleRenderSpeedOptions = () =>{
    return(
      <React.Fragment>
        {props.viewProps === 'history' && (
          <div className={classes.speedDiv}>
            <Tooltip title="Playback speed">
              <IconButton
                onClick={handleClickMenu}
              >
                <SpeedIcon style={{ fill: 'white' }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={menuValue}
              keepMounted
              open={Boolean(menuValue)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleChangeAnimationSpeed(1000)} selected={animationSpeed === 1000 ? true : false}>Normal</MenuItem>
              <MenuItem onClick={() => handleChangeAnimationSpeed(750)} selected={animationSpeed === 750 ? true : false}>1.25</MenuItem>
              <MenuItem onClick={() => handleChangeAnimationSpeed(500)} selected={animationSpeed === 500 ? true : false}>1.5</MenuItem>
              <MenuItem onClick={() => handleChangeAnimationSpeed(250)} selected={animationSpeed === 250 ? true : false}>1.75</MenuItem>
            </Menu>
          </div>
        )}
      </React.Fragment>
    )
  };

  const handleRenderPlayer = () =>{
    return(
      <React.Fragment>
        <div className={props.showPanel ? classes.animationBottom : classes.animationBottomHidden}>
              {!animationCompleted ?
                <React.Fragment>
                  {playAnimation ?
                    <PauseCircleFilledIcon className={classes.playAnimationIcon} onClick={() => handleToggleAnimation(false)} />
                    : <PlayCircleFilledIcon className={classes.playAnimationIcon} onClick={() => handleToggleAnimation(true)} />
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  <ReplayIcon className={classes.playAnimationIcon} onClick={() => cleanAnimationPanel()} />
                </React.Fragment>
              }
              <div className={classes.forwardDiv}>
                <img src={arrowVector} className={classes.forwardAnimationIcon} alt="arrowIcon" onClick={() => handleMoveForward()} />
              </div>
              <div className={props.showPanel ? classes.timeSliderDiv : classes.timeSliderDivHidden}>
                <AnimationSlider
                  addProgress={addProgress}
                  currentTime={animationDateTime}
                  totalDuration={totalDuration}
                  cleanSlider={cleanSlider}
                  userProgress={handleUserProgress}
                  secondsForward={secondsForward} />
              </div>
        </div>
      </React.Fragment>
    )
  };

  return (
    <React.Fragment>
      <div
        id="webNavv-history-tracePlayer-expanded-1.0"
        className={classes.animationControl}
      >
        <div style={{ display: 'block' }}>
          <div className={props.showPanel ? classes.animationHeader : classes.animationHeaderHidden}>
            <div className={classes.closeAnimationPanel}>
              <ArrowDropDownIcon className={classes.hideAnimationCtrl} onClick={() => handleClosePanel()} />
            </div>
            {handleRenderDateTime()}
            {handleRenderFilters()}
            {handleRenderSpeedOptions()}
          </div>
        </div>
        <div
          id="webNavv-history-tracePlayer-slider-1.0"
          style={{ display: 'block' }}
        >
          {handleRenderPlayer()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  jobInfo: state.views.jobInfo,
  animationShowTrace: state.views.animationShowTrace,
  animationShowCallouts: state.views.animationShowCallouts,
  animationCurrentFloorOnly: state.views.animationCurrentFloorOnly
});

export default connect(mapStateToProps, { changeTimeWindow, showAnimationCallouts, showAnimationTrace, showAnimationCurrentFloorOnly })(withStyles(styles)(AnimationPanel));