import React from 'react';
import Chart from "react-google-charts";
import { connect } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { CHART_TYPE } from './superDashboard.constants';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    selector: {
        color: '#2196F3',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    chartTitle: {
        fontStyle: 'normal',
        //fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 17,
        textAlign: 'right',
        color: '#222B45',
    },
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            '& .MuiListItemText-primary': {
                //color: '#FFF',
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
            alignSelf: 'baseline',
        },
        '& .MuiListItemText-primary': {
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
        },
    },
}))(MenuItem);


function UserChart(props) {
    const classes = useStyles();

    const { chartOption } = props;

    const { usersByCampusRole, phonesByCampusRole } = props;
    const [expanded, setExpanded] = React.useState(false);
    const optionList = [...CHART_TYPE];
    const [activeChart, setActiveChart] = React.useState(CHART_TYPE[chartOption]);
    const resultData = chartOption === 0 ? usersByCampusRole : phonesByCampusRole;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectChart = (selection) => {
        handleClose();
        props.onChangeChart(selection);
    };

    return (
        <div className={classes.cardRoot}>
            <div style={{ display: 'flex' }}>
            <p className={classes.chartTitle}>{activeChart}</p>
                    <IconButton
                        disableRipple
                        className={classes.selector}
                        size="small"
                        aria-controls="simple-menu"
                        onClick={handleClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                transform: 'translateX(20px) translateY(30px)',
                            }
                        }}
                        anchorEl={anchorEl}
                        //keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {optionList.map((op, index) => {
                            const isSelected = activeChart === op;

                            return (
                                <StyledMenuItem
                                    key={`superadmin-dashboard-menu-${index}`}
                                    onClick={() => handleSelectChart(index)}
                                    style= {{ color: isSelected ? '#2196F3' : '#000000' }}
                                    >
                                    {op}
                                </StyledMenuItem>
                            )
                        }
                        )}
                        </Menu>
            </div>
            <Chart
                width={'100%'}
                height={'450px'}
                chartType="BarChart"
                /*
                render={({ renderChart }) => {
                    return (
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>{renderChart()}</div>
                      </div>
                    )
                  }}
                  */
                loader={<div>Loading Chart</div>}
                /*
                data={[
                    [' ', 'HF', 'ALLE', 'MAC'],
                    ['Transporter', 0, 90, 50],
                    ['EVS Disp.', 10, 15, 29],
                    ['EVS Manager', 14, 53, 5],
                    ['Admins', 24, 78, 20],
                    ['Pharma Tech', 69, 13, 37],
                    ['Housekeeper', 10, 15, 25],
                ]}
                */
                data={resultData}
                options={{
                    // Material design options
                    /*
                    chart: {
                        title: 'Users by campus and role',
                    },
                    */
                    //colors: ["#0095FF"],
                    isStacked: true,
                    chartArea: { 
                        width: '60%',
                        height: '90%'
                     },
                    //bar: { groupWidth: '95%' },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.user.users,
    user: state.superAdmin.user,
    usersByCampusRole: state.superAdmin.usersByCampusRole,
    phonesByCampusRole: state.superAdmin.phonesByCampusRole,
    auth: state.auth
});

export default connect(mapStateToProps, {})(UserChart);