import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import moment from "moment";

import { categoriesByCampus, storeCategory, updateCategory, deleteCategory, getEquipment } from '../../../actions/equipment/equipmentActions';
import { createLog } from '../../../actions/logs/logsActions';
import CustomToolbarSelect from '../utils/toolbarSelect.component.js'
import AwesomeSpinner from '../../utilities/spinner';
import MasterAlert from '../../utilities/alert.component';
import MasterButton from '../../utilities/button.component';
import ModalPop from '../../utilities/modal.component';
import { styles } from './equipment.styles';
import * as CONSTS from './equipment.constants';

class EquipmentCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      currentCategory: '',
      buttonName: CONSTS.ADD,
      creationDate: '',
      id: '',
      update: false,
      deleted: false,
      action: false,
      showSpinner: false,
      openSnack: false,
      snackSeverity: '',
      snackMsg: '',
      openModal: false,
      categoryError: false,
      categoryErrorDesc: '',
      tableRowsPerPage: 5,
    };
  }
  startLoop = true;
  equipmentPerCategory = {};
  elements = CONSTS.ELEMENTS;
  expire = false;
  showTable = false;
  tableData = [];
  workersList = [];
  devicesList = [];
  usersName = [];
  usersNameList = [];

  componentDidMount = () => {
    this.props.categoriesByCampus(this.props.campusInfo.id);
    this.props.getEquipment(this.props.campusInfo.id);
  };
  UNSAFE_componentWillMount = () => {
    this.props.getEquipment(this.props.campusInfo.id);
  }
  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  }
  componentDidUpdate() {
    if (this.state.action) {
      this.props.categoriesByCampus(this.props.campusInfo.id);
      this.props.getEquipment(this.props.campusInfo.id);
      this.setState({ action: false });
    }
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    let { classes } = this.props;
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          className={classes.dateInput}
        />
      </div>
    );
  };

  getEquipmentPerCategory = () => {
    const { equipmentList, equipmentCategories } = this.props;
    equipmentCategories.forEach(category => {
      let devicesCount = 0;
      equipmentList.map(item => {
        if (item.device_category === category.id) devicesCount++
        return null;
      })
      this.equipmentPerCategory[category.id] = devicesCount;
    });
  };

  handleOpenSnackbar = (type, ms) => {
    this.setState({ openSnack: true, snackMsg: ms, snackSeverity: type });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnack: false, snackMsg: '', snackSeverity: '' });
  };

  handleOpenModal = (data) => {
    this.setState({ openModal: true, name: data.name, id: data.id });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, name: '', id: '' });
  };

  enabledButton = () => {
    if (this.state.name === '') {
      return true;
    } else {
      return false;
    }
  };

  handleCancel = () => {
    this.setState({
      name: '',
      id: '',
      categoryError: false,
      categoryErrorDesc: '',
      buttonName: CONSTS.ADD
    });
  };

  createCategoryLog = (data) => {
    let modifiedBy = this.handleModifiedBy();
    let log = {
      campusId: this.props.campusInfo.id,
      action: data.action,
      userId: modifiedBy,
      description: data.description,
      timestamp: new Date()
    };
    this.props.createLog(log);
  };

  validateDeletion = (id) => {
    let error = false;
    if (this.equipmentPerCategory[id] !== 0)
      error = true;
    return error;
  };

  handlePerformedBy = () => {
    const { user, userId } = this.props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  handleModifiedBy = () => {
    const { user, userId } = this.props;
    let modifiedBy = '';
    if (user && user.id) {
      modifiedBy = user.id;
    } else if (userId) {
      modifiedBy = userId;
    }
    return modifiedBy;
  }

  handleDelete = (e) => {
    const { classes } = this.props;
    if (!this.validateDeletion(this.props.equipmentCategories[e].id)) {
      this.handleOpenModal(this.props.equipmentCategories[e]);
    } else {
      let msg = <div className={classes.inlineContainer}>{`Category couldn't be deleted due equipments ( ${this.equipmentPerCategory[this.props.equipmentCategories[e].id]} ) have this model assigned!`}</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
      this.setState({ name: '', id: '' });
    }
  };

  handleDeleteCategory = () => {
    let { classes } = this.props;
    let category = this.state.name;
    let performedBy = this.handlePerformedBy();
    this.handleCloseModal();
    this.setState({ showSpinner: true });
    this.props.deleteCategory(this.state.id).then(resp => {
      this.createCategoryLog({ action: CONSTS.DELETE_ACTION, description: `Equipment Category "${category}" deleted by "${performedBy}"` });
      this.setState({ deleted: true, action: true, showSpinner: false, name: '', id: '' });
      let msg = <div className={classes.inlineContainer}>{CONSTS.DELETE_MSG}</div>
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      this.createCategoryLog({ action: CONSTS.DELETE_ACTION_ERROR, description: `Equipment Category "${category}" couldn't be deleted by "${performedBy}" due endpoint ${err}` });
      this.setState({ deleted: true, showSpinner: false, name: '', id: '' });
      let msg = <div className={classes.inlineContainer}>{CONSTS.DELETE_MSG_ERROR}</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  }

  editCategory = (e) => {
    this.setState({
      name: this.props.equipmentCategories[e].name,
      currentCategory: this.props.equipmentCategories[e].name,
      id: this.props.equipmentCategories[e].id,
      creationDate: this.props.equipmentCategories[e].creation_date,
      buttonName: CONSTS.EDIT
    });
  }

  validateData = () => {
    let error = false;
    let categoryFound = this.props.equipmentCategories.find(m => m.name.toLowerCase() === this.state.name.toLowerCase());
    if (categoryFound && this.state.name.toLowerCase() !== this.state.currentCategory.toLowerCase()) {
      error = true;
      this.setState({ categoryError: true, categoryErrorDesc: CONSTS.CATEGORY_EXISTS });
    }
    if (this.state.name === '') {
      this.setState({ categoryError: true, categoryErrorDesc: CONSTS.CATEGORY_EMPTY });
      error = true;
    }
    return error;
  };

  onClickModifier = () => {
    let formError = this.validateData();
    if (!formError) {
      this.setState({ showSpinner: true, categoryError: false });
      if (this.state.buttonName === CONSTS.ADD)
        this.sendData();
      else
        this.updateData();
    }
  };

  sendData = () => {
    let { classes } = this.props;
    let performedBy = this.handlePerformedBy();
    let modifiedBy = this.handleModifiedBy();
    this.props.storeCategory({
      name: this.state.name.trim(),
      campusId: this.props.campusInfo.id,
      creation_date: new Date(),
      modified: new Date(),
      modifiedBy: modifiedBy
    }).then(resp => {
      this.createCategoryLog({ action: CONSTS.CREATE_ACTION, description: `Equipment Category "${this.state.name}" created by "${performedBy}"` });
      this.setState({ name: '', showSpinner: false, action: true });
      let msg = <div className={classes.inlineContainer}>{CONSTS.CREATE_MSG}</div>
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      this.createCategoryLog({ action: CONSTS.CREATE_ACTION_ERROR, description: `Equipment Category "${this.state.name}" couldn't be created by "${performedBy}" due enpoint ${err}` });
      this.setState({ name: '', showSpinner: false });
      let msg = <div className={classes.inlineContainer}>{CONSTS.CREATE_MSG_ERROR}</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  };

  updateData = () => {
    let { classes } = this.props;
    let performedBy = this.handlePerformedBy();
    let modifiedBy = this.handleModifiedBy();
    this.props.updateCategory({
      id: this.state.id,
      name: this.state.name.trim(),
      campusId: this.props.campusInfo.id,
      creation_date: this.state.creationDate,
      modified: new Date(),
      modifiedBy: modifiedBy
    }).then(resp => {
      this.createCategoryLog({ action: CONSTS.UPDATE_ACTION, description: `Equipment Category "${this.state.name}" updated by "${performedBy}"` });
      this.setState({ name: '', buttonName: 'ADD', action: true, showSpinner: false });
      let msg = <div className={classes.inlineContainer}>{CONSTS.UPDATE_MSG}</div>
      this.handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      this.createCategoryLog({ action: CONSTS.UPDATE_ACTION_ERROR, description: `Equipment Category "${this.state.name}" couldn't be updated by "${performedBy}" due endpoint ${err}` });
      this.setState({ name: '', buttonName: 'ADD', showSpinner: false });
      let msg = <div className={classes.inlineContainer}>{CONSTS.UPDATE_MSG_ERROR}</div>
      this.handleOpenSnackbar(CONSTS.WARNING, msg);
    });
  }

  headerRender = () => {
    let { classes } = this.props;
    return (
      <div>
        <div className={classes.titleContainer}>
          <LocalOfferIcon className={classes.iconTitle} />
          <h3 className={classes.titleLbl}>{CONSTS.TITLE}</h3>
        </div>
        <div className={classes.formContainer}>
          <TextField
            id="webNavv-settings-equipmentForm-nameInput-1.0"
            error={this.state.categoryError}
            helperText={this.state.categoryError ? this.state.categoryErrorDesc : ''}
            style={{ width: '80%' }}
            id="filled-basic"
            label={CONSTS.NAME}
            value={this.state.name}
            onChange={e => { this.setState({ name: e.target.value }) }}
            variant="filled" />
          <TextField
            id="webNavv-settings-equipmentForm-ownerInput-1.0"
            style={{marginLeft: '30px'}}
            id="filled-basic"
            disabled
            label={CONSTS.OWNER}
            value={this.props.campusInfo.alias}
            variant="filled"
          />
        </div>
        <div>
          <MasterButton
            id="webNavv-settings-equipmentForm-addButton-1.0"
            keyLbl={'equipmentCategories-btn'}
            label={this.state.buttonName}
            buttonType={CONSTS.SUCCESS}
            isDisabled={this.enabledButton()}
            handleClick={() => { this.onClickModifier() }}
            size={CONSTS.MEDIUM}
            class={classes.containedBtn}
          />
          {!this.enabledButton() ?
            <MasterButton
            id="webNavv-settings-equipmentForm-cancelButton-1.0"
            keyLbl={'cancelCat-btn'}
            label={CONSTS.CANCEL}
            buttonType={CONSTS.DEFAULT}
            isDisabled={false}
            handleClick={()=> this.handleCancel()}
            size={CONSTS.MEDIUM}
            class={classes.cancelBtn}
            /> : null}
        </div>
      </div>
    );
  }

  render() {
    let { classes } = this.props;
    let data = [];
    this.getEquipmentPerCategory();
    this.props.equipmentCategories.map((e) => {
      let dateCreatedFormated = moment(e.creation_date).format(CONSTS.DATE_FORMAT);
      let lastModificationFormated = moment(e.modified).format(CONSTS.DATE_FORMAT);
      data.push({
        name: e.name,
        owner: this.props.campusInfo.alias,
        equipments: this.equipmentPerCategory[e.id],
        dateCreated: [dateCreatedFormated, e.creation_date],
        lastModification: [lastModificationFormated, e.modified]
      });
      this.showTable = true;
    });

    const columns = [
      {
        name: CONSTS.COLUMNS.name.id,
        label: CONSTS.COLUMNS.name.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: value => {
            return (
              <div className={this.props.classes.equipmentCategory}>{value}</div>
            );
          }
        }
      },
      {
        name: CONSTS.COLUMNS.owner.id,
        label: CONSTS.COLUMNS.owner.label,
        options: {
          sortThirdClickReset: true,
          filter: false
        }
      },
      {
        name: CONSTS.COLUMNS.equipments.id,
        label: CONSTS.COLUMNS.equipments.label,
        options: {
          sortThirdClickReset: true,
          filter: false
        }
      },
      {
        name: CONSTS.COLUMNS.dateCreated.id,
        label: CONSTS.COLUMNS.dateCreated.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: value => {
            return (
              <div>{value[0]}</div>
            );
          }
        }
      },
      {
        name: CONSTS.COLUMNS.lastModification.id,
        label: CONSTS.COLUMNS.lastModification.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: value => {
            return (
              <div>{value[0]}</div>
            );
          }
        }
      }
    ];

    const options = {
      filter: true,
      filterType: CONSTS.TABLE_OPTIONS.filterType,
      responsive: CONSTS.TABLE_OPTIONS.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.TABLE_OPTIONS.rowsPerPageOptions,
      selectableRowsHeader: false,
      selectableRows: CONSTS.TABLE_OPTIONS.selectableRows,
      selectableRowsOnClick: true,
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        let sortType = order === CONSTS.TABLE_OPTIONS.defaultSort ? true : false;
        return CONSTS.TABLE_SORT(data, colIndex, sortType);
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({ tableRowsPerPage: tableState.rowsPerPageOptions });
            break
          default:
            break
        }
      },
      toolbar: {
        filterTable: CONSTS.TABLE_OPTIONS.filterTable,
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={this.tableData}
          setSelectedRows={setSelectedRows}
          edit={this.editCategory}
          workerInfo={this.workerInfo}
          showDelete={this.handleDelete} />
      )
    };
    return (
      <div style={{marginLeft:'3%'}} id="webNavv-settings-equipmentForm-1.0">
        <div>
          <MuiThemeProvider theme={CONSTS.getMuiTheme()}>
            <MUIDataTable title={this.headerRender()} data={data} columns={columns} options={options} />
          </MuiThemeProvider>
        </div>
        <AwesomeSpinner config={CONSTS.DEFAULT} loading={this.state.showSpinner} navOpen={this.props.open} />
        <MasterAlert
          snackStyle={{ marginTop: '5vh' }}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          openSnack={this.state.openSnack}
          handleClose={this.handleCloseSnackbar}
          alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
          variant={"filled"}
          type={this.state.snackSeverity}
          message={this.state.snackMsg}
        />
        <ModalPop
          handleCloseModal={this.handleCloseModal}
          openModal={this.state.openModal}
          icon={<ReportProblemIcon className={classes.problemIcon} />}
          title={CONSTS.MODAL_TITLE}
          subtitle={`You are going to delete "${this.state.name}"!`}
          handleFirstOption={this.handleDeleteCategory}
          handleSecondOption={this.handleCloseModal}
          button1Label={CONSTS.MODAL_DELETE}
          button2Label={CONSTS.MODAL_CANCEL}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  equipmentCategories: state.equipments.equipmentCategories,
  campusInfo: state.auth.userInfo.campusInfo,
  open: state.views.drawerOpen,
  equipmentList: state.equipments.equipmentList,
  user: state.user.user,
  userId: state.auth.userId
});

export default connect(mapStateToProps, {
  categoriesByCampus,
  storeCategory,
  updateCategory,
  deleteCategory,
  getEquipment,
  createLog
})(withStyles(styles)(EquipmentCategory));