import {
  ALL_USERS,
  SET_USER,
  TOKEN,
  FETCH_ROLES,
  FETCH_ALL_ACLS,
  GET_SUPER_ADMIN_LIST,
  USER_COUNT_BY_CAMPUS,
  PHONE_COUNT_BY_CAMPUS,
  MESSAGE_COUNT_BY_CAMPUS,
  EQUIPMENT_COUNT_BY_CAMPUS,
  USER_BY_ROLE_AND_CAMPUS,
  MESSAGE_BY_DATE_AND_CAMPUS,
  MESSAGE_BY_ADMIN,
  PHONE_BY_ROLE_AND_CAMPUS,
  INIT_STATE
} from "../actions/superAdmin/types";

const initialState = {
  user: {},
  allUsers: []
};

const SUPER_ADMIN_INFO = "adminInfo";

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      localStorage.setItem(SUPER_ADMIN_INFO, JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      }
    case ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      }
      case USER_COUNT_BY_CAMPUS:
      return {
        ...state,
        usersByCampus: action.payload,
      }
    case PHONE_COUNT_BY_CAMPUS:
      return {
        ...state,
        phonesByCampus: action.payload,
      }
    case MESSAGE_COUNT_BY_CAMPUS:
      return {
        ...state,
        messagesByCampus: action.payload,
      }
    case EQUIPMENT_COUNT_BY_CAMPUS:
      return {
        ...state,
        equipmentsByCampus: action.payload,
      }
    case USER_BY_ROLE_AND_CAMPUS:
      return {
        ...state,
        usersByCampusRole: action.payload,
      }
    case PHONE_BY_ROLE_AND_CAMPUS:
      return {
        ...state,
        phonesByCampusRole: action.payload,
      }
    case MESSAGE_BY_DATE_AND_CAMPUS:
      return {
        ...state,
        messagesByCampusDate: action.payload,
      }
    case MESSAGE_BY_ADMIN:
      return {
        ...state,
        messagesByAdmin: action.payload,
      }
    case FETCH_ROLES:
      return {
        ...state,
        roleList: action.payload,
      }
    case FETCH_ALL_ACLS:
      return {
        ...state,
        aclList: action.payload,
      }
    case GET_SUPER_ADMIN_LIST:
      return {
        ...state,
        superAdminList: action.payload,
      }
    case INIT_STATE:
      return initialState;
    default:
      return state;
  }
}
