import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
class AssingUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state= {
      inputValue:props.phone.user && props.users[props.phone.user.userId]!==undefined?props.users[props.phone.user.userId]:null
    };
  }

  handleSelectedUser=(userId)=>{
    return Object.values(this.props.users).findIndex((user)=>{
      return user._id === userId;
    });
  }

  handleDefineCurrentValue=(phone, users)=> {
    if (!!phone.user) {
      let userIndex = users[phone.user.userId]
      if (userIndex !== undefined) {
        this.setState({inputValue: users[phone.user.userId]});
      }
    }
  }
  componentDidUpdate(props, state) {
    if (state.inputValue == null) {
      //this.handleDefineCurrentValue(props.phone, props.users);
    }
  }
  componentDidMount=()=> {
    this.handleDefineCurrentValue(this.props.phone, this.props.users);
  }
  handleAssingUser=(phone,user)=>{
    if(!!phone.assign){
      return user[phone.assign];
    }
      return null
  }
  handleDisableAutoComplete=(phone,selectedUser,remoteLogin)=>{
    let remoteLoginUserOnline= !!phone.user && !!phone.user.online;
    let basicAuthUserOnline = !!phone.usedBy && !!phone.usedBy.online
    
    if(remoteLoginUserOnline && phone.phoneId === selectedUser.device_id){
      return true;
    }
    if(!remoteLogin && basicAuthUserOnline && phone.usedBy.device_id === phone.phoneId){
      return true;
    }
    return false
  }
  render() {
    const {phone, users, handleUpdateAssingUser,updatePhoneCheck, remoteLogin} = this.props;
    const {inputValue} = this.state;
    let selectedUser= !!inputValue?inputValue:this.handleAssingUser(phone.phone,users); 
    return (
      <React.Fragment>
        <Autocomplete
          style={{ zIndex: "998", width: '200px', marginLeft: '5%'}}
          id={'role-opt'}
          options={Object.values(users)}
          getOptionDisabled={(option)=>option.online}
          value={selectedUser}

          getOptionLabel={(option) =>{
              return option.name;
            }           
        }
        getOptionSelected={(option)=>{
            if(!!selectedUser && !!option && option._id === selectedUser._id){
                return true;
            }
        }}
          disabled={this.handleDisableAutoComplete(phone,selectedUser,remoteLogin)}
          renderInput={(params) => <TextField {...params}  label={"Assign To"} variant='filled'/>}
          onChange={async(event, user)=>{
            if (!!user) {
              user.phoneToLogon = phone;
              //handleAssingPhoneToUser(user, phone.phone._id, phone.phone);
              phone.phone.remoteLogin=true;
              await handleUpdateAssingUser(phone.phone._id,phone.phone,user._id);
              if(phone.phone.checklist === undefined || Object.values(phone.phone.checklist).length === 0){                  
                await updatePhoneCheck({
                    worker_id:user._id,
                    phone:phone.phone,
                    reset:false})
              }
            }
          }

          }
        />
      </React.Fragment>
    );
  }
}

export default AssingUser;

