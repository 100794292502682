import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = (theme) => ({
    cancelBtn: {
        marginLeft: '15px'
    },
    problemIcon: {
        fill:'#FFC738',
        fontSize:'200px',
        marginLeft:'100px'
    },
    inlineContainer: {
        display:'inline'
    },
    titleLbl: {
        fontWeight: '500',
        letterSpacing:'0.25px',
        marginLeft: '10px',
        fontSize: '18px',
        color:'#2843A3',
    },
    iconTitle: {
        fill:'#2843A3',
        fontSize:'50px',
        opacity:0.7,
    },
    titleIconDiv:{
        display:'flex',
    },
    dateInput: {
        marginLeft: '20px',
        width:'85%',
        marginTop:'10px',
    },
    titleContainer: {
        display:'flex',
        flexDirection:'row',
        marginTop: '10px'
    },
    titleContainerOpen: {
        display:'flex',
        flexDirection:'row',
        marginTop: '10px',
        width:'130vh'
    },
    formContainer: {
        justifyContent:'space-between',
        width:'100%',
        paddingBottom:'20px',
        display:'flex',
        marginTop: '20px'
    },
    formContainerOpen: {
        justifyContent:'space-between',
        width:'130vh',
        paddingBottom:'20px',
        display:'flex',
        marginTop: '20px'
    },
    containedBtnPass: {
        width:'15%',
        marginTop: '10px',
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    input: {
        color: '#757575',
        backgroundColor: 'rgba(0,0,0,0.04)',
        border: 'none',
        width: '72vh',
        marginLeft: '20px',
        borderRadius: '7px',
        height: '7vh',
        paddingLeft: '10px'
    },
    inputOpen: {
        color: '#757575',
        backgroundColor: 'rgba(0,0,0,0.04)',
        border: 'none',
        width: '45vh',
        marginLeft: '20px',
        borderRadius: '7px',
        height: '7vh',
        paddingLeft: '10px'
    },
    searchContainer: {
        display: 'flex',
        width: '40vh',
        marginLeft: '7vh'
    },
    searchContainerOpen: {
        display: 'flex',
        width: '40vh',
        marginLeft: 'none'
    },
    closeSearch: {
        display: 'inline',
        width: '5vh',
        marginLeft:'7%',
        marginTop: '0.5%'
    },
    inputContainer: {
        display: 'inline',
        width: '65vh'
    },
    cleanBtn: {
        marginBottom: '10%'
    },
    timeContainer: {
        display:'flex',
        marginLeft:'35vh',
        fontSize: '15px',
    },
    timeContainerOpen: {
        display:'flex',
        marginLeft:'5vh',
        fontSize: '15px',
    },
    timeLbls: {
        display:'flex'
    },
    date: {
        display:'inline',
        marginLeft: '1vh'
    },
    timeDifference:{
        color:'#2843A3',
        fontWeight: 'bold',
        display: 'inline',
        marginTop: '2.5vh',
        marginLeft:'3vh'
    },
    timeWindowLbls: {
        color:'#2843A3',
        display: 'inline',
        fontWeight: 'bold'
    },
    timeInputContainer: {
        display:'inline',
        textAlign: 'center',
        width: '15vh'
    },
    paper: {
        position:'absolute',
        zIndex:'1000',
        left:'93vh',
        width:'40vh',
        top:'21vh'
    },
    paperOpen: {
        position:'absolute',
        zIndex:'1000',
        left:'60vh',
        width:'40vh',
        top:'21vh'
    },
    timeBtns: {
        display: 'flex',
        float: 'right',
        marginTop:'10px'
    },
    mainContainer: {
        marginLeft:'3%',
        marginTop: '5%'
    },
    autocomplete:{
        width:'40vh',
        marginLeft:'110vh'
    },
    autocompleteOpen:{
        width:'40vh',
        marginLeft:'75vh'
    },
    actionLbl:{
        textAlign: 'left',
        paddingLeft:'10px'
    },
    descriptionLbl:{
        textAlign: 'left',
        paddingLeft:'5px'
    },
    descriptionLblOpen:{
        textAlign: 'left',
        paddingLeft: '10px',
        maxWidth: '400px'
    },
    timestampOpen:{
        paddingLeft:'10px',
        paddingRignt:'10px'
    },
    fromDiv:{
        display: 'inline',
        marginTop: '1vh'
    },
    toDiv:{
        display:'block',
        marginTop: '1vh'
    }
});