import moment from "moment";

export const renderEquipmentLocation=(renderEquipmentLocationParameters, handleFilterUsersByFloor)=>{
    const {equipmentLoc, activeFloor, allCallouts, smallCallouts, equipmentCategories, indoorOnly, mapkit} = renderEquipmentLocationParameters;
    let currentFloor=-3;
    if(activeFloor)
        currentFloor = handleFilterUsersByFloor();

    // //Clean all equipment annotations before update
    mapkit.handleRemoveEquipmentAnnotations();

    let equipmentList = [
        {
            kioLoc:{
                floor: 1,
                point: [-100.27599259289006,20.608619760256808]
            },
            control_number: "TEST-01",
            device_category: "6303f88cc1dbcf03e4a4eaad",
            timestamp: new Date()
        },
        // equipmentLoc
    ];

    for (const item of equipmentList) {
        if(item.hasOwnProperty('kioLoc')){
            let currentCategory = equipmentCategories.find(category=>category.id === item.device_category);
            let floor = -3;
            if(item.kioLoc.floor!== null && item.kioLoc.floor!==undefined){
                floor = item.kioLoc.floor;
            }

            if(!!currentCategory) {
                let locationInfo= {
                    longitude: item.kioLoc.point[0],
                    latitude: item.kioLoc.point[1],
                    asset_tag: item.control_number,
                    device_category: currentCategory.name,
                    floor: floor,
                    date: moment(item.kioLoc.timestamp).format("MM/DD/YYYY HH:mm:ss")
                }

                mapkit.handleEquipmentGeolocation(locationInfo,allCallouts,smallCallouts,indoorOnly,activeFloor,currentFloor);
            }
        }
    }
    return mapkit;
}

export const renderSelectedEquipment = (renderSelectedEquipmentParams, handleFilterUsersByFloor)=>{
    const {activeFloor, allCallouts, smallCallouts, equipmentCategories, equipmentList, equipmentSelected, indoorOnly, mapkit} = renderSelectedEquipmentParams;
    let currentFloor=-3;
    if(activeFloor) {
        currentFloor = handleFilterUsersByFloor();
    }
        
    // //Clean all equipment annotations before update
    mapkit.handleRemoveEquipmentAnnotations();
    let selected = {};

    for (const item of equipmentList) {
        if(item.control_number !== equipmentSelected){
            if(item.hasOwnProperty('kioLoc')){
                let currentCategory = equipmentCategories.find(category=>category.id === item.device_category);
                let floor = -3;
                if(item.kioLoc.floor!== null && item.kioLoc.floor!==undefined){
                    floor = item.kioLoc.floor;
                }
 
                let locationInfo= {
                    longitude: item.kioLoc.point[0],
                    latitude: item.kioLoc.point[1],
                    asset_tag: item.control_number,
                    device_category: currentCategory.name,
                    floor: floor,
                    date: moment(item.kioLoc.timestamp).format("MM/DD/YYYY HH:mm:ss")
                }

                mapkit.handleEquipmentGeolocation(locationInfo,allCallouts,smallCallouts,indoorOnly,activeFloor,currentFloor);
            }
        }else{
            selected = item;
        }
    }

    if(selected.hasOwnProperty('kioLoc')){
        let currentCategory = equipmentCategories.find(category=>category.id === selected.device_category);
        let floor = -3;
        if(selected.kioLoc.floor!== null && selected.kioLoc.floor!==undefined){
            floor = selected.kioLoc.floor;
        }

        let locationInfo= {
            longitude: selected.kioLoc.point[0],
            latitude: selected.kioLoc.point[1],
            asset_tag: selected.control_number,
            device_category: currentCategory.name,
            floor: floor,
            date: moment(selected.kioLoc.timestamp).format("MM/DD/YYYY HH:mm:ss")
        }

        mapkit.handleEquipmentGeolocation(locationInfo,allCallouts,smallCallouts,indoorOnly,activeFloor,currentFloor);
    }

    return mapkit
};