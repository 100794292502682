import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    drawerDiv: {
      left: 10,
      backgroundColor: 'white',
      height: '100%',
      width: '265px',
      boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
      display: 'inline-table'
    },
    userContainer: {
      backgroundColor: 'white',
      borderTop: '1px solid #CECECE',
      borderBottom: '1px solid #CECECE',
      display: 'table',
      width: '100%'
    },
    userContainerClick: {
      backgroundColor: 'white',
      borderTop: '1px solid #CECECE',
      borderBottom: '1px solid #CECECE',
      display: 'table',
      width: '100%'
    },
    userContainerSelected: {
      backgroundColor: 'white',
      borderTop: '1px solid #CECECE',
      borderBottom: '1px solid #CECECE',
      display: 'table',
      width: '100%',
      backgroundColor: "#E3F2FD !important"
    },
    userTopSection: {
      display: 'flex',
    },
    initialsDiv: {
      display: 'inline'
    },
    controlDiv: {
      display: 'flex',
      width: '150px'
    },
    nameDiv: {
      display: 'inline',
      width: '150px'
    },
    userName: {
      paddingTop: '15px',
      color: '#6F6F6F',
      letterSpacing: '0.019em',
      paddingLeft: '10px',
      fontWeight: 'bold'
    },
    levelDiv: {
      display: 'inline',
      width: '50px'
    },
    userRole: {
      color: '#6F6F6F',
      letterSpacing: '0.019em',
      paddingLeft: '10px',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 200
    },
    userDate: {
      color: 'rgba(0, 0, 0, 0.35)',
      letterSpacing: '0.019em',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 200,
      paddingBottom: '10px',
      paddingTop: '10px',
      marginLeft: '20px'
    },
    equipmentDate: {
      color: 'rgba(0, 0, 0, 0.35)',
      letterSpacing: '0.019em',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 200,
      paddingBottom: '10px',
      paddingTop: '5px'
    },
    userStatus: {
      paddingTop: '10px',
      letterSpacing: '0.019em',
      paddingLeft: '10px',
      fontSize: '12px',
      letterSpacing: '0.02em',
      marginBottom: '10px'
    },
    initials: {
      backgroundColor: '#757575',
      margin: '10px',
      height: '30px',
      width: '30px',
      fontSize: '14px',
      borderRadius: '4px',
      textAlign: 'center',
    },
    initialsToClick: {
      backgroundColor: '#757575',
      margin: '10px',
      height: '30px',
      width: '30px',
      fontSize: '14px',
      borderRadius: '4px',
      textAlign: 'center',
      "&:hover": {
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
      },
    },
    initialsClicked: {
      backgroundColor: '#757575',
      margin: '10px',
      height: '30px',
      width: '30px',
      fontSize: '14px',
      borderRadius: '4px',
      textAlign: 'center',
      cursor: 'pointer',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
    },
    initialLbl: {
      paddingTop: '7px',
      color: 'white',
      fontWeight: 'bold',
      "&:hover": {
        paddingTop: '-5px',
      },
    },
    level: {
      width: '40px',
      height: '30px',
      textAlign: 'center'
    },
    levelLbl: {
      color: '#757575',
      fontWeight: 'bold',
      fontSize: '16px'
    },
    timeLbl: {
      fontWeight: 'bold',
      fontSize: '13px',
      width: '50px',
      color: '#6F6F6F',
      textAlign: 'center'
    },
    assetTagTitle: {
      color: '#6F6F6F',
      letterSpacing: '0.019em',
      paddingLeft: '10px',
      fontSize: '12px',
      fontWeight: 200
    },
    timeDiv:{
      display: 'flex',
      float:'right',
      paddingRight:'18px',
      paddingTop:'5px'
    },
    assetTag: {
      fontWeight: 900,
      marginLeft: '10px',
      color: '#757575'
    },
    listContainer: {},
    chatIconContainer: {
      "&:hover": {
        cursor: 'pointer'
      },
    },
    searchBox: {
      backgroundColor: 'white',
    },
    input: {
      color: '#757575',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: 'none',
      width: '220px',
      marginLeft: '20px',
      marginTop: '15px',
      borderRadius: '7px',
      height: '37px',
      paddingLeft: '10px'
    },
    unreadDot: {
      backgroundColor: 'red',
      height: '7px',
      width: '7px',
      borderRadius: '7px',
      transform: 'translate(30px, 8px)'
    },
    cleanBtn: {
      height: '10px',
      width: '10px',
      marginBottom: '13px'
    },
    cleanIcon: {
      fontSize: '12px',
      fill: '#6F6F6F'
    },
    userTopSectionSkeleton: {
      display: 'flex',
      marginTop:'10px'
    },
    chatIconContainerSkeleton: {
      width: '15px',
      height:'15px',
      marginLeft:'20px'
    },
    userRoleSkeleton:{
      width: '30px',
      paddingLeft: '10px',
      fontWeight: 200
    },
    userRoleLargeSkeleton:{
      width: '90px',
      paddingLeft: '10px',
      fontWeight: 200
    },
    initialsSkeleton: {
      margin: '10px',
      height: '30px',
      width: '30px',
      borderRadius: '4px',
    },
    levelSkeleton: {
      width: '30px',
      height: '31px',
      marginLeft:'10px'
    },
  }));