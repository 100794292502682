import {CREATE_LOG, GET_LOGS, ERROR} from '../actions/logs/types';

const initialState = {
    dialogError:'',
    logs: []
}

export default function( state = initialState, action){
    switch(action.type){
        case CREATE_LOG:
            return initialState;
        case GET_LOGS:
            return{
                ...state,
                logs: action.payload.logs
            }
        case ERROR:
            return{
                ...state,
                dialogError:action.payload.dialogError
            }
        default:
            return state;
    }
}