import React from "react";
import moment from "moment";

import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";
import Timeline, {
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
  TodayMarker
}  from 'react-calendar-timeline';

import MasterButton from "../../../utilities/button.component";
import JobInfoCard from "../infoCard/jobsInfoCard.component";

import unassignUserDisabled from '../../../../img/unassign-user-disabled.svg';
import unassignUserEnabled from '../../../../img/unassign-user-enabled.svg';
import jobLocked from "../../../../img/jobLocked.svg";

import { jobStatusTypesGraphQL, ERROR_TASKS } from "../jobsBoard.constants";

import {styles} from './jobsCalendar.styles';
import './jobsCalendar.css';
import { 
    ADD_ROW_BTN_LBL,
    ASSIGN_USER_BTN_LBL,
    CLEAR_ROW_BTN_LBL,
    DELETE_ROW_BTN_LBL,
    INPROGRESS_TOOLTIP,
    REMOVE_JOB, 
    UNASSIGN_TOOLTIP} from "./jobsCalendar.constants";
import { handleGetJobStyle, handleCheckRowPosition } from "./jobsCalendar";

const JobCustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
    height: 10,
    width: 10
  }
})(Tooltip);

class JobsCalendar extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isEdit: this.props.editBoard,
            showRowButtons: this.props.showRowButtons,
            scrollMaxTime: 0,
            scrollMinTime: 0,
        }
        this.slotsList = [];
    }

    componentDidUpdate(){
        if (this.props.editBoard && this.state.isEdit !== this.props.editBoard) {
            this.setState({isEdit: true});
        }
    }

    handleJobClick = (item) => {
        if (!this.props.editBoard) {
            this.props.handleShowJobInfo(item);
        }
    }

    itemRenderer = ({
        item,
        timelineContext,
        itemContext,
        getItemProps,
        getResizeProps
      }) => {
        let jobStyle = handleGetJobStyle(item);
        const lastRowItem =  handleCheckRowPosition(item);
        if (item.visible) {
            return (
                <div
                  {...getItemProps({style: jobStyle})}
                  id={item.id}
                >
                    {item.template ? (
                      <div
                          onClick={()=> this.handleJobClick(item)}
                          id={`${item.id}-webNavv-workflowAutomation-jobsBoard-job-1.0`}
                          style={{
                              height: itemContext.dimensions.height,
                              overflow: "hidden",
                              paddingLeft: 3,
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontWeight: "bold"
                          }}
                          >
                          {itemContext.title}
                      </div>
                    ):(
                      <JobCustomTooltip
                          id={`${item.id}-webNavv-workflowAutomation-jobsBoard-itemTooltip-1.0`}
                          placement={lastRowItem? "top": "bottom"}
                          title={
                          <React.Fragment>
                              <JobInfoCard
                                  groups={this.props.groups}
                                  items={this.props.items}
                                  currentItemSelected={item.id}
                                  lastRowItem={lastRowItem}
                              />
                          </React.Fragment>
                          }>
                          <div
                          onClick={()=> this.handleJobClick(item)}
                          id={`${item.id}-webNavv-workflowAutomation-jobsBoard-job-1.0`}
                          style={{
                              height: itemContext.dimensions.height,
                              overflow: "hidden",
                              paddingLeft: 3,
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontWeight: "bold"
                          }}
                          >
                          {itemContext.title}
                          </div>
                      </JobCustomTooltip>
                    )}
      
                </div>
              );   
        }
        else {
            return null
        }
    };

    handleRenderAssignButton = (group) =>{
        return(
            <div key={group.id}>
                <MasterButton
                    id={`${group.id}-webNavv-workflowAutomation-jobsBoard-assignButton-1.0`}
                    keyLbl={`${group.id}-webNavv-workflowAutomation-jobsBoard-assignButton-1.0`}
                    label={ASSIGN_USER_BTN_LBL}
                    buttonType={"success"}
                    isDisabled={group.status !== jobStatusTypesGraphQL.unassigned ? true : false}
                    handleClick={() =>this.props.handleShowUsersList(group.id, `${group.id}-webNavv-workflowAutomation-jobsBoard-assignButton-1.0`)}
                    size={"medium"}
                    class={this.props.classes.JobsCalendar_BlueButton}
                    startIcon={"add"}/>
            </div>
        )
    }

    handleRenderSlotAssignedEditable = (group) =>{
        return(
        <div className="jobsCalendar-iconButtonContainer">
            <MasterButton
            id={`${group.id}-user-button`}
            keyLbl={`${group.id}-user-button`}
            label={group.title}
            buttonType={"success-flat"}
            handleClick={() =>this.props.handleShowUsersList(group.id, `${group.id}-user-button`)}
            size={"medium"}
            class={ !!group.title && group.title.length > 12? this.props.classes.JobsCalendar_UserButtonSmall : this.props.classes.JobsCalendar_UserButton}/>
            <Tooltip  id={`${group.id}-unassign-button`} title={UNASSIGN_TOOLTIP}>
                    <div 
                    id="webNavv-workflowAutomation-jobsBoard-unassignButton-1.0"
                    className="jobsCalendar-iconButtonEnabled"
                    onClick={()=>this.props.handleUnassign(group.id)}
                    >
                        <img
                        src={unassignUserEnabled}
                        className="jobsCalendar-SlotIcon"
                        alt="unassignUserEnabled-icon"/>
                    </div>
            </Tooltip>
        </div>
        )
    }

    handleRenderSlotAssignedNotEditable = (group) =>{
        return(
            <div className="jobsCalendar-iconButtonContainer">
                <div className={ !!group.title && group.title.length > 12? "jobsCalendar-groupTitleDisabledSmall" : "jobsCalendar-groupTitleDisabled"}>{group.title}</div>
                <div 
                    id="webNavv-workflowAutomation-jobsBoard-jobLocked-1.0"
                    className="jobsCalendar-iconButton"
                    >
                        <Tooltip  id={`${group.id}-locked-button`} title={INPROGRESS_TOOLTIP}>
                            <img
                            src={jobLocked}
                            className="jobsCalendar-SlotIcon"
                            alt="unassignUserDisabled-icon"/>
                        </Tooltip>
                    </div>
            </div>
        );
    }

    handleRenderActionsRow = (group) =>{
        return(
            <div className="jobsCalendar-RemoveJobLabel">{group.title}</div>
        )
    }

    groupRenderer = ({group}) =>{
        switch(group.status){
            case jobStatusTypesGraphQL.assigned:
                return this.handleRenderSlotAssignedEditable(group);
            case jobStatusTypesGraphQL.unassigned:
                return this.handleRenderAssignButton(group);
            case 'actions':
                return this.handleRenderActionsRow(group);
            case 'templates':
                return this.handleRenderActionsRow(group);
            default:
                return this.handleRenderSlotAssignedNotEditable(group);
        }
    }

    handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
        const minTime = moment(this.props.startDate).valueOf()
        const maxTime = moment(this.props.endDate).valueOf()

        if (visibleTimeStart > minTime && visibleTimeEnd < maxTime){
            this.setState({
                scrollMinTime: visibleTimeStart,
                scrollMaxTime: visibleTimeEnd
            });
            this.props.updateTimeWindowFromScroll(visibleTimeStart, visibleTimeEnd);
        }
    };

    handleRenderTimelineInEditMode = () =>{
        return(
            <Timeline
            groups={this.props.groups}
            items={this.props.items}
            visibleTimeStart={moment(this.props.calendarStartDate).valueOf()}
            visibleTimeEnd={moment(this.props.calendarEndDate).valueOf()}
            itemRenderer={this.itemRenderer}
            groupRenderer={this.groupRenderer}
            canMove={true}
            canResize={false}
            onItemMove={this.props.onItemMove}
            itemsSorted
            lineHeight={60}
            onTimeChange={this.handleTimeChange}
            horizontalLineClassNamesForGroup={group => {
                if (group.status === jobStatusTypesGraphQL.inProgress) {
                    return ["disabledRow"]
                }
                else if (group.status === 'actions'){
                    return ["actionsRow"]
                }
                else if (group.status === 'templates'){
                    return ["templatesRow"]
                }
                return ""
            }}
            >
            <TimelineHeaders>
                <DateHeader unit="hour" labelFormat={"hh A"}/>
            </TimelineHeaders>
            <TodayMarker />
            </Timeline>
        )
    }

    handleRenderTimelineInDefaultMode = () =>{
        return(
            <Timeline
            groups={this.props.groups}
            items={this.props.items}
            visibleTimeStart={moment(this.props.calendarStartDate).valueOf()}
            visibleTimeEnd={moment(this.props.calendarEndDate).valueOf()}
            itemRenderer={this.itemRenderer}
            groupRenderer={this.groupRenderer}
            onTimeChange={this.handleTimeChange}
            canMove={false}
            canResize={false}
            itemsSorted
            lineHeight={60}
            >
            <TimelineHeaders>
                <DateHeader unit="hour" labelFormat={"hh A"}/>
            </TimelineHeaders>
            <TodayMarker />
            </Timeline>
        )
    }

    handleRenderAddJobButton = () =>{
        return(
            <div className="jobsCalendar-BottomBtnContainer">
              <div className="jobsCalendar-BottomBtnDiv">
                <MasterButton
                keyLbl={'add-job-row'}
                label={ADD_ROW_BTN_LBL}
                buttonType={"success"}
                isDisabled={false}
                handleClick={() =>this.props.handleAddRow()}
                size={"medium"}
                class={this.props.classes.JobsCalendar_BlueButton}
                startIcon={"add"}/>
              </div>
            </div>
        )
    }

    handleRenderRowButtons = () =>{
        return(
            <div className="jobsCalendar-BottomBtnContainer">
              <div className="jobsCalendar-BottomBtnDiv">
                <MasterButton
                keyLbl={'clear-row'}
                label={CLEAR_ROW_BTN_LBL}
                buttonType={"success"}
                isDisabled={false}
                handleClick={()=>this.props.handleOpenModal('clear')}
                size={"small"}
                class={this.props.classes.JobsCalendar_GreenButton}
                startIcon={"refresh"}/>
              </div>
              <div className="jobsCalendar-OrLbl">or</div>
              <div className="jobsCalendar-BottomCancelBtnDiv">
                <MasterButton
                keyLbl={'delete-row'}
                label={DELETE_ROW_BTN_LBL}
                buttonType={"error"}
                isDisabled={false}
                class={this.props.classes.JobsCalendar_DefaultButtonText}
                handleClick={()=>this.props.handleOpenModal('delete')}
                size={"small"}
                startIcon={"trashCan"}/>
              </div>
            </div>
        )
    }

    render(){
        const {isEdit} = this.state;
        return(
            <div id="webNavv-workflowAutomation-jobsBoard-jobsScheduler-1.0" style={{marginBottom: "20px"}}>
                <div className="jobsCalendar-TimelineContainer" id="calendar-container">
                    {isEdit? (
                        this.handleRenderTimelineInEditMode()
                    ):(
                        this.handleRenderTimelineInDefaultMode()
                    )}
                </div>
                <div>
                    {isEdit && this.props.showRowButtons && (this.handleRenderRowButtons())}
                    {isEdit && !this.props.showRowButtons && (this.handleRenderAddJobButton())}
                </div>
            </div>
        )
    }
}

export default (withStyles(styles)(JobsCalendar));