import React from "react";
import moment from "moment";

import { Typography, Chip} from "@material-ui/core";
import { Timeline, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot} from "@material-ui/lab";
import { CheckCircle, RadioButtonUnchecked, AccessTime, Error, LocationOn, Flag} from "@material-ui/icons";

import "./routeInfo.css";
import { useStyles, CustomTimelineItem} from "./routeInfo.styles";
import { ROUTE_INFO_TITLE, START_POINT } from "./routeInfo.constants";
import { STATUS_STYLES, ERROR_TASKS, taskStatusTypesGraphQL } from "../jobsBoard.constants";
import { getStartPointDate, getStartPointDateFlagColor, handleErrorDescription} from "./routeInfo";
import { getFloorColorByOrdinal } from "../../../map/floorColors";

const RouteInfoCard = (props) =>{
    const classes = useStyles();
    const {jobTasks, currentItemSelected, items} = props;
    const currentJob = items.find(item=> item.id === currentItemSelected);

    const handleRenderFloorLevel = (step) =>{
        const { mapConfigs } = props;
        
        const currentFloor = !!mapConfigs.floors ? mapConfigs.floors.find(floor=>floor.label === step.floor_name) : null;
        const style = !!currentFloor ? {backgroundColor: getFloorColorByOrdinal(currentFloor.ordinal), color: "white"} : {backgroundColor: 'lightgray'};
        const floorClass = !!step.floor_name ? classes.RouteInfoCard_stepperFloor : classes.RouteInfoCard_stepperFloorNA;
        const floorLabel = !!currentFloor ? `L-${currentFloor.label}` : 'NA';

        return(
            <React.Fragment>
                <Typography  
                  style={style}
                  className={floorClass}>
                    {floorLabel}
                </Typography>
            </React.Fragment>
        )
    }

    const handleRenderTaskIcons = (step) =>{
        if (step.task_status === taskStatusTypesGraphQL.complete){
            return(
                <TimelineDot className={classes.RouteInfoCard_timelineDot}>
                    <CheckCircle style={{fill: STATUS_STYLES.complete.color}}/>
                </TimelineDot>
            )
        }
        else if (step.task_status === taskStatusTypesGraphQL.inProgress){
            return(
                <TimelineDot className={classes.RouteInfoCard_timelineDot}>
                    <AccessTime style={{fill: STATUS_STYLES.inProgress.color}}/>
                </TimelineDot>
            )
        }
        else if (!!step.job_error_number) {
            return(
                <TimelineDot className={classes.RouteInfoCard_timelineDot}>
                    <Error style={{fill: STATUS_STYLES.error.color}}/>
                </TimelineDot>
            )
        }
        else {
            return(
                <TimelineDot className={classes.RouteInfoCard_timelineDot}>
                    <RadioButtonUnchecked style={{fill: STATUS_STYLES.unassigned.color}}/>
                </TimelineDot>
            )
        }
    }

    const handleRenderTaskTime = (step) =>{
        if (!!step.job_error_number) {
            return(
                <Typography className={classes.RouteInfoCard_stepperContent}>
                    {!!step.error_date ? moment(step.error_date).format("hh:mm:ss A") : 'N/A'}
                </Typography>
            )
        }
        else{
            return(
                <Typography className={classes.RouteInfoCard_stepperContent}>
                    {!!step.task_start_date ? moment(step.task_start_date).format("hh:mm:ss A") : 'TBA'}
                </Typography>
            )
        }

    }

    const handleRenderErrorDescription = (step) =>{
        if (!!step.job_error_number) {
            const errorDescription =  handleErrorDescription(step.job_error_number);
            return(
                <Chip
                label={errorDescription}
                className={classes.RouteInfoCard_ErrorChip}
                style={{backgroundColor: STATUS_STYLES.error.backgroundColor, color: STATUS_STYLES.error.color}}
                icon={<Error style={{fill: STATUS_STYLES.error.color}}/>}/>
            )
        }
    }

    const handleRenderStartPointErrorDescription = () =>{
        return(
            <Chip
            label={ERROR_TASKS.jobStartDelay.description}
            className={classes.RouteInfoCard_ErrorChip}
            style={{backgroundColor: STATUS_STYLES.error.backgroundColor, color: STATUS_STYLES.error.color}}
            icon={<Error style={{fill: STATUS_STYLES.error.color}}/>}/>
        )
    }

    const handleRenderStartPointInfo = () =>{
        const startPointDate = getStartPointDate(currentJob);
        let startPointError = false;
        if (currentJob.job_error_number === ERROR_TASKS.jobStartDelay.key || (!!currentJob.job_errors && currentJob.job_errors.includes(ERROR_TASKS.jobStartDelay.key)) ) {
            startPointError = true;
        }
        const startPointFlagColor = getStartPointDateFlagColor(startPointError, currentJob);
        return(
            <CustomTimelineItem key={'startPoint'}>
                <TimelineSeparator>
                    <TimelineDot className={classes.RouteInfoCard_timelineDot}>
                        <Flag style={{fill: startPointFlagColor}}/>
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography  
                        style={{backgroundColor: '#2843A3'}}
                        className={classes.RouteInfoCard_stepperFloor}>
                            <LocationOn style={{fill: 'white'}}/>
                        </Typography>
                        <Typography className={classes.RouteInfoCard_stepperLabel}>
                            {START_POINT}
                        </Typography>
                        <Typography className={classes.RouteInfoCard_stepperContent}>
                            {startPointDate}
                        </Typography>
                        {startPointError && handleRenderStartPointErrorDescription()}
                    </TimelineContent>
            </CustomTimelineItem>
        )
    }

    return(
        <div id="webNavv-workflowAutomation-jobsBoard-jobRoute-cardContainer-1.0" className="RouteInfoCard_Container">
            <div className="RouteInfoCard_Title">{ROUTE_INFO_TITLE}</div>
            <div className="RouteInfoCard_Timeline">
                <Timeline position="alternate">
                    {handleRenderStartPointInfo()}
                    {jobTasks.map((step, index)=>(
                    <CustomTimelineItem key={index}>
                        <TimelineSeparator>
                        {handleRenderTaskIcons(step)}
                        {index !== jobTasks.length-1 && (<TimelineConnector />)}
                        </TimelineSeparator>
                        <TimelineContent>
                            {handleRenderFloorLevel(step)}
                            <Typography className={classes.RouteInfoCard_stepperLabel}>
                                {step.location_name}
                            </Typography>
                            {handleRenderTaskTime(step)}
                            {handleRenderErrorDescription(step)}
                        </TimelineContent>
                    </CustomTimelineItem>
                    ))}
                </Timeline>
            </div>
        </div>
    );
}

export default RouteInfoCard