import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nestedTxt: {
    color: 'white',
    fontSize: "12px !important",
    letterSpacing: '0.019em',
    marginLeft: '30px'
  }
}));

export default function CustomizedExpansionPanels(props) {
  const {windowDistance, windowDistances, historyRoute, index, workers, jobInfo} = props;
  const classes = useStyles();
  let jobData = jobInfo.find(value=> value.job === historyRoute[0].jobId) || {};
  // let distanceLbl = jobData.distance >= 0 ? `${jobData.distance} miles` : `Distance: N/A`;
  // let percentageLabel = jobData.percentage >= 0 && jobData.traceHealingJob ? ` ${Number(jobData.percentage).toPrecision(3)}%` : ' none';
  let jobMatch = false;
  let windowDistancesAvailable = false;
  let startDate = moment(jobData.startDate);
  let endDate = moment(jobData.endDate);
  let jobDistance = [];
  let jobDevice = jobData.device;
  if (windowDistance) {
    jobDistance = windowDistances.find(job => job[0] === historyRoute[0].jobId) || [];
    if (jobDistance.length > 0) {
      jobMatch = true;
      if (windowDistances.length > 0) {
        windowDistancesAvailable = jobDistance[1] !== undefined ? true : false;
      }
    }
  }
  // const appData = historyRoute[0]['User-Agent'].split(" ");
  // const appInfo = `${appData[1]}  ${appData[2]}`;

  return (
    <div style={{marginLeft: '80px'}}>
      <p className={classes.nestedTxt} style={{marginTop: '0px'}}><b style={{marginRight: '92px'}}>{`Job ${index + 1} ID:`}</b> {historyRoute[0].jobId}</p>
      <p className={classes.nestedTxt} ><b style={{marginRight: '97px'}}>Worker:</b> {workers[index][0].first_name} {workers[index][0].last_name}</p>
      <p className={classes.nestedTxt} ><b style={{marginRight: '111px'}}>Role:</b> {workers[index][0].role_name}</p>
      <p className={classes.nestedTxt} ><b style={{marginRight: '98px'}}>Device:</b> {jobDevice}</p>
      <p className={classes.nestedTxt} ><b style={{marginRight: '98px'}}>Status:</b> {jobData.status}</p>
      {/* <p className={classes.nestedTxt} ><b style={{marginRight: '52.5px'}}>Total Distance:</b> {distanceLbl}</p> */}
      {/* {!windowDistance &&
        <p className={classes.nestedTxt} ><b style={{marginRight: '40px'}}>Current Distance:</b>{distanceLbl}</p>
      }
      {jobMatch && windowDistancesAvailable &&
        <p className={classes.nestedTxt} ><b style={{marginRight: '36px'}}>Current Distance:</b> {jobDistance[1].toFixed(3)} miles</p>
      }
      {!jobMatch && windowDistance &&
        <p className={classes.nestedTxt} ><b style={{marginRight: '35px'}}>Current Distance:</b> 0 miles</p>
      } */}
      <p className={classes.nestedTxt} ><b style={{marginRight: '77px'}}>Start Date:</b> {startDate.format('MM-DD-YYYY HH:mm:ss')}</p>
      <p className={classes.nestedTxt} ><b style={{marginRight: '82px'}}>End Date:</b> {endDate.format('MM-DD-YYYY HH:mm:ss')}</p>
      {/* <p className={classes.nestedTxt} ><b style={{marginRight:'112px'}}>App:</b> {appInfo}</p> */}
      {/* <p className={classes.nestedTxt} style={{marginBottom: '0px'}}><b style={{marginRight: '49px'}}>Healing Status:</b>{percentageLabel}</p> */}
    </div>
  );
}
