import lightBlue from "@material-ui/core/colors/lightBlue";
export const styles = theme => ({
  snackMsg: {
    display: 'inline',
    color: 'white'
  },
  addBtn: {
    position: 'relative',
    marginLeft: '95%',
    bottom: 100,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2843A3',
  },
  problemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  },
  statusLblContainer:{
    display: "flex",
    justifyContent: "start",
    padding: "none",
    color:"#757575"
  },
  statusLblDiv:{
    margin: '0 8px 0 0',
    height: 8,
    width: 8,
    borderRadius: 4.5,
    position: "relative",
    top: 6
  },
});