import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';

import './mappedIn.component.css';
import { getACL, setTokenUserId } from '../../actions/auth/authActions';
import { SCRIPT_SRC } from './mappedIn.constants';
import { MAP } from './mappedIn';

function WayFinding(props){
  const [showMap,setShowMap] = useState(false);
  let script;

    useEffect(()=>{
      script = loadScript();
      setShowMap(true);
      MAP();
     return()=>{
       document.body.removeChild(script);
     }
    },[]);

    const loadScript = ()=>{
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src=SCRIPT_SRC;
      document.body.appendChild(s);
      return s;
    }

    return(
      <React.Fragment>
        {showMap && (
        <div className="main-container">
          <div id="mappedinContainer">
            <div id="mappedin-map"></div>
            <main data-key="externalId" data-lang="en" data-venue="henry-ford-cancer-center" id="mappedin-app"></main>
          </div>
        </div>
        )}
      </React.Fragment>
    );
};

const mapStateToProps = state => ({
  userInfo:state.auth.userInfo,
  acl:state.auth.acl,
  campusInfo:state.auth.userInfo.campusInfo,
});
export default connect(mapStateToProps,{
  getACL,
  setTokenUserId})(withRouter(WayFinding));