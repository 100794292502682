import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {timeWindowStyles, CustomSlider} from '../styles';
import MasterButton from '../../../utilities/button.component';

export default function TimeWindowSlider(props) {
  const classes = timeWindowStyles();
  const [startDateValue, setStartDateValue] = useState(props.defaultValues.startDate);
  const [endDateValue, setEndDateValue] = useState(props.defaultValues.endDate);
  const defaultStartDate = new Date(props.defaultValues.startDate).getTime();
  const defaultEndDate = new Date(props.defaultValues.endDate).getTime();
  const [value, setValue] = React.useState([defaultStartDate, defaultEndDate]);
  const [startDateLbl, setStartDateLbl] = useState(moment(props.defaultValues.startDate).format("MM/DD/YYYY"));
  const [endDateLbl, setEndDateLbl] = useState(moment(props.defaultValues.endDate).format("MM/DD/YYYY"));
  const [startHrsLbl, setStartHrsLbl] = useState(moment(props.defaultValues.startDate ).format("HH:mm:ss"));
  const [endHrsLbl, setEndHrsLbl] = useState(moment(props.defaultValues.endDate).format("HH:mm:ss"));

  useEffect(()=>{
    thumbColor();
  }, []);

  const handleChange = (event, newValue) => {
    //labels
    setStartDateLbl(moment(newValue[0]).format("MM/DD/YYYY"));
    setStartHrsLbl(moment(newValue[0]).format("HH:mm:ss"));
    setEndDateLbl(moment(newValue[1]).format("MM/DD/YYYY"));
    setEndHrsLbl(moment(newValue[1]).format("HH:mm:ss"));
    //Slider value
    setValue(newValue);
    //Values of 'time Window'
    let startD = moment(new Date(newValue[0])).format("YYYY-MM-DDTHH:mm:ss");
    let endD = moment(new Date(newValue[1])).format("YYYY-MM-DDTHH:mm:ss");
    setStartDateValue(startD);
    setEndDateValue(endD);

    timeWindowDates();
  };

  const timeWindowDates = ()=>{
    let timeWindow = [startDateValue, endDateValue, 'slider'];
    props.setWindowDistanceFlag(true);
    props.timeWindow(timeWindow);
  };

  const clearTimeWindow = ()=>{
    props.setWindowDistanceFlag(false);
    setValue([defaultStartDate, defaultEndDate]);
    setStartDateValue(props.defaultValues.startDate);
    setEndDateValue(props.defaultValues.endDate);
    props.resetHistoryTrace();
  };

  const thumbColor = ()=>{
    if (document.querySelector("[data-index='0']")) {
      let start = document.querySelector("[data-index='0']");
      start.style.backgroundColor = '#0277bd';
    }

    if (document.querySelector("[data-index='1']")) {
      let end = document.querySelector("[data-index='1']");
      end.style.backgroundColor = '#757575';
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.startDate}>
          <p className={classes.dateTitle}>START</p>
          <p className={classes.timeLbl}>{startHrsLbl}</p>
          <p className={classes.dateLbl}>{startDateLbl}</p>
        </div>
        <div className={classes.slider}>
          <CustomSlider
            disabled ={props.animationView ? true : false}
            aria-labelledby="range-slider"
            value={value}
            min={defaultStartDate}
            max={defaultEndDate}
            onChange={handleChange}
          />
        </div>
        <div className={classes.endDate}>
          <p className={classes.dateTitle}>END</p>
          <p className={classes.timeLbl}>{endHrsLbl}</p>
          <p className={classes.dateLbl}>{endDateLbl}</p>
        </div>

        <div>
          <MasterButton
            keyLbl={'restart-animation'}
            label={''}
            buttonType={'success'}
            isDisabled={props.animationView? true:false}
            handleClick={clearTimeWindow}
            size={"medium"}
            startIcon={'refresh'}
            class={classes.containedBtn}
            styles={{marginLeft: '5px'}}
          />
        </div>
      </div>
    </div>
  );
}