export const styles = () => ({
  msgContainer:{
    display:'inline'
  },
  msgDiv:{
    display:'inline',
    fontWeight:'bold'
  },
  expireUserContainer:{
    display: "flex",
    justifyContent: "center",
    color:"#757575"
  },
  expireUserDiv:{
    margin: "0 2px",
    height: "9px",
    width: "9px",
    borderRadius: "4.5px",
    position: "relative",
    top: "13px"
  },
  defaultStatusContainer:{
    justifyContent: "center",
    display:"none",
    color:"#757575"
  },
  defaultStatusDiv:{
    margin: "0 2px",
    backgroundColor: "red",
    height: "9px",
    width: "9px",
    borderRadius: "4.5px",
    position: "relative",
    top: "5px"
  },
  statusLblContainer:{
    display: "flex",
    justifyContent: "center",
    color:"#757575"
  },
  statusLblDiv:{
    margin: "0 2px",
    height: "9px",
    width: "9px",
    borderRadius: "4.5px",
    position: "relative",
    top: "5px"
  },
  streamingContainer:{
    display: "flex",
    justifyContent: "center"
  },
  streamingDiv:{
    margin: "0 2px",
    height: "10px",
    width: "10px",
    position: "relative",
    top: "5px"
  },
  inactiveStreaming:{
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 4.5px 10px 4.5px',
    borderColor: 'transparent transparent orange transparent'
  },
  activeStreaming:{
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 4.5px 10px 4.5px',
    borderColor: 'transparent transparent #3FA33E transparent'
  },
  workerContainer:{
    display: "flex",
    justifyContent: "left"
  },
  workerBtn:{
    display:"inline-block",
    height:"auto",
    lineHeight:"1.6",
    textAlign:'left !important'
  },
  tableTitleDiv:{
    display:'flex',
    marginTop:"20px"
  },
  tableTitleIcon:{
    fill:'#2843A3',
    fontSize:'50px',
    opacity:0.7
  },
  tableTitleIconDiv:{
    marginTop:"1%"
  },
  tableTitleLbl:{
    color:'#2843A3',
    marginLeft:'1%',
  },
  numHeader:{
    textAlign: 'center',
    marginTop:"15px",
    fontSize:"14px",
    color:"gray"
  }
})