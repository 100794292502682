import {
    ANNOTATION_SUBTITLES,
    OUTSIDE,
} from '../../mapkitJS/IMDF.constants';

export const checkEquipmentFilter = (mapkit, equipmentVisible) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocBubble 
        || dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocDot 
        || dot.subtitle === ANNOTATION_SUBTITLES.equipmentCallout)) {
        dot.visible = equipmentVisible;
      }
    });
  
    return mapkit;
};
  
export const checkEquipmentCurrentFloorOnlyFilter = (mapkit, filterFloor, floor) =>{
    mapkit.forEach(dot=>{
      if (dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot && dot.data.floor !== floor) {
        dot.visible = false;
      }
      else if (dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot && dot.data.floor === floor) {
        dot.visible = true;
      }
    });
  
    return mapkit;
};
  
export const checkEquipmentIndoorOnlyFilter = (mapkit, filterIndoor, floor) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocBubble  || dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocDot) 
      && (dot.data.floor !== floor || dot.data.floor === null || dot.data.floor === OUTSIDE || dot.data.floor === -3)) {
        dot.visible = false;
      }
      else if ((dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocBubble  || dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot)
      && dot.data.floor === floor && dot.data.floor !== null && dot.data.floor !== OUTSIDE && dot.data.floor !== -3) {
        dot.visible = true;
      }
    });
  
    return mapkit;
};
  
export const checkEquipmentAllCalloutsFilterEnabled = (mapkit, calloutVisible) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle===ANNOTATION_SUBTITLES.equipmentCallout || dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = calloutVisible;
      }
      else if ((dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocBubble  || dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = false;
      }
    });
  
    return mapkit;
};

export const checkEquipmentAllCalloutsFilterDisabled = (mapkit) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle===ANNOTATION_SUBTITLES.equipmentCallout || dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = false;
      }
      else if((dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocBubble  || dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = false;
      }
    });

  return mapkit;
};
  
export const checkEquipmentMinimizeCalloutsFilter = (mapkit, smallVisible) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocBubble  || dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = smallVisible;
      }
      else if ((dot.subtitle===ANNOTATION_SUBTITLES.equipmentCallout || dot.subtitle===ANNOTATION_SUBTITLES.equipmentLocDot)) {
        dot.visible = false;
      }
    });
  
    return mapkit;
};