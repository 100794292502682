import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';

import { listUsers, createUser, patchUser, deleteUser } from '../../../../actions/user/userActions';
import { fetchACLS } from '../../../../actions/acl/aclActions';
import { userChatUpdated } from '../../../../actions/views/viewsActions';
import { convertLowerCase, validateEmail } from '../../../utilities/formValidation';
import * as CONSTS from './userForm.constants';
import AwesomeSpinner from '../../../utilities/spinner';
import MasterButton from '../../../utilities/button.component';
import usersIcon from '../../../../img/multicampus.png';
import { styles } from './styles';

class AddUsers extends React.Component {
  state = {
    open: true,
    currentRoleId: this.props.currentRoleId ? this.props.currentRoleId : '',
    currentRoleName: this.props.currentRoleName ? this.props.currentRoleName : '',
    isUpdate: this.props.isUpdate ? this.props.isUpdate : '',
    userUpdateId: this.props.userUpdateId ? this.props.userUpdateId : '',
    newFirstName: this.props.newFirstName ? this.props.newFirstName : '',
    newLastName: this.props.newLastName ? this.props.newLastName : '',
    newEmail: this.props.newEmail ? this.props.newEmail : '',
    badgeTag: this.props.badge ? this.props.badge : '',
    picture: null,
    pictureData: this.props.picture ? this.props.picture : null,
    roles: [],
    pass1: this.props.isUpdate ? CONSTS.DEFAULT_PASSWORD : '',
    pass2: this.props.isUpdate ? CONSTS.DEFAULT_PASSWORD : '',
    modalType: '',
    validPass: true,
    equalPass: true,
    openModal: false,
    openSnack: false,
    emailError: false,
    emailErrorType: '',
    formError: false,
    firstNameError: false,
    lastNameError: false,
    roleError: false,
    passError: false,
    changePassword: false,
    showSpinner: false,
    isActioninProgress: false,
    showPicture: false,
    authType: CONSTS.BASIC_AUTH
  }
  rolesList = this.props.rolesList;
  workerInfo = [];
  deleteUser = 0;

  componentDidMount() {
    if (this.props.roles.length > 0) {
      this.setState({ roles: this.props.roles });
    } else {
      this.props.fetchACLS()
        .then(res => {
          this.setState({ roles: res });
        });
    }
    if (!this.props.currentBasicAuth && this.props.isUpdate) {
      this.setState({ authType: CONSTS.ACTIVE_DIRECTORY })
    }
  }

  handlePerformedBy = () => {
    const { user, userId } = this.props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  updateUser = (user) => {
    const { userUpdateId, authType, pass1, equalPass, validPass, changePassword } = this.state;
    const { classes } = this.props;
    let performedBy = this.handlePerformedBy();
    if (changePassword && equalPass && validPass) {
      user.password = pass1;
    }
    user.id = userUpdateId + "";
    user.basic_auth = authType === CONSTS.BASIC_AUTH ? true : false;
    this.props.patchUser(userUpdateId, user).then(res => {
      if (!res.hasOwnProperty(CONSTS.MESSAGE)) {
        this.props.createUserLog(
          {
            action: CONSTS.UPDATE_USER_ACTION,
            description: `User "${user.first_name} ${user.last_name}" updated by "${performedBy}"`
          }
        );

        this.props.userChatUpdated(user);
        //this.props.socketChat.emit('refreshChat', userUpdateId);

        this.props.handleClose();
        let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" was updated successfully!</div>
        this.props.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCES, msg);
        this.setState({ showSpinner: false, isActioninProgress: false });
      } else {
        this.setState({
          emailError: true,
          emailErrorType: CONSTS.DUPLICATED_FORM_ERROR,
          showSpinner: false,
          isActioninProgress: false
        });
      }
    }).catch(err => {
      this.props.createUserLog({
        action: CONSTS.UPDATE_USER_ERROR,
        description: `User "${user.first_name} ${user.last_name}" couldn't be updated by "${performedBy}" due enpoint ${err}`
      });
      let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" couldn't be updated</div>
      this.props.handleOpenSnackbar(CONSTS.SNACKBAR_WARNING, msg);
      this.setState({ showSpinner: false, isActioninProgress: false });
    });
  };

  createUserMicrosoftAuth = (user) => {
    const { classes } = this.props;
    user.basic_auth = false;
    user.password = CONSTS.DEFAULT_PASSWORD;
    let performedBy = this.handlePerformedBy();
    this.props.createUser(user).then(res => {
      if (!res.hasOwnProperty(CONSTS.MESSAGE)) {
        this.props.createUserLog({
          action: CONSTS.CREATE_USER_ACTION,
          description: `User "${user.first_name} ${user.last_name}" created by "${performedBy}" as a Microsoft auth user`
        });
        this.props.handleClose();
        this.props.socketChat.emit('refreshChat', this.props.user.id);
        let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" created successfully!</div>
        this.props.listUsers();
        this.props.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCES, msg);
        this.props.handleClose();
        this.setState({ modalType: '', showSpinner: false, isActioninProgress: false });
      } else {
        this.setState({
          emailError: true,
          emailErrorType: CONSTS.DUPLICATED_FORM_ERROR,
          showSpinner: false,
          isActioninProgress: false
        });
      }
    }).catch(err => {
      this.props.createUserLog({
        action: CONSTS.CREATE_USER_ACTION_ERROR,
        description: `User "${user.first_name} ${user.last_name}" couldn't be created by "${performedBy}" due enpoint ${err}`
      });
      this.props.handleClose();
      this.props.socketChat.emit('refreshChat', this.props.user.id);
      let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" couldn't be saved!</div>
      this.props.handleOpenSnackbar(CONSTS.SNACKBAR_WARNING, msg);
      this.props.handleClose();
      this.setState({ modalType: '', showSpinner: false, isActioninProgress: false });
    });
  };

  createUserBasicAuth = (user) => {
    const { pass1 } = this.state;
    const { classes } = this.props;
    let performedBy = this.handlePerformedBy();
    let password = pass1;
    user.basic_auth = true;
    user.password = password.trim();
    this.props.createUser(user).then(res => {
      if (!res.hasOwnProperty("message")) {

        this.props.createUserLog({
          action: CONSTS.CREATE_USER_ACTION,
          description: `User "${user.first_name} ${user.last_name}" created by "${performedBy}" as a basic auth user`
        });
        this.props.handleClose();
        this.props.socketChat.emit('refreshChat', this.props.userId);
        let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" created successfully!</div>
        this.props.listUsers();
        this.props.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCES, msg);
        this.setState({ modalType: '', showSpinner: false, isActioninProgress: false });
      } else {
        this.setState({
          emailError: true,
          emailErrorType: CONSTS.DUPLICATED_FORM_ERROR,
          showSpinner: false,
          isActioninProgress: false
        });
      }
    }).catch(err => {
      this.props.createUserLog({
        action: CONSTS.CREATE_USER_ACTION_ERROR,
        description: `User "${user.first_name} ${user.last_name}" couldn't be created by "${performedBy}" due enpoint ${err}`
      });
      this.props.handleClose();
      this.props.socketChat.emit('refreshChat', this.props.userId);
      let msg = <div className={classes.snackMsg}>"<div style={{ display: 'inline', fontWeight: 'bold' }}>{user.first_name + ' ' + user.last_name}</div>" couldn't be saved!</div>
      this.props.handleOpenSnackbar(CONSTS.SNACKBAR_WARNING, msg);
      this.props.handleClose();
      this.setState({ modalType: '', showSpinner: false, isActioninProgress: false });
    });
  };

  handleSubmit = () => {
    let formChecked = this.checkForm();
    if (formChecked.formError) {
      this.setState({
        emailError: formChecked.emailError,
        emailErrorType: formChecked.emailErrorType,
        firstNameError: formChecked.firstNameError,
        lastNameError: formChecked.lastNameError,
        roleError: formChecked.roleError,
        passError: formChecked.passError
      });
    } else {
      this.setState({ showSpinner: true, isActioninProgress: true });
      const { newFirstName, newLastName, newEmail, currentRoleId, isUpdate, currentRoleName, equalPass, validPass, authType, badgeTag } = this.state;
      let roleId = currentRoleId !== '' ? currentRoleId : this.getRoleId(currentRoleName);
      const lowerCaseEmail = convertLowerCase(newEmail);
      let user = {
        first_name: newFirstName.trim(),
        last_name: newLastName.trim(),
        email: lowerCaseEmail.trim(),
        role: roleId,
        role_name: currentRoleName,
        campusId: this.props.campusId,
        username: this.handleCreateUserName(lowerCaseEmail),
        //profile_picture: pictureData,     //?Attatch picture here
        badge: badgeTag.trim()
      };
      if (isUpdate) {
        this.updateUser(user);
      } else {
        user = {...user, online: false, status: CONSTS.LOGGED_OUT};
        if (equalPass && validPass && authType === CONSTS.BASIC_AUTH) {
          this.createUserBasicAuth(user);
        }
        if (authType === CONSTS.ACTIVE_DIRECTORY) {
          this.createUserMicrosoftAuth(user);
        }
      }
    }
  }

  checkForm = () => {
    const { newEmail, isUpdate, currentRoleId, newFirstName, newLastName, pass1, authType, changePassword } = this.state;
    let email = false;
    let username = false;
    let errorType = '';
    let roleEmpty = false;

    if (newEmail === '') {
      email = true;
      errorType = CONSTS.EMPTY_FORM_ERROR;
    } else if (!validateEmail(newEmail)) {
      email = true;
      errorType = CONSTS.WRONG_FORMAT_FORM_ERROR;
    } else if (!isUpdate) {
      [email, username] = this.checkEmail(newEmail);
      if (email) {
        errorType = CONSTS.DUPLICATED_FORM_ERROR;
      }
      if (!email && username) {
        errorType = CONSTS.DUPLICATED_USERNAME;
      }
    }
    if (!isUpdate) {
      roleEmpty = currentRoleId === '' ? true : false;
    }

    let firstNameEmpty = newFirstName === '' ? true : false;
    let lastNameEmpty = newLastName === '' ? true : false;
    let passError = false;
    if (isUpdate && changePassword) {
      passError = pass1 !== '' || authType === CONSTS.ACTIVE_DIRECTORY ? false : true;
    }
    if (!isUpdate) {
      passError = !!pass1 || authType === CONSTS.ACTIVE_DIRECTORY ? false : true;
    }
    let form = email || username || firstNameEmpty || lastNameEmpty || roleEmpty || passError ? true : false;
    let errorEmailInput = email || username ? true : false;

    return {
      formError: form,
      emailError: errorEmailInput,
      emailErrorType: errorType,
      firstNameError: firstNameEmpty,
      lastNameError: lastNameEmpty,
      roleError: roleEmpty,
      passError: passError
    }
  }

  checkEmail = (email) => {
    let emailDuplicated = false;
    let usernameDuplicated = false;
    const lowerCaseEmail = convertLowerCase(email);
    let username = this.handleCreateUserName(lowerCaseEmail)
    this.props.allUsers.filter(e => {
      if (e.email === email) {
        emailDuplicated = true;
      }
    });
    this.props.allUsers.filter(e => {
      if (e.username === username) {
        usernameDuplicated = true;
      }
    });
    return [emailDuplicated, usernameDuplicated];
  }

  getRoleId = (value) => this.props.roles.filter(role => role.role === value)

  handleCreateUserName = (email) => email.replace(/@.+/, "").toLowerCase();

  handleUpdateRole = (event, value) => {
    let role = this.props.roles.filter(role => {
      if (role.role === value) {
        return true;
      } else {
        return false;
      }
    })
    if (role.length > 0) {
      this.setState({ currentRoleId: role[0]._id, currentRoleName: role[0].role, roleError: false });
    }
  }

  inputChange = (e, type) => {
    if (type === 1) {
      this.setState({ newFirstName: e.target.value, firstNameError: false })
    } else if (type === 2) {
      this.setState({ newLastName: e.target.value, lastNameError: false });
    } else if (type === 3) {
      this.setState({ newEmail: e.target.value, emailError: false, emailErrorType: '' });
    } else if (type === 4) {
      this.setState({ picture: e.target.value });
    } else if (type === 5) {
      this.setState({ badgeTag: e.target.value });
    }
  }

  handleTextFieldChange = (e, type) => {
    if (type === 1) {
      this.setState({ pass1: e.target.value, passError: false });
      if (this.props.isUpdate) {
        this.setState({ changePassword: true });
      }
      if (e.target.value === this.state.pass2 && !this.state.equalPass) {
        this.setState({ equalPass: true });
      }
      if (e.target.value !== this.state.pass2 && this.state.equalPass) {
        this.setState({ equalPass: false });
      }
    }
    if (type === 2) {
      if (e.target.value === this.state.pass1 && !this.state.equalPass) {
        this.setState({ equalPass: true });
      }
      if (e.target.value !== this.state.pass1 && this.state.equalPass) {
        this.setState({ equalPass: false });
      }
      this.setState({ pass2: e.target.value });
    }
  }

  handleOptionButtons = () => {
    const { equalPass, authType, isUpdate, isActioninProgress } = this.state;
    const { classes, users } = this.props;
    let userInfo = {};
    if (isUpdate) {
      userInfo = users.find(user => user._id === this.state.userUpdateId);
    }
    userInfo.id = this.state.userUpdateId;
    let defaultBtnConfig = { marginLeft: '30px', width: '40%' };

    return (
      <React.Fragment>
        {isUpdate ?
          <div className={classes.buttonContainerUpdate}>
            <MasterButton
              id={'webNavv-adminPanel-usersForm-disabled-1.0'}
              keyLbl={'upd-delete-user'}
              label={CONSTS.DELETE_BTN_LABEL}
              buttonType={CONSTS.BUTTON_TYPE.error}
              isDisabled={isActioninProgress}
              handleClick={() => this.props.handleShowDeleteModal(userInfo)}
              size={"medium"}
              endIcon={'trashCan'}
              class={classes.defaultBtnConfig}
            />
            <MasterButton
              id={'webNavv-adminPanel-usersForm-cancel-1.0'}
              keyLbl={'upd-cancel-user'}
              label={CONSTS.CANCEL_BTN_LABEL}
              buttonType={CONSTS.BUTTON_TYPE.default}
              isDisabled={isActioninProgress}
              handleClick={this.props.handleClose}
              size={"medium"}
              class={classes.closeBtnConfig}
            />
            {equalPass || authType === CONSTS.ACTIVE_DIRECTORY ?
              <MasterButton
                id={'webNavv-adminPanel-usersForm-save-1.0'}
                keyLbl={'upd-save-user'}
                label={CONSTS.SAVE_BTN_LABEL}
                buttonType={CONSTS.BUTTON_TYPE.success}
                isDisabled={isActioninProgress}
                handleClick={this.handleSubmit}
                size={"medium"}
                styles={defaultBtnConfig}
                class={classes.containedBtn}
              />
              :
              <MasterButton
                id={'webNavv-adminPanel-usersForm-saveDisabled-1.0'}
                keyLbl={'upd-saveDisabled-user'}
                label={CONSTS.SAVE_BTN_LABEL}
                buttonType={CONSTS.BUTTON_TYPE.success}
                isDisabled={true}
                size={"medium"}
                styles={defaultBtnConfig}
                class={classes.containedBtn}
              />
            }
          </div>
          :
          <div className={classes.buttonContainer}>
            <MasterButton
            id={'webNavv-adminPanel-usersForm-cancel-1.0'}
              keyLbl={'add-cancel-user'}
              label={CONSTS.CANCEL_BTN_LABEL}
              buttonType={CONSTS.BUTTON_TYPE.default}
              isDisabled={isActioninProgress}
              handleClick={this.props.handleClose}
              size={"medium"}
              class={classes.closeBtnConfig}
            />
            {equalPass || authType === CONSTS.ACTIVE_DIRECTORY ?
              <MasterButton
                id={'webNavv-adminPanel-usersForm-save-1.0'}
                keyLbl={'add-save-user'}
                label={CONSTS.SAVE_BTN_LABEL}
                buttonType={CONSTS.BUTTON_TYPE.success}
                isDisabled={isActioninProgress}
                handleClick={this.handleSubmit}
                size={"medium"}
                styles={defaultBtnConfig}
                class={classes.containedBtn}
              />
              :
              <MasterButton
                id={'webNavv-adminPanel-usersForm-saveDisabled-1.0'}
                keyLbl={'add-saveDisabled-user'}
                label={CONSTS.SAVE_BTN_LABEL}
                buttonType={CONSTS.BUTTON_TYPE.success}
                isDisabled={true}
                size={"medium"}
                styles={defaultBtnConfig}
                class={classes.containedBtn}
              />
            }
          </div>
        }
      </React.Fragment>
    );
  };

  passwordOptions = () => {
    const { pass1, pass2, validPass, passError, equalPass, authType } = this.state;
    const { classes } = this.props;
    let password = authType === CONSTS.BASIC_AUTH ? pass1 : CONSTS.DEFAULT_PASSWORD;
    let password2 = authType === CONSTS.BASIC_AUTH ? pass2 : CONSTS.DEFAULT_PASSWORD;
    return (
      <React.Fragment>
        <div className={classes.passwordDiv}>
          <React.Fragment>
            <TextField
              className={classes.defaultInputConfig}
              error={validPass && !passError ? false : true}
              helperText={validPass && !passError ? '' : CONSTS.INCORRECT_ENTRY_ERROR}
              disabled={authType === CONSTS.BASIC_AUTH ? false : true}
              ref="pass"
              id="webNavv-adminPanel-usersForm-password-1.0"
              type="password"
              label={CONSTS.PASSWORD_INPUT_LABEL}
              variant="outlined"
              value={password}
              onChange={(e) => this.handleTextFieldChange(e, 1)}
            />
            <TextField
              className={classes.defaultInputConfig}
              error={equalPass && !passError ? false : true}
              helperText={equalPass && !passError ? '' : CONSTS.PASS_DONOT_MATCH}
              disabled={authType === CONSTS.BASIC_AUTH ? false : true}
              ref="pass2"
              id="webNavv-adminPanel-usersForm-confirmPassword-1.0"
              type="password"
              label={CONSTS.CONFIRM_PASS_INPUT_LABEL}
              variant="outlined"
              value={password2}
              onChange={(e) => this.handleTextFieldChange(e, 2)}
            />
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  };

  handlePassButtonsOnChange = (type) => {
    if (type === CONSTS.BASIC_AUTH) {
      if (this.props.isUpdate) {
        this.setState({ changePassword: true, pass1: '', pass2: '', authType: CONSTS.BASIC_AUTH });
      } else {
        this.setState({ authType: CONSTS.BASIC_AUTH });
      }
    }
    if (type === CONSTS.ACTIVE_DIRECTORY) {
      this.setState({ authType: CONSTS.ACTIVE_DIRECTORY });
    }
  };


  passwordButtons = () => {
    const { classes, isUpdate } = this.props;
    let firstBtnClass = {};
    let firstBtnLbl = '';
    let secondBtnClass = {};
    let secondBtnLbl = '';
    if (isUpdate) {
      firstBtnLbl = CONSTS.RESTORE_PASSWORD;
      if (this.props.open) {
        firstBtnClass = this.state.authType === CONSTS.RESTORE_PASSWORD ? classes.uploadBtnOpenNav : classes.uploadBtnCancelNavOpen;
      } else {
        firstBtnClass = this.state.authType === CONSTS.RESTORE_PASSWORD ? classes.uploadBtn : classes.uploadBtnCancel;
      }
      secondBtnLbl = CONSTS.ACTIVE_DIRECTORY;
      secondBtnClass = this.state.authType === CONSTS.ACTIVE_DIRECTORY ? classes.uploadBtn : classes.uploadBtnCancel;
    } else {
      firstBtnLbl = CONSTS.BASIC_AUTH;
      if (this.props.open) {
        firstBtnClass = this.state.authType === CONSTS.BASIC_AUTH ? classes.uploadBtnOpenNav : classes.uploadBtnCancelNavOpen;
      } else {
        firstBtnClass = this.state.authType === CONSTS.BASIC_AUTH ? classes.uploadBtn : classes.uploadBtnCancel;
      }
      secondBtnLbl = CONSTS.ACTIVE_DIRECTORY;
      secondBtnClass = this.state.authType === CONSTS.ACTIVE_DIRECTORY ? classes.uploadBtn : classes.uploadBtnCancel;
    }
    return (
      <div style={{ marginLeft: '2%' }}>
        <MasterButton
          keyLbl={'upload-cancel-first'}
          label={firstBtnLbl}
          buttonType={CONSTS.BUTTON_TYPE.defaultFlat}
          isDisabled={false}
          size={"medium"}
          handleClick={() => this.handlePassButtonsOnChange(CONSTS.BASIC_AUTH)}
          styles={{ marginLeft: '30px' }}
          class={firstBtnClass}
        />
        <MasterButton
          keyLbl={'upload-cancel-second'}
          label={secondBtnLbl}
          buttonType={CONSTS.BUTTON_TYPE.defaultFlat}
          isDisabled={false}
          size={"medium"}
          handleClick={() => this.handlePassButtonsOnChange(CONSTS.ACTIVE_DIRECTORY)}
          styles={{ marginLeft: '30px' }}
          class={secondBtnClass}
        />
      </div>
    );
  }
  validatePass = (e) => {
    let password = this.state.pass1;
    let password2 = this.state.pass2;
    let mEqual = false;
    let mValid = true;
    if (password !== password2) {
      mEqual = false;
    } else {
      mEqual = true;
    }
    this.setState({ validPass: mValid, equalPass: mEqual });
  };

  handleUpload = () => {
    if (document.getElementById(CONSTS.UPLOAD_INPUT_ID)) {
      document.getElementById(CONSTS.UPLOAD_INPUT_ID).click();
    }
  };

  handleUploadStatus = () => {
    if (document.getElementById(CONSTS.UPLOAD_INPUT_ID)) {
      if (document.getElementById(CONSTS.UPLOAD_INPUT_ID).files[0]) {
        let path = URL.createObjectURL(document.getElementById(CONSTS.UPLOAD_INPUT_ID).files[0]);
        this.setState({ picture: path, pictureData: document.getElementById(CONSTS.UPLOAD_INPUT_ID).files[0] });
      }
    }
  };
  handleCleanUploadInput = () => {
    this.setState({ picture: null });
    document.getElementById(CONSTS.UPLOAD_INPUT_ID).value = "";
  }

  backToTable = () => {
    this.props.handleClose();
  }

  render() {
    const { newEmail, newFirstName, newLastName, isUpdate, currentRoleName, emailError, emailErrorType, lastNameError, firstNameError, roleError, badgeTag } = this.state;
    const { classes } = this.props;
    this.workerInfo = [];
    let optionsList = this.rolesList.sort();
    let titleModal = isUpdate ? CONSTS.EDIT_USER : CONSTS.NEW_USER;
    return (
      <React.Fragment>
        <div id="webNavv-adminPanel-usersForm-1.0">
          <div className={classes.tableTop}>
            <div
              id="webNavv-adminPanel-usersForm-title-1.0"
              className={classes.titleContainer}
            >
              <img
                src={usersIcon}
                style={{
                  height: "35px",
                  width: "35px",
                  marginLeft: "2px",
                  display: "table-cell",
                }}
                alt="usersIcon"
              />
            </div>
            <div className={classes.titleLbl}>
              {titleModal}
            </div>
            <div style={{ float: 'right', width: '70%' }}>
              <p 
              id="webNavv-adminPanel-usersForm-backToTable-1.0"
              className={classes.back}
              onClick={() => this.backToTable()}
              >
                {CONSTS.BACK_TO_TABLE}
                </p>
            </div>
          </div>
          <div className={classes.container}>
            <div style={{ display: 'flex' }}>
              <div className={classes.mainDiv}>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"}>
                  <Grid item xs={12}>
                    <div style={{ marginTop: '2%' }}>
                      <div className={classes.infoSubtitle}>{CONSTS.INFORMATION_FORM_SUBTITLE}</div>
                      <div style={{ display: 'flex' }}>
                        <TextField
                          className={classes.defaultInputConfig}
                          id="webNavv-adminPanel-usersForm-firstName-1.0"
                          label={CONSTS.FIRST_NAME_INPUT_LABEL}
                          variant="outlined"
                          onChange={(e) => this.inputChange(e, 1)}
                          value={newFirstName}
                          error={firstNameError}
                          helperText={firstNameError ? CONSTS.FIRST_NAME_EMPTY_ERROR : ''}
                        />
                        <TextField
                          className={classes.defaultInputConfig}
                          id="webNavv-adminPanel-usersForm-lastName-1.0"
                          label={CONSTS.LAST_NAME_INPUT_LABEL}
                          variant="outlined"
                          onChange={(e) => this.inputChange(e, 2)}
                          value={newLastName}
                          error={lastNameError}
                          helperText={lastNameError ? CONSTS.LAST_NAME_EMPTY_ERROR : ''}
                        />
                      </div>
                      <div style={{ display: 'flex', marginTop: '3%' }}>
                        <TextField
                          className={classes.defaultInputConfig}
                          id="webNavv-adminPanel-usersForm-email-1.0"
                          label={CONSTS.EMAIL_INPUT_LABEL}
                          variant="outlined"
                          onChange={(e) => this.inputChange(e, 3)}
                          value={newEmail}
                          error={emailError}
                          helperText={emailError ? CONSTS.PASSWORD_ERRORS[emailErrorType] : ''}
                        />
                        <Autocomplete
                          className={classes.defaultInputConfig}
                          disableClearable
                          id={'webNavv-adminPanel-usersForm-role-1.0'}
                          options={optionsList}
                          getOptionLabel={(option) => option}
                          onChange={this.handleUpdateRole}
                          defaultValue={isUpdate ? currentRoleName : ''}
                          renderInput={(params) =>
                            <TextField {...params}
                              variant="outlined"
                              label={CONSTS.ROLE_INPUT_LABEL}
                              error={roleError}
                              helperText={roleError ? CONSTS.ROLE_EMPTY_ERROR : ''} />}
                        />
                      </div>
                      <div style={{ display: 'flex', marginTop: '3%' }}>
                        <Avatar className={classes.picture} src={this.state.picture || ""} id={"webNavv-adminPanel-usersForm-profileImg-1.0"} />
                        <input
                          accept="image/*"
                          hidden
                          id={CONSTS.UPLOAD_INPUT_ID}
                          type="file"
                          onChange={() => this.handleUploadStatus()}
                        />
                        <MasterButton
                          keyLbl={'upload-picture'}
                          label={CONSTS.UPLOAD_PICTURE}
                          buttonType={CONSTS.BUTTON_TYPE.success}
                          isDisabled={true}
                          size={"medium"}
                          handleClick={this.handleUpload}
                          styles={{ marginLeft: '30px' }}
                          class={this.props.open ? classes.uploadBtn : classes.uploadBtnOpenNav}
                          startIcon={CONSTS.UPLOAD_ICON_TYPE}
                        />
                        {this.state.picture && (
                          <MasterButton
                            id={'webNavv-adminPanel-usersForm-upload-1.0'}
                            keyLbl={'upload-cancel-user'}
                            label={CONSTS.CANCEL_BTN_LABEL}
                            buttonType={CONSTS.BUTTON_TYPE.default}
                            isDisabled={true}
                            size={"medium"}
                            handleClick={this.handleCleanUploadInput}
                            styles={{ marginLeft: '30px' }}
                            class={classes.uploadBtnCancel}
                          />
                        )}
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.passSubtitle}>{CONSTS.PASSWORD_FORM_SUBTITLE}</div>
                      {this.passwordOptions()}
                      {this.passwordButtons()}
                      <Divider className={classes.divider} />
                      <div className={classes.passSubtitle}>{CONSTS.BADGE_FORM_SUBTITLE}</div>
                      <div style={{ display: 'flex' }}>
                        <TextField
                          className={classes.badgeInput}
                          id="webNavv-adminPanel-usersForm-badge-1.0"
                          label={CONSTS.BADGE_INPUT_LABEL}
                          variant="outlined"
                          onChange={(e) => this.inputChange(e, 5)}
                          value={badgeTag}
                        />
                      </div>
                      {this.handleOptionButtons()}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <AwesomeSpinner config={CONSTS.SPINNER_DEFAULT_CONFIG} loading={this.state.showSpinner} navOpen={this.props.open} />
        </div>
      </React.Fragment>
    );
  }
}

AddUsers.propTypes = {
  users: PropTypes.array.isRequired,
  success: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  campusId: PropTypes.string.isRequired,
  listUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  userChatUpdated: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  users: state.user.users,
  success: state.user.success,
  roles: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
  socketChat: state.auth.socketChat,
  user: state.user.user,
  userId: state.auth.userId,
  open: state.views.drawerOpen,
  allUsers: state.user.allUsers
})

export default connect(mapStateToProps, {
  fetchACLS,
  listUsers,
  createUser,
  patchUser,
  userChatUpdated,
  deleteUser
})(withStyles(styles)(AddUsers));