import React from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import lightBlue from "@material-ui/core/colors/lightBlue";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

function CampusForm(props) {
  const formRowTypeAutocomplete = (label, key) => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          { key === 'name' && !_.isEmpty(props.campusSelected) && !props.editCampusName && (
            <div style={{display: 'flex'}}>
              <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
              <IconButton size="small" style={{marginLeft:'10px', marginTop:'5px'}} onClick={()=>props.handleEditCampusName(true)}>
                <EditIcon fontSize="inherit" style={{color: lightBlue[500]}}/>
              </IconButton>
            </div>
          )}
          { key === 'name' && !_.isEmpty(props.campusSelected) && props.editCampusName && (
            <div style={{display: 'flex'}}>
              <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
              <IconButton size="small" style={{marginLeft:'10px', marginTop:'5px'}} onClick={()=>props.handleEditCampusName(false)}>
                <CancelIcon fontSize="inherit" style={{color: lightBlue[500]}}/>
              </IconButton>
            </div>
          )}
          {key === 'name' && _.isEmpty(props.campusSelected) && (
            <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
          )}
          {key === 'team'&& (
            <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          { key === 'name' && !props.editCampusName &&
          <Autocomplete
            id={'campus-config'}
            options={props.campusList}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value)=>option.name === value.name}
            // defaultValue={{name:props.campus.name, id: props.campusSelected.id}}
            onChange={(event, newValue)=> !!newValue && props.handleUpdateInput(newValue.name, key, 'text')}
            style={{ width: 250 }}
            renderInput={(params) => <TextField {...params}/>}
          />
          }
          { key === 'name' && props.editCampusName &&
           <TextField
             style={{width: 250, backgroundColor:'#f5f5f5'}}
             value={props.campus[key] || ''}
             onChange={(e)=>props.handleUpdateInput(e.target.value, key, 'text')} />
          }
          { key === 'team' &&
            <Autocomplete
              id={'role-config'}
              options={props.campusRoles}
              getOptionLabel={(option) => option.role}
              // onChange={this.handleUpdateRole}
              style={{ width: 250 }}
              renderInput={(params) => <TextField {...params}/>}
            />
          }
        </Grid>
      </React.Fragment>
    );
  };

  const formRowTypeLongNumber = (label, key) =>{
    let numberProps = {};
    switch (key) {
      case 'latitude':
        numberProps = {step: ".00001"};
        break;
      case 'longitude':
        numberProps = {step: ".00001"};
        break;
    }
    let number = !!props.campus[key]? props.campus[key].toFixed(5): props.campus[key];
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            style={{width: 250, backgroundColor:'#f5f5f5'}}
            inputProps={numberProps}
            value={number || ''}
            onChange={(e)=>props.handleUpdateInput(e.target.value, key, 'float')} />
        </Grid>
      </React.Fragment>
    );
  };

  const formRowShowNumber = (label, key) =>{
    let numberProps = {};
    let error = false;
    let errorMsg = '';
    let inputNumberType = 'int';
    switch (key) {
      case 'floor':
        numberProps = {min: props.campus.firstFloor, max: props.campus.lastFloor};
        error = (props.campus[key] < props.campus.firstFloor || props.campus[key] > props.campus.lastFloor) ? true : false;
        errorMsg = error ? 'Invalid floor' : '';
        break;
      case 'rotation':
        numberProps = {step: ".1", min: 0};
        inputNumberType = 'float';
        break;
      case 'zoom':
        numberProps = {min: 0, max: 4};
        error = (props.campus[key] < 0 || props.campus[key] > 4) ? true : false;
        errorMsg = error ? 'Invalid zoom' : '';
        break;
    }

    if (!error) {
      return (
        <React.Fragment>
          <Grid item xs={4}>
            <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              style={{width: 70, backgroundColor:'#f5f5f5'}}
              value={props.campus[key] || 0}
              inputProps={numberProps}
              onChange={(e)=>props.handleUpdateInput(e.target.value, key, inputNumberType)}/>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item xs={4}>
            <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error
              helperText={errorMsg}
              type="number"
              style={{width: 70, backgroundColor:'#f5f5f5'}}
              value={props.campus[key] || 0}
              inputProps={numberProps}
              onChange={(e)=>props.handleUpdateInput(e.target.value, key, 'int')}/>
          </Grid>
        </React.Fragment>
      );
    }
  };

  const formRowDoubleNumber = (labelOne, keyOne, labelTwo, keyTwo) =>{
    let errorOne = (props.campus.firstFloor > props.campus.lastFloor) ? true : false;
    let errorMsgOne = errorOne ? 'Invalid first floor' : '';
    let errorTwo = (props.campus.lastFloor < props.campus.firstFloor) ? true : false;
    let errorMsgTwo = errorTwo ? 'Invalid last floor' : '';
    return (
      <React.Fragment>
        <Grid item xs={2}>
          <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{labelOne}</Paper>
        </Grid>
        <Grid item xs={3}>
          <TextField
              id="webNavv-settings-campusSettings-campusInfo-firstFloor-1.0"
              error={errorOne? true : false}
              helperText={errorOne? errorMsgOne : null}
              type="number"
              style={{width: 70, backgroundColor:'#f5f5f5'}}
              value={props.campus[keyOne] || 0}
              inputProps={{max: props.campus.lastFloor}}
              onChange={(e)=>props.handleUpdateInput(e.target.value, keyOne, 'int')}/>
        </Grid>
        <Grid item xs={2}>
          <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{labelTwo}</Paper>
        </Grid>
        <Grid item xs={3}>
          <TextField
              id="webNavv-settings-campusSettings-campusInfo-firstFloor-1.0"
              error={errorTwo? true:false}
              helperText={errorTwo? errorMsgTwo:null}
              type="number"
              style={{width: 70, backgroundColor:'#f5f5f5'}}
              value={props.campus[keyTwo] || 0}
              inputProps={{min: props.campus.firstFloor}}
              onChange={(e)=>props.handleUpdateInput(e.target.value, keyTwo, 'int')}/>
        </Grid>
      </React.Fragment>
    );
  };

  const formRowTypeText = (label, key) => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{width: 250, backgroundColor:'#f5f5f5'}}
            value={props.campus[key] || ''}
            onChange={(e)=>props.handleUpdateInput(e.target.value, key, 'text')} />
        </Grid>
      </React.Fragment>
    );
  };

  const formRowTypeTextDisabled = (label, key) => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            style={{width: 250, backgroundColor:'#f5f5f5'}}
            value={props.campus[key] || ''} />
        </Grid>
      </React.Fragment>
    );
  };
  const formRowUpload = (label, key, type) => {
    return (
      <React.Fragment>
        {!_.isEmpty(props.campusSelected) && (
          <React.Fragment>
            <Grid item xs={4}>
             <Paper style={{paddingTop: '10px', border: 'none', fontSize: '13px'}}>{label}</Paper>
            </Grid>
            <Grid item xs={3}>
             <input id={type} type='file' accept='.json' hidden/>
             <Chip
              disabled
              label="Select"
              variant="outlined"
              clickable
              onClick={()=>props.handleUpload(type)}
              style={props.fileFlags[key] ? {borderColor: '#8bc34a', color:'#8bc34a'} : {borderColor: lightBlue[500], color:lightBlue[500] }}
              icon={<AttachFileIcon style={props.fileFlags[key] ? {color: '#8bc34a'} : {color: lightBlue[500]}}/>}/>
            </Grid>
            <Grid item xs={3}>
              <Chip
                disabled={props.showSpinner || props.campusSelected[key]? false : true}
                label="Download"
                clickable
                onClick={()=>props.handleDownload(key)}
                icon={<GetAppIcon style={{color: 'rgba(0, 0, 0, 0.2)'}}/>}/>
            </Grid>
         </React.Fragment>
        )
        }
      </React.Fragment>
    );
  };

  return (
    <div id="webNavv-settings-campusSettings-campusInfo-1.0">
      <Grid container spacing={1}>
        {props.formList.map((item, i)=>{
          switch (item.inputType) {
            case 'autocomplete':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowTypeAutocomplete(item.label, item.key)}
                </Grid>
              );
            case 'text':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowTypeText(item.label, item.key)}
                </Grid>
              );
            case 'text-disabled':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowTypeTextDisabled(item.label, item.key)}
                </Grid>
              );
            case 'longNumber':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowTypeLongNumber(item.label, item.key)}
                </Grid>
              );
            case 'doubleNumber':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowDoubleNumber(item.labelOne, item.keyOne, item.labelTwo, item.keyTwo)}
                </Grid>
              );
            case 'number':
              return (
                <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                  {formRowShowNumber(item.label, item.key)}
                </Grid>
              );
            case 'upload-file':
            return (
              <Grid id={item.id} key={`row-${i}`} item container xs={12} justify="center">
                {formRowUpload(item.label, item.key, item.type)}
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
}

export default (CampusForm);