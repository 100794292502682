import { jobStatusTypesGraphQL } from "../../jobsBoard.constants";
import _ from 'lodash';

export const handleAssignUserToJob = async (items, currentSlot) => {
    const jobsCopy = [...items];
    // Set job assign to user (if applies)
    items.forEach((job, i)=>{
      if (job.resource_slotid === currentSlot.resource_slotid && (job.status === jobStatusTypesGraphQL.unassigned || job.status === jobStatusTypesGraphQL.assigned) ) {
        jobsCopy[i].job_assigned_user_name = currentSlot.assigned_user_name;
        jobsCopy[i].job_assigned_userid = currentSlot.assigned_userid;
      }
    });
  
    return jobsCopy;
}