import moment from "moment";
import {jobStatusTypesGraphQL} from "../../jobsBoard.constants";
import _ from 'lodash';

export const handleFormatNewJob = (groups, items, newJob) =>{
    let slotItBelongs = groups.find(group => group.resource_slotid === newJob.resource_slotid);
    let jobsCopy = [...items];
  
    if (!!slotItBelongs) {
      const jobStartDate = moment(newJob.job_start_date).format("YYYY-MM-DD HH:mm:ss");
      const jobEndDate = moment(newJob.job_end_date).format("YYYY-MM-DD HH:mm:ss");
      const jobCanMove =  checkIfJobCanMove(newJob.job_status);
      const jobAssignedTo = jobCanMove ? slotItBelongs.title : "";
      const jobUserId = jobCanMove ? slotItBelongs.assignedId : "";
    
      let job = {
        id: items[items.length-1].id + 1,
        jobid: newJob.jobid,
        group: slotItBelongs.id,
        title: newJob.job_name,
        template: false,
        progress: !!newJob.job_percentage_complete? newJob.job_percentage_complete : 0,
        specimensRetrieved: 0,
        status: newJob.job_status,
        canMove: jobCanMove,
        start_time: moment(jobStartDate).valueOf(),
        end_time: moment(jobEndDate).valueOf(),
        job_start_date: newJob.job_start_date,
        job_end_date: newJob.job_end_date,
        job_started_date: newJob.job_started_date,
        job_ended_date: newJob.job_ended_date,
        resource_slotid: newJob.resource_slotid,
        job_assigned_user_name: jobAssignedTo,
        job_assigned_userid: jobUserId,
        unstarted_taskid: newJob.unstarted_taskid,
        job_number: newJob.job_number,
        visible: true
      }
      jobsCopy.push(job);
    }
  
    return jobsCopy;
}
  
export const checkIfJobCanMove = (job_status) =>{
    return job_status === jobStatusTypesGraphQL.assigned || job_status === jobStatusTypesGraphQL.unassigned;
}