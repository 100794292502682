import React from 'react';
import Chart from "react-google-charts";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import { MESSAGE_CHART_TITLE } from './superDashboard.constants';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    chartTitle: {
        display: 'flex',
        fontStyle: 'normal',
        //fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 17,
        textAlign: 'right',
        color: '#222B45',
    },
}));

function MessageChart(props) {
    const { messagesByCampusDate } = props;
    const classes = useStyles();

    return (
        <div className={classes.cardRoot}>
            <p className={classes.chartTitle}>{MESSAGE_CHART_TITLE}</p>
            <Chart
                width={'100%'}
                height={'450px'}
                chartType="Line"
                /*
                render={({ renderChart }) => {
                    return (
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ width: '100%' }}>{renderChart()}</div>
                      </div>
                    )
                  }}*/
                loader={<div>Loading Chart</div>}
                data={messagesByCampusDate}
                options={{
                    /*
                    chart: {
                        title: 'Number of messages',
                    },
                    //colors: ["#0095FF"],
                    */
                    hAxis: {
                        minValue: 1,
                    },
                    vAxis: {
                        minValue: 0,
                    },
                    /*
                    chartArea: {
                        width: '100%',
                        height: '100%'
                    },
                    */
                }}
                legendToggle
                rootProps={{ 'data-testid': '3' }}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.user.users,
    user: state.superAdmin.user,
    messagesByCampusDate: state.superAdmin.messagesByCampusDate,
    auth: state.auth
});

export default connect(mapStateToProps, {})(MessageChart);