import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    CssBaseline,
    FormLabel,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import lightBlue from "@material-ui/core/colors/lightBlue";

import { createUser, deleteUser, patchUser } from '../../../actions/user/userActions';
import { allUsers } from '../../../actions/superAdmin/superAdminActions';
import { createLog } from '../../../actions/logs/logsActions';
import { emailValidation, passwordMatch, noEmptyValidation } from './generalValidators';
import MasterButton from '../../utilities/button.component';
import { convertLowerCase } from '../../utilities/formValidation';
import {
    SUPERADMIN_ROLE_TITLE,
    BUTTON_TYPE,
    BUTTON_TITLE,
    SETTINGS,
    EMPTY_STRING,
    EDIT_USER,
    NEW_USER,
    DELETE_BTN_LABEL,
    CANCEL_BTN_LABEL,
    SAVE_BTN_LABEL,
    LABEL,
    ERROR_NOTIFICATION,
    LOGGED_OUT,
    SELECTOR_OPTIONS,
    SUCCESS_NOTIFICATION,
    CREATE_USER_ACTION,
    EDIT_USER_ACTION,
} from './users.constants';
import { calendarFormat } from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 40,
    },
    restoreOption: {
        "& .MuiFormControlLabel-label": {
            height: 16,
            left: 8,
            right: 8,
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            color: '#9E9E9E',
        }
    },
    activeBtn: {
        width: 137,
        height: 45,
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
            backgroundColor: lightBlue[800],
            "@media (hover: none)": {
                backgroundColor: lightBlue[600]
            }
        }
    },
    saveBtn: {
        width: 137,
        height: 45,
    },
    switchLabelOn: {
        justifyContent: 'flex-end',
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            color: '#757575',
        }
    },
    snackMsg: {
        display: 'inline',
        color: 'white'
    },
    infoText: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 20,
        color: '#000000DE'
    },
    divider: {
        width: '103%', 
        marginLeft: -12,
        marginRight: -12,
    }
}));

function NewCampusUser(props) {
    const classes = useStyles();
    const { campusList, departmentList = [], aclList, userList, editUserInfo, backToUsers } = props;
    const isEditing = Boolean(editUserInfo);
    const [newUserId, setNewUserId] = React.useState(null); // the new user id

    const [inputData, setInputData] = React.useState({
        firstName: isEditing ? editUserInfo.firstName : EMPTY_STRING,
        lastName: isEditing ? editUserInfo.lastName : EMPTY_STRING,
        email: isEditing ? editUserInfo.email : EMPTY_STRING,
        password: EMPTY_STRING,
        confirm: EMPTY_STRING,
        campus: isEditing ? {id: editUserInfo.campusId, name: editUserInfo.campus} : null,
        role: isEditing ? editUserInfo.role : null,
        department: EMPTY_STRING,
        option: null,
        roleList: isEditing ? editUserInfo.roleList : [],
    });
    const [inputError, setInputError] = React.useState({
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        passwordError: false,
        confirmError: false,
        campusError: false,
        roleError: false,
    });
    const [errorNotification, setErrorNotification] = React.useState({
        firstNameErrorText: EMPTY_STRING,
        lastNameErrorText: EMPTY_STRING,
        emailErrorText: EMPTY_STRING,
        passwordErrorText: EMPTY_STRING,
        confirmErrorText: EMPTY_STRING,
        campusErrorText: EMPTY_STRING,
        roleErrorText: EMPTY_STRING,
    });

    const checkValidation = (option) => {
        const userInputs = Object.keys(inputData);
        const userErrors = Object.keys(inputError);
        const userNotifications = Object.keys(errorNotification);

        let noValid = false;
        let userError = null;
        let userNotification = null;
        let textNotification = EMPTY_STRING;
        let activeError = false;

        switch (option) {
            case userInputs[0]:
                // first name validation
                noValid = !noEmptyValidation(firstName);
                userError = userErrors[0];
                userNotification = userNotifications[0];
                textNotification = ERROR_NOTIFICATION.firstName;
                activeError = firstNameError;
                break;
            case userInputs[1]:
                // last name validation
                noValid = !noEmptyValidation(lastName);
                userError = userErrors[1];
                userNotification = userNotifications[1];
                textNotification = ERROR_NOTIFICATION.lastName;
                activeError = lastNameError;
                break;
            case userInputs[2]:
                // email validation
                noValid = !emailValidation(email);
                userError = userErrors[2];
                userNotification = userNotifications[2];
                textNotification = ERROR_NOTIFICATION.emailFormat;
                activeError = emailError;
                break;
            case userInputs[3]:
                // password validation
                noValid = isEditing ? false : !noEmptyValidation(password);
                userError = userErrors[3];
                userNotification = userNotifications[3];
                textNotification = ERROR_NOTIFICATION.passwordEmpty;
                activeError = passwordError;
                break;
            case userInputs[4]:
                // confirm password validation
                noValid = !passwordMatch(password, confirm);
                userError = userErrors[4];
                userNotification = userNotifications[4];
                textNotification = ERROR_NOTIFICATION.passwordMatch;
                activeError = confirmError;
                break;
            case userInputs[5]:
                // campus validation
                noValid = !noEmptyValidation(campus);
                userError = userErrors[5];
                userNotification = userNotifications[5];
                textNotification = ERROR_NOTIFICATION.campus;
                activeError = campusError;
                break;
            case userInputs[6]:
                // role validation
                noValid = !noEmptyValidation(role);
                userError = userErrors[6];
                userNotification = userNotifications[6];
                textNotification = ERROR_NOTIFICATION.role;
                activeError = roleError;
                break;
        }

        if (noValid) {
            setInputError({
                ...inputError,
                [userError]: noValid
            });
            setErrorNotification({
                ...errorNotification,
                [userNotification]: textNotification
            });
        }
        else if (activeError) {
            setInputError({
                ...inputError,
                [userError]: false
            });
            setErrorNotification({
                ...errorNotification,
                [userNotification]: EMPTY_STRING
            });
        }

        return !noValid;
    }


    useEffect(() => {
        const { option } = inputData;
        checkValidation(option);
    }, [inputData])

    const handleInputChange = (event) => {
        if (event.target.name) {
            setInputData({
                ...inputData,
                [event.target.name]: event.target.value,
                option: event.target.name
            });
        }
    }

    const handleCampusChange = (event, value) => {
        if (value) {
            let filterRoles = props.roleList.filter(role => aclList.filter(acl => acl.campusId === value.id && acl.role === role).length > 0);
            setInputData({
                ...inputData,
                campus: value,
                option: SELECTOR_OPTIONS.campus,
                roleList: filterRoles,
            });
        }
    }

    const handleRoleChange = (event, value) => {
        if (value) {
            setInputData({
                ...inputData,
                role: value,
                option: SELECTOR_OPTIONS.role,
            });
        }
    }

    const handleDepartmentChange = (event, value) => {
        if (value) {
            setInputData({
                ...inputData,
                department: value.id,
            });
        }
    }

    const confirmSubmit = () => {
        const userInputs = Object.keys(inputData).slice(0, 7);
        const successSubmit = userInputs.reduce((previus, current) => {
            const validateCurrent = checkValidation(current);
            return previus && validateCurrent;
        }, true);

        return successSubmit;
    }

    const createUserLog = (data) => {
        const { createLog, superAdminId } = props;
        let log = {
            action: data.action,
            description: data.description,
            timestamp: new Date(),
            userId: superAdminId
        };
        createLog(log);
    };

    const handleCreateUserName = (email) => email.replace(/@.+/, "").toLowerCase();

    const isEmailDuplicated = () => {
        const userResult = isEditing ? userList.filter((u) => u.email === email && u.id !== editUserInfo.id) : userList.filter((item) => item.email === email);
        const isDuplicated = userResult.length > 0;

        return isDuplicated;
    }

    const onCreateCampusUser = () => {
        const submit = confirmSubmit();
        const emailDuplicated = isEmailDuplicated();

        if (!emailDuplicated) {
            if (submit) {
                const roleId = getRoleId();
                const lowerCaseEmail = convertLowerCase(email);

                if (roleId) {
                    const bodyInfo = {
                        first_name: firstName,
                        last_name: lastName,
                        email: lowerCaseEmail,
                        online: false,
                        status: LOGGED_OUT,
                        role: roleId,
                        role_name: role,
                        campusId: campus.id,
                        username: handleCreateUserName(lowerCaseEmail),
                        basic_auth: true,
                        password: password,
                        //department: department, We need update department list
                    }
    
                    props.createUser(bodyInfo).then((resp) => {
                        const { superAdminId, superAdminInfo } = props;
                        const completeName = `${superAdminInfo.first_name} ${superAdminInfo.last_name}`;
                        const { createdSuccess } = SUCCESS_NOTIFICATION;
                        const logDescription = `User ${firstName} ${lastName} created by super admin ${completeName} as a basic auth user`;
    
                        let snackMsg = <div className={classes.snackMsg}><div style={{ display: 'inline', fontWeight: 'bold' }}>{`${firstName} ${lastName}`}</div> {createdSuccess}</div>
    
                        props.allUsers();
                        createUserLog({ action: CREATE_USER_ACTION, description: logDescription });
                        props.createUserSuccess(snackMsg);
                    }).catch((err) => {
                    });
                }
                else {
                    setInputError({
                        ...inputError,
                        roleError: true
                    });
                    setErrorNotification({
                        ...errorNotification,
                        roleErrorText: "Undefined role"
                    });
                }

            }
        }
        else {
            setInputError({
                ...inputError,
                emailError: true
            });
            setErrorNotification({
                ...errorNotification,
                emailErrorText: ERROR_NOTIFICATION.emailDuplicated
            });
        }
    }

    const onEditCampusUser = () => {
        const submit = confirmSubmit();
        const emailDuplicated = isEmailDuplicated();

        if (!emailDuplicated) {
            if (submit) {
                const roleId = getRoleId();
                const lowerCaseEmail = convertLowerCase(email);
                const { id } = editUserInfo;

                if (roleId) {
                    const bodyInfo = {
                        first_name: firstName,
                        last_name: lastName,
                        email: lowerCaseEmail,
                        role: roleId,
                        role_name: role,
                        campusId: campus.id,
                        username: handleCreateUserName(lowerCaseEmail),
                        //department: department, We need update department list
                    }

                    if (password) {
                        bodyInfo.password = password;
                    }

                    props.patchUser(id, bodyInfo).then((resp) => {
                        const { superAdminId, superAdminInfo } = props;
                        const completeName = `${superAdminInfo.first_name} ${superAdminInfo.last_name}`;
                        const { updatedSuccess } = SUCCESS_NOTIFICATION;
                        const logDescription = `User ${firstName} ${lastName} updated by super admin ${completeName} as a basic auth user`;

                        let snackMsg = <div className={classes.snackMsg}><div style={{ display: 'inline', fontWeight: 'bold' }}>{`${firstName} ${lastName}`}</div> {updatedSuccess}</div>

                        props.allUsers();
                        createUserLog({ action: EDIT_USER_ACTION, description: logDescription });
                        props.createUserSuccess(snackMsg);
                    }).catch((error) => {
                        console.error("[NEWCAMPUSUSER] an error ocurred in onEditCampusUser: ",error);
                    });
                }
                else {
                    setInputError({
                        ...inputError,
                        roleError: true
                    });
                    setErrorNotification({
                        ...errorNotification,
                        roleErrorText: "Undefined role"
                    });
                }

            }
        }
        else {
            setInputError({
                ...inputError,
                emailError: true
            });
            setErrorNotification({
                ...errorNotification,
                emailErrorText: ERROR_NOTIFICATION.emailDuplicated
            });
        }
    }

    const onCancelUser = () => {
        //clearForm();
        backToUsers();
    }

    const onEditCancel = () => {
        backToUsers();
    }

    const clearForm = () => {
        setInputData({
            firstName: isEditing ? editUserInfo.firstName : EMPTY_STRING,
            lastName: isEditing ? editUserInfo.lastName : EMPTY_STRING,
            email: isEditing ? editUserInfo.email : EMPTY_STRING,
            password: EMPTY_STRING,
            confirm: EMPTY_STRING,
            department: EMPTY_STRING,
            campus: isEditing ? {id: editUserInfo.campusId, name: editUserInfo.campus} : null,
            role: isEditing ? editUserInfo.role : null,
            option: null,
            roleList: [],
        });
        setInputError({
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            passwordError: false,
            confirmError: false,
            campusError: false,
            roleError: false,
        });
        setErrorNotification({
            firstNameErrorText: EMPTY_STRING,
            lastNameErrorText: EMPTY_STRING,
            emailErrorText: EMPTY_STRING,
            passwordErrorText: EMPTY_STRING,
            confirmErrorText: EMPTY_STRING,
            campusErrorText: EMPTY_STRING,
            roleErrorText: EMPTY_STRING,
        });
    }

    const getRoleId = () => {
        const acl = aclList.filter((item) => item.role === role && item.campusId === campus.id);
        const roleId = acl[0].id || null;

        return roleId;
    }

    const renderPasswordBtn = () => {
        return (
            <>
                <MasterButton
                    keyLbl={'msoft-cancel'}
                    label={BUTTON_TITLE.restore}
                    buttonType={BUTTON_TYPE.defaultFlat}
                    isDisabled={true}
                    size={"medium"}
                    styles={{ marginLeft: 20, width: 175 }}
                    class={{}}
                />
                <MasterButton
                    keyLbl={'upload-cancel'}
                    label={BUTTON_TITLE.microsoft}
                    buttonType={BUTTON_TYPE.defaultFlat}
                    isDisabled={false}
                    handleClick={() => { }}
                    size={"medium"}
                    styles={{ marginLeft: 30 }}
                    class={classes.activeBtn}
                />
            </>
        )
    }

    const renderUpdateBtn = () => {
        return (
            <>
                <Grid item>
                    <MasterButton
                        keyLbl={'cancel-user'}
                        label={BUTTON_TITLE.cancel}
                        buttonType={BUTTON_TYPE.default}
                        isDisabled={false}
                        handleClick={isEditing ? onEditCancel : onCancelUser}
                        size={"medium"}
                        class={classes.saveBtn}
                    />
                </Grid>
                <Grid item>
                    <MasterButton
                        keyLbl={'save-user'}
                        label={BUTTON_TITLE.save}
                        buttonType={BUTTON_TYPE.success}
                        isDisabled={false}
                        handleClick={isEditing ? onEditCampusUser : onCreateCampusUser}
                        size={"medium"}
                        styles={{ marginLeft: 30 }}
                        class={classes.activeBtn}
                    />
                </Grid>
            </>
        )
    }

    const { firstName, lastName, email, password, confirm, department, campus, role, roleList } = inputData;
    const { firstNameError, lastNameError, emailError, passwordError, confirmError, campusError, roleError } = inputError;
    const { firstNameErrorText, lastNameErrorText, emailErrorText, passwordErrorText, confirmErrorText, campusErrorText, roleErrorText } = errorNotification;
    const emptyText = EMPTY_STRING;

    return (
        <div style={{ padding: 16, margin: 'auto' }}>
            <CssBaseline />
            <form onSubmit={() => { }}>
                <Paper style={{ background: 'transparent', padding: 20 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <span className={classes.infoText}>Information</span>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="firstName"
                                label={LABEL.firstName}
                                variant="outlined"
                                value={firstName}
                                onChange={handleInputChange}
                                error={firstNameError}
                                helperText={firstNameError ? firstNameErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="lastName"
                                label={LABEL.lastName}
                                variant="outlined"
                                value={lastName}
                                onChange={handleInputChange}
                                error={lastNameError}
                                helperText={lastNameError ? lastNameErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="email"
                                label={LABEL.email}
                                value={email}
                                variant="outlined"
                                onChange={handleInputChange}
                                error={emailError}
                                helperText={emailError ? emailErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="newcampus-user-department"
                                disableClearable
                                options={departmentList}
                                getOptionLabel={(option) => option}
                                defaultValue={EMPTY_STRING}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={LABEL.department}
                                    />}
                                onChange={handleDepartmentChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="newcampus-user-password1"
                                name="password"
                                fullWidth
                                variant="outlined"
                                type="password"
                                label={isEditing ? `New ${LABEL.password}` : LABEL.password}
                                value={password}
                                error={passwordError}
                                helperText={passwordError ? passwordErrorText : emptyText}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="newcampus-user-confirm"
                                name="confirm"
                                fullWidth
                                variant="outlined"
                                type="password"
                                label={LABEL.confirm}
                                value={confirm}
                                error={confirmError}
                                helperText={confirmError ? confirmErrorText : emptyText}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderPasswordBtn()}
                        </Grid>
                        <div className={classes.divider} >
                        <Divider />
                        </div>
                        <Grid item xs={12} style={{ background: 'transparent', border: 'none', margin: '15px 0' }}>
                            <FormLabel className={classes.infoText} component="legend">{LABEL.campusUser}</FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="newcampus-user-control"
                                //disableClearable
                                options={campusList}
                                value={campus}
                                getOptionLabel={(option) => option.name}
                                //defaultValue={null}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={LABEL.campus}
                                        error={campusError}
                                        helperText={campusError ? campusErrorText : EMPTY_STRING}
                                    />}
                                onChange={handleCampusChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="newcampus-role-control"
                                //disableClearable
                                options={roleList}
                                value={role}
                                getOptionLabel={(option) => option}
                                //defaultValue={null}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={LABEL.role}
                                        error={roleError}
                                        helperText={roleError ? roleErrorText : EMPTY_STRING}
                                    />}
                                onChange={handleRoleChange}
                            />
                        </Grid>
                        <Grid xs={12} style={{ margin: '20px 0', display: 'flex', justifyContent: 'flex-end' }}>
                            {renderUpdateBtn()}
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </div>
    );
}

const mapStateToProps = state => ({
    campusList: state.campus.campusList,
    roleList: state.superAdmin.roleList,
    superAdminId: state.superAdmin.user.id,
    aclList: state.superAdmin.aclList,
    userList: state.superAdmin.allUsers,
    superAdminInfo: state.superAdmin.user
})
export default connect(mapStateToProps, {
    createUser,
    allUsers,
    deleteUser,
    createLog,
    patchUser
})(NewCampusUser);