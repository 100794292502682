import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    CssBaseline,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import lightBlue from "@material-ui/core/colors/lightBlue";

import { createSuperAdmin, getSuperAdmins, patchSuperAdmin } from '../../../actions/superAdmin/superAdminActions';
import { createLog } from '../../../actions/logs/logsActions';

import MasterButton from '../../utilities/button.component';
import SettingSelector from './settingSelector.component';
import { emailValidation, passwordMatch, noEmptyValidation } from './generalValidators';
import { convertLowerCase } from '../../utilities/formValidation';

import {
    SUPERADMIN_ROLE_TITLE,
    BUTTON_TYPE,
    BUTTON_TITLE,
    SETTINGS,
    EMPTY_STRING,
    EDIT_USER,
    NEW_USER,
    DELETE_BTN_LABEL,
    CANCEL_BTN_LABEL,
    SAVE_BTN_LABEL,
    LABEL,
    ERROR_NOTIFICATION,
    LOGGED_OUT,
    SUCCESS_NOTIFICATION,
    CREATE_USER_ACTION,
    EDIT_USER_ACTION,
    SUPERADMIN_NAME
} from './users.constants';
import { async } from 'rxjs/internal/scheduler/async';
import { calendarFormat } from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 40,
    },
    restoreOption: {
        "& .MuiFormControlLabel-label": {
            height: 16,
            left: 8,
            right: 8,
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            color: '#9E9E9E',
        }
    },
    activeBtn: {
        width: 137,
        height: 45,
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
            backgroundColor: lightBlue[800],
            "@media (hover: none)": {
                backgroundColor: lightBlue[600]
            }
        }
    },
    saveBtn: {
        width: 137,
        height: 45,
    },
    switchLabelOn: {
        justifyContent: 'flex-end',
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            color: '#757575',
        }
    },
    inputs: {
        border: '1px solid #E5E5E5',
        boxSizing: 'border-box',
        borderRadius: 4,
        marginTop: 5,
        padding: '5px 40px'
    },
    settings: {
        border: '1px solid #E5E5E5',
        boxSizing: 'border-box',
        borderRadius: 4,
        marginLeft: -8,
        marginTop: 21,
        padding: '20px 40px'
    },
    infoText: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 20
    },
    snackMsg: {
        display: 'inline',
        color: 'white'
    },
}));

function NewUser(props) {
    const classes = useStyles();
    const { editUserInfo, backToUsers } = props;
    const isEditing = Boolean(editUserInfo);
    const roleOptions = [SUPERADMIN_ROLE_TITLE];
    const [userId, setUserId] = React.useState(null); // the id of the new user when it is created

    const [inputData, setInputData] = React.useState({
        firstName: isEditing ? editUserInfo.firstName : EMPTY_STRING,
        lastName: isEditing ? editUserInfo.lastName : EMPTY_STRING,
        email: isEditing ? editUserInfo.email : EMPTY_STRING,
        password: EMPTY_STRING,
        confirm: EMPTY_STRING,
        role: isEditing ? editUserInfo.role : roleOptions[0],
        option: null,
    });
    const [inputError, setInputError] = React.useState({
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        passwordError: false,
        confirmError: false,
    });
    const [errorNotification, setErrorNotification] = React.useState({
        firstNameErrorText: EMPTY_STRING,
        lastNameErrorText: EMPTY_STRING,
        emailErrorText: EMPTY_STRING,
        passwordErrorText: EMPTY_STRING,
        confirmErrorText: EMPTY_STRING,
    });

    const checkValidation = (option) => {
        const userInputs = Object.keys(inputData);
        const userErrors = Object.keys(inputError);
        const userNotifications = Object.keys(errorNotification);

        let noValid = false;
        let userError = null;
        let userNotification = null;
        let textNotification = EMPTY_STRING;
        let activeError = false;

        switch (option) {
            case userInputs[0]:
                // first name validation
                noValid = !noEmptyValidation(firstName);
                userError = userErrors[0];
                userNotification = userNotifications[0];
                textNotification = ERROR_NOTIFICATION.firstName;
                activeError = firstNameError;
                break;
            case userInputs[1]:
                // last name validation
                noValid = !noEmptyValidation(lastName);
                userError = userErrors[1];
                userNotification = userNotifications[1];
                textNotification = ERROR_NOTIFICATION.lastName;
                activeError = lastNameError;
                break;
            case userInputs[2]:
                // email validation
                noValid = !emailValidation(email);
                userError = userErrors[2];
                userNotification = userNotifications[2];
                textNotification = ERROR_NOTIFICATION.emailFormat;
                activeError = emailError;
                break;
            case userInputs[3]:
                // password validation
                noValid = isEditing ? false : !noEmptyValidation(password);
                userError = userErrors[3];
                userNotification = userNotifications[3];
                textNotification = ERROR_NOTIFICATION.passwordEmpty;
                activeError = passwordError;
                break;
            case userInputs[4]:
                // confirm password validation
                noValid = !passwordMatch(password, confirm);
                userError = userErrors[4];
                userNotification = userNotifications[4];
                textNotification = ERROR_NOTIFICATION.passwordMatch;
                activeError = confirmError;
                break;
        }

        if (noValid) {
            setInputError({
                ...inputError,
                [userError]: noValid
            });
            setErrorNotification({
                ...errorNotification,
                [userNotification]: textNotification
            });
        }
        else if (activeError) {
            setInputError({
                ...inputError,
                [userError]: false
            });
            setErrorNotification({
                ...errorNotification,
                [userNotification]: EMPTY_STRING
            });
        }

        return !noValid;
    }

    useEffect(() => {
        const { option } = inputData;
        checkValidation(option);
    }, [inputData])

    const handleInputChange = (event) => {
        if (event.target.name) {
            setInputData({
                ...inputData,
                [event.target.name]: event.target.value,
                option: event.target.name
            });
        }
    }

    const handleRoleChange = (event, value) => {
        if (value) {
            setInputData({
                ...inputData,
                role: value,
            });
        }
    }

    const confirmSubmit = () => {
        const userInputs = Object.keys(inputData).slice(0, 5);
        const successSubmit = userInputs.reduce((previus, current) => {
            const validateCurrent = checkValidation(current);
            return previus && validateCurrent;
        }, true)

        return successSubmit;
    };

    const createUserLog = (data) => {
        const { createLog, superAdminId } = props;
        let log = {
            action: data.action,
            description: data.description,
            timestamp: new Date(),
            userId: superAdminId
        };
        createLog(log);
    };

    const handleCreateUserName = (email) => email.replace(/@.+/, "").toLowerCase();

    const isEmailDuplicated = () => {
        const { superAdminList } = props;
        const superAdminResult = isEditing ? superAdminList.filter((u) => u.email === email && u.id !== editUserInfo.id) : superAdminList.filter((u) => u.email === email);
        const isDuplicated = superAdminResult.length > 0;

        return isDuplicated;
    }

    const onCreateSuperadmin = () => {
        const lowerCaseEmail = convertLowerCase(inputData.email);
        const emailDuplicated = isEmailDuplicated(lowerCaseEmail);
        const submit = confirmSubmit();
        
        if (!emailDuplicated) {
            if (submit) {
                const { firstName, lastName, password } = inputData;
                const logDate = new Date;
    
                const userBodyInfo = {
                    first_name: firstName,
                    last_name: lastName,
                    email: lowerCaseEmail,
                    username: handleCreateUserName(lowerCaseEmail),
                    password: password,
                    last_login: logDate,
                    lastLogout: logDate,
                    enabled: true,
                    status: LOGGED_OUT,
                }
    
                const body = {
                    user: userBodyInfo,
                }
    
                props.createSuperAdmin(body).then((resp) => {
                    const { superAdminId, superAdminInfo } = props;
                    const completeName = `${superAdminInfo.first_name} ${superAdminInfo.last_name}`;
                    const { createdSuccess } = SUCCESS_NOTIFICATION;
                    const logDescription = `Super Admin User ${firstName} ${lastName} created by super admin ${completeName}`;
    
                    let snackMsg = <div className={classes.snackMsg}><div style={{ display: 'inline', fontWeight: 'bold' }}>{`${SUPERADMIN_NAME} ${firstName} ${lastName} `}</div> {createdSuccess}</div>
    
                    props.getSuperAdmins();
                    createUserLog({ action: CREATE_USER_ACTION, description: logDescription });
                    props.createUserSuccess(snackMsg);
                }).catch((error) => {
                    console.error("[NEWUSER] An error ocurred in createSuperAdmin: ", error);
                });
    
            }
        }
        else {
            setInputError({
                ...inputError,
                emailError: true
            });
            setErrorNotification({
                ...errorNotification,
                emailErrorText: ERROR_NOTIFICATION.emailDuplicated
            });
        }
        
    }

    const onEditSuperadmin = () => {
        const lowerCaseEmail = convertLowerCase(inputData.email);
        const emailDuplicated = isEmailDuplicated(lowerCaseEmail);
        const submit = confirmSubmit();
        const { id } = editUserInfo;

        if (!emailDuplicated) {
            if (submit) {
                const { firstName, lastName, password } = inputData;
                const logDate = new Date;

                const userBodyInfo = {
                    first_name: firstName,
                    last_name: lastName,
                    email: lowerCaseEmail,
                    username: handleCreateUserName(lowerCaseEmail),
                }

                if (password) {
                    userBodyInfo.password = password;
                }

                
                props.patchSuperAdmin(id, userBodyInfo).then((resp) => {
                    const { superAdminId, superAdminInfo } = props;
                    const completeName = `${superAdminInfo.first_name} ${superAdminInfo.last_name}`;
                    const { updatedSuccess } = SUCCESS_NOTIFICATION;
                    const logDescription = `Super Admin User ${firstName} ${lastName} updated by super admin ${completeName}`;

                    let snackMsg = <div className={classes.snackMsg}><div style={{ display: 'inline', fontWeight: 'bold' }}>{`${SUPERADMIN_NAME} ${firstName} ${lastName} `}</div>{updatedSuccess}</div>

                    props.getSuperAdmins();
                    createUserLog({ action: EDIT_USER_ACTION, description: logDescription });
                    props.createUserSuccess(snackMsg);
                }).catch((error) => {
                    console.error("[NEWUSER] An error ocurred in patchSuperAdmin: ", error);
                });
            }
        }
        else {
            setInputError({
                ...inputError,
                emailError: true
            });
            setErrorNotification({
                ...errorNotification,
                emailErrorText: ERROR_NOTIFICATION.emailDuplicated
            });
        }
    }

    const onCancelSuperAdmin = () => {
        //clearForm();
        backToUsers();
    }

    const onEditCancel = () => {
        backToUsers();
    }

    const onClose = async () => {
    }

    const clearForm = async () => {
        setInputData({
            firstName: isEditing ? editUserInfo.firstName : EMPTY_STRING,
            lastName: isEditing ? editUserInfo.lastName : EMPTY_STRING,
            email: isEditing ? editUserInfo.email : EMPTY_STRING,
            password: EMPTY_STRING,
            confirm: EMPTY_STRING,
            option: null,
        });
        setInputError({
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            passwordError: false,
            confirmError: false,
        });
        setErrorNotification({
            firstNameErrorText: EMPTY_STRING,
            lastNameErrorText: EMPTY_STRING,
            emailErrorText: EMPTY_STRING,
            passwordErrorText: EMPTY_STRING,
            confirmErrorText: EMPTY_STRING,
        });
    }

    const renderPasswordBtn = () => {
        return (
            <>
                <MasterButton
                    keyLbl={'def-login'}
                    label={BUTTON_TITLE.restore}
                    buttonType={BUTTON_TYPE.defaultFlat}
                    isDisabled={true}
                    handleClick={() => { }}
                    size={"medium"}
                    styles={{ marginLeft: 20, width: 175 }}
                />
                <MasterButton
                    keyLbl={'microsf-login'}
                    label={BUTTON_TITLE.microsoft}
                    buttonType={BUTTON_TYPE.defaultFlat}
                    isDisabled={false}
                    handleClick={() => { }}
                    size={"medium"}
                    styles={{ marginLeft: 30 }}
                    class={classes.activeBtn}
                />
            </>
        )
    }

    const renderUpdateBtn = () => {
        return (
            <>
                <Grid item>
                    <MasterButton
                        keyLbl={'cancel-user'}
                        label={BUTTON_TITLE.cancel}
                        buttonType={BUTTON_TYPE.default}
                        isDisabled={false}
                        handleClick={isEditing ? onEditCancel : onCancelSuperAdmin}
                        size={"medium"}
                        class={classes.saveBtn}
                    />
                </Grid>
                <Grid item>
                    <MasterButton
                        keyLbl={'save-user'}
                        label={BUTTON_TITLE.save}
                        buttonType={BUTTON_TYPE.success}
                        isDisabled={false}
                        handleClick={isEditing ? onEditSuperadmin : onCreateSuperadmin}
                        size={"medium"}
                        styles={{ marginLeft: 30 }}
                        class={classes.activeBtn}
                    />
                </Grid>
            </>
        )
    }

    const { firstName, lastName, email, password, confirm, role } = inputData;
    const { firstNameError, lastNameError, emailError, passwordError, confirmError } = inputError;
    const { firstNameErrorText, lastNameErrorText, emailErrorText, passwordErrorText, confirmErrorText } = errorNotification;
    const emptyText = EMPTY_STRING;

    return (
        <div>
            <CssBaseline />
            <form onSubmit={() => { }}>
                <Paper style={{ background: 'transparent', border: 'none' }}>
                    <Grid className={classes.inputs} container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <span className={classes.infoText}>Information</span>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="firstName"
                                label={LABEL.firstName}
                                variant="outlined"
                                value={firstName}
                                onChange={handleInputChange}
                                error={firstNameError}
                                helperText={firstNameError ? firstNameErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="lastName"
                                label={LABEL.lastName}
                                variant="outlined"
                                value={lastName}
                                onChange={handleInputChange}
                                error={lastNameError}
                                helperText={lastNameError ? lastNameErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="email"
                                label={LABEL.email}
                                value={email}
                                variant="outlined"
                                onChange={handleInputChange}
                                error={emailError}
                                helperText={emailError ? emailErrorText : emptyText}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id={'role'}
                                disableClearable
                                options={roleOptions}
                                defaultValue={role}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={LABEL.role}
                                    />}
                                onChange={handleRoleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="user-password1"
                                name="password"
                                fullWidth
                                variant="outlined"
                                type="password"
                                label={isEditing ? `New ${LABEL.password}` : LABEL.password}
                                value={password}
                                error={passwordError}
                                helperText={passwordError ? passwordErrorText : emptyText}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="user-confirm"
                                name="confirm"
                                fullWidth
                                variant="outlined"
                                type="password"
                                label={LABEL.confirm}
                                value={confirm}
                                error={confirmError}
                                helperText={confirmError ? confirmErrorText : emptyText}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {renderPasswordBtn()}
                        </Grid>
                    </Grid>
                    <Grid className={classes.settings} item xs={6}>
                        <SettingSelector superAdmin={true} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
                        {renderUpdateBtn()}
                    </Grid>
                </Paper>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    users: state.superAdmin.allUsers,
    roles: state.acl.adminAcls,
    superAdminId: state.superAdmin.user.id,
    superAdminList: state.superAdmin.superAdminList,
    superAdminInfo: state.superAdmin.user
});
export default connect(mapStateToProps, { createSuperAdmin, createLog, getSuperAdmins, patchSuperAdmin })(NewUser);