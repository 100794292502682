import lightBlue from "@material-ui/core/colors/lightBlue";
export const styles = theme => ({
    badgeInput:{
      width:'30%',
      marginLeft:'5%'
    },
    defaultInputConfig:{
      width:'40%',
      marginLeft:'5%'
    },
    passwordsDiv:{
      display:'flex',
      marginTop:'10px'
    },
    closeBtnConfig:{
      marginLeft:'10%',
      width:'40%'
    },
    defaultBtnConfig:{
      marginLeft:'30px',
      width:'40%'
    },
    snackMsg:{
      display:'inline',
      color:'white'
    },
    picture:{
        marginLeft:'5%',
        height: '80px',
        width:'80px'
    },
    divider:{
        marginTop:'3%'
    },
    passSubtitle:{
        display:'flex',
        fontWeight:'500',
        paddingLeft:'5%',
        paddingBottom:'2%',
        marginTop:'2%',
        fontSize:'18px'
    },
    infoSubtitle:{
        display:'flex',
        fontWeight:'500',
        paddingLeft:'5%',
        paddingBottom:'2%',
        fontSize:'18px'
    },
    tableTop:{
        marginLeft: '10%',
        display:'flex'
    },
    problemIcon: {
        fill:'#FFC738',
        fontSize:'200px',
        marginLeft:'100px'
    },
    mainDiv: {
        display: 'block',
        flexGrow: 1,
        padding: '2%'
    },
    container: {
        backgroundColor: 'white',
        display: 'content',
        boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
        width: '80%',
        marginLeft: '10%',
        marginTop:'1%'
    },
    uploadBtn: {
        height:'5%',
        width:'12%',
        marginTop:'3%',
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    uploadBtnOpenNav: {
      height:'5%',
      width:'15%',
      marginTop:'3%',
      color: theme.palette.getContrastText(lightBlue[600]),
      backgroundColor: lightBlue[600],
      "&:hover": {
        backgroundColor: lightBlue[800],
        "@media (hover: none)": {
          backgroundColor: lightBlue[600]
        }
      }
  },
    restoreBtn: {
      height:'5%',
      width:'22%',
      marginTop:'3%',
      color: theme.palette.getContrastText(lightBlue[600]),
      backgroundColor: lightBlue[600],
      "&:hover": {
        backgroundColor: lightBlue[800],
        "@media (hover: none)": {
          backgroundColor: lightBlue[600]
        }
      }
    },
    restoreBtnCancel: {
      height:'5%',
      width:'22%',
      marginTop:'3%'
    },
    uploadBtnCancel: {
        height:'5%',
        width:'12%',
        marginTop:'3%'
    },
    uploadBtnCancelOpenNav: {
      height:'5%',
      width:'15%',
      marginTop:'3%'
    },
    containedBtn: {
        height:'5%',
        width:'12%',
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    buttonContainerUpdate: {
        display:'flex',
        float: 'right',
        paddingRight: '5%',
        width: '50%'
    },
    buttonContainer: {
      display:'flex',
      float: 'right',
      paddingRight: '5%',
      width: '30%'
    },
    back: {
        marginLeft: '80%',
        marginBottom: '1%',
        color:'rgba(0, 0, 0, 0.4)',
        fontWeight: '500',
        letterSpacing:'0.25px',
        "&:hover": {
          color: lightBlue[600],
          cursor: "pointer"
        }
    },
    titleLbl: {
        color:'#2843A3',
        fontWeight: '700',
        marginLeft: '10px',
        fontSize: '23px',
        display: 'inline',
        paddingTop: '5px'
    },
    iconTitle: {
        color:'#2843A3',
        fontSize: '30px'
    },
    titleContainer: {
        display:'inline'
    }
});