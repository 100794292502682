export const SET_INPUTS = (props) =>{
    let equipmentInputs = [
      {
        id: "webNavv-tableView-equipment-equipmentDetails-deviceCategoryInput-1.0",
        key: "deviceCategory",
        label: "Device Category",
        value: props.equipment.deviceCategory,
        input: 'select',
        grid: 6,
        list: props.equipmentCategories,
        listKey: 'name',
        style: {width:'70%', marginTop: '20px', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-controlNumber-1.0",
        key: "controlNum",
        label: "Control Number",
        value: props.equipment.controlNum,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginTop: '20px', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-model-1.0",
        key: "model",
        label: "Model",
        value: props.equipment.model,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-maker-1.0",
        key: "maker",
        label: "Maker",
        value: props.equipment.maker,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-team-1.0",
        key: "team",
        label: "Team",
        value: props.equipment.team,
        input: 'select',
        grid: 6,
        list: props.roles,
        listKey: 'role',
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-department-1.0",
        key: "department",
        label: "Department",
        value: props.equipment.department,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-tagNum-1.0",
        key: "tagNumber",
        label: "Tag Number",
        value: props.equipment.tagNumber,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-tableView-equipment-equipmentDetails-trackingId-1.0",
        key: "trackingId",
        label: "Tracking Id",
        value: props.equipment.trackingId,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      }
    ];

    return equipmentInputs;
  };