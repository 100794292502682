import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TimelineItem} from "@material-ui/lab";
import lightBlue from "@material-ui/core/colors/lightBlue";

export const CustomTimelineItem = withStyles((theme) => ({
    missingOppositeContent:{
        "&:before": {
            flex: "none !important"
        }
    },
}))(TimelineItem);

export const useStyles = makeStyles((theme) => ({
    JobHistory_exportButton: {
        height: "40px",
        marginTop: "20px",
        marginLeft: "25%",
        fontSize: '12px',
        color: "#2843A3",
        textTransform: "none !important",
        borderColor: "#2843A3",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "lightgray",
        }
    },
    JobHistory_errorDetailButton: {
        marginLeft: "20px",
        fontSize: '11px',
        color: "white",
        textTransform: "none !important",
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    JobHistory_stepperElement: {
        border: "none !important"
    },
    JobHistory_stepperContent:{
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        color: "#458E2D",
    },
    JobHistory_stepperLabel:{
        display: "flex",
        fontWeight: "700 !important",
        fontSize: "17px !important",
        lineHeight: "26px !important",
        letterSpacing: "0.2px !important",
        color: "#000000 !important",
    },
    JobHistory_stepperFloor:{
        position: "absolute",
        right: "95%",
        top: "10%",
        height: "30px",
        width: "38px",
        textAlign: "center",
        borderRadius: "3px",
        paddingTop: "5px",
        fontWeight: "bold"
    },
    JobHistory_stepperFloorNA:{
        position: "absolute",
        right: "95%",
        top: "10%",
        height: "30px",
        width: "35px",
        textAlign: "center",
        borderRadius: "3px",
        paddingTop: "5px",
        backgroundColor: "lightgray",
        color: "white"
    },
    JobHistory_timelineFloor:{
        marginTop: "3px",
        position: "absolute",
        right:"95%"
    },
    JobHistory_timelineDot:{
        backgroundColor: "transparent",
        boxShadow: "none !important"
    }
}));