export const validateUserName=(userObj)=>{
    if(userObj.first_name!==undefined && userObj.last_name!==undefined ){
          return userObj.first_name+" "+userObj.last_name
      }
      if(userObj.first_name!==undefined  && userObj.last_name===undefined){
        return userObj.first_name;
    }

    return (userObj.first_name===undefined && userObj.last_name!==undefined)?userObj.last_name:userObj.email;   

}