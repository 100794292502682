import { mutationDeleteResourceSlotJobs } from "../../../graphql/mutations";

export const handleRemoveJobsFromSlot = async (currentRowSelected) =>{
  const DeleteResource_Slot_Input = {
    resource_slotid: currentRowSelected.resource_slotid,
  }
  const jobsDeletedFromSlot = await mutationDeleteResourceSlotJobs(DeleteResource_Slot_Input);

  if (jobsDeletedFromSlot) {
    return "done";
  }
  else{
    return "error";
  }
}