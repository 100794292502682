import _ from 'lodash';
import moment from "moment";

export const renderJobTaskHistory = (renderJobTaskHistoryParams, renderJobTaskHistoryActions) =>{
    const {spaghetti, historyRoute, timeWindow, wifi, jobInfo, jobsToHide, activeFloor, centerHistoryMap, animationShowTrace, animationShowCallouts, mapkit, historyRoutesCleanList} = renderJobTaskHistoryParams;
    let {openJTFirstTime} =renderJobTaskHistoryParams;
    const {changeGeoJSONJobs, checkJobsToHideList, storeHistoryTraces, handleFilterUsersByFloor, updateHistoryRoutesCleanList} = renderJobTaskHistoryActions;

    if (!!spaghetti) {
        let currentFloor = 0;
        let geoJSONJob = {};
        const {geolocation} = spaghetti[0];
        currentFloor = handleFilterUsersByFloor();
        mapkit.cleanTraceOverlays();
        mapkit.handleAnnotationsHistory();

        const jobTaskInfo = [{
            job: jobInfo[0].jobSessionId,
            jobLabel: jobInfo[0].jobName,
            traceHCompleted: true
        }];

        if(timeWindow.length>0){
                let historyRouteTimeWindow = handleTracesWithTimeWindow({timeWindow, jobsToHide, historyRoute, spaghetti, historyRoutesCleanList}, updateHistoryRoutesCleanList);
                storeHistoryTraces(historyRouteTimeWindow);
                if(timeWindow[2] === 'slider'){
                    geoJSONJob= mapkit.handleTraces(historyRouteTimeWindow,wifi,geolocation,jobTaskInfo);
                }
                if(timeWindow[2] === 'animation') {
                    geoJSONJob= mapkit.handleTracesAnimation(historyRouteTimeWindow,wifi,geolocation,jobTaskInfo, animationShowTrace, animationShowCallouts);
                }
        }else{
                if(jobsToHide.length>0){
                    cleanJobList(historyRoute, jobsToHide, updateHistoryRoutesCleanList);
                    storeHistoryTraces(historyRoutesCleanList);
                    geoJSONJob= mapkit.handleTraces(historyRoutesCleanList,wifi,geolocation,jobTaskInfo);
                }else{
                    storeHistoryTraces(spaghetti);
                    geoJSONJob= mapkit.handleTraces(spaghetti,wifi,geolocation,jobTaskInfo);
                }
        }

        mapkit.handleFloorTraces(activeFloor,currentFloor);
        // mapkit.handleSetMapDefault();
        let centerMapHidden = checkJobsToHideList();
        changeGeoJSONJobs(geoJSONJob);
        if(!_.isEmpty(centerHistoryMap) && !centerMapHidden && timeWindow[2] !== 'animation') {
            mapkit.handleCenterHistoryTraceAnnotation(centerHistoryMap,jobTaskInfo);
        }
        //'Open' trace in start point by default
        if(openJTFirstTime){
            openJTFirstTime = false;
            mapkit.handleCenterHistoryTraceAnnotation({type: "startTag", jobId: jobTaskInfo[0].job},jobTaskInfo);
        }   
    }
    return {mapkit, openJTFirstTime}
}

const handleTracesWithTimeWindow=({timeWindow, jobsToHide, historyRoute, spaghetti, historyRoutesCleanList}, updateHistoryRoutesCleanList)=>{
    let historyRouteWindow = [];
    let startDate = moment(moment(timeWindow[0]).toISOString());
    let auxEndDate = moment(moment(timeWindow[1]).toISOString());
    let endDate = auxEndDate.add(1, 'seconds');
    if(jobsToHide.length>0){
        cleanJobList(historyRoute, jobsToHide, updateHistoryRoutesCleanList);
        historyRoutesCleanList.map(trace=>{
            let date = moment(trace.create_date);
            if(date >= startDate && date<= endDate) {
                historyRouteWindow.push(trace);
            }
                
            return null;
        });
    }else{
        if (!!historyRoute) {
            historyRoute.forEach(trace=>{
                let date = moment(trace.create_date);
                if(date >= startDate && date<= endDate){
                    historyRouteWindow.push(trace);
                }
            });
        }
        else {
            spaghetti.forEach(trace=>{
                let date = moment(trace.create_date);
                if(date >= startDate && date<= endDate){
                    historyRouteWindow.push(trace);
                }
            });
        }
    }
    return historyRouteWindow
}

const cleanJobList=(historyRoute, jobsToHide, updateHistoryRoutesCleanList)=>{
    let jobList = historyRoute;
    let auxJobList = [];
    jobsToHide.map(job=>{
        jobList.map(trace=>{
            if(trace.jobId!==job) {
                auxJobList.push(trace);
            }
                
            return null;
        });
        jobList = auxJobList;
        auxJobList = [];

        return null;
    });

    updateHistoryRoutesCleanList(jobList);
}