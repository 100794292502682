import equipmentWhiteIcon from '../../../../img/equipmentWhite.png';
import { getFloorColorByOrdinal } from '../../floorColors';

export const EQUIPMENT_ANNOTATION = (geolocationInfo, allCallouts, smallCallouts,indoorOnly, activeFloor, currentFloor, mapkit, mapConfigs)=>{
  if (geolocationInfo !== undefined) {
    geolocationInfo['calloutPointer'] = "pointerItem on";
    geolocationInfo['calloutCont'] = "container on";

    mapkit.annotations.map(item=>{
      if ((item.subtitle === 'equipment-locationBubble' || item.subtitle === 'equipment-callout') && item.title === geolocationInfo.asset_tag)
        mapkit.removeAnnotation(item);
    });

    let coordinate = new window.mapkit.Coordinate(geolocationInfo.latitude, geolocationInfo.longitude);

    let showCallout = true;
    let showSmallCallout = false;
    if (allCallouts && smallCallouts) {
      showCallout = false;
      showSmallCallout = true;
    }
    if (!allCallouts && !smallCallouts) {
      showCallout = false;
      showSmallCallout = false;
    }
    let EquipmentOptionsBubble = {
      calloutEnabled: false,
      title: geolocationInfo.asset_tag,
      subtitle: "equipment-locationBubble",
      data: geolocationInfo,
      enabled: true,
      visible: showSmallCallout
    };
    let EquipmentOptions = {
      calloutEnabled: false,
      title: geolocationInfo.asset_tag,
      subtitle: "equipment-locationDot",
      data: geolocationInfo,
      enabled: false,
    };
    let calloutOptions = {
      calloutEnabled: false,
      title: geolocationInfo.asset_tag,
      subtitle: "equipment-callout",
      data: geolocationInfo,
      enabled: true,
      visible: showCallout
    };

    if (activeFloor === true) {
      if (currentFloor === geolocationInfo.floor)
        mapkit = handleCreateEquipmentCallouts(mapkit, coordinate, EquipmentOptionsBubble, EquipmentOptions, calloutOptions, mapConfigs);
    } else if (indoorOnly) {
      if (geolocationInfo.floor !== null && geolocationInfo.floor !== undefined && geolocationInfo.floor !== 'outside' && geolocationInfo.floor !== -3)
        mapkit = handleCreateEquipmentCallouts(mapkit, coordinate, EquipmentOptionsBubble, EquipmentOptions, calloutOptions, mapConfigs);
    } else if (indoorOnly && activeFloor === true) {
      if (geolocationInfo.floor !== null && geolocationInfo.floor !== undefined && geolocationInfo.floor !== 'outside' && geolocationInfo.floor !== -3 && currentFloor === geolocationInfo.floor)
        mapkit = handleCreateEquipmentCallouts(mapkit, coordinate, EquipmentOptionsBubble, EquipmentOptions, calloutOptions, mapConfigs);
    } else {
      mapkit = handleCreateEquipmentCallouts(mapkit, coordinate, EquipmentOptionsBubble, EquipmentOptions, calloutOptions, mapConfigs);
    }

  }
  return mapkit;
};

const handleCreateEquipmentCallouts = (map, coordinate, EquipmentOptionsBubble, EquipmentOptions, calloutOptions, mapConfigs)=>{
    map = createEquipmentLocationAnnotationBubble(coordinate, EquipmentOptionsBubble, map, mapConfigs);
    map = createEquipmentLocationAnnotation(coordinate, EquipmentOptions, map, mapConfigs);
    map = createEquipmentCalloutAnnotation(coordinate, calloutOptions, map, mapConfigs);
  return map;
};

const createEquipmentLocationAnnotationBubble = (coords, EquipmentOptions, mapkit, mapConfigs)=>{
  let EquipmentFactory = handleFactoryAnnotationEquipmentBubble(coords, EquipmentOptions, mapConfigs);
  var equipment = new window.mapkit.Annotation(coords, EquipmentFactory, EquipmentOptions);
  mapkit.addAnnotation(equipment);
  return mapkit;
};

const createEquipmentLocationAnnotation = (coords, EquipmentOptions, mapkit, mapConfigs)=>{
  let EquipmentFactory = handleFactoryAnnotationEquipment(coords, EquipmentOptions, mapConfigs);
  var equipment = new window.mapkit.Annotation(coords, EquipmentFactory, EquipmentOptions);
  mapkit.addAnnotation(equipment);
  return mapkit;
};

const createEquipmentCalloutAnnotation = (coords, calloutOptions, mapkit, mapConfigs)=>{
  let calloutFactory = handleFactoryEquipmentAnnotationCallout(coords, calloutOptions, mapConfigs);
  var callout = new window.mapkit.Annotation(coords, calloutFactory, calloutOptions);
  mapkit.addAnnotation(callout);
  return mapkit;
};

const handleFactoryAnnotationEquipment = (coordinate, options, mapConfigs)=>{
  return (coordinate, options) =>{
    let floorColor = "";

    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor=>{
        if (floor.ordinal === options.data.floor)
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
      });
    }
    

    let divContainer = document.createElement("div");
    let divPin = document.createElement("div");
    let equipmentAnnotation = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Annotation : {};
    let styleProperties = Object.keys(equipmentAnnotation);
    styleProperties.forEach(property=>{
      divPin.style[property] = equipmentAnnotation[property];
    });
    divPin.style.backgroundColor = floorColor;

    divContainer.append(divPin);

    return divContainer;
  };
};

const handleFactoryAnnotationEquipmentBubble = (coordinate, options, mapConfigs)=>{
  return (coordinate, options) =>{
    let floorColor = "";

    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor=>{
        if (floor.ordinal === options.data.floor)
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
      });
    }
    

    let icon = document.createElement("img");
    icon.setAttribute("src", equipmentWhiteIcon);
    icon.setAttribute("alt", "equipmentIcon");
    let smallCalloutPointerStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.small_location_Callout.pointer : {};
    let smallCalloutPointerProperties = Object.keys(smallCalloutPointerStyles);
    smallCalloutPointerProperties.forEach(property=>{
      icon.style[property] = smallCalloutPointerStyles[property];
    });

    let divCallout = document.createElement("div"), id = "marker";
    divCallout.className = "equipment-small-callout";
    let smallCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.small_location_Callout.main_Container : {};
    let smallCalloutProperties = Object.keys(smallCalloutStyles);
    smallCalloutProperties.forEach(property=>{
      divCallout.style[property] = smallCalloutStyles[property];
    });
    divCallout.style.backgroundColor = floorColor;

    divCallout.append(icon);

    return divCallout;
  };
};

const handleFactoryEquipmentAnnotationCallout = (coordinate, options, mapConfigs)=>{
  return (coordinate, options) =>{
    let actualFloor = '';
    let floorColor = "";
    let nameLblSize = "10px";
    let controlNumSize = "10px";
    if (options.data.asset_tag.toString().length > 8) controlNumSize = '9px';
    if (options.data.device_category.length > 16) nameLblSize = "9px";

    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor=>{
        if (floor.ordinal === options.data.floor) {
          actualFloor = floor.label;
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
        }
      });
    }
    

    let divCallout = document.createElement("div"), id="marker";
    divCallout.className = "equipment-callout";
    let equipmentCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.main_Container : {};
    let equipmentCalloutProperties = Object.keys(equipmentCalloutStyles);
    equipmentCalloutProperties.forEach(property=>{
      divCallout.style[property] = equipmentCalloutStyles[property];
    });

    let divTitle = document.createElement("div");
    let deviceCategoryStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.deviceCategory_Container : {};
    let deviceCategoryProperties = Object.keys(deviceCategoryStyles);
    deviceCategoryProperties.forEach(property=>{
      divTitle.style[property] = deviceCategoryStyles[property];
    });
    divTitle.append(options.data.device_category);

    let divSubtitle = document.createElement("div");
    let controlNumberStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.controlNumber_Container : {};
    let controlNumberProperties = Object.keys(controlNumberStyles);
    controlNumberProperties.forEach(property=>{
      divSubtitle.style[property] = controlNumberStyles[property];
    });
    divSubtitle.style.fontSize = controlNumSize;
    divSubtitle.append(options.data.asset_tag);

    let divAsset = document.createElement("div");
    let controlNumberTitleStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.controlNumberTitle_Container : {};
    let controlNumberTitleProperties = Object.keys(controlNumberTitleStyles);
    controlNumberTitleProperties.forEach(property=>{
      divAsset.style[property] = controlNumberTitleStyles[property];
    });
    divAsset.style.fontSize = controlNumSize;
    divAsset.append('Control No.:');

    let divTag = document.createElement("div");
    divTag.style.display = 'flex';
    divTag.append(divAsset);
    divTag.append(divSubtitle);

    let divDate = document.createElement("div");
    let dateContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.date_Container : {};
    let dateContainerProperties = Object.keys(dateContainerStyles);
    dateContainerProperties.forEach(property=>{
      divDate.style[property] = dateContainerStyles[property];
    });
    divDate.append(options.data.date);

    let divData = document.createElement("div");
    divData.style.textAlign = 'left';
    divData.append(divTitle);
    divData.append(divTag);
    divData.append(divDate);

    let divPointer = document.createElement("div");
    let equipmentPointerStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.pointer : {};
    let equipmentPointerProperties = Object.keys(equipmentPointerStyles);
    equipmentPointerProperties.forEach(property=>{
      divPointer.style[property] = equipmentPointerStyles[property];
    });
    divPointer.style.fontSize = nameLblSize;
    divPointer.append(divData);
    divCallout.append(divPointer);

    let divLevel = document.createElement("div");
    let floorDivStyles = Object.keys(mapConfigs).length ? mapConfigs.equipment.location_Callout.floor_Container : {};
    let floorDivProperties = Object.keys(floorDivStyles);
    floorDivProperties.forEach(property=>{
      divLevel.style[property] = floorDivStyles[property];
    });
    divLevel.style.backgroundColor = floorColor;
    let label = actualFloor !== "GPS" ? "L-" + actualFloor : actualFloor;
    divLevel.append(label);
    divCallout.append(divLevel);

    return divCallout;
  };
};