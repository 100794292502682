export const SET_INPUTS = (props) =>{
  let equipmentInputs = [
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-deviceCategory-1.0",
        key: "deviceCategory",
        label: "Device Category",
        value: props.equipment.deviceCategory,
        input: 'select',
        grid: 6,
        list: props.equipmentCategories,
        listKey: 'name',
        style: {width:'70%', marginTop: '20px', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-UUID-1.0",
        key: "UUID",
        label: "UUID",
        value: props.equipment.UUID,
        input: 'text',
        grid: 6,
        style: {width:'90%', marginTop: '20px', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-minor-1.0",
        key: "minor",
        label: "Minor",
        value: props.equipment.minor,
        input: 'number',
        inputProps: {min: 0, max: 65535},
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-major-1.0",
        key: "major",
        label: "Major",
        value: props.equipment.major,
        input: "number",
        inputProps: {min: 0, max: 65535},
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-controlNumber-1.0",
        key: "controlNum",
        label: "Control Number",
        value: props.equipment.controlNum,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-beaconNumber-1.0",
        key: "beaconNum",
        label: "Beacon Number",
        value: props.equipment.beaconNum,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-model-1.0",
        key: "model",
        label: "Model",
        value: props.equipment.model,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-maker-1.0",
        key: "maker",
        label: "Maker",
        value: props.equipment.maker,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-team-1.0",
        key: "team",
        label: "Team",
        value: props.equipment.team,
        input: 'select',
        grid: 6,
        list: props.roles,
        listKey: 'role',
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-department-1.0",
        key: "department",
        label: "Department",
        value: props.equipment.department,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      },
      {
        id: "webNavv-adminPanel-equipment-equipmentForm-trackingId-1.0",
        key: "trackingId",
        label: "TrackingId",
        value: props.equipment.trackingId,
        input: 'text',
        grid: 6,
        style: {width:'70%', marginLeft:'30px'}
      }
  ];

  return equipmentInputs;
};