import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { getEquipment, getEquipmentLocationKIO, categoriesByCampus, getEquipmentLocation } from '../../actions/equipment/equipmentActions';
import { changeZoomToEquipment, changeView, changeEquipmentFilter, changePeopleFilter } from '../../actions/views/viewsActions';
import { fetchACLS } from '../../actions/acl/aclActions';
import FilterForm from './tableHeader/filterForm.component';
import EquipmentInfo from './equipmentInfo/equipmentInfo.component';
import AwesomeSpinner from '../utilities/spinner';
import { getFloorColorByOrdinal } from '../map/floorColors';
import { styles, getInnerMuiTheme, getMuiTheme } from './equipment-history.styles';
import * as CONST from './equipment-history.constants';

class EquipmentHistory extends React.Component {
  state = {
    open: false,
    data: [],
    showTable: false,
    openFilterMenu: false,
    search: '',
    searchResult: [],
    isSearch: false,
    showDetails: false,
    equipmentSelected: {},
    equipmentLocationList: [],
    equipmentHasLocation: false,
    showSpinner: true,
    filter: {
      deviceCategory: '',
      controlNum: '',
      model: '',
      maker: '',
      team: '',
      department: ''
    }
  }
  tableData = [];
  innerTableData = [];
  listPerCategory = {};
  data = [];
  mainTheme = getMuiTheme();
  innerTheme = getInnerMuiTheme();
  equipmentKIOInterval = null;

  componentDidMount() {
    this.props.getEquipment(this.props.campusId)
      .then(resp => {
        this.props.getEquipmentLocation(this.props.campusId);
      }).then(resp => {
        this.orderEquipmentLocation();
        this.setState({ showTable: true, showSpinner: false });
      })
    this.props.categoriesByCampus(this.props.campusId);

    if (this.props.roles === null || this.props.roles === undefined || this.props.roles.length === 0) {
      this.props.fetchACLS();
    }

    this.equipmentKIOInterval = setInterval(() => {
      let enableEquipmentFunctions = this.props.equipmentMapFilter ? true : false;
      if (enableEquipmentFunctions) {
        this.props.getEquipmentLocationKIO(this.props.campusId)
          .then(resp => {
            this.props.getEquipment(this.props.campusId);
          });
      }
    }, 60000);
  };

  componentWillUnmount() {
    clearInterval(this.equipmentKIOInterval);
  };

  orderEquipmentLocation = () => {
    let equipmentList = [];
    let grouped = Object.create(null);
    if (!!this.props.equipmentLocation.equipments) {
      this.props.equipmentLocation.equipments.forEach(function (a) {
        grouped[a.control_number] = grouped[a.control_number] || [];
        grouped[a.control_number].push(a);
      });
      const equipments = Object.values(grouped);

      equipments.map(item => {
        let floor = -3;
        if (item[0].hasOwnProperty('floor'))
          floor = item[0].floor;
        let locationInfo = {
          longitude: item[0].location.Point[0],
          latitude: item[0].location.Point[1],
          control_number: item[0].control_number,
          device_category: item[0].device_category,
          floor: floor,
          date: item[0].datetime
        };
        equipmentList.push(locationInfo);
        return null;
      });
      this.setState({ equipmentLocationList: equipmentList });
    }
  };

  handleCleanSearch = () => {
    this.setState({ isSearch: false, search: '', searchResult: [] });
  };

  handleSearch = (search) => {
    if (search !== '') {
      this.setState({ isSearch: true, search: search });
      this.handleFilterSearch(search);
    } else {
      this.setState({ isSearch: false, search: search });
    }
  };

  handleFilterSearch = (search) => {
    let filtered = this.innerTableData.filter(item => {
      if (item.deviceCategory.toLowerCase().includes(search.toLowerCase())
        || item.controlNum.toLowerCase().includes(search.toLowerCase())
        || item.team.toLowerCase().includes(search.toLowerCase())
        || item.model.toLowerCase().includes(search.toLowerCase())
        || item.maker.toLowerCase().includes(search.toLowerCase())
        || item.department.toLowerCase().includes(search.toLowerCase())
        || item.team.toLowerCase().includes(search.toLowerCase())) {
          return item;
      } else {
        return null;
      }
    });
    this.setState({ searchResult: filtered });
  };

  handleMenu = () => {
    this.setState({ openFilterMenu: !this.state.openFilterMenu });
  }

  handleInputChange = (newValue, key) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [key]: newValue
      },
    }));
  };

  handleApplyFilters = () => {
    const { filter } = this.state;
    let filtered = [];
    let finalFilter = [];
    let dataList = [];
    const inputs = CONST.FILTER_INPUTS;
    let filterBy = [];
    inputs.map((type, i) => {
      if (filter[type] !== '') {
        filterBy[i] = true;
      }
      return null;
    });

    filterBy.map((d, i) => {
      if (d) {
        dataList[i] = this.innerTableData.filter(item => item[inputs[i]].toLowerCase() === filter[inputs[i]].toLowerCase())
      }
      return null;
    });
    for (let i = 0; i < 6; i++) {
      if (!!dataList[i]) {
        filtered = filtered.concat(dataList[i]);
      }
    }
    if (filtered.length > 1) {
      let duplicates = filtered.reduce(function (acc, el, i, arr) {
        if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
      }, []);
      finalFilter = duplicates.length === 0 ? filtered : duplicates;
    } else {
      finalFilter = filtered;
    }
    this.setState({ searchResult: finalFilter, isFilterApplied: true, openFilterMenu: false });
  };

  handleCleanFilters = () => {
    this.setState({
      searchResult: [],
      isFilterApplied: false,
      openFilterMenu: false,
      filter: {
        deviceCategory: '',
        controlNum: '',
        model: '',
        maker: '',
        team: '',
        department: ''
      }
    });
  };

  handleHideForm = () => {
    this.setState({ showDetails: false, equipmentSelected: {}, equipmentHasLocation: false });
  }

  handleShowDetailsView = (equipmentId, tableMeta) => {
    const equipment = this.props.equipmentList.find(item => item.id === equipmentId[0]);
    equipment.name = tableMeta.rowData[1];
    equipment.floorName = tableMeta.rowData[6];
    this.setState({ showDetails: true, equipmentSelected: equipment, equipmentHasLocation: equipmentId[1] });
  }

  equipmentDetails = (equipmentId, tableMeta) => {
    return (
      <IconButton style={{ height: '4vh', width: '4vh' }} onClick={() => { this.handleShowDetailsView(equipmentId, tableMeta) }}>
        <NoteAddIcon style={{ color: "#2F80ED" }} />
      </IconButton>
    );
  };

  handleEquipmentLocation = (equipmentId) => {
    const currentEquipment = this.props.equipmentList.find(item => item.id === equipmentId);
    this.props.changeView(CONST.MAP, CONST.PREVIEWEQUIPMENT);
    this.props.changeEquipmentFilter(true);
    this.props.changePeopleFilter(false);
    this.props.changeZoomToEquipment(currentEquipment.control_number);
  };

  findEquipmentLocation = (equipmentId, tableMeta) => {
    return (
      <div>
        {equipmentId[1] ?
          <IconButton
            style={{ height: '4vh', width: '4vh' }}
            onClick={() => { this.handleEquipmentLocation(equipmentId[0]) }}>
            <SearchIcon style={{ color: "#2F80ED" }} />
          </IconButton>
          :
          <IconButton
            disabled
            style={{ height: '4vh', width: '4vh' }}>
            <SearchIcon style={{ color: "#757575" }} />
          </IconButton>
        }
      </div>
    );
  };

  floorRender = (label) => {
    return (
      <div style={{ color: label[1], fontWeight: 'bold' }}>
        {label[0]}
      </div>
    );
  }

  getFloorInfo = (userFloor) => {
    const { mapConfigs } = this.props;
    
    let floorColor = '#757575';
    let actualFloor = 'GPS';
    let floor = !!mapConfigs.floors ? mapConfigs.floors.find(f => f.ordinal === userFloor) : undefined;
    if (!!floor) {
      //floorColor = floor.color;
      floorColor = getFloorColorByOrdinal(floor.ordinal);
      actualFloor = floor.label;
    }
    if (actualFloor !== 'GPS') {
      let aux = actualFloor;
      actualFloor = `L${aux}`;
    }
    return [floorColor, actualFloor];
  };

  handleFillInnerTable = () => {
    this.props.equipmentList.map(item => {
      let categoryName = '';
      let categoryInfo = this.props.equipmentCategories.find(category => category.id === item.device_category);
      let roleInfo = this.props.roles.find(role => role._id === item.team);
      if (!!categoryInfo)
        categoryName = categoryInfo.name;

      let floor = '-';
      let floorColor = CONST.DEFAULT_FLOOR_COLOR;
      let location = '-';
      let locationAvailable = false;
      let dateFormated = "";
      let date = "";
      if (item.hasOwnProperty(CONST.KIOLOC)) {
        if (item.kioLoc.hasOwnProperty(CONST.FLOOR)) {
          [floorColor, floor] = this.getFloorInfo(item.kioLoc.floor);
        }
        if (item.kioLoc.hasOwnProperty(CONST.LOCATION)) {
          location = item.kioLoc.location;
        }
        if (item.kioLoc.hasOwnProperty(CONST.POINT)) {
          locationAvailable = true;
        }
        if (item.kioLoc.hasOwnProperty(CONST.TIMESTAMP)) {
          dateFormated = moment(item.kioLoc.timestamp).format(CONST.DATE_FORMAT);
          date = item.kioLoc.timestamp;
        }
      }

      this.innerTableData.push({
        deviceCategory: categoryName,
        controlNum: item.control_number,
        team: roleInfo ? roleInfo.role : null,
        model: item.model,
        maker: item.maker,
        department: item.department,
        id: [item.id, locationAvailable],
        find: [item.id, locationAvailable],
        location: location,
        floor: [floor, floorColor],
        tagNumber: item.beacon_number,
        timestamp: [dateFormated, date],
      });
      return null;
    });
  };

  handleSortInnerTable = () => {
    let grouped = Object.create(null);
    if (this.state.isSearch || this.state.isFilterApplied) {
      this.state.searchResult.forEach(function (a) {
        grouped[a.deviceCategory] = grouped[a.deviceCategory] || [];
        grouped[a.deviceCategory].push(a);
      });
    } else {
      this.innerTableData.forEach(function (a) {
        grouped[a.deviceCategory] = grouped[a.deviceCategory] || [];
        grouped[a.deviceCategory].push(a);
      });
    }
    this.data = grouped;
  }

  handleFillTable = () => {
    this.props.equipmentCategories.map(category => {
      if (!!this.data[category.name]) {
        this.tableData.push({ deviceCategory: category.name });
      }
      return null;
    });
  }

  handleReturnTableColumns = () => {
    const columns = [
      {
        name: CONST.MAIN_COLUMNS.deviceCategory.id,
        label: CONST.MAIN_COLUMNS.deviceCategory.label,
        options: {
          sort: false,
          filter: false,
          customHeadRender: (value, tableMeta) => {
            return (<th key={tableMeta.rowIndex + 1} className="MuiTableCell-root MuiTableCell-head" style={{ width: 'auto', top: "0px", left: "0px", backgroundColor: "white", position: "sticky", zIndex: "100" }}>
              <p style={{ textAlign: 'center', marginTop: "15px", fontSize: "14px", color: "gray" }}>{""}</p>
            </th>)
          },
        }
      }
    ];
    return columns;
  }

  handleReturnInnerTableColumns = () => {
    const innerColumns = [
      {
        name: CONST.INNER_COLUMNS.details.id,
        label: CONST.INNER_COLUMNS.details.label,
        options: {
          filter: false,
          sortThirdClickReset: true,
          customBodyRender: (value, tableMeta) => {
            return this.equipmentDetails(value, tableMeta);
          }
        }
      },
      {
        name: CONST.INNER_COLUMNS.deviceCategory.id,
        label: CONST.INNER_COLUMNS.deviceCategory.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.find.id,
        label: CONST.INNER_COLUMNS.find.label,
        options: {
          sort: false,
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return this.findEquipmentLocation(value, tableMeta);
          }
        }
      },
      {
        name: CONST.INNER_COLUMNS.controlNum.id,
        label: CONST.INNER_COLUMNS.controlNum.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.location.id,
        label: CONST.INNER_COLUMNS.location.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.tagNumber.id,
        label: CONST.INNER_COLUMNS.tagNumber.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.floor.id,
        label: CONST.INNER_COLUMNS.floor.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return this.floorRender(value, tableMeta);
          }
        }
      },
      {
        name: CONST.INNER_COLUMNS.timestamp.id,
        label: CONST.INNER_COLUMNS.timestamp.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => <p>{value[0]}</p>
        }
      },
      {
        name: CONST.INNER_COLUMNS.model.id,
        label: CONST.INNER_COLUMNS.model.label,
        options: {
          sortThirdClickReset: true,
          filter: true,
        }
      },
      {
        name: CONST.INNER_COLUMNS.maker.id,
        label: CONST.INNER_COLUMNS.maker.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.team.id,
        label: CONST.INNER_COLUMNS.team.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      },
      {
        name: CONST.INNER_COLUMNS.department.id,
        label: CONST.INNER_COLUMNS.department.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
        }
      }
    ];
    return innerColumns;
  }

  handleReturnInnerTableOptions = () => {
    const innerOptions = {
      filterType: CONST.TABLE_OPTIONS.filterType,
      responsive: CONST.TABLE_OPTIONS.responsive,
      selectableRows: CONST.TABLE_OPTIONS.selectableRows,
      filter: false,
      download: false,
      viewColumns: false,
      pagination: false,
      search: false,
      print: false,
      customSort: (data, colIndex, order) => {
        return CONST.TABLE_SORT(data, colIndex, order);
      },
    };
    return innerOptions;
  }

  handleReturnTableOptions = (innerColumns, innerOptions) => {
    const options = {
      filterType: CONST.TABLE_OPTIONS.filterType,
      responsive: CONST.TABLE_OPTIONS.responsiveMain,
      selectableRows: CONST.TABLE_OPTIONS.selectableRows,
      expandableRows: true,
      expandableRowsHeader: true,
      expandableRowsOnClick: true,
      filter: false,
      download: false,
      search: false,
      viewColumns: false,
      print: false,
      rowsPerPage: CONST.TABLE_OPTIONS.rowsPerPage,
      rowsPerPageOptions: CONST.TABLE_OPTIONS.rowsPerPageOptions,
      isRowExpandable: (dataIndex, expandedRows) => {
        // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
        if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
        return true;
      },
      rowsExpanded: [0, 1],
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <React.Fragment>
            <tr>
              <td colSpan={6}>
                <TableContainer component={Paper}>
                  <MuiThemeProvider theme={this.innerTheme}>
                    <MUIDataTable
                      data={this.data[rowData]}
                      columns={innerColumns}
                      options={innerOptions}
                    />
                  </MuiThemeProvider>
                </TableContainer>
              </td>
            </tr>
          </React.Fragment>
        );
      },
      onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.info("[EQUIPMENTHISTORY] onRowExpansionChange:", curExpanded, allExpanded, rowsExpanded)
    };
    return options;
  }

  handleRenderTableTitle = () => {
    let { classes, open } = this.props;
    return (
      <div>
        <div className={classes.titleContainer}>
          <div className={classes.titleDiv}>
            <div className={classes.titleIconDiv}>
              <BusinessCenterIcon className={classes.titleIcon} />
            </div>
            <h2 className={classes.titleLabel}>{CONST.EQUIPMENT}</h2>
          </div>
          <div className={classes.formContainer}>
            <div className={open ? classes.searchContainerOpen : classes.searchContainer}>
              <div className={classes.inputContainer}>
                <Input
                  id="webNavv-tableView-equipmentTable-searchInput-1.0"
                  className={classes.input}
                  placeholder='Search'
                  disableUnderline={true}
                  value={this.state.search}
                  onChange={e => { this.handleSearch(e.target.value) }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  } />
              </div>
              <div className={classes.closeSearch}>
                {this.state.search !== '' &&
                  <IconButton
                    id="webNavv-tableView-equipmentTable-clearSearchButton-1.0"
                    onClick={() => this.handleCleanSearch()}
                    className={classes.cleanBtn}>
                    <CloseIcon />
                  </IconButton>
                }
              </div>
            </div>
            <Tooltip title="Filters">
              <IconButton
                id="webNavv-tableView-equipmentTable-showFilters-1.0"
                style={this.state.isFilterApplied
                  ? { marginLeft: '23vh', marginTop: '5px', height: '6vh', width: '6vh' }
                  : { marginLeft: '19vh', marginTop: '5px', height: '6vh', width: '6vh' }}
                onClick={() => { this.handleMenu() }}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            {this.state.isFilterApplied && (
              <Tooltip title="Clean Filter">
                <IconButton
                  id="webNavv-tableView-equipmentTable-hideFilters-1.0"
                  style={{ marginTop: '5px', height: '6vh', width: '6vh' }}
                  onClick={() => { this.handleCleanFilters() }}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            )}
            {this.state.openFilterMenu && (
              <Paper className={classes.paper}>
                <Grid
                  id="webNavv-tableView-equipment-equipmentFilterForm-1.0"
                  container
                  spacing={2}
                  justify={"center"}
                  alignItems={"center"}>
                  <FilterForm
                    filter={this.state.filter}
                    equipmentCategories={this.props.equipmentCategories}
                    roles={this.props.roles}
                    handleInputChange={this.handleInputChange}
                  />
                </Grid>
                <div className={classes.timeBtns}>
                  <Button
                    id="webNavv-tableView-equipmentTable-clearFiltersButton-1.0"
                    size="small"
                    onClick={() => this.handleCleanFilters()}>
                      {CONST.CANCEL}
                  </Button>
                  <Button
                    id="webNavv-tableView-equipmentTable-applyFiltersButton-1.0"
                    size="small"
                    color="primary"
                    onClick={() => this.handleApplyFilters()}>
                      {CONST.APPLY}
                  </Button>
                </div>
              </Paper>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleRenderEquipmentTable = () => {
    this.handleFillInnerTable();
    this.handleSortInnerTable();
    this.handleFillTable();
    const columns = this.handleReturnTableColumns();
    const innerColumns = this.handleReturnInnerTableColumns();
    const innerOptions = this.handleReturnInnerTableOptions();
    const options = this.handleReturnTableOptions(innerColumns, innerOptions);

    return { columns, options }
  }

  render() {
    this.tableData = [];
    this.innerTableData = [];
    const { columns, options } = this.handleRenderEquipmentTable();
    let l = this.props.open ? '23%' : '4%';
    let w = this.props.open ? '75.5%' : '95%';

    return (
      <div
        id="webNavv-tableView-equipment-1.0"
        style={{ marginTop: '5%', marginLeft: l, width: w, height: '90vh' }}
      >
        {this.state.showTable && !this.state.showDetails &&
          <div id="webNavv-tableView-equipmentTable-1.0">
            <MuiThemeProvider theme={this.mainTheme}>
              <MUIDataTable
                title={this.handleRenderTableTitle()}
                data={this.tableData}
                columns={columns}
                options={options}
              />
          </MuiThemeProvider>
          </div>
        }
        {this.state.showDetails && (
          <EquipmentInfo
            equipmentList={this.props.equipmentList}
            equipmentSelected={this.state.equipmentSelected}
            equipmentHasLocation={this.state.equipmentHasLocation}
            handleHideForm={this.handleHideForm}
            createEquipmentLog={this.createEquipmentLog}
            handleEquipmentLocation={this.handleEquipmentLocation}
          />
        )}
        <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  equipmentList: state.equipments.equipmentList,
  equipmentCategories: state.equipments.equipmentCategories,
  equipmentLocation: state.equipments.equipmentLocation,
  equipmentMapFilter: state.views.equipmentMapFilter,
  roles: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
  open: state.views.drawerOpen,
  campusList: state.campus.campusList,
  campusInfo: state.auth.userInfo.campusInfo,
  mapConfigs: state.views.mapConfigs
})

export default connect(mapStateToProps, {
  fetchACLS,
  getEquipment,
  getEquipmentLocationKIO,
  getEquipmentLocation,
  categoriesByCampus,
  changeZoomToEquipment,
  changeView,
  changeEquipmentFilter,
  changePeopleFilter
})(withStyles(styles)(EquipmentHistory));