import _ from "lodash";
import { mutationUpdateResourceSlotUnassignUser } from "../../../graphql/mutations";
import { jobStatusTypesGraphQL } from "../../jobsBoard.constants";
import { handleRemoveFirstRowFromGroups, handleUpdateGroupIdPosition, handleSortRowsByUserName } from "../jobsBoard";

export const handleUnassignUserToSlot = async(items, groups, groupId, assignedTo, jobStatusList, tenantId) =>{
  const currentSlot = groups.find(group=>group.id === groupId);
  const userUnassignedFromSlot = await mutationUpdateResourceSlotUnassignUser(currentSlot.resource_slotid);

  if (userUnassignedFromSlot) {
    return "done";
  }
  else{
    return "error";
  }
}

export const handleAfterUnassignUserToSlot = (groups, updateSlot, editBoard) =>{
  const newArray = groups.map(
    group =>
      group.resource_slotid === updateSlot.resource_slotid
        ? Object.assign({}, group, {
          title: "",
          assigned: false,
          assignedId: "",
          status: jobStatusTypesGraphQL.unassigned
        })
      : group
  );

  if (editBoard) {
    let firstRow = newArray[0];
    const groupsWithoutFirstRow = handleRemoveFirstRowFromGroups(newArray);
    const groupsSortedByUserName =  handleSortRowsByUserName(groupsWithoutFirstRow);
    const groupsWithFirstRow = [firstRow, ...groupsSortedByUserName];
    const groupsCopy = handleUpdateGroupIdPosition(groupsWithFirstRow, editBoard);
    return groupsCopy;
  }
  else{
    const groupsSortedByUserName =  handleSortRowsByUserName(newArray);
    const groupsCopy = handleUpdateGroupIdPosition(groupsSortedByUserName, editBoard);
    return groupsCopy;
  }
}