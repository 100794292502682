import React from 'react';
import TuneIcon from "@material-ui/icons/Tune";
import BookIcon from "@material-ui/icons/Book";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import GroupIcon from "@material-ui/icons/Group";
import AppsIcon from "@material-ui/icons/Apps";
import RestoreIcon from "@material-ui/icons/Restore";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ErrorIcon from '@material-ui/icons/Error';
import MapIcon from '@material-ui/icons/Map';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import contactVector from "../../../../img/contactVector.png";
import contactSelected from "../../../../img/contactVector2.png";

export const USER_TRACK_KEY = "transporter";
export const DEVICE_TRACK_KEY = "transporterPhone";
export const EQUIPMENT_TRACK_KEY = "previewEquipment";
export const ACTIVE_MAP = "Active Map";
export const USER_MAP = "Track User Map";
export const DEVICE_MAP = "Track Device Map";
export const EQUIPMENT_MAP = "Equipment Map";
export const PEOPLE_FILTER = "People";
export const EQUIPMENT_FILTER = "Equipment";
export const MAP = "map";
export const HISTORY = "history";

export const SET_SETTINGS_TAB = (props) =>{
  let settingsTabInfo = [
    {
      action: 'changeView',
      label: "ST",
      view: "settings",
      viewProp: "mainSettings",
      primaryText: "Main Settings",
      style: { marginTop: "10px" },
      leftIcon:  <TuneIcon style={
        props.itemSelected === "ST"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key:"main-settings-tab"
    },
    {
      action: 'changeView',
      label: "LG",
      view: "settings",
      viewProp: "logs",
      primaryText: "Logs",
      style: { marginTop: "5px" },
      leftIcon:  <BookIcon style={
        props.itemSelected === "LG"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "logs-tab"
    }
  ];
  return settingsTabInfo;
};

export const SET_ADMIN_TAB = (props)=>{
  let adminTabInfo = [
    {
      action: 'changeView',
      view: "admin",
      viewProp: "users",
      label: "US",
      primaryText: "Users",
      style: { marginTop: "10px" },
      leftIcon: <PersonIcon style={
        props.itemSelected === "US"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "users-tab"
    },
    {
      action: 'changeView',
      view: "admin",
      viewProp: "equipment",
      label: "EQ",
      primaryText: "Equipment",
      style: { marginTop: "5px" },
      leftIcon: <BusinessCenterIcon style={
        props.itemSelected === "EQ"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "equipment-tab"
    },
    {
      action: 'changeView',
      view: "admin",
      viewProp: "accessControl",
      label: "AC",
      primaryText: "Access Control",
      style: { marginTop: "5px" },
      leftIcon: <LockIcon style={
        props.itemSelected === "AC"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "access-control-tab"
    }
  ];
  return adminTabInfo;
};

export const SET_CONTACT_TAB = (props)=>{
  let contactTabInfo=[
    {
      action: '',
      label: "DB",
      primaryText: "Dashboard",
      style: { marginTop: "10px" },
      leftIcon: <DashboardIcon
      style={
        props.itemSelected === "DB"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "dashboard-tab"
    },
    {
      action: 'changeView',
      view: "contact",
      viewProp: "",
      label: "CT",
      primaryText: "Contact",
      style: { marginTop: "10px" },
      type: "svg",
      src1: contactSelected,
      alt1: "contactVectorSelectedIcon",
      src2: contactVector,
      alt2: "contactVectorIcon",
      key: "contact-tab"
    }
  ];
  return contactTabInfo;
};

export const SET_WORKFLOWAUTOMATION_TAB = (props)=>{
  let workflowAutomationTabInfo=[
    {
      action: 'changeView',
      view: "workflowAutomation",
      viewProp: "jobsBoard",
      label: "WA-JB",
      primaryText: "Jobs Board",
      style: { marginTop: "10px" },
      leftIcon: <FormatListBulletedIcon
      style={
        props.itemSelected === "DB"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "jobsBoard-tab"
    },
    {
      action: 'changeView',
      view: "workflowAutomation",
      viewProp: "jobsHistory",
      label: "WA-JH",
      primaryText: "Jobs History",
      style: { marginTop: "10px" },
      leftIcon: <RestoreIcon
      style={
        props.itemSelected === "DB"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "jobsHistory-tab"
    }
  ];
  return workflowAutomationTabInfo;
};

export const SET_CHAT_TAB = (props) => {
  let chatTabInfo=[
    {
      action: 'openBroadcast',
      view: "chat",
      viewProp: "broadcast",
      label: "B",
      primaryText: "NavvCast",
      style: { marginTop: "10px" },
      leftIcon: <ChatBubbleIcon style={
        props.itemSelected === "B"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "chat-tab"
    }
  ];
  return chatTabInfo;
};

export const SET_WAYFINDING_TAB = (props) => {
  let wayfindingTabInfo=[
    {
      action: 'wayfinding',
      view: "wayfinding",
      viewProp: "wayfinding",
      label: "WF",
      primaryText: "Not Available",
      style: { marginTop: "10px" },
      leftIcon: <ErrorIcon style={
        props.itemSelected === "WF"
          ? { fill: "red" }
          : { fill: "red" }
      }/>,
      type: "icon",
      key: "wayfinding-error-tab"
    },
    {
      action: 'changeView',
      view: "wayfinding",
      viewProp: "wayfinding",
      label: "WF",
      primaryText: "MappedIn Map",
      style: { marginTop: "10px" },
      leftIcon: <MapIcon style={
        props.itemSelected === "WF"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "wayfinding-tab"
    }
  ];
  return wayfindingTabInfo;
};

export const SET_TABLE_TAB = (props) =>{
  let tableTabInfo=[
    {
      action: 'changeView',
      view: "table",
      viewProp: "teamManager",
      label: "TM",
      primaryText: "Team Manager",
      style: { marginTop: "10px" },
      leftIcon:  <GroupIcon style={
        props.itemSelected === "TM"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "team-manager-tab"
    },
    {
      action: 'changeView',
      view: "table",
      viewProp: "fleetManager",
      label: "FM",
      primaryText: "Fleet Manager",
      style: { marginTop: "5px" },
      leftIcon:  <AppsIcon style={
        props.itemSelected === "FM"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "fleet-manager-tab"
    },
    {
      action: 'changeView',
      view: "table",
      viewProp: "history",
      label: "HT",
      primaryText: "History",
      style: { marginTop: "5px" },
      leftIcon:  <RestoreIcon style={
        props.itemSelected === "HT"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "history-table-tab"
    },
    {
      action: 'changeView',
      view: "table",
      viewProp: "equipmentHistory",
      label: "ET",
      primaryText: "Equipment",
      style: { marginTop: "5px" },
      leftIcon:  <BusinessCenterIcon style={
        props.itemSelected === "ET"
          ? { fill: "#2196F3" }
          : { fill: "#757575" }
      }/>,
      type: "icon",
      key: "equipment-table-tab"
    }
  ];
  return tableTabInfo;
};