import lightBlue from "@material-ui/core/colors/lightBlue";
export const styles = theme => ({
  snackMsg: {
    display: 'inline',
    color: 'white'
  },
  addBtn: {
    position: 'relative',
    marginLeft: '95%',
    bottom: 100,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 30,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2843A3',
  },
  back: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.25,
    "&:hover": {
      color: lightBlue[600],
      cursor: "pointer",
    }
  },
});