import React from "react";
import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    position: absolute;
    left: 60%;
    top: 55%;
    transform: translate(-50%, -50%);
`;
const overrideNavClosed = css`
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
`;

function AwesomeSpinner(props) {
  const renderSpinner = ()=>{
    let spinnerStyle = props.navOpen ? override : overrideNavClosed;

    switch (props.config) {
      case 'default':
        return (
          <div className="sweet-loading">
            <HashLoader
              css={spinnerStyle}
              size={100}
              color={"#3352AD"}
              loading={props.loading}
            />
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      {renderSpinner()}
    </React.Fragment>
  );
};
export default AwesomeSpinner;
