export const dateFormat = (date) => {
  const month = [];
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  const h = addZero(date.getHours());
  const m = addZero(date.getMinutes());
  const s = addZero(date.getSeconds());
  const time = h + ":" + m + ":" + s;
  const dateexposed = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${time}`;
  return dateexposed;
};

const addZero = (i) =>{
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};
