import React from 'react';

import MUIDataTable from "mui-datatables/dist/index";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const getMuiTheme = ()=>{
  return createTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: '75vh !important'
        },
        paper: {
          boxShadow: "0 4px 2px -2px gray",
          paddingTop: "1px"
        }
      },
      MUIDataTableHeadCell: {
        toolButton: {
          color: "#7D7D7D",
          //backgroundColor: 'lightblue',
          float: 'none !important'
        },
        contentWrapper:{
          //backgroundColor: 'pink',
          justifyContent: "center",
          display: "flex",
          paddingLeft: "30px"
        }
      },
      MUIDataTableBodyCell: {
        root: {
          textAlign: "center",
          // width: "150px",
          padding: "0px 3px !important"
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor: '#E3F2FD',
        },
        title: {
          color: '#2196F3'
        }
      },
      MUIDataTableBody: {
        emptyTitle:{
          display:'none'
        }
      },
      MUIDataTableSelectCell:{
        fixedLeft:{
          backgroundColor: 'white'
        }
      }
    },
  });
};

function MasterTable(props) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        options={props.options}
      />
    </MuiThemeProvider>
  );
};

export default (MasterTable);
