import { makeStyles } from '@material-ui/core/styles';
import lightBlue from "@material-ui/core/colors/lightBlue";

export const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: 'block',
    flexGrow: 1,
    height: '80%',
    padding: '30px'
  },
  container: {
    backgroundColor: 'white',
    display: 'content',
    boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
    height: '87vh'
  },
  containedBtn: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  containedBtnPass: {
    marginLeft: '10px',
    marginTop: '10px',
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  buttonContainer: {
    height:'20%',
    display: 'block',
    float:'right',
    paddingRight: '30px'
  },
  titleLbl: {
    color:'rgba(0, 0, 0, 0.6)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    marginLeft: '10px',
    fontSize: '18px'
  },
  inlineContainer: {
    display: 'inline'
  },
  iconTitle: {
    color:'rgba(0, 0, 0, 0.6)',
    fontSize: '30px'
  },
  titleContainer: {
    display:'inline',
    paddingTop: '10px'
  },
  formControlTop: {
    marginTop: '20px'
  },
  snackMsg:{
    display:'inline',
    color:'white'
  },
  passInput:{
    marginLeft:'10px'
  },
  passInputError:{
    marginLeft:'10px',
    color:'red'
  },
  securityContainer:{
    display:'flex',
    marginTop: '40px'
  }
}));