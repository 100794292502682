import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';

import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Skeleton from '@material-ui/lab/Skeleton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { listUsers } from "../../../actions/user/userActions";
import { changeZoomToUser, userChatSelected, changeZoomToEquipment, changeView } from '../../../actions/views/viewsActions';
import { getEquipment, getEquipmentLocationKIO, categoriesByCampus } from "../../../actions/equipment/equipmentActions";
import equipmentWhiteIcon from '../../../img/equipmentWhite.png';
import chatIcon from '../../../img/ico_chatbox.png';
import { filterUsers } from "../../../filters/aclUsersFilter";
import { getFloorColorByOrdinal } from '../../map/floorColors';
import { useStyles } from './styles';

const UsersList = React.memo((props) => {
  const classes = useStyles();
  const [itemSelected, setItemSelected] = useState('');
  const [orderedList, setOrderedList] = useState([]);
  const [equipmentOrderedList, setEquipmentOrderedList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [unreadMsg, setUnreadMsg] = useState([]);
  const [isListAvailable, setIsListAvailable] = useState(true);
  const [usersStreaming, setUsersStreaming] = useState({});
  const [actionMessage, setActionMessage] = useState(null);

  let search = '';
  let equipmentKIOInterval = null;

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      if (props.users.length !== 0) {
        handleSocketChat();
      } else {
        props.listUsers().then(resp => {
          handleSocketChat();
        });
      }
      if (props.equipmentMapFilter) {
        handleEquipmentData();
      }
      equipmentInterval();
      mounted.current = true;
    } else {
      // componentDidUpdate
      if (updated === false && props.users.length !== 0)
        setUpdated(true);
    }
    //Unmount
    return () => {
      clearInterval(equipmentKIOInterval);
    };
  }, []);

  useEffect(() =>{
    handleUpdateFloorsList(props.lastlocationSocket);
  }, [props.lastlocationSocket]);

  useEffect(() => {
    if (props.transporterFound === '' || props.transporterFound === false) {
      setItemSelected(false);
    }
  }, [props.transporterFound]);

  useEffect(() => {
    if (!props.userFilterSelected)
      setItemSelected(false);
  }, [props.userFilterSelected]);

  useEffect(() => {
    const { userId } = props;
    if (userId) {
      props.socketChat.emit('users', userId);
    }
  }, [props.userId]);

  useEffect(() => {
    const { userChatUpdated } = props;

    if (!!userChatUpdated && !!userChatUpdated.id) {
      let orderedUsers = orderedList.map( (user) => {
          if (user._id === userChatUpdated.id) {
            return {...user, first_name: `${userChatUpdated.first_name}`, last_name: `${userChatUpdated.last_name}`};
          }
          return user;
      });
      let users = usersList.map( (user) => {
        if (user._id === userChatUpdated.id) {
          return {...user, first_name: `${userChatUpdated.first_name}`, last_name: `${userChatUpdated.last_name}`};
        }
        return user;
    });

      setOrderedList(orderedUsers);
      setUsersList(users);
  }
  }, [props.userChatUpdated]);

  useEffect(() => {
    if (props.equipmentMapFilter && props.peopleMapFilter) {
      let users = handleOrderUsers();
      let equipment = handleOrderEquipmentList();
      setOrderedList(users);
      setUsersList(users);
      setEquipmentOrderedList(equipment);
      setEquipmentList(equipment);
    } else if (!props.equipmentMapFilter && props.peopleMapFilter) {
      let users = handleOrderUsers();
      setOrderedList(users);
      setUsersList(users);
    } else if (props.equipmentMapFilter && !props.peopleMapFilter) {
      let equipment = handleOrderEquipmentList();
      setEquipmentOrderedList(equipment);
      setEquipmentList(equipment);
    } else if (!props.equipmentMapFilter && !props.peopleMapFilter) {
      setOrderedList([]);
      setUsersList([]);
      setEquipmentOrderedList([]);
      setEquipmentList([]);
    }
    if (searchValue !== '')
      searchList(searchValue);
  }, [props.equipmentMapFilter, props.peopleMapFilter, props.users]);

  useEffect(() => {
    if (orderedList.length === 0) {
      setOrderedList(handleOrderUsers());
      setUsersList(handleOrderUsers());
    }
    if (equipmentOrderedList.length === 0) {
      setEquipmentOrderedList(handleOrderEquipmentList());
      setEquipmentList(handleOrderEquipmentList());
    }
  }, [updated, props.users]);

  /*
  useEffect(() => {
    renderListInfo();
  }, [unreadMsg]);
  */

  const handleEquipmentData = () => {
    props.categoriesByCampus(props.campusId)
    props.getEquipmentLocationKIO(props.campusId);
    props.getEquipment(props.campusId);
  }

  const equipmentInterval = () => {
    equipmentKIOInterval = setInterval(() => {
      let enableEquipmentFunctions = props.equipmentMapFilter ? true : false;
      if (enableEquipmentFunctions) {
        props.getEquipmentLocationKIO(props.campusId)
          .then(resp => {
            props.getEquipment(props.campusId);
          });
      }
    }, 60000);
  }

  useEffect(() => {
    if (actionMessage) {
      const { type, from } = actionMessage;

      switch (type) {
        case "New": {
          if (!unreadMsg.includes(from)) {
            let unreadCopy = [...unreadMsg];
            unreadCopy.push(from);
            setUnreadMsg(unreadCopy);
          }
          break;
        }
        case "Read": {
          if (unreadMsg.includes(from)) {
            let unreadCopy = unreadMsg.filter(u => u !== from);
            setUnreadMsg(unreadCopy);
          }
          break;
        }
        case "Unread": {
          setUnreadMsg(from);
          break;
        }
      }
    }
  }, [actionMessage]);

  const handleSocketChat = () => {
    const { userId } = props;

    props.socketChat.on('refreshChat', user => {
      if (!!props.users && !!props.campusId) {
        let currentUser = props.users.find(u => u._id === user);
        if (!!currentUser && currentUser.campusId === props.campusId)
          props.listUsers();
      }
    });

    props.socketChat.on('users', user => {
      let { users } = user;
      updateUnreadMessage(users);
    });


    props.socketChat.on('SSC-SIO-connect', newUser => {
      props.listUsers();
    });

    props.socketChat.on('SSC-SIO-disconnect', newUser => {
      props.listUsers();
    });
    props.socketChat.on('user message read', user => {
      updateMessageRead(user);
    });

    props.socketChat.on('new message', msg => {
      updateNewMessage(msg);
    });

    if (!!userId) {
      //props.socketChat.emit('join', { id: props.socketChat.id, userId: userId, firebaseToken: props.pushNotToken });
      props.socketChat.emit('users', userId);
    }

  };

  const updateMessageRead = (user) => {
    setActionMessage({ type: "Read", from: user })
  }

  const updateNewMessage = (res) => {
    let { newMessage } = res;
    setActionMessage({ type: "New", from: newMessage.origin });
  };

  const updateUnreadMessage = (users) => {
    let unreadCopy = [];

    Object.entries(users).forEach(([id, value]) => {
      if (value.unreadMsgs) {
        unreadCopy.push(id);
      }
    });

    setActionMessage({ type: "Unread", from: unreadCopy });
  }

  const handleUpdateFloorsList = (message) => {
    let locationKeys = Object.keys(message);
    locationKeys.forEach(location=>{
      if(!!message[location].userId){
        usersStreaming[message[location].userId] = message[location].frl;
        setUsersStreaming({ ...usersStreaming });
      }
    });
  }

  const getUserStatus = (status) => {
    let userStatus = 'Offline';
    if (status === 'idle')
      userStatus = 'Online';
    else if (status === 'inactive')
      userStatus = 'Inactive';

    return userStatus;
  };

  const getUserInitials = (first_name, last_name) => {
    const firstName = first_name.trim();
    const lastName = last_name.trim();
    let initials = firstName.substring(0, 1) + lastName.substring(0, 1);
    return initials;
  };

  const getUserFloorInfo = (userFloor) => {
    const { mapConfigs } = props;

    let floorColor = '#757575';
    let actualFloor = 'GPS';
    if (!!mapConfigs.floors) {
      mapConfigs.floors.forEach(floor => {
        if (floor.ordinal == userFloor) {
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
          actualFloor = floor.callout_name;
        }
      });
    }
    
    /*
    if (actualFloor !== 'GPS') {
      let aux = actualFloor;
      actualFloor = `L-${aux}`;
    }
    */

    return [floorColor, actualFloor];
  };

  const handleZoomMapToUser = (userId, deviceId) => {
    if (props.viewProps === '') {
      if (props.currentView !== 'map')
        props.changeView('map', '');
      setItemSelected(userId);
      props.changeZoomToUser({ userId: userId, deviceId: deviceId });
    }
  };

  const handleCleanZoomMapToUser = () => {
    if (props.viewProps === '') {
      setItemSelected('');
      props.changeZoomToUser({ userId: false, deviceId: '' });
    }
  };

  const handleZoomMapToEquipment = (assetTag) => {
    if (props.viewProps === '') {
      if (props.currentView !== 'map')
        props.changeView('map', '');
      setItemSelected(assetTag);
      props.changeZoomToEquipment(assetTag);
    }
  };

  const handleCleanZoomMapToEquipment = () => {
    if (props.viewProps === '') {
      setItemSelected('');
      props.changeZoomToEquipment('');
    }
  };

  const handleOrderUsers = () => {
    const usersEnabled = props.users.filter(u => u.enabled === true && u.role_name !== 'Admin');
    let userList = filterUsers(usersEnabled, props.acl.contact);
    return userList.sort((a, b) => {
      let statusA = 0;
      let statusB = 0;
      if (a.status === 'idle')
        statusA = 0;
      else if (a.status === 'inactive')
        statusA = 1;
      else if (a.status === 'logged out')
        statusA = 2;
      if (b.staus === 'idle')
        statusB = 0;
      else if (b.status === 'inactive')
        statusB = 1;
      else if (b.status === 'logged out')
        statusB = 2;
      return (statusA < statusB ? -1 : 1);
    });
  };

  const handleEquipmentLocationKIO = () => {
    let equipmentList = [];

    props.equipmentList.map(item => {
      if (item.hasOwnProperty('kioLoc')) {
        let floor = -3;
        if (item.kioLoc.floor !== null && item.kioLoc.floor !== undefined) {
          floor = item.kioLoc.floor;
        }
        let locationInfo = {
          longitude: item.kioLoc.point[0],
          latitude: item.kioLoc.point[1],
          control_number: item.control_number,
          device_category: item.device_category,
          floor: floor,
          date: moment(item.kioLoc.timestamp).format("MM/DD/YYYY HH:mm:ss")
        };
        equipmentList.push(locationInfo);
      }
    });
    return equipmentList;
  }

  const handleOrderEquipmentList = () => {
    let equipmentList = handleEquipmentLocationKIO();
    equipmentList.sort((a, b) => {
      return (a.floor < b.floor ? -1 : 1);
    });
    let equipmentCopy = equipmentList;
    equipmentList.forEach((e, i) => {
      props.equipmentCategories.forEach(item => {
        if (item.id === e.device_category) {
          equipmentCopy[i].category_name = item.name;
        }
      });
    });
    return equipmentCopy;
  };

  const handleOpenUserChat = (e, userId) => {
    e.stopPropagation();
    props.userChatSelected(userId);
    //confirmSeenMessages(userId);
  };

  const confirmSeenMessages = (destiny) => {
    //props.socketChat.emit('set message read', destiny);
  };

  const userUnreadMsgs = (user) => {
    if (unreadMsg.includes(user._id)) {
      //handleOpenUserChat(user._id);
      /*
       const myNotification = new Notify('Message', {
         body: `Unread message`,
         tag: 'newMessage',
         timeout: 1,
       });
      myNotification.show();
      */
      return true;
    }
    return false;
  };

  const userContainerBody = (item, itemSelected, userInitials, userFloorColor, userFloorLbl, userTimeLbl, userStatus) => {
    let showUnreadDot = userUnreadMsgs(item);
    let initialsA = <div className={classes.initialsClicked} style={{ backgroundColor: userFloorColor }}
      onClick={() => { handleCleanZoomMapToUser() }}>
      <p className={classes.initialLbl}>{userInitials}</p>
    </div>;
    let initialsB = <div className={classes.initialsToClick} style={{ backgroundColor: userFloorColor }}
      onClick={() => { handleZoomMapToUser(item._id, item.device_id) }}>
      <p className={classes.initialLbl}>{userInitials}</p>
    </div>;
    let initials = item._id === itemSelected ? initialsA : initialsB;
    let isLongName = item.first_name.length + item.last_name.length > 16 ? true : false;
    return (
      <div className={classes.userTopSection}>
        <div className={classes.initialsDiv}>
          {props.viewProps !== 'transporter' && userStatus !== 'Offline' && userFloorLbl !== 'desktop' ?
            <React.Fragment>
              {initials}
            </React.Fragment>
            : <div className={classes.initials} style={{ backgroundColor: userFloorColor }}>
              <p className={classes.initialLbl}>{userInitials}</p>
            </div>
          }
          {props.aclViews[6].access &&
            <div className={classes.chatIconContainer} onClick={(e) => { handleOpenUserChat(e, item._id) }}>
              {showUnreadDot ?
                <React.Fragment>
                  <div className={classes.unreadDot}></div>
                  <img src={chatIcon} alt="chatIcon" width="17px" height="17px" style={{ marginLeft: '15px' }} />
                </React.Fragment>
                : <img src={chatIcon} alt="chatIcon" width="17px" height="17px" style={{ marginTop: '5px', marginLeft: '15px' }} />
              }
            </div>
          }
        </div>
        <div style={{ display: 'block' }}>
          <div style={{ display: 'flex' }}>
            <div className={classes.nameDiv}>
              <div className={classes.userName} style={isLongName ? { fontSize: '12px' } : { fontSize: '14px' }}>{`${item.first_name} ${item.last_name}`}</div>
            </div>
            <div className={classes.levelDiv}>
              <div className={classes.level}>
                {userFloorLbl !== 'desktop' ?
                  <p className={classes.levelLbl} style={{ color: userFloorColor }}>{userFloorLbl}</p>
                  : <p className={classes.levelLbl} style={{ color: 'white' }}>DSK</p>
                }
              </div>
            </div>
          </div>
          <div className={classes.nameDiv}>
            <div className={classes.userRole}>{item.role_name}</div>
            <div style={{ display: 'flex' }}>
              {userStatus === 'Online' && <div className={classes.userStatus} style={{ color: '#8bc34a' }}>Online</div>}
              {userStatus === 'Inactive' && <div className={classes.userStatus} style={{ color: '#ffc107' }}>Online</div>}
              {userStatus === 'Offline' && <div className={classes.userStatus} style={{ color: '#ff5722' }}>Offline</div>}
              <div className={classes.userDate}>{userTimeLbl}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const equipmentContainerBody = (item, itemSelected, userFloorLbl, userFloorColor, userTimeLbl) => {
    let findBtnA = <div className={classes.initialsClicked} style={{ backgroundColor: userFloorColor }}
      onClick={() => { handleCleanZoomMapToEquipment() }}>
      <img src={equipmentWhiteIcon} alt="equipmentIcon" width="17px" height="17px" style={{ marginTop: '5px' }} />
    </div>;
    let findBtnB = <div className={classes.initialsToClick} style={{ backgroundColor: userFloorColor }}
      onClick={() => { handleZoomMapToEquipment(item.control_number) }}>
      <img src={equipmentWhiteIcon} alt="equipmentIcon" width="17px" height="17px" style={{ marginTop: '5px' }} />
    </div>;
    let icon = itemSelected === item.control_number ? findBtnA : findBtnB;
    let currentCategory = props.equipmentCategories.find(category => category.id === item.device_category);
    let isLongCategory = item.device_category > 13 ? true : false;
    let isLongControlNum = item.control_number.toString().length > 8 ? true : false;
    return (
      <div className={classes.userTopSection}>
        <div className={classes.initialsDiv}>
          {props.viewProps !== 'transporter' && props.viewProps !== 'previewEquipment' ?
            <React.Fragment>
              {icon}
            </React.Fragment>
            : <div className={classes.initials} style={{ backgroundColor: userFloorColor }}>
              <img src={equipmentWhiteIcon} alt="equipmentIcon" width="17px" height="17px" style={{ marginTop: '5px' }} />
            </div>
          }
        </div>
        <div style={{ display: 'block' }}>
          <div style={{ display: 'flex', paddingBottom: '10px' }}>
            <div className={classes.nameDiv}>
              <div className={classes.userName} style={isLongCategory ? { fontSize: '12px' } : { fontSize: '14px' }}>{currentCategory.name}</div>
            </div>
            <div className={classes.levelDiv}>
              <div className={classes.level}>
                <div className={classes.levelLbl} style={{ color: userFloorColor, paddingTop: '15px' }}>
                  {userFloorLbl}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.controlDiv}>
            <div className={classes.assetTagTitle}>Control No.:</div>
            <div className={classes.assetTag} style={isLongControlNum ? { fontSize: '10px' } : { fontSize: '12px' }}>{item.control_number}</div>
          </div>
          <div className={classes.timeDiv}>
            <div className={classes.equipmentDate}>{userTimeLbl}</div>
          </div>
        </div>
      </div>
    );
  };

  const cleanSearch = () => {
    setSearchValue('');
    if (props.equipmentMapFilter && props.peopleMapFilter) {
      let users = handleOrderUsers();
      let equipment = handleOrderEquipmentList();
      setOrderedList(users);
      setUsersList(users);
      setEquipmentOrderedList(equipment);
      setEquipmentList(equipment);
    } else if (!props.equipmentMapFilter && props.peopleMapFilter) {
      let users = handleOrderUsers();
      setOrderedList(users);
      setUsersList(users);
    } else if (props.equipmentMapFilter && !props.peopleMapFilter) {
      let equipment = handleOrderEquipmentList();
      setEquipmentOrderedList(equipment);
      setEquipmentList(equipment);
    } else if (!props.equipmentMapFilter && !props.peopleMapFilter) {
      setOrderedList([]);
      setUsersList([]);
      setEquipmentOrderedList([]);
      setEquipmentList([]);
    }
  };

  const handleSearch = e => {
    if (e.target.value === '') {
      cleanSearch();
    }
    search = e.target.value;
    searchList(search);
  };

  const searchList = search => {
    const updatedList = usersList.filter(item => {
      if (item.hasOwnProperty('first_name')) {
        let fullName = item.first_name + " " + item.last_name;
        let labelFloor = search.substr(2, search.length);
        let ordinalFloor = getOrdinalLevel(labelFloor);
        if (ordinalFloor !== 20) {
          if (search === 'GPS' && item.device_id !== 'desktop')
            return (item);
        }
        if (item.device_id === 'desktop' && search === 'desktop')
          return (item);
        if (item.first_name.toLowerCase().search(search.toLowerCase()) !== -1
          || item.last_name.toLowerCase().search(search.toLowerCase()) !== -1
          || fullName.toLowerCase().search(search.toLowerCase()) !== -1
          || item.role_name.toLowerCase().search(search.toLowerCase()) !== -1)
          return (item);
      }
    });
    const equipmentUpdatedList = equipmentList.filter(item => {
      if (item.hasOwnProperty('control_number')) {
        let labelFloor = search.substr(2, search.length);
        let ordinalFloor = getOrdinalLevel(labelFloor);
        if (ordinalFloor !== 20) {
          if (item.hasOwnProperty('floor')) {
            if (search === 'GPS') {
              if (item.floor == 'outside' || item.floor == null || item.floor == -3)
                return (item);
            } else if (item.floor === ordinalFloor)
              return (item);
          } else if (search === 'GPS')
            return (item);
        }
        if (item.control_number.toLowerCase().search(search.toLowerCase()) !== -1
          || item.category_name.toLowerCase().search(search.toLowerCase()) !== -1)
          return (item);
      }
    });
    setEquipmentOrderedList(equipmentUpdatedList);
    setOrderedList(updatedList);
    setSearchValue(search);
  };

  const getOrdinalLevel = (label) => {
    const { mapConfigs } = props;

    let ordinalLevel = -20;
    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor => {
        if (floor.label === label)
          ordinalLevel = floor.ordinal;
      });
    }
    
    return ordinalLevel;
  };

  const renderListInfo = () => {
    return (
      <div className={classes.listContainer}>
        {props.equipmentMapFilter && renderEquipmentListInfo()}
        {props.peopleMapFilter && renderUserListInfo()}
      </div>
    );
  };

  const renderEquipmentListInfo = () => {
    let grouped = _.groupBy(equipmentOrderedList, 'device_category');
    let currentCategoryKeys = Object.keys(grouped);
    let currentCategories = [];
    currentCategoryKeys.forEach(key => {
      let found = props.equipmentCategories.find(cat => cat.id === key) || null;
      if (!!found) {
        currentCategories.push(found);
      }
    });
    const equipmentAndUsers = {
      padding: '0px',
      display: 'block',
      overflow: 'auto',
      maxHeight: '25vh'
    };
    const equipmentOnly = {
      padding: '0px',
      display: 'block',
      overflow: 'auto',
      maxHeight: '60vh'
    };
    const styleType = props.peopleMapFilter ? equipmentAndUsers : equipmentOnly;
    return (
      <div id="webNavv-activeMap-equipment-1.0">
        {currentCategories.map(item => {
          return (
            <Accordion id="webNavv-activeMap-container-equipment-1.0" style={{ margin: '0px' }} key={item.name}>
              <AccordionSummary
                style={{
                  backgroundColor: '#EBEBEB',
                  minHeight: '20px',
                  maxHeight: '25px',
                  textTransform: 'uppercase',
                  fontWeight: '300',
                  fontSize: '15px'
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                {item.name}
              </AccordionSummary>
              <AccordionDetails style={styleType}>
                {grouped[item.id].map((e, i) => {
                  let userFloorColor = '#757575';
                  let userFloorLbl = 'GPS';
                  let userTimeLbl = '';
                  let userContainerStyle = itemSelected === e.control_number ? classes.userContainerSelected : classes.userContainerClick;
                  [userFloorColor, userFloorLbl] = getUserFloorInfo(e.floor);
                  userTimeLbl = moment(e.date).format("MM/DD/YYYY HH:mm:ss");
                  return (
                    <div className={userContainerStyle} key={i}>
                      {equipmentContainerBody(e, itemSelected, userFloorLbl, userFloorColor, userTimeLbl)}
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const renderUserListInfo = () => {
    const equipmentAndUsers = {
      overflow: 'auto',
      maxHeight: '72vh'
    };
    const usersOnly = {
      border: 'none'
    };
    const styleType = props.equipmentMapFilter ? equipmentAndUsers : usersOnly;
    return (
      <div style={styleType}>
        {orderedList.map((item, i) => {
          if (item.hasOwnProperty('first_name') && item._id !== props.userId) {
            let userInList = usersStreaming.hasOwnProperty(item._id) ? true : false;
            let userStatus = getUserStatus(item.status);
            let userInitials = getUserInitials(item.first_name, item.last_name);
            let userFloorColor = item.device_id === 'desktop' ? '#bdbdbd' : '#757575';
            let userFloorLbl = 'GPS';
            let userTimeLbl = '';
            let userContainerStyle = classes.userContainer;
            if (userStatus !== 'Offline' && itemSelected !== item._id)
              userContainerStyle = classes.userContainerClick;
            if (userStatus !== 'Offline' && itemSelected === item._id)
              userContainerStyle = classes.userContainerSelected;
            if (userInList && item.device_id !== 'desktop') {
              [userFloorColor, userFloorLbl] = getUserFloorInfo(usersStreaming[item._id]);
            }
            if (userStatus === 'Offline') {
              userTimeLbl = !!item.lastLogout ? moment(item.lastLogout).format("MM/DD/YYYY HH:mm:ss") : "";
            } else {
              userTimeLbl = !!item.last_login ? moment(item.last_login).format("MM/DD/YYYY HH:mm:ss") : "";
            }
            if (item.device_id === 'desktop')
              return (
                <div
                  id="webNavv-activeMap-container-user-1.0"
                  className={classes.userContainer}
                  key={i}
                >
                  {userContainerBody(item, itemSelected, userInitials, userFloorColor, 'desktop', userTimeLbl, userStatus)}
                </div>
              );
            else
              return (
                <div
                  id="webNavv-activeMap-container-user-1.0"
                  className={userContainerStyle}
                  key={i}
                >
                  {userContainerBody(item, itemSelected, userInitials, userFloorColor, userFloorLbl, userTimeLbl, userStatus)}
                </div>
              );
          }
        })}
      </div>
    );
  };

  const renderSkeletons = () => {
    let list = [];
    for (let i = 0; i < 20; i++) {
      list.push(
        <div className={classes.userTopSectionSkeleton}>
          <div className={classes.initialsDiv}>
            <Skeleton className={classes.initialsSkeleton} variant="rect" />
            <Skeleton className={classes.chatIconContainerSkeleton} variant="rect" />
          </div>
          <div style={{ display: 'block' }}>
            <div style={{ display: 'flex' }}>
              <div className={classes.nameDiv}>
                <Skeleton className={classes.userName} variant="text" />
              </div>
              <div className={classes.levelDiv}>
                <Skeleton className={classes.levelSkeleton} variant="text" />
              </div>
            </div>
            <div className={classes.nameDiv}>
              <Skeleton className={classes.userRoleLargeSkeleton} variant="text" />
              <Skeleton className={classes.userRoleSkeleton} variant="text" />
            </div>
          </div>
        </div>)
    }
    return (
      <div className={classes.listContainer}>
        {list.map(item => {
          return (item)
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        id="webNavv-activeMap-container-1.0"
        className={classes.drawerDiv}
      >
        <div style={{ height: '65px' }}>
          <div
            id="webNavv-activeMap-container-search-1.0"
            className={classes.searchBox}
          >
            <Input className={classes.input} placeholder='Search' disableUnderline={true} onChange={handleSearch} value={searchValue}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              } />
            {searchValue !== '' &&
              <IconButton onClick={() => cleanSearch()} className={classes.cleanBtn}>
                <CloseIcon className={classes.cleanIcon} />
              </IconButton>
            }
          </div>
        </div>
        <Paper style={{ maxHeight: "91vh", overflow: 'auto', boxShadow: 'none' }}>
          {isListAvailable ? renderListInfo() : renderSkeletons()}
        </Paper>
      </div>
    </React.Fragment>
  );
});

const mapStateToProps = state => ({
  users: state.user.users,
  socketChat: state.auth.socketChat,
  userId: state.auth.userId,
  equipmentLocation: state.equipments.equipmentLocation,
  equipmentList: state.equipments.equipmentList,
  usersChat: state.user.usersObj,
  userChatUpdated: state.views.userChatUpdated,
  peopleMapFilter: state.views.peopleMapFilter,
  equipmentMapFilter: state.views.equipmentMapFilter,
  acl: state.auth.acl,
  aclViews: state.auth.acl.views,
  transporterFound: state.views.transporterFound,
  currentView: state.views.currentView,
  campusId: state.auth.userInfo.campusId,
  equipmentCategories: state.equipments.equipmentCategories,
  campusInfo: state.auth.userInfo.campusInfo,
  viewProps: state.views.viewProps,
  mapConfigs: state.views.mapConfigs,
  lastlocationSocket: state.lastlocation.lastlocationSocket
});

export default connect(mapStateToProps,
  {
    listUsers,
    categoriesByCampus,
    getEquipmentLocationKIO,
    getEquipment,
    changeZoomToUser,
    userChatSelected,
    changeZoomToEquipment,
    changeView
  })(UsersList);
