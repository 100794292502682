import {LIST_CAMPUSES, UPDATE_CAMPUS, CREATE_CAMPUS, UPLOAD_LEVEL, UPLOAD_OPENING, UPLOAD_UNIT, UPLOAD_VENUE, GET_CAMPUS} from '../actions/campus/types';

const initialState = {
  campusList: [],
  success: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CAMPUS:
      const idIsEqual = (campus) => campus.id === action.campusId;
      let index = state.campusList.findIndex(idIsEqual);
      let campusCopy = [...state.campusList];
      campusCopy[index] = action.payload;
      return {
        ...state,
        campusList: campusCopy
      };
    case LIST_CAMPUSES:
      return {
        ...state,
        campusList: action.payload
      };
    case UPDATE_CAMPUS:
      return {
        ...state,
        sucess: action.payload
      };
    case CREATE_CAMPUS:
      return {
        ...state,
        sucess: action.payload
      };
    case UPLOAD_VENUE:
      return {
        ...state,
        sucess: action.payload
      };
    case UPLOAD_UNIT:
      return {
        ...state,
        sucess: action.payload
      };
    case UPLOAD_OPENING:
      return {
        ...state,
        sucess: action.payload
      };
    case UPLOAD_LEVEL:
      return {
        ...state,
        sucess: action.payload
      };
    default:
      return state;
  }
};