import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "../fleet-manager/phones.component.css";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dayJobs } from "../../actions/jobs/jobsActions";
import { getSpaghetti } from "../../actions/directions/directionsActions";
import { changeView, changeActiveFloor } from "../../actions/views/viewsActions";
import AwesomeSpinner from '../utilities/spinner';
import {UPDATE_FILTERS} from "../utilities/common_tableFilters";
import RestoreIcon from '@material-ui/icons/Restore';
import {
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as CONSTS from './history.constants';
import CustomToolbar from  '../utilities/refreshBtn.component';
import CustomToolbarSelect from './toolbarSelect.component';
import MasterTable from '../utilities/table.component';
import MasterAlert from '../utilities/alert.component';
import MasterButton from '../utilities/button.component';
import { styles } from './history.styles';
import moment from "moment";
class HistoryTable extends React.Component {
  //TODO: verify orderBy
  state = {
    users: [],
    rangeFilterChecked:false,
    resetFilters:false,
    hiddenRows:{
      jobID:false,
      distance:true,
      worker:true,
      duration:true,
      status:true,
      deviceID:true,
      date:true,
      jobStart:true,
      jobEnd:true,
    },
    tableRowsPerPage: 10,
    openSnack: false,
    snackMsg: '',
    showSpinner: true,
    showTable: false,
    tableLoaded: false,
    isSearchClosed: false
  };
  refreshHT=true;
  workersList=[];
  devicesList=[];
  startDateError=false;
  endDateError=false;
  startDate="";
  endDate="";
  tableJobData=[];
  distanceSelected=0;
  onSearch=false;
  searchBy='';
  onFilter=false;
  filterBy=[];

  formatFilterLabelDates = (dateToFormat, label) => {
    if(this.state.rangeFilterChecked && label===CONSTS.COLUMNS.jobStart.label){
      label = CONSTS.FROM
    }
    if(this.state.rangeFilterChecked && label===CONSTS.COLUMNS.jobEnd.label){
      label = CONSTS.TO
    }

    if (dateToFormat !== undefined) {
      let search ="";
      let startDateIso = moment(dateToFormat).toISOString();
      if(label !== CONSTS.COLUMNS.date.id){
        search = moment(startDateIso).format(CONSTS.DATE_FORMAT);
      }else{
        search = moment(dateToFormat).format(CONSTS.DATE);
      }
      return `${label}: ${search}`;
    } else return false;
  };

  componentDidMount(){
    this.props.dayJobs().then(resp=>{
      this.setState({showSpinner: false, showTable: true});
    });
  }

  componentDidUpdate(){
    if(this.state.isSearchClosed){
      this.setState({isSearchClosed: false});
    }
  }

  handleChangeSwitch=()=>{
    this.filterBy=[];
    this.onFilter=false;
    this.onSearch=false;
    this.searchBy='';
    this.props.dayJobs();
  };

  handleCloseSnackbar = () => {
    this.setState({openSnack: false, snackMsg: ''});
  };

  handleOpenSnackbar = (msg) => {
      this.setState({openSnack: true, snackMsg: msg});
  };

  getTracesPerJob=(traceSegment)=>{
    let grouped = Object.create(null);
    traceSegment.forEach(function (a) {
        grouped[a.jobId] = grouped[a.jobId] || [];
        grouped[a.jobId].push(a);
    });
    return Object.values(grouped);
  }

   getTHPercentage= (historyRoute, jobInfo)=>{
    let jobTypeTraces= [];
    let tracesPerJob = this.getTracesPerJob(historyRoute);

    tracesPerJob.forEach(job=>{
      let healingTraces = 0;
      let geolocationTraces = 0;
      job.forEach(traces=>{
        if(traces.madeForTraceHealing){
          healingTraces++;
        }else{
          geolocationTraces++;
        }
      });
       jobTypeTraces.push({job:job[0].jobId, healingTraces: healingTraces, geolocationTraces: geolocationTraces});
    });
    let jobInfoUpdate = [];

    tracesPerJob.forEach((jobTraces,i) =>{
        let tracesInJob = jobTraces.length;
        let jobData = jobInfo.find(jobData=>jobData.job === jobTraces[0].jobId);
        let jobTrace = jobTypeTraces.find(jobData=>jobData.job === jobTraces[0].jobId);
        let percentage = 0;
        let total = jobData.totalLogs - jobTrace.geolocationTraces;
        let thCompleted = true;
        if(jobData.jobHasTraceHealing){
          percentage = (jobTrace.healingTraces * 100) / total;
          let currentTotal = jobTrace.geolocationTraces + jobTrace.healingTraces;
          if(currentTotal === total){
            thCompleted = true;
          }else{
            thCompleted = false;
          }
        }
        let jobDevice = jobData.device;
        if(jobData.device === '-'){
          jobDevice = 'n/a';
        }

        jobInfoUpdate.push({
          job:jobData.job,
          distance: jobData.distance,
          status: jobData.status,
          device: jobDevice,
          startDate: jobData.startDate,
          endDate: jobData.endDate,
          jobLabel: `Job ${i+1}`,
          totalLogs: jobData.totalLogs,
          tracesInJob: tracesInJob,
          geolocationTraces: jobTrace.geolocationTraces,
          totalHealingTraces: total,
          healingTraces: jobTrace.healingTraces,
          percentage: percentage,
          traceHealingJob: jobData.jobHasTraceHealing,
          traceHCompleted: thCompleted
        });
    });
    return jobInfoUpdate
  }

  spaghetti = job_id => {
    this.setState({showSpinner: true});
    this.props.changeActiveFloor(false);
    this.props.getSpaghetti(job_id).then(res => {
      this.setState({showSpinner: false});
      let jobInfoUpdated = this.getTHPercentage(this.props.spaghetti,job_id.jobInfo);
      this.props.changeView(CONSTS.MAP, CONSTS.HISTORY, {traces:this.props.spaghetti,jobInfo:jobInfoUpdated });
    }).catch(error=>{
      let msg = this.props.dialogError;
      this.handleOpenSnackbar(msg);
      this.setState({showSpinner: false});
    })
  };

  evaluateJobs(jobs, users) {
    let jobsAux = jobs;
    for (let index = 0; index < jobsAux.length; index++) {
      let job = jobsAux[index];
      let transporter = users.filter(user => {
        if (job._id.transporter === user._id) {
          return true;
        } else {
          return false;
        }
      });
      if (transporter.length > 0) {
        job._id.transporter_name = job._id.first_name + " " + job._id.last_name;
        job._id.transporter_name = job._id.transporter_name || job._id.device_id;
      }
      const finish = moment(job._id.finish_date).format();
      const start = moment(job._id.start_date).format();
      job._id.duration = moment.duration(moment(finish).diff(start));
    }
    return jobsAux;
  }

  dateCustomRenderError = (type, event)=>{
    if(type===CONSTS.FROM){
      if(event.target.value>this.endDate && this.endDate!==""){
        this.startDateError= true;
      }else{
        this.startDateError= false;
        if(event.target.value<this.endDate && this.endDateError){
          this.endDateError=false;
        }
      }
      this.startDate= event.target.value;
      if(event.target.value===''){
        this.startDateError=false;
      }
    }else{
      if(event.target.value<this.startDate){
        this.endDateError= true;
      }else{
        this.endDateError=false;
        if(this.startDate<event.target.value && this.startDateError){
          this.startDateError= false;
        }
      }
      this.endDate=event.target.value;
      if(event.target.value===''){
        this.startEndError=false;
      }
    }
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    const {classes} =this.props;
    if(this.state.rangeFilterChecked && label===CONSTS.COLUMNS.jobStart.label){
      label = CONSTS.FROM;
    }
    if(this.state.rangeFilterChecked && label===CONSTS.COLUMNS.jobEnd.label){
      label = CONSTS.TO;
    }
    return (
      <div>
        {(label===CONSTS.COLUMNS.jobStart.label || label===CONSTS.FROM) ?
        <div>
            <TextField
            id={key}
            label={label}
            type={CONSTS.TEXTFIELD_DATETIME}
            format={CONSTS.TEXTFIELD_FORMAT}
            InputLabelProps={{
              shrink: true
            }}
            value={filterList[index][0] || ""}
            onChange={event => {
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
              this.dateCustomRenderError(label, event)
            }}
            className={classes.textInput}
            helperText= {this.startDateError ? CONSTS.INCORRECT_ENTRY :''}
            error={this.startDateError? true : false}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={this.state.rangeFilterChecked}
                onChange={event => this.setState({ rangeFilterChecked: event.target.checked })}
              />
            }
            label={CONSTS.DATE_RANGE}
            className={classes.formControl}
          />
        </div>
        :
          <TextField
          id={key}
          label={label}
          type={CONSTS.TEXTFIELD_DATETIME}
          format={CONSTS.TEXTFIELD_FORMAT}
          InputLabelProps={{
            shrink: true
          }}
          value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
            this.dateCustomRenderError(label, event)
          }}
          className={classes.textInput}
          helperText= {this.endDateError? CONSTS.INCORRECT_ENTRY :''}
          error={this.endDateError? true : false}
        />
        }
      </div>
    );
  };

  dateCustomRender = (filterList, onChange, index, column, label, key)=>{
    const {classes}=this.props;
    return(
      <TextField
        id={key}
        label={label}
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={filterList[index][0] || ""}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
        className={classes.textInput}
      />
    );
  }

  stringFieldsCustomRender = (filterList, onChange, index, column,key)=>{
   let inputLabel = "";
   let optionList = [];
   if(key===CONSTS.STRING_FILED_TYPES.worker.id){
    inputLabel = CONSTS.STRING_FILED_TYPES.worker.label;
    optionList = this.workersList.sort();
   }else{
    inputLabel = CONSTS.STRING_FILED_TYPES.device.label;
    optionList = this.devicesList.sort();
   }
   const onTagsChange = (event, values) => {
      filterList[index][0] = values;
      onChange(filterList[index], index, column);
   }
    return(
      <Autocomplete
        id={key}
        options={optionList}
        getOptionLabel={(option) => option}
        style={{ width: 320 }}
        onChange={onTagsChange}
        renderInput={(params) => <TextField {...params} label={inputLabel} InputLabelProps={{shrink: true}} />}
      />
    );
  }

  handleTableFilters = (column)=>{
    let filter = this.filterBy.length ? this.filterBy[column] : null
    return filter;
  };

  handleFillTable = () =>{
    const { jobs, users } = this.props;
    const auxJobs = this.evaluateJobs(jobs, users);

    auxJobs.sort((a,b)=>{
      return new Date(b._id.start_date) - new Date(a._id.start_date);
    });

    let data = auxJobs
    .filter(job => (job._id.transporter || this.props.acl.contact.includes(job._id.role)))
    .map(job => {

      let startDate = moment(job._id.start_date).format(CONSTS.DATE_FORMAT);
      let endDate= moment(job._id.finish_date).format(CONSTS.DATE_FORMAT);
      let date = moment(job._id.creation_date).format(CONSTS.DATE);

      let distance = 0;
      if (job._id.hasOwnProperty(CONSTS.HISTORY_ATTRIBUTES.distance)){
        distance = job._id.distance > 0 ? job._id.distance.toFixed(3) : 0;
      }
      if(!this.workersList.includes(job._id.transporter_name) && job._id.transporter_name!==undefined){
        this.workersList.push(job._id.transporter_name);
      }
      if(!this.devicesList.includes(job._id.device) && job._id.device!==undefined){
        this.devicesList.push(job._id.device);
      }
      let logs = 0; //-1
      if (job._id.hasOwnProperty(CONSTS.HISTORY_ATTRIBUTES.total_logs)){
        logs = job._id.total_logs;
      }
      let jobHasTraceHealing = false;
      if (job._id.hasOwnProperty(CONSTS.HISTORY_ATTRIBUTES.jobHasTraceHealing)){
        jobHasTraceHealing = job._id.jobHasTraceHealing;
      }

      this.tableJobData.push({jobId: job._id.id, totalLogs:logs, jobHasTraceHealing: jobHasTraceHealing});

      let jobDevice = '-';
      let jobStatus = 'Closed'
      if(job._id.hasOwnProperty(CONSTS.HISTORY_ATTRIBUTES.device)){
        jobDevice= job._id.device;
      }
      if(job._id.status === CONSTS.IDLE){
        jobStatus= CONSTS.IN_PROGRESS;
      }
      return {
        show_traces: job._id,
        jobID: job._id.id,
        distance: distance,
        worker: job._id.transporter_name,
        duration: job._id.duration,
        status: jobStatus,
        deviceID: jobDevice,
        date: [date,job._id.creation_date],
        jobStart: [startDate,job._id.start_date],
        jobEnd: [endDate,job._id.finish_date],
        jobHasLocation: job._id.jobHasLocation
      };
    });
    return data;
  };

  handleApplyFilters = () =>{
    let applyFilters = false;
    if (this.filterBy.length !== 0){
      this.filterBy.forEach(filter=>{
        if(filter.length){
          applyFilters = true
        }
      });
      if(!applyFilters){
        this.handleChangeSwitch();
      }
    }
  };

  handleReturnTableColumns = () =>{
    const { hiddenRows, rangeFilterChecked } = this.state;
    const { classes } = this.props;
    const columns = [
      {
        name: CONSTS.COLUMNS.jobID.id,
        label: CONSTS.COLUMNS.jobID.label,
        options: {
          sort:false,
          display: hiddenRows.jobID,
          filterList: this.handleTableFilters(0),
          filterType:CONSTS.FILTER_TYPE.textField,
          customFilterListOptions: {
            render: job => `${CONSTS.COLUMNS.jobID.label}: ${job}`,
            update: (filterList, filterPos, index) =>
            UPDATE_FILTERS(filterList, filterPos, index)
          },
          customHeadRender: (value, tableMeta)=>{
            return (<th className="MuiTableCell-root MuiTableCell-head" style={{width:'auto',top:"0px", left:"0px", backgroundColor:"white", position:"sticky", zIndex:"100"}}>
              <p className={classes.jobLbl}>{CONSTS.COLUMNS.jobID.label}</p>
            </th>)
          },
          customBodyRender: (jobId) => <div style={{textAlign:'left', paddingLeft:'5px'}}>{jobId}</div>
        }
      },
      {
        name: CONSTS.COLUMNS.distance.id,
        label: CONSTS.COLUMNS.distance.label,
        options: {
          sortThirdClickReset: true,
          filterType:CONSTS.FILTER_TYPE.textField,
          filterList: this.handleTableFilters(1),
          customFilterListOptions: {
            render: distance => `${CONSTS.COLUMNS.distance.label}: ${distance}`,
            update: (filterList, filterPos, index) =>
            UPDATE_FILTERS(filterList, filterPos, index)
          },
          display:hiddenRows.distance,
          customBodyRender: (distance) => <p>{distance>=0? distance+' miles': ''}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.worker.id,
        label: CONSTS.COLUMNS.worker.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.worker,
          customBodyRender: (worker) => <p style={{textAlign: 'left', paddingLeft:'5px'}}>{worker}</p>,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(2),
          customFilterListOptions: {
            render: worker => `${CONSTS.COLUMNS.worker.label}: ${worker}`,
            update: (filterList, filterPos, index) =>
            UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(worker, filters) {
              return CONSTS.STRING_FILTER_LOGIC(worker, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FILED_TYPES.worker.id)
          }
        }
      }, {
        name: CONSTS.COLUMNS.duration.id,
        label: CONSTS.COLUMNS.duration.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.duration,
          customBodyRender: (duration) => <p>{duration.humanize()}</p>,
          filter: false,
        }
      }, {
        name: CONSTS.COLUMNS.status.id,
        label: CONSTS.COLUMNS.status.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.status,
        }
      }, {
        name: CONSTS.COLUMNS.deviceID.id,
        label: CONSTS.COLUMNS.deviceID.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.deviceID,
          customBodyRender: (deviceID) => <p>{deviceID}</p>,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(4),
          customFilterListOptions: {
            render: device => `${CONSTS.COLUMNS.deviceID.label}: ${device}`,
            update: (filterList, filterPos, index) =>
            UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(device, filters) {
              return CONSTS.STRING_FILTER_LOGIC(device, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_FILED_TYPES.device.id)
          }
        }
      }, {
        name: CONSTS.COLUMNS.date.id,
        label: CONSTS.COLUMNS.date.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.date,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(5),
          customBodyRender: (creationDate) => <p> {creationDate[0]} </p>,
          customFilterListOptions: {
            render: creationDate => this.formatFilterLabelDates(creationDate[0], CONSTS.COLUMNS.date.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return CONSTS.DATE_FILTER_LOGIC(date, filters[0]);
            },
            display: (filterList, onChange, index, column) =>
              this.dateCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.date.label, CONSTS.COLUMNS.date.id)
          }
        }
      }, {
        name: CONSTS.COLUMNS.jobStart.id,
        label: CONSTS.COLUMNS.jobStart.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.jobStart,
          filterType:CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(6),
          customBodyRender: (value) => <p>{value[0]}</p>,
          customFilterListOptions: {
            render: creationDate => this.formatFilterLabelDates(creationDate[0], CONSTS.COLUMNS.jobStart.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return CONSTS.START_DATE_FILTER_LOGIC(date, filters[0], rangeFilterChecked);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.jobStart.label, CONSTS.COLUMNS.jobStart.id)
          }
        }
      }, {
        name: CONSTS.COLUMNS.jobEnd.id,
        label: CONSTS.COLUMNS.jobEnd.label,
        options: {
          sortThirdClickReset: true,
          display:hiddenRows.jobEnd,
          filterType: CONSTS.FILTER_TYPE.custom,
          filterList: this.handleTableFilters(7),
          customBodyRender: (value) => <p>{value[0]}</p>,
          customFilterListOptions: {
            render: creationDate => this.formatFilterLabelDates(creationDate[0], CONSTS.COLUMNS.jobEnd.label),
            update: (filterList, filterPos, index) =>
              UPDATE_FILTERS(filterList, filterPos, index)
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              return CONSTS.END_DATE_FILTER_LOGIC(date, filters[0], rangeFilterChecked);
            },
            display: (filterList, onChange, index, column) =>
              this.datesCustomRender(filterList, onChange, index, column, CONSTS.COLUMNS.jobEnd.label, CONSTS.COLUMNS.jobEnd.id)
          }
        }
      }
    ];
    return columns;
  };

  handleReturnTableOptions = (data) =>{
    const tableOptions = {
      searchText: this.searchBy,
      print: false,
      filter: true,
      filterType: CONSTS.TABLE_OPTIONS.filterType,
      responsive: CONSTS.TABLE_OPTIONS.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.ROWS_PER_PAGE,
      selectableRows:CONSTS.TABLE_OPTIONS.selectableRows,
      selectableRowsHeader: false,
      selectableRowsOnClick: true,
      downloadOptions: {
        filename: CONSTS.TABLE_OPTIONS.filename,
        separator: CONSTS.TABLE_OPTIONS.separator,
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true
        }
      },
      isRowSelectable: (dataIndex,selectedRows)=>{
        if (!data[dataIndex].jobHasLocation) return false;
        return data[dataIndex][1] !== "Attorney";
      },
      customSort: (data, colIndex, order) => {
        let sortType = order=== CONSTS.TABLE_OPTIONS.sort? true:false;
        return  CONSTS.TABLE_SORT_HT(data, colIndex,sortType);
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return CONSTS.CUSTOM_CSVDOWNLOAD(buildHead, buildBody, columns, data);
      },
      customToolbar: () => {
        return (
          <CustomToolbar
          handleChangeSwitch={this.handleChangeSwitch}
          table={CONSTS.TABLE_ID}
          data={data}
          filterChips={this.filterBy}/>
        );
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
        selectedRows={selectedRows}
        data={data}
        setSelectedRows={setSelectedRows}
        getTraces={this.spaghetti}
        distanceSelected={this.distanceSelected}
        jobData={this.tableJobData}
        leftNavOpen={this.props.open}/>
      ),
      onFilterChange: (filterList) => {
          if(filterList===null){
          this.startDateError=false;
          this.endDateError=false;
          this.setState({resetFilters: true});
        }
      },
      onTableChange:(action,tableState)=>{
        switch(action){
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({tableRowsPerPage: tableState.rowsPerPage});
            break
          case CONSTS.TABLE_EVENTS.propsUpdate:
            if(this.filterBy.length!==0 && this.onFilter){
              tableState.filterList= this.filterBy;
            }
            break
          case CONSTS.TABLE_EVENTS.rowsSelect:
            this.refreshHT = false;
            break
          case CONSTS.TABLE_EVENTS.search:
            if(tableState.searchText){
              this.searchBy = tableState.searchText;
              this.onSearch = true;
            }
            break
          case CONSTS.TABLE_EVENTS.filterChange:
            this.filterBy = tableState.filterList;
            this.onFilter = true;
            break
          case CONSTS.TABLE_EVENTS.onSearchClose:
            this.searchBy = '';
            this.onSearch = false;
            this.setState({isSearchClosed: true});
            break
          case CONSTS.TABLE_EVENTS.resetFilters:
            this.handleChangeSwitch();
            break
          case CONSTS.TABLE_EVENTS.viewColumnsChange:
            tableState.columns.forEach(col=>{
              if(this.state.hiddenRows.hasOwnProperty(col.name)){
                if(this.state.hiddenRows[col.name].toString()!== col.display){
                  let update = col.display==='true'? true:false;
                  this.setState(prevState =>({
                  hiddenRows:{
                    ...prevState.hiddenRows,
                    [col.name]: update}
                  }));
                }
              }
             });
            break
          default:
            break
        }
      },
    };
    return tableOptions;
  };

  tableTitle=()=>{
    const { classes } = this.props;
    return(
      <div className={classes.tableTitleContainer}>
          <div className={classes.tableTitleIconDiv}>
            <RestoreIcon className={classes.tableTitleIcon}/>
          </div>
          <h2 className={classes.tableTitleLbl}>{CONSTS.TABLE_TITLE}</h2>
      </div>)
  };

  render() {
    let data = this.handleFillTable();
    this.handleApplyFilters();
    const columns = this.handleReturnTableColumns();
    const tableOptions = this.handleReturnTableOptions(data);

    let l= this.props.open? '23%': '4%';
    let w= this.props.open? '75.5%': '95%';
    return (
        <div style={{marginTop:'5%',marginLeft:l, width:w}}>
          {this.state.showTable && (
           <div id="webNavv-tableView-HistoryTable-1.0">
              <MasterTable
                title={this.tableTitle()}
                data={data}
                columns={columns}
                options={tableOptions}
              />
          </div>
          )}
          {this.state.showSpinner &&(
            <div id="webNavv-tableView-HistoryTable-spinner-1.0">
             <AwesomeSpinner config={CONSTS.SPINNER_CONFIG} loading={this.state.showSpinner} navOpen={this.props.open}/>
            </div>
          )}
          <div id="webNavv-tableView-HistoryTable-alert-1.0">
            <MasterAlert
              snackStyle={{marginTop:'5vh'}}
              anchor={{ vertical: 'top', horizontal: 'right' }}
              openSnack={this.state.openSnack}
              handleClose={this.handleCloseSnackbar}
              alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
              elevation={6}
              variant={CONSTS.ALERT_VARIANT}
              type={CONSTS.ALERT_TYPE}
              message={this.state.snackMsg}
            />
          </div>
        </div>
    );
  }
}

HistoryTable.propTypes = {
  users: PropTypes.array.isRequired,
  jobs: PropTypes.array,
  spaghetti: PropTypes.array,
  requested: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  getSpaghetti: PropTypes.func.isRequired,
  dayJobs: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  users: state.user.users,
  jobs: state.jobs.jobs,
  requested: state.jobs.requested,
  spaghetti: state.directions.spaghetti,
  dialogError: state.directions.dialogError,
  currentView: state.views.currentView,
  acl: state.auth.acl,
  open:state.views.drawerOpen,
});

export default connect(mapStateToProps, {
  dayJobs,
  getSpaghetti,
  changeView,
  changeActiveFloor
})(withStyles(styles)(HistoryTable));