import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import TimeLineIcon from "@material-ui/icons/Timeline";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import MasterAlert from '../utilities/alert.component';

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    width:'100vh',
    display:'flex !important'
  },
  iconContainerFull: {
    width:'120vh',
    display:'flex !important'
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
  progress:{
    marginRight:'10px',
    marginLeft:'10px',
    root: {
      width: '100%',
    },
  },
  msgContainer:{
    width:'100%',
    textAlign:'center',
    "& .MuiAlert-icon": {
      marginLeft:'30%'
    }
  },
  msgContainerSmall:{
    width:'100%',
    textAlign:'center',
    "& .MuiAlert-icon": {
      marginLeft:'40%'
    }
  },
  snackBar:{
    marginTop:'4vh',
    width: '100%',
  },
  defaultProgress:{
    "& .MuiLinearProgress-bar":{
      backgroundColor:'#1a90ff',
    }
  },
  attentionProgress:{
    "& .MuiLinearProgress-bar":{
      backgroundColor:'#ffa000',
    },
  },
  errorProgress:{
    "& .MuiLinearProgress-bar":{
      backgroundColor:'#e53935',
    }
  }
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5
  },
}))(LinearProgress);

// function LinearProgressWithLabel(props) {
//   let milesLbl= props.distance!==0 ? props.distance: 0;
//   let colorLbl='#e53935';
//   if(milesLbl<= 25)colorLbl='#757575';
//   else if(milesLbl>25 && milesLbl<=50)colorLbl='#ffa000';

//   return (
//     <Box display="flex" alignItems="center">
//       <Box width="200px" mr={1}>
//         {colorLbl ==='#757575' && <BorderLinearProgress className={props.defaultClass} variant="determinate" {...props} />}
//         {colorLbl ==='#ffa000' && <BorderLinearProgress className={props.attentionClass} variant="determinate" {...props} />}
//         {colorLbl ==='#e53935' && <BorderLinearProgress className={props.errorClass} variant="determinate" {...props} />}
//       </Box>
//       <Box minWidth={40}>
//         <div style={{display:'flex'}}><p style={{color:colorLbl, marginRight:'5px', fontWeight:'bold'}}>{milesLbl.toFixed(3)}</p><p>miles</p></div>
//       </Box>
//     </Box>
//   );
// }
class CustomToolbarSelect extends React.Component {

  state={
    openSnack:false,
    showMsg: false
  }
  jobList = [];
  userList= [];
  jobInfo = [];
  startDateList = [];
  totalDistance = 0;
  distancePercentage= 0;

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleShowTraces = () => {
    let jobsOrdered = this.props.selectedRows.data.sort((a,b)=>{
      return b.index - a.index
    });
    jobsOrdered.map(row=>{
      let user = this.props.data[row.dataIndex].show_traces.transporter;
      let job = this.props.data[row.dataIndex].show_traces.id;
      let jobStartDate = this.props.data[row.dataIndex].jobStart[1];
      let jobEndDate = this.props.data[row.dataIndex].jobEnd[1];
      let jobLog = this.props.jobData.find(jobData=> jobData.jobId ===job);

      this.userList.push(user);
      this.jobList.push(job);
      this.startDateList.push(jobStartDate);
      this.jobInfo.push({
        job:job,
        status: this.props.data[row.dataIndex].status,
        distance: this.props.data[row.dataIndex].distance,
        device: this.props.data[row.dataIndex].deviceID,
        startDate: jobStartDate,
        endDate:jobEndDate,
        totalLogs: jobLog.totalLogs,
        jobHasTraceHealing: jobLog.jobHasTraceHealing});
        return null;
    });
    this.props.getTraces({userId: this.userList, jobId: this.jobList, jobInfo: this.jobInfo, startDate: this.startDateList});
  };

  getDistanceHeader=()=>{
    this.distancePercentage=0;
    this.totalDistance = 0;
    this.props.selectedRows.data.map(row=>{
      this.totalDistance = parseFloat(this.totalDistance) + parseFloat(this.props.data[row.dataIndex].distance);
      return null;
    });
    this.distancePercentage = (this.totalDistance *100)/ 50;
  }

  handleOpenSnackbar=()=>{
    this.setState({openSnack:true, showMsg: true})
  }

  handleCloseSnackbar=()=>{
    this.setState({openSnack:false})
  }

  render() {
    const { classes, leftNavOpen} = this.props;
    const {showMsg, openSnack} = this.state;
    this.getDistanceHeader();
    const toolbarHeaderStyle = leftNavOpen? classes.iconContainer: classes.iconContainerFull;
    const alertBarStyle = leftNavOpen? classes.msgContainerSmall: classes.msgContainer;
    const iconButtonStyle = leftNavOpen? '25vw' : '50vw';
    if(this.totalDistance>50 && !showMsg) this.handleOpenSnackbar();

    return (
      <div className={toolbarHeaderStyle}>
        <div style={{display:'flex'}}>
          {/* <p style={{color:'#2196F3'}}>Multiple jobs distance threshold:</p>
          <div className={classes.progress}>
            <LinearProgressWithLabel value={this.distancePercentage} distance={this.totalDistance}
              defaultclass={classes.defaultProgress}
              attentionclass={classes.attentionProgress}
              errorclass={classes.errorProgress}/>
          </div> */}
        </div>
        <div style={{marginLeft:iconButtonStyle}}>
        <Tooltip title={"Deselect ALL"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBoxIcon style={{fill:'#2196F3'}} className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Show Traces"}>
          <IconButton className={classes.iconButton} onClick={this.handleShowTraces}>
            <TimeLineIcon style={{fill:'#2196F3'}} className={classes.icon} />
          </IconButton>
        </Tooltip>
        </div>
        <MasterAlert
          snackClass={classes.snackBar}
          anchor={{ vertical: 'top', horizontal: 'center' }}
          open={openSnack}
          handleClose={this.handleCloseSnackbar}
          alertClass={alertBarStyle}
          elevation={6}
          variant={"filled"}
          type={"error"}
          message={"Server performance may be affected with more than 50 miles of total distance between jobs"}
        />
      </div>
    );
  }
}

export default (withStyles(defaultToolbarSelectStyles)(CustomToolbarSelect));