import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import { Icon } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";
import _, { curryRight } from "lodash";
import moment from "moment";

import { getLogsByTimeWindow, getLogs } from '../../../actions/logs/logsActions';
import { getCampuses } from '../../../actions/campus/campusActions';
import AwesomeSpinner from '../../utilities/spinner';
import MasterButton from '../../utilities/button.component';
import { exportCSVFile } from "../../utilities/exportCSV";
import { styles } from './styles';
import * as CONSTS from './logsTable.constants';
import logIcon from './img/logIcon.png';

class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      action: '',
      description: '',
      tableRowsPerPage: 7,
      timestamp: '',
      id: false,
      showSpinner: true,
      showTable: false,
      startDate: new Date(),
      endDate: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      openTimeWindowMenu: false,
      isSearch: false,
      logsList: [],
      actionList: [],
      actionFilter: '',
      campus: '',
      campusId: '',
      difference: 0,
      isRefreshStoped: false,
      inputUpdate: false,
      isDataFiltered: false,
      startDateError: false,
      endDateError: false,
      selectedCampus: null,
      error: ''
    };
  }
  logInterval = null;
  tableData = [];
  currentStartDate = null;
  currentEndDate = null;
  currentCampusId = '';

  componentDidMount = () => {
    const { endDate } = this.state;
    const { campusInfo } = this.props;
    const { DEFAULT_DIFF_DATE } = CONSTS;

    let start = new Date();
    start.setDate(start.getDate() - DEFAULT_DIFF_DATE);

    this.updateLogsData(start, endDate, campusInfo);
  };

  componentWillUnmount() {
    clearInterval(this.logInterval);
  }
  distinctBy = (prop, arr) => [...new Set(arr.map(o => o[prop]))];

  updateLogsData = (start, end, campus) => {
    let logsFn = !!campus.id ? this.props.getLogsByTimeWindow : this.props.getLogs;
    const { EMPTY_VALUE, DATE_FORMAT } = CONSTS;
    logsFn(start, end, campus.id).then(resp => {
      const logs = [...resp];
      const actionList = this.distinctBy('action', logs).map(log => {
        const item = { name: log };
        return item;
      });
      actionList.push(EMPTY_VALUE);
      actionList.sort();

      this.props.getCampuses();

      let newState = {
        showTable: true,
        showSpinner: false,
        actionList: [...actionList],
        difference: moment.duration(moment(end).diff(start)),
        minDate: moment(start).subtract(1, 'years').format(DATE_FORMAT),
        selectedCampus: campus,
        logsList: [...logs],
        inputUpdate: false,
        startDate: start,
        endDate: end
      };

      this.setState(newState);
    }).catch(error => {
      console.error("[LOGSTABLE] An error ocurred in logsFn: ", error);
      this.setState({ error: "Error loading data" });
    });
  };

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    let { classes } = this.props;
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          className={classes.dateInput}
        />
      </div>
    );
  };

  handleCancelTimeWindow = () => {
    const { campusInfo } = this.props;
    const { DEFAULT_DIFF_DATE } = CONSTS;
    let start = new Date();
    let end = new Date();

    start.setDate(start.getDate() - DEFAULT_DIFF_DATE);

    this.currentStartDate = start;
    this.currentEndDate = end;
    this.currentCampusId = campusInfo.id;

    this.setState({
      openTimeWindowMenu: false,
      startDate: start,
      endDate: end,
      difference: moment.duration(moment(start).diff(end)),
      startDateError: false,
      endDateError: false,
      inputUpdate: false
    });
  }

  handleNewTimeWindow = () => {
    if (this.state.inputUpdate) {
      const { startDate, endDate, selectedCampus } = this.state;
      this.setState({ showSpinner: true, openTimeWindowMenu: false });
      this.updateLogsData(startDate, endDate, selectedCampus);
    }
  }

  handleMenu = () => {
    this.setState({ openTimeWindowMenu: !this.state.openTimeWindowMenu });
  };

  handleCleanSearch = () => {
    const restoredList = [...this.logsList];
    this.logsList = [];
    this.setState({
      isSearch: false,
      search: '',
      logsList: [...restoredList]
    });
  }

  handleSearch = (search) => {
    if (search !== '') {
      this.handleFilterSearch(search);
    } else {
      this.handleCleanSearch();
    };
  }

  handleFilterSearch = (search) => {
    if (!this.state.isSearch) this.logsList = [...this.state.logsList];

    let filtered = this.logsList.filter(item => {
      if ((item.action && item.action.toLowerCase().includes(search.toLowerCase()))
        || (item.description && item.description.toLowerCase().includes(search.toLowerCase()))
        || (item._id && item._id.toLowerCase().includes(search.toLowerCase())))
        return item;
    });

    this.setState({
      logsList: filtered,
      isSearch: true,
      search: search
    });
  };

  generateCSV = () => {
    const { logsList } = this.state;
    let itemsCopy = [];
    logsList.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0));
    logsList.map(item => {
      itemsCopy.push({
        id: item._id,
        action: item.action,
        description: item.description,
        performedBy: item.userId ? item.userId : 'Id not available',
        timestamp: item.timestamp
      });
    });
    let headers = Object.keys(itemsCopy[0]);
    let start = moment(this.state.startDate).format(CONSTS.SIMPLE_DATE);
    let end = moment(this.state.endDate).format(CONSTS.SIMPLE_DATE);
    let filtered = this.state.isDataFiltered ? CONSTS.FILTERED : CONSTS.ALL;
    let fileTitle = `${this.props.campusInfo.name}-${filtered}-${start}-${end}-Log`
    exportCSVFile(headers, itemsCopy, fileTitle, CONSTS.CSV_CONFIGS);
  };

  handleTimeUpdate = (type, newValue) => {
    let newDate = moment(newValue, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY HH:mm:ss');
    if (type === CONSTS.START) {
      if (moment(newDate).isBetween(this.state.minDate, this.state.endDate)) {
        this.setState({ startDate: newValue, inputUpdate: true, startDateError: false });
      } else {
        this.setState({ startDate: newValue, inputUpdate: false, startDateError: true });
      }
    } else {
      if (moment(newDate).isBetween(this.state.startDate, this.state.maxDate)) {
        this.setState({ endDate: newValue, inputUpdate: true, startDateError: false });
      } else {
        this.setState({ endDate: newValue, inputUpdate: false, startDateError: true });
      }
    }
  };

  handleFilterCampus = (newValue) => {
    const { startDate, endDate } = this.state;
    this.updateLogsData(startDate, endDate, newValue);
  };

  onActionFilter = (newAction) => {
    this.setState({ actionFilter: newAction });
  }

  render() {
    let data = [];
    const { actionList } = this.state.actionList;

    if (!this.state.showSpinner) {
      this.state.logsList.map((e) => {
        let dateFormated = moment(e.timestamp).format(CONSTS.DATE_FORMAT);
        data.push({
          action: e.action,
          description: e.description,
          timestamp: [dateFormated, e.timestamp],
          performedBy: e.userId ? e.userId : "Id not available",
          id: e._id,
        });
      });

      data.sort((a, b) => (a.timestamp[0] < b.timestamp[0]) ? 1 : ((b.timestamp[0] < a.timestamp[0]) ? -1 : 0));
    }

    const columns = [
      {
        name: CONSTS.COLUMNS.action.id,
        label: CONSTS.COLUMNS.action.label,
        options: {
          sort: true,
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => <p className={classes.actionLbl}>{value}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.description.id,
        label: CONSTS.COLUMNS.description.label,
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => <p className={this.props.open ? classes.descriptionLblOpen : classes.descriptionLbl}>{value}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.timestamp.id,
        label: CONSTS.COLUMNS.timestamp.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => <p className={this.props.open ? classes.timestampOpen : ''}>{value[0]}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.performedBy.id,
        label: CONSTS.COLUMNS.performedBy.label,
        options: {
          filter: false,
          sort: false,
        }
      },
    ];

    const options = {
      filter: true,
      filterType: CONSTS.TABLE_CONFIG.filterType,
      responsive: CONSTS.TABLE_CONFIG.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.TABLE_CONFIG.rowsPerPageOptions,
      selectableRowsHeader: false,
      selectableRows: CONSTS.TABLE_CONFIG.selectableRows,
      selectableRowsOnClick: false,
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        return CONSTS.TABLE_SORT(data, colIndex, order);
      },
      toolbar: {
        filterTable: CONSTS.TABLE_CONFIG.filterTable,
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({ tableRowsPerPage: tableState.rowsPerPage });
            break
        }
      }
    };

    const headerRender = () => {
      let { classes } = this.props;
      let campusList = [...this.props.campusList];
      let dateHeaderValue = `${moment(new Date(this.state.startDate)).format("MMM D")} -  ${moment(new Date(this.state.endDate)).format("MMM D, YY")}`
      campusList.push(this.props.campusInfo);

      return (
        <div
          id="webNavv-superAdmin-logs-1.0"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, gap: 20 }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '50vh' }}>
            <div className={classes.inputContainer}>
              <Input
                className={classes.inputOpen}
                placeholder={CONSTS.SEARCH}
                disableUnderline={true}
                value={this.state.search}
                onChange={(e) => { this.handleSearch(e.target.value) }}
                startAdornment={
                  <InputAdornment position={CONSTS.START}>
                    <SearchIcon />
                  </InputAdornment>
                } />
            </div>
            <div className={classes.closeSearch}>
              {this.state.search !== '' &&
                <IconButton onClick={() => this.handleCleanSearch()} className={classes.cleanBtn}>
                  <CloseIcon />
                </IconButton>
              }
            </div>
          </div>
          <div style={{ display: 'flex', flexGrow: 2, justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
            <Autocomplete
              className={classes.autocomplete}
              id={'slog-campus'}
              disableClearable
              options={campusList}
              value={this.state.selectedCampus}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => { this.handleFilterCampus(newValue) }}
              renderInput={(params) =>
                <TextField
                  label={CONSTS.CAMPUS}
                  variant="filled"
                  {...params} />
              }
            />
            <div>
              <div>
                <div className={classes.dateSelectedDiv}>
                  <div className={classes.timeLbls}>
                    <div className={classes.date}>{dateHeaderValue}</div>
                  </div>
                </div>
                <div className={classes.timeDifference}>{this.state.difference.humanize()}</div>
                <div className={classes.timeInputContainer}>
                  <IconButton onClick={() => { this.handleMenu() }}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              </div>
              {this.state.openTimeWindowMenu && (
                <Paper className={this.props.open ? classes.paperOpen : classes.paper}>
                  <TextField
                    className={classes.dateInput}
                    error={this.state.startDateError}
                    id="log-startDate"
                    ref="startDateField"
                    label={CONSTS.START_DATE}
                    type="datetime-local"
                    value={moment(new Date(this.state.startDate)).format(CONSTS.INPUT_DATE_FORMAT)}
                    onChange={(e) => this.handleTimeUpdate(CONSTS.START, e.target.value)}
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: this.state.startDateError ? false : true
                    }}
                    inputProps={{
                      min: moment(new Date(this.state.minDate)).format(CONSTS.INPUT_DATE_FORMAT),
                      max: moment(new Date(this.state.endDate)).format(CONSTS.INPUT_DATE_FORMAT)
                    }}
                  />
                  <TextField
                    className={classes.dateInput}
                    error={this.state.endDateError}
                    id="log-endDate"
                    ref="endDateField"
                    label={CONSTS.END_DATE}
                    type="datetime-local"
                    value={moment(new Date(this.state.endDate)).format(CONSTS.INPUT_DATE_FORMAT)}
                    onChange={(e) => this.handleTimeUpdate(CONSTS.END, e.target.value)}
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      disableUnderline: this.state.endDateError ? false : true
                    }}
                    inputProps={{
                      min: moment(new Date(this.state.startDate)).format(CONSTS.INPUT_DATE_FORMAT),
                      max: moment(new Date(this.state.maxDate)).format(CONSTS.INPUT_DATE_FORMAT)
                    }}
                  />
                  <div className={classes.timeBtns}>
                    <MasterButton
                      keyLbl={'cancel-log'}
                      label={CONSTS.CANCEL}
                      buttonType={'default-flat'}
                      isDisabled={false}
                      handleClick={() => this.handleCancelTimeWindow()}
                      size={"small"} />
                    <MasterButton
                      isDisabled={this.state.startDateError || this.state.endDateError ? true : false}
                      keyLbl={'applyDate-log'}
                      label={CONSTS.APPLY}
                      buttonType={'success-flat'}
                      handleClick={() => this.handleNewTimeWindow()}
                      size={"small"} />
                  </div>
                </Paper>
              )}
            </div>
          </div>


        </div>
      );
    }
    let { classes } = this.props;

    return (
      <div>
        <div>
          {!this.state.showSpinner &&
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={classes.titleContainer} >
                  <Icon style={{ width: 45, height: 35 }}>
                    <img src={logIcon} height={35} width={35} />
                  </Icon>
                  <span className={classes.titleText}>Logs</span>
                </div>
                <div>
                  <MasterButton
                    keyLbl={'export-slogs'}
                    label={CONSTS.EXPORT}
                    buttonType={'success'}
                    isDisabled={false}
                    handleClick={this.generateCSV}
                    size={"medium"}
                    class={classes.btnExport} />
                </div>
              </div>
              <MuiThemeProvider theme={CONSTS.getMuiTheme()}>
                <MUIDataTable
                  title={headerRender()}
                  data={data}
                  columns={columns}
                  options={options} />
              </MuiThemeProvider>
            </div>
          }
        </div>
        <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.logs.logs,
  //campusInfo: state.auth.userInfo.campusInfo,
  open: state.views.drawerOpen,
  userId: state.auth.userId,
  campusList: state.campus.campusList
});

export default connect(mapStateToProps, { getLogsByTimeWindow, getLogs, getCampuses })(withStyles(styles)(LogsTable));