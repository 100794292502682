import moment from "moment";
export const IDLE='idle';
export const IN_PROGRESS='In Progress';
export const TABLE_ID='HT';
export const TABLE_TITLE='History';
export const ALERT_VARIANT="filled";
export const ALERT_TYPE="warning";
export const SPINNER_CONFIG='default';
export const FROM='From';
export const TO='To';
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const DATE="MM/DD/YYYY";
export const MAP='map';
export const SECONDS='seconds';
export const HISTORY='history';
export const SHOW_TRACES='Show Traces';
export const TEXTFIELD_DATETIME="datetime-local";
export const TEXTFIELD_FORMAT="MM/dd/yyyy hh:mm";
export const INCORRECT_ENTRY="Incorrect entry";
export const DATE_RANGE='Date range';
export const ROWS_PER_PAGE=[10, 30, 50, 100];
export const HISTORY_ATTRIBUTES={
  distance: 'distance',
  total_logs: "total_logs",
  jobHasTraceHealing: "jobHasTraceHealing",
  device:'device'
};
export const BUTTON_TYPES={
  defaultFlat: 'default-flat'
};
export const BUTTON_SIZE={
  medium: 'medium'
};
export const TABLE_EVENTS={
  changeRowsPerPage:'changeRowsPerPage',
  propsUpdate:'propsUpdate',
  rowsSelect:'rowsSelect',
  search:'search',
  filterChange:'filterChange',
  onSearchClose:'onSearchClose',
  resetFilters:'resetFilters',
  viewColumnsChange:'viewColumnsChange',
  changePage: 'changePage',
  onFilterDialogOpen: 'onFilterDialogOpen',
  onFilterDialogClose: 'onFilterDialogClose'
};
export const TABLE_OPTIONS={
  filterType:"dropdown",
  responsive:"standard",
  selectableRows:"multiple",
  filename:'History.csv',
  separator:';',
  sort: 'asc'
};
export const STRING_FILED_TYPES={
  worker:{
    id:'worker',
    label:'Worker'
  },
  device:{
    id:'device',
    label:'Device'
  }
};
export const COLUMNS={
  jobID:{
    no:0,
    id:'jobID',
    label:'Job ID'
  },
  distance:{
    no:1,
    id:'distance',
    label:'Distance'
  },
  worker:{
    no:2,
    id:'worker',
    label:'Worker'
  },
  duration:{
    no:3,
    id:'duration',
    label:'Duration'
  },
  status:{
    no:4,
    id:'status',
    label:'Status'
  },
  deviceID:{
    no:5,
    id:'deviceID',
    label: 'Device ID'
  },
  date:{
    no:6,
    id:'date',
    label:'Date'
  },
  jobStart:{
    no:7,
    id:'jobStart',
    label:'Job Start'
  },
  jobEnd:{
    no:8,
    id:'jobEnd',
    label:'Job End'
  }
};
export const FILTER_TYPE={
  textField: 'testField',
  custom: 'custom'
};
export const TABLE_SORT_HT = (data, colIndex, order) => {
  if (colIndex === 2) {
    return data.sort((a, b) => {
      return (a.data[colIndex].toLowerCase() < b.data[colIndex].toLowerCase() ? -1 : 1) * (order ? 1 : -1);
    });
  } else if (colIndex === 0 || colIndex === 3 || colIndex === 5) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order ? 1 : -1);
    });
  } else if (colIndex === 1 ) {
    return data.sort((a, b) => {
      return (a.data[colIndex] - b.data[colIndex]) * (order ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][0] < b.data[colIndex][0] ? -1 : 1) * (order ? 1 : -1);
    });
  }
};

export const CUSTOM_CSVDOWNLOAD = (buildHead, buildBody, columns, data) => {
  let csvBody = [];
  let finalColumns = columns;
  let jobEndIndex = -1;
  let jobStartIndex =-1;
  let dateIndex=-1;
  let durationIndex=-1;
  columns.forEach((column, i)=>{
    if(column.name===COLUMNS.jobStart.id){
      jobStartIndex=i;
    }
    if(column.name===COLUMNS.jobEnd.id){
      jobEndIndex=i;
    }
    if(column.name===COLUMNS.duration.id){
      durationIndex=i;
    }
    if(column.name===COLUMNS.date.id){
      dateIndex=i;
    }
  });
  data.forEach(p => {
    let csvData = [];
    for (let i = 0; i <finalColumns.length; i++) {
      if (i === dateIndex || i === jobStartIndex || i === jobEndIndex){
        csvData.push(p.data[i][1]);
      }else if (i === durationIndex){
        csvData.push(p.data[i].humanize());
      }else{
        csvData.push(p.data[i]);
      }
    }
    csvBody.push({ data: csvData });
  });
  return `${buildHead(finalColumns)}${buildBody(csvBody)}`.trim();
};

export const START_DATE_FILTER_LOGIC = (date, filters, rangeFilterChecked) => {
  if (filters) {
    let startDateIso = moment(filters).toISOString();
    let search = moment(startDateIso).format(DATE_FORMAT);
    if (!rangeFilterChecked) {
      if (search !== date[0]) {
        return true;
      }
    } else {
      let search = moment(filters).subtract(1, SECONDS);
      let dateValue = moment(date[0]);
      if (search >= dateValue) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const END_DATE_FILTER_LOGIC = (date, filters, rangeFilterChecked) => {
  if (filters) {
    if (!rangeFilterChecked) {
      let startDateIso = moment(filters).toISOString();
      let search = moment(startDateIso).format(DATE_FORMAT);
      if (search !== date[0]) {
        return true;
      }
    } else {
      let search = moment(filters).add(1, SECONDS);
      let dateValue = moment(date[0]);
      if (search <= dateValue) {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const DATE_FILTER_LOGIC = (date, filters) => {
  if (filters) {
    let startDateIso = moment(filters).toISOString();
    let search = moment(startDateIso).format(DATE);
    if (search !== date[0]) {
      return true;
    }
  } else {
    return false;
  }
};

export  const  STRING_FILTER_LOGIC = (label, filters) => {
  if (filters) {
    if (label !== filters){
      return true;
    }
  } else{
    return false;
  }
};

const columnLabels = {
  0: COLUMNS.jobID.label,
  1: COLUMNS.distance.label,
  2: COLUMNS.worker.label,
  3: COLUMNS.duration.label,
  4: COLUMNS.status.label,
  5: COLUMNS.deviceID.label,
  6: COLUMNS.date.label,
  7: COLUMNS.jobStart.label,
  8: COLUMNS.jobEnd.label
};

export const DATE_FILTER_REFRESH = (filter, data)=>{
  let filtered = [];
  let dateIso = moment(filter).toISOString();
  let search = moment(dateIso).format(DATE);
  filtered = data.filter(row=>row.date[0] === search);

  return filtered;
};

export const START_DATE_FILTER_REFRESH = (filter, data, rangeFilterChecked)=>{
  let filtered = [];
  if (!rangeFilterChecked) {
    let startDateIso = moment(filter).toISOString();
    let search = moment(startDateIso).format(DATE_FORMAT);
    filtered = data.filter(row=> moment(row.jobStart[0]).format(DATE_FORMAT) === search);
  } else {
    let search = moment(filter).subtract(1, SECONDS);
    filtered = data.filter(row=>search >= moment(row.jobStart[0]));
  }

  return filtered;
};

export const END_DATE_FILTER_REFRESH = (filter, data, rangeFilterChecked)=>{
  let filtered = [];
  if (!rangeFilterChecked) {
    let endDateIso = moment(filter).toISOString();
    let search = moment(endDateIso).format(DATE_FORMAT);
    filtered = data.filter(row=>moment(row.jobEnd[0]).format(DATE_FORMAT) === search);
  } else {
    let search = moment(filter).add(1, SECONDS);
    filtered = data.filter(row=> search <= moment(row.jobEnd[0]));
  }

  return filtered;
};

export const TABLE_FILTER_REFRESH = (filterBy, data, rangeFilterChecked)=>{
  let filtered = [];
  let finalFilter = [];
  let dataList = {};

  filterBy.forEach((d, i)=>{
    if (d.length !== 0 && i > 0) {
      switch (i) {
        case COLUMNS.date.no:
          dataList[i] = DATE_FILTER_REFRESH(d[0], data);
          break;
        case COLUMNS.startDate.no:
          dataList[i] = START_DATE_FILTER_REFRESH(d[0], data, rangeFilterChecked);
          break;
        case COLUMNS.endDate.no:
          dataList[i] = END_DATE_FILTER_REFRESH(d[0], data, rangeFilterChecked);
          break;
        default:
          dataList[i] = data.filter(row=> row[columnLabels[i]] === d[0]);
      }
    }
  });

  for (let i = 0; i < 9; i++) {
    if (dataList[i] !== undefined){
      filtered = filtered.concat(dataList[i]);
    }
  }
  if (filtered.length > 1) {
    let duplicates = filtered.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0){
        acc.push(el);
      }
      return acc;
    }, []);
    finalFilter = duplicates.length === 0? filtered : duplicates;
  } else {
    finalFilter = filtered;
  }

  return finalFilter;
};