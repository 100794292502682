export const exportCSVFile = (headers, items, fileTitle, CSV_CONFIGS)=> {
    if (headers) {
            items.unshift(headers);
    }
          
    let jsonObject = JSON.stringify(items);
    let csv = convertToCSV(jsonObject);
    let exportedFilenmae = fileTitle + CSV_CONFIGS.extension || CSV_CONFIGS.defaultName;
    let blob = new Blob([csv], { type: CSV_CONFIGS.blobType });

    if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, exportedFilenmae);

    } else {
            
        let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

     }
};

const convertToCSV = (objArray)=> {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line != '') {
                line += ';';
            }  
            line += array[i][index];
        }
        str += line + '\r\n';
    }
        return str;
};