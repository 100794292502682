import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    TimeWindowControls_Paper:{
        padding: "15px",
        position: "absolute",
        zIndex: "100",
        top: "21%",
        left: "45%"
    },
    TimeWindowControls_Buttons:{
        marginTop: "10px",
        float: "right",
    },
    TimeWindowControls_TitleContainer:{
        display:"inline-flex",
        color:"#757575",
        fontWeight:"600",
        fontSize:"20px"
    },
    TimeWindowControls_DateContainer:{
        display:"inline-flex",
        marginLeft: "30%",
        fontSize:"18px"
    },
    TimeWindowControls_DateTitle:{
        marginLeft: "20px",
        marginRight: "20px",
        marginTop:"11px"
    },
    TimeWindowControls_calendarIconDiv:{
        "&:hover": {
          cursor: "pointer"
        }
    },
    TimeWindowControls_calendarIcon:{
        fill: "#2196F3",
        fontSize: "18px"
    },
    TimeWindowControls_calendarIconDisabled:{
        fill: "#CFCFCF",
        fontSize: "18px"
    },
}));