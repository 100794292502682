/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import hostName from "./components/utilities/hostName";

const secureUrl = new hostName();
secureUrl.initHostName();


export const graphQL_Config = () => {
    return {
        APIKEY: "da2-xxh26zu7qzeqjj3oqnw6pntwli",
        URL: "https://workflow-appsync.lahey.navvtrack.com/graphql"
    }
}

const configs = graphQL_Config();

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": configs.URL,
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": configs.APIKEY
};


export default awsmobile;
