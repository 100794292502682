import { GET_MODELS, ADD_MODEL, PUT_MODEL, DELETE_MODEL } from "../actions/modelPhone/types";

const initialState = {
  models: [],
  model: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODELS:
      return {
        ...state,
        models: action.payload
      };
    case ADD_MODEL:
      return {
        ...state,
        model: action.payload
      };
    case PUT_MODEL:
      return {
        ...state,
        model: action.payload
      };
    case DELETE_MODEL:
      return {
        ...state,
        model: action.payload
      };
    default:
      return state;
  }
}
