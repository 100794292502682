import React from "react";
import moment from "moment";
import { jobStatusTypesGraphQL } from "../../jobsBoard/jobsBoard.constants";

export const FROMINPUT = "fromInput";
export const SEARCH = "Search";
export const EXPORT = "Export";
export const JOBS_HISTORY = "Jobs History";
export const WORKFLOW_AUTOMATION = "Workflow Automation";
export const COMPLETE_WITH_ERROR = "Complete with Error";
export const COLUMNS = {
    jobName:{
        no:0,
        id:'jobName',
        label:'Job Name'
    },
    messenger:{
        no:1,
        id:'messenger',
        label:'Messenger/Escort'
    },
    date:{
        no:2,
        id:'date',
        label:'Date'
    },
    startTime:{
        no:3,
        id:'startTime',
        label:'Start Time'
    },
    endTime:{
        no:4,
        id:'endTime',
        label:'End Time'
    },
    duration:{
        no:5,
        id:'duration',
        label:'Duration'
    },
    status:{
        no:5,
        id:'status',
        label:'Status'
    },
    jobId:{
        no:6,
        id:'jobId',
        label:'Job ID'
    },
}

export const OPTIONS={
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 30],
    selectableRows: 'single',
    defaultSort: 'asc',
    filterTable: 'Filter Table'
}

export const TABLE_COLUMNS = [
  {
    name: COLUMNS.jobName.id,
    label: COLUMNS.jobName.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"60px"}}>{value}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.messenger.id,
    label: COLUMNS.messenger.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"65px"}}>{value}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.date.id,
    label: COLUMNS.date.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"60px"}}>{moment(value).format("MM/DD/YYYY")}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.startTime.id,
    label: COLUMNS.startTime.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"60px"}}>{moment(value).format("hh:mm A")}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.endTime.id,
    label: COLUMNS.endTime.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"60px"}}>{moment(value).format("hh:mm A")}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.duration.id,
    label: COLUMNS.duration.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        return (
          <p style={{textAlign:'left', marginLeft:"55px"}}>{value.humanize()}</p>
        )
      },
    }
  },
  {
    name: COLUMNS.status.id,
    label: COLUMNS.status.label,
    options: {
      sortThirdClickReset: true,
      filter: false,
      customBodyRender: (value) => {
        if (value.status === jobStatusTypesGraphQL.complete) {
          return (
            <p style={{color:"#458E2D", textAlign:'left', marginLeft:"55px"}}>{value.label}</p>
          )
        }
        else if (value.status === jobStatusTypesGraphQL.error || value.status === jobStatusTypesGraphQL.completeWithError) {
          return (
            <p style={{color:"#C83532", textAlign:'left', marginLeft:"55px"}}>{value.label}</p>
          )
        }
      },
    }
  },
]

export const DEFAULT_SORT='asc';

export const TABLE_SORT = (data, colIndex, sortType) => {
  if (colIndex === 5) {
    return data.sort((a, b) => {
      if (a.data[2] === null || a.data[3] === null || a.data[4] === null) {
        // Incorrect date, startDate or endDate
        return 1;
      }
      if (b.data[2] === null || b.data[3] === null || b.data[4] === null) {
        // Incorrect date, startDate or endDate
        return -1;
      }
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (sortType ? -1 : 1);
    });
  } 
  else if(colIndex === 6) {
    return data.sort((a, b) => {
      return (a.data[colIndex].label < b.data[colIndex].label ? -1 : 1) * (sortType ? 1 : -1);
    });
  }
  else{
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (sortType ? 1 : -1);
    });
  }
};