import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import FlatButton from 'material-ui/FlatButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { doLogin, handlCheckToken } from '../../actions/auth/authActions';
import packageJson from '../../../package.json';

//import MicrosoftLoginCmp from '../utilities/microsoftLogin.component';
import { MAIN, ACCESS, DEFAULT_USER_ERROR, DEFAULT_PASS_ERROR, EMPTY_VALUE, COGNITO_USERS, COGNITO_PASSWORD } from './login.constants';

import hlogo from './../../img/novatrack_logo.png';
//import miniLogo from './../../img/ntrackLogo.png';
import miniLogo from './hospital-icons/Logo.png';
import pVector from './../../img/Frame.png';
import phoneVector from './../../img/phone.png';
import microsoftIcon from './hospital-icons/microsoftIcon.png';
import superAd from './hospital-icons/superAd.png';
import './login.component.css';
import hostName from '../utilities/hostName';

//CHIME IMPORT
import { useAuthContext } from '../../Providers/AuthProvider';

const secureUrl = new hostName();
secureUrl.initHostName();

const LoginFunction = (props) => {
    const cognitoUsers = [...COGNITO_USERS];
    const classes = useStyles();
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [userError, setUserError] = useState(DEFAULT_USER_ERROR);
    const [passError, setPassError] = useState(DEFAULT_PASS_ERROR);
    const [loginError, setLoginError] = useState(false);
    const [checkedB, setCheckedB] = useState(false);
    const { isAuthenticated, userSignIn, userSignOut, member, userExchangePassForAwsCreds, userExchangeLambdaAPIForAwsCreds } = useAuthContext();

    useEffect(() => {
        // code to run on component mount
        handleSAMLRequest();
    }, [])

    useEffect(() => {
        if (!!props.token) {
            props.history.push('/dashboard');
        }
    }, [props.token])

    useEffect(() => {
        if (isAuthenticated) {
            const userName = user.toString().toLowerCase().trim();

            console.log(`Autenticated user: ${JSON.stringify(member)}`);
            if (userName.length > 0 && pass.length > 0) {
                props.doLogin(userName, pass.toString());
            } else {
                setLoginError(true);
            }

        }
    }, [isAuthenticated])

    useEffect(() => {
        if (!!props.loginError && !!props.loginError.response) {
            const { status } = props.loginError.response
            if (!!status && status === 400) {
                const { message } = props.loginError.response.data.error;
                setLoginError(true);
                setUserError(message);
                setPassError(EMPTY_VALUE);
            }
            else {
                setLoginError(true);
                setUserError(DEFAULT_USER_ERROR);
                setPassError(DEFAULT_PASS_ERROR);
            }
        }
    }, [props.loginError])

    const handleSAMLRequest = async () => {
        if (Object.values(props.match.params).length > 0
            && !!props.match.params.id
            && !!props.match.params.campusId) {
            const { id, campusId } = props.match.params;
            const isValidToken = await props.handlCheckToken(id, campusId);
            if (isValidToken) {
                props.history.push('/dashboard');
            }
        }
    };

    const goToSuperAdmin = () => {
        props.history.push('/superAdminLogin');
    }

    const handleLogin = async () => {
        //Cognito login activated
        const userName = user.toString().toLowerCase().trim();
        const passw = pass.toString().trim()

        try {
            //Lambda function for authentication... Working fine but slow response
            /* userExchangeLambdaAPIForAwsCreds(); */
            if (cognitoUsers.includes(userName)) {
                if (!isAuthenticated) {
                    await userSignIn(userName, COGNITO_PASSWORD);
                }
                else {
                    props.doLogin(userName, passw);
                }
            }
            else {
                if (userName.length && passw.length) {
                    props.doLogin(userName, passw);
                }
                else {
                    setLoginError(true);
                }
            }
        } catch (e) {
            console.log(`Error handling authentication : ${e}`);
        }


        //Cognito logout
        // userSignOut();

        //original login
        /*
        if (user.length > 0 && pass.length > 0) {
            props.doLogin(user.toString().toLowerCase().trim(), pass.toString().trim());
        } else {
            setLoginError(true);
        }
        */
    }

    const handleForgot = () => {
        props.history.push('/passwordReset');
    }

    const SvgIcon = (
        <Icon style={{ fontSize: 20, display: 'flex' }}>
            <img src={microsoftIcon} />
        </Icon>
    );

    const keyPressed = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

    const validateEmail = (emailString) => {
        let regex = /^[a-zA-Z-0-9_]+(\.{1}[a-zA-Z-0-9_]+)?@[a-zA-Z]+?\.[a-z-A-Z]{2,3}(\.[a-z-A-Z]{2,3})?$/;
        return (regex.test(String(emailString)));
    }

    return (
        <div
            id="webNavv-login-1.0"
            className="App"
        >
            <form onSubmit={() => { goToSuperAdmin() }}>
                <div className="left-splash">
                    <p className="welcome-to-novatrack">{MAIN.title}</p>
                    <p className="fancy-copy-here-ver">{MAIN.subtitle}</p>
                    <p className="bacon-ipsum-dolor-am">{MAIN.detail}</p>
                    <img src={hlogo} className="b-logo" alt="hlogo" />
                    <img src={pVector} className="p-vector" alt="pVector" />
                    <img src={phoneVector} className="phone-vector" alt="phoneVector" />
                    <p className="version">v{packageJson.version}</p>
                </div>

                <div className="login-form" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ display: "flex", width: 300, marginBottom: 120 }}>
                        <img src={miniLogo} className={classes.mLogo} alt="miniLogo" />
                    </div>
                    <div id="webNavv-login-form-email_input-1.0">
                        <p className={classes.inputEmailLbl}>{ACCESS.email}</p>
                        <TextField
                            tabIndex="1"
                            hinttext="Email Address"
                            floatinglabeltext="Email Address"
                            onChange={(e) => { setUser(e.target.value) }}
                            InputProps={{ className: classes.inputEmail }}
                            FormHelperTextProps={loginError ? { className: classes.helperTextEmail } : {}}
                            helperText={loginError ? userError : null}
                        />
                    </div>
                    <div
                        id="webNavv-login-form-password_input-1.0"
                        style={{ margin: "20px 0 40px 0" }}
                    >
                        <p className={classes.inputPassLbl}>{ACCESS.password}</p>
                        <TextField
                            tabIndex="2"
                            hinttext="Enter a password"
                            floatinglabeltext="Enter a password"
                            type="password"
                            onChange={(e) => { setPass(e.target.value) }}
                            InputProps={{ className: classes.inputPass }}
                            FormHelperTextProps={loginError ? { className: classes.helperTextPass } : {}}
                            helperText={loginError ? passError : null}
                        />
                    </div>
                    <Button
                        id='webNavv-login-form-login_button.1.0'
                        className={classes.loginBtn}
                        onClick={() => handleLogin()}>
                        {ACCESS.login}
                    </Button>
                    <div style={{ display: 'flex' }}>
                        <span className={classes.line}></span>
                        <span className={classes.textLine}>{ACCESS.or}</span>
                        <span className={classes.line}></span>
                    </div>
                    <Button
                        id='webNavv-login-form-microsoft_button-1.0'
                        variant="contained"
                        className={classes.microsoftButton}
                        startIcon={SvgIcon}
                        href={`${secureUrl.hostUrl}/api/loginSAML`}
                    >
                        Sign in with Microsoft
                    </Button>
                    <div
                        id="webNavv-login-form-remember-1.0"
                        style={{ width: 300 }}
                    >
                        <Checkbox
                            className={classes.checkbox}
                            checked={checkedB}
                            onChange={(e) => { setCheckedB(e.target.checked) }}
                            name="checkedB"
                            color="primary"
                        />
                        <span className={classes.checkboxTxt}>Remember me</span>
                    </div>
                    <IconButton
                        id="webNavv-login-form-superAdmin_button-1.0"
                        type="submit"
                        className={classes.superIconBtn}
                    >
                        <img src={superAd} style={{ width: 20, height: 17 }} />
                    </IconButton>
                </div>
            </form>
        </div>
    );


}

const useStyles = makeStyles((theme) => ({
    checkboxTxt: {
        fontSize: '12px',
        color: 'gray',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        textAlign: 'left',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginLeft: '24vh',
            marginTop: '-3.5vh',
        }
    },
    checkbox: {
        ['@media (max-width:1440px)']: {
            marginLeft: '19vh',
            marginTop: '70vh',
        }
        */
    },
    helperTextEmail: {
        color: "red",
        transform: 'translate(225px, 435px)',
        ['@media (max-width:1440px)']: {
            transform: 'translate(160px, 360px)',
        },
        display: "contents",
    },
    helperTextPass: {
        color: "red",
        transform: 'translate(225px, 550px)',
        ['@media (max-width:1440px)']: {
            transform: 'translate(160px, 455px)',
        },
        display: "contents",
    },

    contact: {
        height: '19px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        fontFamily: 'Roboto',
        lineHeight: '14px',
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: '#2843A3',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginTop: '3%',
            marginLeft: '25%',
        }
        */
    },
    footerTxt: {
        width: '289px',
        height: '19px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        fontFamily: 'Roboto',
        lineHeight: '14px',
        textAlign: 'center',
        letterSpacing: '0.04em',
        color: '#6F6F6F',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginTop: '3%',
            marginLeft: '7%',
        }
        */
    },
    forgotPass: {
        height: '19px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '10px',
        fontFamily: 'Roboto',
        lineHeight: '16px',
        textAlign: 'right',
        color: '#2843A3',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginLeft: '18vh',
            marginTop: '71.5vh',
        }
        */
    },
    loginBtn: {
        width: '296px',
        height: '45px',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: '10px',
        boxSizing: 'border-box',
        color: 'white',
        fontWeight: '600',
        background: '#CFCFCF',
        /*
        ['@media (max-width:1440px)']: {
            marginLeft: '20vh',
        },
        */
        "&:hover": {
            background: '#2843A3'
        }
    },
    root: {
        background: "black"
    },
    inputEmailLbl: {
        width: '47px',
        height: '23px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '15px',
        fontFamily: 'Roboto',
        lineHeight: '23px',
        color: '#6F6F6F',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginLeft: '20vh',
            marginTop: '36vh',
        },
        */
        margin: "unset",
    },
    inputPassLbl: {
        width: '47px',
        height: '23px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '15px',
        fontFamily: 'Roboto',
        lineHeight: '23px',
        color: '#6F6F6F',
        boxSizing: 'border-box',
        /*
        ['@media (max-width:1440px)']: {
            marginLeft: '20vh',
            marginTop: '48vh',
        },
        */
        margin: "unset",
    },
    inputEmail: {
        width: '296px',
        height: '45px',
        background: '#F9FAFF',
        border: '0.2px solid #979797',
        boxSizing: 'border-box',
        borderRadius: '10px',
        "&&&:before": { borderBottom: "none" },
        "&&:after": { borderBottom: "none" },
        /*
        ['@media (max-width:1440px)']: {
            left: '20vh',
            top: '40vh',
        }
        */
    },
    inputPass: {
        width: '296px',
        height: '45px',
        background: '#F9FAFF',
        border: '0.2px solid #979797',
        boxSizing: 'border-box',
        borderRadius: '10px',
        "&&&:before": { borderBottom: "none" },
        "&&:after": { borderBottom: "none" },
        /*
        ['@media (max-width:1440px)']: {
            left: '20vh',
            top: '52vh',
        }
        */
    },
    inputPassError: {
        width: '296px',
        height: '45px',
        background: '#F9FAFF',
        border: '0.2px solid red',
        boxSizing: 'border-box',
        borderRadius: '10px',
        "&&&:before": { borderBottom: "none" },
        "&&:after": { borderBottom: "none" },
        /*
        ['@media (max-width:1440px)']: {
            left: '20vh',
            top: '52vh',
        }
        */
    },
    inputEmailError: {
        width: '296px',
        height: '45px',
        background: '#F9FAFF',
        border: '0.2px solid red',
        boxSizing: 'border-box',
        borderRadius: '10px',
        "&&&:before": { borderBottom: "none" },
        "&&:after": { borderBottom: "none" },
        /*
        ['@media (max-width:1440px)']: {
            left: '20vh',
            top: '40vh',
        }
        */
    },
    mLogo: {
        width: '100%',
        height: 100,
    },
    appHeader: {
        width: 215,
        height: 70,
        color: "#2843A3",
        fontSize: 40,
        fontWeight: "bold",
    },
    line: {
        height: 1,
        width: 132,
        backgroundColor: '#bbb',
        float: 'left',
        margin: '14px 0px',
        padding: 'none',
    },
    textLine: {
        float: 'left',
        margin: 5,
        padding: 'none',
        color: '#818181',
        fontSize: 14,
    },
    microsoftButton: {
        width: 296,
        height: 45,
        fontSize: 12,
        fontFamily: 'Roboto',
        borderWidth: 1,
        color: '#5E5E5E',
        background: '#FFFFFF',
        borderColor: '#8C8C8C',
        marginTop: 20,
        textTransform: 'unset',
    },
    notificationMsg: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 500,
        //lineHeight: 14,
        letterSpacing: '0.04em',
    },
    notificationSelect: {
        "&:hover": {
            cursor: 'pointer',
        }
    },
    superIconBtn: {
        marginTop: 40,
        textDecoration: 'none',
        width: 30,
        height: 30,
    },
    checkbox: {
        width: 16,
        paddingLeft: 'unset',
    }
}));

LoginFunction.propTypes = {
    token: PropTypes.string,
    doLogin: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    token: state.auth.token,
    loginError: state.auth.loginError,
})

export default connect(mapStateToProps, { doLogin, handlCheckToken })(LoginFunction);