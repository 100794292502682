export const styles = () => ({
  paper: {
    minWidth: "700px",
    minHeight: "100%",
    border: 'none'
  },
  root: {
    marginRight: "20px",
    width: "150px"
  },
  tableCell: {
    padding: "0px 10px 0px 10px",
    textAlign: "center !important"
  },
  tableCellNumber: {
    padding: "4px 4px 4px 4px",
    textAlign: "center !important",
    width: "1px"
  },
  tableHeader: {
    padding: "4px 30px 4px 31px"
  },
  killSession: {
    width: "100px",
    padding: "4px 4px 4px 25px"
  },
  tableCellWorker: {
    padding: "0px 10px 0px 10px"
  },
  findPhoneIcon:{
    color: '#7D7D7D',
    marginLeft: '20%'
  },
  titleContainer:{
    display:'flex',
    marginTop:"20px"
  },
  titleIcon:{
    fill:'#2843A3',
    fontSize:'50px',
    opacity:0.7
  },
  titleIconContainer: {
    marginTop: '1%'
  },
  titleLbl:{
    color:'#2843A3',
    marginLeft:'1%',
  },
  dialogTitleMainDiv:{
    backgroundColor:'#2843A3',
    height:'10vh',
    display:'flex'
  },
  dialogContainer:{
    display:'flex',
    marginLeft:'2vw',
    marginTop:'2vh'
  },
  dialogIcon:{
    fill:'white',
    fontSize:'30px',
    marginTop:'1.5vh'
  },
  dialogTitleLbl:{
    color:'white',
    marginLeft:'1vw'
  },
  statusMainDiv:{
    display: "flex",
    justifyContent: "center"
  },
  statusContainer:{
    margin: "0 2px",
    height: "9px",
     width: "9px",
    borderRadius: "4.5px",
    position: "relative",
    top: "5px"
  },
  cicoIMG:{
    height: "20px",
    width: "25px",
    marginLeft: "2px",
    display: "table-cell",
  },
  streamingMainDiv:{
    display: "flex",
    justifyContent: "center",
  },
  streamingContainer:{
    margin: "0 2px",
    height: "10px",
    width: "10px",
    position: "relative",
    top: "5px"
  },
  inactiveStreaming:{
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 4.5px 10px 4.5px',
    borderColor: 'transparent transparent orange transparent'
  },
  activeStreaming:{
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 4.5px 10px 4.5px',
    borderColor: 'transparent transparent #3FA33E transparent'
  },
  numHeader:{
    textAlign: 'center',
    marginTop:"15px",
    fontSize:"14px",
    color:"gray"
  }
});