export const CHART_TYPE = [
    'Users by Campus and Role',
   // 'Users by Campus',
    'Phones by Campus and Role',
   // 'Phones by Campus'
];
export const DASHBOARD_CARD_TITLE = {
    users: 'Number of users',
    phones: 'Number of phones',
    messages: 'Number of messages',
    equipments: 'Total equipment devices',
}
export const MESSAGE_CHART_TITLE = 'Number of messages';
export const EMPTY_STRING = '';
export const DEFAULT_DATE_FORMAT="MM/DD/YYYY HH:mm";
export const BACK_TITLE ="BACK TO DASHBOARD";
export const USER_COLUMNS = {
    name: 'Name',
    campus: 'Campus',
    role: 'Role',
    email: 'Email',
    date: 'Date',
    login: 'Last Login',
    logout: 'Last Logout',
};
export const MESSAGE_COLUMNS = {
    campus: 'Campus',
    admin: 'Admin',
    send: 'Messages Send',
    read: 'Messages Read',
    lastDate: 'Last Message Send',
};
export const PHONE_COLUMNS = {
    id: 'Identifier',
    model: 'Model',
    campus: 'Campus',
    user: 'Used By',
    role: 'Role',
    lastLogin: 'Last Login',
    lastLogout: 'Last Logout',
};
export const DASHBOARD_COMPONENT = {
  dashboard: 'd',
  users: 'u',
  phones: 'p',
  messages: 'm',
}