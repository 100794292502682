import moment from "moment";
import { mutationUpdateAssociatedJob } from "../../../graphql/mutations";
import { jobStatusTypesGraphQL, MULTIPLE_ERRORS } from "../../jobsBoard.constants";
import _ from 'lodash';

export const handleUpdateItemGroupId = (items, groups) =>{
  let itemsCopy = [];

  items.forEach(item=>{
    const currentGroup = groups.find(group=> group.resource_slotid === item.resource_slotid);
    if (!!currentGroup) {
      item.group = currentGroup.id;
      itemsCopy.push(item)
    }
  });

  return itemsCopy;
}

export const handleUpdateCurrentJobStatus = async(UpdateAssociatedJob_Input) =>{
  await mutationUpdateAssociatedJob(UpdateAssociatedJob_Input);
}

export const handleFormatDelayedJob = (groups,items,delayedJobs) =>{
  let jobsCopy = [...items];
  let groupsCopy = [...groups];

  delayedJobs.forEach(delayedJob =>{
    const slotItBelongsIndex = groups.findIndex(group => group.resource_slotid === delayedJob.resource_slotid);
  
    if(slotItBelongsIndex !== -1) {
      const jobStartDate = moment(delayedJob.job_start_date).valueOf();
      const jobEndDate = moment(delayedJob.job_end_date).valueOf();
      const jobCanMove = checkIfJobCanMove(delayedJob.job_status);
      const jobProgress = getJobProgressInError(delayedJob);
      const jobErrors = getJobErrorsInError(delayedJobs);
      const jobToSetAsDelayedIndex = items.findIndex(item=>item.jobid === delayedJob.jobid);

      if (slotItBelongsIndex !== -1) {
        jobsCopy = updateJobAttributesInError({jobsCopy, index: jobToSetAsDelayedIndex, groups, slotItBelongsIndex, updateJob: delayedJob, jobProgress, jobStartDate, jobEndDate, jobCanMove, jobErrors});
      }
    
      groupsCopy = handleCheckSlotStatusAfterJobUpdate(groups, jobsCopy, slotItBelongsIndex);
    }

  });

  return {jobsCopy, groupsCopy}
}

export const handleFormatUpdatedJobInError = (groups,items,updateJobInError,jobTasks) =>{
    const updateJob = updateJobInError[0];
    const slotItBelongsIndex = groups.findIndex(group => group.resource_slotid === updateJob.resource_slotid);
    let jobsCopy = [...items];
    let groupsCopy = [...groups];
    let jobTasksCopy = [...jobTasks];
  
    if(slotItBelongsIndex !== -1) {
      const jobStartDate = moment(updateJob.job_start_date).valueOf();
      const jobEndDate = moment(updateJob.job_end_date).valueOf();
      const jobCanMove = checkIfJobCanMove(updateJob.job_status);
      const jobProgress = getJobProgressInError(updateJob);
      const jobErrors = getJobErrorsInError(updateJobInError);
  
      items.forEach((item, index)=>{
        if (item.jobid === updateJob.jobid){
          jobsCopy = updateJobAttributesInError({jobsCopy, index, groups, slotItBelongsIndex, updateJob, jobProgress, jobStartDate, jobEndDate, jobCanMove, jobErrors});
        }
      });
    
      groupsCopy = handleCheckSlotStatusAfterJobUpdate(groups, jobsCopy, slotItBelongsIndex);
      jobTasksCopy = handleSetErrorInTask(updateJobInError[updateJobInError.length-1], jobTasks);
    }
  
    return {jobsCopy, groupsCopy, jobTasksCopy}
}

export const handleFormatUpdatedJob = (groups,items,updateJob) =>{
    const slotItBelongsIndex = groups.findIndex(group => group.resource_slotid === updateJob.resource_slotid);
    let jobsCopy = [...items];
    let groupsCopy = [...groups];
  
    if(slotItBelongsIndex !== -1) {
      const jobStartDate = moment(updateJob.job_start_date).valueOf();
      const jobEndDate = moment(updateJob.job_end_date).valueOf();
      const jobCanMove = checkIfJobCanMove(updateJob.job_status);
      const jobProgress = getJobProgress(updateJob);
      const {jobStatus, jobAssignedUserName, jobAssignedUserId} = updateJobAfterUnassigned(groups, slotItBelongsIndex, updateJob);
  
      items.forEach((item, index)=>{
        if (item.jobid === updateJob.jobid){
          jobsCopy = updateJobAttributes({jobsCopy, index, groups, slotItBelongsIndex, updateJob, jobStartDate, jobEndDate, jobCanMove, jobProgress, jobStatus, jobAssignedUserName, jobAssignedUserId})
        }
      });
    
      groupsCopy = handleCheckSlotStatusAfterJobUpdate(groups, jobsCopy, slotItBelongsIndex);
    }
    
    return {jobsCopy, groupsCopy}
}

export const handleCheckSlotStatusAfterJobUpdate = (groups, jobsCopy, slotItBelongsIndex) =>{
    let groupsCopy = [...groups];
  
    const slotJobsInProgress = getSlotJobsInProgress(jobsCopy, groupsCopy, slotItBelongsIndex);
    const slotJobsCompleted = getSlotJobsCompleted(jobsCopy, groupsCopy, slotItBelongsIndex);
    const slotJobsError = getSlotJobsWithErrors(jobsCopy, groupsCopy, slotItBelongsIndex);
    const slotJobsInProgressWithErrors = getSlotJobsInProgressWithErrors(jobsCopy, groupsCopy, slotItBelongsIndex);
    const slotJobsCompletedWithErrors = getSlotJobsCompletedWithErrors(jobsCopy, groupsCopy, slotItBelongsIndex);
  
    if (checkIfSlotContainsJobsInFirstScenario(slotJobsCompleted, slotJobsError, slotJobsCompletedWithErrors)) {
      groupsCopy = setSlotStatusFirstScenario(groupsCopy, slotItBelongsIndex);
    }
    if (checkIfSlotContainsJobsInSecondScenario(slotJobsInProgress, slotJobsInProgressWithErrors)){
      groupsCopy = setSlotStatusSecondScenario(groupsCopy, slotItBelongsIndex);
    }
  
    return groupsCopy
}

export const handleSetErrorInTask = (updateJob, jobTasks) =>{
    const jobTasksCopy = [...jobTasks];
    jobTasks.forEach((element, i) => {
      if (element.taskid === updateJob.taskid) {
        jobTasksCopy[i].job_error_number = updateJob.job_error_number;
        jobTasksCopy[i].error_date = jobTasksCopy[i].task_start_date;
      }
    });
  
    return jobTasksCopy
  }

const checkIfJobCanMove = (job_status) =>{
    return job_status === jobStatusTypesGraphQL.assigned || job_status === jobStatusTypesGraphQL.unassigned;
}
  
const getJobProgressInError = (updateJob) =>{
    let jobProgress = 0;
    if (!!updateJob.job_percentage_complete) {
      jobProgress = updateJob.job_percentage_complete;
    }
  
    return jobProgress;
}
  
const getJobErrorsInError = (updateJobInError) =>{
    const jobErrors = [];
    if (updateJobInError.length > 1) {
      updateJobInError.forEach(job =>{
        if (!jobErrors.includes(job.job_error_number)) {
          jobErrors.push(job.job_error_number);
        }
      });
    }
  
    return jobErrors
}
  
const jobStatusOnJobError = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_status ? updateJob.job_status : jobsCopy[index].status;
}
  
const jobAssignedUserNameOnJobError = (updateJob, jobsCopy, index) =>{
    return !!updateJob.assigned_user_name ?  updateJob.assigned_user_name : jobsCopy[index].job_assigned_user_name;
}
  
const jobAssignedUserIdOnJobError = (updateJob, jobsCopy, index) =>{
    return !!updateJob.assigned_userid ? updateJob.assigned_userid : jobsCopy[index].job_assigned_userid;
}
  
const jobErrorNumberOnJobError = (jobErrors, updateJob) =>{
    return jobErrors.length !== 0 ?  MULTIPLE_ERRORS : updateJob.job_error_number
}
  
const updateJobAttributesInError = ({jobsCopy, index, groups, slotItBelongsIndex, updateJob, jobProgress, jobStartDate, jobEndDate, jobCanMove, jobErrors}) =>{
    jobsCopy[index].group = groups[slotItBelongsIndex].id;
    jobsCopy[index].progress =  jobProgressForJobToUpdate(updateJob, jobProgress, jobsCopy, index);
    jobsCopy[index].status = jobStatusOnJobError(updateJob, jobsCopy, index);
    jobsCopy[index].start_time = startTimeForJobToUpdate(updateJob, jobStartDate, jobsCopy, index);
    jobsCopy[index].end_time = endTimeForJobToUpdate(updateJob, jobEndDate, jobsCopy, index);
    jobsCopy[index].job_start_date = jobStartDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_end_date = jobEndDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_started_date = jobStartedDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_ended_date = jobEndedDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].resource_slotid = jobResourceSlotIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_assigned_user_name = jobAssignedUserNameOnJobError(updateJob, jobsCopy, index);
    jobsCopy[index].job_assigned_userid = jobAssignedUserIdOnJobError(updateJob, jobsCopy, index);
    jobsCopy[index].canMove = jobCanMove;
    jobsCopy[index].job_error_number = jobErrorNumberOnJobError(jobErrors, updateJob);
    jobsCopy[index].job_errors = jobErrors;
    jobsCopy[index].job_mobile_sessionid = mobileSessionIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].unstarted_taskid = unstartedTaskIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_number = jobNumberForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].visible = true;
  
    return jobsCopy;
}

const getJobProgress = (updateJob) =>{
    let jobProgress = 0;
    if (updateJob.job_status === jobStatusTypesGraphQL.complete || updateJob.job_status === jobStatusTypesGraphQL.completeWithError){
      jobProgress = 100;
    }else if (!!updateJob.job_percentage_complete) {
      jobProgress = updateJob.job_percentage_complete;
    }
  
    return jobProgress;
}
  
const updateJobAfterUnassigned = (groups, slotItBelongsIndex, updateJob) =>{
    let jobStatus = updateJob.job_status;
    let jobAssignedUserName = updateJob.assigned_user_name;
    let jobAssignedUserId = updateJob.assigned_userid;
    if (groups[slotItBelongsIndex].status === jobStatusTypesGraphQL.unassigned) {
      jobStatus = jobStatusTypesGraphQL.unassigned;
      jobAssignedUserName = "";
      jobAssignedUserId = "";
    }
  
    return {jobStatus, jobAssignedUserName, jobAssignedUserId}
}
  
const jobProgressForJobToUpdate = (updateJob, jobProgress, jobsCopy, index) =>{
    return !!updateJob.job_status ? jobProgress : jobsCopy[index].progress;
}
  
const startTimeForJobToUpdate = (updateJob, jobStartDate, jobsCopy, index) =>{
    return !!updateJob.job_start_date ? jobStartDate : jobsCopy[index].start_time;
}
  
const endTimeForJobToUpdate = (updateJob, jobEndDate, jobsCopy, index) =>{
    return !!updateJob.job_end_date ? jobEndDate : jobsCopy[index].end_time;
}
  
const jobStartDateForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_start_date ? updateJob.job_start_date : jobsCopy[index].job_start_date;
}
  
const jobEndDateForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_end_date ? updateJob.job_end_date : jobsCopy[index].job_end_date;
}
  
const jobStartedDateForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_started_date ? updateJob.job_started_date : jobsCopy[index].job_started_date;
}
  
const jobEndedDateForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_ended_date ? updateJob.job_ended_date : jobsCopy[index].job_ended_date;
}
  
const jobResourceSlotIdForJobToUpdate = (updateJob, jobsCopy, index) => {
    return !!updateJob.resource_slotid ? updateJob.resource_slotid : jobsCopy[index].resource_slotid;
}
  
const jobErrorNumberForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_error_number ?  updateJob.job_error_number : jobsCopy[index].job_error_number;
}
  
const mobileSessionIdForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_mobile_sessionid ? updateJob.job_mobile_sessionid : jobsCopy[index].job_mobile_sessionid
}
  
const unstartedTaskIdForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.unstarted_taskid ? updateJob.unstarted_taskid : jobsCopy[index].unstarted_taskid;
}
  
const jobNumberForJobToUpdate = (updateJob, jobsCopy, index) =>{
    return !!updateJob.job_number ?  updateJob.job_number : jobsCopy[index].job_number;
}
  
const updateJobAttributes = ({jobsCopy, index, groups, slotItBelongsIndex, updateJob, jobStartDate, jobEndDate, jobCanMove, jobProgress, jobStatus, jobAssignedUserName, jobAssignedUserId}) =>{
    jobsCopy[index].group = groups[slotItBelongsIndex].id;
    jobsCopy[index].progress =  jobProgressForJobToUpdate(updateJob, jobProgress, jobsCopy, index);
    jobsCopy[index].status = jobStatus;
    jobsCopy[index].start_time = startTimeForJobToUpdate(updateJob, jobStartDate, jobsCopy, index);
    jobsCopy[index].end_time = endTimeForJobToUpdate(updateJob, jobEndDate, jobsCopy, index);
    jobsCopy[index].job_start_date = jobStartDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_end_date = jobEndDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_started_date = jobStartedDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_ended_date = jobEndedDateForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].resource_slotid = jobResourceSlotIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_assigned_user_name = jobAssignedUserName;
    jobsCopy[index].job_assigned_userid = jobAssignedUserId;
    jobsCopy[index].canMove = jobCanMove;
    jobsCopy[index].job_error_number = jobErrorNumberForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_mobile_sessionid = mobileSessionIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].unstarted_taskid = unstartedTaskIdForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].job_number = jobNumberForJobToUpdate(updateJob, jobsCopy, index);
    jobsCopy[index].visible = true;
  
    return jobsCopy;
}


const getSlotJobsInProgress = (jobsCopy, groupsCopy, slotItBelongsIndex) =>{
    return jobsCopy.filter(job=>job.resource_slotid === groupsCopy[slotItBelongsIndex].resource_slotid && job.status === jobStatusTypesGraphQL.inProgress);
}
  
const getSlotJobsCompleted = (jobsCopy, groupsCopy, slotItBelongsIndex) =>{
    return jobsCopy.filter(job=>job.resource_slotid === groupsCopy[slotItBelongsIndex].resource_slotid && job.status === jobStatusTypesGraphQL.complete);
}
  
const getSlotJobsWithErrors = (jobsCopy, groupsCopy, slotItBelongsIndex) =>{
    return jobsCopy.filter(job=>job.resource_slotid === groupsCopy[slotItBelongsIndex].resource_slotid && job.status === jobStatusTypesGraphQL.error);
}
  
const getSlotJobsInProgressWithErrors = (jobsCopy, groupsCopy, slotItBelongsIndex) =>{
    return jobsCopy.filter(job=>job.resource_slotid === groupsCopy[slotItBelongsIndex].resource_slotid && job.status === jobStatusTypesGraphQL.inProgressWithError)
}
  
const getSlotJobsCompletedWithErrors = (jobsCopy, groupsCopy, slotItBelongsIndex) =>{
    return jobsCopy.filter(job=>job.resource_slotid === groupsCopy[slotItBelongsIndex].resource_slotid && job.status === jobStatusTypesGraphQL.completeWithError)
}
  
const setSlotStatusFirstScenario = (groupsCopy, slotItBelongsIndex) =>{ 
    groupsCopy[slotItBelongsIndex].canEdit = false;
    if (!!groupsCopy[slotItBelongsIndex].assignedId) {
      groupsCopy[slotItBelongsIndex].status = jobStatusTypesGraphQL.assigned;
    }
    else {
      groupsCopy[slotItBelongsIndex].status = jobStatusTypesGraphQL.unassigned;
    }
  
    return groupsCopy;
}
  
const setSlotStatusSecondScenario = (groupsCopy, slotItBelongsIndex) =>{
    groupsCopy[slotItBelongsIndex].status = jobStatusTypesGraphQL.inProgress;
    groupsCopy[slotItBelongsIndex].canEdit = false;
  
    return groupsCopy;
}
  
const checkIfSlotContainsJobsInFirstScenario = (slotJobsCompleted, slotJobsError, slotJobsCompletedWithErrors ) =>{
    // For Completed / Completed with errors / Error jobs
    return slotJobsCompleted.length !== 0 || slotJobsError.length !== 0 || slotJobsCompletedWithErrors.length !== 0;
}
  
const checkIfSlotContainsJobsInSecondScenario = (slotJobsInProgress, slotJobsInProgressWithErrors ) =>{
    // For InProgress / InProgress with error jobs
    return slotJobsInProgress.length !== 0 || slotJobsInProgressWithErrors.length !== 0;
}