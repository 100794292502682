export const floorColorPalette = {
    primary: {
        0: '#64A540', // L-B ord -2
        1: '#4571B4', // L-1  ord 0
        2: '#CF4D9A', // L-2 ord 1
        3: '#265427', // L-3
        4: '#6E62EF', // L-4
        5: '#FF6E00', // L-5
        6: '#192CD8', // L-6
        7: '#86539F', // L-7
        8: '#E51573', // L-8
        9: '#00857D', // L-9
        10: '#DC7A18', // L-10
        11: '#11577E', // L-11
        12: '#FF5786', // L-12
        13: '#6E7D40', // L-13
        14: '#495DA7', // L-14
        15: '#BB9611', // L-15
        16: '#660000', // L-16
        17: '#5B878F', // L-17   ord 16
        18: '#757575', // GPS
        19: '#A8242B', // L-E ord -1
        20: '#00005C', // ord -3
        21: '#A621AF' // ord -4
    },
};

export const env = {
    localhost: 'localhost',
    devLahey: 'dev-lahey.navvtrack.com',
    testHfhs: 'test-hfhs.navvtrack.com',
    prod: 'henryfordhealth.navvtrack.com'
}