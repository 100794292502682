import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import contactIcon from "../../../img/contactIcon.png";
import broadCast from "../../../img/broadCast.png";
import wayfinding from "../../../img/wayfindingIcon.png";
import workflowAutomation from "../../../img/workflowAutomation.svg";
import LeftNav from '../leftNav/leftnav.component';
import { useStyles } from "./sideBar.styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SvgIcon from "@material-ui/core/SvgIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';

import {
  changeView,
  changePeopleFilter,
  changeEquipmentFilter,
  openBroadcastChat,
  changeZoomToUser,
  changeZoomToEquipment
} from '../../../actions/views/viewsActions';
import '../dashboard.component';

const determineCurrentTabSelected = (props) =>{
  let currentValue = "a";

  if (props.currentMenuValue === "HT"){
    currentValue = "b";
  }
  else if (props.currentMenuValue === 'WF'){
    currentValue = "e";
  }

  return currentValue;
}

const VerticalTabs = (props) => {
  const classes = useStyles();
  const currentTabToOpen = determineCurrentTabSelected(props);
  const usersListState = props.currentView === 'map' ? true : false;
  const [value, setValue] = useState(currentTabToOpen);
  const [itemSelected, setItemSelected] = useState("");
  const [usersFilter, setUsersFilter] = useState(props.peopleFilter);
  const [equipmentFilter, setEquipmentFilter] = useState(props.equipmentFilter);
  const [usersListOpen, setUsersListOpen] = useState(usersListState);

  useEffect(() => {
    if (props.remoteSelectTab.hasOwnProperty('view'))
      handleChangeView(props.remoteSelectTab.view, props.remoteSelectTab.viewProp, props.remoteSelectTab.item);
  }, [props.remoteSelectTab]);

  useEffect(() => {
    if (props.currentView === "map") {
      setValue("a");
      setUsersListOpen(true);
    } else {
      setUsersListOpen(false);
    }
  }, [props.currentView]);

  useEffect(() => {
    if (props.viewProps === "transporter" || props.viewProps === 'transporterPhone') setUsersListOpen(false);
    if (props.viewProps === "") setUsersListOpen(true);
  }, [props.viewProps]);

  const handleChange = (event, newValue) => {
    if (newValue === 'c') {
      props.openBroadcastChat(true);
    }
    else {
      setValue(newValue);
      if (props.openNav === false) props.handleToggle();
      if (newValue === "a") {
        //Update current value selected
        props.setCurrentMenuVal("");
        setItemSelected("");
      }

    }

  };

  const handleChangeView = (view, viewProp, item) => {
    setItemSelected(item);
    props.changeView(view, viewProp);
    //Update current value selected
    props.setCurrentMenuVal(item);
  };

  const handlePeopleMapFilter = () => {
    // props.changeZoomToUser({ userId: false, deviceId: '' });
    props.changePeopleFilter(!usersFilter);
    setUsersFilter(!usersFilter);
  };

  const handleEquipmentFilter = () => {
    props.changeZoomToEquipment("");
    props.changeEquipmentFilter(!equipmentFilter);
    setEquipmentFilter(!equipmentFilter);
  };

  const openBroadcastChatWindow = (item) => {
    setItemSelected(item);
    props.openBroadcastChat(true);
  };

  const handleToggleUsersList = (value) => {
    setUsersListOpen(value);
  };

  return (
    <div style={{ zIndex: "999", display: "flex" }}>
      <div
        id="webNavv-sidebarContainer-1.0"
        className={classes.root}
      >
        {props.aclViews !== undefined && (
          <React.Fragment>
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              className={props.openNav ? classes.tabs : classes.tabsClosed}
              classes={
                props.openNav
                  ? { indicator: classes.indicatorOpen }
                  : { indicator: classes.indicatorClosed }
              }
              TabIndicatorProps={{ children: <div /> }}
            >
              <Tab
                id="webNavv-navvbar-activeMap-1.0"
                value="a"
                index={0}
                className={classes.firstSectionTabs}
                icon={
                  <Tooltip title="Active Map">
                    <LocationOnIcon style={{ fill: "#8AA4FF" }} />
                  </Tooltip>
                }
                aria-label="activeMap-tab"
                onClick={() => {
                  props.changeView("map", "");
                }}
              />
              <Tab
                id="webNavv-navvbar-tableView-1.0"
                value="b"
                index={1}
                className={classes.firstSectionTabs}
                icon={
                  <Tooltip title="Table View">
                    <TableChartIcon style={{ fill: "#8AA4FF" }} />
                  </Tooltip>
                }
                aria-label="tableView-tab"
              />
              <Tab
                id="webNavv-navvbar-navvCast-1.0"
                value="c"
                index={2}
                className={classes.firstSectionTabs}
                icon={
                  <Tooltip title="NavvCast">
                    <>
                      {props.unreadBroadcast && <div className={classes.navUnreadDot}></div>}
                      <img
                        src={broadCast}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "2px",
                          display: "table-cell",
                        }}
                        alt="broadCastIcon"
                      />
                    </>
                  </Tooltip>
                }
                aria-label="navvCast-tab"
              />
              {props.aclViews[9].access &&
                <Tab
                  id="webNavv-navvbar-contactPanel-1.0"
                  value='d'
                  index={3}
                  className={classes.firstSectionTabs}
                  icon={
                    <Tooltip title="Contact Panel">
                      <img
                        src={contactIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '2px',
                          display: 'table-cell'
                        }}
                        alt="contactIcon"
                      />
                    </Tooltip>
                  }
                  aria-label="contact-tab" />
              }
              {!!props.aclViews[13] && props.aclViews[13].access && props.featureFlags.SpecimenCollection.Permission &&
                <Tab
                  id="webNavv-navvbar-workflowAutomation-1.0"
                  value='e'
                  index={3}
                  className={classes.firstSectionTabs}
                  icon={
                    <Tooltip title="Workflow Automation">
                      <img
                        src={workflowAutomation}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '2px',
                          display: 'table-cell'
                        }}
                        alt="workflowAutomationIcon"
                      />
                    </Tooltip>
                  }
                  aria-label="workflowAutomation-tab" />
              }
              {!!props.aclViews[11] && props.aclViews[11].access && (
                <Tab
                  id="webNavv-navvbar-wayfinding-1.0"
                  value='f'
                  index={6}
                  className={classes.firstSectionTabs}
                  icon={
                    <Tooltip title="Wayfinding">
                      <img
                        src={wayfinding}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "2px",
                          display: "table-cell",
                        }}
                        alt="wayfinding"
                      />
                    </Tooltip>

                  }
                  aria-label="wayfinding-tab" />
              )}
              {!!props.aclViews[0] && props.aclViews[0].access && (
                <Tab
                  id="webNavv-navvbar-adminPanel-1.0"
                  value="g"
                  index={4}
                  className={classes.secondSectionTabs}
                  icon={
                    <Tooltip title="Admin Panel">
                      <SvgIcon style={{ fill: "#8AA4FF" }}>
                        <path d="M15 6a1.54 1.54 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0A1.54 1.54 0 0 1 15 6zm-1.5-5A5.55 5.55 0 0 0 8 6.5a6.81 6.81 0 0 0 .7 2.8L1 17v2h4v-2h2v-2h2l3.2-3.2a5.85 5.85 0 0 0 1.3.2A5.55 5.55 0 0 0 19 6.5 5.55 5.55 0 0 0 13.5 1z" />
                      </SvgIcon>
                    </Tooltip>
                  }
                  aria-label="adminPanel-tab"
                />
              )}
              {!!props.aclViews[10] && props.aclViews[10].access && (
                <Tab
                  id="webNavv-navvbar-ConfigPanel-1.0"
                  value="h"
                  index={5}
                  className={classes.secondSectionTabs}
                  icon={
                    <Tooltip title="Configuration Panel">
                      <SettingsIcon style={{ fill: "#8AA4FF" }} />
                    </Tooltip>
                  }
                  aria-label="config-tab"
                />
              )}
              {/* <Tab value='g' index={7} className={classes.secondSectionTabs} icon={<LanguageIcon style={{fill:'#8AA4FF'}}/>} 
          aria-label="multiCampus"  />  */}
            </Tabs>
            <LeftNav
              value={value}
              handleToggle={props.handleToggle}
              usersFilter={usersFilter}
              handlePeopleMapFilter={handlePeopleMapFilter}
              equipmentFilter={equipmentFilter}
              handleEquipmentFilter={handleEquipmentFilter}
              itemSelected={itemSelected}
              handleChangeView={handleChangeView}
              openBroadcastChatWindow={openBroadcastChatWindow}
              usersListOpen={usersListOpen}
              handleToggleUsersList={handleToggleUsersList}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  userInfo: state.auth.userInfo,
  users: state.user.users,
  aclViews: state.auth.acl.views,
  currentView: state.views.currentView,
  openNav: state.views.drawerOpen,
  viewProps: state.views.viewProps,
  peopleFilter: state.views.peopleMapFilter,
  equipmentFilter: state.views.equipmentMapFilter,
  currentMenuValue: state.views.currentMenuValue,
  remoteSelectTab: state.views.remoteSelectTab,
  unreadBroadcast: state.views.unreadBroadcast,
  featureFlags: state.auth.featureFlags
})

export default connect(mapStateToProps, {
  changeView,
  changePeopleFilter,
  changeEquipmentFilter,
  openBroadcastChat,
  changeZoomToUser,
  changeZoomToEquipment
})(VerticalTabs);