import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {useStyles} from '../../sideBar/sideBar.styles';

function MasterTab(props){
    const classes = useStyles();
    const [showWayfindingError, setShowWayfindingError] = useState(false);

    useEffect(()=>{
      if(props.campusInfo && !props.campusInfo.mappedIn){
        setShowWayfindingError(true);
      }
    },[]);

    const handleClick = (action, view, viewProp, label) =>{
      if(action === 'changeView'){
        props.handleChangeView(view, viewProp, label);
      }
      if(action === 'openBroadcast'){
        props.openBroadcastChatWindow(label);
      }
    };

    const renderWayfindingTabs = ()=>{
      const {tabs} = props;
      return(
        <div id="wayfindingTab-container">
          {showWayfindingError?
          (
            <div id="wayfindingError-tab">
              <div
              id="webNavv-sidebarContainer-navigation-wayfindingError-tab-1.0"
              key={tabs[0].key}
              className='ButtonItem_Container'
              style={{ marginTop: "10px", width:'100px' }}
              onClick={()=>props.handleEquipmentFilter()}>
                <div className='ButtonItem_Text'>{tabs[0].primaryText}</div>
              </div>
            </div>
          )
          :(
            <div id="wayfinding-tab">
              <div 
              id="webNavv-sidebarContainer-navigation-wayfinding-tab-1.0"
              key={tabs[1].key}
              className={
                props.itemSelected === tabs[1].label ||
                props.currentMenuValue === tabs[1].label
                  ? "ButtonItem_ContainerSelected"
                  : "ButtonItem_Container"
              }
              style={{ marginTop: "10px" }}
              onClick={() => { handleClick(tabs[1].action, tabs[1].view, tabs[1].viewProp, tabs[1].label)}}>
                <div>{tabs[1].leftIcon}</div>
                <div className='ButtonItem_Text'>{tabs[1].primaryText}</div>
              </div>
            </div>
          )
          }
        </div>
      );
    }

    const checkItemType = (item) =>{
      if (item.type === 'icon') {
        return(
          <div 
          id={`${item.key}-container`}
          key={item.key}>
            <div 
              id={`webNavv-sidebarContainer-navigation-${item.key}-1.0`}
              key={item.key}
              className={
                props.itemSelected === item.label ||
                props.currentMenuValue === item.label
                  ? "ButtonItem_ContainerSelected"
                  : "ButtonItem_Container"
              }
              style={{ marginTop: "10px" }}
              onClick={() => { handleClick(item.action, item.view, item.viewProp, item.label) }}>
                <div>{item.leftIcon}</div>
                <div className='ButtonItem_Text'>{item.primaryText}</div>
            </div>
          </div>
        );
      }
      if (item.type === 'svg') {
        return(
          <div
          id={`${item.key}-container`}
          key={item.key}>
            <div 
              id={`webNavv-sidebarContainer-navigation-${item.key}-1.0`}
              key={item.key}
              className={
                props.itemSelected === item.label
                  ? "ButtonItem_ContainerSelected"
                  : "ButtonItem_Container"
              }
              style={{ marginTop: "10px" }}
              onClick={() => { handleClick(item.action, item.view, item.viewProp, item.label) }}>
                <div>{props.itemSelected === item.label ?
                 (<img src={item.src1} className={classes.selectedSVG} alt={item.alt1}/>)
                 : (<img src={item.src2} className={classes.svg} alt={item.alt2} />)}
                </div>
                <div className='ButtonItem_Text'>{item.primaryText}</div>
            </div>
          </div>
        );
      }
    }

    const checkForTeamManagerTab = (item) => {
      return(item.viewProp === "teamManager" && props.aclViews && props.aclViews[3].access === true)
    }

    const checkForFleetManagerTab = (item) => {
      return(item.viewProp === "fleetManager" && props.aclViews && props.aclViews[7].access === true)
    }

    const checkForHistoryTab = (item) => {
      return(item.viewProp === "history" && props.aclViews && props.aclViews[4].access === true)
    }

    const checkForJobsBoardTab = (item) =>{
      return(item.viewProp === "jobsBoard"  && props.featureFlags.SpecimenCollection.JobsBoard === true)
    }

    const checkForJobsHistoryTab = (item) =>{
      return(item.viewProp === "jobsHistory"  && props.featureFlags.SpecimenCollection.JobsHistory === true)
    }

    const checkForTableViewEquipmentTab = (item) =>{
      return(item.viewProp === "equipmentHistory" && props.aclViews && props.aclViews[8].access && props.featureFlags.Equipment.TableViewTable)
    }

    const checkForAdminEquipmentTab = (item) =>{
      return(item.viewProp === "equipment" && props.aclViews && props.aclViews[8].access && props.featureFlags.Equipment.AdminTable)
    }

    const checkForTabs = (item) =>{
      return( (item.viewProp !== "equipmentHistory" && item.viewProp !== "equipment"
              && item.viewProp !== "teamManager" && item.viewProp !== "fleetManager"
              && item.viewProp !== "history" && item.viewProp !== "jobsBoard"
              && item.viewProp !== "jobsHistory"));
    }

    const renderTabs = () => {
        return(
        <div id="regularTabs-container">
            {props.tabs.map(item=>{
              if ( checkForJobsBoardTab(item) || checkForTableViewEquipmentTab(item)
              || checkForTeamManagerTab(item) || checkForFleetManagerTab(item)
              || checkForHistoryTab(item) || checkForAdminEquipmentTab(item)
              || checkForJobsHistoryTab(item) ) {
                return(checkItemType(item))
              }
              else if (checkForTabs(item)) {
                return(checkItemType(item))
              }
            })}
        </div>
        );
    }

    return(
      <div id="masterTab-container">
        <div
        id={props.tabId}
        key={props.tabTitle+'-title'}>
          <p className="TabFilterDescription">{props.tabTitle}</p>
        </div>
        {props.tabTitle!=='Wayfinding'? renderTabs() : renderWayfindingTabs()}
      </div>
    );
};

export default MasterTab;