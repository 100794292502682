import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from "clsx";
import moment from 'moment'
import Moment from 'react-moment';

import { withStyles } from '@material-ui/core/styles';
import SendIcon from 'material-ui/svg-icons/content/send';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MinimizeIcon from '@material-ui/icons/Minimize';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';

import './chat.window.css';


//import Notify from 'notifyjs';
let socket;
class ChatWindow extends React.Component {
  constructor(props) {
    super(props);
    socket = this.props.socket;
    this.state = {
      open: this.props.chat.open,
      messages: [],
      dialogOpen: false,
      dialogError: '',
      unconfirmedMessages: [],
      loadingMessages: true,
      newMessage: false,
      typing: false
    };
    socket.on('connect', () => {
      try {
        this.getMessageHistory();
      } catch (error) {
        console.warn("An error occurred trying to load message history", error);
      }
    });
    socket.on('new message', message => { this.appendMessage(message.newMessage) });
    socket.on('watching typing', chat => { this.handleTyping(chat) });
    //socket.on('message sent', () => { this.onMessageSent() });
    socket.on('message history seen', destiny => { this.handleHistorySeen(destiny) });
  }

  getMessageHistory = () => {
    socket.emit('messages', {
      destiny: this.props.chat.destiny,
      origin: this.props.chat.origin,
    });
  }

  handleTyping = (chat) => {
    const { origin, destiny } = this.props.chat;

    if (chat.origin === destiny && chat.destiny === origin) {
      this.setState({ typing: true });
      setTimeout(() => {
        this.setState({ typing: false });
      }, 2500);
    }
  }

  handleHistorySeen = (userDestiny) => {
    const { origin, destiny } = this.props.chat;

    //console.log(`[handleHistorySeen] origin: ${origin} destiny: ${destiny}  userDestiny: ${userDestiny}`);
    if (destiny === userDestiny) {
      let messages = this.state.messages.map((msg) => ({ ...msg, seen: true }));
      this.setState({ messages });
    }
  }

  appendMessage(message) {
    const { origin, destiny } = this.props.chat;

    if ((message.origin === destiny && message.destiny === origin) || (message.origin === origin && message.destiny === destiny)) {
      const { messages } = this.state;

      messages.push(message);
      this.scrollToBottom();
      this.setState({ messages, open: true, newMessage: true });
    }
  }

  toggleClass() {
    this.setState({ open: !this.state.open });
  }

  UNSAFE_componentWillMount() {
    /*
    socket.emit('messages', {
      destiny: this.props.chat.destiny,
      origin: this.props.chat.origin,
    });
    */
    try {
      this.getMessageHistory();
    } catch (error) {
      console.warn("An error occurred trying to load message history", error);
    }

    socket.on('messages', messages => { this.onMessages(messages) });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  sendChatMessage() {
    if (this.state.currentMessage.length) {
      /** 
      const unconfirmed = [];
      unconfirmed.push({
        text: this.state.currentMessage,
        date: new Date(),
        destiny: this.props.chat.destiny,
        origin: this.props.chat.origin,
      });
      this.props.sendMessage({
        destiny: this.props.chat.destiny,
        currentMessage: this.state.currentMessage,
      });
      this.setState({
        unconfirmedMessages: unconfirmed,
        currentMessage: '',
      });
      */

      let message = {
        text: this.state.currentMessage,
        date: new Date(),
        creationDate: new Date(),
        destiny: this.props.chat.destiny,
        origin: this.props.chat.origin,
      };
      const messages = [...this.state.messages, message];
      this.props.sendMessage({
        destiny: this.props.chat.destiny,
        currentMessage: this.state.currentMessage
      });
      this.setState({ messages, currentMessage: '' });

    }
  }

  onMessageSent() {
    const { unconfirmedMessages } = this.state;
    if (unconfirmedMessages.length) {
      this.appendMessage(unconfirmedMessages.pop());
      this.setState({ unconfirmedMessages });
    }
  }

  onMessages(req) {
    if (!!req.messages.length) {
      if (
        (req.messages[0].origin === this.props.chat.origin &&
          req.messages[0].destiny === this.props.chat.destiny) ||
        (req.messages[0].destiny === this.props.chat.origin &&
          req.messages[0].origin === this.props.chat.destiny)
      ) {
        //socket.emit('set message read', this.props.chat.destiny);
        this.setState({ loadingMessages: false, messages: [...req.messages] });
      }
    }
    else {
      this.setState({ loadingMessages: false });
    }
  }

  sortMsgsDate(acc, cont) {
    return new Date(cont.creationDate) - new Date(acc.creationDate)
  }

  renderMessages(classes) {
    if (this.state.messages.length > 0) {
      let messages = [];
      //send it to notification
      //this.handleNotification();

      this.state.messages.map(msg => {
        const dateInfo = !!msg.creationDate ? moment(msg.creationDate).format("MM/DD/YYYY LT") : moment(msg.date).format("MM/DD/YYYY LT");
        if (msg.origin === this.props.userId)
          messages.push(
            <div className={classes.bubbleContainer} key={msg.creationDate}>
              <div className={clsx(classes.messageBubble, classes.messageSent)}>{msg.text}</div>
              <div className={classes.timeSent}>
                {msg.seen ? 'Seen ' : ''}
                {dateInfo}
                {/* <Moment format="MM/DD/YYYY hh:mm a" date={msg.creationDate} /> */}
              </div>
            </div>,
          );
        else
          messages.push(
            <div className={classes.bubbleContainer} key={msg.creationDate}>
              <div className={clsx(classes.messageBubble, classes.messageGot)}>{msg.text}</div>
              <div className={classes.timeGot}>
                {dateInfo}
                {/* <Moment format="MM/DD/YYYY hh:mm a" date={msg.creationDate} /> */}
              </div>
            </div>,
          );
        //return false;
      });
      /**
      this.state.unconfirmedMessages.map(msg => {
        const dateInfo = !!msg.creationDate ? moment(msg.creationDate).format("MM/DD/YYYY LT") : moment(msg.date).format("MM/DD/YYYY LT");
        messages.push(
          <div className={clsx(classes.bubbleContainer, classes.unconfirmed)} key={msg.creationDate}>
            <div className={clsx(classes.messageBubble, classes.messageSent)}>{msg.text}</div>
            <div className={classes.timeSent}>
              {dateInfo}
            </div>
          </div>,
        );
      });
      */
      return messages;
    } else if (this.state.loadingMessages) {
      return (
        <div id="webNavv-navvChat-expanded-messages-loading-1.0">
          <div className={clsx(classes.bubbleContainer, classes.empty)}>
            <div className={clsx(classes.messageBubble, classes.messageGot)}></div>
            <div className={classes.timeGot}></div>
          </div>
          <div className={clsx(classes.bubbleContainer, classes.empty)}>
            <div className={clsx(classes.messageBubble, classes.messageSent)}></div>
            <div className={classes.timeSent}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="webNavv-navvChat-expanded-messages-noMessages-1.0"
          className={classes.noMessages}
        >
          No Messages Yet
        </div>
      );
    }
  }

  removeChat() {
    socket.emit('set message read', this.props.chat.destiny);
    this.props.removeChat(this.props.chat.destiny);
  }

  renderMinizeOrClose(classes) {
    if (this.state.open)
      return (
        <div className={classes.chatAction} >
          <div
            id="webNavv-navvChat-expanded-header-minimize-1.0"
            onClick={() => { this.toggleClass(); }}
          >
            <MinimizeIcon style={{ fontSize: 15 }} />
          </div>
          <div
            id="webNavv-navvChat-expanded-header-close-1.0"
            className={classes.closeAction}
            onClick={() => { this.removeChat(); }}
          >
            <CloseIcon style={{ fontSize: 15 }} />
          </div>
        </div>
      );
    else
      return (
        <div className={classes.chatAction} >
          <div
            id="webNavv-navvChat-expanded-header-launch-1.0"
            onClick={() => { this.toggleClass(); }}
          >
            <LaunchIcon style={{ fontSize: 15 }} />
          </div>
          <div
            id="webNavv-navvChat-expanded-header-close-1.0"
            className={classes.closeAction}
            onClick={() => { this.removeChat(); }}
          >
            <CloseIcon style={{ fontSize: 15 }} />
          </div>
        </div>
      );
  }

  handleClose() {
    window.location.href = '/';
  }

  scrollToBottom() {
    const scrollHeight = this.el && this.el.scrollHeight;
    const height = this.el && this.el.clientHeight;
    const maxScrollTop = scrollHeight - height;
    if (this.el) {
      this.el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.sendChatMessage();
    }
    else {
      socket.emit('typing', { destiny: this.props.chat.destiny, origin: this.props.chat.origin });
    }
  };

  handleCheckHiddenApp() {
    var visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      visibilityChange = document.hidden;
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = document.msHidden;
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = document.webkitHidden;
    }
    return visibilityChange;
  }

  handleNotification() {
    try {
      let checkAppHidden = this.handleCheckHiddenApp();
      if (checkAppHidden) {
        let sizeMsg = this.state.messages.length;
        let lastMessage = this.state.messages[sizeMsg - 1];
        // const myNotification = new Notify(this.props.chatTittle, {
        //   body: lastMessage.text,
        //   tag: 'newMessage',
        //   timeout: 1,
        // });
        //myNotification.show();
      }
    } catch (error) {
      console.error('[CHATWINDOW] An error occurred with handle notifications', error);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.newMessage && !this.state.messages.length) {
      return true;
    }
    if (this.state.newMessage) {
      return true;
    }
    if (this.state.open) {
      return true;
    }
    if (this.state.messages) {
      return true;
    }
    if (this.state.typing) {
      return true;
    }
    return false;
  }

  handleRenderTyping() {
    const { classes } = this.props;
    return (
      <div
        id="webNavv-navvChat-expanded-messages-typing-1.0"
        className={classes.typing}
      >
        Typing...
      </div>
    );
  }

  render() {
    const actions = [
      <FlatButton label="Ok" primary={true} onClick={this.handleClose} />,
    ];
    const { classes } = this.props;

    return (
      <div
        className={classes.chatWindow}
        id="webNavv-navvChat-expanded-1.0"
      >
        <div
          className={classes.chatNormal}
          id="webNavv-navvChat-expanded-header-1.0"
        >
          <p id="webNavv-navvChat-expanded-header-title-1.0">
            {this.props.chatTittle}
          </p>
          {this.renderMinizeOrClose(classes)}
        </div>
        <div
          id="webNavv-navvChat-expanded-messages-1.0"
          className={this.state.open ? classes.chatOpen : classes.chatClosed}
        >
          <div
            id="webNavv-navvChat-expanded-messages-history-1.0"
            className={classes.messageView}
            ref={el => {
              this.el = el;
            }}
          >
            {this.renderMessages(classes)}
            {this.state.typing ? this.handleRenderTyping() : null}
          </div>
          <div className={classes.messageBox}>
            <div className={clsx(classes.searchWrapper, classes.chatWindowSize)}>
              <input
                id="webNavv-navvChat-expanded-messages-editor-1.0"
                placeholder="Type Message"
                type="text"
                className={classes.input}
                onChange={input => {
                  this.setState({ currentMessage: input.target.value })
                }
                }
                value={this.state.currentMessage}
                onKeyPress={this.enterPressed.bind(this)}
              />
            </div>
            <div
              id="webNavv-navvChat-expanded-messages-sender-1.0"
              className={classes.messageSendButton}
              onClick={this.sendChatMessage.bind(this)}
            >
              <SendIcon />
            </div>
          </div>
        </div>
        <Dialog
          title="Session Terminated"
          actions={actions}
          modal={true}
          open={this.state.dialogOpen}
        >
          {this.state.dialogError}
        </Dialog>
      </div>
    );
  }
}

const styles = (theme) => ({
  chatWindow: {
    backgroundColor: '#FFF',
    width: 264,
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2)',
    alignSelf: 'end',
    direction: 'ltr',
    borderRadius: '10px 10px 0px 0px'
  },
  chatOpen: {
    height: 448,
    overflowY: 'auto'
  },
  chatClosed: {
    display: 'none',
  },
  chatHeader: {
    height: 48,
    backgroundColor: '#2843A3',
    color: '#FFF',
    textAlign: 'left',
    paddingTop: 1,
    paddingLeft: 16,
    fontSize: 16,
    lineHeight: 24,
    borderBottom: '1px solid rgba(0,0,0,0.2)'
  },
  chatNormal: {
    height: 48,
    backgroundColor: '#2843A3',
    color: '#FFF',
    fontWeight: 'bold',
    textAlign: 'left',
    borderRadius: '10px 10px 0px 0px',
    fontSize: 16,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    display: 'grid',
    gridTemplateColumns: '190px 40px',
    //gridColumnGap: 10,
    paddingLeft: 16
  },
  closeAction: {
    marginLeft: 10
  },
  chatAction: {
    display: 'flex',
    cursor: 'pointer',
    paddingTop: 15
  },
  messageView: {
    backgroundColor: '#FFF',
    height: 390,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    overflowY: 'auto',
    paddingTop: 10,
    paddingRight: 5,
  },
  messageBox: {
    backgroundColor: '#FFF',
    height: 32,
    padding: 8,
    position: 'fixed',
    bottom: 0
  },
  bubbleContainer: {
    bottom: 20
  },
  messageBubble: {
    minWidth: 40,
    maxWidth: 200,
    bordeRadius: '10px!important',
    padding: 10,
    overflowWrap: 'break-word',
    marginBottom: 8,
  },
  messageSent: {
    marginLeft: 30,
    backgroundColor: '#2843A3',
    color: '#FFF',
    position: 'relative',
    borderRadius: 25,
    "&:before": {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 180,
      width: 0,
      borderTop: '7px solid #2843A3',
      borderLeft: '7px solid transparent',
      borderright: '7px solid transparent',
    }
  },
  messageGot: {
    marginLeft: 5,
    color: '#000',
    backgroundColor: '#F4F4FF',
    position: 'relative',
    borderRadius: 25,
    "&:before": {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 20,
      width: 0,
      borderTop: '7px solid #F4F4FF',
      borderLeft: '7px solid transparent',
      borderright: '7px solid transparent',
    }
  },
  timeGot: {
    fontSize: 11,
    marginBottom: 8,
    textAlign: 'left',
    marginLeft: 10,
    color: 'gray',
  },
  timeSent: {
    fontSize: 11,
    marginBottom: 8,
    textAlign: 'right',
    marginRight: 8,
    color: 'gray'
  },
  typing: {
    fontSize: 11,
    marginBottom: 8,
    textAlign: 'right',
    marginRight: 8,
    color: 'gray',
    float: 'left',
    marginLeft: 10
  },
  noMessages: {
    paddingTop: 30,
    width: '100%',
    textAlign: 'center',
    color: 'gray',
  },
  unconfirmed: {
    opacity: 0.5,
  },
  searchBox: {
    backgroundColor: '#FFF',
    height: 32,
    padding: 8,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  searchInput: {
    backgroundColor: 'rgba(0,0,0,0.04)',
    width: 246,
    height: '100%',
    float: 'left'
  },
  messageInput: {
    backgroundColor: 'rgba(0,0,0,0.04)',
    width: 200,
    height: '115%',
    float: 'left',
  },
  messageSendButton: {
    backgroundColor: 'rgba(0,0,0,0.0)',
    width: 30,
    height: '91%',
    float: 'left',
    marginLeft: 10,
    paddingLeft: 6.8,
    paddingTop: 7,
    cursor: 'pointer'
  },
  searchWrapper: {
    width: 190,
    float: 'left',
    height: 36,
    backgroundColor: '#f4f4ff',
    borderRadius: 10,
    color: '#000',
    display: 'flex',
    alignItems: 'center'
  },
  chatWindowSize: {
    paddingLeft: 10
  },
  input: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0,
    height: 30,
    "&:focus": {
      outline: 'none'
    }
  }
});

ChatWindow.propTypes = {
  userId: PropTypes.string.isRequired,
  access_token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userId: state.auth.userId,
  access_token: state.auth.token,
});

export default connect(mapStateToProps, {})(withStyles(styles)(ChatWindow));

