import {SAVE_LASTLOCATION_FROM_SOCKET} from '../actions/lastlocation/types';

const initialState = {
    lastlocationSocket:{}
}

export default function( state = initialState, action){
    switch(action.type){
        case SAVE_LASTLOCATION_FROM_SOCKET:
            return{
                ...state,
                lastlocationSocket: action.payload
            }
        default:
            return state;
    }
}