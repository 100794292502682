import { makeStyles } from '@material-ui/core/styles';
import lightBlue from "@material-ui/core/colors/lightBlue";

export const useStyles = makeStyles((theme) => ({
    chipContainer: {
        marginLeft:'77%',
        bottom:'60%',
        position:'relative',
        height:'15px'
    },
    regularContainer: {
        display:'block',
        height:'15px'
    },
    containerInlineBlock: {
        display:'inline-block'
    },
    roleToUpdateVariant: {
        display:'inline-block',
        marginLeft:'2px'
    },
    teamBtn:{
        "&:hover": {
          backgroundColor: lightBlue[50],
          cursor:'pointer',
          color: '#2196F3',
          "@media (hover: none)": {
            backgroundColor: lightBlue[50]
          }
        }
    },
    regularContainerVariant: {
        display:'block',
        height:'25px'
    },
    showTeamsRoleContainer: {
        display:'inline-block',
        width:'72%'
    },
    showTeamRoleLbl: {
        marginLeft:'20px',
        fontSize:'17px'
    },
    showTeamChip: {
        backgroundColor:'transparent',
        color:'#2843A3'
    },
    regularContainerComplex: {
        display:'block',
        height:'15px',
        marginTop:'7px'
    },
    regularContainerMini: {
        display:'inline-block',
        marginLeft:'2px'
    },
}));
