import React, { useState } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useStyles, AntTab, AntTabs } from './equipmentInfo.styles';
import EquipmentForm from './equipmentInfoForm/equipmentForm.component';
import MasterButton from '../../../utilities/button.component';
import { getEquipment, storeEquipment, updateEquipment, updateEquipmentBadge } from '../../../../actions/equipment/equipmentActions';
import AwesomeSpinner from '../../../utilities/spinner';

function EquipmentInfo(props) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('a');
  const [showSpinner, setShowSpinner] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [equipment, setEquipment] = useState({
    deviceCategory: props.isUpdate ? props.equipmentSelected.equipment : '',
    UUID: props.isUpdate ? props.equipmentSelected.UUID : '',
    minor: props.isUpdate ? props.equipmentSelected.minor : 0,
    major: props.isUpdate ? props.equipmentSelected.major : 0,
    controlNum: props.isUpdate ? props.equipmentSelected.controlNum : '',
    beaconNum: props.isUpdate ? props.equipmentSelected.beaconNum : '',
    model: props.isUpdate ? props.equipmentSelected.model : '',
    maker: props.isUpdate ? props.equipmentSelected.maker : '',
    team: props.isUpdate ? props.equipmentSelected.team : '',
    department: props.isUpdate ? props.equipmentSelected.department : '',
    id: props.isUpdate ? props.equipmentSelected.id : '',
    creationDate: props.isUpdate ? props.equipmentSelected.creationDate : '',
    modificationDate: props.isUpdate ? props.equipmentSelected.modificationDate : '',
    trackingId: props.isUpdate ? props.equipmentSelected.trackingId : ''
  });
  const [badgeInfo, setBadgeInfo] = useState({
    UUID: props.isUpdate ? props.equipmentSelected.UUID : '',
    minor: props.isUpdate ? props.equipmentSelected.minor : 0,
    major: props.isUpdate ? props.equipmentSelected.major : 0,
  });
  const [formError, setFormError] = useState({
    error: false,
    deviceCategory: '',
    UUID: '',
    minor: '',
    major: '',
    controlNum: '',
    beaconNum: '',
    model: '',
    maker: '',
    team: '',
    department: '',
    trackingId: ''
  });

  const changeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (newValue, key) => {
    if (key !== 'trackingId') {
      setEquipment({ ...equipment, [key]: newValue });
    } else {
      setEquipment({ ...equipment, [key]: newValue.toLowerCase() });
    }

    if (newValue === '' && key !== 'trackingId')
      setFormError({ ...formError, [key]: "Field can't be empty!" });
    if (newValue !== '' && key !== 'trackingId')
      setFormError({ ...formError, [key]: '' });
    if ((key === 'major' || key === 'minor') && (equipment[key] >= 0 && equipment[key] <= 65535))
      setFormError({ ...formError, [key]: '' });
  };

  const handleSubmit = () => {
    let error = validateForm();
    if (!error) {
      setShowSpinner(true);
      setIsActionInProgress(true);
      if (!props.isUpdate) {
        saveEquipment();
      } else {
        editEquipment();
      }
    }
  };

  const handlePerformedBy = () => {
    const { user, userId } = props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  const handleModifiedBy = () => {
    const { user, userId } = props;
    let modifiedBy = '';
    if (user && user.id) {
      modifiedBy = user.id;
    } else if (userId) {
      modifiedBy = userId;
    }
    return modifiedBy;
  }

  const validateForm = () => {
    let keys = ['deviceCategory', 'UUID', 'minor', 'major', 'controlNum', 'beaconNum', 'model', 'maker', 'team', 'department', 'trackingId'];
    let formErrors = {
      error: false,
      deviceCategory: '',
      UUID: '',
      minor: '',
      major: '',
      controlNum: '',
      beaconNum: '',
      model: '',
      maker: '',
      team: '',
      department: '',
      trackingId: ''
    };

    keys.forEach(key => {
      if (equipment[key] === '' && key !== 'trackingId') {
        formErrors.error = true;
        formErrors[key] = "Field can't be empty!";
      }
      if ((key === 'major' || key === 'minor') && (equipment[key] < 0 || equipment[key] > 65535)) {
        formErrors.error = true;
        formErrors[key] = "Wrong format!";
      }
      if (key === 'UUID' && equipment[key].length !== 36) {
        formErrors.error = true;
        formErrors[key] = "Wrong format!";
      }
      if (key === 'UUID' && equipment[key].length === 36) {
        const regex = /[A-Fa-f0-9]/g
        let part1 = equipment[key].substring(0, 8);
        let part2 = equipment[key].substring(9, 13);
        let part3 = equipment[key].substring(14, 18);
        let part4 = equipment[key].substring(19, 23);
        let part5 = equipment[key].substring(24, 36);
        if (part1.match(regex).length !== 8
          || part2.match(regex).length !== 4
          || part3.match(regex).length !== 4
          || part4.match(regex).length !== 4
          || part5.match(regex).length !== 12
          || equipment[key][8] !== '-'
          || equipment[key][13] !== '-'
          || equipment[key][18] !== '-'
          || equipment[key][23] !== '-') {
          formErrors.error = true;
          formErrors[key] = "Wrong format!";
        }
      }
    });

    setFormError({
      error: formErrors.error,
      deviceCategory: formErrors.deviceCategory,
      UUID: formErrors.UUID,
      minor: formErrors.minor,
      major: formErrors.major,
      controlNum: formErrors.controlNum,
      beaconNum: formErrors.beaconNum,
      model: formErrors.model,
      maker: formErrors.maker,
      team: formErrors.team,
      department: formErrors.department,
      trackingId: ''
    });

    return formErrors.error;
  };

  const editEquipment = () => {
    let categoryInfo = props.equipmentCategories.find(category => category.name === equipment.deviceCategory);
    let roleInfo = props.roles.find(team => team.role === equipment.team);
    let performedBy = handlePerformedBy();
    let modifiedBy = handleModifiedBy();
    let equipmentInfo = {
      device_category: categoryInfo.id,
      UUID: equipment.UUID.trim(),
      minor: parseInt(equipment.minor),
      major: parseInt(equipment.major),
      control_number: equipment.controlNum.trim(),
      beacon_number: equipment.beaconNum.trim(),
      model: equipment.model.trim(),
      maker: equipment.maker.trim(),
      team: roleInfo._id,
      department: equipment.department.trim(),
      campusId: props.campusId,
      id: equipment.id,
      creationDate: equipment.creationDate,
      modificationDate: new Date(),
      modifiedBy: modifiedBy,
      trackingId: !!equipment.trackingId ? equipment.trackingId.trim() : ''
    };
    props.updateEquipment(equipmentInfo).then(resp => {
      props.updateEquipmentBadge(equipmentInfo, badgeInfo);
      props.createEquipmentLog({
        action: 'Update Equipment',
        description: `Equipment "${equipmentInfo.beacon_number}" updated by "${performedBy}"`
      });
      setShowSpinner(false);
      setIsActionInProgress(false);
      props.handleHideForm();
      let msg = <div className={classes.inlineContainer}>Equipment "<div style={{ display: 'inline', fontWeight: 'bold' }}>{equipmentInfo.beacon_number}</div>" updated successfully!</div>
      props.getEquipment(props.campusId);
      props.handleOpenSnackbar('success', msg);
    }).catch(err => {
      props.createEquipmentLog({
        action: 'Update Equipment Error',
        description: `Equipment "${equipmentInfo.beacon_number}" couldn't be updated by "${performedBy}" due endpoint ${err}`
      });
      setShowSpinner(false);
      setIsActionInProgress(false);
      let msg = <div className={classes.inlineContainer}>Equipment "<div style={{ display: 'inline', fontWeight: 'bold' }}>{equipmentInfo.beacon_number}</div>" couldn't be updated!</div>
      props.handleOpenSnackbar('warning', msg);
    });
  };

  const saveEquipment = () => {
    let categoryInfo = props.equipmentCategories.find(category => category.name === equipment.deviceCategory);
    let roleInfo = props.roles.find(team => team.role === equipment.team);
    let performedBy = handlePerformedBy();
    let modifiedBy = handleModifiedBy();
    let equipmentInfo = {
      device_category: categoryInfo.id,
      UUID: equipment.UUID.trim(),
      minor: parseInt(equipment.minor),
      major: parseInt(equipment.major),
      control_number: equipment.controlNum.trim(),
      beacon_number: equipment.beaconNum.trim(),
      model: equipment.model.trim(),
      maker: equipment.maker.trim(),
      team: roleInfo._id,
      department: equipment.department.trim(),
      campusId: props.campusId,
      creationDate: new Date(),
      modificationDate: new Date(),
      modifiedBy: modifiedBy,
      trackingId: !!equipment.trackingId ? equipment.trackingId.trim() : ''
    };
    props.storeEquipment(equipmentInfo).then(resp => {
      props.createEquipmentLog({
        action: 'Create Equipment',
        description: `Equipment "${equipmentInfo.beacon_number}" created by "${performedBy}"`
      });
      setShowSpinner(false);
      setIsActionInProgress(false);
      props.handleHideForm();
      let msg = <div className={classes.inlineContainer}>Equipment "<div style={{ display: 'inline', fontWeight: 'bold' }}>{equipmentInfo.beacon_number}</div>" stored successfully !</div>
      props.getEquipment(props.campusId);
      props.handleOpenSnackbar('success', msg);
    }).catch(err => {
      props.createEquipmentLog({
        action: 'Create Equipment Error',
        description: `Equipment "${equipmentInfo.beacon_number}" couldn't be created by "${performedBy}" due endpoint ${err}`
      });
      setShowSpinner(false);
      setIsActionInProgress(false);
      let msg = <div className={classes.inlineContainer}>Equipment "<div style={{ display: 'inline', fontWeight: 'bold' }}>{equipmentInfo.beacon_number}</div>"couldn't be stored!</div>
      props.handleOpenSnackbar('warning', msg);
    });
  };

  return (
    <div id="webNavv-adminPanel-equipment-equipmentForm-1.0">
      <div
        id="equipmentForm-back-container"
        className={classes.back}
        onClick={() => props.handleHideForm()}>
        Back to Equipment table
      </div>
      <div id="equipmentForm-form-container" className={classes.container}>
        <div className={classes.mainDiv}>
          <Grid container spacing={formError.error ? 2 : 4} justify={"center"} alignItems={"center"}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <div className={classes.titleContainer}>
                  <BusinessCenterIcon className={classes.iconTitle} />
                </div>
                <div className={classes.titleLbl}>
                  {!props.isUpdate ? 'New Equipment' : 'Edit Equipment'}
                </div>
                <div className={classes.btnDiv}>
                  <MasterButton
                    id="webNavv-adminPanel-equipment-equipmentForm-cancelButton-1.0"
                    keyLbl={'change-password'}
                    label={'Cancel'}
                    buttonType={'default-flat'}
                    isDisabled={isActionInProgress}
                    handleClick={() => props.handleHideForm()}
                    size={"medium"} />
                  <MasterButton
                    id="webNavv-adminPanel-equipment-equipmentForm-saveButton-1.0"
                    keyLbl={'submit-equipment'}
                    label={'Save'}
                    buttonType={'success'}
                    isDisabled={isActionInProgress}
                    handleClick={handleSubmit}
                    size={"medium"}
                    styles={{ marginLeft: '10px' }}
                    class={classes.containedBtn} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <AntTabs
                value={tabValue}
                onChange={changeTab}
                inkBarStyle={{ background: 'blue' }}>
                <AntTab
                  id="webNavv-adminPanel-equipment-equipmentForm-equipmentInfoTab-1.0"
                  style={{ fontSize: '12px' }}
                  value='a'
                  label="Equipment Info"
                  index={0}
                  aria-label="equipmentInfo" />
                {/* <AntTab style={{fontSize:'12px'}} value='b' label="Location Log" index={1} aria-label="locationLog" />
                      <AntTab style={{fontSize:'12px'}} value='c' label="Comments" index={1} aria-label="comments" /> */}
              </AntTabs>
            </Grid>
            {tabValue === 'a' && (
              <EquipmentForm
                isUpdate={props.isUpdate}
                formError={formError}
                equipment={equipment}
                handleInputChange={handleInputChange}
                equipmentCategories={props.equipmentCategories}
                roles={props.roles}
              />
            )}
            {/* {tabValue === 'b' &&(
                    <div>Location</div>
                  )}
                  {tabValue === 'c' &&(
                    <div>Comments</div>
                  )} */}
          </Grid>
        </div>
      </div>
      <AwesomeSpinner config={'default'} loading={showSpinner} navOpen={props.open} />
    </div>
  );
};

const mapStateToProps = state => ({
  equipmentCategories: state.equipments.equipmentCategories,
  roles: state.acl.acls,
  campusId: state.auth.userInfo.campusId,
  open: state.views.drawerOpen,
  user: state.user.user,
  userId: state.auth.userId
});
export default connect(mapStateToProps, { getEquipment, storeEquipment, updateEquipment, updateEquipmentBadge })(EquipmentInfo);