import axios from "axios";

import { GET_MODELS, ADD_MODEL, PUT_MODEL, DELETE_MODEL } from "./types";
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const getModels = (campusId) => (dispatch, getState) => {
  try{
    axios.get(`${secureUrl.hostUrl}/api/phone_models?filter={"where":{"campusId":"${campusId}"}}&access_token=${+getState().auth.token}`)
    .then((resp) => {
      dispatch({
        type: GET_MODELS,
        payload: resp.data
      });
    });
  }catch(error){
    console.error("[MODEL PHONE ACTIONS] An error ocurred in getModels: ", error);
  }
};

export const addModelPhone = (phone) => (dispatch, getState) => {
  return new Promise ((resolve, reject) =>{
    try{
      axios
      .post(
        `${secureUrl.hostUrl}/api/phone_models`,
        phone
      )
      .then((resp) => {
        dispatch({
          type: ADD_MODEL,
          payload: resp
        });
        resolve(resp);
      })
    }catch(error){
      console.error("[MODEL PHONE ACTIONS] An error ocurred in addModelPhone", error);
      reject(error);
    }
  });
};

export const putModelPhone = (phone) => (dispatch, getState) => {
  return new Promise ((resolve, reject) =>{
    try{
      axios
      .put(
        `${secureUrl.hostUrl}/api/phone_models/${phone.id}`,
        phone
      )
      .then((resp) => {
        dispatch({
          type: PUT_MODEL,
          payload: resp
        });
        resolve(resp)
      })
    }catch(error){
      console.error("[MODEL PHONE ACTIONS] An error ocurred in putModelPhone: ", error);
      reject(error);
    }
  });
};

export const deleteModelPhone = (id) => (dispatch, getState) => {
  return new Promise ((resolve, reject) =>{
    try{
      axios
      .delete(
        `${secureUrl.hostUrl}/api/phone_models/${id}`
      )
      .then((resp) => {
        dispatch({
          type: DELETE_MODEL,
          payload: resp
        });
        resolve(resp)
      })
    }catch(error){
      console.error("[MODEL PHONE ACTIONS] An error ocurred in deleteModelPhone", error);
      reject(error);
    }
  });
};