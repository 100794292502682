import React from "react";

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const JobTemplatesSwitch = (props) =>{
    return(
        <div style={{position: "absolute", left:"83%", top:"16%"}}>
            <FormGroup>
                <FormControlLabel control={<Switch checked={props.showJobTemplates} onChange={props.handleJobTemplatesChange} color="primary"/>}  label="Show job templates"/>
            </FormGroup>
        </div>
    );
}

export default JobTemplatesSwitch;