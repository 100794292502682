import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: "AIzaSyDCwckg1Qdj6QC0aCJUiWF7byz3UbJdSLg",
  authDomain: "spry-chassis-279614.firebaseapp.com",
  databaseURL: "https://spry-chassis-279614.firebaseio.com",
  projectId: "spry-chassis-279614",
  storageBucket: "spry-chassis-279614.appspot.com",
  messagingSenderId: "711282896000",
  appId: "1:711282896000:web:00e2b5d62c359a324e0a65",
  measurementId: "G-SFX6P52SQY"
};

firebase.initializeApp(config);

const vapidKey = {
  vapidKey: "BK_g9HuaGeCt0U1tE7EDvYVdYYYAZltZ4FPycRYe0_QBhbrjcKDZ9HBsmwzFjPahHzMl7cCm4XwpRCt2uBn_p54"
};

class Firebase {
  constructor() {
    this.messaging = firebase.messaging();
    this.tokenFirebase = this.messaging.getToken(vapidKey);

  }
  getMessaging() {
    return this.messaging;
  }

  getToken() {
    return this.tokenFirebase;
  }
}
export {Firebase};
