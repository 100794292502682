import {
    GET_PHONES,
    GET_PHONE,
    GET_ALL_PHONES,
    GET_PUBNUB_SUBSCR,
    PHONE_TO_UPDATE,
    CREATE_PHONE_COMMENT,
    GET_PHONE_COMMENTS,
    CLEAN_PHONE_COMMENTS,
    DUPLICATED_PHONE} from '../actions/phones/types';

const initialState = {
    phones: [],
    phonesCheckList: [],
    pubnubSub: {},
    phoneInfo:{},
    allPhoneComments:[],
    phoneComments: [],
    duplicateSuccess: false,
    allPhones: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PHONES:
            return {
                ...state,
                phones: action.payload,
            };
        case GET_PHONE:
            const idIsEqual = (phone) => phone.usedBy === action.userId;
            let index = state.phones.findIndex(idIsEqual);
            let phonesCopy = [...state.phones];
            phonesCopy[index] = action.payload;
            return {
                ...state,
                phones: phonesCopy,
            };
        case GET_ALL_PHONES:
            return {
                ...state,
                allPhones: action.payload,
            };
        case GET_PUBNUB_SUBSCR:
            return {
                ...state,
                pubnubSub: action.payload,
            };
        case PHONE_TO_UPDATE:
            return {
                ...state,
                phoneInfo: action.payload
            };
        case CREATE_PHONE_COMMENT:
            return {
                ...state,
                allPhoneComments: action.payload
            };
        case GET_PHONE_COMMENTS:
            return {
                ...state,
                phoneComments: action.payload
        };
        case CLEAN_PHONE_COMMENTS:
            return {
                ...state,
                phoneComments: action.payload
        };
        case DUPLICATED_PHONE:
            return {
                ...state,
                duplicateSuccess: action.payload
            };
        default:
            return state;
    }
}