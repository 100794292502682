import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NotifyConfirm from './notifyjs/notify_alert';
//import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
//import config from './aws-exports';
import configureAmplify from './services/servicesConfig';

/*
Amplify.configure(config);

datadogRum.init({
   applicationId: 'b45566a0-4acd-4c4f-95ec-77daf7b96157',
   clientToken: 'pub9e4ad6afd6ce26438e9bec9c9dea873b',
   site: 'datadoghq.com',
   service:'navy',
   version: '1.0.0',
   sampleRate: 10,
   trackInteractions: true,
   defaultPrivacyLevel: 'mask-user-input',
   trackSessionAcrossSubdomains: true,
   useSecureSessionCookie: true,
   useCrossSiteSessionCookie: true,
});

datadogRum.startSessionReplayRecording();
*/

// Call services configuration
configureAmplify();

ReactDOM.render(<BrowserRouter>
                    <App />
                </BrowserRouter>, document.getElementById('root'));
//registerServiceWorker();
