import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = (theme) => ({
    tableCell:{
        textAling:"center"
     },
    paper: {
       minWidth: "500px",
       minHeight: "100%",
       position: "absolute",
       zIndex: 1000,
       left: "100vh",
       top: "12vh"
    },
    containedBtnPass: {
        width:'15%',
        marginTop: '10px',
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    titleContainer: {
        display:'flex',
        flexDirection:'row',
        marginTop: '10px',
        // marginLeft: '5vh'
    },
    titleDiv:{
      display:'flex',
      marginTop:'20px'
    },
    titleIcon:{
      fill:'#2843A3',
      fontSize:'50px',
      opacity:0.7,
    },
    titleIconDiv:{
      marginTop:'1%'
    },
    titleLabel:{
      color:'#2843A3',
      marginLeft:'2%'
    },
    formContainer: {
        justifyContent:'space-between',
        width:'100%',
        paddingBottom:'20px',
        display:'flex',
        marginTop: '20px'
    },
    input: {
        color: '#757575',
        backgroundColor: 'rgba(0,0,0,0.04)',
        border: 'none',
        width: '40vh',
        marginLeft: '20px',
        borderRadius: '7px',
        height: '7vh',
        paddingLeft: '10px'
    },
    searchContainerOpen: {
      display: 'flex',
      width: '20vh',
      marginLeft: '50vh'
    },
    searchContainer: {
        display: 'flex',
        width: '20vh',
        marginLeft: '85vh'
    },
    closeSearch: {
        display: 'inline',
        width: '5vh',
        marginLeft:'10%',
        marginTop: '0.5%'
    },
    inputContainer: {
        display: 'inline',
        width: '35vh'
    },
    cleanBtn: {
        marginBottom: '10%'
    },
    timeBtns: {
        float: 'right',
        marginTop:'10px'
    }
});

export const getMuiTheme = () =>
createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: '80vh !important'
      },
      paper: {
        boxShadow: "0 4px 2px -2px gray",
        paddingTop: "1px"
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: "1%"
      },
      toolButton: {
        display: "flex",
        justifyContent: "center",
        color: "#7D7D7D"
      }
    },
    MUIDataTableBodyCell: {
      root: {
        padding: '5 !important',
        textAlign: "left"
      }
    },
    MUIDataTableBodyRow:{
      root: {
        backgroundColor: '#f5f5f5'
      }
    },
    MuiTableFooter:{
      root:{
        backgroundColor: '#f5f5f5 !important'
      }
    }
  }
});
export const getInnerMuiTheme = () =>
createMuiTheme({
  overrides: {
    MUIDataTableHeadCell: {
      toolButton: {
        color: "#7D7D7D",
        float: 'none !important'
      },
      contentWrapper:{
        justifyContent: "center",
        display: "flex",
      },
      fixedHeader: {
        color: "#7D7D7D",
      }
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: "center"
      }
    },
    MuiTableCell: {
      root:{
        padding: '1px'
      }
    }
  }
});