export const SELECTED='#2196F3';
export const DISABLED='#757575';
export const CURRENT_FLOOR='Current Floor Only';
export const SHOW_TRACES='Show Traces';
export const INDOOR_ONLY='Indoor Only';
export const ALL_CALLOUTS='All Callouts';
export const MINIMIZE_CALLOUT='Minimize Callouts';
export const CENTER_MAP='Center Map';
export const FILTERS='Filters';
export const ACTIONS='Actions';
export const CURRENT_DEVICE="Current Device Only";
export const CURRENT_USER="Current User Only";