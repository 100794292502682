import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ReactComponent as SuperBarIcon } from './img/superBarIcon.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "45px",
        backgroundColor: "transparent",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        overflowX: "hidden",
        zIndex: "1000",
        position: "fixed !important",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: "#2843A3",
        width: "45px",
        zIndex: "999",
        position: "fixed !important",
        height: "100%",
        borderRight: "1px solid white",
    },
    indicatorOpen: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > div": {
            maxWidth: 20,
            width: "10%",
            borderTop: "30px solid transparent",
            borderRight: "25px solid white",
            borderBottom: "30px solid transparent",
        },
    },
    firstSectionTabs: {
        right: 60,
        top: "70px",
    },
}));

export default function VerticalTabs(props) {
    const classes = useStyles();
    const { toggleDrawer } = props;

    const toggleArrow = () => {
        toggleDrawer()
    };

    return (
        <div style={{ zIndex: 1000, display: "flex" }}>
            <div
                id="webNavv-superAdmin-navvbar-1.0"
                className={classes.root}
            >
                <React.Fragment>
                    <Tabs
                        orientation="vertical"
                        variant="fullWidth"
                        value="a"
                        className={classes.tabs}
                        classes={{ indicator: classes.indicatorOpen }}
                        TabIndicatorProps={{ children: <div /> }}
                    >
                        <Tab
                            value="a"
                            index={0}
                            className={classes.firstSectionTabs}
                            icon={<SuperBarIcon />}
                            aria-label="emtyTab"
                            onClick={toggleArrow}
                        />
                    </Tabs>
                </React.Fragment>
            </div>
        </div>
    );
}