import React from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import { getSubscr } from '../../actions/phones/phonesActions';
import { loggedUser } from '../../actions/user/userActions';
import { userChatSelected, setUnreadMsgsList, openBroadcastChat, unreadBroadcastChat } from '../../actions/views/viewsActions';

import Broadcast from './broadcast-window/broadcast.component';
import ChatWindow from './chat-window/chat.window';
import ChatWindowChime from './chat-window-chime/chat.window.chime';

import { Subject } from 'rxjs';
import './chat.component.css';

class Chat extends React.Component {
    get users() {
        return this.state.users;
    }

    set users(state = {}) {
        this.setState({ users: state });
    }

    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            initial: null,
            chats: [],
            dialogOpen: false,
            dialogError: '',
            filter: '',
            users: {},
            lastMessage: '',
            newMessage: null,
        };
    }

    componentDidMount() {
        this.props.socketChat.on('connect', () => {
            console.log("[CHAT] socket client connected ", this.props.userId);
            try {
                this.socketManagment(this.props.userId);
            } catch (error) {
                console.warn("[Chat initialization] an error has ocurred in traying to initialize the chat", error);
            }
        });

        this.props.socketChat.on('error', (err) => { 
            console.error('[CHAT].- Socket connect error:', err) 
        });

        this.props.socketChat.on('reconnection_attempt', () => { 
            console.error('[CHAT].- Socket reconnection_attempt:') 
        });

        this.props.socketChat.on("connect_error", error => {
            console.error(`[CHAT].- Socket connect_error: ${error}`);
            setTimeout(() => {
                this.props.socketChat.connect();
            }, 1000);
          });

        this.socketConnCheck=setInterval(this.handleSocketCheckHealth,10000);

        this.props.socketChat.on('error', (err) => { console.error('[CHAT] Socket connect error:', err) });
    }
    handleIsSocketConnected=()=>{
        return this.props.socketChat.connected
    };

    handleSocketCheckHealth=()=>{
        if(!this.handleIsSocketConnected){   
            this.props.socketChat.connect();
        }else{
            clearInterval(this.socketConnCheck);
        }
    }
    
    componentWillUnmount() {
        this.props.socketChat.close();
    }

    socketManagment(userId) {
        //validate userId
        this.isUserIdValid(this.props.userId);

        this.props.socketChat.on('new message', event => {
            this.onNewMessage(event);
        });

        this.props.socketChat.once('users', message => {
            const { users } = message;
            this.users = {...users};
            this.props.loggedUser(this.users[userId]);
        });

        this.props.socketChat.on('joined', newUser => {
            if (!this.users[newUser.id]) {
                return;
            }
            this.users[newUser.id].status = newUser.status;
            this.users[newUser.id].channel = newUser.channel;
        });

        this.props.socketChat.on('user disconnect', userDisconnected => {
            if (!this.users[userDisconnected.id]) {
                return;
            }
            this.users[userDisconnected.id].status = userDisconnected.status;
            this.users[userDisconnected.id].channel = userDisconnected.channel;
        });

        this.props.socketChat.on('bcst-messages', (broadcast) => {
            if (!!broadcast) {
                this.props.openBroadcastChat(true);
                if (!!broadcast.message && !this.props.unreadBroadcast && broadcast.message.senderId !== userId) {
                    this.props.unreadBroadcastChat(true);
                }
            }
        });

        this.props.socketChat.emit('join', { id: this.props.socketChat.id, userId, firebaseToken: this.props.pushNotToken });
        this.props.socketChat.emit('users', userId);
    }

    isUserIdValid = userId => {
        if (!userId) {
            throw new Error(`userId should not be undefined or null ${userId}`);
        }
    }

    onNewMessage(res) {
        let { newMessage } = res;
        this.setState({ newMessage: newMessage });
    }

    updateNewMessage() {
        const { newMessage } = this.state;

        const chatToComunicate = this.windowWithDestinyExists(newMessage.origin);
        if (chatToComunicate) {
            chatToComunicate.subject.next(newMessage);
        } else {
            if (!!this.users[newMessage.origin]) {
                this.users[newMessage.origin].unreadMsgs++;
            }
        }
        let isWinChatOpen = this.state.chats.findIndex(chat => chat.destiny === newMessage.origin);
        if (isWinChatOpen < 0) {
            this.addNewChat(newMessage.origin);
        }
        else {
            const openChats = [...this.state.chats];
            openChats[isWinChatOpen].open = true;
            this.setState({ chats: openChats });
        }
    }

    sendMessage(messageData) {
        const message = {
            text: messageData.currentMessage,
            origin: this.props.userId,
            destiny: messageData.destiny,
            status: '',
            channel: this.users[messageData.destiny].channel,
            creationDate: new Date(),
        };
        this.props.socketChat.emit('send message', message);
        return message;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.userChat !== this.props.userChat) {
            this.addNewChat(nextProps.userChat);
        }
        if (nextProps.userChatUpdated !== this.props.userChatUpdated) {
            if (!!nextProps.userChatUpdated) {
                this.updateUserName(nextProps.userChatUpdated);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.newMessage !== this.state.newMessage) {
            this.updateNewMessage();
        }
    }

    updateUserName(user) {
        if (!!user.id) {
            if (!!this.users[user.id] && this.users[user.id].hasOwnProperty('name')) {
                this.users[user.id].name = `${user.first_name} ${user.last_name}`;
            }
            let chatUsers = this.state.chats.map( (chat) => {
                if (chat.destiny === user.id) {
                  //console.log(`Updating chat window, user: ${JSON.stringify(user)} , destiny: ${chat.destiny}`);
                  return {...chat, name: `${user.first_name} ${user.last_name}`};
                }
                return chat;
            });

            this.setState({ chats: chatUsers });
        }
    }

    getUsersWithUnreadMsgs() {
        let unreadList = [];
        delete this.users["111111111111111111111111"];
        for (const key in this.users) {
            if (
                this.users.hasOwnProperty(key) &&
                this.users[key].hasOwnProperty('name') &&
                key !== this.props.userId
            ) {
                const user = this.users[key];
                if (user.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    if (user.unreadMsgs)
                        unreadList.push({ userId: user.id, unreadMsg: user.unreadMsgs });
                }
            }
        }
        this.props.setUnreadMsgsList(unreadList);
    }

    renderContacts() {
        let onlineList = [];
        let unreadList = [];
        let list = [];
        delete this.users["111111111111111111111111"];
        for (const key in this.users) {
            if (
                this.users.hasOwnProperty(key) &&
                this.users[key].hasOwnProperty('name') &&
                key !== this.props.userId
            ) {
                const user = this.users[key];
                if (user.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    if (user.status.includes('online') || user.status.includes('inactive')) {
                        onlineList.push(this.renderUserBox(user));
                    } else if (user.unreadMsgs) {
                        unreadList.push(this.renderUserBox(user));
                    } else {
                        list.push(this.renderUserBox(user));
                    }
                }
            }
        }
        return <div>{[...onlineList, ...unreadList, ...list]}</div>;
    }

    renderUserBox(user) {
        return (
            <div key={user.id} className="contact-container">
                <div
                    style={{
                        backgroundColor: 'blue',
                        height: '24px',
                        width: '24px',
                        borderRadius: '12px',
                    }}
                ></div>
                <div
                    style={{ justifySelf: 'left' }}
                    onClick={() => {
                        this.addNewChat(user.id);
                    }}
                >
                    {(user.unreadMsgs > 0 ? `(${user.unreadMsgs}) ` : '') + user.name}
                </div>
                <div
                    style={{
                        backgroundColor: this.handleUserStatus(user.status),
                        height: '9px',
                        width: '9px',
                        borderRadius: '4.5px',
                    }}
                ></div>
            </div>
        );
    }

    handleUserStatus(status) {

        switch (status) {
            case 'online':
                return '#3FA33E'
            case 'inactive':
                return 'orange'
            default:
                return 'red'
        }
    }

    isUsersEmpty() {
        return !Object.entries(this.users).length;
    }

    renderUsersLoading() {
        return (
            <div>
                <div className="contact-container empty-contacts">
                    <div
                        style={{ height: '24px', width: '24px', borderRadius: '12px' }}
                    ></div>
                    <div
                        style={{ justifySelf: 'left', height: '30px', width: '180px' }}
                    ></div>
                    <div
                        style={{ height: '9px', width: '9px', borderRadius: '4.5px' }}
                    ></div>
                </div>
                <div className="contact-container empty-contacts">
                    <div
                        style={{ height: '24px', width: '24px', borderRadius: '12px' }}
                    ></div>
                    <div
                        style={{ justifySelf: 'left', height: '30px', width: '180px' }}
                    ></div>
                    <div
                        style={{ height: '9px', width: '9px', borderRadius: '4.5px' }}
                    ></div>
                </div>
                <div className="contact-container empty-contacts">
                    <div
                        style={{ height: '24px', width: '24px', borderRadius: '12px' }}
                    ></div>
                    <div
                        style={{ justifySelf: 'left', height: '30px', width: '180px' }}
                    ></div>
                    <div
                        style={{ height: '9px', width: '9px', borderRadius: '4.5px' }}
                    ></div>
                </div>
            </div>
        );
    }

    removeChat(destiny) {
        const channels = this.state.chats.filter(chat => chat.destiny !== destiny);
        this.setState({ chats: channels });
    }

    addNewChat(destiny) {
        const { featureFlags } = this.props;
        if (featureFlags.Chat.ChatWindowChime) {
            if (!this.state.chats.length) {
                const newChat = {
                    ...this.users[destiny],
                    open: true,
                    origin: this.props.userId,
                    destiny: destiny,
                    subject: new Subject(),
                };
                const chats = [newChat];
                this.props.userChatSelected('');
                this.setState({ chats });
            }
        }
        else if (!!this.users[destiny]) {
            if (this.windowWithDestinyExists(destiny) && !this.users[destiny]) {
                return;
            }
            let { chats } = this.state;
            this.users[destiny].unreadMsgs = 0;
            const newChat = {
                ...this.users[destiny],
                open: true,
                origin: this.props.userId,
                destiny: destiny,
                subject: new Subject(),
            };

            chats = this.handleValidateOpenChats(chats, newChat)

            chats.push(newChat);
            this.props.userChatSelected('');
            this.setState({ chats });
        }
    }

    handleValidateOpenChats = (chats, newChat) => {
        let newChats = chats.filter(chat => chat.destiny !== newChat.destiny)
        newChats.length >= 3 && newChats.shift();
        return newChats;
    }

    windowWithDestinyExists(chatOriginId) {
        const openChats = [...this.state.chats];
        return openChats.find(chat => chat.id === chatOriginId);
    }

    renderOpenChats() {
        if (!this.state.chats.length) {
            return;
        }
        const chatWindows = [];
        this.state.chats.map(chat => {
            const { featureFlags } = this.props;
            if (featureFlags.Chat.ChatWindowChime) {
                chatWindows.push(
                    <ChatWindowChime
                        chat={chat}
                        socket={this.props.socketChat}
                        chatTittle={`${chat.name}`}
                        onMessage={chat.subject}
                        key={chat.destiny}
                        removeChat={this.removeChat.bind(this)}
                        sendMessage={this.sendMessage.bind(this)}
                    />
                );
            }
            else {
                chatWindows.push(
                    <ChatWindow
                        chat={chat}
                        socket={this.props.socketChat}
                        chatTittle={`${chat.name}`}
                        onMessage={chat.subject}
                        key={chat.destiny}
                        removeChat={this.removeChat.bind(this)}
                        sendMessage={this.sendMessage.bind(this)}
                    />
                );

            }
        });
        return chatWindows;
    }

    filterContacts(event) {
        this.setState({ filter: event.target.value });
    }

    handleClose() {
        this.setState({ dialogOpen: false });
    }

    render() {
        const actions = [
            <FlatButton
                label="Ok"
                primary={true}
                onClick={this.handleClose.bind(this)}
            />,
        ];
        return (
            <React.Fragment>
                <div className="chat-window-container">
                    {/* <ChatConversations title={'Start Conversation'}>
                    <div className="search-box">
                        <Input
                            placeholder="Search"
                            sizeClass="chat-window-size"
                            search={this.filterContacts.bind(this)}
                        >
                            <SearchIcon color={'#a0a0a3'} />
                        </Input>
                    </div>
                    <Divider />
                <Broadcast userInfo={this.props.userInfo} socketChat={this.props.socketChat} socketConn={this.props.socketLocation} />
                        {
                        this.isUsersEmpty()
                        ? this.renderUsersLoading()
                        : this.renderContacts()
                        }
                </ChatConversations> */}
                    {this.renderOpenChats()}
                    {this.props.openBroadcast &&
                        <Broadcast
                            removeChat={this.removeChat}
                            sendMessage={this.sendMessage}
                            userInfo={this.props.userInfo}
                        />
                    }
                    <Dialog
                        title="Warning!"
                        modal={true}
                        open={this.state.dialogOpen}
                        actions={actions}
                    >
                        {this.state.dialogError}
                    </Dialog>
                </div>
            </React.Fragment>
        );
    }
}

Chat.propTypes = {
    userId: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    userId: state.auth.userId,
    users: state.user.users,
    userInfo: state.auth.userInfo,
    socketChat: state.auth.socketChat,
    userChat: state.views.userChat,
    userChatUpdated: state.views.userChatUpdated,
    openBroadcast: state.views.openBroadcast,
    pushNotToken: state.auth.pushNotificationToken,
    unreadBroadcast: state.views.unreadBroadcast,
    featureFlags: state.auth.featureFlags
});

export default connect(mapStateToProps, { getSubscr, loggedUser, userChatSelected, setUnreadMsgsList, openBroadcastChat, unreadBroadcastChat })(Chat);

