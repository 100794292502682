import _ from "lodash";
import { mutationDeleteResourceSlot } from "../../../graphql/mutations";

export const handleDeleteSlot = async(currentRowSelected) =>{
  const DeleteResource_Slot_Input = {
    resource_slotid: currentRowSelected.resource_slotid,
  }
  const slotDeleted = await mutationDeleteResourceSlot(DeleteResource_Slot_Input);

  if (slotDeleted) {
    return "done";
  }
  else{
    return "error";
  }
}
