import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = () => ({
    JobsHistory_JobRouteIcon:{
      color:"#D6D6D6",
      fontSize: "16px",
      marginTop:"6px"
    },
    JobsHistory_SearchBar:{
        color: '#757575',
        backgroundColor: 'rgba(0,0,0,0.04)',
        border: 'none',
        width: '75vh',
        marginLeft: '15px',
        borderRadius: '7px',
        height: '50px',
        paddingLeft: '10px',
        marginTop: "25px"
    },
    JobsHistory_blueButton: {
        position: "absolute",
        top: "40%",
        left: "90%",
        fontSize: '12px',
        marginLeft:"20px",
        color: "white",
        textTransform: "none !important",
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
});