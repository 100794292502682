import React from "react";

import Checkbox  from "@material-ui/core/Checkbox";
import {withStyles} from '@material-ui/core/styles';

import './boardCheckBoxes.css';

export const CustomCheckbox = withStyles((theme) => ({
    root: {
      "&$checked": {
        color: "#2196F3"
      }
    },
    checked: {}
}))(Checkbox);

const BoardCheckBoxes = (props) =>{
    const handleRenderCheckboxPerRow = () =>{
        const {groups} = props;
        return(
            <div id="webNavv-workflowAutomation-jobsBoard-checkBoxContainer-1.0" className="BoardCheckBoxes_container">
                {groups.map((group, index)=>{
                    if (group.status === 'actions' || group.status === 'templates') {
                        return null
                    }else {
                        return(
                            <div className="BoardCheckBoxes_div" key={index}>
                                <CustomCheckbox
                                id={`${group.id}-webNavv-workflowAutomation-jobsBoard-checkbox-1.0`}
                                disabled={!group.canEdit? true : false}
                                style={group.checked? {fill: '#2196F3'} : {fill: '#919191'}}
                                onChange={() =>props.displayRowOptions(group.id)}
                                checked={group.checked}/>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    return(
        <div id="webNavv-workflowAutomation-jobsBoard-checkBoxes-1.0">
            {handleRenderCheckboxPerRow()}
        </div>
    )
}

export default BoardCheckBoxes;