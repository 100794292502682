import React, {useState, useEffect} from 'react';
import {CustomSlider} from './styles';

export default function AnimationSlider(props) {
  const [progressValue, setProgressValue] = useState(0);
  let totalDuration = props.totalDuration;

  useEffect(()=>{
    getProgressBarValues();
  }, [props.addProgress]);

  useEffect(()=>{
    if (props.secondsForward) {
      let newValue = +progressValue + +10;
      setProgressValue(newValue);
      props.userProgress(newValue);
    }
  }, [props.secondsForward]);

  useEffect(()=>{
    if (props.cleanSlider)
      handleCleanSlider();
  }, [props.cleanSlider]);

  const getProgressBarValues = ()=>{
    setProgressValue(props.addProgress);
  };

  const handleCleanSlider = ()=>{
    setProgressValue(0);
  };

  const handleChangeSlider = (event, newValue) => {
    setProgressValue(newValue);
    props.userProgress(newValue);
  };

  return (
    <React.Fragment>
      <CustomSlider
        value={progressValue}
        min={0}
        step={1}
        max={totalDuration}
        onChange={handleChangeSlider}
        // valueLabelDisplay="auto"
      />
    </React.Fragment>
  );
};

