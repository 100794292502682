/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getJob_Template = /* GraphQL */ `
  query GetJob_Template($id: ID!) {
    getJob_Template(id: $id) {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const getJob_Percentage = /* GraphQL */ `
  query GetJob_Percentage($jobid: ID!) {
    getJob_Percentage(jobid: $jobid) {
      job_percentage_complete
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($taskid: ID!) {
    getTask(taskid: $taskid) {
      taskid
      task_name
      jobid
      task_description
      task_typeid
      task_type
      task_locationid
      isfixed_location
      floor_name
      departmentid
      department_name
      task_statusid
      task_status
      task_actionid
      task_action
      task_start_date
      task_end_date
      suggested_sequence
      location_description
      location_instructions
      specimen_instructions
      location_name
      task_identifier
      task_identifier_typeid
      task_identifier_type
      job_errorid
      job_error_number
      job_error_description
      error_date
    }
  }
`;
export const getJobNotifications = /* GraphQL */ `
  query GetJobNotifications($JobsForMinute: AWSDateTime) {
    getJobNotifications(JobsForMinute: $JobsForMinute) {
      firebase_token
      jobid
      job_name
      job_start_date
      notification_status
      assigned_userid
      mongo_userid
      job_error_numberid
      error_number
      error_description
      job_status
      isnotification
    }
  }
`;
export const listResource_Slots_ByUser = /* GraphQL */ `
  query ListResource_Slots_ByUser(
    $min: AWSDateTime
    $max: AWSDateTime
    $assigned_userid: ID
  ) {
    listResource_Slots_ByUser(
      min: $min
      max: $max
      assigned_userid: $assigned_userid
    ) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const listResource_Slots_ByUserToday = /* GraphQL */ `
  query ListResource_Slots_ByUserToday(
    $assigned_userid: ID!
    $min: AWSDateTime
    $max: AWSDateTime
  ) {
    listResource_Slots_ByUserToday(
      assigned_userid: $assigned_userid
      min: $min
      max: $max
    ) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const listStandaloneJob_Templates = /* GraphQL */ `
  query ListStandaloneJob_Templates($job_typeid: String) {
    listStandaloneJob_Templates(job_typeid: $job_typeid) {
      job_templateid
      description
      job_template_name
      job_type
      job_template_description
      job_typeid
      job_duration
    }
  }
`;
export const listResource_Slot_JobByDateRange = /* GraphQL */ `
  query ListResource_Slot_JobByDateRange(
    $min: AWSDate
    $max: AWSDateTime
    $teamid: String
  ) {
    listResource_Slot_JobByDateRange(min: $min, max: $max, teamid: $teamid) {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const listJob_Types = /* GraphQL */ `
  query ListJob_Types {
    listJob_Types {
      job_typeid
      job_type
      tenantid
    }
  }
`;
export const listJob_Status = /* GraphQL */ `
  query ListJob_Status {
    listJob_Status {
      job_statusid
      job_status
      tenantid
    }
  }
`;
export const listTask_Status = /* GraphQL */ `
  query ListTask_Status {
    listTask_Status {
      task_statusid
      task_status
      tenantid
      description
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      userid
      user_name
      first_name
      last_name
      isenabled
      teamid
      team_name
      mongo_userid
    }
  }
`;
export const listUsersbyUser_name = /* GraphQL */ `
  query ListUsersbyUser_name($user_name: String) {
    listUsersbyUser_name(user_name: $user_name) {
      userid
      user_name
      first_name
      last_name
      isenabled
      teamid
      team_name
      mongo_userid
    }
  }
`;
export const listUsersByTeam = /* GraphQL */ `
  query ListUsersByTeam($teamid: ID) {
    listUsersByTeam(teamid: $teamid) {
      userid
      user_name
      first_name
      last_name
      isenabled
      teamid
      team_name
      mongo_userid
    }
  }
`;
export const listJobTasks = /* GraphQL */ `
  query ListJobTasks($jobid: ID!) {
    listJobTasks(jobid: $jobid) {
      taskid
      task_name
      jobid
      task_description
      task_typeid
      task_type
      task_locationid
      isfixed_location
      floor_name
      departmentid
      department_name
      task_statusid
      task_status
      task_actionid
      task_action
      task_start_date
      task_end_date
      suggested_sequence
      location_description
      location_instructions
      specimen_instructions
      location_name
      task_identifier
      task_identifier_typeid
      task_identifier_type
      job_errorid
      job_error_number
      job_error_description
      error_date
    }
  }
`;
export const listJob_HistoryByDateRange = /* GraphQL */ `
  query ListJob_HistoryByDateRange($min: AWSDateTime, $max: AWSDateTime) {
    listJob_HistoryByDateRange(min: $min, max: $max) {
      jobid
      job_name
      job_type
      job_description
      job_start_date
      job_end_date
      job_typeid
      description
      issequence_required
      job_statusid
      job_status
      resource_slotid
      modified_by
      job_percentage_complete
      job_assigned_userid
      job_assigned_user_name
      job_mobile_sessionid
      job_started_date
      job_ended_date
      job_error_description
      job_error_number
      unstarted_taskid
      job_number
    }
  }
`;
export const listlocations = /* GraphQL */ `
  query Listlocations {
    listlocations {
      task_locationid
      location_name
      task_identifier
      task_identifier_typeid
      task_identifier_type
      isfixed_location
      floor_name
      location_description
      departmentid
      department_name
      tenantid
    }
  }
`;
export const listTaskStatus2 = /* GraphQL */ `
  query ListTaskStatus2 {
    listTaskStatus2 {
      task_statusid
      task_status
      tenantid
      description
    }
  }
`;
export const listJob_Error_Numbers = /* GraphQL */ `
  query ListJob_Error_Numbers {
    listJob_Error_Numbers {
      job_error_numberid
      job_error_number
      job_error_description
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams($campusid: ID!) {
    listTeams(campusid: $campusid) {
      teamid
      campusid
      campus_name
      team_name
      istracked
      istransporter
      tenantid
    }
  }
`;
