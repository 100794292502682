import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import {
  getCampuses,
  updateCampus,
  createCampus,
  uploadIMDF
} from '../../../actions/campus/campusActions';
import { createLog } from '../../../actions/logs/logsActions';
import _ from 'lodash';
import './mapConfig.component.css';
import { useStyles } from './mapConfig.styles';
import PreviewMap from './previewMap/preview.component';
import MasterButton from '../../utilities/button.component';
import MasterAlert from '../../utilities/alert.component';
import AwesomeSpinner from '../../utilities/spinner';
import * as CONSTS from './mapConfig.constants';
import ModalPop from '../../utilities/modal.component';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CampusForm from './campusForm.component';

function MapConfig(props) {
  const classes = useStyles();
  const [campusRoles, setCampusRoles] = useState([]);
  const [campusSelected, setCampusSelected] = useState([]);
  const [isNewCampus, setIsNewCampus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editCampusName, setEditCampusName] = useState(false);
  const [fileFlags, setFileFlags] = useState({
    venue: false,
    unit: false,
    opening: false,
    level: false
  });
  const [campus, setCampus] = useState({
    id: null,
    name: '',
    alias: '',
    longitude: null,
    latitude: null,
    floor: null,
    zoom: null,
    rotation: null,
    firstFloor: null,
    lastFloor: null,
    channel: null
  });
  const [showButtons, setShowButtons] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [updateSelected, setUpdateSelected] = useState(false);
  const [snack, setSnack] = useState({ snackType: CONSTS.SUCCESS, snackMsg: '', openSnack: false });
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      //componentDidMount
      props.getCampuses();
    }
    mounted.current = true;
  });

  useEffect(() => {
    if (updateSelected) {
      let campusInfo = props.campusList.filter(c => {
        if (c.name === campus.name)
          return true;
        else return false;
      });
      if (campusInfo.length > 0)
        setCampusSelected(campusInfo[0]);
      setUpdateSelected(false);
    }
  }, [updateSelected]);

  useEffect(() => {
    if (!_.isEmpty(campusSelected)) {
      let filteredRoles = props.roles.filter(role => role.campusId === campusSelected.id) || [];
      setCampusRoles(filteredRoles);
    }
    if (document.getElementById(CONSTS.PREVIEW_LVL_PICKER)) {
      let element = document.getElementById(CONSTS.PREVIEW_LVL_PICKER);
      element.parentNode.removeChild(element);
    }
  }, [campusSelected]);

  useEffect(() => {
    if (!isNewCampus) {
      let campusInfo = props.campusList.filter(c => {
        if (c.name === campus.name)
          return true;
        else return false;
      });
      if (campusInfo.length > 0) {
        setCampus({
          ...campus,
          id: campusInfo[0].id,
          alias: campusInfo[0].alias,
          longitude: parseFloat(campusInfo[0].config.long),
          latitude: parseFloat(campusInfo[0].config.lat),
          floor: parseInt(campusInfo[0].config.default_floor),
          zoom: parseInt(campusInfo[0].config.zoom_level),
          rotation: parseFloat(campusInfo[0].config.rotation),
          firstFloor: parseInt(campusInfo[0].config.firstFloor),
          lastFloor: parseInt(campusInfo[0].config.lastFloor),
          channel: campusInfo[0].config.channel
        });
        setCampusSelected(campusInfo[0]);
      }
    }
  }, [campus.name]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCleanCampus = () => {
    if (editCampusName)
      setEditCampusName(false);
    setCampus({
      ...campus,
      id: campusSelected.id,
      name: campusSelected.name,
      alias: campusSelected.alias,
      longitude: parseFloat(campusSelected.config.long),
      latitude: parseFloat(campusSelected.config.lat),
      floor: parseInt(campusSelected.config.default_floor),
      zoom: parseInt(campusSelected.config.zoom_level),
      rotation: parseFloat(campusSelected.config.rotation),
      firstFloor: parseInt(campusSelected.config.firstFloor),
      lastFloor: parseInt(campusSelected.config.lastFloor),
      channel: campusSelected.config.channel
    });
    if (document.getElementById(`preview-${campusSelected.config.default_floor}`))
      document.getElementById(`preview-${campusSelected.config.default_floor}`).click();
    handleCleanInputs();
  };

  const handlePerformedBy = () => {
    const { user, userId } = props;
    let performedBy = '';
    if (user && user.name) {
      performedBy = user.name;
    } else if (user && user.id) {
      performedBy = user.id;
    } else if (userId) {
      performedBy = userId;
    }
    return performedBy;
  }

  const handleModifiedBy = () => {
    const { user, userId } = props;
    let modifiedBy = '';
    if (user && user.id) {
      modifiedBy = user.id;
    } else if (userId) {
      modifiedBy = userId;
    }
    return modifiedBy;
  }

  const createMapConfigLog = (data) => {
    let modifiedBy = handleModifiedBy();
    let log = {
      campusId: props.campusInfo.id,
      userId: modifiedBy,
      action: data.action,
      description: data.description,
      timestamp: new Date()
    };
    props.createLog(log);
  };

  const editCampus = () => {
    let modifiedBy = handleModifiedBy();
    let campusUpdate = {
      name: campus.name.trim(),
      alias: campus.alias.trim(),
      config: {
        zoom_level: parseInt(campus.zoom),
        lat: parseFloat(campus.latitude),
        long: parseFloat(campus.longitude),
        rotation: parseFloat(campus.rotation),
        channel: campus.channel.trim(),
        default_floor: parseInt(campus.floor),
        firstFloor: parseInt(campus.firstFloor),
        lastFloor: parseInt(campus.lastFloor)
      },
      user_id_modified: modifiedBy,
      id: campusSelected.id,
      organizationId: campusSelected.organizationId,
      venue: campusSelected.venue,
      unit: campusSelected.unit,
      opening: campusSelected.opening,
      level: campusSelected.level,
      disabled: campusSelected.disabled
    };
    handleUploadFiles(campusUpdate);
  };

  const createNewCampus = () => {
    let modifiedBy = handleModifiedBy();
    let performedBy = handlePerformedBy();
    let isNewCampus = {
      name: campus.name.trim(),
      alias: campus.alias.trim(),
      config: {
        zoom_level: parseInt(campus.zoom),
        lat: parseFloat(campus.latitude),
        long: parseFloat(campus.longitude),
        rotation: parseFloat(campus.rotation),
        channel: campus.channel.trim(),
        default_floor: parseInt(campus.floor),
        firstFloor: parseInt(campus.firstFloor),
        lastFloor: parseInt(campus.lastFloor)
      },
      user_id_modified: modifiedBy,
      organizationId: props.campusInfo.organizationId,
      venue: false,
      unit: false,
      opening: false,
      level: false,
      disabled: false
    };
    props.createCampus(isNewCampus).then(res => {
      createMapConfigLog({
        action: CONSTS.CREATE_CAMPUS_ACTION,
        description: `Campus "${isNewCampus.name}" created by "${performedBy}"`
      });
      setShowSpinner(false);
      let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> created successfully!</div>;
      handleOpenSnackbar(CONSTS.SUCCESS, msg);
    }).catch(err => {
      createMapConfigLog({
        action: CONSTS.CREATE_CAMPUS_ACTION_ERROR,
        description: `Campus "${isNewCampus.name}" couldn't be created by "${performedBy}" due endpoint ${err}`
      });
      setShowSpinner(false);
      let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> could not be created!</div>;
      handleOpenSnackbar(CONSTS.WARNING, msg);
    });
    handleCleanNewCampus();
    props.getCampuses();
  };

  const handleSubmit = () => {
    setShowSpinner(true);
    if (!isNewCampus)
      editCampus();
    else
      createNewCampus();
  };

  const handleDeleteCampus = async () => {
    handleCloseModal();
    let campusName = campus.name;
    let performedBy = handlePerformedBy();
    let modifiedBy = handleModifiedBy();
    let campusUpdate = {
      name: campus.name,
      alias: campus.alias,
      config: {
        zoom_level: parseInt(campus.zoom),
        lat: parseFloat(campus.latitude),
        long: parseFloat(campus.longitude),
        rotation: parseFloat(campus.rotation),
        channel: campus.channel,
        default_floor: parseInt(campus.floor),
        firstFloor: parseInt(campus.firstFloor),
        lastFloor: parseInt(campus.lastFloor)
      },
      user_id_modified: modifiedBy,
      id: campusSelected.id,
      organizationId: campusSelected.organizationId,
      venue: campusSelected.venue,
      unit: campusSelected.unit,
      opening: campusSelected.opening,
      level: campusSelected.level,
      disabled: true
    };
    await props.updateCampus(campusSelected.id, campusUpdate)
      .then(res => {
        createMapConfigLog({
          action: CONSTS.DELETE_CAMPUS_ACTION,
          description: `Campus "${campusName}" deleted by "${performedBy}"`
        });
        document.querySelector(".MuiAutocomplete-clearIndicator").click();
        let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> deleted successfully!</div>;
        handleOpenSnackbar(CONSTS.SUCCESS, msg);
        props.getCampuses();
      }).catch(e => {
        createMapConfigLog({
          action: CONSTS.DELETE_CAMPUS_ACTION_ERROR,
          description: `Campus "${campusName}" couldn't be deleted by "${performedBy}" due endpoint ${e}`
        });
        let msg = <div style={{ display: 'inline', color: 'white' }}><div className={classes.msgBold}>{campus.name}</div> could not be deleted!</div>;
        handleOpenSnackbar(CONSTS.WARNING, msg);
      });
    handleCleanNewCampus();
  };

  const handleDownload = (type) => {
    const resolve = CONSTS.DOWNLOAD_FILES(type, campusSelected.id, props.access_token);
    if (resolve === false) {
      let msg = <div className={classes.msg}><div className={classes.msgBold}>{`"${type}"`}</div> file couldn't be downloaded!</div>;
      handleOpenSnackbar(CONSTS.WARNING, msg);
    }
  };

  const handleEditCampusName = (value) => {
    if (!value)
      setCampus({ ...campus, name: campusSelected.name });
    setEditCampusName(value);
  };

  const handleUpload = (type) => {
    if (document.getElementById(type))
      document.getElementById(type).click();
  };

  const handleUploadStatus = () => {
    for (const item of CONSTS.IMDF) {
      if (document.getElementById(`${item.key}-upload`))
        document.getElementById(`${item.key}-upload`).onchange = function () { setFileFlags({ ...fileFlags, [item.key]: true }) }
    }
  };

  const handleUploadFiles = async (campusUpdate) => {
    let success = false;
    if (!fileFlags.level && !fileFlags.venue && !fileFlags.unit && !fileFlags.opening) {
      success = true;
    } else {
      [success, campusUpdate] = await upload_files(fileFlags, campusUpdate);
    }
    let campusInfo = props.campusList.find(campus => campus.id === campusSelected.id);
    let performedBy = handlePerformedBy();

    if (success) {
      await props.updateCampus(campusSelected.id, campusUpdate).then(res => {
        createMapConfigLog({
          action: CONSTS.UPDATE_CAMPUS_ACTION,
          description: `Campus "${campusInfo.name}" updated by "${performedBy}"`
        });
        setShowSpinner(false);
        let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> updated successfully!</div>;
        handleOpenSnackbar(CONSTS.SUCCESS, msg);
      }).catch(e => {
        createMapConfigLog({
          action: CONSTS.UPDATE_CAMPUS_ACTION_ERROR,
          description: `Campus "${campusInfo.name}" couldn't be updated by "${performedBy}" due endpoint ${e}`
        });
        setShowSpinner(false);
        let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> could not be updated!</div>;
        handleOpenSnackbar(CONSTS.WARNING, msg);
      });
    } else {
      createMapConfigLog({
        action: CONSTS.UPDATE_CAMPUS_ACTION_ERROR,
        description: `Campus "${campusInfo.name}" couldn't be updated by "${performedBy}" due map files couldn't be uploaded`
      });
      setShowSpinner(false);
      let msg = <div className={classes.msg}><div className={classes.msgBold}>{campus.name}</div> could not be updated!</div>;
      handleOpenSnackbar(CONSTS.WARNING, msg);
    }
    handleCleanInputs();
    if (editCampusName) {
      setEditCampusName(false);
    }
    props.getCampuses().then(resp => { setUpdateSelected(true); });
  };

  const createIMDFUploadLog = (type, label, error) => {
    const campusId = campusSelected.id;
    let performedBy = handlePerformedBy();
    let campusInfo = props.campusList.find(campus => campus.id === campusId);
    if (type === CONSTS.SUCCESS) {
      createMapConfigLog({
        action: `Upload ${label}`,
        description: `${label} file uploaded for "${campusInfo.name}" uploaded by "${performedBy}"`
      });
    }
    if (type === CONSTS.WARNING) {
      createMapConfigLog({
        action: `Upload ${label} Error`,
        description: `${label} file uploaded for "${campusInfo.name}" couldn't be uploaded by "${performedBy}" due endpoint ${error}`
      });
    }
  };

  const upload_files = async (fileFlags, campusUpdate) => {
    let campusId = campusSelected.id;
    let success = false;
    setShowSpinner(true);
    for (const element of CONSTS.IMDF) {
      if (fileFlags[element.key]) {
        let item = document.getElementById(`${element.key}-upload`).files[0];
        await props.uploadIMDF(campusId, item, element.key).then(res => {
          success = true;
          campusUpdate[element.key] = true;
          createIMDFUploadLog(CONSTS.SUCCESS, element.label, null);
        }).catch(e => {
          createIMDFUploadLog(CONSTS.WARNING, element.label, e);
          success = false;
        });
      }
    }
    setShowSpinner(false);
    return [success, campusUpdate];
  }

  const handleCleanInputs = () => {
    if (document.getElementById(CONSTS.LEVEL_ID)) {
      document.getElementById(CONSTS.LEVEL_ID).value = "";
    }
    if (document.getElementById(CONSTS.OPENING_ID)) {
      document.getElementById(CONSTS.OPENING_ID).value = "";
    }
    if (document.getElementById(CONSTS.UNIT_ID)) {
      document.getElementById(CONSTS.UNIT_ID).value = "";
    }
    if (document.getElementById(CONSTS.VENUE_ID)) {
      document.getElementById(CONSTS.VENUE_ID).value = "";
    }
    setFileFlags({
      level: false,
      opening: false,
      unit: false,
      venue: false
    });
  };

  const handleCleanNewCampus = () => {
    setCampus({
      id: '',
      name: '',
      alias: '',
      longitude: '',
      latitude: 0,
      floor: 0,
      zoom: 0,
      rotation: 0,
      firstFloor: 0,
      lastFloor: 0,
      channel: ''
    });
    setCampusSelected([]);
    setIsNewCampus(false);
    props.getCampuses();
  };

  const handleNewCampus = () => {
    setCampus({
      id: '',
      name: '',
      alias: '',
      longitude: '',
      latitude: 0,
      floor: 0,
      zoom: 0,
      rotation: 0,
      firstFloor: 0,
      lastFloor: 0,
      channel: ''
    });
    setCampusSelected([]);
    setIsNewCampus(true);
    handleCleanInputs();
    props.getCampuses();
  };

  const handleCancelBtn = () => {
    if (isNewCampus)
      handleCleanNewCampus();
    else
      handleCleanCampus();
  };

  const handleOpenSnackbar = (type, msg) => {
    setSnack({ snackType: type, snackMsg: msg, openSnack: true });
  };

  const handleCloseSnackbar = () => {
    setSnack({ snackType: CONSTS.SUCCESS, snackMsg: '', openSnack: false });
  };

  const mapview = () => {
    return (
      <div id="previewContainer" className={classes.previewMapDiv}>
        <PreviewMap
          campusSelected={campusSelected}
          newCampus={isNewCampus}
          campusLongitude={campus.longitude}
          campusLatitude={campus.latitude}
          campusFloor={campus.floor}
          campusZoom={campus.zoom}
          campusRotation={campus.rotation}
          setShowButtons={setShowButtons}
          updateSelected={updateSelected} />
      </div>
    );
  };

  const handleUpdateInput = (newValue, key, type) => {
    switch (type) {
      case CONSTS.TEXT_TYPE:
        setCampus({
          ...campus,
          [key]: newValue
        });
        break;
      case CONSTS.INT_TYPE:
        setCampus({
          ...campus,
          [key]: parseInt(newValue)
        });
        break;
      case CONSTS.FLOAT_TYPE:
        setCampus({
          ...campus,
          [key]: parseFloat(newValue)
        });
        break;
    }
  };

  return (
    <div className={classes.container} id="webNavv-settings-campusSettings-1.0">
      <div className={classes.mainDiv}>
        {!isNewCampus ?
          <React.Fragment>
            <div className={classes.headerDiv}>
              <div className={classes.titleContainer}>
                <GpsFixedIcon className={classes.iconTitle} />
                <h3 className={classes.titleLbl}>{CONSTS.CAMPUS_INFO_TITLE}</h3>
              </div>
              <div className={classes.addHospitalDiv}>
                <MasterButton
                  id="webNavv-settings-campusSettings-campusInfo-addCampusButton-1.0"
                  keyLbl={'add-hospital'}
                  label={CONSTS.ADD_CAMPUS_BTN}
                  buttonType={CONSTS.SUCCESS}
                  isDisabled={false}
                  handleClick={handleNewCampus}
                  size={"medium"}
                  class={classes.addCampus} />
              </div>
            </div>
            {handleUploadStatus()}
            <div className={classes.editDiv}>
              <CampusForm
                handleUpdateInput={handleUpdateInput}
                campus={campus}
                formList={CONSTS.UPDATE_CAMPUS_FORM}
                campusList={props.campusList}
                campusRoles={campusRoles}
                campusSelected={campusSelected}
                fileFlags={fileFlags}
                handleUpload={handleUpload}
                handleDownload={handleDownload}
                handleEditCampusName={handleEditCampusName}
                editCampusName={editCampusName} />
            </div>
          </React.Fragment>
          : <React.Fragment>
            <div style={{ marginTop: '20px' }} className={classes.titleContainer}>
              <ApartmentIcon className={classes.iconTitle} />
              <h3 className={classes.titleLbl}>{CONSTS.NEW_CAMPUS_TITLE}</h3>
            </div>
            <div className={classes.editDiv}>
              <CampusForm
                handleUpdateInput={handleUpdateInput}
                campus={campus}
                formList={CONSTS.CREATE_CAMPUS_FORM}
                campusList={props.campusList}
                campusRoles={campusRoles} />
            </div>
          </React.Fragment>}
      </div>
      <div className={classes.mapHeaderDiv}>
        <div style={{ padding: '10px', display: "flex" }}>
          <div style={{ width: '340px' }} className={classes.titleContainer}>
            <LocationOnIcon className={classes.iconTitle} />
            <h3 className={classes.titleLbl}>{CONSTS.MAP_PREVIEW_TITLE}</h3>
          </div>
        </div>
        {props.currentView && mapview()}
        {isNewCampus &&
          <div className={classes.editBtnDiv}>
            <MasterButton
              keyLbl={'cancel-campus'}
              label={CONSTS.CANCEL_BTN_LBL}
              buttonType={'default-flat'}
              isDisabled={false}
              handleClick={handleCancelBtn}
              size={"medium"} />
            <MasterButton
              keyLbl={'save-campus'}
              label={CONSTS.SAVE_BTN_LBL}
              buttonType={CONSTS.SUCCESS}
              isDisabled={false}
              handleClick={handleSubmit}
              size={"medium"}
              styles={{ marginLeft: '15px' }}
              class={classes.containedBtn} />
          </div>
        }
        {/* { ((!_.isEmpty(campusSelected) && !isNewCampus) || isNewCampus) && */}
        {!_.isEmpty(campusSelected) && !isNewCampus && showButtons &&
          <div className={classes.editBtnDiv}>
            <MasterButton
              keyLbl={'cancel-campus'}
              label={CONSTS.CANCEL_BTN_LBL}
              buttonType={'default-flat'}
              isDisabled={false}
              handleClick={handleCancelBtn}
              size={"medium"} />
            <MasterButton
              keyLbl={'save-campus'}
              label={CONSTS.SAVE_BTN_LBL}
              buttonType={CONSTS.SUCCESS}
              isDisabled={false}
              handleClick={handleSubmit}
              size={"medium"}
              styles={{ marginLeft: '15px' }}
              class={classes.containedBtn} />
            <MasterButton
              keyLbl={'delete-campus'}
              label={CONSTS.DELETE_BTN_LBL}
              buttonType={CONSTS.ERROR}
              isDisabled={false}
              handleClick={handleOpenModal}
              size={"medium"}
              styles={{ marginLeft: '20px', height: '36px' }}
              endIcon={CONSTS.TRASH_ICON} />
          </div>
        }
      </div>
      <MasterAlert
        snackStyle={{ marginTop: '5vh' }}
        anchor={{ vertical: 'top', horizontal: 'right' }}
        openSnack={snack.openSnack}
        handleClose={handleCloseSnackbar}
        alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
        elevation={6}
        variant={"filled"}
        severity={snack.snackType}
        message={snack.snackMsg}
      />
      <ModalPop
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        icon={<ReportProblemIcon className={classes.problemIcon} />}
        title={CONSTS.MODAL_TITLE}
        subtitle={`You are going to delete "${campus.name}"!`}
        handleFirstOption={handleDeleteCampus}
        handleSecondOption={handleCloseModal}
        button1Label={CONSTS.DELETE_BTN_LBL}
        button2Label={CONSTS.CANCEL_BTN_LBL}
      />
      <AwesomeSpinner config={CONSTS.DEFAULT_SPINNER_CONFIG} loading={showSpinner} navOpen={props.open} />
    </div>
  );
}

const mapStateToProps = state => ({
  access_token: state.auth.token,
  user: state.user.user,
  campusInfo: state.auth.userInfo.campusInfo,
  campusList: state.campus.campusList,
  roles: state.acl.acls,
  open: state.views.drawerOpen,
  currentView: state.views.currentView,
  userId: state.auth.userId
});

export default connect(mapStateToProps, {
  getCampuses,
  updateCampus,
  createCampus,
  uploadIMDF,
  createLog
})(MapConfig);