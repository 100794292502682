import React from 'react';

import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BugReportIcon from '@material-ui/icons/BugReport';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

export const TABS_TITLE = 'Super Admin Panel';
export const ROLE = 'Super Admin';
export const CAMPUS_TITLE = 'SUPER ADMIN PANEL';
export const DEFAULT_OPTION = 'US';
export const DRAWER_WIDTH = 300;
export const DEFAULT_PASS = '12345';
export const DEFAULT_SELECTOR = {
  name: "ALL",
  id: ''
}

export const SET_TABS = (props) =>{
    let tableTabInfo=[
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "dashboard",
        ff: "Dashboard",
        label: "DA",
        primaryText: "Dashboard",
        style: { marginTop: "10px" },
        leftIcon:  <BarChartIcon style={
          props.itemSelected === "DA"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "superadmin-tab"
      },
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "statistics",
        ff: "Statistics",
        label: "ST",
        primaryText: "Statistics",
        style: { marginTop: "5px" },
        leftIcon:  <PieChartIcon style={
          props.itemSelected === "ST"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "statistics-tab"
      },
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "Users",
        ff: "Users",
        label: "US",
        primaryText: "Users",
        style: { marginTop: "5px" },
        leftIcon:  <PersonIcon style={
          props.itemSelected === "US"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "users-tab"
      },
      /*
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "equipment",
        ff: "Equipment",
        label: "EQ",
        primaryText: "Equipment",
        style: { marginTop: "5px" },
        leftIcon:  <BusinessCenterIcon style={
          props.itemSelected === "EQ"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "equiptment-tab"
      },
      */
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "debugtool",
        ff: "DebugTool",
        label: "DT",
        primaryText: "Debug Tool",
        style: { marginTop: "5px" },
        leftIcon:  <BugReportIcon style={
          props.itemSelected === "DT"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "debug-tool-tab"
      },
      {
        action: 'changeView',
        view: "superadmin",
        viewProp: "logs",
        ff: "Logs",
        label: "LO",
        primaryText: "Logs",
        style: { marginTop: "5px" },
        leftIcon:  <LibraryBooksIcon style={
          props.itemSelected === "LO"
            ? { fill: "#2196F3" }
            : { fill: "#757575" }
        }/>,
        type: "icon",
        key: "logs-tab"
      },
    ];
    return tableTabInfo;
  };