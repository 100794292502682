/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const newAssociatedJob_Template = /* GraphQL */ `
  subscription NewAssociatedJob_Template {
    newAssociatedJob_Template {
      jobid
      job_name
      job_type
      job_description
      job_start_date
      job_end_date
      job_typeid
      description
      issequence_required
      job_statusid
      job_status
      resource_slotid
      modified_by
      job_percentage_complete
      job_number
      counterdoy
    }
  }
`;
export const newStandaloneJob_Template = /* GraphQL */ `
  subscription NewStandaloneJob_Template {
    newStandaloneJob_Template {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const newResource_Slot = /* GraphQL */ `
  subscription NewResource_Slot {
    newResource_Slot {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const newCreateTask_Template = /* GraphQL */ `
  subscription NewCreateTask_Template {
    newCreateTask_Template {
      task_templateid
      task_template_name
      description
      special_instructions
      job_templateid
      task_template_start_date
      task_template_end_date
      task_typeid
      task_locationid
      task_statusid
      task_actionid
      suggested_sequence
      tenantid
    }
  }
`;
export const dropResource_Slot = /* GraphQL */ `
  subscription DropResource_Slot {
    dropResource_Slot {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const dropJob_Template = /* GraphQL */ `
  subscription DropJob_Template {
    dropJob_Template {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const modifyJob_Template = /* GraphQL */ `
  subscription ModifyJob_Template {
    modifyJob_Template {
      job_templateid
      job_template_name
      job_type
      description
      job_template_description
      job_template_start_date
      job_template_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
    }
  }
`;
export const modifyResource_Slot = /* GraphQL */ `
  subscription ModifyResource_Slot {
    modifyResource_Slot {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const modifyResource_SlotUnassignUser = /* GraphQL */ `
  subscription ModifyResource_SlotUnassignUser {
    modifyResource_SlotUnassignUser {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const modifyAssociatedJob_Template = /* GraphQL */ `
  subscription ModifyAssociatedJob_Template {
    modifyAssociatedJob_Template {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const modifyTask = /* GraphQL */ `
  subscription ModifyTask {
    modifyTask {
      taskid
      floor
      task_name
      jobid
      job_statusid
      job_status
      task_description
      special_instructions
      task_typeid
      task_type
      task_locationid
      location_name
      task_statusid
      task_status
      task_actionid
      task_action
      task_start_date
      task_end_date
      suggested_sequence
      job_percentage_complete
      floor_name
      distance_traveled
      unstarted_taskid
    }
  }
`;
export const dropResource_SlotJobs = /* GraphQL */ `
  subscription DropResource_SlotJobs {
    dropResource_SlotJobs {
      resource_slotid
      resource_slot_name
      assigned_user_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      job_statusid
      job_status
      teamid
      team_name
      assigned_userid
      job_type
      job_typeid
    }
  }
`;
export const dropAssociatedJob = /* GraphQL */ `
  subscription DropAssociatedJob {
    dropAssociatedJob {
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
export const modifyJobInerror = /* GraphQL */ `
  subscription ModifyJobInerror {
    modifyJobInerror {
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      jobid
      taskid
      tenantid
      job_name
      job_type
      job_description
      job_start_date
      job_end_date
      job_typeid
      issequence_required
      job_statusid
      job_status
      resource_slotid
      modified_by
      job_percentage_complete
      job_number
      job_started_date
      job_ended_date
      job_mobile_sessionid
    }
  }
`;
export const modifyDelayedJobs = /* GraphQL */ `
  subscription ModifyDelayedJobs {
    modifyDelayedJobs {
      success
      resource_slotid
      resource_slot_name
      resource_slot_description
      resource_slot_start_date
      resource_slot_end_date
      assigned_userid
      assigned_user_name
      job_assigned_userid
      job_assigned_user_name
      jobid
      job_name
      job_description
      teamid
      team_name
      job_typeid
      job_type
      job_statusid
      job_status
      job_start_date
      job_end_date
      job_started_date
      job_ended_date
      tenantid
      job_percentage_complete
      job_mobile_sessionid
      job_errorid
      job_error_numberid
      job_error_number
      job_error_description
      job_number
      unstarted_taskid
    }
  }
`;
