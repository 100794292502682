import axios from 'axios';
import hostName from '../../utilities/hostName';
export const PREVIEW_LVL_PICKER= 'preview-levelpicker';
export const LEVEL_ID='level-upload';
export const UNIT_ID='unit-upload';
export const OPENING_ID='opening-upload';
export const VENUE_ID='venue-upload';
export const SUCCESS='success';
export const WARNING='warning';
export const ERROR='error';
export const CREATE_CAMPUS_ACTION='Create New Campus';
export const CREATE_CAMPUS_ACTION_ERROR='Create New Campus Error';
export const DELETE_CAMPUS_ACTION='Delete Campus';
export const DELETE_CAMPUS_ACTION_ERROR='Delete Campus Error';
export const UPDATE_CAMPUS_ACTION='Update Campus';
export const UPDATE_CAMPUS_ACTION_ERROR='Update Campus Error';
export const ADD_CAMPUS_BTN='Add Campus';
export const MAP_PREVIEW_TITLE='MAP PREVIEW';
export const NEW_CAMPUS_TITLE='NEW CAMPUS';
export const CAMPUS_INFO_TITLE='CAMPUS INFO';
export const FLOAT_TYPE='float';
export const TEXT_TYPE='text';
export const INT_TYPE='int';
export const CANCEL_BTN_LBL='Cancel';
export const SAVE_BTN_LBL='Save';
export const DELETE_BTN_LBL='Delete';
export const TRASH_ICON='trashCan';
export const MODAL_TITLE="Are you sure?";
export const DEFAULT_SPINNER_CONFIG='default';
export const VENUE='venue';
export const UNIT='unit';
export const LEVEL='level';
export const OPENING='opening';

export const CREATE_CAMPUS_FORM = [
  {
    label: 'Campus Name:',
    key: 'name',
    inputType: 'text',
    id: "webNavv-settings-campusSettings-campusInfo-campusName-1.0"
  },
  {
    label: 'Alias:',
    key: 'alias',
    inputType: 'text',
    id: "webNavv-settings-campusSettings-campusInfo-alias-1.0"
  },
  {
    label: 'Channel:',
    key: 'channel',
    inputType: 'text',
    id: "webNavv-settings-campusSettings-campusInfo-channel-1.0"
  },
  {
    label: 'Latitude:',
    key: 'latitude',
    inputType: 'longNumber',
    id: "webNavv-settings-campusSettings-campusInfo-latitude-1.0"
  },
  {
    label: 'Longitude:',
    key: 'longitude',
    inputType: 'longNumber',
    id: "webNavv-settings-campusSettings-campusInfo-longitude-1.0"
  },
  {
    labelOne: 'First Floor:',
    keyOne: 'firstFloor',
    labelTwo: 'Last Floor:',
    keyTwo: 'lastFloor',
    inputType: 'doubleNumber',
    id: "webNavv-settings-campusSettings-campusInfo-floor-1.0"
  },
  {
    label: 'Default Floor:',
    key: 'floor',
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-defaultFloor-1.0"
  },
  {
    label: 'Zoom Level (0 - 4):',
    key: 'zoom',
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-zoomLevel-1.0"
  },
  {
    label: 'Rotation:',
    key: 'rotation',
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-rotation-1.0"
  }
];

export const UPDATE_CAMPUS_FORM = [
  // {label: 'Team:', key: 'team', inputType: 'autocomplete'},
  {
    label: 'Campus Name:', 
    key: 'name', 
    inputType: 'autocomplete',
    id: "webNavv-settings-campusSettings-campusInfo-campusName-1.0"
  },
  {
    label: 'Campus ID:', 
    key: 'id', 
    inputType: 'text-disabled',
    id: "webNavv-settings-campusSettings-campusInfo-campusId-1.0"
  },
  {
    label: 'Alias:', 
    key: 'alias', 
    inputType: 'text',
    id: "webNavv-settings-campusSettings-campusInfo-alias-1.0"
  },
  {
    label: 'Channel:', 
    key: 'channel', 
    inputType: 'text',
    id: "webNavv-settings-campusSettings-campusInfo-channel-1.0"
  },
  {
    label: 'Latitude:', 
    key: 'latitude', 
    inputType: 'longNumber',
    id: "webNavv-settings-campusSettings-campusInfo-latitude-1.0"
  },
  {
    label: 'Longitude:', 
    key: 'longitude', 
    inputType: 'longNumber',
    id: "webNavv-settings-campusSettings-campusInfo-longitude-1.0"
  },
  {
    labelOne: 'First Floor:', 
    keyOne: 'firstFloor', 
    labelTwo: 'Last Floor:', 
    keyTwo: 'lastFloor', 
    inputType: 'doubleNumber',
    id: "webNavv-settings-campusSettings-campusInfo-floor-1.0"
  },
  {
    label: 'Default Floor:', 
    key: 'floor', 
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-defaultFloor-1.0"
  },
  {
    label: 'Zoom Level (0 - 4):', 
    key: 'zoom', 
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-zoomLevel-1.0"
  },
  {
    label: 'Rotation:', 
    key: 'rotation', 
    inputType: 'number',
    id: "webNavv-settings-campusSettings-campusInfo-rotation-1.0"
  },
  {
    label: 'Venue:', 
    key: 'venue', 
    inputType: 'upload-file', 
    type: 'venue-upload',
    id: "webNavv-settings-campusSettings-campusInfo-venue-1.0"
  },
  {
    label: 'Unit:', 
    key: 'unit', 
    inputType: 'upload-file',
    type: 'unit-upload',
    id: "webNavv-settings-campusSettings-campusInfo-unit-1.0"
  },
  {
    label: 'Opening:', 
    key: 'opening', 
    inputType: 'upload-file',
    type: 'opening-upload',
    id: "webNavv-settings-campusSettings-campusInfo-opening-1.0"
  },
  {
    label: 'Level:', 
    key: 'level', 
    inputType: 'upload-file',
    type: 'level-upload',
    id: "webNavv-settings-campusSettings-campusInfo-level-1.0"
  }
];

export const IMDF = [
  {key: 'venue',label: 'Venue', path:'venues'},
  {key: 'unit',label: 'Unit', path:'units'},
  {key: 'opening',label: 'Opening', path:'openings'},
  {key: 'level',label: 'Level', path:'levels'}
];

const secureUrl = new hostName();
secureUrl.initHostName();

export const DELETE_FILES = async (campus, token)=>{
  let success = false;
  for (const element of IMDF) {
    if (campus[element.key]) {
      try{
        await axios.get(secureUrl.hostUrl + `/api/${element.path}/${element.key}IDBycampus?campusId=${campus.id}&access_token=${token}`)
        .then(res=>{
          axios.delete(secureUrl.hostUrl + `/api/${element.path}/${res.data.response[0]._id}?&access_token=${token}`)})
        .then(res=>{
          success = true})
      }catch(error){
        console.error(`[MAPCONFIG] an error ocurred in DELETE_FILES, couldn't delete ${element.key}`, error);
        success = false;
      }
    }
  }

  return success;
};

export const DOWNLOAD_FILES = (type, campusId, token) =>{
  let file = IMDF.find(item=>item.key === type);
  try{
    axios({
      url: secureUrl.hostUrl + `/api/${file.path}/${file.path}ByCampus?campusId=${campusId}&access_token=${token}`,
      method: 'GET',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data.response[0],0,4)]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file.key}.json`);
      document.body.appendChild(link);
      link.click();
      return(true);
    })
  }catch(error){
    console.error("[MAPCONFIG] An error ocurred in DOWNLOAD_FILES: ", error);
    return(false);
  }
};