import { createMuiTheme } from "@material-ui/core/styles";

export const ADD='ADD';
export const EDIT='EDIT';
export const CANCEL='CANCEL';
export const ELEMENTS=["default", "hidden"];
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const SUCCESS='success';
export const WARNING='warning';
export const DEFAULT='default';
export const MODAL_TITLE="Are you sure?";
export const MODAL_CANCEL='Cancel';
export const MODAL_DELETE='Delete';
export const DELETE_ACTION='Delete Equipment Category';
export const DELETE_ACTION_ERROR='Delete Equipment Category Error';
export const DELETE_MSG='Category deleted successfully!';
export const DELETE_MSG_ERROR="Category couldn't be deleted!";
export const CATEGORY_EXISTS='Category already exists!';
export const CATEGORY_EMPTY='Category cannot be empty!';
export const CREATE_ACTION='Create Equipment Category';
export const CREATE_ACTION_ERROR='Create Equipment Category Error';
export const CREATE_MSG="Category created successfully!";
export const CREATE_MSG_ERROR="Category couldn't be created!";
export const UPDATE_ACTION='Update Equipment Category';
export const UPDATE_ACTION_ERROR='Update Equipment Category Error';
export const UPDATE_MSG="Category updated successfully!";
export const UPDATE_MSG_ERROR="Category couldn't be updated!";
export const TITLE='EQUIPMENT CATEGORY';
export const NAME='Name';
export const OWNER='Owner';
export const MEDIUM='medium';
export const TABLE_EVENTS={
  changeRowsPerPage: 'changeRowsPerPage'
};
export const TABLE_OPTIONS={
    filterType:"dropdown",
    responsive:"standard",
    rowsPerPage:5,
    rowsPerPageOptions:[5, 10, 30],
    selectableRows:'single',
    defaultSort:'asc',
    filterTable:"Filter Table"
};
export const COLUMNS={
    name:{
        no:0,
        id:'name',
        label:'Label'
    },
    owner:{
        no:1,
        id:'owner',
        label:'Owner'
    },
    equipments:{
        no:2,
        id:'equipments',
        label:'Equipment'
    },
    dateCreated:{
        no:3,
        id:'dateCreated',
        label:'Date Created'
    },
    lastModification:{
        no:4,
        id:'lastModification',
        label:'Last Modification'
    }
};

export const TABLE_SORT = (data, colIndex, order) => {
    if (colIndex === 0 || colIndex === 1) {
      return data.sort((a, b) =>{
        return a.data[colIndex].localeCompare(b.data[colIndex]) * (order? 1 : -1);
      });
    }else if(colIndex === 2){
      return data.sort((a, b) => {
        return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order? 1 : -1);
      });
    } else {
      return data.sort((a, b) => {
        return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order? 1 : -1);
      });
    }
};

export const getMuiTheme = () =>
createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "635px !important",
      },
      paper: {
        boxShadow: "0 4px 2px -2px gray",
        paddingTop: "1px",
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        color: "#7D7D7D",
        float: 'none !important'
      },
      contentWrapper:{
        justifyContent: "center",
        display: "flex",
      }
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: "center",
        padding: "0 !important"
      },
    },
  },
});