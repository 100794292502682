import moment from "moment";
import { jobStatusTypesGraphQL, ERROR_TASKS, STATUS_STYLES } from "../jobsBoard.constants";

const checkJobStatus = (currentJob) =>{
    return currentJob.status === jobStatusTypesGraphQL.error || currentJob.status === jobStatusTypesGraphQL.complete || currentJob.status === jobStatusTypesGraphQL.completeWithError || currentJob.status === jobStatusTypesGraphQL.inProgress || currentJob.status === jobStatusTypesGraphQL.inProgressWithError;
}

export const getStartPointDate = (currentJob) =>{
    let startPointDate = moment(currentJob.job_start_date).format("hh:mm:ss A");
    if (checkJobStatus(currentJob)){
        if (currentJob.job_started_date){
            startPointDate =  moment(currentJob.job_started_date).format("hh:mm:ss A");
        }
        else {
            startPointDate = "N/A";
        }
    }
    return startPointDate
}

export const getStartPointDateFlagColor = (startPointError, currentJob) =>{
    let color = '#2843A3';
    if (startPointError) {
        color =  STATUS_STYLES.error.color;
    }
    else if (checkJobStatus(currentJob)){
        color =  STATUS_STYLES.complete.color;
    }
    return color
}

export const handleErrorDescription = (errorNumber) =>{
    switch (errorNumber) {
        case ERROR_TASKS.scanOverride.key:
            return ERROR_TASKS.scanOverride.description;
        case ERROR_TASKS.locationSkipped.key:
            return ERROR_TASKS.locationSkipped.description;
        case ERROR_TASKS.jobCancelled.key:
            return ERROR_TASKS.jobCancelled.description;
        case ERROR_TASKS.jobStartDelay.key:
            return ERROR_TASKS.jobStartDelay.description;
        case ERROR_TASKS.scanError.key:
            return ERROR_TASKS.scanError.description;
        default:
            return errorNumber
    }
}