import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import lightBlue from "@material-ui/core/colors/lightBlue";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const useStyles = makeStyles((theme) => ({
  problemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  },
  mainDiv: {
    display: 'block',
    flexGrow: 1,
    height: '80%',
    padding: '30px'
  },
  container: {
    backgroundColor: 'white',
    display: 'content',
    boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
    width: '100vh',
    marginLeft: '20vh',
    marginTop: '17vh'
  },
  containerOpen: {
    backgroundColor: 'white',
    display: 'content',
    boxShadow: 'rgba(0, 0, 0, 0.23) 0px 3px 10px',
    width: '100vh',
    marginLeft: '10vh',
    marginTop: '17vh'
  },
  containedBtn: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  btnDiv: {
    display:'inline',
    float:'right',
    marginLeft: '35%',
  },
  btnDivEdit: {
    display:'inline',
    float:'right',
    marginLeft: '45%',
  },
  buttonContainer: {
    height:'20%',
    display: 'block',
    float:'right',
    paddingRight: '30px'
  },
  back: {
    position: 'absolute',
    marginTop: '-5vh',
    marginLeft: '22vh',
    marginBottom: '1%',
    color:'rgba(0, 0, 0, 0.4)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    "&:hover": {
      color: lightBlue[600],
      cursor: 'pointer'
    }
  },
  backOpen: {
    position: 'absolute',
    marginTop: '-5vh',
    marginLeft: '12vh',
    marginBottom: '1%',
    color:'rgba(0, 0, 0, 0.4)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    "&:hover": {
      color: lightBlue[600],
      cursor: 'pointer'
    }
  },
  goToMapOpen: {
    position: 'absolute',
    marginTop: '-5vh',
    marginLeft: '115vh',
    marginBottom: '1%',
    color:'rgba(0, 0, 0, 0.4)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    "&:hover": {
      color: lightBlue[600]
    }
  },
  goToMap: {
    position: 'absolute',
    marginTop: '-5vh',
    marginLeft: '140vh',
    marginBottom: '1%',
    color:'rgba(0, 0, 0, 0.4)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    "&:hover": {
      color: lightBlue[600]
    }
  },
  titleLbl: {
    marginLeft: '10px',
    display: 'inline',
    paddingTop: '5px'
  },
  equipmentName: {
    display:'block',
    fontSize: '18px',
    color:'rgba(0, 0, 0, 0.6)',
    fontWeight: '500',
    letterSpacing:'0.25px',
  },
  equipmentSubtitle: {
    display:'inline',
    fontSize: '14px',
    color:'rgba(0, 0, 0, 0.4)',
    fontWeight: '500',
    letterSpacing:'0.25px'
  },
  equipmentValue: {
    display:'inline',
    fontSize: '14px',
    color:'rgba(0, 0, 0, 0.8)',
    fontWeight: '500',
    letterSpacing:'0.25px',
    marginLeft: '5px'
  },
  inlineContainer: {
    display: 'inline'
  },
  iconTitle: {
    color:'rgba(0, 0, 0, 0.6)',
    fontSize: '80px'
  },
  titleContainer: {
    display:'inline'
  },
  formControlTop: {
    marginTop: '20px'
  }
}));

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);