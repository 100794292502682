import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import lightBlue from "@material-ui/core/colors/lightBlue";

export const CustomSlider = withStyles({
    root: {
      color: lightBlue[600],
      height: 8,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#f5f5f5',
      border: '2px solid #f5f5f5',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 4,
      borderRadius: 4,
    },
    rail: {
      height: 4,
      borderRadius: 4,
    },
  })(Slider);

export const CustomCheckbox = withStyles({
    root: {
      color: 'white',
      '&$checked': {
        color: 'white',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export const timeWindowStyles = makeStyles({
    root: {
      marginLeft: 30,
      marginTop: 5,
      display: 'flex'
    },
    slider: {
      display: 'inline',
      width: 200,
      paddingTop: 30,
      marginLeft: 10
    },
    dateTitle: {
      fontSize: '11px',
      textAlign: 'center',
      letterSpacing: '0.019em',
      fontStyle: 'italic'
    },
    timeLbl: {
      fontSize: '13px',
      textAlign: 'center',
      letterSpacing: '0.019em',
      fontWeight: 'bold'
    },
    dateLbl: {
      fontSize: '13px',
      textAlign: 'center',
      letterSpacing: '0.019em'
    },
    startDate: {
      display: 'inline',
      textAlign: 'center',
      color: '#2196F3'
    },
    endDate: {
      display: 'inline',
      textAlign: 'center',
      color: '#6F6F6F',
      marginLeft: 10
    },
    containedBtn: {
      marginTop: 30,
      marginLeft: 20,
      color: 'white',
      backgroundColor: lightBlue[600],
      "&:hover": {
        backgroundColor: lightBlue[800],
        "@media (hover: none)": {
          backgroundColor: lightBlue[600]
        }
      }
    },
  });

  export const jobTracesStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:'#2843A3'
      },
      formControl: {
        margin: theme.spacing(2),
      },
      labelLink:{
        color:'white',
        paddingBottom:'3px',
        "&:hover": {
          cursor:'pointer',
          fontWeight:'bold',
          borderBottom: '2px solid white'
        }
      },
      labelLinkSelected:{
        color:'white',
        paddingBottom:'3px',
        borderBottom: '2px solid white',
        fontWeight:'bold'
      },
      iconJob:{
        fontSize:"13px",
        fill:'white'
      },
      iconRefresh:{
        fontSize:"14px",
        fill:'white'
      },
      iconButton:{
        marginLeft:'5px'
      },
      defaultJobBar:{
        display:'flex', 
        width:'380px', 
        marginLeft:'30px'
      },
      multipleJobBar:{
        display:'flex',
        width:'380px'
      }
  }));

  export const historyMapStyles = theme => ({
    paper: {
      minWidth: "700px",
      minHeight: "100%",
    },
    containedBtn: {
      marginLeft: 20,
      color: 'white',
      backgroundColor: lightBlue[600],
      "&:hover": {
        backgroundColor: lightBlue[800],
        "@media (hover: none)": {
          backgroundColor: lightBlue[600]
        }
      }
    },
    mapFilterDiv: {
      position: 'fixed !important',
      display: 'flex',
      zIndex: "1002",
      width: '550px',
      top: 0,
      left: 0,
      height: '100%'
    },
    backIcon: {
      fontWeight: 'bold',
      fill: 'white',
      marginLeft: '20px',
      marginTop: '20px',
      "&:hover": {
        cursor: 'pointer'
      }
    },
    animationIconDiv: {
      display: 'inline',
      zIndex: "1000",
      height: '58px',
      width: '1px',
      transform: 'translate(-1vh,90vh)'
    },
    exitIconDiv: {
      display: 'inline',
      zIndex: "1000",
      backgroundColor: '#2843A3',
      height: '58px',
      width: '30px',
      transform: 'translate(0vh,0vh)'
    },
    exitIconAnimationDiv: {
      display: 'inline',
      zIndex: "1000",
      backgroundColor: '#2843A3',
      height: '58px',
      width: '30px',
      transform: 'translate(1vh,0vh)'
    },
    exitIcon: {
      fill: 'white',
      marginLeft: '5px',
      marginTop: '20px',
      fontSize: '15px',
      "&:hover": {
        cursor: 'pointer'
      }
    },
    subtitleDiv: {
      display: 'inline',
      width: '30%',
      textAlign: 'left',
      height: '10%'
    },
    infoDiv: {
      display: 'inline',
      width: '70%',
      marginLeft: '20px',
      textAlign: 'left',
      height: '10%'
    },
    nestedSubtitle: {
      padding: "2px 0px 1px 25px !important",
      fontSize: "13px !important",
    },
    nestedTxt: {
      color: 'white',
      fontSize: "12px !important",
      letterSpacing: '0.019em',
      marginLeft: '30px'
    },
    nestedTxtTitle: {
      color: 'white',
      padding: "1px 0px 1px 25px !important",
      fontSize: "13px !important",
      letterSpacing: '0.019em',
      fontWeight: 'bold'
    },
    nested: {
      padding: "5px 0px 15px 25px !important",
      fontSize: "13px !important",
    },
    nestedChip: {
      fontSize: "13px !important",
      marginLeft: "5px"
    },
    nestedBtn: {
      padding: "0px 0px 1px 15px !important",
      fontSize: "13px !important",
    },
    icon: {
      color: "#0277bd"
    },
    iconEnd: {
      color: "#757575"
    },
    iconJob: {
      fontSize: "13px"
    },
    iconButton: {
    },
    floorFilter: {
      height: "20px",
      marginBottom: "15px",
    },
    openAnimationPanelIcon: {
      height: '20px !important',
      width: '20px',
      marginTop: '0.5vh',
      backgroundColor: 'none'
    },
  });