import { SAVE_LASTLOCATION_FROM_SOCKET } from './types';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const setLastLocation = (data) => dispatch => {
    dispatch({
      type: SAVE_LASTLOCATION_FROM_SOCKET,
      payload: data,
    });
}