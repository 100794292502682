import moment from "moment";
import { mutationCreateAssociatedJob } from "../../../graphql/mutations";
import { SPECIMEN_COLLECTION_TYPE, jobStatusTypesGraphQL} from "../../jobsBoard.constants";
import { checkIfItemCanBeMoved } from "./job-Move";
import _ from 'lodash';

export const handleCreateNewJobFromJobTemplate = async(items, currentItemSelected, dragTime, newGroupOrder, groups, jobTemplates, tenantId, jobStatusList) =>{
  const item = items.find(item=> item.id === currentItemSelected);
  const timeCanBeMoved = checkIfItemCanBeMoved(item, dragTime, newGroupOrder, items, groups);

  if (timeCanBeMoved) { 
    const currentJobTemplate = jobTemplates.find(template => template.id === currentItemSelected);
    const slotToBeMoved = groups.find(group => group.id === newGroupOrder);
    const startDateFromDrag = moment(dragTime).utc().format();
    const endDateFromDrag = moment(startDateFromDrag).add(currentJobTemplate.duration, "minutes").utc().format();
    let currentJobStatus = jobStatusList.find(status => status.job_status === slotToBeMoved.status);

    if (slotToBeMoved.status === jobStatusTypesGraphQL.inProgress || slotToBeMoved.status === jobStatusTypesGraphQL.inProgressWithError) {
      currentJobStatus = jobStatusList.find(status => status.job_status === jobStatusTypesGraphQL.assigned);
    }

    const CreateAssociatedJob_Input = {
      id: "",
      issequence_required: "false",
      job_start_date: startDateFromDrag,
      job_end_date: endDateFromDrag,
      job_statusid: currentJobStatus.job_statusid,
      resource_slotid: slotToBeMoved.resource_slotid,
      job_description: currentJobTemplate.description,
      job_name: currentJobTemplate.title,
      job_templateid: currentJobTemplate.templateid,
      job_typeid: SPECIMEN_COLLECTION_TYPE,
      tenantid: tenantId
    }

    const jobCreated = await mutationCreateAssociatedJob(CreateAssociatedJob_Input);
    if (jobCreated) {
      return "done";
    }
    else {
      return "error";
    }
  }
}