export const DEFAULT_SORT='asc';
export const SNACKBAR_SUCCESS='success';
export const SNACKBAR_WARNING='warning';
export const DELETE_EQ_ACTION='Delete Equipment';
export const DELETE_EQ_ACTION_ERROR='Delete Equipment Error';
export const TABLE_ID='EQ';
export const TABLE_TITLE='Equipment';
export const SPINNER_CONFIG='default';
export const ALERT_VARIANT='filled';
export const MODAL_TITLE="Are you sure?";
export const MODAL_SUBTITLE=`You are going to delete this row(s)!`;
export const DELETE_BTN='Delete';
export const CANCEL_BTN='Cancel';
export const ROWS_PER_PAGE=[10, 30, 50, 100];
export const TABLE_EVENTS={
  changeRowsPerPage:'changeRowsPerPage',
  propsUpdate:'propsUpdate',
  search:'search',
  filterChange:'filterChange',
  onSearchClose:'onSearchClose',
  resetFilters:'resetFilters'
};
export const STRING_INPUT_TYPE={
  equipment:{
    id:'equipment',
    label:'Equipment'
  },
  team:{
    id:'team',
    label:'Team'
  }
};
export const COLUMNS={
  equipment:{
    id:'equipment',
    no:0,
    label:'Equipment'
  },
  beaconNum:{
    id:'beaconNum',
    no:1,
    label:'Beacon Number'
  },
  major:{
    id:'major',
    no:2,
    label:'Major'
  },
  minor:{
    id:'minor',
    no:3,
    label:'Minor'
  },
  controlNum:{
    id:'controlNum',
    no:4,
    label:'Control Number'
  },
  team:{
    id:'team',
    no:5,
    label:'Team'
  },
  model:{
    id:'model',
    no:6,
    label:'Model'
  },
  maker:{
    id:'maker',
    no:7,
    label:'Maker'
  },
  department:{
    id:'department',
    no:8,
    label:'Department'
  },
  trackingId:{
    id:'trackingId',
    no:9,
    label:'TrackingId'
  }
};
export const FILTER_TYPE={
  custom:'custom',
  textField:'textField'
};
export const TABLE_OPTIONS={
  filterType: "dropdown",
  responsive: "standard",
  selectableRows: "single",
  filename: `Equipment.csv`,
  separator: ";"
};

export const TABLE_SORT = (data, colIndex, order) => {
    if (colIndex === 0 || colIndex === 5 || colIndex === 7 || colIndex === 8 || colIndex === 9 ) {
      return data.sort((a, b) => {
        return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order ? 1 : -1);
      });
    } else {
      return data.sort((a, b) => {
        return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (order? 1 : -1);
      });
    }
};