
export const handleUpdateTaskAndJobOnTaskChange =  async (updatedTask, jobTasks, items) =>{
    const jobTasksCopy = [...jobTasks];
    jobTasks.forEach((element, i) => {
      if (element.taskid === updatedTask.taskid) {
        jobTasksCopy[i].task_start_date = updatedTask.task_start_date;
        jobTasksCopy[i].task_end_date = updatedTask.task_end_date;
        jobTasksCopy[i].task_status = updatedTask.task_status;
        jobTasksCopy[i].task_statusid = updatedTask.task_statusid;
        jobTasksCopy[i].jobid = updatedTask.jobid;
      }
    });
  
    const jobsCopy = [...items];
    items.forEach((job, i) =>{
      if (job.jobid === updatedTask.jobid) {
        jobsCopy[i].progress = updatedTask.job_percentage_complete;
      }
    });
  
    return {jobTasksCopy, jobsCopy}
}