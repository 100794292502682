import {
  ANNOTATION_SUBTITLES,
  GPS_COLOR,
  TRACK_DEVICE,
  TRACK_USER,
  TRACK_NONE,
  OFFLINE,
  LINE_STYLE,
  LINE_JOIN,
  LASTLOCATION,
  OUTSIDE,
  OVERLAY_TYPES
} from '../../mapkitJS/IMDF.constants';
import {
  checkPeopleFilter,
  checkCurrentFloorOnlyFilter,
  checkIndoorOnlyFilter,
  checkCurrentUserOnlyFilter,
  checkAllCalloutsFilterEnabled,
  checkAllCalloutsFilterDisabled,
  checkMinimizeCalloutsFilter
} from './peopleFilters';
import {
  checkEquipmentFilter,
  checkEquipmentCurrentFloorOnlyFilter,
  checkEquipmentIndoorOnlyFilter,
  checkEquipmentAllCalloutsFilterEnabled,
  checkEquipmentAllCalloutsFilterDisabled,
  checkEquipmentMinimizeCalloutsFilter
} from './equipmentFilters';
import{
  checkTraceFiltersRemoveAll,
  checkTraceFiltersRemoveNotCurrentFloor,
  checkTraceFiltersRemoveNotIndoor,
  checkTraceFiltersRemoveNotCurrentUser
} from './traceFilters';
import { getFloorColorByOrdinal } from '../../floorColors';

export const TRACE_FILTERS = (mapkit, traces, activeUser, annotationId, indoorOnly, activeFloor, currentFloor, peopleMapFilter, viewProps, currentView)=>{

  if (REMOVE_ALL(traces, viewProps, currentView, peopleMapFilter)) {
    mapkit = checkTraceFiltersRemoveAll(mapkit);
  }
  else if (REMOVE_NOT_CURRENT_FLOOR(traces, activeFloor)) {
    mapkit = checkTraceFiltersRemoveNotCurrentFloor(mapkit, currentFloor);
  }
  else if (REMOVE_NOT_INDOOR(traces, indoorOnly)) {
    mapkit = checkTraceFiltersRemoveNotIndoor(mapkit);
  }
  else if (REMOVE_NOT_CURRENT_USER(traces, activeUser)){
    mapkit = checkTraceFiltersRemoveNotCurrentUser(mapkit, annotationId);
  }

  return mapkit;
};

export const PEOPLE_FILTERS = (mapkit, peopleVisible, calloutVisible, smallVisible, filterFloor, floor, filterIndoor, filterUser, user, filterDevice, device)=>{
  let annotationId = filterUser? user+device: '';

  if (peopleVisible) {
    mapkit = checkPeopleFilter(mapkit, peopleVisible);
  }
  if (filterUser) {
    mapkit = checkCurrentUserOnlyFilter(mapkit, filterUser, annotationId);
  }
  if (calloutVisible) {
    mapkit = checkAllCalloutsFilterEnabled(mapkit, calloutVisible);
  }
  if (!calloutVisible) {
    mapkit = checkAllCalloutsFilterDisabled(mapkit, calloutVisible);
  }
  if (smallVisible) {
    mapkit = checkMinimizeCalloutsFilter(mapkit, smallVisible);
  }
  if (filterFloor) {
    mapkit = checkCurrentFloorOnlyFilter(mapkit, filterFloor, floor);
  }
  if (filterIndoor) {
    mapkit = checkIndoorOnlyFilter(mapkit, filterIndoor);
  }

  return mapkit;
}

export const EQUIPMENT_FILTERS = (mapkit, equipmentVisible, calloutVisible, smallVisible, filterFloor, floor, filterIndoor)=>{

  if (equipmentVisible) {
    mapkit = checkEquipmentFilter(mapkit, equipmentVisible);
  }
  if (filterFloor) {
    mapkit = checkEquipmentCurrentFloorOnlyFilter(mapkit, filterFloor, floor);
  }
  if (filterIndoor) {
    mapkit = checkEquipmentIndoorOnlyFilter(mapkit, filterIndoor, floor);
  }
  if (calloutVisible) {
    mapkit = checkEquipmentAllCalloutsFilterEnabled(mapkit, calloutVisible);
  }
  if (!calloutVisible) {
    mapkit = checkEquipmentAllCalloutsFilterDisabled(mapkit);
  }
  if (smallVisible) {
    mapkit = checkEquipmentMinimizeCalloutsFilter(mapkit, smallVisible);
  }

  return mapkit;
};

/**
* Function that based on the filters, it decides how to ignore/remove annotations in map
*/
export const PEOPLE_ANNOTATION_FILTERS = (trackType, showtraces, location, activeFloor, indoorOnly, mapkit, showHistoryMap, mapConfigs)=>{
  if (showtraces) {
    mapkit = changePreviousAnnotationToOverlay(mapkit, location, indoorOnly, activeFloor, mapConfigs);
  } 
  else {
    mapkit = cleanTraceOverlays(showHistoryMap, mapkit);
    mapkit = removePreviousUserAnnotations(mapkit, location, trackType);
  }
  return [mapkit, showHistoryMap];
};

export const EQUIPMENT_ANNOTATION_FILTERS = (assetTag, activeFloor, indoorOnly, currentFloor, campusInfo, mapkit, mapRotation)=>{
  let  equipmentInfo = {};
  mapkit.annotations.map(dot=>{
    if (dot.data.asset_tag === assetTag && dot.subtitle === ANNOTATION_SUBTITLES.equipmentLocDot){
      equipmentInfo = dot.data;
    }
    return null;
  });

  if (activeFloor && !indoorOnly) {
    mapkit = checkEquipmentAnnotationFilers_ActiveFloorEnabled_IndoorOnlyDisabled(equipmentInfo.latitude, equipmentInfo.longitude, mapkit, mapRotation);
  }
  if (activeFloor && indoorOnly) {
    mapkit = checkEquipmentAnnotationFilters_activeFloorEnabled_IndoorOnlyEnabled(mapkit, equipmentInfo, currentFloor, campusInfo, mapRotation);
  }
  if (!activeFloor && indoorOnly) {
    mapkit = checkEquipmentAnnotationFilters_ActiveFloorDisabled_IndoorOnlyEnabled(mapkit, equipmentInfo, campusInfo, mapRotation);
  }
  if (!activeFloor && !indoorOnly){
    mapkit = handleMapConfigUserTracking(equipmentInfo.latitude, equipmentInfo.longitude, mapkit, mapRotation);
  }

  return mapkit;
};

const REMOVE_ALL = (traces, viewProps, currentView, peopleMapFilter) => (!traces && viewProps !=='history' && currentView==='map') || (traces && !peopleMapFilter);

const REMOVE_NOT_CURRENT_FLOOR = (traces, activeFloor) => traces && activeFloor;

const REMOVE_NOT_INDOOR = (traces, indoorOnly) => traces && indoorOnly;

const REMOVE_NOT_CURRENT_USER = (traces, activeUser) => traces && activeUser;

const  checkTrackingType = (res, location, trackType) =>{
  if(trackType === TRACK_DEVICE){
    return(res.title === location.id || (res.data.assetTag === location.assetTag && res.data.status === OFFLINE));
  }
  if(trackType === TRACK_USER){
    return(res.title === location.id || (res.data.firstName === location.firstName && res.data.lastName === location.data.lastName && res.data.status === OFFLINE));
  }
  if(trackType === TRACK_NONE){
    return(res.title === location.id);
  }
};

const handleTraceConditional = (res, location)=>{
  return res.subtitle === ANNOTATION_SUBTITLES.location && res.data.latitude !== location.latitude && res.data.longitude !== location.longitude;
};

const handleKeepTraceConditional = (res, location)=>{
  return res.subtitle === ANNOTATION_SUBTITLES.trace && res.data.floor === location.floor && res.data.latitude !== location.latitude && res.data.longitude !== location.longitude;
};

/**
* Removes 'traces' (polylines) if the trace floor does not correspond with the currentFloor
*/
const handleRemoveTraces = (currentFloor, mapkit, location)=>{
  mapkit.overlays.forEach(overlay=>{
    if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter && overlay.data[1] === location.id && overlay.data[2] !== currentFloor[1]){
      mapkit.removeOverlay(overlay);
    }
  });

  return mapkit;
};
/**
* Removes 'traces' (polylines) if the trace floor is GPS
*/
const handleCheckIndoorTraces = (mapkit, location)=>{
  mapkit.overlays.forEach(overlay=>{
    if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter && overlay.data[1] === location.id && overlay.style.strokeColor === GPS_COLOR){
      mapkit.removeOverlay(overlay);
    }
  });

  return mapkit;
};

/**
* Removes historyTraces and polyline traces from map
*/
const cleanTraceOverlays = (showHistoryMap, mapkit)=>{
  showHistoryMap = false;
  mapkit.overlays.forEach(overlay=>{
    if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter || overlay.data.subtitle === OVERLAY_TYPES.historyTrace){
      mapkit.removeOverlay(overlay);
    }
  });

  mapkit.annotations.forEach(dot=>{
    if (dot.subtitle === ANNOTATION_SUBTITLES.historyTrace || dot.subtitle === ANNOTATION_SUBTITLES.historyTraceCallout) {
      mapkit.removeAnnotation(dot);
    }
  });

  return mapkit;
};

const setOverlayStyle = (mapkit, lineStyle, floorColor, location, visible, userTrace) =>{
  mapkit.overlays.forEach(overlay=>{
    if (overlay.data[1] === location.id) {
      if (overlay.style.strokeColor !==GPS_COLOR) {
        if (lineStyle === LINE_STYLE.line && overlay.style.strokeColor === floorColor) {
          overlay.style.lineDash[0] = 0;
          overlay.style.lineDash[1] = 0;
        } else {
          overlay.style.lineDash[0] = 8;
          overlay.style.lineDash[1] = 4;
        }
      }
      overlay.visible = visible;
      userTrace.push(overlay);
      mapkit.removeOverlay(overlay);
    }
  });

  return {mapkit, userTrace}
}

/**
* Function that based on the filter 'Show traces' creates a line (polyline)
*/
const handlePolylineTrace = (res, location, visible, lineStyle, mapkit, mapConfigs)=>{
  if (res.title === location.id) {
    let userTrace = [];
    const floorStyle =  !!mapConfigs.floors ? mapConfigs.floors.find(floor=>floor.ordinal === location.floor) : undefined;
    const floorColor = floorStyle ? getFloorColorByOrdinal(floorStyle.ordinal) : "";
    const overlayStyle = setOverlayStyle(mapkit, lineStyle, floorColor, location, visible, userTrace);
    mapkit = overlayStyle.mapkit;
    userTrace = overlayStyle.userTrace;

    let point = [];
    let pointA = [res.data.latitude, res.data.longitude];
    point.push(pointA);
    let pointB = [location.latitude, location.longitude];
    point.push(pointB);

    let coords = point.map(function(point) {
      return new window.mapkit.Coordinate(point[0], point[1]);
    });

    let style = new window.mapkit.Style({
      lineWidth: 3,
      lineJoin: LINE_JOIN.round,
      lineDash: lineStyle === LINE_STYLE.dashed ? [8, 4] : [],
      strokeColor: floorColor,
    });

    let polyline = new window.mapkit.PolylineOverlay(coords, { style: style });
    polyline.data = [OVERLAY_TYPES.polylineTraceFilter, location.id, location.floor, location.user_id];
    polyline.visible = visible;
    userTrace.push(polyline);

    mapkit.addOverlays(userTrace);
  }
};

const getCurrentFloor = (mapConfigs) => {
  let currentFloor = "";

  if (!!mapConfigs.floors) {
    mapConfigs.floors.forEach(floor=>{
      let className = "level-" + floor.ordinal + " selected";
      if (document.getElementsByClassName(className).length === 1){
        currentFloor = floor.ordinal;
      }
    });
  }
  
  return currentFloor;
}

/**
* Function that based on the floor asigns or not a dashed line
*/
const handleRemoveNCreateTraces = (res, location, currentFloor, mapkit, mapConfigs)=>{
  let showPolyline = true;
  if (currentFloor[0]) {
    if (location.floor !== currentFloor[1]){
      showPolyline = false;
    }
  }

  //?Remove trace annotation (dots)?
  if (!handleKeepTraceConditional(res, location)) {
    mapkit.removeAnnotation(res);
  }

  //?Location change detected (only for 'geolocation' type)?
  if (handleTraceConditional(res, location) && res.data.type !== LASTLOCATION) {
    const currentFloor = getCurrentFloor(mapConfigs);
    let polylineType = LINE_STYLE.dashed;

    if (location.floor === currentFloor) {
      polylineType = LINE_STYLE.line;
      handlePolylineTrace(res, location, showPolyline, polylineType, mapkit, mapConfigs);
    } else {
      if (location.floor === -100 || location.floor === OUTSIDE || location.floor === null || location.floor === undefined){
        polylineType = LINE_STYLE.line;
      }
      handlePolylineTrace(res, location, showPolyline, polylineType, mapkit, mapConfigs);
    }
  }
  return mapkit;
};


/**
* Reconfigures the properties of map in order to 'follow' the user to track
*/
const handleMapConfigUserTracking = (latitude, longitude, mapkit, mapRotation)=>{
  mapkit.center = new window.mapkit.Coordinate(latitude, longitude);
  mapkit.span = new window.mapkit.CoordinateSpan(0.0004, 0.0004);
  mapkit.region = new window.mapkit.CoordinateRegion(mapkit.center, mapkit.span);
  mapkit.rotation = mapRotation;
  return mapkit;
};

const handleSetMapDefault = (campusInfo, mapkit, mapRotation)=>{
  const span = new window.mapkit.CoordinateSpan(0.0026, 0.0056);
  const center = new window.mapkit.Coordinate(campusInfo.config.lat, campusInfo.config.long);
  const region = new window.mapkit.CoordinateRegion(center, span);
  mapkit.region = region;
  mapkit.rotation = mapRotation;
  return mapkit;
};

const removePreviousUserAnnotations = (mapkit, location, trackType) =>{
  mapkit.annotations.forEach(res=>{
    if (checkTrackingType(res,location,trackType)){
      mapkit.removeAnnotation(res);
    }
  });

  return mapkit;
};

const changePreviousAnnotationToOverlay = (mapkit, location, indoorOnly, activeFloor, mapConfigs) =>{
  if (indoorOnly) {
    mapkit = handleCheckIndoorTraces(mapkit, location);
  }
  if (activeFloor[0]) {
    mapkit = handleRemoveTraces(activeFloor, mapkit, location);
  }

  mapkit.annotations.forEach(res=>{
    if (res.title === location.id){
      mapkit = handleRemoveNCreateTraces(res, location, activeFloor, mapkit, mapConfigs);
    }
  });
  return mapkit;
}

const checkEquipmentAnnotationFilers_ActiveFloorEnabled_IndoorOnlyDisabled = (mapkit, equipmentInfo, currentFloor, campusInfo, mapRotation) =>{
  if (equipmentInfo.floor !== currentFloor){
    mapkit = handleSetMapDefault(campusInfo, mapkit, mapRotation);
  }else{
    mapkit = handleMapConfigUserTracking(equipmentInfo.latitude, equipmentInfo.longitude, mapkit, mapRotation);
  }

  return mapkit;
};

const checkEquipmentAnnotationFilters_activeFloorEnabled_IndoorOnlyEnabled = (mapkit, equipmentInfo, currentFloor, campusInfo, mapRotation) =>{
  if (equipmentInfo.floor !== currentFloor || equipmentInfo.floor === null || equipmentInfo.floor === OUTSIDE || equipmentInfo.floor === -3){
    mapkit = handleSetMapDefault(campusInfo, mapkit, mapRotation);
  }else{
    mapkit = handleMapConfigUserTracking(equipmentInfo.latitude, equipmentInfo.longitude, mapkit, mapRotation);
  }

  return mapkit;
};

const checkEquipmentAnnotationFilters_ActiveFloorDisabled_IndoorOnlyEnabled = (mapkit, equipmentInfo, campusInfo, mapRotation) =>{
  if (equipmentInfo.floor === null || equipmentInfo.floor === OUTSIDE || equipmentInfo.floor === -100){
    mapkit = handleSetMapDefault(campusInfo, mapkit, mapRotation);
  }else{
    mapkit = handleMapConfigUserTracking(equipmentInfo.latitude, equipmentInfo.longitude, mapkit, mapRotation);
  }

  return mapkit;
}