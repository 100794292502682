import {
    GPS_COLOR,
    OVERLAY_TYPES
} from '../../mapkitJS/IMDF.constants';

export const checkTraceFiltersRemoveAll = (mapkit) =>{
    mapkit.overlays.forEach(overlay=>{
      if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter) {
        mapkit.removeOverlay(overlay);
      }
    });
  
    return mapkit;
}
  
export const checkTraceFiltersRemoveNotCurrentFloor = (mapkit, floor) =>{
    mapkit.overlays.forEach(overlay=>{
      if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter && (overlay.data[2] !== floor || overlay.style.strokeColor ===GPS_COLOR)) {
        mapkit.removeOverlay(overlay);
      }
    });
  
    return mapkit;
}
  
export const checkTraceFiltersRemoveNotIndoor = (mapkit) =>{
    mapkit.overlays.forEach(overlay=>{
      if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter && overlay.style.strokeColor ===GPS_COLOR) {
        mapkit.removeOverlay(overlay);
      }
    });
  
    return mapkit;
};
  
export const checkTraceFiltersRemoveNotCurrentUser = (mapkit, annotationId) =>{
    mapkit.overlays.forEach(overlay=>{
      if (overlay.data[0] === OVERLAY_TYPES.polylineTraceFilter && overlay.data[1] !== annotationId) {
        mapkit.removeOverlay(overlay);
      }
    });
  
    return mapkit;
};