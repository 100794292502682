import React from "react";
import moment from "moment";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';

import MasterButton from "../../../utilities/button.component";

import {useStyles} from './historyTimeWindowControls.styles';


const HistoryTimeWindowControls = (props) =>{
    const classes = useStyles();
    const {showTimeWindowControls, startDate, endDate, fromInputDate, toInputDate, minDate, maxDate, fromInputError, toInputError} = props;

    const renderTimeWindowMenu = () =>{
        return(
          <Paper className={classes.HistoryTimeWindow_Paper}>
          <TextField
            id="webNavv-workflowAutomation-jobsHistory-fromDateInput-1.0"
            label={'From'}
            type="date"
            error={fromInputError}
            className={classes.HistoryTimeWindow_fromInput}
            format='YYYY-MM-DD'
            value={moment(new Date(fromInputDate)).format('YYYY-MM-DD')}
            onChange={(e)=>props.handleChangeInputDate('fromInput', e.target.value)}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{disableUnderline: fromInputError? false : true}}
            inputProps={{
              min: moment(new Date(minDate)).format('YYYY-MM-DD'),
              max: moment(new Date(endDate)).format('YYYY-MM-DD')
            }}
          />
          <TextField
            id="webNavv-workflowAutomation-jobsHistory-toDateInput-1.0"
            label={'To'}
            type="date"
            error={toInputError}
            className={classes.HistoryTimeWindow_toInput}
            format='YYYY-MM-DD'
            value={moment(new Date(toInputDate)).format('YYYY-MM-DD')}
            onChange={(e)=>props.handleChangeInputDate('toInput', e.target.value)}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{disableUnderline: toInputError? false : true}}
            inputProps={{
              min: moment(new Date(startDate)).format('YYYY-MM-DD'),
              max: moment(new Date(maxDate)).format('YYYY-MM-DD')
            }}
          />
            <div>
              <div className={classes.HistoryTimeWindow_Buttons}>
              <MasterButton
                id="webNavv-workflowAutomation-jobsHistory-dateTime-cancelBtn-1.0"
                keyLbl={'cancel-date-time'}
                label={'Cancel'}
                buttonType={'default-flat'}
                isDisabled={false}
                handleClick={()=>props.handleCancelCalendarTimeWindow()}
                size={"small"}/>
              <MasterButton
                id="webNavv-workflowAutomation-jobsHistory-dateTime-saveBtn-1.0"
                isDisabled={fromInputError || toInputError? true : false}
                keyLbl={'apply-date-time'}
                label={'Save'}
                buttonType={'success-flat'}
                handleClick={()=>props.handleUpdateCalendarTimeWindow()}
                size={"small"}/>
              </div>
            </div>
          </Paper>
        )
    }

    return(
      <div>
        <div style={{position: "absolute"}} className={classes.HistoryTimeWindow_DateContainer} id="webNavv-workflowAutomation-jobsHistory-timeWindowContainer-1.0">
          <div>
            <p className={classes.HistoryTimeWindow_fromLabel}>{`${moment(fromInputDate).format('MMM DD')} - ${moment(toInputDate).format('MMM DD')}, ${moment(toInputDate).format('YYYY')}`}</p>
          </div>
          <div className={classes.HistoryTimeWindow_durationContainer}>{moment.duration(fromInputDate.diff(toInputDate)).humanize()}</div>
          <div>
            <Tooltip title={"Edit Date"}>
              <IconButton className={classes.HistoryTimeWindow_CalendarIcon} onClick={()=>props.handleTimeWindowVisibility()} >
                <KeyboardArrowDownIcon/>
              </IconButton>
            </Tooltip>
          </div>
          {showTimeWindowControls && (renderTimeWindowMenu())}
        </div>
      </div>
    );
};

export default HistoryTimeWindowControls;