import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    svgLbl: {
      paddingLeft: "40%",
      display: "table-cell",
    },
    svg: {
      height: "15px",
      width: "20px",
      marginLeft: "5px",
      verticalAlign: "middle",
      display: "table-cell",
    },
    selectedSVG: {
      height: "15px",
      width: "20px",
      marginLeft: "5px",
      verticalAlign: "middle",
      display: "table-cell",
    },
    listItem: {
      width: "200px",
      left: "30px",
      borderRadius: "0.5em",
      "&,&:focus": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    listItemDone: {
      width: "200px",
      left: "30px",
      borderRadius: "0.5em",
      "&,&:focus": {
        backgroundColor: "#E3F2FD !important",
        color: "#2196F3 !important",
      },
    },
    indicatorOpen: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > div": {
        maxWidth: 20,
        width: "10%",
        borderTop: "30px solid transparent",
        borderRight: "25px solid white",
        borderBottom: "30px solid transparent",
      },
    },
    indicatorClosed: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    root: {
      flexGrow: 1,
      width: "45px",
      backgroundColor: "transparent",
      height: "100%",
      top: 0,
      left: 0,
      display: "flex",
      overflowX: "hidden",
      zIndex: "1002",
      position: "fixed !important",
    },
    tabs: {
      // borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: "#2843A3",
      width: "45px",
      zIndex: "999",
      position: "fixed !important",
      height: "100%",
      borderRight: "1px solid white",
    },
    tabsClosed: {
      borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: "#2843A3",
      width: "45px",
      zIndex: "999",
      position: "fixed !important",
      height: "100%",
    },
    firstSectionTabs: {
      right: 60,
      top: "70px",
    },
    secondSectionTabs: {
      right: 60,
      top: "50vh",
    },
    logo: {
      height: "30%",
      width: "10%",
      marginTop: "3%",
      marginLeft: "4%",
    },
    navvTrackTitle: {
      marginLeft: "5%",
      color: "#2843A3",
    },
    listSubtitle: {
      marginTop: "12px",
      fontWeight: "bold",
    },
    exitIcon: {
      fill: "white",
      marginLeft: "10px",
      marginTop: "20px",
      fontSize: "15px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    backIconDiv: {
      display: "inline-table",
      zIndex: "999",
      backgroundColor: "#2843A3",
      height: "58px",
      width: "30px",
      position: "fixed !important",
      float: "right",
    },
    backIconListDiv: {
      display: "inline",
      zIndex: "999",
      backgroundColor: "#2843A3",
      height: "58px",
      width: "30px",
      position: "fixed !important",
      marginLeft: "265px",
    },
    usersListContainer: {
      display: "inline-table",
      height: "100vh",
      width: "21vw",
      position: "fixed !important",
      float: "right",
      left: "313px"
    },
    usersListDiv: {
      display: "inline",
      position: "fixed !important",
      float: "right",
      height: "100%",
      width: "265px",
    },
    drawerDiv: {
      backgroundColor: "white",
      width: "270px",
      boxShadow: "rgba(0, 0, 0, 0.23) 0px 3px 10px",
      zIndex: "999 !important",
      left: "43px",
      float: "right",
      height: "100vh",
      display: "inline-table",
      position: "fixed !important"
    },
    navUnreadDot: {
      marginLeft: 5,
      backgroundColor: 'red',
      height: '7px',
      width: '7px',
      borderRadius: '7px',
    }
  }));