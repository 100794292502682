import React from "react";

import IconButton from "material-ui/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ActionMessage from "../actionMsg/actionMessage.component";


const ZoomControls = (props) =>{
    return(
    <div 
      style={{
        float:"right",
        marginRight:"10px",
        display:"flex",
        width: "350px"
      }}>
        <ActionMessage actionStatus={props.actionStatus}/>
        <Tooltip title="Zoom out">
            <IconButton
                id="webNavv-workflowAutomation-jobsBoard-zoomOut-1.0"
                disabled={props.disableZoomOut}
                onClick={props.handleZoomOut}
                style={{
                    marginTop: "5px",
                    borderRadius: "4px",
                    border: "1.5px solid #2196F3",
                    backgroundColor:"#E9F5FE",
                    padding:"none",
                    width:"38px",
                    height:"38px"
                }}
            >
            <RemoveIcon/>
            </IconButton>
        </Tooltip>
        <Tooltip title="Zoom in">
            <IconButton
                id="webNavv-workflowAutomation-jobsBoard-zoomIn-1.0"
                disabled={props.disableZoomIn}
                onClick={props.handleZoomIn}
                style={{
                    marginTop: "5px",
                    marginLeft: "20px",
                    borderRadius: "4px",
                    border: "1.5px solid #2196F3",
                    backgroundColor:"#E9F5FE",
                    padding:"none",
                    width:"38px",
                    height:"38px"
                }}
            >
            <AddIcon/>
            </IconButton>
        </Tooltip>
        <Tooltip title="Clear zoom">
            <IconButton
                id="webNavv-workflowAutomation-jobsBoard-clearZoom-1.0"
                onClick={props.handleCenterBoard}
                style={{
                    marginTop: "5px",
                    marginLeft: "20px",
                    borderRadius: "4px",
                    border: "1.5px solid #2196F3",
                    backgroundColor:"#E9F5FE",
                    padding:"none",
                    width:"38px",
                    height:"38px"
                }}
            >
            <CropFreeIcon/>
            </IconButton>
        </Tooltip>
    </div>
    );
}

export default ZoomControls;