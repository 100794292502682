import {
    listUsersByTeam,
    listResource_Slot_JobByDateRange,
    listJob_Status,
    listJobTasks,
    listStandaloneJob_Templates,
    listJob_HistoryByDateRange,
    listTeams} from "../../../graphql/queries";
import { SPECIMEN_COLLECTION_TYPE } from "../jobsBoard/jobsBoard.constants";
import { API } from 'aws-amplify';

export const queryListJobHistoryByDateRange = async(minDate, maxDate) =>{
    const data = {query: listJob_HistoryByDateRange, variables:{ min: minDate, max: maxDate}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listJob_HistoryByDateRange]", apiData.data.listJob_HistoryByDateRange);
            let response = apiData.data.listJob_HistoryByDateRange;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listJob_HistoryByDateRange - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryListTeams = async(campusId) =>{
    const data = {query: listTeams, variables:{ campusid: campusId }};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listTeams]", apiData.data.listTeams);
            let response = apiData.data.listTeams;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listTeams - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryListStandaloneJobTemplates = async() =>{
    const data = {query: listStandaloneJob_Templates, variables:{ job_typeid: SPECIMEN_COLLECTION_TYPE}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listStandaloneJob_Templates]", apiData.data.listStandaloneJob_Templates);
            let response = apiData.data.listStandaloneJob_Templates;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listStandaloneJob_Templates - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryListUsersByTeam = async(currentTeamId) =>{
    const data = {query: listUsersByTeam, variables:{ teamid: currentTeamId}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listUsersByTeam]", apiData.data.listUsersByTeam);
            let response = apiData.data.listUsersByTeam;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listUsersByTeam - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryResourceSlotsByDateRange = async(currentTeamId, minDate, maxDate) =>{
    const data = {query: listResource_Slot_JobByDateRange, variables:{teamid: currentTeamId, min: minDate, max: maxDate}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listResource_Slot_JobByDateRange]", apiData.data.listResource_Slot_JobByDateRange);
            let response = apiData.data.listResource_Slot_JobByDateRange;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listResource_Slot_JobByDateRange - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryJobStatusList = async () =>{
    const data = {query: listJob_Status, variables:{}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listJob_Status]", apiData.data.listJob_Status);
            let response = apiData.data.listJob_Status;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listJob_Status - ERROR] couldn't get data:", err);
            reject(err);
        }
    });
}

export const queryListJobTasks = async(jobid) =>{
    const data = {query: listJobTasks, variables:{jobid: jobid}};

    return new Promise(async(resolve, reject) =>{
        try{
            const apiData = await API.graphql(data);
            console.info("[Query - listJobTasks]", apiData.data.listJobTasks);
            let response = apiData.data.listJobTasks;
            const responseHasData = response.length !== 0;
            if (responseHasData) {
              resolve(response);
            }
            else {
                resolve(null);
            }
        }catch(err){
            console.info("[Query - listJobTasks - ERROR] couldn't get data:", err);
            reject(err);
        }
    });

}