import React from 'react';
import { connect } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

import {useStyles} from '../../sideBar/sideBar.styles';
import FilterList from './../../../filters/filterlist.component';
import {
  USER_TRACK_KEY,
  DEVICE_TRACK_KEY,
  EQUIPMENT_TRACK_KEY,
  ACTIVE_MAP,
  USER_MAP,
  DEVICE_MAP,
  EQUIPMENT_MAP,
  PEOPLE_FILTER,
  EQUIPMENT_FILTER,
  MAP,
  HISTORY
} from './tab.constants';
import "./tab.css";

function ActiveTab(props){
  let viewType = ACTIVE_MAP;
  if (props.viewProps===USER_TRACK_KEY) {
    viewType = USER_MAP;
  }
  if (props.viewProps===DEVICE_TRACK_KEY) {
    viewType = DEVICE_MAP;
  }
  if (props.viewProps===EQUIPMENT_TRACK_KEY) {
    viewType = EQUIPMENT_MAP;
  }
  const classes = useStyles();


  return(
    <React.Fragment>
      <div id="webNavv-activeMap-mainFilters-1.0">
        <p className="TabFilterDescription">{viewType}</p>
      </div>
      {props.viewProps !== EQUIPMENT_TRACK_KEY &&(
        <div 
        id="webNavv-activeMap-mainFilters-peopleFilter-1.0"
        className='ButtonItem_Container'
        onClick={()=>props.handlePeopleMapFilter()}>
          <div><PersonIcon style={props.usersFilter?{ color: "#2196F3" }:{ color: "#757575" }} /></div>
          <div className='ButtonItem_Text'style={props.usersFilter?{ color: "#2196F3" }:{ color: "#757575" }} >{PEOPLE_FILTER}</div>
          <div>{props.usersFilter?
              (<CheckBoxIcon style={{ color: "#2196F3" }} />)
              : (<CheckBoxOutlineBlankIcon style={{ color: "#757575" }}/>)}</div>
        </div>
      )}
      {props.aclViews[8].access && props.viewProps !== EQUIPMENT_TRACK_KEY && props.featureFlags.Equipment.MapFilter &&(
        <div
        id="webNavv-activeMap-mainFilters-equipmentFilter-1.0"
        className='ButtonItem_Container'
        onClick={()=>props.handleEquipmentFilter()}>
          <div><BusinessCenterIcon style={props.equipmentFilter?{ color: "#2196F3" }:{ color: "#757575" }} /></div>
          <div className='ButtonItem_Text' style={props.equipmentFilter?{ color: "#2196F3" }:{ color: "#757575" }}>{EQUIPMENT_FILTER}</div>
          <div>{props.equipmentFilter?
            (<CheckBoxIcon style={{ color: "#2196F3" }} />)
            :(<CheckBoxOutlineBlankIcon style={{ color: "#757575" }} />)}</div>
        </div>
      )}
    <Divider style={{ marginTop: "10px" }} />
    {props.currentView === MAP && props.viewProps !== HISTORY &&(
      <FilterList/>
    )}
  </React.Fragment>
  );
};

const mapStateToProps = state => ({
  users: state.user.users,
  activeUser: state.views.activeUser,
  aclViews: state.auth.acl.views,
  currentView: state.views.currentView,
  viewProps: state.views.viewProps,
  featureFlags: state.auth.featureFlags
})

export default connect(mapStateToProps,{}) (ActiveTab);