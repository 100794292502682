export const LEVEL='level';
export const UNIT='unit';
export const OPENING='opening';
export const VENUE='venue';
export const CATEGORIES_TO_EXCLUDE= ["walkway", "nonpublic", "opentobelow"];
export const ROOM='room';
export const FEATURE='Feature';
export const ANCHOR='anchor';
export const FILES=["levels","units","openings","venues"];
export const IMDF_FILES=["level","unit", "opening", "venue"];

export const LEVELPICKER= 'levelpicker';
export const MAPCONTAINER="mapContainer";
export const BUTTON="button";
export const SWITCHBUTTON='switchButton';
export const X='x';
export const CLICK='click';
export const FLOOR_SELECTED="floorSelected";
export const LEVEL_ORDINAL='level-';
export const LEVEL_ORDINAL_CLASS=".level-";
export const FIRST_FLOOR="first-floor";
export const LAST_FLOOR="last-floor";
export const TITLE='title';
export const DEFAULT_BORDER_COLOR="#696969";
export const DEFAULT_BACKGROUND_COLOR="#808080";
export const WHITE='white';
export const SELECTED=".selected";
export const SELECTED_CLASS="selected";
export const NONE='none';
export const BLOCK='block';
export const UL='ul';
export const LI='LI';
export const BORDER_LEFT="7px solid";

export const TRACK_DEVICE = "transporterPhone";
export const TRACK_USER = "transporter";
export const TRACK_NONE = "none";
export const OFFLINE = "Offline";
export const LASTLOCATION= "lastLocation";
export const OUTSIDE= "outside";

export const OVERLAY_TYPES = {
    historyTrace: "historyTrace",
    polylineTraceFilter: "PolylineTraceFilter"
}

export const LINE_STYLE={
    line: "line",
    dashed: "dashed"
}

export const LINE_JOIN={
    round: "round"
}

export const USER_LOCATION_STATUS={
    online: "online",
    inactive: "inactive",
    offline: "offline"
}


export const ZOOM_INFO ={
    0: {lat: 0.0006,  lon:0.0006},
    1: {lat: 0.0016, lon:0.0016},
    2: {lat: 0.0026, lon:0.0026},
    3: {lat: 0.0036, lon:0.0036},
    4: {lat: 0.0046, lon:0.0046}
};
export const MAP='map';
export const DEFAULT_SPAN={lat:0.0026, lon:0.0056};
export const TRACK_USER_SPAN={lat: 0.0004, lon:0.0004};
export const GPS_COLOR = "#757575";
export const ANNOTATION_SUBTITLES={
    historyTrace: 'historyTrace',
    historyTraceCallout:"historyTraceCallout",
    location: 'location',
    callout: 'callout',
    smallCallout: 'smallCallout',
    equipmentLocBubble:"equipment-locationBubble",
    equipmentLocDot:"equipment-locationDot",
    equipmentCallout: "equipment-callout",
    mapAnnotation: 'map annotation',
    trace:'trace'
}
export const MAP_EVENTS={
    configChange:{
        type: "configuration-change",
        status:{
            initialized: 'Initialized',
            refreshed: 'Refreshed'
        },
        logs:{
            initialized:"[MapKit JS] map initialized and configured.",
            refreshed:"[MapKit JS] map configuration updated."
        }
    },
    error:{
        type: "error",
        status:{
            unauthorized: "Unauthorized",
            manyRequests: "Too Many Requests"
        },
        logs:{
            unauthorized: "[MapKit JS] The authorization token provided is invalid.",
            manyRequests: "[MapKit JS] The authorization token provided is based on a Maps ID, which has exceeded its allowed daily usage."
        }
    },
    select:{
        type: 'select'
    },
    regionChangeEnd:{
        type:"region-change-end"
    },
    zoomEnd:{
        type:"zoom-end",
        zoomMax:0.0009819103411885521,
        zoomMin:0.0009819103411885521
    }
}

export const ERROR_TASKS= {
    jobCancelled:{
        key: 'job_cancelled',
        description: 'Job Cancelled'
    },
    jobStartDelay:{
        key: 'job_delayed',
        description: 'Job Start Delayed'
    },
    locationSkipped:{
        key: 'skip_location',
        description: 'Location Skipped'
    },
    scanOverride:{
        key: 'scan_override',
        description: 'Location Scan Override'
    },
    scanError:{
        key: 'failed_scan',
        description: 'Scan Failed'
    }
}
