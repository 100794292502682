import lightBlue from "@material-ui/core/colors/lightBlue";
import green from "@material-ui/core/colors/green";

export const styles = () => ({
  JobsCalendar_RemoveJobIcon:{
    fill: "#D8726F",
  },
  JobsCalendar_BlueButton: {
    fontSize: '12px',
    marginLeft:"20px",
    color: "white",
    textTransform: "none !important",
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  JobsCalendar_UserButtonSmall: {
    fontSize: '10px',
    width: "100px",
    marginLeft:"5px",
    color: "rgba(0, 0, 0, 0.65)",
    backgroundColor: "none",
    textTransform: "none !important",
    justifyContent: "none !important",
    "&:hover": {
      backgroundColor: "lightgray"
    }
  },
  JobsCalendar_UserButton: {
    fontSize: '12px',
    width: "100px",
    marginLeft:"5px",
    color: "rgba(0, 0, 0, 0.65)",
    backgroundColor: "none",
    textTransform: "none !important",
    justifyContent: "left !important",
    "&:hover": {
      backgroundColor: "lightgray"
    }
  },
  JobsCalendar_GreenButton: {
    fontSize: '12px',
    marginLeft:"20px",
    color: "white",
    textTransform: "none !important",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[800],
      "@media (hover: none)": {
        backgroundColor: green[600]
      }
    }
  },
  JobsCalendar_DefaultButtonText: {
    textTransform: "none !important",
  },
});
