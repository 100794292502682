import moment from "moment";

export const DEVICE_LBL = 'Device';
export const DATE_FORMAT="MM/DD/YYYY HH:mm";

export const FORMAT_FILTER_LABELDATES = (dateToFormat, label) => {
    if (dateToFormat !== undefined) {
      let startDateIso = moment(dateToFormat).toISOString();
      let search = moment(startDateIso).format(DATE_FORMAT);
      return `${label}: ${search}`;
    } else return false;
  };

export const FORMAT_FILTER_LABELDEVICE= device => {
    if (device !== undefined)
      return `${DEVICE_LBL}: ${device}`;
    else return false;
  };

  export const DATE_FILTER_LOGIC = (date, filters) => {
    if (filters) {
      let startDateIso = moment(filters).toISOString();
      let search = moment(startDateIso).format(DATE_FORMAT);
      if (search !== date[0])return true;
    } else return false;
  };

  export const FILTER_LOGIC_FM = (date, filters) => {
    if (filters) {
      //let formated = moment(moment(date.last_logout).toISOString()).format(DATE_FORMAT);
      let formatedTime = moment(moment(date.timestamp).toISOString()).format(DATE_FORMAT);
      let search = moment(moment(filters).toISOString()).format(DATE_FORMAT);
      if (search !== formatedTime)return true;
    } else return false;
  };

  export const FILTER_LOGIC_CHECKOUT = (date, filters) => {
    if (filters) {
      let formatedTime = moment(moment(date.checkOut).toISOString()).format(DATE_FORMAT);
      let search = moment(moment(filters).toISOString()).format(DATE_FORMAT);
      if (search !== formatedTime)return true;
    } else return false;
  };

  export const FILTER_LOGIC_CHECKIN = (date, filters) => {
    if (filters) {
      let formatedTime = moment(moment(date.checkIn).toISOString()).format(DATE_FORMAT);
      let search = moment(moment(filters).toISOString()).format(DATE_FORMAT);
      if (search !== formatedTime)return true;
    } else return false;
  };

  export const UPDATE_FILTERS = (filterList, filterPos, index) => {
    if (filterPos === 0)
      filterList[index].splice(filterPos, 1, "");
    if (filterPos === 1)
      filterList[index].splice(filterPos, 1);
    if (filterPos === -1)
      filterList[index] = [];
    return filterList;
  };

  export const DEVICE_FILTER_LOGIC = (device, filters) => {
    if (filters) {
      if (device[0] !== filters)return true;
    } else return false;
  };

  export  const  STRING_FILTER_LOGIC = (label, filters) => {
    if (filters) {
      if(label!==filters)
        return true;
    } else
      return false;
  };