import moment from "moment";
import _ from "lodash";
import { jobStatusTypesGraphQL } from "../../jobsBoard.constants";
import { mutationCreateResourceSlot } from "../../../graphql/mutations";
import { formatRangeDatesWithOffsetAndTimezone } from "../jobsBoard";

export const handleAddNewSlot = async(tenantId, currentTeamId, startDate, endDate) =>{
  const {minDate, maxDate} = formatRangeDatesWithOffsetAndTimezone(startDate, endDate);
  const CreateResource_Slot_Input = {
    resource_slot_start_date: minDate,
    resource_slot_end_date: maxDate,
    resource_slot_name: new Date(),
    teamid : currentTeamId,
    tenantid: tenantId
  }
  let slotCreated = await mutationCreateResourceSlot(CreateResource_Slot_Input);

  if (slotCreated) {
    return "done";
  }
  else{
    return "error";
  }
}


export const handleFormatNewSlot = (currentDate, groups, newSlot) =>{
  let slotsCopy = [...groups];
  let slotsIndex = groups[groups.length-1].id + 1;
  const slotStartDate = moment(newSlot.resource_slot_template_start_date).format("YYYY-MM-DD HH:mm:ss");
  const slotEndDate = moment(newSlot.resource_slot_template_end_date).format("YYYY-MM-DD HH:mm:ss");
  const currentStartDateFormat = moment(currentDate).subtract(1,"hours").format("YYYY-MM-DD HH:mm:ss");
  const currentEndDateFormat = moment(currentDate).add(23,"hours").format("YYYY-MM-DD HH:mm:ss");
  const isNewSlotStartDateInTimeRange = moment(slotStartDate).isBetween(currentStartDateFormat, currentEndDateFormat) ;
  const isNewSlotEndDateInTimeRange = moment(slotEndDate).isBetween(currentStartDateFormat, currentEndDateFormat);

  if (isNewSlotStartDateInTimeRange && isNewSlotEndDateInTimeRange) {
    let slot = {
      id: slotsIndex,
      title: "",
      assignedId: "",
      checked: false,
      template: false,
      assigned: false,
      canEdit: true,
      status: jobStatusTypesGraphQL.unassigned,
      resource_slotid: newSlot.resource_slotid
    };
    slotsCopy.push(slot);
  }
  return slotsCopy;
}