import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import SuperadminMenu from '../user-account/superadminMenu.component';
import LoggedUser from '../user-account/loggedUser.component';
import { CAMPUS_TITLE, ROLE, DRAWER_WIDTH } from './superAdmin.constants';

const drawerWidth = DRAWER_WIDTH;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        backgroundColor: "#FFF",
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24), 0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24) !important',
        left: 45,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        left: 0,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    backIconDiv: {
        display: "flex",
        backgroundColor: "#2843A3",
        height: "100%",
        width: 35,
    },
    drawerIcon: {
        fill: "white",
        fontSize: "15px",
        "&:hover": {
            cursor: "pointer"
        }
    },
    toogleDrawler: {
        height: 56,
        zIndex: 100000,
    }
}));

export default function SuperAdminToolbar(props) {
    const classes = useStyles();

    const { first_name, last_name, toggleState } = props;
    const role = ROLE;

    //const [open, setOpen] = React.useState(toggleState);
    const [openMenu, setOpenMenu] = React.useState(false);


    const toggleUserMenu = () => {
        setOpenMenu(!openMenu);
    }

    const closeUserMenu = () => {
        if (openMenu) {
            setOpenMenu(false);
        }
    }

    const toggleArrow = () => {
        //setOpen(!open);
        //toggleDrawer();
    };

    return (
        <>
            <AppBar
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: toggleState
                })}
            >
                <div
                    id="webNavv-superAdmin-topbar-1.0"
                    className={classes.toogleDrawler}
                >
                    <div className={classes.backIconDiv}>
                        <IconButton onClick={toggleArrow}>
                            {!toggleState ? (
                                <ArrowForwardIosIcon className={classes.drawerIcon} />
                            ) : (
                                <ArrowBackIosIcon className={classes.drawerIcon} />
                            )}
                        </IconButton>
                    </div>
                </div>
            </AppBar>
            <div style={{ display: 'flex', position: 'absolute', top: 0, width: '100%', backgroundColor: 'transparent' }}>
                <div id="user-menu-container" style={{ display: 'flex', marginLeft: "80vw" }}>
                    {openMenu &&
                        <div style={{ zIndex: '99 !important', width: '0px' }}>
                            <SuperadminMenu />
                        </div>
                    }
                    <LoggedUser firstName={first_name} lastName={last_name} campus={CAMPUS_TITLE} role={role} toggleUserMenu={toggleUserMenu} closeUserMenu={closeUserMenu} />
                </div>
            </div>
        </>
    );
}