export const filterStyles = theme => ({
  listItemA:{
      fontSize: "14px !important",
      height: '15px',
      padding: '16px 7px 10px 50px !important'
  },
  allCallouts:{
      fontSize: "14px !important",
      height: '15px',
      padding: '16px 7px 10px 50px !important'
  },
  minimizeCallouts:{
      fontSize: "14px !important",
      height: '15px',
      padding: '16px 7px 10px 50px !important'
  },
  nestedSubtitle: {
      padding: "2px 0px 1px 25px !important",
      fontSize: "13px !important",
  },
  nestedTxt: {
    padding: "1px 0px 1px 25px !important",
    fontSize: "13px !important",
  },
  centerIcon: {
    color: "#0277bd"
  },
  nested: {
    padding: "5px 0px 15px 25px !important",
    fontSize: "13px !important",
  },
  nestedChip: {
    fontSize: "13px !important",
    marginLeft: "5px"
  },
  nestedBtn: {
    padding: "0px 0px 1px 15px !important",
    fontSize: "13px !important",
  },
  icon: {
    color: "#0277bd"
  },
  iconEnd: {
    color: "#757575"
  },
  iconJob: {
    fontSize: "13px"
  },
  iconButton: {
   marginLeft: "18px"
  },
  floorFilter: {
    height: "20px",
    marginBottom: "15px",
  }
});