
import {
    GET_EQUIPMENT,
    EQUIPMENT_CROWD,
    EQUIPMENT_LOCATION,
    GET_BEACON_LOCATION,
    EQUIPMENT_CATEGORIES,
    EQUIPMENT_LOCATION_KIO,
    EQUIPMENT_LOCATION_LOGS,
    EQUIPMENT_LOCATION_LOGS_PAGINATION} from "./types";
    import axios from 'axios';
    import hostName from "../../components/utilities/hostName";

    const secureUrl = new hostName();
    secureUrl.initHostName();

    export const getEquipment = (campusId) => (dispatch, getState) => {
      return new Promise ((resolve, reject) =>{
        try{
            axios.get(secureUrl.hostUrl+'/api/equipment?filter={"where":{"campusId":"' + campusId+ '"}}&access_token='+getState().auth.token)
            .then(resp=>{
                dispatch({
                    type:GET_EQUIPMENT,
                    payload:resp.data
                });
                resolve(resp);
            })
        }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipment: ", error);
            reject(error);
        }
      });
    }
    
    export const storeEquipment = (data) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            try {
                axios.post(secureUrl.hostUrl+'/api/equipment?access_token='+getState().auth.token,data)
                .then(resp=>{
                    const badgeData = {
                        UUID: data.UUID,
                        major: data.major,
                        minor: data.minor,
                        IsReadyToPair: true,
                        IsPaired: true,
                        userId: data.modifiedBy
                    };
                    axios.post(secureUrl.hostUrl+'/api/badges?access_token='+getState().auth.token,badgeData)
                    .then(res=>{
                      resolve(resp);
                    });
                })
            }catch(error){
                console.error("[EQUIPMENT ACTIONS] An error ocurred in storeEquipment: ", error);
                reject(error);
            }
        });
    };

    export const updateEquipmentBadge = (data, badgeInfo) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            try {
                axios.get(`${secureUrl.hostUrl}/api/badges?filter={"where":{"UUID":"${badgeInfo.UUID}", "major":${badgeInfo.major}, "minor":${badgeInfo.minor}}}&access_token=${getState().auth.token}`)
                .then(res=>{
                    if (res.data.length > 0 && !!res.data[0].id) {
                        const badgeId = res.data[0].id;
                        const badgeData = {
                            UUID: data.UUID,
                            major: data.major,
                            minor: data.minor,
                            IsReadyToPair: true,
                            IsPaired: true,
                            userId: data.modifiedBy
                        };
                        axios.put(secureUrl.hostUrl+`/api/badges/${badgeId}`,badgeData)
                        .then(res=>{
                            resolve(res);
                        });
                    } else { //Equipment with no badge
                        resolve(res);
                    }
                })
            }catch(error){
                console.error("[EQUIPMENT ACTIONS] An error ocurred in updateEquipmentBadge: ", error);
                reject(error);
            }
        });
    };

    export const updateEquipment = (data) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            try{
                axios.put(secureUrl.hostUrl+`/api/equipment/${data.id}`,data)
                .then(resp=>{
                    resolve(resp);
                })
            }catch(error){
                console.error("[EQUIPMENT ACTIONS] An error ocurred in updateEquipment:", error);
                reject(error);
            }
        });
    };
    
    export const deleteEquipment = (data) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            try{
                resolve(true);
                axios.delete(secureUrl.hostUrl+`/api/equipment/${data.id}`)
                .then(resp=>{
                    axios.get(`${secureUrl.hostUrl}/api/badges?filter={"where":{"UUID":"${data.UUID}", "major":${data.major}, "minor":${data.minor}}}&access_token=${getState().auth.token}`)
                    .then(res=>{
                        const badgeId = res.data[0].id;
                        axios.delete(secureUrl.hostUrl+`/api/badges/${badgeId}`)
                        .then(res=>{
                            resolve(resp);
                        });
                    })
                })
            }catch(error){
                console.error("[EQUIPMENT ACTIONS] An error ocurred in deleteEquipment: ", error);
                reject(error);
            }
        });
    };
    
    export const getEquipmentCrowd = (campusId) => (dispatch, getState) => {
        try {
            axios.get(secureUrl.hostUrl+'/api/equipment_crowdsources?filter={"where":{"campusId":"' + campusId+ '"}}&access_token='+getState().auth.token)
            .then(resp=>{
                dispatch({
                    type:EQUIPMENT_CROWD,
                    payload:resp.data
                })
            });
        }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipmentCrowd: ", error);
        }
    }
    
    export const getEquipmentLocation = (campusId) => (dispatch, getState) => {
        try{
            axios.get(secureUrl.hostUrl+'/api/equipment/equipmentLocation?campusId='+campusId+'&access_token='+getState().auth.token)
            .then(resp=>{
                dispatch({
                    type:EQUIPMENT_LOCATION,
                    payload:resp.data
                })
            });
        }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipmentLocation: ", error);
        }
    }
    
    export const getLocationByBeacon = (beaconInfo) => (dispatch, getState) => {
        try{
            axios.get(secureUrl.hostUrl+'/api/equipment_crowdsources/beaconLocation?UUID='+beaconInfo.UUID+'&major='+beaconInfo.major+'&minor='+beaconInfo.minor+'&access_token='+getState().auth.token)
            .then(resp=>{
                dispatch({
                    type:GET_BEACON_LOCATION,
                    payload:resp.data
                })
            });
        }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getLocationByBeacon: ", error);
        }
    }
    
    export const getEquipmentLocationKIO = (campusId) => (dispatch, getState) => {
        return new Promise ((resolve, reject) =>{
          try{
            axios.get(secureUrl.hostUrl+'/api/equipment/location?campusId='+campusId+'&access_token='+getState().auth.token);
            dispatch({
                type:EQUIPMENT_LOCATION_KIO,
                payload:true
            });
            resolve(true);
          }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipmentLocationKIO: ", error);
            reject(error);
          }
        });
    };
    
    export const getEquipmentLocationLogs = (campusId, UUID, major, minor) => (dispatch, getState) => {
        return new Promise ((resolve, reject) =>{
          try{
            axios.get(`${secureUrl.hostUrl}/api/equipment/equipmentLocationLogs?campusId=${campusId}&UUID=${UUID}&major=${major}&minor=${minor}&access_token=${getState().auth.token}`)
            .then(resp=>{
                dispatch({
                    type:EQUIPMENT_LOCATION_LOGS,
                    payload:resp.data.data
                });
                resolve(resp.data.data);
            });
          }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipmentLocationLogs: ", error);
            reject(error);
          }
        });
    };
    
    export const getEquipmentLocationLogsPagination = (campusId, UUID, major, minor, page, rows) => (dispatch, getState) => {
        return new Promise ((resolve, reject) =>{
          try{
            axios.get(`${secureUrl.hostUrl}/api/equipment/equipmentLocationLogsPagination?campusId=${campusId}&UUID=${UUID}&major=${major}&minor=${minor}&page=${page}&rows=${rows}&access_token=${getState().auth.token}`)
            .then(resp=>{
                dispatch({
                    type:EQUIPMENT_LOCATION_LOGS_PAGINATION,
                    payload:{
                        data: resp.data.data.data,
                        total: resp.data.data.total
                    }
                });
                resolve(true);
            });
          }catch(error){
            console.error("[EQUIPMENT ACTIONS] An error ocurred in getEquipmentLocationLogsPagination: ", error);
            reject(error);
          }
        });
    };
    
    export const categoriesByCampus = (campusId) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
        axios.get(secureUrl.hostUrl+'/api/equipment_categories?filter={"where":{"campusId":"' + campusId+ '"}}&access_token='+getState().auth.token)
        .then(resp=>{
            if(resp.data.length > 0)
              dispatch({
                  type:EQUIPMENT_CATEGORIES,
                  payload:resp.data
              });
              resolve(true);
        }).catch(error=>{
            console.error("[EQUIPMENT ACTIONS] An error ocurred in categoriesByCampus: ", error);
            reject(error);
        });
        });
    };
    
    export const storeCategory = (data) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(secureUrl.hostUrl+'/api/equipment_categories?access_token='+getState().auth.token,data).then(resp=>{
                resolve(resp);
            }).catch(error=>{
                console.error("[EQUIPMENT ACTIONS] An error ocurred in storeCategory: ", error);
                reject(error);
            });
        });
    };
    
    export const updateCategory = (data) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            axios.put(secureUrl.hostUrl+`/api/equipment_categories/${data.id}`,data).then(resp=>{
                resolve(resp);
            }).catch(error=>{
                console.error("[EQUIPMENT ACTIONS] An error ocurred in updateCategory: ", error);
                reject(error);
            });
        });
    };
    
    export const deleteCategory = (id) =>(dispatch, getState)=>{
        return new Promise ((resolve, reject) =>{
            axios.delete(secureUrl.hostUrl+`/api/equipment_categories/${id}`).then(resp=>{
                resolve(resp);
            }).catch(error=>{
                console.error("[EQUIPMENT ACTIONS] An error ocurred in deleteCategory: ", error);
                reject(error);
            })
        });
    };