/*
See LICENSE folder for this sample’s licensing information.

Abstract:
The IMDF archive class contains the indoor mapping data organized by feature types and implements level-based feature filtering.

    Levels model floors in a building. The IMDF features this sample uses include openings, amenities, anchors,
    and occupants. The methods are filters that find the features by floor.
*/
import axios  from 'axios';
import * as CONSTS from './IMDF.constants';
import hostName from '../../../../utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

class ImdfArchive{
    constructor(features){
        this.features = features;
        this.featuresByType = {};
        this.features.forEach((feature)=> {
            if (!!feature) {
                this.featuresByType[feature.feature_type] = this.featuresByType[feature.feature_type] || [];
                this.featuresByType[feature.feature_type].push(feature);
            }
        });
    }
    levels=(ordinal)=> {
        return this.featuresByType[CONSTS.LEVEL].filter((feature)=> {
            return feature.properties.ordinal === ordinal;
        });
    }

    unitsOnLevel = (levelId)=> {
        return this.featuresByType[CONSTS.UNIT].filter((feature)=> {
            return feature.properties.level_id.toLowerCase() === levelId.toLowerCase();
        });
    }
    unitsByIdOnLevel = (levelId)=> {
        // Cache the units (such as rooms or walkways) by their ID per level, so this data can be reused.
        this._unitsByIdOnLevelCache = this._unitsByIdOnLevelCache || {};
        if ( !(levelId in this._unitsByIdOnLevelCache) ) {
            var units = {};
            this.featuresByType[CONSTS.UNIT].map(unit=> {
                if (!!unit) {
                    if (unit.properties.level_id.toLowerCase() === levelId)
                    units[unit.id.toLowerCase()] = unit;
                }
            });
            this._unitsByIdOnLevelCache[levelId] = units;
        }
        return this._unitsByIdOnLevelCache[levelId];
    }

    openingsOnLevel = (levelId)=> {
        return this.featuresByType[CONSTS.OPENING].filter((feature)=> {
            return feature.properties.level_id.toLowerCase() === levelId.toLowerCase();
        });
    }

    occupantsWithAnchorsOnLevel = (levelId)=> {
        var unitsById = this.unitsByIdOnLevel(levelId.toLowerCase());
        var result = [];
        Object.values(unitsById).forEach(function(unit) {
            var name = (unit.properties.alt_name || {}).en || (unit.properties.name || {}).en
            var excludedCategories = CONSTS.EXCLUDE_CATEGORIES;
            if (!excludedCategories.includes(unit.properties.category) && (name != null || unit.properties.category != CONSTS.ROOM)) {
                var occupant = {
                    properties: {
                        name: { en: name },
                        category: unit.properties.category,
                    }
                };

                var anchor = {
                    geometry: unit.properties.display_point,
                    type: CONSTS.FEATURE,
                    feature_type: CONSTS.ANCHOR
                };
                result.push({ occupant: occupant, anchor: anchor });
            }
        });

        return result;
    }

}

const ImdfArchiveLoad = async (token,campusId)=> {
    var files = CONSTS.FILES;
    var completed = 0;
    var features = [];
    var getArray=[];
    files.map(name=>getArray.push(axios.get(secureUrl.hostUrl+`/api/${name}/${name}ByCampus?campusId=${campusId}&access_token=${token}`)));

  return new Promise((resolve,reject)=>{
      try{
        Promise.all(getArray).then(res=>{
            res.map(res=>features = features.concat(res.data.response[0].features));
            let IMDFLoaded =new ImdfArchive(features);
            resolve(IMDFLoaded);
        })
      }catch(error){
        console.error("[IMDFARCHIVE] An error ocurred fetching IMDF files: ", error);
      }
  })
}


export default ImdfArchiveLoad
