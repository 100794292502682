import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from "moment";

import './mapcontainer.component.css';
import {fetchACLS} from '../../actions/acl/aclActions';
import {getAccessTokenMK, logoutPromise, connectLastLocationSocket, disconnectLastLocationSocket} from '../../actions/auth/authActions';
import IDMMaps  from './mapkitJS/IndoorMap';
import { getPhones } from '../../actions/phones/phonesActions';
import {filterUsers} from "../../filters/aclUsersFilter";
import { listUsers } from "../../actions/user/userActions";
import {getEquipmentLocation, getEquipment, categoriesByCampus} from "../../actions/equipment/equipmentActions";
import {getCampuses, getCampus} from '../../actions/campus/campusActions';
import {changeGeoJSONJobs,
    storeHistoryTraces,
    changeView,
    centerActiveMap,
    selectTabRemote,
    changeZoomToEquipment,
    changeEquipmentFilter,
    changePeopleFilter,
    updateLastViewProps,
    showAnimationCallouts} from '../../actions/views/viewsActions';
import {clearSpaghettiTrace} from '../../actions/directions/directionsActions';
import {setLastLocation} from '../../actions/lastlocation/lastlocationActions';
import AwesomeSpinner from '../utilities/spinner';
import MasterAlert from '../utilities/alert.component';
import {handleFloorSwitchEventListener,
    handleRemoveFloorSwitchEventListener,
    handleDisplayFloorSwitch } from "./utils/floorSwitch";
import { handleShowMsgTrackingUserNotAvailable } from "./utils/mapAlerts";
import { checkIfCurrentUserShouldExpireSession } from "./utils/notify";
import { checkCurrentMapScenario } from "./utils/mapScenarios";
import { renderJobTaskHistory } from './utils/displayLocation/history-map-job-specimen';
import { renderUserJobHistory } from './utils/displayLocation/history-map-user-job';
import { renderEquipmentLocation } from './utils/displayLocation/map-equipment-location';
import { renderUserLocation } from './utils/displayLocation/map-user-location';
import { renderSelectedEquipment } from './utils/displayLocation/map-equipment-location';

class MapContainer extends React.Component{

    constructor(props){
        super(props);

        this.state={
            showSpinner: true,
            floorSelected: 0,
            snackBarOpen: false,
            snackBarMsg: "", 
            mapReadyToLoad: false,
        }
        this.isCampusInfoAvailable = false;
        this.isMapLoaded = false;
        this.NewMapkitJS = {};
        this.historyRoutesCleanList = [];
        this.openHTFirstTime = false;
        this.openJTFirstTime = false;
        this.spinnerInterval = null;
        this.floorInterval = null;
        this.checkIfConnectedToLastLocationSocket = true;
        this.connectedToSocket = false;
        this.socketIOQuery = '';
        this.lastPayloadReceivedTimestamp = new Date();
        this.checkForOfflineUsers = false;
    }


    componentDidMount = () =>{
        if (this.props.peopleMapFilter) {
            this.props.connectLastLocationSocket('*','');
        }
        
        if (this.props.acls === null || this.props.acls === undefined || this.props.acls.length === 0) {
            this.props.fetchACLS();
        }
        if (this.props.users === null || this.props.users === undefined) {
            this.props.listUsers();
        }

        this.props.getAccessTokenMK().then(resp=>{
            if (!this.props.campusList || !this.props.campusList.length) {
                this.props.getCampuses().then(resp=>{
                    //this.isCampusInfoAvailable=true;
                    this.setState({mapReadyToLoad: true});
                });
            }
            else {
                //this.isCampusInfoAvailable=true;
                this.setState({mapReadyToLoad: true});
            }
        });   
        
        
    
        this.handleSpinnerInterval();
        this.handleFloorInterval();
    };

    componentWillUnmount(){
        if (this.state.showSpinner) {
            clearInterval(this.spinnerInterval);
        }
        if (this.floorInterval) {
            clearInterval(this.floorInterval);
        }
        handleRemoveFloorSwitchEventListener();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.currentView !== 'settings') {
            const actions = this.mapScenariosActions();
            checkCurrentMapScenario(nextProps, this.props, this.mapkit, actions);
        }

        let enableEquipmentFunctions = !this.props.equipmentMapFilter && nextProps.equipmentMapFilter? true: false;
        if (enableEquipmentFunctions) {
            this.props.getEquipment(this.props.campusInfo.id);
            this.props.categoriesByCampus(this.props.campusInfo.id);
        }

        if (this.state.showSpinner && !nextState.showSpinner) {
            clearInterval(this.spinnerInterval);
        }

        return true
    }

    componentDidUpdate(prevProps, prevState){
        if(!!this.openHTFirstTime)
            this.openHTFirstTime = false;

        if(this.isCampusInfoAvailable){
            this.handleConfigurationToLoadMap();
        }
        if (this.state.mapReadyToLoad && !prevState.mapReadyToLoad) {
            this.handleConfigurationToLoadMap();
        }
        if(this.checkIfConnectedToLastLocationSocket){
            this.handleLastLocationSubscribe();
        }
    }

    /**
    * Function that checks if spinner should be removed
    */
    handleSpinnerInterval = () =>{
        this.spinnerInterval= setInterval(()=>{
            if (!!document.getElementById("levelpicker")) {
                handleFloorSwitchEventListener(this.handleCheckAnchorAnnotations);
                this.removeSpinner();
            }
        },1000);
    }

    /**
    * Function that checks if there is an update in the floor switch
    */

   
    handleFloorInterval = () =>{
        this.floorInterval= setInterval(()=>{
            this.handleCheckUpdateFloorSwitch();
            this.handleCheckIfRemoveAllLocationAnnotations();
          },2000);
    }
    
    
    /**
    * Function that returns array with all map scenarios actions
    */
    mapScenariosActions = () =>{
        return [
            this.setMapWithDefaultConfigurations,
            this.handleFromHistoryTable_toHistoryMap,
            this.handleFromJobHistory_toJobHistoryMap,
            this.handleFromJobHistoryMap_toActiveMap,
            this.handleShowCalloutsInJobHistoryMap,
            this.handleEquipmentFilterDisabled,
            this.handleFromPreviewEquipment_toDefaultOptions,
            this.handleTrackUser_toActiveMap,
            this.handleFromActiveMap_toTrackUser,
            this.handlePeopleDisabled_trackingUser,
            this.handlePeopleEnabled_trackingUser,
            this.handlePeopleDisabled_activeMap,
            this.handlePeopleEnabled_activeMap
        ];
    }

    /**
    * Function that checks if listener with lastLocationSocket needs to be added. If so, redirect received msgs.
    */
    handleLastLocationSubscribe = () =>{
        const {socketLastLocation} = this.props;
        
        if (!!socketLastLocation && this.socketIOQuery !== socketLastLocation.query && !this.connectedToSocket) {
            this.socketIOQuery = socketLastLocation.query;
            this.connectedToSocket = true;
            socketLastLocation.on("lastLocation_subscribe", (ms) =>{
                this.handleLastLocationMessage(ms);
            });
        }
    }

    /**
    * Function that receives lastlocation socket msgs, formats the data and call functions to print info on map.
    */
    handleLastLocationMessage = (ms) =>{
        const {jobTasks, viewProps, historyRoute,users, auth, activeUser,transporterFound, transporterPhone,traces, peopleMapFilter, equipmentMapFilter, equipmentSelected, activeFloor, allCallouts, smallCallouts, equipmentCategories, indoorOnly} = this.props;

        let enablePeopleFunctions = peopleMapFilter? true: false;
        let enableEquipmentFunctions = equipmentMapFilter? true: false;
        let zoomToEquipment = equipmentSelected !== '' ? true: false;
        this.lastPayloadReceivedTimestamp = new Date();
        this.checkForOfflineUsers = true;

        if (enableEquipmentFunctions && !zoomToEquipment) {
            const renderEquipmentLocationParameters = {
                equipmentLoc: ms.equipment_guid,
                activeFloor,
                allCallouts,
                smallCallouts,
                equipmentCategories,
                indoorOnly,
                mapkit: this.mapkit
            };
            this.mapkit = renderEquipmentLocation(renderEquipmentLocationParameters, this.handleFilterUsersByFloor);
        }
        if (enablePeopleFunctions && !!ms && !!ms.teamId && !jobTasks && !historyRoute && peopleMapFilter && !!users) {
            let locations = ms.teamId;
            if (transporterFound && ms.hasOwnProperty('userId')) {
                locations = Object.assign(ms.teamId, ms.userId);
            }
            this.props.setLastLocation(locations);
            const renderUserLocationParams = {
                message: locations, 
                viewProps, 
                users, 
                auth,
                activeUser,
                transporterFound,
                transporterPhone,
                activeFloor,
                traces,
                allCallouts,
                smallCallouts,
                indoorOnly,
                mapkit: this.mapkit,
                mapLoaded: this.isMapLoaded
            }
            renderUserLocation(renderUserLocationParams, this.handleSwitchFloor, this.handleFilterUsersByFloor);
        }
    }

    /**
    * Function scenario: Handles actions from view Equipment map to default options.
    */
    handleFromPreviewEquipment_toDefaultOptions = () =>{
        this.props.selectTabRemote({});
        this.props.changeZoomToEquipment('');
        this.props.changeEquipmentFilter(false);
        this.props.changePeopleFilter(true);
    }

    /**
    * Function scenario: Handles actions if Equipment filter is disabled.
    */
    handleEquipmentFilterDisabled = () =>{
        this.mapkit.handleRemoveEquipmentAnnotations();
    }

    /**
    * Function scenario: Handles actions if People filter is disabled when tracking a user.
    */
    handlePeopleDisabled_trackingUser = () =>{
        !!this.mapkit && this.mapkit.removeAllLocationTypeAnnotations();
        this.props.disconnectLastLocationSocket();
        this.checkIfConnectedToLastLocationSocket = false;
        this.socketIOQuery = '';
    }

    /**
    * Function scenario: Handles actions if People filter is enabled when tracking a user.
    */
    handlePeopleEnabled_trackingUser = () =>{
        let people = '*';
        let userId = this.props.transporterFound;
        this.props.connectLastLocationSocket(people,userId);
        this.checkIfConnectedToLastLocationSocket = true;
        this.connectedToSocket = false;
    }

    /**
    * Function scenario: Handles actions if People filter is disabled on Active map view.
    */
    handlePeopleDisabled_activeMap = () =>{
        !!this.mapkit && this.mapkit.removeAllLocationTypeAnnotations();
        this.props.disconnectLastLocationSocket();
        this.checkIfConnectedToLastLocationSocket = false;
        this.socketIOQuery = '';
    }

    /**
    * Function scenario: Handles actions if People filter is enabled on Active map view.
    */
    handlePeopleEnabled_activeMap = () =>{
        let people = '*';
        let userId = '';
        this.props.connectLastLocationSocket(people,userId);
        this.checkIfConnectedToLastLocationSocket = true;
        this.connectedToSocket = false;
    }

    /**
    * Function that sets selected floor in floor switch to the campus default floor.
    */
    setDefaultFloor = () =>{
        let defaultFloor = this.props.campusInfo.config.default_floor;
        if(document.getElementById(defaultFloor)){
            document.getElementById(defaultFloor).click();
        }
    };

    /**
    * Function that sets map configs to default values.
    */
    setMapWithDefaultConfigurations = () =>{
        !!this.mapkit && this.mapkit.cleanTraceOverlays();
        !!this.mapkit && this.mapkit.clearHistoryMapConfig();
        !!this.mapkit && this.mapkit.handleAnnotationsHistory();
        !!this.mapkit && this.mapkit.handleSetMapDefault();
        !!this.mapkit && this.mapkit.handleHideMapAnnotations();
        this.setDefaultFloor();
        this.openHTFirstTime = false;
    }

    /**
    * Function scenario: Handles actions in the transition from Active map to track a user
    */
    handleFromActiveMap_toTrackUser=(nextProps)=>{
        this.connectedToSocket = false;
        this.mapkit.handleAnnotationsHistory();
        this.mapkit.cleanTraceOverlays();
        this.props.disconnectLastLocationSocket();
        let people = '*';
        let userId = nextProps.transporterFound;
        this.props.connectLastLocationSocket(people,userId);
    }

    /**
    * Function scenario: Handles actions in the transition from tracking a user to Active map
    */
    handleTrackUser_toActiveMap = () =>{
        this.connectedToSocket = false;
        this.handleRemoveOfflinePhone();
        this.setMapWithDefaultConfigurations();
        this.props.disconnectLastLocationSocket();
        let people = '*';
        let userId = '';
        this.props.connectLastLocationSocket(people,userId);
    };

    /**
    * Function scenario: Handles actions in the transition from History table to History map view
    */
    handleFromHistoryTable_toHistoryMap = () =>{
        !!this.mapkit && this.mapkit.handleHideMapAnnotations();
        !!this.mapkit && this.mapkit.handleAnnotationsHistory();
        this.openHTFirstTime = true;

        let defaultFloor = this.props.campusInfo.config.default_floor;
        if(document.getElementById(defaultFloor)){
            document.getElementById(defaultFloor).click();
        }
    };

    /**
    * Function scenario: Handles actions in the transition from Job History Map to Active Map
    */
    handleFromJobHistoryMap_toActiveMap = () =>{
        this.openJTFirstTime = false;
        !!this.mapkit && this.mapkit.handleHideAllAnchorAnnotations();
        this.props.clearSpaghettiTrace();
        this.setMapWithDefaultConfigurations();
    };

    /**
    * Function scenario: Handles actions in the transition from Job History table to Job History Map
    */
     handleFromJobHistory_toJobHistoryMap = (nextProps) =>{
        if(!!nextProps){
            this.openJTFirstTime=true;
            this.props.showAnimationCallouts(true);
            !!this.mapkit && this.mapkit.handleShowDefaultAnchorAnnotations(this.props.campusInfo.config.default_floor, nextProps.jobTasks, nextProps.jobInfo);
            }else{
            console.error(`An error has ocurred in Job history, no information found `);
        }
    }

    /**
    * Function scenario: Calls a mapkit function to show Anchor annotations on map
    */
    handleShowCalloutsInJobHistoryMap = (nextProps) =>{
        this.mapkit.handleShowAnchorAnnotations(this.state.floorSelected, this.props.jobTasks, nextProps.animationShowCallouts, this.props.jobInfo);
    }

    /**
    * Function that initialice the map with its correspondant configs
    */
    handleConfigurationToLoadMap = () => {
        const {webAccessTK, token, campusInfo, campusList, specimenTracking, mapConfigs} = this.props;
        const currentCampus = campusList.find(campus=>campus.id === campusInfo.id);
        
        if (!this.isMapLoaded && webAccessTK !== "" && !!currentCampus && !!window.mapkit && !!mapConfigs) {
            this.NewMapkitJS = new IDMMaps(currentCampus.config, mapConfigs);
            this.NewMapkitJS.handleMKInit(webAccessTK);
            this.NewMapkitJS.handleConfigMap(currentCampus.config.default_floor,token, campusInfo.id, campusInfo.guid_campus);
            if (specimenTracking.Permission) {
                this.NewMapkitJS.handleLoadLocationAnnotationsOnMapBasedOnAnchor(campusInfo.id, token, mapConfigs);
            }
            this.mapkit = this.NewMapkitJS;
            this.isCampusInfoAvailable = false;
            this.isMapLoaded=true;
        }
    }

    /**
    * Function that checks if Anchor annotations should be visible in the map
    */
    handleCheckAnchorAnnotations = (event) =>{
        const {jobTasks, animationShowCallouts, jobInfo, specimenTracking} = this.props;

        if (!!this.mapkit && !!jobTasks && specimenTracking.Permission) {
            this.mapkit.handleShowAnchorAnnotations(event.target.id, jobTasks, animationShowCallouts, jobInfo);
            this.setState({floorSelected:event.target.id});
        }
    }

    /**
    * Function that removes spinner interval
    */
    removeSpinner = () =>{
      //clearInterval(this.spinnerInterval);
      this.setState({showSpinner: false});
    };

    /**
    * Function that removes gray annotations (devices tracked)
    */
    handleRemoveOfflinePhone= () => {
        const {transporterFound, transporterPhone} = this.props;
        let annotationId = transporterFound+transporterPhone;
        this.handleMapRemoveUser(annotationId);
        this.mapkit.cleanTraceOverlaysOfflineUser(annotationId);
    }

    /**
    * Function that removes user location of users that are now offline
    */
    handleCheckIfRemoveAllLocationAnnotations = () =>{
        let lastTimestampUpdate = moment(this.lastPayloadReceivedTimestamp);
        let currentTimestamp = moment(new Date());
    
        if (this.checkForOfflineUsers && currentTimestamp.diff(lastTimestampUpdate, "seconds") > 5) {
            this.checkForOfflineUsers = false;
            !!this.mapkit && this.mapkit.removeAllLocationTypeAnnotations();
        }
    }

    /**
    * Function that changes floor based on new ordinal
    */
    handleSwitchFloor=(newFloor)=>{
        if (!!newFloor || newFloor!==-100 || newFloor!=='outside'){
            if(document.getElementById(newFloor))
              document.getElementById(newFloor).click();
        }
    }

    /**
    * Function that filters users by floor level
    */
    handleFilterUsersByFloor=()=>{
        const {mapConfigs} = this.props;

        if (!!mapConfigs && !!mapConfigs.floors) {
            let currentFloor= "";
            mapConfigs.floors.map(floor=>{
              let className = "level-"+floor.ordinal+" selected";
              if (document.getElementsByClassName(className).length ===1) {
                currentFloor = floor.ordinal;
              }
              return null;
            });
            return currentFloor;
        } else {
            return null;
        }
    }

    /**
    * Function that removes a specific user location annotation from map
    */
    handleMapRemoveUser = (annotationId) => {
        if(this.isMapLoaded && !!this.mapkit){
            this.mapkit.handleRemoveUserLocationMark(annotationId);
        }
    }

    /**
    * Function that works with User History data to show it on the map
    */
    handleUserJobHistory = () => {
        console.log(`[SHOW HISTORY] 1.- START PAINT HISTORY`);
        const {spaghetti, historyRoute, timeWindow, wifi, jobInfo, jobsToHide, activeFloor, centerHistoryMap, animationShowTrace, animationShowCallouts} = this.props;
        console.log(`[SHOW HISTORY] 2.- START GET INFO`);
        console.log(`[SHOW HISTORY] traces: ${JSON.stringify( Object.keys(spaghetti).length)}`);
        console.log(`[SHOW HISTORY] historyRoute: ${JSON.stringify( Object.keys(historyRoute).length)}`);
        console.log(`[SHOW HISTORY] jobInfo: ${JSON.stringify(jobInfo)}`);
        console.log(`[SHOW HISTORY] centerHistoryMap: ${JSON.stringify(centerHistoryMap)}`);
        console.log(`[SHOW HISTORY] activeFloor: ${JSON.stringify(activeFloor)}`);
        const renderUserJobHistoryParams = {
            spaghetti, 
            historyRoute, 
            timeWindow, 
            wifi, 
            jobInfo, 
            jobsToHide, 
            activeFloor, 
            centerHistoryMap, 
            animationShowTrace, 
            animationShowCallouts, 
            mapkit: this.mapkit,
            historyRoutesCleanList: this.historyRoutesCleanList, 
            openHTFirstTime: this.openHTFirstTime,
        }
        const  renderUserJobHistoryActions = {
            handleFilterUsersByFloor: this.handleFilterUsersByFloor, 
            storeHistoryTraces: this.props.storeHistoryTraces,
            updateHistoryRoutesCleanList: this.updateHistoryRoutesCleanList,
            changeGeoJSONJobs: this.props.changeGeoJSONJobs
        }
        this.mapkit = renderUserJobHistory(renderUserJobHistoryParams,renderUserJobHistoryActions);
    }

    /**
    * Function that works with Job History data to show it on the map
    */
    handleJobTaskHistory = () => {
        const {spaghetti, historyRoute, timeWindow, wifi, jobInfo, jobsToHide, activeFloor, centerHistoryMap, animationShowTrace, animationShowCallouts} = this.props;
        let renderJobTaskHistoryParams = {
            spaghetti,
            historyRoute,
            timeWindow,
            wifi,
            jobInfo,
            jobsToHide,
            activeFloor,
            centerHistoryMap,
            animationShowTrace,
            animationShowCallouts,
            mapkit: this.mapkit,
            historyRoutesCleanList: this.historyRoutesCleanList,
            openJTFirstTime: this.openJTFirstTime
        };
        const renderJobTaskHistoryActions = {
            changeGeoJSONJobs: this.props.changeGeoJSONJobs,
            checkJobsToHideList: this.checkJobsToHideList,
            storeHistoryTraces: this.props.storeHistoryTraces,
            handleFilterUsersByFloor: this.handleFilterUsersByFloor,
            updateHistoryRoutesCleanList: this.updateHistoryRoutesCleanList
        }
        const updates = renderJobTaskHistory(renderJobTaskHistoryParams, renderJobTaskHistoryActions);
        this.mapkit = updates.mapkit;
        this.openJTFirstTime = updates.openJTFirstTime;
    }

    /**
    * Function that updates list of jobs that should be visible
    */
    updateHistoryRoutesCleanList= (jobList) =>{
        this.historyRoutesCleanList = jobList;
    }

    /**
    * Function that checks if a job in list should be hidden
    */
    checkJobsToHideList=()=>{
        const {jobsToHide, centerHistoryMap}= this.props;
        let centerMapHidden = false;

        if(jobsToHide.length>0){
            jobsToHide.forEach(job=>{
                if(job === centerHistoryMap.jobId) {
                    centerMapHidden= true;
                }
            });
        }
        return centerMapHidden
    }

    /**
    * Function that works with Equipment location to show it on map
    */
    renderEquipmentSelectedLocation=()=>{
        const {activeFloor,indoorOnly,equipmentSelected, campusInfo, equipmentMapFilter, allCallouts, smallCallouts, equipmentCategories, equipmentList} = this.props;
        let currentFloor = this.handleFilterUsersByFloor();
        if (!!this.mapkit && equipmentMapFilter){
          const renderSelectedEquipmentParams = {
            activeFloor,
            allCallouts,
            smallCallouts,
            equipmentCategories,
            equipmentList,
            equipmentSelected,
            indoorOnly,
            mapkit: this.mapkit
          }
          this.mapkit = renderSelectedEquipment(renderSelectedEquipmentParams, this.handleFilterUsersByFloor);
          this.mapkit.handleZoomEquipmentGeolocation(equipmentSelected,activeFloor,indoorOnly,currentFloor,campusInfo);
        }
    };

    /**
    * Function that handles map filters
    */
    handleMapFilters=()=>{
        const {peopleMapFilter, equipmentMapFilter, allCallouts, smallCallouts, activeFloor, indoorOnly, activeUser, transporterFound, transporterPhone, traces, viewProps, currentView} = this.props;
        let currentFloor = this.handleFilterUsersByFloor();
        if(!!this.mapkit && viewProps !=='history' && currentView==='map'){
            const handleMapFiltersParams = {
                peopleVisible:peopleMapFilter,
                calloutVisible: allCallouts,
                smallVisible: smallCallouts,
                filterFloor: activeFloor,
                floor: currentFloor,
                filterIndoor: indoorOnly,
                filterUser: activeUser,
                user: transporterFound
            }
            this.mapkit.handleMapFilters(handleMapFiltersParams);
        }
        if(!!this.mapkit && viewProps !=='history' && currentView==='map'){
            const handleMapEquipmentFiltersParams = {
                equipmentVisible: equipmentMapFilter,
                calloutVisible: allCallouts,
                smallVisible: smallCallouts,
                filterFloor: activeFloor,
                floor: currentFloor,
                filterIndoor: indoorOnly
            };
            this.mapkit.handleMapEquipmentFilters(handleMapEquipmentFiltersParams);
        }
        if (!!this.mapkit) {
            let annotationId = transporterFound+transporterPhone;
            this.mapkit.handleShowTracesFilter({traces, activeUser, annotationId, indoorOnly, activeFloor, currentFloor, peopleMapFilter, viewProps, currentView});
        }
    }

    /**
    * Function that centers the map to it's default config
    */
    handleCenterActiveMap = ()=>{
        if(this.props.centerActiveMapFlag === 'center' && !!this.mapkit) {
            this.mapkit.checkMapZoom();
            this.props.centerActiveMap('');
        }
    }

    /**
    * Function that return view from Map to Equipment table
    */
    handleReturnToEquipmentTable = () =>{
        this.props.selectTabRemote({view:'table', viewProp:'equipmentHistory', item:'ET'});
    }

    /**
    * Function that return view from Map to WorkflowAutomation
    */
    handleReturnToWorkflowAutomationTab = () =>{
        this.props.updateLastViewProps('');
        this.props.selectTabRemote({view:'workflowAutomation', viewProp:'jobsBoard', item:'WA-JB'});
    }

    /**
    * Function that handles 'return to' descriptions based on viewProps value
    */
    renderReturnToTable = () =>{
        const {viewProps} = this.props;

        if (viewProps === 'previewEquipment') {
            return(
                <div
                      className="equipment-Btn"
                      onClick={()=>this.handleReturnToEquipmentTable()}>
                        Return to Equipment Table
                </div>
            )
        } else {
            return(
                <div
                      className="jobsBoard-Btn"
                      onClick={()=>this.handleReturnToWorkflowAutomationTab()}>
                        Return to Jobs Board
                </div>
            )
        }
    }

    /**
    * Function that checks if the floor selected has changed
    */
    handleCheckUpdateFloorSwitch = () =>{
        const {mapConfigs} = this.props;
        
        if (!!mapConfigs.floors) {
            mapConfigs.floors.map(floor=>{
                let className = "level-"+floor.ordinal+" selected";
                if(document.getElementsByClassName(className).length ===1 && this.state.floorSelected !== floor.ordinal) {
                    this.setState({floorSelected: floor.ordinal});
                }
                 
                return null;
            });
        }
        
    }

    /**
    * Function that opens the snackbar msg
    */
    handleOpenSnackBar = (msg) => {
        this.setState({ snackBarOpen: true, snackBarMsg: msg });
    };

    /**
    * Function that closes the snackbar msg
    */
    handleCloseSnackBar = () => {
        this.setState({ snackBarOpen: false});
    };

    render(){
        const {showSpinner, snackBarOpen, snackBarMsg} = this.state;
        const {viewProps, currentView, peopleMapFilter, historyRoute, open, transporterFound, lastViewProps, jobTasks, users} = this.props;
        handleDisplayFloorSwitch(viewProps, currentView);
        this.handleMapFilters();
        this.handleCenterActiveMap();
        let showMap = currentView === "map"? 'block' : 'none';
        let isTrackUser = transporterFound !== undefined && transporterFound !== '' && transporterFound !== false? true: false;
        let showReturnToTableBtn = viewProps === 'previewEquipment' || (lastViewProps === 'jobsBoard' && isTrackUser)? true : false;
        return(
            <React.Fragment>
            <div id="mapContainer" style={{display:showMap}} >
                <div id="map"  style={{height: '100vh', display:showMap}}/>
                {/* {zoomToEquipment && this.renderEquipmentSelectedLocation()} */}
                {!!this.mapkit && historyRoute && this.handleUserJobHistory()}
                {!!this.mapkit && jobTasks && this.handleJobTaskHistory()}
            </div>
            {showReturnToTableBtn && this.renderReturnToTable()}
            { showSpinner && (
                <AwesomeSpinner id={"map-spinner"} config={'default'} loading={showSpinner} navOpen={open}/>
            )}
            { snackBarOpen && (
              <MasterAlert
                id='map-alert'
                snackStyle={{ marginTop: '5vh' }}
                anchor={{ vertical: 'top', horizontal: 'right' }}
                openSnack={snackBarOpen}
                handleClose={this.handleCloseSnackBar}
                alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
                elevation={6}
                variant={"filled"}
                type={'warning'}
                message={snackBarMsg}
              />
            )}
            </React.Fragment>
        )
    }

}


MapContainer.propTypes = {
    users:PropTypes.array.isRequired,
    traces: PropTypes.bool.isRequired,
    activeFloor: PropTypes.bool.isRequired,
    activeUser: PropTypes.bool.isRequired,
    getAccessTokenMK: PropTypes.func.isRequired,
    filterUsers: PropTypes.func.isRequired,
    peopleMapFilter: PropTypes.bool.isRequired,
    equipmentMapFilter: PropTypes.bool.isRequired,
    changeView: PropTypes.func.isRequired
}

const mapStateToProps = state => (
    {
    users:state.user.users,
    historyRoute:state.views.historyRoute,
    transporterFound:state.views.transporterFound,
    transporterPhone: state.views.transporterPhone,
    viewProps:state.views.viewProps,
    allCallouts:state.views.allCallouts,
    smallCallouts:state.views.smallCallouts,
    traces: state.views.traces,
    activeFloor : state.views.activeFloor,
    activeUser: state.views.activeUser,
    acls:state.acl.acls,
    webAccessTK:state.auth.tokenMK,
    auth:state.auth.acl,
    userId: state.auth.userId,
    currentView: state.views.currentView,
    token: state.auth.token,
    campusInfo:state.auth.userInfo.campusInfo,
    timeWindow: state.views.timeWindow,
    jobsToHide: state.views.jobsToHide,
    wifi:state.views.wifi,
    centerHistoryMap:state.views.centerHistoryMap,
    jobTasks: state.views.jobTasks,
    jobInfo: state.views.jobInfo,
    open:state.views.drawerOpen,
    peopleMapFilter: state.views.peopleMapFilter,
    equipmentMapFilter: state.views.equipmentMapFilter,
    equipmentList: state.equipments.equipmentList,
    socketLastLocation: state.auth.socketLastLocation,
    equipmentSelected: state.views.equipmentSelected,
    animationShowTrace: state.views.animationShowTrace,
    animationShowCallouts: state.views.animationShowCallouts,
    indoorOnly: state.views.indoorOnly,
    lastLocation: state.contact.lastLocation,
    centerActiveMapFlag: state.views.centerActiveMapFlag,
    campusList: state.campus.campusList,
    equipmentCategories: state.equipments.equipmentCategories,
    lastViewProps: state.views.lastViewProps,
    spaghetti: state.directions.spaghetti,
    specimenTracking: state.auth.featureFlags.SpecimenCollection,
    mapConfigs: state.views.mapConfigs
    }
)

export default connect(mapStateToProps,{
    logoutPromise,
    fetchACLS,
    getAccessTokenMK,
    filterUsers,
    getPhones,
    listUsers,
    changeGeoJSONJobs,
    storeHistoryTraces,
    getEquipmentLocation,
    getEquipment,
    categoriesByCampus,
    changeView,
    centerActiveMap,
    selectTabRemote,
    changeZoomToEquipment,
    changeEquipmentFilter,
    changePeopleFilter,
    getCampuses,
    updateLastViewProps,
    showAnimationCallouts,
    clearSpaghettiTrace,
    setLastLocation,
    connectLastLocationSocket,
    disconnectLastLocationSocket,
    getCampus})(MapContainer);