import React from 'react';
import firebase from 'firebase/app';
import "firebase/remote-config";
const FlagsContext = React.createContext({});
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
  minimumFetchIntervalMillis: 300000,
};

export const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = React.useState(defaults);

  React.useEffect(() => {
    remoteConfig.defaultConfig = defaults;
    remoteConfig
     .fetchAndActivate()
     .then(activated => {
        //if (!activated) console.log('[FIREBASE PROVIDER] not activated');
        return remoteConfig.getAll();
     })
     .then(remoteFlags => {
       const newFlags = {
         ...flags,
       };
       for (const [key, config] of Object.entries(remoteFlags)) {
        //console.log("[FIREBASE PROVIDER] FF keys: ", key, config)
          newFlags[key] = config._value;
       }
       setFlags(newFlags);
    })
    .catch(error => console.error("[FLAGSPROVIDER] ", error));
  }, []);

  return (
    <FlagsContext.Provider value={flags}>
      {children}
    </FlagsContext.Provider>
  );
};

export const useFlags = () => {
    const context = React.useContext(FlagsContext);
    return context;
}
  