import {
    LIST_USER,
    LIST_USER_INFO,
    LIST_USER_PAGINATION,
    USER_ERROR,
    CREATE_USER,
    PATCH_USER,
    DELETE_USER,
    LOGGED_USER,
    UPDATE_USER,
    SAVE_USER,
    SAVE_USER_EMAIL,
    GET_USERS} from '../actions/user/types';

const initialState = {
    users: [],
    usersPagination: [],
    user : {},
    allUsers: [],
    success:false,
    usersObj:{}
}

export default function( state = initialState, action){
    switch(action.type){
        case PATCH_USER:
            return {
                ...state,
                users: action.payload,
                success: true
            };
        case CREATE_USER:
            return {
                ...state,
                users: action.payload,
                success:true
            };
        case DELETE_USER: 
            return {
                ...state,
                users: action.payload,
                success:true
            };
        case LIST_USER:
            return{
                ...state,
                users: action.payload,
                success:false
            };
        case LIST_USER_INFO:
            const idIsEqual = (user) => user.usedBy === action.userId;
            let index = state.users.findIndex(idIsEqual);
            let usersCopy = [...state.users];
            usersCopy[index] = action.payload;
            return{
                ...state,
                users: usersCopy,
                success:false
            };
        case LIST_USER_PAGINATION:
            return{
                ...state,
                usersPagination: action.payload,
                success:false
            };
        case GET_USERS:
            return{
                ...state,
                allUsers: action.payload,
                success:false
            };
        case SAVE_USER:
            return{
                ...state,
                usersObj: action.payload,
                success:false
            };
        case SAVE_USER_EMAIL:
            return{
                ...state,
                usersEmailObj: action.payload,
                success:false
            };                    
        case LOGGED_USER:
            return{
                ...state,
                user: action.payload,
                success:true
            };
        case USER_ERROR:
            return{
                dialogError: action.payload.dialogError
            }
        case UPDATE_USER:
            return {
                ...state,
                users:[...state.users,{users:action.payload,
                    users:{...action.payload,lastLocation:action.payload.lastLocation}}],
                    success:true
                }
        default:
            return state;
    }
}