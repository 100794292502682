import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from 'material-ui/svg-icons/content/add';
import {withStyles} from '@material-ui/core/styles';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

import AwesomeSpinner from '../../utilities/spinner';
import MasterTable from '../../utilities/table.component';
import MasterAlert from '../../utilities/alert.component';
import ModalPop from '../../utilities/modal.component';
import EquipmentInfo from './addEquipment/equipmentInfo.component';
import CustomToolbarSelect from './toolbarSelect.component';
import CustomToolbar from  '../../utilities/refreshBtn.component';
import {createLog} from '../../../actions/logs/logsActions';
import {getEquipment, deleteEquipment, categoriesByCampus} from '../../../actions/equipment/equipmentActions';
import { styles } from './equipmentTable.styles';
import { UPDATE_FILTERS, STRING_FILTER_LOGIC} from "../../utilities/common_tableFilters";
import * as CONSTS from "./equipmentTable.constants";

class EquipmentControl extends React.Component {
    //modified
    state={
        open:false,
        data: [],
        showForm: false,
        showSpinner: true,
        isUpdate: false,
        openSnack: false,
        snackMsg: '',
        snackSeverity: '',
        openModal: false,
        equipmentSelected: {},
        isSearchClosed: false,
        showTable:false
    }
    tableData = [];
    equipmentList=[];
    teamList=[];
    onSearch=false;
    searchBy='';
    onFilter=false;
    filterBy=[];

    componentDidMount(){
      this.props.categoriesByCampus(this.props.campusId);
      this.props.getEquipment(this.props.campusId).then(resp=>{
        this.setState({showSpinner: false, showTable: true});
      });
    }

    componentDidUpdate(){
      if(this.state.isSearchClosed)
        this.setState({isSearchClosed: false});
    }

    handleHideForm = () =>{
      this.setState({showForm: false});
    };

    handleNewEquipment = () =>{
      this.setState({showForm : true, isUpdate: false});
    };

    handleOpenSnackbar = (type, ms) =>{
      this.setState({openSnack: true, snackMsg: ms, snackSeverity: type});
    };

    handleCloseSnackbar = () =>{
      this.setState({openSnack: false, snackMsg: '', snackSeverity: CONSTS.SNACKBAR_SUCCESS});
    };

    handleOpenModal = () => {
      this.setState({openModal: true});
    };

    handleCloseModal = () => {
      this.setState({openModal: false});
    };

    handleShowSpinner = () =>{
      this.setState({showSpinner: true});
    };

    handleHideSpinner = () =>{
      this.setState({showSpinner: false});
    };

    handlePerformedBy = ()  => {
      const {user, userId} = this.props;
      let performedBy = '';
      if(user && user.name) {
          performedBy = user.name;
      } else if(user && user.id) {
          performedBy = user.id;
      } else if (userId) {
          performedBy = userId;
      }
      return performedBy;
    }

    deleteEquipment = () =>{
      const {classes} = this.props;
      const {equipmentSelected} = this.state;
      let performedBy = this.handlePerformedBy();
      this.handleCloseModal();
      this.handleShowSpinner();
      this.props.deleteEquipment(equipmentSelected).then(resp=>{
        this.createEquipmentLog({
          action: CONSTS.DELETE_EQ_ACTION,
          description:`Equipment "${equipmentSelected.beaconNum}" deleted by "${performedBy}"`,
          appliedTo: equipmentSelected});
        this.handleHideSpinner();
        let msg= <div className={classes.inlineContainer}>Equipment "<div className={classes.boldSnackMsg}>{equipmentSelected.beaconNum}</div>" deleted successfully!</div>
        this.props.getEquipment(this.props.campusId);
        this.setState({ equipmentSelected: {} });
        this.handleOpenSnackbar(CONSTS.SNACKBAR_SUCCESS, msg);
      }).catch(err=>{
        this.createEquipmentLog({
          action: CONSTS.DELETE_EQ_ACTION_ERROR,
          description:`Equipment "${equipmentSelected.beaconNum}" couldn't be deleted by "${performedBy}" due enpoint ${err}`,
          appliedTo: equipmentSelected});
        this.handleHideSpinner();
        let msg= <div className={classes.inlineContainer}>Equipment "<div className={classes.boldSnackMsg}>{equipmentSelected.beaconNum}</div>" couldn't be deleted!</div>
        this.setState({ equipmentSelected: {} });
        this.handleOpenSnackbar(CONSTS.SNACKBAR_WARNING, msg);
      });
    };

    createEquipmentLog = (data) =>{
      const {user, userId} = this.props;
      let performedBy = '';
      if(user && user.id) {
          performedBy = user.id;
      } else if (userId) {
          performedBy = userId;
      }
      let log={
          campusId: this.props.campusId,
          userId: performedBy,
          action: data.action,
          description: data.description,
          timestamp: new Date()
      };
      this.props.createLog(log);
  };

    handleDelete = (index) => {
      this.setState({equipmentSelected: this.tableData[index]});
      this.handleOpenModal();
    };

    handleEditEquipment = (index) =>{
      this.setState({showForm: true, isUpdate: true, equipmentSelected: this.tableData[index]});
    }

    stringFieldsCustomRender = (filterList, onChange, index, column,key)=>{
      let inputLabel = "";
      let optionList = [];
      if(key===CONSTS.STRING_INPUT_TYPE.equipment.id){
       inputLabel = CONSTS.STRING_INPUT_TYPE.equipment.label;
       optionList = this.equipmentList.sort();
      }else if(key===CONSTS.STRING_INPUT_TYPE.team.id){
        inputLabel = CONSTS.STRING_INPUT_TYPE.team.label;
        optionList = this.teamList.sort();
      }
      const onTagsChange = (event, values) => {
         filterList[index][0] = values;
         onChange(filterList[index], index, column);
      }
       return(
         <Autocomplete
           id={key}
           options={optionList}
           getOptionLabel={(option) => option}
           style={{ width: 320 }}
           onChange={onTagsChange}
           renderInput={(params) => <TextField {...params} label={inputLabel} InputLabelProps={{shrink: true}} />}
         />
       );
     }

    handleChangeSwitch=()=>{
      this.filterBy=[];
      this.onFilter=false;
      this.onSearch=false;
      this.searchBy='';
      this.props.getEquipment(this.props.campusId);
    }

    handleTableFilters = (column)=>{
      let filter = this.filterBy.length ? this.filterBy[column] : null
      return filter;
    };

    render() {
      const {classes} = this.props;
      this.tableData = [];
      this.props.equipmentCategories.forEach(category =>{
        if (!this.equipmentList.includes(category.name) && !!category.name)
        this.equipmentList.push(category.name);
      });
      this.props.roles.forEach(role=>{
        if(!this.teamList.includes(role.role) && !!role.role)
        this.teamList.push(role.role);
      });
      if(this.props.equipmentList.length > 0){
        this.props.equipmentList.forEach(item=>{
          let categoryName = '';
          let categoryInfo = this.props.equipmentCategories.find(category =>category.id === item.device_category);
          let roleInfo = this.props.roles.find(role=>role._id === item.team);
          if(!!categoryInfo)
            categoryName = categoryInfo.name;

          if (!!roleInfo) {
            this.tableData.push({
              equipment: categoryName,
              beaconNum: item.beacon_number,
              UUID: item.UUID,
              major: item.major,
              minor: item.minor,
              controlNum: item.control_number,
              team: roleInfo.role,
              model: item.model,
              maker: item.maker,
              department: item.department,
              id: item.id,
              creationDate: item.creationDate,
              modificationDate: item.modificationDate,
              trackingId: item.trackingId
            });
          }
        });
      }

      let applyFilters = false;
      if (this.filterBy.length !== 0){
        this.filterBy.forEach(filter=>{
          if(filter.length)
            applyFilters = true
        });
        if(!applyFilters)
          this.handleChangeSwitch();
      }

      const columns = [
        {
          name: CONSTS.COLUMNS.equipment.id,
          label: CONSTS.COLUMNS.equipment.label,
          options: {
            sortThirdClickReset: true,
            filterType:CONSTS.FILTER_TYPE.custom,
            filterList: this.handleTableFilters(0),
            customFilterListOptions: {
              render: equipment => `${CONSTS.COLUMNS.equipment.label}: ${equipment}`,
              update: (filterList, filterPos, index) =>
                UPDATE_FILTERS(filterList, filterPos, index)
            },
            filterOptions: {
              names: [],
              logic(equipment, filters) {
                return STRING_FILTER_LOGIC(equipment, filters[0]);
              },
              display: (filterList, onChange, index, column) =>
                this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_INPUT_TYPE.equipment.id)
            },
            customBodyRender: (value) => <p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>
          }
        },
        {
            name: CONSTS.COLUMNS.beaconNum.id,
            label: CONSTS.COLUMNS.beaconNum.label,
            options: {
              sort:false,
              filterType:CONSTS.FILTER_TYPE.textField,
              filterList: this.handleTableFilters(1),
              customHeadRender: (value, tableMeta)=>{
                return (<div
                  className="MuiTableCell-root MuiTableCell-head"
                  style={{
                    width:'auto',
                    top:"0px",
                    left:"0px",
                    backgroundColor:"white",
                    position:"sticky",
                    zIndex:"100"
                  }}>
                  <div
                    className="MUIDataTableHeadCell-contentWrapper"
                    style={{
                      justifyContent: "center",
                      display: "flex"
                    }}>
                    {CONSTS.COLUMNS.beaconNum.label}
                  </div>
                </div>)
              },
            }
        },
        {
          name: CONSTS.COLUMNS.major.id,
          label: CONSTS.COLUMNS.major.label,
          options: {
            sort:false,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(2),
            customHeadRender: (value, tableMeta)=>{
              return (<div
                className="MuiTableCell-root MuiTableCell-head"
                style={{
                  width:'auto',
                  top:"0px",
                  left:"0px",
                  backgroundColor:"white",
                  position:"sticky",
                  zIndex:"100"
                }}>
                <div
                  className="MUIDataTableHeadCell-contentWrapper"
                  style={{
                    justifyContent: "center",
                    display: "flex"
                  }}>
                  {CONSTS.COLUMNS.major.label}
                </div>
              </div>)
            },
          }
        },
        {
          name: CONSTS.COLUMNS.minor.id,
          label: CONSTS.COLUMNS.minor.label,
          options: {
            sort:false,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(3),
            customHeadRender: (value, tableMeta)=>{
              return (<div
                className="MuiTableCell-root MuiTableCell-head"
                style={{
                  width:'auto',
                  top:"0px",
                  left:"0px",
                  backgroundColor:"white",
                  position:"sticky",
                  zIndex:"100"
                }}>
                <div
                  className="MUIDataTableHeadCell-contentWrapper"
                  style={{
                    justifyContent: "center",
                    display: "flex"
                  }}>
                  {CONSTS.COLUMNS.minor.label}
                </div>
              </div>)
            },
          }
        },
        {
          name: CONSTS.COLUMNS.controlNum.id,
          label: CONSTS.COLUMNS.controlNum.label,
          options: {
            sort:false,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(4),
            customHeadRender: (value, tableMeta)=>{
              return (<div
                className="MuiTableCell-root MuiTableCell-head"
                style={{
                  width:'auto',
                  top:"0px",
                  left:"0px",
                  backgroundColor:"white",
                  position:"sticky",
                  zIndex:"100"
                }}>
                <div
                  className="MUIDataTableHeadCell-contentWrapper"
                  style={{
                    justifyContent: "center",
                    display: "flex"
                  }}>
                  {CONSTS.COLUMNS.controlNum.label}
                </div>
              </div>)
            },
          }
        },
        {
          name: CONSTS.COLUMNS.team.id,
          label: CONSTS.COLUMNS.team.label,
          options: {
            sortThirdClickReset: true,
            filterType:CONSTS.FILTER_TYPE.custom,
            filterList: this.handleTableFilters(5),
            customFilterListOptions: {
              render: team => `${CONSTS.COLUMNS.team.label}: ${team}`,
              update: (filterList, filterPos, index) =>
                UPDATE_FILTERS(filterList, filterPos, index)
            },
            filterOptions: {
              names: [],
              logic(team, filters) {
                return STRING_FILTER_LOGIC(team, filters[0]);
              },
              display: (filterList, onChange, index, column) =>
                this.stringFieldsCustomRender(filterList, onChange, index, column, CONSTS.STRING_INPUT_TYPE.team.id)
            },
            customBodyRender: (value) => <p style={{textAlign: 'left', paddingLeft:'5px'}}>{value}</p>
          }
        },
        {
          name: CONSTS.COLUMNS.model.id,
          label: CONSTS.COLUMNS.model.label,
          options: {
            sort:false,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(6),
            customHeadRender: (value, tableMeta)=>{
              return (<div
                className="MuiTableCell-root MuiTableCell-head"
                style={{
                  width:'auto',
                  top:"0px",
                  left:"0px",
                  backgroundColor:"white",
                  position:"sticky",
                  zIndex:"100"
                }}>
                <div
                  className="MUIDataTableHeadCell-contentWrapper"
                  style={{
                    justifyContent: "center",
                    display: "flex"
                  }}>
                  {CONSTS.COLUMNS.model.label}
                </div>
              </div>)
            },
          }
        },
        {
          name: CONSTS.COLUMNS.maker.id,
          label: CONSTS.COLUMNS.maker.label,
          options: {
            sortThirdClickReset: true,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(7)
          }
        },
        {
          name: CONSTS.COLUMNS.department.id,
          label: CONSTS.COLUMNS.department.label,
          options: {
            sortThirdClickReset: true,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(8)
          }
        },
        {
          name: CONSTS.COLUMNS.trackingId.id,
          label: CONSTS.COLUMNS.trackingId.label,
          options: {
            sortThirdClickReset: true,
            filterType:CONSTS.FILTER_TYPE.textField,
            filterList: this.handleTableFilters(9)
          }
        }
      ];
    const options = {
        searchText: this.searchBy,
        filter: true,
        print: false,
        filterType: CONSTS.TABLE_OPTIONS.filterType,
        responsive: CONSTS.TABLE_OPTIONS.responsive,
        rowsPerPage: this.state.tableRowsPerPage,
        rowsPerPageOptions: CONSTS.ROWS_PER_PAGE,
        selectableRows: CONSTS.TABLE_OPTIONS.selectableRows,
        selectableRowsHeader: false,
        selectableRowsOnClick: true,
        downloadOptions: {
          filename: CONSTS.TABLE_OPTIONS.filename,
          separator: CONSTS.TABLE_OPTIONS.separator,
          filterOptions: {
            useDisplayedColumnsOnly: false,
            useDisplayedRowsOnly: true
          }
        },
        customSort: (data, colIndex, order) => {
          let sortType = order=== CONSTS.DEFAULT_SORT? true:false;
          return CONSTS.TABLE_SORT(data, colIndex, sortType);
        },
        customToolbar: () => {
          return (
            <CustomToolbar
            handleChangeSwitch={this.handleChangeSwitch}
            table={CONSTS.TABLE_ID}
            data={this.tableData}/>
          );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
          <CustomToolbarSelect
          selectedRows={selectedRows}
          data={this.tableData}
          setSelectedRows={setSelectedRows}
          edit={this.handleEditEquipment}
          showDelete={this.handleDelete}/>
        ),
        onTableChange:(action,tableState)=>{
          switch(action){
            case CONSTS.TABLE_EVENTS.changeRowsPerPage:
              this.setState({tableRowsPerPage: tableState.rowsPerPage});
              break
            case CONSTS.TABLE_EVENTS.propsUpdate:
              if(this.filterBy.length!==0 && this.onFilter)
              tableState.filterList= this.filterBy;
              break
            case CONSTS.TABLE_EVENTS.search:
              if(tableState.searchText){
                this.searchBy = tableState.searchText;
                this.onSearch = true;
              }
              break
            case CONSTS.TABLE_EVENTS.filterChange:
              this.filterBy = tableState.filterList;
              this.onFilter = true;
              break
            case CONSTS.TABLE_EVENTS.onSearchClose:
              this.searchBy = '';
              this.onSearch = false;
              this.setState({isSearchClosed: true});
              break
            case CONSTS.TABLE_EVENTS.resetFilters:
              this.handleChangeSwitch();
              break
            default:
              break
          }
        },
      };
      const tableTitle=()=>{
        return(
        <div className={classes.titleContainer}>
          <div className={classes.titleIconDiv}>
            <BusinessCenterIcon className={classes.titleIcon}/>
          </div>
          <h2 className={classes.titleLbl}>{CONSTS.TABLE_TITLE}</h2>
        </div>)
      }
      let l= this.props.open? '23%': '4%';
      let w= this.props.open? '75.5%': '95%';

      return(
        <div id="webNavv-adminPanel-equipment-1.0" style={{marginTop:'5%',marginLeft:l, width:w}}>
        {this.state.showTable && !this.state.showForm && (
        <div id="webNavv-adminPanel-equipment-equipmentTable-1.0">
          <MasterTable
            title={tableTitle()}
            data={this.tableData}
            columns={columns}
            options={options}
          />
         <FloatingActionButton
           id="webNavv-adminPanel-equipment-equipmentTable-addEquipmentButton-1.0"
           backgroundColor={'#039be5'}
           className={classes.addButton}
           onClick={()=>this.handleNewEquipment()}>
            <ContentAdd />
         </FloatingActionButton>
        </div>)
        }
        {this.state.showForm && (
          <div id="equipmentControl-form-container">
          <EquipmentInfo
          isUpdate={this.state.isUpdate}
          equipmentSelected={this.state.equipmentSelected}
          handleHideForm={this.handleHideForm}
          handleHideSpinner={this.handleHideSpinner}
          handleOpenSnackbar={this.handleOpenSnackbar}
          createEquipmentLog={this.createEquipmentLog}/>
          </div>
        )}
        {this.state.showSpinner && (
          <div id="equipmentControl-spinner-container">
            <AwesomeSpinner config={CONSTS.SPINNER_CONFIG} loading={this.state.showSpinner} navOpen={this.props.open}/>
          </div>
        )}
        <div id="equipmentControl-alert-container">
        <MasterAlert
          snackStyle={{marginTop:'5vh'}}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          openSnack={this.state.openSnack}
          handleClose={this.handleCloseSnackbar}
          alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
          variant={CONSTS.ALERT_VARIANT}
          type={this.state.snackSeverity}
          message={this.state.snackMsg}
         />
        </div>
        <div id="equipmentControl-modal-container">
        <ModalPop
          handleCloseModal={this.handleCloseModal}
          openModal={this.state.openModal}
          icon={<ReportProblemIcon  className={classes.problemIcon}/>}
          title={CONSTS.MODAL_TITLE}
          subtitle={CONSTS.MODAL_SUBTITLE}
          handleFirstOption={this.deleteEquipment}
          handleSecondOption={this.handleCloseModal}
          button1Label={CONSTS.DELETE_BTN}
          button2Label={CONSTS.CANCEL_BTN}
        />
        </div>
      </div>
    )
    }
}

EquipmentControl.propTypes = {
  users: PropTypes.array.isRequired,
  equipmentList: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  users: state.user.users,
  equipmentList: state.equipments.equipmentList,
  equipmentCategories: state.equipments.equipmentCategories,
  campusId: state.auth.userInfo.campusId,
  open:state.views.drawerOpen,
  roles: state.acl.acls,
  user: state.user.user,
  userId: state.auth.userId
})

export default connect(mapStateToProps,{getEquipment, deleteEquipment, categoriesByCampus, createLog}) (withStyles(styles)(EquipmentControl));