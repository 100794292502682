import { ALL_CONTACTS, SELECT_USER, SET_NAMES, LAST_LOCATION, DETAIL_CONTACTS,DETAIL_NAME} from "../actions/contacts/types";

const initialState = {
  allContacts: [],
  directContacts: [],
  userSelected: {},
  names: [],
  lastLocation: {},
  detailContacts:[],
  nameDetail:''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_CONTACTS:
      return {
        ...state,
        allContacts: action.payload.contacts,
      };
    case SELECT_USER:
      return {
        ...state,
        userSelected: action.payload
      };
    case SET_NAMES:
      return {
        ...state,
        names: action.payload
      };
    case LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.payload
      };
    case DETAIL_CONTACTS:
      return {
        ...state,
        detailContacts: action.payload
      }
      case DETAIL_NAME:
        return {
          ...state,
          nameDetail: action.payload
        }
    // case DIRECT_CONTACTS:
    //   return {
    //     ...state,
    //     directContacts: action.payload.contacts
    //   };
    default:
      return state;
  }
}
