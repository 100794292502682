import { GET_ROUTE, RESET, GET_ETA, GET_SPAGHETTI, DIRECTIONS_ERROR,GETSPACES, CLEAR_SPAGHETTI } from '../actions/directions/types';

const initialState = {
    routes: [],
    newRoute: null,
    transporterId: -1,
    spaghetti:null,
    dialogError:null,
    spaces:[],
    map:undefined
}

export default function( state = initialState, action){
    switch(action.type){
        case GET_ROUTE:
            return{
                newRoute:action.payload.newRoute,
                transporterId: action.payload.transporterId
            }
        case RESET:
            return{
                newRoute:null,
                transporterId:-1,
                dialogError:null,
            }
        case GET_ETA:
            return{
                newETA:action.payload.newETA,
                ETAuserID:action.payload.ETAuserID
            }
        case GET_SPAGHETTI:
            return{
                spaghetti:action.payload.traces
            }
        case DIRECTIONS_ERROR:
            return {
                ...state,
                dialogError:action.payload
            }
        case GETSPACES:
            return{
                ...state,
                spaces:action.payload
            }
        case CLEAR_SPAGHETTI:
            return{
                ...state,
                spaghetti:null
            }
        default:
            return state;
    }
}