//CSS
import '../phones.component.css';

//material UI
import FormLabel from '@material-ui/core/FormLabel';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import lightBlue from "@material-ui/core/colors/lightBlue";
import Grid from '@material-ui/core/Grid';
import Divider from "material-ui/Divider";

import {EMPTY_USER_ID} from '../phones.constants';
import {sendComment, getCommentsByPhone, cleanCommentsByPhoneList, checkDuplicatePhone} from '../../../actions/phones/phonesActions';
import {createLog} from '../../../actions/logs/logsActions';
import axios from 'axios';
import {cloneDeep} from 'lodash';
import { connect } from "react-redux";
//local components
import DeactivatePhone from './phone-tools/deactivePhone.component';
import React from 'react';
import AwesomeSpinner from '../../utilities/spinner';
import PhoneComments from './phoneComments/phoneComments.component';
import PhoneForm from './phoneForm/phoneForm.component';
import SimpleModalProp from '../../utilities/modalSimple.component';
import MaskedInput from 'react-text-mask';
import incorrect from '../../../img/incorrect.svg';
import hostName from '../../utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

class AddPhone extends React.PureComponent{

    constructor(props){
        super(props);
        this.state={
            isActionInProgress:false,
            phoneInfo: this.props.isUpdate? this.props.phoneInfo:{
                phoneId: "",
                newSSID: "",
                newBattery: "",
                newAssetTag: "",
                newUUID: "",
                teams: [],
                assignValue: "",
                model: "",
                newUsed: "",
                timestamp: "",
                newLabel: "",
                active: true,
                phoneNum: ""
            },
            formValidation:{
                assetTag:false,
                teams:false,
                model:false,
                assigned:false
            },
            isUpdate:this.props.isUpdate,
            statusUpdate:false,
            statusAdd:false,
            openDialog:false,
            openDialogDesc:'',
            dupPhone:false,
            tabValue:0,
            switchValue: this.props.isUpdate? this.props.phoneInfo.active: true,
            role:'',
            editUserAssigned: false,
            textmask: this.props.isUpdate? this.props.phoneInfo.phoneNum: '(   )    -    ',
            phoneNum: '',
            model: '',
            comment:'',
            phoneNumError:false,
            showSpinner: false
           }
        }
    phoneModelsList=[];
    currentModel=this.props.isUpdate? this.props.phoneInfo.model:'';
    currentRoles=this.props.isUpdate? this.props.phoneInfo.teams:[];
    duplicatePhone=false;

handleChangeTab = (newValue) => {
    this.setState({tabValue: newValue});
};

handleChangeUser=(event, value)=>{
    if(!!value){
        const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
        phoneInfoCloned['assignValue']=value.id;
        this.setState({phoneInfo:phoneInfoCloned});
    }
}
handleChangeRole = (role) => {
    if(!this.currentRoles.includes(role)){
        this.currentRoles.push(role);
    }else{
        let rolesCopy = this.currentRoles.filter(r=>r!== role);
        this.currentRoles = rolesCopy;
    }
    const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
    phoneInfoCloned['teams']=this.currentRoles;
    this.setState({phoneInfo:phoneInfoCloned});
};
handleClickChip=(model)=>{
    const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
    const modelId = this.props.phoneModels.filter(phone=> phone.model===model)[0].id
    phoneInfoCloned['model']=modelId;
    this.currentModel= modelId;
    this.setState({phoneInfo:phoneInfoCloned});
};
handleChangeSwitch = (event) => {
    this.setState({ switchValue: event.target.checked });
    const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
    phoneInfoCloned["active"] = event.target.checked;
    this.setState({phoneInfo:phoneInfoCloned});
};
handleChangePhoneNumber = e => {
    // const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
    // phoneInfoCloned['phoneNum']=value;
    // this.setState({phoneInfo:phoneInfoCloned, textmask: value});
    if(e.target.value.includes('_'))
      this.setState({textmask: e.target.value, phoneNumError: true});
    else
      this.setState({textmask: e.target.value, phoneNumError: false});
};

inputChange = (e) =>{
    const phoneInfoCloned=cloneDeep(this.state.phoneInfo);
    phoneInfoCloned[e.target.name]=this.inputChangeType(e.target.name,e);
    this.setState({phoneInfo:phoneInfoCloned});
}

inputChangeType =(typeOfInput,e)=>{
    return typeOfInput==="active"?e.target.checked:e.target.value; // checkbox uses checked, please name all components that uses checked attribute with active name
}
validateDupPhone= async(assetTag,phoneID,isUpdate)=>{
    await this.props.checkDuplicatePhone(assetTag).then(res=>{
        const {duplicate, id} = res;
        if (!duplicate) {
            this.duplicatePhone = false;
        }
        if (duplicate && !isUpdate) {
            this.duplicatePhone = true;
        }
        if (duplicate && isUpdate && id === phoneID) {
            this.duplicatePhone = false;
        }
        if (duplicate && isUpdate && id !== phoneID) {
            this.duplicatePhone = true;
        }
    }).catch(err=>{
        console.error("[CHECK DUPLICATES] Error, couldn't get info", err)
    });
}
handleValidateEmptyField=({newAssetTag})=>newAssetTag.trim();

handleModifiedBy = ()  => {
    const {user, userId} = this.props;
    let modifiedBy = '';
    if(user && user.id) {
        modifiedBy = user.id;
    } else if (userId) {
        modifiedBy = userId;
    }
    return modifiedBy;
}

handlePerformedBy = ()  => {
    const {user, userId} = this.props;
    let performedBy = '';
    if(user && user.name) {
        performedBy = user.name;
    } else if(user && user.id) {
        performedBy = user.id;
    } else if (userId) {
        performedBy = userId;
    }
    return performedBy;
  }

createPhoneLog = (data) =>{
    let modifiedBy = this.handleModifiedBy();
    let log={
        campusId: this.props.campusId,
        userId: modifiedBy,
        action: data.action,
        description: data.description,
        timestamp: new Date()
    };
    this.props.createLog(log);
};

handleSubmit = async () => {
    let validForm=false
    const {phoneInfo,isUpdate,textmask}=this.state;
    let phoneNumber = textmask.match(".*\\d.*")? textmask : '';
    const {campusId} =this.props;
    phoneInfo.newAssetTag=this.handleValidateEmptyField(phoneInfo);
    let assignToUser = phoneInfo.assignValue === EMPTY_USER_ID?'':phoneInfo.assignValue;
    let usedByUser = phoneInfo.newUsed!== ''? phoneInfo.newUsed : this.props.userId;
    let phone = {
        _id:phoneInfo.phoneId,
        assetTag: phoneInfo.newAssetTag.trim(),
        model: phoneInfo.model,
        UUID: phoneInfo.newAssetTag.trim(),
        teams: phoneInfo.teams,
        assign: assignToUser,
        usedBy: usedByUser,
        battery: phoneInfo.newBattery,
        SSID: phoneInfo.newSSID,
        label: phoneInfo.newLabel? phoneInfo.newLabel.trim():phoneInfo.newLabel,
        timestamp:phoneInfo.timestamp,
        active:phoneInfo.active,
        campusId:campusId,
        phoneNumber: phoneNumber
    }

    await this.validateDupPhone(phone.assetTag,phone._id,isUpdate);
    if(this.duplicatePhone){
        this.setState({dupPhone:true,openDialog:true,openDialogDesc:`The phone ${phone.UUID} already exists.`});
        return false;
    }

    validForm = this.validateForm(phone);
    if(!validForm){
        this.setState({showSpinner: true, isActionInProgress:true});
        let desc='';
        if(isUpdate) {
            let phoneUpdate = await this.updateForm(phone._id,phone);
            desc=`The phone ${phoneUpdate.data.UUID} was updated!`
        } else {
            delete phone._id;
            delete phone.id;
            delete phone.phone_user;
            this.addForm(phone);
            desc='The phone was added!'
        }
        this.handleCloseDialogConfirm(desc);
        //this.setState({openDialog:true,openDialogDesc:desc,dupPhone:false})
    }
}
updateForm=async (phoneId,phone)=>{
    let performedBy = this.handlePerformedBy();
    return new Promise(async(resolve,reject)=>{
        delete phone._id;
        delete phone.id;
        delete phone.phone_user;
        try{
            let updatePhone=await axios.patch(secureUrl.hostUrl+'/api/Phones/'+phoneId,phone); 
            resolve(updatePhone);
            this.setState({showSpinner: false, isActionInProgress:false});
            this.createPhoneLog({
                action:'Update Phone',
                description:`Phone "${phone.UUID}" updated by "${performedBy}"`});
        }catch(error){
            console.error("[ADDPHONE] An error ocurred in updateForm: ", error);
            this.createPhoneLog({
                action:'Update Phone Error',
                description:`Phone "${phoneId.UUID}" couldn't be updated by "${performedBy}" due endpoint ${error}`});
            this.setState({openDialogDesc:"Phone couldn't be updated,"+error, showSpinner: false, isActionInProgress:false});
        }
    })
}

addForm(phone){
    let performedBy = this.handlePerformedBy();
    try{
        axios.post(secureUrl.hostUrl+'/api/Phones',phone)
        .then(resp=>{
            if(resp.data.id.length>0){
                this.createPhoneLog({
                    action:'Create Phone',
                    description:`Phone "${phone.UUID}" created by "${performedBy}"`});
                //this.props.getPhones();
                let phones =this.props.phones;
                phones.push(resp.data);
                this.setState({showSpinner: false, isActionInProgress:false});
                this.resetForm();
            }
        })
    }catch(error){
        console.error("[ADDPHONE] An error ocurred in addForm: ", error);
        this.createPhoneLog({
            action:'Create Phone Error',
            description:`Phone "${phone.UUID}" couldn't be created by "${performedBy}" due endpoint ${error}`});
        this.setState({openDialogDesc:'We had problems to adding this phone,'+error, showSpinner: false, isActionInProgress:false});
    }
}

resetForm(){
    let formError={
        assetTag:false,
        model:false,
        teams:false
    };
    this.setState({open:false,
        isUpdate:true,
        newAssetTag: '',
        menuValue:'none',
        newUUID:'',
        assignValue: 'none',
        teams: [],
        newUsed:'',
        newBattery:0,
        newSSID:'',
        newLabel:'',
        timestamp:'',
        formValidation: formError
    });
}

validateForm=(form)=>{
    let formError={
        assetTag:{error: false, msg: ''},
        model:false,
        teams:false,
        assigned:false
    };
    let error=false;

    // if (form.assign === '') {
    //     formError.assigned = true;
    //     error = true;
    // }
    if (form.assetTag === '') {
        formError.assetTag.error = true;
        formError.assetTag.msg = 'Field cannot be empty!';
        error = true;
    }
    if (!this.spaceValidation(form.assetTag)) {
        formError.assetTag.error = true;
        formError.assetTag.msg = 'Incorrect format!';
        error = true;
    }
    if (form.model === '') {
        formError.model = true;
        error = true;
    }
    if (form.teams.length === 0) {
        formError.teams = true;
        error = true;
    }
    this.setState({formValidation: formError});
    return error;
};

spaceValidation=(input)=>{
    let valid = (input.split("").indexOf(" ")>-1)?false:true;
    return valid;
};

handleShowdeactivateTool=({active})=>{
    return (
        <React.Fragment>
            <FormLabel>Deactivate phone </FormLabel>
            <DeactivatePhone active={active} inputChange={this.inputChange}/>
        </React.Fragment>
    )
}
handleRenderPhoneModelChips=(switchValue)=>{
    let currentModelInfo = this.props.phoneModels.find(m => m.id === this.currentModel);
    return(
    <div>
        {this.phoneModelsList.map(model=>
        !!currentModelInfo && model === currentModelInfo.model?
        <Chip  disabled={!switchValue} icon={<DoneIcon style={{fill:'#2196F3'}}/>} style={{marginLeft:'4px', marginTop: '5px', backgroundColor:'#E3F2FD', color:'#2196F3'}} label={model} onClick={()=>this.handleClickChip(model)} />
        : <Chip disabled={!switchValue} style={{marginLeft:'4px', marginTop: '5px'}} label={model} onClick={()=>this.handleClickChip(model)} />
        )}
    </div>
    );
}
handleRenderTeams=(switchValue)=>{
    return(
        <div>
            <Grid container spacing={1}>
            {this.props.acls.sort().map(value=>
                this.currentRoles.includes(value._id)?
                <Grid item md={4}>
                <Chip  disabled={!switchValue} icon={<CheckBoxIcon style={{fill:'#2196F3'}}/>} style={{backgroundColor:'transparent', color:'#2196F3'}}  label={value.role} onClick={()=>this.handleChangeRole(value.id)} />
                </Grid>
                : <Grid item md={4}>
                <Chip disabled={!switchValue} icon={<CheckBoxOutlineBlankIcon/>} style={{backgroundColor:'transparent'}}  label={value.role} onClick={()=>this.handleChangeRole(value.id)} />
                </Grid>
            )}
            </Grid>
       </div>
    );
}

handleCloseDiaglogDup=()=>{
    this.setState({openDialog:false});
}

handleCloseDialogConfirm=(desc)=>{
    this.props.handleCloseDialog();
    this.props.handleOpenSnackbar(desc);
}

getRoleList=()=>{
    this.props.users.forEach(user=>{
       let nameLbl = user.first_name +" "+ user.last_name;
       this.usersList.push({name: nameLbl, id: user._id});
    });
}

getModelList=()=>{
    this.props.phoneModels.forEach(phone=>{
        if(!this.phoneModelsList.includes(phone.model) && phone.model!==undefined)
            this.phoneModelsList.push(phone.model)
    })
}

TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
    );
  }

componentDidMount(){
    if(this.props.isUpdate){
      this.props.getCommentsByPhone(this.props.phoneInfo.phoneId,this.props.token);
    }
}

handleSendComment=()=>{
    let commentInfo = {
        user_Id: this.props.userId,
        phone_Id: this.props.phoneInfo.phoneId,
        date: new Date(),
        comment: this.state.comment,
        team: this.props.phoneInfo.team,
    }
    this.props.sendComment(commentInfo);
    this.setState({comment: ''});
    this.props.getCommentsByPhone(this.props.phoneInfo.phoneId,this.props.token);
}

getUserInitials=(firstName,lastName)=>{
    const fName = firstName.trim();
    const lName = lastName.trim();
    let initials = fName.substring(0,1) + lName.substring(0,1);
    return initials;
}

handleCloseCommentsDialog=()=>{
    this.props.handleCloseDialog();
    this.props.cleanCommentsByPhoneList();
}

handleCommentOnChange=(value)=>{
    this.setState({comment: value});
}

render(){
    const {classes, statusOfDevice} = this.props;
    const {phoneInfo,isUpdate,openDialog,openDialogDesc,tabValue,switchValue,textmask,phoneNumError, formValidation} = this.state;
    let switchcolorLbl = switchValue? '#00C614': 'gray';
    this.getModelList();
    const initials = this.getUserInitials(this.props.userInfo.first_name,this.props.userInfo.last_name);
    const assignInfo = this.props.users.filter(user=>user._id === phoneInfo.assignValue || user.id === phoneInfo.assignValue) || {};
    let deviceOffline = isUpdate && statusOfDevice === 'Online' ? false : true;
    return(
      <div id="addPhone-container" style={{overflow:'auto'}}>
        <DialogContent>
          <div style={{display:'flex', marginBottom: '5px'}}>
              <div
                id="webNavv-tableView-fleetManager-newPhone-phoneInfoTab-1.0"
                className={tabValue === 0 ? classes.selectedTab : classes.Tab}
                onClick={()=>this.handleChangeTab(0)}>Info</div>
              <div
                id="webNavv-tableView-fleetManager-newPhone-phoneCommentsTab-1.0"
                className={tabValue === 1 ? classes.selectedTab : classes.Tab}
                onClick={()=>this.handleChangeTab(1)}>Comments</div> 
          </div>
          <Divider/>
         {tabValue === 0 && (
            <div id="webNavv-tableView-fleetManager-form-1.0">
                <PhoneForm
                handleChangeSwitch={this.handleChangeSwitch}
                inputChange={this.inputChange}
                TextMaskCustom={this.TextMaskCustom}
                handleChangePhoneNumber={this.handleChangePhoneNumber}
                handleChangeUser={this.handleChangeUser}
                handleRenderPhoneModelChips={this.handleRenderPhoneModelChips}
                handleRenderTeams={this.handleRenderTeams}
                isActionInProgress={this.state.isActionInProgress}
                handleCloseDialog={this.props.handleCloseDialog}
                handleSubmit={this.handleSubmit}
                users={this.props.users}
                isUpdate={isUpdate}
                assignInfo={assignInfo}
                textmask={textmask}
                phoneInfo={phoneInfo}
                switchValue={switchValue}
                phoneNumError={phoneNumError}
                switchcolorLbl={switchcolorLbl}
                formValidation={formValidation}
                deviceOffline={deviceOffline}
                disableSaveButton={this.props.disableSaveButton}
                />
            </div>
         )}
        {tabValue === 1 && isUpdate && (
            <div id="webNavv-tableView-fleetManager-comments-1.0">
                <PhoneComments
                handleSendComment={this.handleSendComment}
                handleCloseCommentsDialog={this.handleCloseCommentsDialog}
                comment={this.state.comment}
                initials={initials}
                userInfo={this.props.userInfo}
                handleCommentOnChange={this.handleCommentOnChange}
                phoneComments={this.props.phoneComments}
                users={this.props.users}
                getUserInitials={this.getUserInitials}
                />
            </div>
        )}
        <div id="webNavv-tableView-fleetManager-newPhone-spinner-1.0">
            <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open}/>
        </div>
        </DialogContent>
        <div id="webNavv-tableView-fleetManager-newPhone-alert-1.0">
            <SimpleModalProp
            handleCloseModal={this.handleCloseDiaglogDup}
            openModal={openDialog}
            icon={<img src={incorrect} className={classes.problemIcon} alt="error-modal-icon"/>}
            title={'Error!'}
            subtitle={openDialogDesc}
            handleOption={this.handleCloseDiaglogDup}
            buttonLabel={'Close'}
            />
        </div>
      </div>
    )
}
}

const styles = (theme) => ({
    containedBtn: {
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
    problemIcon: {
        marginLeft:'150px'
    },
    selectedTab: {
        margin: '0px 20px',
        cursor: 'pointer',
        color: lightBlue[800],
        width:'100px',
        textAlign:'center',
        fontWeight: 700,
        paddingBottom: '5px'
    },
    Tab: {
        margin: '0px 20px',
        cursor: 'pointer',
        color: 'gray',
        width:'100px',
        textAlign:'center',
        paddingBottom: '5px',
        "&:hover": {
           color: lightBlue[800],
        }
    }
});


const mapStateToProps = state => ({
    phones: state.phones.phones,
    phoneInfo: state.phones.phoneInfo,
    acls: state.acl.acls,
    campusId: state.auth.userInfo.campusId,
    userId: state.auth.userId,
    user: state.user.user,
    userInfo: state.auth.userInfo,
    token: state.auth.token,
    phoneComments: state.phones.phoneComments,
    users: state.user.users,
    phoneModels:state.modelPhones.models,
    open:state.views.drawerOpen
});

export default connect(mapStateToProps, {
    sendComment,
    getCommentsByPhone,
    cleanCommentsByPhoneList,
    createLog,
    checkDuplicatePhone
})(withStyles(styles)(AddPhone));