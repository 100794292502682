import moment from "moment";

export const JOINED_CHANNEL= 'joined';
export const LOGOUT_USER_CHANNEL="logoutUser";
export const GEOLOCATION_CHANNEL="geolocation-channel-susbcribe";
export const EXPIRE_USER_CHANNEL='expireSignal';
export const SECONDS='seconds';
export const TM_TAB='a';
export const TEXTFIELD_DATE_FORMAT="MM/dd/yyyy hh:mm";
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const DATE_FORMAT_SECONDS="MM/DD/YYYY HH:mm:ss";
export const TEXTFIELD_TYPE_DATETIME="datetime-local";
export const DEVICE_INPUT_LBL='Device';
export const DESKTOP_LBL= 'Desktop';
export const SNACKBAR_SUCCESS='success';
export const SNACKBAR_WARINING='warning';
export const UNKNOWN_DEVICE_MSG='User with unknown device can not be expired.!';
export const YES='Yes';
export const NO='No';
export const MAP='map';
export const TRANSPORTER='transporter';
export const STATUS= 'status';
export const START_DATE='startDate';
export const FINISH_DATE='finishDate';
export const CUSTOM_FILTER_TYPE='custom';
export const FILTERTYPE= "dropdown";
export const RESPONSIVE="standard";
export const DEFAULT_SORT='asc';
export const CSV_FILE_NAME=`TeamManager.csv`;
export const CSV_SEPARATOR= ';';
export const SPINNER_CONFIG='default';
export const ALERT_VARIANT='filled';
export const TABLE_TITLE='Team Manager';
export const TABLE_ID="TM";
export const DEFAULT_ELEMENTS=["default","hidden"];
export const MILISECONDS='miliseconds';
export const IDLE='idle';
export const ROWS_PER_PAGE=[10, 30, 50, 100];
export const DEVICE_COLOR_CODE={
  inDB: 'inDB',
  notInDB: 'notInDB',
  notInCurrentCampus: 'notInCurrentCampus'
}
export const USER_PROPERTIES={
  lastLocation:"lastLocation",
  geolocation:"geolocation",
  lastLogout:"lastLogout"
}
export const CHAT_BTN_LBLS={
  expire: 'Expire User',
  unknown: 'Unknown Device',
  otherCampus: 'Device assigned to other campus'
};
export const CHAT={
  Online:{
    label: 'Online',
    color: "#3FA33E",
    value: 1
  },
  Inactive:{
    label:'Inactive',
    color: 'orange',
    value: 2
  },
  Offline:{
    label:'Offline',
    color: 'red',
    value: 3
  }
};
export const DEVICE_TYPE={
  phone: 'phone',
  desktop: 'desktop'
};
export const WORKER_LBL={
  find: 'Find User',
  last: 'Last Location'
};
export const STRING_FIELD_TYPE={
  worker:{
    id: 'worker',
    label: 'Worker'
  },
  role:{
    id:'role',
    label:'Team'
  },
  device:{
    id: 'device',
    label: 'Device'
  }
};
export const COLUMNS={
  // num: {
  //   no:0,
  //   id:"num",
  //   label:"#"
  // },
  findWorker: {
    no:0,
    id:"findWorker",
    label:"Find"
  },
  worker:{
    no:1,
    id:'worker',
    label:'Worker'
  },
  userId:{
    no:2,
    id:'userId',
    label:'User ID'
  },
  email:{
    no:3,
    id:'email',
    label:'Email'
  },
  role:{
    no:4,
    id:'role',
    label:'Team'
  },
  status:{
    no:5,
    id:'status',
    label:'Chat Status',
    filter:[CHAT.Online.label, CHAT.Inactive.label, CHAT.Offline.label]
  },
  location:{
    no:6,
    id:'location',
    label:'Location Streaming',
    filter:[YES, NO]
  },
  device:{
    no:7,
    id:'device',
    label:'Device'
  },
  lastlogin:{
    no:8,
    id:'lastlogin',
    label:'Last Login'
  },
  lastlogout:{
    no:9,
    id:'lastlogout',
    label:'Last Logout'
  },
  duration:{
    no:10,
    id:'duration',
    label:'Duration'
  }
};
export const TABLE_EVENTS={
  changeRowsPerPage: 'changeRowsPerPage',
  propsUpdate:'propsUpdate',
  search: 'search',
  filterChange:'filterChange',
  onSearchClose: 'onSearchClose',
  resetFilters:'resetFilters',
  viewColumnsChange:'viewColumnsChange',
  changePage:'changePage',
  onFilterDialogOpen: 'onFilterDialogOpen',
  onFilterDialogClose: 'onFilterDialogClose'
};

export const DATE_DIFFERENCE = (lastLIn, lastLOut) => {
  const LIn = moment(lastLIn);
  const LOut = moment(lastLOut);
  const CDate = moment(moment().format());

  const miliseconds = lastLIn > lastLOut ? moment.duration(CDate.diff(LIn)) : moment.duration(LOut.diff(LIn));
  const label = miliseconds.humanize();

  return {difference: label, sortDifference: miliseconds};
};

export const TABLE_SORT = (data, colIndex, sortType) => {
  if (colIndex === 1 || colIndex === 3 || colIndex === 4) {
    return data.sort((a, b) => {
      return (a.data[colIndex].toLowerCase() < b.data[colIndex].toLowerCase() ? 1 : -1) * (sortType ? -1 : 1);
    });
  } else if (colIndex === 7) {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (sortType ? 1 : -1);
    });
  } else if (colIndex === 5) {
    return data.sort((a, b) => {
      let statusA = 0;
      let statusB = 0;
      if (a.data[colIndex][0] === CHAT.Online.label)
        statusA = 0;
      else if (a.data[colIndex][0] === CHAT.Inactive.label)
        statusA = 1;
      else if (a.data[colIndex][0] === CHAT.Offline.label)
        statusA = 2;
      if (b.data[colIndex][0] === CHAT.Online.label)
        statusB = 0;
      else if (b.data[colIndex][0] === CHAT.Inactive.label)
        statusB = 1;
      else if (b.data[colIndex][0] === CHAT.Offline.label)
        statusB = 2;
      return (statusA < statusB ? -1 : 1) * (sortType ? 1 : -1);
    });
  } else if (colIndex === 10) {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (sortType ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex][1] < b.data[colIndex][1] ? -1 : 1) * (sortType ? 1 : -1);
    });
  }
};

export const CUSTOM_CSVDOWNLOAD = (buildHead, buildBody, columns, data) => {
    let csvBody = [];
    let finalColumns = [];
    let statusIndex = -1;
    let durationIndex =-1;
    let loginIndex=-1;
    let logoutIndex=-1;
    // let numIndex=-1;
    let findIndex=-1;
    columns.map((column, i)=>{
      // if(column.name===COLUMNS.num.id){
      //   numIndex=i;
      // }
      if(column.name===COLUMNS.findWorker.id){
        findIndex=i;
      }
      if(column.name===COLUMNS.status.id){
        statusIndex=i;
      }
      if(column.name===COLUMNS.duration.id){
        durationIndex=i;
      }
      if(column.name===COLUMNS.lastlogin.id){
        loginIndex=i;
      }
      if(column.name===COLUMNS.lastlogout.id){
        logoutIndex=i;
      }
      if(column.name!==COLUMNS.findWorker.id){
        finalColumns.push(column);
      }
    });
    data.map(p => {
      let csvData = [];
      for (let i = 0; i <=finalColumns.length; i++) {
        if (i === statusIndex || i === durationIndex){
          csvData.push(p.data[i][0]);
        } else if (i === loginIndex|| i === logoutIndex){
          csvData.push(p.data[i][1]);
        } else if(i !== findIndex){
          csvData.push(p.data[i]);
        }
        // else if(i !== numIndex){
        //   csvData.push(p.data[i]);
        // }
      }
      csvBody.push({ data: csvData });
    });
    return `${buildHead(finalColumns)}${buildBody(csvBody)}`.trim();
};

export const TRACK_USER_BUTTON = (online, device_id) => {
  if (online && device_id !== DEVICE_TYPE.desktop && device_id !== undefined){
    return true;
  }else{
    return false;
  }
};

export const WORKER_STATUS = (status, online) => {
  if (status === IDLE && online){
    return CHAT.Online.label;
  }else if (!online){
    return CHAT.Offline.label;
  }else{
    return CHAT.Inactive.label;
  }
};

export const WORKER_TYPE = (worker) => {
  if (worker.device_id !== DEVICE_TYPE.desktop && worker.device_id !== undefined){
    return true;
  }else{
    return false;
  }
};

const columnLabels = {
  1: COLUMNS.worker.id,
  2: COLUMNS.userId.id,
  3: COLUMNS.email.id,
  4: COLUMNS.role.id,
  6: COLUMNS.location.id,
  7: COLUMNS.device.id,
  8: COLUMNS.lastlogin.id,
  9: COLUMNS.lastlogout.id,
  10: COLUMNS.duration.id
};

export const TABLE_FILTER_REFRESH = (filterBy, data)=>{
  let filtered = [];
  let finalFilter = [];
  let dataList = {};

  filterBy.map((d, i)=>{
    if (d.length !== 0 && i > 0) {
      switch (i) {
        case COLUMNS.status.no:
          dataList[i] = data.filter(row=> row.status[0] === d[0]);
          break
        case COLUMNS.lastlogin.no:
          dataList[i] = data.filter(row=> moment(row.lastlogin[1]).format(DATE_FORMAT) === moment(d[0]).format(DATE_FORMAT));
          break
        case COLUMNS.lastlogout.no:
          dataList[i] = data.filter(row=>moment(row.lastlogout[1]).format(DATE_FORMAT) === moment(d[0]).format(DATE_FORMAT));
          break
        default:
          dataList[i] = data.filter(row=> row[columnLabels[i]] === d[0]);
          break
      }
    }
  });
  for (let i = 0; i < 9; i++) {
    if (dataList[i] !== undefined){
      filtered = filtered.concat(dataList[i]);
    }
  }
  if (filtered.length > 1) {
    let duplicates = filtered.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0){
        acc.push(el);
      }
      return acc;
    }, []);
    finalFilter = duplicates.length === 0? filtered : duplicates;
  } else {
    finalFilter = filtered;
  }

  return finalFilter;
};