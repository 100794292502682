import {combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import jobsReducer from './jobsReducer';
import directionsReducer from './directionsReducer';
import viewsReducer from './viewsReducer';
import aclReducer from './aclReducer';
import phoneReducer from './phoneReducer';
import contactReducer from './contactReducer';
import contactEventReducer from './contactEventReducer';
import contactUserListReducer from './contactUserListReducer';
import equipmentReducer from './equipmentReducer';
import modelPhoneReducer from './modelPhoneReducer';
import campusReducer from './campusReducer';
import superAdminReducer from './superAdminReducer';
import logsReducer from './logsReducer';
import lastlocation from './lastlocationReducer';
export default combineReducers({
  auth: authReducer,
  user: userReducer,
  jobs: jobsReducer,
  directions: directionsReducer,
  views: viewsReducer,
  acl: aclReducer,
  phones: phoneReducer,
  contact: contactReducer,
  contactEvent: contactEventReducer,
  contactUserList: contactUserListReducer,
  equipments: equipmentReducer,
  modelPhones: modelPhoneReducer,
  campus: campusReducer,
  superAdmin: superAdminReducer,
  logs: logsReducer,
  lastlocation: lastlocation
});
