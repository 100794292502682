export const EMPTY_STRING = '';
export const expire_time_secs = 43200;
export const EXPIRE_DATE="expire_date";
export const EXPIRE_TIME="expire_time";
export const expire_time_hours=12;


export const View = {
    MAP : 'map',
    HISTORY: 'history',
    TABLE: 'table',
    CONTACT: 'contact',
    CONTACTS: 'Contacts',
    DIRECT_CONTACT: 'direct_contact',
    SETTINGS: 'settings',
    MAIN_SETTINGS: 'mainSettings',
    LOGS: 'logs',
    ADMIN: 'admin',
    USER: 'user',
    USERS: 'users',
    TRANSPORTER: 'transporter',
    ACCESS_CONTROL: 'accessControl',
    EQUIPMENT: 'equipment',
    DETAILS_CONTACT: 'details_contact',
    FLEET_MANAGER: 'fleetManager',
    TEAM_MANAGER: 'teamManager',
    WAYFINDING: 'wayfinding',
    EQUIPMENT_HISTORY: 'equipmentHistory',
    WORKFLOW_AUTOMATION: "workflowAutomation",
    JOBS_BOARD: "jobsBoard",
    JOBS_HISTORY: "jobsHistory"
};

export const LoggedItem = {
    USER_ID: 'userId',
    USER_TOCKEN: 'userToken',
    USER_INFO: 'userInfo',
};

export const MapValues = {
    MAP: 'map',
    BLOCK: 'block',
    NONE: 'none',
};

export const Tabs = {
    TAB_A: 'a',
    TAB_B: 'b',
    TAB_C: 'c',
};


export const MenuValues = {
    AC: 'AC',
    TM: 'TM',
    FM: 'FM',
    HT: 'HT',
    EQ: 'EQ',
    WF: 'WF'
};