export const TIMEOFFSET = -1;
export const TIMEZONE = "-04:00";
export const specimenTrackingBoard = "Specimen Retrieval";
export const MULTIPLE_ERRORS = "Multiple Errors";

export const STATUS_STYLES = {
    inProgress:{
        label: "In Progress",
        backgroundColor: "#E9F5FE",
        color: "#2196F3"
    },
    inProgressWithError:{
        label: "In Progress with Error",
        backgroundColor: "#F4D7D6",
        color: "#CE4947"
    },
    unassigned:{
        label: "Not Started",
        backgroundColor: "#F1F1F1",
        color: "#757575"
    },
    assigned:{
        label: "Not Started",
        backgroundColor: "#F1F1F1",
        color: "#757575"
    },
    complete:{
        label: "Complete",
        backgroundColor: "#ECF4EA",
        color: "#458E2D"
    },
    completeWithError:{
        label: "Complete with Error",
        backgroundColor: "#F4D7D6",
        color: "#CE4947"
    },
    error:{
        label: "Error",
        backgroundColor: "#F4D7D6",
        color: "#CE4947"
    }
};

export const ERROR_TASKS= {
    jobCancelled:{
        key: 'job_cancelled',
        description: 'Job Cancelled'
    },
    jobStartDelay:{
        key: 'job_delayed',
        description: 'Job Start Delayed'
    },
    locationSkipped:{
        key: 'skip_location',
        description: 'Location Skipped'
    },
    scanOverride:{
        key: 'scan_override',
        description: 'Location Scan Override'
    },
    scanError:{
        key: 'failed_scan',
        description: 'Scan Failed'
    }
}

export const jobStatusTypesGraphQL = {
    inProgress: "In-Progress",
    inProgressWithError: "In-Progress-Errored",
    complete: "Complete",
    completeWithError: "Complete-Errored",
    unassigned: "Unassigned",
    assigned: "Assigned",
    error: "Error"
}
  
export const taskStatusTypesGraphQL = {
    inProgress: "In Process",
    complete: "Complete",
    unstarted: "Unstarted",
    error: "Error",
}

export const JOBS_BOARD_TITLE = "Jobs Board";
export const JOB_ROUTE_TITLE = "Jobs";
export const JOB_ROUTE_SUBTITLE = "WorkFlow Automation";
export const MODAL_TITLE = "Are you sure?";
export const REMOVE_JOB_ROW = [{ 
    id: 0,
    title: "Remove Jobs",
    assignedId: "",
    checked: false,
    template: true,
    assigned: false,
    canEdit: false,
    status: 'actions',
    resource_slotid: ""
}];

export const JOB_TEMPLATES_ROW = [{ 
    id: 0,
    title: "Job Templates",
    assignedId: "",
    checked: false,
    template: true,
    assigned: false,
    canEdit: false,
    status: 'templates',
    resource_slotid: ""
}];

export const EDIT_LABEL = "Drag jobs between rows";
export const REMOVE_LABEL = "Drag jobs here to remove";
export const ACTION_STATUS = {
    inProgress:{
        id : "inProgress",
        label: "Saving ..."
    },
    done:{
        id: "done",
        label: "Changes saved"
    },
    error:{
        id: "error",
        label: "Couldn't save changes"
    }
}

export const ERROR_CANCEL_JOB = "47c1b9f9-d2d1-4701-b340-e768ec14b1d2";
export const SPECIMEN_COLLECTION_TYPE = "68001710-44d0-4ec5-bed0-9393fb81130c";