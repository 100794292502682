/*
See LICENSE folder for this sample’s licensing information.

Abstract:
A level picker control that lets users choose the floor to display on the map.
*/
"use strict";
import * as CONSTS from './IMDF.constants';
  export class LevelPicker{
    constructor(archive,onSelectedOrdinal){
        this.archive=archive;
        this.onSelectedOrdinal=onSelectedOrdinal;
        this.element = document.createElement(CONSTS.UL);
        this.element.id = CONSTS.PREVIEW_LEVELPICKER;
        this.element.style.display=CONSTS.NONE;
        this.currentOrdinal = undefined;
        let mapContainer = document.getElementById(CONSTS.PREVIEW_CONTAINER);
        if(document.getElementById(CONSTS.PREVIEW_CONTAINER)){
            mapContainer.append(this.element);
            this.levelNames = [];
            this.shortNameByOrdinal = {};
            this.loadLevelPicker();
            this.toggleShow = false;
        }
    }

    /* Multiple levels may be related to the same ordinal and are represented by one level picker entry.
     * This sample code uses one short name and full name for each ordinal for simplicity.
     */

    loadLevelPicker(){
        // console.log("this.archive--->",this.archive)
        this.archive.featuresByType[CONSTS.LEVEL].forEach((level)=> {
            var ordinal = level.properties.ordinal;
            if ( !(ordinal in this.shortNameByOrdinal) ) {
                // This example chooses to display the short name in the level picker.
                var shortName = (level.properties.short_name || {}).en || ordinal.toString();
                if (shortName) {
                    var fullName = (level.properties.name || {}).en || shortName;
                    this.levelNames.push({ ordinal: ordinal, fullName: fullName, shortName: shortName });
                    this.shortNameByOrdinal[ordinal] = true;
                }
            }
        });
        /* Sort the levels in the picker so that the top floor appears at the top of the picker,
        * and the lowest floor at the bottom.
        */
        this.levelNames.sort((a, b)=> {
            if (a.ordinal < b.ordinal) return 1;
            if (a.ordinal > b.ordinal) return -1;
            return 0;
        });

        var label = document.createElement(CONSTS.LI);
            label.textContent = "";
            label.classList.add(CONSTS.FLOOR_SELECTED);
            // levelElement.style.borderLeft= "7px solid" + floorColor;
            label.style.display=CONSTS.NONE;
            this.element.appendChild(label);
            var lbl = this;
            label.addEventListener(CONSTS.CLICK, function(e) {
                lbl.showList();
            });

        this.levelNames.map((levelName,i)=> {
            var levelElement = document.createElement(CONSTS.LI);
            levelElement.textContent = levelName.shortName;
            levelElement.id= `preview-${levelName.ordinal}`;
            levelElement.classList.add(CONSTS.LEVEL_ORDINAL + levelName.ordinal);
            this.element.appendChild(levelElement);
            var self = this;
            levelElement.addEventListener(CONSTS.CLICK, function(e) {
                self.selectOrdinal(levelName.ordinal);
            });
        }, this);
    }

    selectOrdinal = (ordinal)=> {
        this.onSelectedOrdinal(ordinal, this.currentOrdinal);
        this.currentOrdinal = ordinal;
        var selected = this.element.querySelector(CONSTS.CLASS_SELECTED)
        if (selected){
            selected.classList.remove(CONSTS.SELECTED);
        }
        if(this.element.querySelector(CONSTS.LEVEL_ORDINAL_SELECTED + ordinal)){
          this.element.querySelector(CONSTS.LEVEL_ORDINAL_SELECTED + ordinal).classList.add(CONSTS.SELECTED);
          let currentLevel = this.element.querySelector(CONSTS.LEVEL_ORDINAL_SELECTED + ordinal);
          let style = getComputedStyle(currentLevel);
          currentLevel.style.backgroundColor= style.borderLeftColor;
        }
    };
}
