import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const generatePDF = (table, tickets) => {
  const doc = new jsPDF();
  let tableColumn = [];
  const tableRows = [];

  switch(table){
    case 'TM':
      teamManager(doc, tableColumn, tableRows, tickets);
      break;
    case 'FM':
      fleetManager(doc, tableColumn, tableRows, tickets);
      break;
    case 'HT':
      history(doc, tableColumn, tableRows, tickets);
      break;
    case 'UT':
      users(doc, tableColumn, tableRows, tickets);
      break;
    case 'EQ':
      equipment(doc, tableColumn, tableRows, tickets);
      break;
    case 'JH':
      jobsHistory(doc, tableColumn, tableRows, tickets);
      break;
    default:
      break;
  }

  doc.setProperties({title: 'Print Report'});
  doc.autoPrint();

  const hiddFrame = document.createElement('iframe');
  hiddFrame.style.position = 'fixed';
  hiddFrame.style.width = '1px';
  hiddFrame.style.height = '1px';
  hiddFrame.style.opacity = '0.01';
  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  if (isSafari) {
    hiddFrame.onload = () => {
      try {
        hiddFrame.contentWindow.document.execCommand('print', false, null);
      } catch (e) {
        hiddFrame.contentWindow.print();
      }
    };
  }
  hiddFrame.src = doc.output('bloburl');
  document.body.appendChild(hiddFrame);
};

export default generatePDF;

const equipment = (doc, tableColumn, tableRows, tickets) =>{
  tableColumn = [{
    equipment: "Equipment",
    beaconNum: "Beacon Num",
    major: "Major",
    minor: "Minor",
    controlNum: "Control Num",
    team: "Team",
    model: "Model",
    maker: "Maker",
    department: "Department"
  }];

  tickets.map(row=>{
    let rowInfo = {
      equipment: row.equipment,
      beaconNum: row.beaconNum,
      major: row.major,
      minor: row.minor,
      controlNum: row.controlNum,
      team: row.team,
      model: row.model,
      maker: row.maker,
      department: row.department
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('Equipment Table Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 30, fontSize: 9},
      1: {cellWidth: 20, fontSize: 9},
      2: {cellWidth: 15, halign: 'center', fontSize: 9},
      3: {cellWidth: 15, halign: 'center', fontSize: 9},
      4: {cellWidth: 20, halign: 'center', fontSize: 9},
      5: {cellWidth: 25, halign: 'center', fontSize: 9},
      6: {cellWidth: 15, halign: 'center', fontSize: 9},
      7: {cellWidth: 25, halign: 'center', fontSize: 9},
      8: {cellWidth: 20, halign: 'center', fontSize: 9}
    }});
};

const users = (doc, tableColumn, tableRows, tickets) =>{
  tableColumn = [{
    no: "No",
    name: "Name",
    email: "Email",
    role: "Role",
    lastlogin: "Last Login"}];

  let i = 1;
  tickets.map(row=>{
    let rowInfo = {
      no: i++,
      name: row.name,
      email: row.email,
      role: row.role,
      lastlogin: row.lastlogin[0]
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('Users Table Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 10, halign: 'center', fontSize: 9},
      1: {cellWidth: 50, fontSize: 9},
      2: {cellWidth: 55, fontSize: 9},
      3: {cellWidth: 35, halign: 'center', fontSize: 9},
      4: {cellWidth: 30, halign: 'center', fontSize: 9}
    }});
  return [doc, tableColumn, tableRows];
};

const history = (doc, tableColumn, tableRows, tickets) =>{
  tableColumn = [{
    no: "No",
    distance: "Distance",
    worker: "Worker",
    duration: "Duration",
    device: "Device",
    date: "Date",
    jobstart: "Job Start",
    jobend: "Job End"
  }];

  let i = 1;
  tickets.map(row=>{
    let rowInfo = {
      no: i++,
      distance: parseFloat(row.distance).toFixed(3),
      worker: row.worker,
      duration: row.duration.humanize(),
      device: row.deviceID,
      date: row.date[0],
      jobstart: row.jobStart[0],
      jobend: row.jobEnd[0]
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('History Table Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 10, halign: 'center', fontSize: 9},
      1: {cellWidth: 25, halign: 'center', fontSize: 9},
      2: {cellWidth: 30, fontSize: 9},
      3: {cellWidth: 25, halign: 'center', fontSize: 9},
      4: {cellWidth: 25, halign: 'center', fontSize: 9},
      5: {cellWidth: 20, halign: 'center', fontSize: 9},
      6: {cellWidth: 25, halign: 'center', fontSize: 9},
      7: {cellWidth: 25, halign: 'center', fontSize: 9},
    }});
};

const fleetManager = (doc, tableColumn, tableRows, tickets) =>{
  tableColumn = [{
    no: "No",
    uuid: "UUID",
    // teams: "Team",
    team: "User's Team",
    usedby: "Used By",
    battery: "Battery",
    label: "Label",
    lastlogin: "Last Login",
    lastlogout: "Last Logout",
    model: "Model"}];

  let i = 1;
  tickets.map(row=>{
    let rowInfo = {
      no: i++,
      uuid: row.UUID,
      // teams: row.teams,
      team: row.team.role,
      usedby: row.usedBy,
      battery: Math.abs(parseInt(row.battery,10)),
      label: row.label,
      lastlogin: moment(row.last_login).format("MM/DD/YYYY HH:mm"),
      lastlogout: moment(row.last_logout).format("MM/DD/YYYY HH:mm"),
      model: row.model
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('Fleet Manager Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 10, halign: 'center', fontSize: 8},
      1: {cellWidth: 15, halign: 'center', fontSize: 8},
      // 2: {cellWidth: 20, halign: 'center', fontSize: 8},
      2: {cellWidth: 20, halign: 'center', fontSize: 8},
      3: {cellWidth: 25, fontSize: 8},
      4: {cellWidth: 17, halign: 'center', fontSize: 8},
      5: {cellWidth: 15, halign: 'center', fontSize: 8},
      6: {cellWidth: 20, halign: 'center', fontSize: 8},
      7: {cellWidth: 20, halign: 'center', fontSize: 8},
      9: {cellWidth: 20, halign: 'center', fontSize: 8},
    }});
};

const teamManager = (doc, tableColumn, tableRows, tickets) => {
  tableColumn = [{
    no: "No",
    worker: "Worker",
    role: "Role",
    status: "Status",
    location: "Location",
    lastlogin: "Last Login",
    lastlogout: "Last Logout",
    duration: "Duration",
    device: "Device"}];

  let i = 1;
  tickets.map(row=>{
    let rowInfo = {
      no: i++,
      worker: row.worker,
      role: row.role,
      status: row.status[0],
      location: row.location,
      lastlogin: row.lastlogin[0],
      lastlogout: row.lastlogout[0],
      duration: row.duration[0],
      device: row.device
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('Team Manager Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 10, halign: 'center', fontSize: 8},
      1: {cellWidth: 25, fontSize: 8},
      2: {cellWidth: 20, halign: 'center', fontSize: 8},
      3: {cellWidth: 20, halign: 'center', fontSize: 8},
      4: {cellWidth: 20, halign: 'center', fontSize: 8},
      5: {cellWidth: 20, halign: 'center', fontSize: 8},
      6: {cellWidth: 20, halign: 'center', fontSize: 8},
      7: {cellWidth: 30, halign: 'center', fontSize: 8},
      8: {cellWidth: 20, halign: 'center', fontSize: 8}}
  });
};

const jobsHistory = (doc, tableColumn, tableRows, tickets) =>{
  tableColumn = [{
    jobName: "Job Name",
    jobNumber: "Job Number",
    locationName: "Location Name",
    timestamp: "Timestamp",
    visited: "Visited",
    employee: "Employee",
    error: "Error",
    date: "Date"
  }];

  tickets.map(row=>{
    let rowInfo = {
      jobName: row.jobName,
      jobNumber: row.jobNumber,
      locationName: row.locationName,
      timestamp: row.timestamp,
      visited: row.visited,
      employee: row.employee,
      error: row.error,
      date: row.date
    };
    tableRows.push(rowInfo);
    return null;
  });

  doc.text('Job History Report', 14, 12);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 20,
    didDrawPage: function(data) {
      let str = "Page " + doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left + 80, doc.internal.pageSize.height - 5);
    },
    headStyles: { halign: 'center'},
    columnStyles: {
      0: {cellWidth: 20, fontSize: 8},
      1: {cellWidth: 25, fontSize: 8},
      2: {cellWidth: 25, fontSize: 8, fontSize: 8},
      3: {cellWidth: 25, halign: 'center', fontSize: 8},
      4: {cellWidth: 15, halign: 'center', fontSize: 8},
      5: {cellWidth: 30, fontSize: 8},
      6: {cellWidth: 25, fontSize: 8},
      7: {cellWidth: 25, halign: 'center', fontSize: 8}
    }});
};