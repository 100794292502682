export const EMPTY_VALUE= "";
export const CAMPUS_LIST = {
    selectTitle: 'SELECT HOSPITAL',
    panelTitle: 'Super Admin Panel',
    navvTrackTitle: 'NavvTrack',
    panelPath: '/superPanel',
};
export const ADMIN_PANEL = {
    appTitle: "Navtrack",
    panelTitle: "ADMIN PANEL",
    emailTitle: "Email",
    loginTitle: "Login",
    passwordTitle: "Password",
    orTitle: "OR",
    selectPath: "/superAdminSelect",
};