import { LOGIN, LOGOUT, ERROR, GET_ACL, TOKEN, ADMIN_LOGOUT, UPDATE_USERINFO,LOAD_CONFIG, SET_FEATURE_FLAGS, LASTLOCATION_CONN } from '../actions/auth/types';
import  {expire_time_secs,EXPIRE_DATE} from '../components/dashboard/dashboard.constants'
const USER_ID_KEY = "userId";
const USER_TOKEN_KEY = "userToken";
const USER_INFO = "userInfo";
const initialState = {
    token: '',
    userId: '',
    acl: {},
    userInfo: '',
    loginError: false,
    tokenMK: '',
    socketChat: '',
    socketLastLocation: '',
    pushNotificationToken: '',
    config:'',
    featureFlags: {
        "SpecimenCollection" : {
            "JobsBoard": false,
            "JobsHistory": false,
            "Permission": false,
            "AddJobs": false,
            "ExportTraces": false
        },
        "Equipment" : {
            "Permission": false,
            "MapFilter": false,
            "TableViewTable": false,
            "AdminTable": false,
            "EquipmentCategories": false
        },
        "Chat" : {
            "ChatWindowChime": false,
        },
        "Superadmin" : {
            "Dashboard": false,
            "Statistics": false,
            "Users": false,
            "DebugTool": false,
            "Logs": false
        },
        "Version": ''
    }
};

const SUPER_ADMIN_TOKEN = "adminToken";
const SUPER_ADMIN_INFO = "adminInfo";

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem(USER_ID_KEY, action.payload.userId);
            localStorage.setItem(USER_TOKEN_KEY, action.payload.token);
            localStorage.setItem(USER_INFO, JSON.stringify(action.payload.userInfo));
            return {
                ...state,
                token: action.payload.token,
                userId: action.payload.userId,
                userInfo: action.payload.userInfo,
                socketChat: action.payload.socketChat,
                pushNotificationToken: action.payload.pushNotificationToken,
                acl: action.payload.acl
            };
        case GET_ACL:
            return {
                ...state,
                acl: action.payload
            }
        case LOGOUT:
            localStorage.removeItem(USER_ID_KEY);
            localStorage.removeItem(USER_TOKEN_KEY);
            localStorage.removeItem(USER_INFO);
            if (!localStorage.hasOwnProperty(SUPER_ADMIN_INFO)) {
                localStorage.clear();
                sessionStorage.clear();
            }
            
            return initialState;
        case ADMIN_LOGOUT:
            //localStorage.removeItem(SUPER_ADMIN_TOKEN);
            //localStorage.removeItem(SUPER_ADMIN_INFO);
            localStorage.clear();
            sessionStorage.clear();
            return initialState;
        case ERROR:
            return {
                ...state,
                loginError: action.payload,
            };
        case TOKEN:
            return {
                ...state,
                tokenMK: action.payload.tokenMK
            }
        case UPDATE_USERINFO:
            return {
                ...state,
                userInfo: action.payload
            }
        case LOAD_CONFIG:
            return {
                ...state,
                config: action.payload
            }  
        case SET_FEATURE_FLAGS:
            return {
                ...state,
                featureFlags: action.payload
            }   
        case LASTLOCATION_CONN:
            return{
                ...state,
                socketLastLocation: action.payload.socketLastLocation,
            }    
        default:
            return state;
    }
}
