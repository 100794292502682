import moment from "moment";
import {exportCSVFile} from "../../../utilities/exportCSV";
import {jobStatusTypesGraphQL, ERROR_TASKS} from "../../jobsBoard/jobsBoard.constants";
import { COMPLETE_WITH_ERROR } from "./jobsHistory.constants";

export const handleTableSearch = (search, tableData) =>{
    const lowerCaseValue = search.toLowerCase();

    let filtered = tableData.filter(item => {
      if (item.jobName.toLowerCase().includes(lowerCaseValue)
        || item.messenger.toLowerCase().includes(lowerCaseValue)
        || item.status.label.toLowerCase().includes(lowerCaseValue)) {
          return item;
      } else {
        return null;
      }
    });

    return filtered;
}

const setJobStatusLabel = (status) =>{
  let jobStatus = jobStatusTypesGraphQL.complete;

  if (status === jobStatusTypesGraphQL.completeWithError) {
    jobStatus = COMPLETE_WITH_ERROR;
  }
  else if (status === jobStatusTypesGraphQL.error){
    jobStatus = jobStatusTypesGraphQL.error;
  }

  return jobStatus;
}

export const handleFormatTableData = (jobs) =>{
    let data = [];
    const sortByStartTime = sortTableDataByAttribute(jobs, "job_start_date");
    const sortByEndTime = sortTableDataByAttribute(sortByStartTime, "job_end_date");

    sortByEndTime.forEach(job=>{
      if (job.job_status === jobStatusTypesGraphQL.complete  || job.job_status === jobStatusTypesGraphQL.completeWithError || job.job_error_number === ERROR_TASKS.jobCancelled.key || job.job_errors.includes(ERROR_TASKS.jobCancelled.key)) {
        const startDate = moment(job.job_started_date);
        const endDate = moment(job.job_ended_date);
        const jobDuration = moment.duration(startDate.diff(endDate));
        const jobStatus = setJobStatusLabel(job.job_status);
  
        const item = {
          jobName: job.job_name,
          messenger: job.job_assigned_user_name,
          date: job.job_start_date,
          startTime: job.job_started_date,
          endTime: job.job_ended_date,
          duration: jobDuration,
          status: {status: job.job_status, label: jobStatus},
          jobId: job.jobid,
          jobSessionId: job.job_mobile_sessionid,
          errorNumber: job.job_error_number,
          unstarted_taskid: job.unstarted_taskid,
          jobNumber: job.job_number,
          job_errors: !!job.job_errors ? job.job_errors : []
        }
        data.push(item);
      }
    });
    return data;
}

const sortTableDataByAttribute = (data, attribute) =>{
  return data.sort((a, b) => {
    return a[attribute] < b[attribute] ? 1 : -1;
  });
}

export const CSV_CONFIGS={
  fileName:'JobHistory',
  defaultName:'JobHistory.csv',
  extension:'.csv',
  blobType:'text/csv;charset=utf-8;'
};

export const handleExportTable = ( jobs, currentDate) => {
    let itemsCopy = [];
    jobs.map(item => {
      itemsCopy.push({
        jobName: item.jobName,
        messenger: item.messenger,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        duration: item.duration,
        status: item.status.label,
        jobId: item.jobid,
        jobNumber: item.job_number
      });
      return null;
    });
    let headers = Object.keys(itemsCopy[0]);
    let fileTitle = `JobHistory-${moment(currentDate).format('MM-DD-YYYY')}`
    exportCSVFile(headers, itemsCopy, fileTitle, CSV_CONFIGS);
};

export const handleGetCurrentTeam = (teamsList, currentUserContacts, contacts, currentTeam) =>{
  const currentContactsMongoDB = [];
  const currentContactsGraphQL = [];
  contacts.forEach(contact=>{
    if (currentUserContacts.includes(contact._id)){
      currentContactsMongoDB.push(contact);
    }
  });
  
  currentContactsMongoDB.forEach(contact=>{
    const teamInGraphQL = teamsList.find(team=> team.team_name === contact.role);
    if(!!teamInGraphQL){
      currentContactsGraphQL.push(teamInGraphQL);
    }
  });
  const currentUserTeamGraphQL = teamsList.find(team=> team.team_name === currentTeam);

  return {currentContactsMongoDB, currentContactsGraphQL, currentUserTeamGraphQL}
}

export const handleFilterJobsByContacts = (jobs, users) =>{
  let jobsInContacts = [];
  let jobInList = [];

  jobs.forEach(job =>{
    const currentJobInContacts = users.find(user=> `${user.first_name} ${user.last_name}` === job.job_assigned_user_name) || null;

    if (currentJobInContacts) {
      if (!jobInList.includes(job.jobid)) {
        job.job_errors = [];
        jobsInContacts.push(job);
        jobInList.push(job.jobid);
      }
      else {
        const jobInListIndex = jobsInContacts.findIndex(item=> item.jobid === job.jobid);
        if (jobsInContacts[jobInListIndex].job_errors.length === 0){
          jobsInContacts[jobInListIndex].job_errors = [jobsInContacts[jobInListIndex].job_error_number];
        }
        if (!jobsInContacts[jobInListIndex].job_errors.includes(job.job_error_number)) {
          jobsInContacts[jobInListIndex].job_errors.push(job.job_error_number);
        }
        jobsInContacts[jobInListIndex].job_error_number = "Multiple errors";
      }
    }

  });
  return jobsInContacts;
}