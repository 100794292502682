export const EMPTY_STRING = '';
export const FILLED = 'filled'
export const DEFAULT_SUCCES = 'success';
export const ADMIN_TITLE = 'New Admin User';
export const CAMPUS_TITLE = 'New Campus User';
export const HEADER_TITLE = {
    adminAdd: 'New Admin User',
    userAdd: 'New Campus User',
    adminEdit: 'Edit Admin User',
    userEdit: 'Edit Campus User',
};
export const SWITCH_TITLE = 'CAMPUS USER';
export const BACK_USER_TABLE = 'BACK TO TABLE';
export const SUPERADMIN_ROLE_TITLE = 'Super Admin';
export const LOGGED_OUT = 'logged out';
export const DEFAULT_PASSWORD = 'Henryford1!';
export const SUPERADMIN_NAME = 'Super Admin';
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY HH:mm";
export const DIALOG_DELETE_TITLE = "Are you sure?";
export const DIALOG_DELETE_MSG = `You are going to delete this account!`;
export const DIALOG_RESTORE_MSG = `You are going to restore this account!`;
export const DELETE_BTN = 'Delete';
export const RESTORE_BTN = `Restore`;
export const CANCEL_BTN = 'Cancel';

export const BUTTON_TYPE = {
    error: 'error',
    default: 'default',
    success: 'success',
    defaultFlat: 'default-flat',
};

export const BUTTON_TITLE = {
    save: 'SAVE',
    cancel: 'CANCEL',
    export: 'EXPORT',
    microsoft: 'MICROSOFT',
    restore: 'RESTORE PASSWORD',
    close: 'CLOSE',
};

export const SETTINGS = {
    dashboard: 'Dashboard',
    users: 'Users',
    logs: 'Logs',
    statistics: 'Statistics',
    debug: 'Debug Tool',
};

export const EDIT_USER = 'EDIT USER';
export const NEW_USER = 'NEW USER';

export const DELETE_BTN_LABEL = 'Delete';
export const CANCEL_BTN_LABEL = 'Cancel';
export const SAVE_BTN_LABEL = 'Save';

export const CREATE_USER_ACTION = 'Create User';
export const EDIT_USER_ACTION = 'Edit User';
export const DELETE_USER_ACTION = 'Delete User';
export const RESTORE_USER_ACTION = 'Restore User';
export const CREATE_USER_ACTION_ERROR = 'Create User Error';
export const EDIT_USER_ACTION_ERROR = 'Create User Error';

export const SUCCESS_NOTIFICATION = {
    createdSuccess: 'created successfully!',
    updatedSuccess: 'updated successfully!',
    deletedSuccess: 'deleted successfully!',
};

export const ERROR_NOTIFICATION = {
    firstName: "First Name cannot be empty!",
    lastName: "Last Name cannot be empty!",
    role: "Role cannot be empty!",
    campus: "Campus cannot be empty!",
    passwordMatch: "Passwords don't match!",
    passwordEmpty: "Password cannot be empty!",
    confirmEmpty: "Confirm Password cannot be empty!",
    emailEmpty: "Email cannot be empty!",
    emailDuplicated: "Email already registered!",
    emailFormat: "Incorrect email format!",
};

export const LABEL = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    role: 'Role',
    password: 'Password',
    confirm: 'Confirm Password',
    adminSetting: 'Admin Settings',
    campus: 'Campus',
    role: 'Role',
    department: 'Department',
    campusUser: 'Campus User',
};

export const SELECTOR_OPTIONS = {
    campus: 'campus',
    role: 'role',
    department: 'department',
};

export const USER_COLUMNS = {
    status: 'Status',
    name: 'Name',
    campus: 'Campus',
    role: 'Role',
    email: 'Email',
    date: 'Date',
    login: 'Last Login'
};