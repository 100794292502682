import React from 'react';

import { SET_INPUTS } from './equipmentForm.constants';
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

function EquipmentForm (props){
    let equipmentInputs = SET_INPUTS(props);

    const inputTextRender = (data)=>{
      if(props.formError[data.key] !== ''){
        return(
          <TextField
            error
            helperText={props.formError[data.key]}
            key={'input-'+data.id}
            style={data.style}
            id={data.id}
            type={data.type}
            label={data.label}
            variant="filled"
            value={data.value || ''}
            onChange={(e)=>props.handleInputChange(e.target.value,data.key)}
          />
        );
      } else {
        return(
          <TextField
            disabled={!props.isEdit? true:false}
            key={'input-'+data.id}
            style={data.style}
            id={data.id}
            type={data.type}
            label={data.label}
            variant="filled"
            value={data.value || ''}
            onChange={(e)=>props.handleInputChange(e.target.value,data.key)}
          />
        );
      }
    };

    const inputNumRender = (data)=>{
      if(props.formError[data.key] !== ''){
        return(
          <TextField
            error
            helperText={props.formError[data.key]}
            key={'input-'+data.id}
            style={data.style}
            id={data.id}
            type={data.type}
            label={data.label}
            inputProps={data.inputProps}
            variant="filled"
            value={data.value}
            onChange={(e)=>props.handleInputChange(e.target.value,data.key)}
          />
        );
      } else {
        return(
          <TextField
            disabled={!props.isEdit? true:false}
            key={'input-'+data.id}
            style={data.style}
            id={data.id}
            type={data.type}
            label={data.label}
            variant="filled"
            inputProps={data.inputProps}
            value={data.value}
            onChange={(e)=>props.handleInputChange(e.target.value,data.key)}
          />
        );
      }
    };

    const showInputs = () =>{
      return(
        <React.Fragment>
            { equipmentInputs.map(item =>{
              switch (item.input){
                case 'text':
                  return(
                    <Grid item xs={item.grid} key={item.key}>
                      {inputTextRender({
                        key: item.key,
                        style: item.style,
                        id: item.id,
                        label: item.label,
                        value: item.value,
                        type: item.input})}
                    </Grid>
                  );
                case 'number':
                  return(
                    <Grid item xs={item.grid} key={item.key}>
                      {inputNumRender({
                        key: item.key,
                        style: item.style,
                        id: item.id,
                        label: item.label,
                        value: item.value,
                        type: item.input,
                        inputProps: item.inputProps})}
                    </Grid>
                  );
                case 'select':
                    return renderEditEquipment(item);
                default:
                  break;
              }
              return null
              })
            }
        </React.Fragment>
      );
    };

    const renderEditEquipment = (item)=>{
      let defaultInfo = {};
      if(item.key === 'deviceCategory')
        defaultInfo = props.equipmentCategories.find(category => category.id === item.value);
      if(item.key === 'team')
        defaultInfo = props.roles.find(category => category._id === item.value);

      return(
        <Grid item xs={item.grid} key={item.key}>
          <Autocomplete
            disabled={!props.isEdit? true:false}
            id={item.id}
            options={item.list}
            getOptionLabel={(option) => option[item.listKey]}
            getOptionSelected={(option, value)=>option[item.listKey] === value[item.listKey]}
            defaultValue={{[item.listKey]: defaultInfo[item.listKey], id: defaultInfo.id}}
            onChange={(event, newValue)=> !!newValue && props.handleInputChange(newValue[item.listKey], item.key)}
            renderInput={(params) => props.formError[item.key] !== ''?
              (<TextField
                error
                helperText={props.formError[item.key]}
                style={item.style}
                label={item.label}
                variant="filled"
                {...params}/>)
              :(<TextField
                style={item.style}
                label={item.label}
                variant="filled"
                {...params}/>)
            }
          />
        </Grid>
      );
    };

    return(
      <React.Fragment>
       {showInputs()}
      </React.Fragment>
    );
};

export default EquipmentForm;