export const remoteConfigEnabled = {
    "SpecimenCollection" : {
        "JobsBoard": "SpecimenCollection_jobsBoard_v1.0",
        "JobsHistory": "SpecimenCollection_jobsHistory_v1.0",
        "Permission": "SpecimenCollection_permission",
        "AddJobs": "SpecimenCollection_jobsBoard_addJobs_v1.0",
        "ExportTraces": "SpecimenCollection_jobsHistory_exportTraces_v1.0"
    },
    "Equipment":{
        "Permission": "Equipment_permission",
        "MapFilter": "Equipment_mapFilter_v1.0",
        "TableViewTable": "Equipment_tableViewTable_v1.0",
        "AdminTable": "Equipment_adminTable_v1.0",
        "EquipmentCategories": "Equipment_equipmentCategories_v1.0"
    },
    "Chat" : {
        "ChatWindowChime": "Chat_chatWindowChime_v1.0",
    },
    "Superadmin" : {
        "Dashboard": "Superadmin_dashboard_v1.0",
        "Statistics": "Superadmin_statictics_v1.0",
        "Users": "Superadmin_user_v1.0",
        "DebugTool": "Superadmin_debugTool_v1.0",
        "Logs": "Superadmin_logs_v1.0"
    }
}

export const remoteConfigNotEnabled = {
}