import React from "react";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import "./deleteRowMessage.css";

const DeleteRowMessage = (props) =>{
    const {showSpinner, editBoard} = props;
    const rowLabel = "Drag jobs here to remove";
    
    return(
        <React.Fragment>
            {editBoard && !showSpinner && (
                <div className="DeleteRowMessage_RemoveLabelContainer">
                <InfoOutlinedIcon style={{marginTop: "40%"}}/>
                <p className="DeleteRowMessage_RemoveLabel">{rowLabel}</p>
                </div>
            )}
        </React.Fragment>
    );
}

export default DeleteRowMessage;