import React from "react";
import moment from "moment";

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import ClickAwayListener  from "@material-ui/core/ClickAwayListener";

import {specimenTrackingBoard} from '../jobsBoard.constants';

import {useStyles} from './timeWindowControls.styles';
import { DATE_INPUT_LBL, TIMEWINDOW_NEXTDAY, TIMEWINDOW_PREVIOUSDAY, TIMEWINDOW_TOOLTIP } from "./timeWindowControls.constants";


const TimeWindowControls = (props) =>{
    const classes = useStyles();
    const {timeWindowMenuVisible, startDate, inputDate, inputDateError} = props;

    const renderTimeWindowMenu = () =>{
        const formatDate = moment(inputDate).format('YYYY-MM-DD');
        return(
          <ClickAwayListener onClickAway={()=>props.handleCancelCalendarTimeWindow()}>
            <Paper className={classes.TimeWindowControls_Paper}>
            <TextField
              id="webNavv-workflowAutomation-jobsBoard-dateInput-1.0"
              label={DATE_INPUT_LBL}
              type="date"
              format='YYYY-MM-DD'
              value={formatDate}
              onChange={(e)=>props.handleChangeInputDate(e.target.value)}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{disableUnderline: !inputDateError}}
              error={inputDateError}
            />
            </Paper>
          </ClickAwayListener>
        )
    }

    return(
        <div style={{marginTop:"10px"}}>
        <div className={classes.TimeWindowControls_TitleContainer} id="webNavv-workflowAutomation-jobsBoard-boardTitle-1.0">{specimenTrackingBoard}</div>
        <div className={classes.TimeWindowControls_DateContainer}>
          <div>
            <Tooltip title={TIMEWINDOW_TOOLTIP}>
              <span>
                <IconButton
                  id="webNavv-workflowAutomation-jobsBoard-calendar-1.0"
                  disabled={props.editBoard || props.showSpinner} 
                  className={classes.TimeWindowControls_calendarIconDiv}
                  onClick={()=>props.handleTimeWindowVisibility()} >
                  <CalendarTodayIcon className={props.editBoard || props.showSpinner? classes.TimeWindowControls_calendarIconDisabled : classes.TimeWindowControls_calendarIcon}/>
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <div id="webNavv-workflowAutomation-jobsBoard-date-1.0" className={classes.TimeWindowControls_DateTitle}>{moment(startDate).format('MMMM DD, YYYY')}</div>
          {timeWindowMenuVisible && (renderTimeWindowMenu())}
          <Tooltip title={TIMEWINDOW_PREVIOUSDAY}>
            <span>
              <IconButton
                id="webNavv-workflowAutomation-jobsBoard-backwardsArrow-1.0"
                disabled={props.editBoard || props.showSpinner}
                className={classes.TimeWindowControls_calendarIconDiv}
                onClick={()=>props.handleSubtractDay()} >
                <ArrowBackIosIcon className={props.editBoard || props.showSpinner? classes.TimeWindowControls_calendarIconDisabled : classes.TimeWindowControls_calendarIcon}/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={TIMEWINDOW_NEXTDAY}>
            <span>
              <IconButton
                id="webNavv-workflowAutomation-jobsBoard-forwardArrow-1.0"
                disabled={props.editBoard || props.showSpinner}
                className={classes.TimeWindowControls_calendarIconDiv}
                onClick={()=>props.handleAddDay()} >
                <ArrowForwardIosIcon className={props.editBoard || props.showSpinner? classes.TimeWindowControls_calendarIconDisabled : classes.TimeWindowControls_calendarIcon}/>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    );
};

export default TimeWindowControls;