import { SAVE_ACL, UPDATE_ACL, FETCH_ACLS, DELETE_ACL, ADMIN_ACLS } from '../actions/acl/types';

const initialState = {
    acls: [],
    adminAcls: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACL:
        case SAVE_ACL:
            return {
                ...state,
                acls: action.payload
            }
        case FETCH_ACLS:
            return {
                ...state,
                acls: action.payload
            }
        case ADMIN_ACLS:
            return {
                ...state,
                adminAcls: action.payload
            }
        case DELETE_ACL:
            return {
                ...state,
                acls: action.payload
            }
        default:
            return state;
    }
}