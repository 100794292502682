import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { grey } from '@material-ui/core/colors';
import Icon from "@material-ui/core/Icon";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import superProfileIcon from './img/super_profile.png';

import { EMPTY_STRING } from './loggedUser.constants';

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//         backgroundColor: '#3FA33E',
//         boxShadow: '0 0 0 1px #3FA33E',
//     },
// }))(Badge);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 10000,
        '& > *': {
            padding: 0,
        },
        '& p': {
            fontFamily: 'sans-serif',
            fontSize: 10,
            margin: 0,
            padding: 0,
        },
    },
    userName: {
        display: 'block',
    },
    role: {
        display: 'block',
    },
    campus: {
        display: 'block',
    },
    userAvatar: {
        backgroundColor: grey[400],
        height: 45,
        width: 45,
    },
}));

export default function LoggedUser(props) {
    const classes = useStyles();

    const { firstName, lastName, role, campus, closeUserMenu, toggleUserMenu } = props;
    // let badge={
    //     background: props.online?"#3FA33E":"#FF0000",
    //     boxShadow: '0 0 0 1px #3FA33E',
    // }
    let badge = {
        background: "#3FA33E",
        boxShadow: '0 0 0 1px #3FA33E',
    }
    const StyledBadge = withStyles((theme) => ({ badge }))(Badge);
    const fullName = `${firstName} ${lastName}`;

    const getUserInitials = () => {
        let initials = firstName ? firstName.substring(0, 1).toUpperCase() : EMPTY_STRING;
        initials += lastName ? lastName.substring(0, 1).toUpperCase() : EMPTY_STRING;
        return initials;
    }

    const initials = getUserInitials();

    return (
        <div
            id="webNavv-superAdmin-topbar-profile-1.0"
            className={classes.root}
        >
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <ClickAwayListener onClickAway={closeUserMenu}>
                        <StyledBadge
                            overlap="circular"
                            variant="dot"
                        >
                            <Avatar className={classes.userAvatar} onClick={toggleUserMenu} style={{ cursor: 'pointer' }}>{initials}</Avatar>
                        </StyledBadge>
                    </ClickAwayListener>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    style={{ alignSelf: 'center' }}
                    primary={
                        <React.Fragment>
                            <div style={{ display: 'inline-block' }}>
                                <p style={{ fontSize: 15, fontWeight: 'bold' }}>{fullName}</p>
                                {role &&
                                    <p style={{ color: 'gray' }}>{role}</p>
                                }
                                {campus === 'SUPER ADMIN PANEL' ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon style={{ fontSize: 11, display: 'flex' }}>
                                            <img src={superProfileIcon} alt="superAdmin-icon"/>
                                        </Icon>
                                        <p style={{ color: '#2843A3', textTransform: 'uppercase' }}>{campus}</p>
                                    </div>
                                    :
                                    <p style={{ color: '#2843A3', textTransform: 'uppercase' }}>{campus}</p>
                                }
                            </div>
                        </React.Fragment>
                    }
                />
            </ListItem>
        </div>
    );
}