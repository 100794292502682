import React from 'react';
import './new_password.component.css';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from 'axios';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

class NewPassword extends React.Component {
    state = {
        pass:'',
        dobPass:'',
        token:'',
        status: true,
        validPass : true,
        samePass: true,
        open: false,
    }

    UNSAFE_componentWillMount(){
        let address = window.location.search;
        let index = address.indexOf("=");
        let mToken = address.substring(index + 1);
        this.setState({token: mToken});
    }

    componentDidMount() {
        //this.setState({token:this.props.match.params.token})
    }
    
    validatePass = (e) => {
        let password = e.target.value;
        let mValid = true;
        let mEqual = false;
        if(password !== this.state.dobPass) {
            mEqual = false;
        } else {
            mEqual = true;
        }
        var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
        
        /*if (regex.test(String(password))) {
            mValid = true;
        } else {
            mValid = false;
        }*/
        this.setState({validPass:mValid, samePass:mEqual, pass:password, status:true});
    }

    comparePass= (e) => {
        let verify = e.target.value;
        let mVerify = false;
        if(this.state.pass === verify){
            mVerify = true;
        } else {
            mVerify = false;
        }
        this.setState({samePass:mVerify, dobPass:verify, status:true});
    }
    resetPassword(){
        if(this.state.validPass && this.state.samePass && this.state.token !== '') {
            this.setState({status:true});
            try{
                axios({
                    method:'post',
                    url:secureUrl.hostUrl+'/api/accounts/update-password-from-token?access_token='+this.state.token,
                    data:{
                        access_token:this.state.token,
                        newPassword:this.state.pass
                    }
                }).then((response)=>{
                    if(response.status === 200 && response.statusText === "OK"){
                        this.setState({ open: true,status:true });
                    } else {
                        this.setState({open: false,status:false});
                    }
                })
            }catch(error){
                this.setState({open: false,status:false});
                console.error("[NEWPASSWORD] an error ocurred in resetPassword: ",error.message);
            }
        } else {
            this.setState({open: false,status:false});
        }
    }
    
    handleClose = () => {
        this.setState({ open: false });
        window.location.href = "/";
    };

    render(){
        const actions = [
            <FlatButton
              label="Ok"
              primary={true}
              onClick={this.handleClose}
            />
        ];
        let inputPass, verifyPass;
        if(this.state.validPass){
            inputPass = <TextField tabIndex="1" hintText="Insert password" type="password" floatingLabelText="Insert password" 
            onKeyUp={(e) => {this.validatePass(e);}} />;
        } else {
            inputPass = <TextField tabIndex="1" hintText="Insert password" type="password" floatingLabelText="Insert password" 
            onKeyUp={(e) => {this.validatePass(e);}} 
            errorText="Invalid Password must contain at least: 8 characters, 1 number, 1 uppercase, 1 lowercase, 1 special characters"/>
        }
        if(this.state.samePass) {
            verifyPass = <TextField tabIndex="2" hintText="Repeat password" type="password" floatingLabelText="Repeat password" 
            onChange={(e) => {this.comparePass(e);}}/>;
        } else {
            verifyPass = <TextField tabIndex="2" hintText="Repeat password" floatingLabelText="Repeat password" type="password"
            onChange={(e) => {this.comparePass(e);}} 
            errorText="Passwords Do Not Match"/>;
        }

        let mDialog = <Dialog
                        title="Password reset, you'll be redirected to login"
                        actions={actions}
                        modal={true}
                        open={this.state.open}>
                        {this.state.dialogError}
                    </Dialog>
        return (
            <div className="reset-form">
                <p className="reset-header">Please Enter a New Password and Confirmation</p>
                <div>
                    {inputPass}
                    <br />
                    {verifyPass}
                    <br />
                    <RaisedButton className="right-mid-margin" onClick={()=>this.resetPassword()}>Reset Password</RaisedButton>
                    {!this.state.status &&
                        <p className="errorParagraph">There was an error!</p>
                    }
                    {mDialog}
                </div>
            </div>
        );
    }
}
export default NewPassword;