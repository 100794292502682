export const CHANGE_DASHBOARD_CONTENT = 'CHANGE_DASHBOARD_CONTENT';
export const CHANGE_CALLOUTS = 'CHANGE_CALLOUTS';
export const CHANGE_SMALL_CALLOUTS = 'CHANGE_SMALL_CALLOUTS';
export const CHANGE_TRACES = 'CHANGE_TRACES';
export const CHANGE_ACTIVE_FLOOR = 'CHANGE_ACTIVE_FLOOR';
export const CHANGE_ACTIVE_USER = 'CHANGE_ACTIVE_USER';
export const CHANGE_TIME_WINDOW = 'CHANGE_TIME_WINDOW';
export const DRAWER_CHANGE = 'DRAWER_CHANGE';
export const DEFAULT_VIEW_LOADED = 'DEFAULT_VIEW_LOADED';
export const CHANGE_HIDE_JOB = 'CHANGE_HIDE_JOB';
export const CHANGE_WIFI = 'CHANGE_WIFI';
export const CHANGE_GEOJSON_JOB = 'CHANGE_GEOJSON_JOB';
export const CHANGE_CENTER_HISTORY_MAP = 'CHANGE_CENTER_HISTORY_MAP';
export const OPEN_HISTORY_TABLE = 'OPEN_HISTORY_TABLE';
export const STORE_HISTORY_TRACES = 'STORE_HISTORY_TRACES';
export const STORE_USERS_STREAMING = 'STORE_USERS_STREAMING';
export const CHANGE_PEOPLE_FILTER = 'CHANGE_PEOPLE_FILTER';
export const CHANGE_EQUIPMENT_FILTER = 'CHANGE_EQUIPMENT_FILTER';
export const SET_MENU_VALUE = 'SET_MENU_VALUE';
export const ZOOM_ON_USER = 'ZOOM_ON_USER';
export const USER_CHAT = 'USER_CHAT';
export const USER_CHAT_UPDATED = 'USER_CHAT_UPDATED';
export const UNREAD_MSG_LIST = 'UNREAD_MSG_LIST';
export const ZOOM_ON_EQUIPMENT = 'ZOOM_ON_EQUIPMENT';
export const OPEN_BROADCAST_CHAT = 'OPEN_BROADCAST_CHAT';
export const UNREAD_BROADCAST_CHAT = 'UNREAD_BROADCAST_CHAT';
export const ANIMATION_SHOW_TRACES = 'ANIMATION_SHOW_TRACES';
export const ANIMATION_SHOW_CALLOUTS = 'ANIMATION_SHOW_CALLOUTS';
export const ANIMATION_CURRENT_FLOOR_ONLY = 'ANIMATION_CURRENT_FLOOR_ONLY';
export const CHANGE_INDOOR_ONLY = 'CHANGE_INDOOR_ONLY';
export const CENTER_MAP = 'CENTER_MAP';
export const REMOTE_SELECT_TAB = 'REMOTE_SELECT_TAB';
export const SET_LAST_VIEWPROPS = 'SET_LAST_VIEWPROPS';
export const SET_MAP_CONFIGS = "SET_MAP_CONFIGS";