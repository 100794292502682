

export const handleFloorSwitchEventListener = (handleCheckAnchorAnnotations) =>{
    const element = document.getElementById("levelpicker");
    element.addEventListener('click', handleCheckAnchorAnnotations);
}

export const handleRemoveFloorSwitchEventListener = () =>{
    const element = document.getElementById("levelpicker");
    element.removeEventListener('click', ()=>{});
}

export const handleDisplayFloorSwitch = (viewProps, currentView)=>{
    if((viewProps==="transporter" || viewProps==="lastLocation" || viewProps==='transporterPhone') && currentView==="map"){
        if(document.getElementById("levelpicker")){
            document.getElementById("levelpicker").style.display="none";
        }
    } else{
        if(document.getElementById("levelpicker") && currentView==="map"){
            document.getElementById("levelpicker").style.display="block";
        }
    }
}