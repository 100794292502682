import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from "@material-ui/core/styles";
import { SUPERADMIN_NAME } from './../users/users.constants';


const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {

  userList = [];


  handleAction = (action) => {
    let index = this.props.selectedRows.data[0].dataIndex;
    let data = this.props.data[index];

    switch (action) {
      case "Edit":
        this.props.edit(data);
        break;
      case "Delete":
        this.props.showDelete(data);
        break;
      case "Restore":
        this.props.showRestore(data);
        break;
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    let index = this.props.selectedRows.data[0].dataIndex;
    let data = this.props.data[index];
    let isSuperadmin = data.role === SUPERADMIN_NAME;
    const { enabled } = data;

    return (
      <div className={classes.iconContainer}>
        {(this.props.selectedRows.data.length === 1) &&
          <Tooltip title={"Edit account"}>
            <IconButton className={classes.iconButton} onClick={() => { this.handleAction("Edit") }} >
              <EditIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
        <Tooltip title={enabled ? "Delete account" : "Restore account"}>
          <IconButton className={classes.iconButton} onClick={() => { this.handleAction(enabled ? "Delete" : "Restore") }} disabled={isSuperadmin}>
            {enabled ? <DeleteIcon className={classes.icon} /> : <RestoreIcon className={classes.icon} />}
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default (withStyles(defaultToolbarSelectStyles)(CustomToolbarSelect));