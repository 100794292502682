import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ActiveTab from './tabs/activemap.component';
import MasterTab from './tabs/tab.component';
import { SET_SETTINGS_TAB, SET_ADMIN_TAB, SET_CONTACT_TAB, SET_CHAT_TAB, SET_TABLE_TAB, SET_WAYFINDING_TAB, SET_WORKFLOWAUTOMATION_TAB } from './tabs/tab.constants';
import { useStyles } from '../sideBar/sideBar.styles';
import miniLogo from "../../../img/ntrackLogo.png";
import UsersList from "../list/usersList.component";
import UsersListChime from "../list/usersListChime.component";
import { openBroadcastChat } from '../../../actions/views/viewsActions';
import { getACL } from '../../../actions/auth/authActions';


function LeftNav(props) {
  const classes = useStyles();
  let settingsTabInfo = SET_SETTINGS_TAB(props);
  let adminTabInfo = SET_ADMIN_TAB(props);
  let contactTabInfo = SET_CONTACT_TAB(props);
  let chatTabInfo = SET_CHAT_TAB(props);
  let tableTabInfo = SET_TABLE_TAB(props);
  let wayfindingTabInfo = SET_WAYFINDING_TAB(props);
  let workflorAutomationTabInfo = SET_WORKFLOWAUTOMATION_TAB(props);
  let { featureFlags } = props;

  useEffect(() => {
    if (props.aclViews === null || props.aclViews === undefined) {
      getACL(props.userId);
    }
  }, []);

  return (
    <React.Fragment>
      {props.openNav ? (
        <div className={classes.drawerDiv}>
          <List>
            <div style={{ display: "flex" }}>
              <img src={miniLogo} className={classes.logo} alt="miniLogo" />
              <h3 className={classes.navvTrackTitle}>NavvTrack</h3>
              <IconButton
                id="webNavv-navigation-burguerIcon-1.0"
                style={{ marginLeft: "25%" }}
                onClick={() => {
                  props.handleToggle();
                }}
              >
                <MenuIcon style={{ fill: "#2843A3" }} />
              </IconButton>
            </div>
            <Divider />
            {props.aclViews[1].access && props.value === "a" && (
              <ActiveTab
                usersFilter={props.usersFilter}
                handlePeopleMapFilter={props.handlePeopleMapFilter}
                equipmentFilter={props.equipmentFilter}
                handleEquipmentFilter={props.handleEquipmentFilter}
              />
            )}
            {props.value === "b" && (
              <MasterTab
                tabId="webNavv-tableview-navigation-1.0"
                tabTitle={"Table View"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={tableTabInfo}
                handleChangeView={props.handleChangeView}
                aclViews={props.aclViews}
              />
            )}
            {props.value === "c" && (
              <MasterTab
                tabId="webNavv-navvCast-navigation-1.0"
                tabTitle={"NavvCast"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={chatTabInfo}
                openBroadcastChatWindow={props.openBroadcastChatWindow}
                aclViews={props.aclViews}
              />
            )}
            {props.aclViews[9].access && props.value === "d" && (
              <MasterTab
                tabId="webNavv-contactPanel-navigation-1.0"
                tabTitle={"Contact Tracing"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={contactTabInfo}
                handleChangeView={props.handleChangeView}
                aclViews={props.aclViews}
              />
            )}
            {!!props.aclViews[13] && props.aclViews[13].access && props.value === "e" && (
              <MasterTab
                tabId="webNavv-workflowAutomation-navigation-1.0"
                tabTitle={"Workflow Automation"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={workflorAutomationTabInfo}
                handleChangeView={props.handleChangeView}
                aclViews={props.aclViews}
              />
            )}
            {props.value === "f" && (
              <MasterTab
                tabId="webNavv-wayfinding-navigation-1.0"
                tabTitle={"Wayfinding"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={wayfindingTabInfo}
                handleChangeView={props.handleChangeView}
                campusInfo={props.campusInfo}
                aclViews={props.aclViews}
              />
            )}
            {props.value === "g" && (
              <MasterTab
                tabId="webNavv-adminPanel-navigation-1.0"
                tabTitle={"Admin Panel"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={adminTabInfo}
                handleChangeView={props.handleChangeView}
                aclViews={props.aclViews}
              />
            )}
            {props.value === "h" && (
              <MasterTab
                tabId="webNavv-configPanel-navigation-1.0"
                tabTitle={"Settings"}
                featureFlags={props.featureFlags}
                itemSelected={props.itemSelected}
                currentMenuValue={props.currentMenuValue}
                tabs={settingsTabInfo}
                handleChangeView={props.handleChangeView}
                aclViews={props.aclViews}
              />
            )}
          </List>
        </div>
      ) : (null)}

      {props.openNav && (
        <div className={classes.usersListContainer}>
          {(props.usersListOpen && featureFlags.Chat.ChatWindowChime) &&
            (
              <div className={classes.usersListDiv}>
                <UsersListChime
                  usersListOpen={props.usersListOpen}
                  setUsersListOpen={props.handleToggleUsersList}
                  userFilterSelected={props.usersFilter}
                  equipmentFilterSelected={props.equipmentFilter}
                />
              </div>
            )
          }
          {(props.usersListOpen && !featureFlags.Chat.ChatWindowChime) &&
            (
              <div className={classes.usersListDiv}>
                <UsersList
                  usersListOpen={props.usersListOpen}
                  setUsersListOpen={props.handleToggleUsersList}
                  userFilterSelected={props.usersFilter}
                  equipmentFilterSelected={props.equipmentFilter}
                />
              </div>
            )
          }
          {props.usersListOpen ? (
            <div className={classes.backIconListDiv}>
              <ArrowBackIosIcon
                id="webNavv-navigation-arrowBackIcon-1.0"
                className={classes.exitIcon}
                onClick={() => {
                  props.handleToggleUsersList(false);
                }}
              />
            </div>
          ) : (
            <div className={classes.backIconDiv}>
              <ArrowForwardIosIcon
                id="webNavv-navigation-arrowForwardIcon-1.0"
                className={classes.exitIcon}
                onClick={() => {
                  props.handleToggleUsersList(true);
                }}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  users: state.user.users,
  userId: state.auth.userId,
  aclViews: state.auth.acl.views,
  currentView: state.views.currentView,
  openNav: state.views.drawerOpen,
  viewProps: state.views.viewProps,
  currentMenuValue: state.views.currentMenuValue,
  campusInfo: state.auth.userInfo.campusInfo,
  featureFlags: state.auth.featureFlags
})

export default connect(mapStateToProps, {
  openBroadcastChat,
  getACL
})(LeftNav);