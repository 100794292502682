import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables/dist/index";
import BookIcon from '@material-ui/icons/Book';
import moment from "moment";

import { getLogsByTimeWindow } from '../../../actions/logs/logsActions';
import { getCampuses } from '../../../actions/campus/campusActions';
import AwesomeSpinner from '../../utilities/spinner';
import MasterButton from '../../utilities/button.component';
import { exportCSVFile } from "../../utilities/exportCSV";
import { styles } from './logsTable.styles';
import * as CONSTS from './logsTable.constants';

class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      action: '',
      description: '',
      tableRowsPerPage: 7,
      timestamp: '',
      id: false,
      showSpinner: true,
      showTable: false,
      startDate: new Date(),
      endDate: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      openTimeWindowMenu: false,
      isSearch: false,
      logsList: [],
      campus: '',
      campusId: '',
      difference: 0,
      isRefreshStoped: false,
      inputUpdate: false,
      isDataFiltered: false,
      startDateError: false,
      endDateError: false
    };
  }
  logInterval = null;
  tableData = [];
  currentStartDate = null;
  currentEndDate = null;
  currentCampusId = '';

  componentDidMount = () => {
    const { startDate, endDate } = this.state;
    const { campusInfo, getCampuses, getLogsByTimeWindow } = this.props;
    let start = startDate;
    start.setDate(start.getDate() - 7);
    this.currentStartDate = start;
    this.currentEndDate = endDate;
    this.currentCampusId = campusInfo.id;
    getLogsByTimeWindow(start, endDate, campusInfo.id).then(resp => {
      this.setState({ showTable: true, showSpinner: false });
    });
    getCampuses();
    this.setState({
      difference: moment.duration(moment(endDate).diff(startDate)),
      minDate: moment(start).subtract(1, 'years').format(CONSTS.DATE_FORMAT)
    });
    this.logInterval = setInterval(() => {
      if (!this.state.isRefreshStoped)
        getLogsByTimeWindow(this.currentStartDate, this.currentEndDate, this.currentCampusId);
    }, 60000);
  };

  componentWillUnmount() {
    clearInterval(this.logInterval);
  }

  componentDidUpdate() {
    const { startDate, endDate, isRefreshStoped, inputUpdate } = this.state;
    if (isRefreshStoped && inputUpdate) {
      let diff = moment.duration(moment(endDate).diff(startDate));
      this.setState({ difference: diff, inputUpdate: false });
    }
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    let { classes } = this.props;
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          className={classes.dateInput}
        />
      </div>
    );
  };

  handleCancelTimeWindow = () => {
    const { campusInfo } = this.props;
    let start = new Date();
    let end = new Date();
    start.setDate(start.getDate() - 7);
    this.setState({
      openTimeWindowMenu: false,
      startDate: start,
      endDate: end,
      difference: moment.duration(moment(start).diff(end)),
      startDateError: false,
      endDateError: false
    });
    this.currentStartDate = start;
    this.currentEndDate = end;
    this.currentCampusId = campusInfo.id;
    this.props.getLogsByTimeWindow(start, end, campusInfo.id).then(resp => {
      this.setState({ showTable: true, showSpinner: false, isRefreshStoped: false, isDataFiltered: false });
    });
  }

  handleNewTimeWindow = () => {
    const { campusInfo } = this.props;
    const { startDate, endDate } = this.state;
    this.setState({ showSpinner: true, openTimeWindowMenu: false });
    this.currentStartDate = startDate;
    this.currentEndDate = endDate;
    this.currentCampusId = campusInfo.id;
    this.props.getLogsByTimeWindow(startDate, endDate, campusInfo.id).then(resp => {
      this.setState({ showSpinner: false, isRefreshStoped: false, isDataFiltered: true });
    });
  }

  handleMenu = () => {
    this.setState({ openTimeWindowMenu: !this.state.openTimeWindowMenu });
  };

  handleCleanSearch = () => {
    this.setState({ isSearch: false, search: '' });
    this.logList = [];
  }

  handleSearch = (search) => {
    if (search !== '') {
      this.setState({ isSearch: true, search: search });
      this.handleFilterSearch(search);
    } else {
      this.setState({ isSearch: false, search: search });
    }
  };

  handleFilterSearch = (search) => {
    let filtered = this.props.logs.filter(item => {
      if (item.action.toLowerCase().includes(search.toLowerCase())
        || item.description.toLowerCase().includes(search.toLowerCase())
        || item._id.toLowerCase().includes(search.toLowerCase())) {
          return item;
      }
      return null;
    });
    this.setState({ logsList: filtered });
  };

  generateCSV = () => {
    const { logsList } = this.state;
    let itemsCopy = [];
    logsList.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0));
    logsList.map(item => {
      itemsCopy.push({
        id: item._id,
        action: item.action,
        description: item.description,
        performedBy: item.userId ? item.userId : 'Id not available',
        timestamp: item.timestamp
      });
      return null;
    });
    let headers = Object.keys(itemsCopy[0]);
    let start = moment(this.state.startDate).format(CONSTS.SIMPLE_DATE);
    let end = moment(this.state.endDate).format(CONSTS.SIMPLE_DATE);
    let filtered = this.state.isDataFiltered ? CONSTS.FILTERED : CONSTS.ALL;
    let fileTitle = `${this.props.campusInfo.name}-${filtered}-${start}-${end}-Log`
    exportCSVFile(headers, itemsCopy, fileTitle, CONSTS.CSV_CONFIGS);
  };

  handleTimeUpdate = (type, newValue) => {
    let newDate = moment(newValue, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY HH:mm:ss');
    if (type === CONSTS.START) {
      if (moment(newDate).isBetween(this.state.minDate, this.state.endDate)) {
        this.setState({ startDate: newValue, isRefreshStoped: true, inputUpdate: true, startDateError: false });
      } else {
        this.setState({ startDate: newValue, isRefreshStoped: true, inputUpdate: true, startDateError: true });
      }
    } else {
      if (moment(newDate).isBetween(this.state.startDate, this.state.maxDate)) {
        this.setState({ endDate: newValue, isRefreshStoped: true, inputUpdate: true, startDateError: false });
      } else {
        this.setState({ endDate: newValue, isRefreshStoped: true, inputUpdate: true, startDateError: true });
      }
    }
  };

  handleFilterCampus = (newValue) => {
    const { startDate, endDate } = this.state;
    let selectedCampus = this.props.campusList.filter(item => item.id === newValue.id);
    this.setState({ campus: newValue, showSpinner: true });
    this.props.getLogsByTimeWindow(startDate, endDate, selectedCampus[0].id).then(resp => {
      this.setState({ showSpinner: false });
    });
  };

  render() {
    let data = [];
    if (!this.state.isSearch && this.state.logsList !== this.props.logs) {
      this.setState({ logsList: this.props.logs });
    }
    this.state.logsList.map((e) => {
      let dateFormated = moment(e.timestamp).format(CONSTS.DATE_FORMAT);
      data.push({
        action: e.action,
        description: e.description,
        performedBy: e.userId ? e.userId : "Id not available",
        timestamp: [dateFormated, e.timestamp],
        id: e._id,
      });
      return null;
    });

    data.sort((a, b) => (a.timestamp[0] < b.timestamp[0]) ? 1 : ((b.timestamp[0] < a.timestamp[0]) ? -1 : 0));

    const columns = [
      {
        name: CONSTS.COLUMNS.action.id,
        label: CONSTS.COLUMNS.action.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => <p className={classes.actionLbl}>{value}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.description.id,
        label: CONSTS.COLUMNS.description.label,
        options: {
          filter: false,
          sort: false,
          customHeadRender: (value, tableMeta) => {
            return (<div
              className="MuiTableCell-root MuiTableCell-head">
              <div
                className="MUIDataTableHeadCell-contentWrapper"
                style={{
                  justifyContent: "center",
                  display: "flex"
                }}>
                {CONSTS.COLUMNS.description.label}
              </div>
            </div>)
          },
          customBodyRender: (value) => <p className={this.props.open ? classes.descriptionLblOpen : classes.descriptionLbl}>{value}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.performedBy.id,
        label: CONSTS.COLUMNS.performedBy.label,
        options: {
          filter: false,
          sort: false,
          customHeadRender: (value, tableMeta) => {
            return (<div
              className="MuiTableCell-root MuiTableCell-head">
              <div
                className="MUIDataTableHeadCell-contentWrapper"
                style={{
                  justifyContent: "center",
                  display: "flex"
                }}>
                {CONSTS.COLUMNS.performedBy.label}
              </div>
            </div>)
          }
        }
      },
      {
        name: CONSTS.COLUMNS.timestamp.id,
        label: CONSTS.COLUMNS.timestamp.label,
        options: {
          sortThirdClickReset: true,
          filter: false,
          customBodyRender: (value) => <p className={this.props.open ? classes.timestampOpen : ''}>{value[0]}</p>
        }
      },
      {
        name: CONSTS.COLUMNS.id.id,
        label: CONSTS.COLUMNS.id.label,
        options: {
          filter: false,
          sort: false,
          customHeadRender: (value, tableMeta) => {
            return (<div
              className="MuiTableCell-root MuiTableCell-head">
              <div
                className="MUIDataTableHeadCell-contentWrapper"
                style={{
                  justifyContent: "center",
                  display: "flex"
                }}>
                {CONSTS.COLUMNS.id.label}
              </div>
            </div>)
          },
        }
      }
    ];

    const options = {
      filterType: CONSTS.TABLE_CONFIG.filterType,
      responsive: CONSTS.TABLE_CONFIG.responsive,
      rowsPerPage: this.state.tableRowsPerPage,
      rowsPerPageOptions: CONSTS.TABLE_CONFIG.rowsPerPageOptions,
      selectableRowsHeader: false,
      selectableRows: CONSTS.TABLE_CONFIG.selectableRows,
      selectableRowsOnClick: false,
      search: false,
      print: false,
      filter: false,
      download: false,
      viewColumns: false,
      customSort: (data, colIndex, order) => {
        return CONSTS.TABLE_SORT(data, colIndex, order);
      },
      toolbar: {
        filterTable: CONSTS.TABLE_CONFIG.filterTable,
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case CONSTS.TABLE_EVENTS.changeRowsPerPage:
            this.setState({ tableRowsPerPage: tableState.rowsPerPage });
            break
          default:
            break;
        }
      }
    };

    const headerRender = () => {
      let { classes } = this.props;
      return (
        <div>
          <div className={this.props.open ? classes.titleContainerOpen : classes.titleContainer}>
            <div className={classes.titleIconDiv}>
              <BookIcon className={classes.iconTitle} />
              <h2 className={classes.titleLbl}>{CONSTS.TABLE_TITLE}</h2>
            </div>
            <Autocomplete
              className={this.props.open? classes.autocompleteOpen:classes.autocomplete}
              id="webNavv-settings-logsTable-campusNameSelector-1.0"
              options={this.props.campusList}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              defaultValue={{ name: this.props.campusInfo.name, id: this.props.campusInfo.id }}
              onChange={(event, newValue) => !!newValue && this.handleFilterCampus(newValue)}
              renderInput={(params) =>
                <TextField
                  label={CONSTS.CAMPUS}
                  variant="filled"
                  {...params} />
              }
            />
          </div>
          <div className={this.props.open ? classes.formContainerOpen : classes.formContainer}>
            <div className={this.props.open ? classes.searchContainerOpen : classes.searchContainer}>
              <div className={classes.inputContainer}>
                <Input
                id="webNavv-settings-logsTable-searchBar-1.0"
                className={this.props.open? classes.inputOpen:classes.input}
                placeholder={CONSTS.SEARCH}
                disableUnderline={true}
                value={this.state.search}
                onChange={e=>{this.handleSearch(e.target.value)}}
                startAdornment={
                  <InputAdornment position={CONSTS.START}>
                    <SearchIcon />
                  </InputAdornment>
                }/>
              </div>
              <div className={classes.closeSearch}>
                {this.state.search !== '' &&
                <IconButton
                  id="webNavv-settings-logsTable-clearSearchButton-1.0"
                  onClick={()=>this.handleCleanSearch()} 
                  className={classes.cleanBtn}>
                  <CloseIcon />
                </IconButton>
                }
              </div>
            </div>
            <div className={this.props.open? classes.timeContainerOpen:classes.timeContainer}>
              <div className={classes.fromDiv} id="webNavv-settings-logsTable-fromDate-1.0">
                <div style={{display:'block'}}>
                  <div className={classes.timeLbls}>
                    <div className={classes.timeWindowLbls}>{CONSTS.FROM}</div>
                    <div className={classes.date}>{moment(new Date(this.state.startDate)).format(CONSTS.DATE_FORMAT)}</div>
                  </div>
                </div>
                <div className={classes.toDiv} id="webNavv-settings-logsTable-toDate-1.0">
                  <div className={classes.timeLbls}>
                    <div className={classes.timeWindowLbls}>{CONSTS.TO}</div>
                    <div className={classes.date}>{moment(new Date(this.state.endDate)).format(CONSTS.DATE_FORMAT)}</div>
                  </div>
                </div>
              </div>
              <div className={classes.timeDifference}>{this.state.difference.humanize()}</div>
              <div className={classes.timeInputContainer}>
                <IconButton
                  id="webNavv-settings-logsTable-openCalendarButton-1.0"
                  onClick={()=>{this.handleMenu()}}>
                    <KeyboardArrowDownIcon/>
                </IconButton>
              </div>
            </div>
            {this.state.openTimeWindowMenu && (
              <Paper className={this.props.open? classes.paperOpen:classes.paper}>
              <TextField
              id="webNavv-settings-logsTable-startDateInput-1.0"
              className={classes.dateInput}
              error={this.state.startDateError}
              label={CONSTS.START_DATE}
              type="datetime-local"
              value={moment(new Date(this.state.startDate)).format(CONSTS.INPUT_DATE_FORMAT)}
              onChange={(e)=>this.handleTimeUpdate(CONSTS.START, e.target.value)}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                disableUnderline: this.state.startDateError? false:true
              }}
              inputProps={{
                min: moment(new Date(this.state.minDate)).format(CONSTS.INPUT_DATE_FORMAT),
                max: moment(new Date(this.state.endDate)).format(CONSTS.INPUT_DATE_FORMAT)
              }}
            />
            <TextField
              id="webNavv-settings-logsTable-endDate-1.0"
              className={classes.dateInput}
              error={this.state.endDateError}
              label={CONSTS.END_DATE}
              type="datetime-local"
              value={moment(new Date(this.state.endDate)).format(CONSTS.INPUT_DATE_FORMAT)}
              onChange={(e)=>this.handleTimeUpdate(CONSTS.END, e.target.value)}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                disableUnderline: this.state.endDateError? false:true
              }}
              inputProps={{
                min: moment(new Date(this.state.startDate)).format(CONSTS.INPUT_DATE_FORMAT),
                max: moment(new Date(this.state.maxDate)).format(CONSTS.INPUT_DATE_FORMAT)
              }}
            />
            <div className={classes.timeBtns}>
              <MasterButton
                id="webNavv-settings-logsTable-datesCancelButton-1.0"
                keyLbl={'cancel-log'}
                label={CONSTS.CANCEL}
                buttonType={'default-flat'}
                isDisabled={false}
                handleClick={()=>this.handleCancelTimeWindow()}
                size={"small"}/>
              <MasterButton
                id="webNavv-settings-logsTable-datesApplyButton-1.0"
                isDisabled={this.state.startDateError || this.state.endDateError ? true:false}
                keyLbl={'applyDate-log'}
                label={CONSTS.APPLY}
                buttonType={'success-flat'}
                handleClick={()=>this.handleNewTimeWindow()}
                size={"small"}/>
            </div>
            </Paper>
            )}
            <MasterButton
              id="webNavv-settings-logsTable-exportDataButton-1.0"
              keyLbl={'export-logs'}
              label={CONSTS.EXPORT}
              buttonType={'success'}
              isDisabled={false}
              handleClick={this.generateCSV}
              size={"medium"}
              styles={{ marginLeft: '30px', height: '5vh' }}
              class={classes.containedBtnPass} />
          </div>
        </div>
      );
    }
    let { classes } = this.props;
    let l = this.props.open ? '23%' : '4%';
    let w = this.props.open ? '75.5%' : '95%';

    return (
      <div style={{marginTop:'5%',marginLeft:l, width:w}} id="webNavv-settings-logsTable-1.0">
        <div>
          {this.state.showTable &&
            <MuiThemeProvider theme={CONSTS.getMuiTheme()}>
              <MUIDataTable
                title={headerRender()}
                data={data}
                columns={columns}
                options={options} />
            </MuiThemeProvider>
          }
        </div>
        <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.open} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.logs.logs,
  campusInfo: state.auth.userInfo.campusInfo,
  open: state.views.drawerOpen,
  userId: state.auth.userId,
  campusList: state.campus.campusList
});

export default connect(mapStateToProps, { getLogsByTimeWindow, getCampuses })(withStyles(styles)(LogsTable));
