export const SET_USER = 'SET_USER';
export const TOKEN = 'TOKEN';
export const ALL_USERS = 'ALL_USERS';
export const CREATE_SUPER_ADMIN = 'CREATE_SUPER_ADMIN';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ALL_ACLS = 'FETCH_ALL_ACLS';
export const GET_SUPER_ADMIN_LIST = 'GET_SUPER_ADMIN_LIST';
export const INIT_STATE = 'INIT_STATE';
export const USER_COUNT_BY_CAMPUS = 'USER_COUNT_BY_CAMPUS';
export const PHONE_COUNT_BY_CAMPUS = 'PHONE_COUNT_BY_CAMPUS';
export const MESSAGE_COUNT_BY_CAMPUS = 'MESSAGE_COUNT_BY_CAMPUS';
export const EQUIPMENT_COUNT_BY_CAMPUS = 'EQUIPMENTS_COUNT_BY_CAMPUS';
export const USER_BY_ROLE_AND_CAMPUS = 'USER_BY_ROLE_AND_CAMPUS';
export const MESSAGE_BY_DATE_AND_CAMPUS = 'MESSAGE_BY_DATE_AND_CAMPUS';
export const MESSAGE_BY_ADMIN = 'MESSAGE_BY_ADMIN';
export const PHONE_BY_ROLE_AND_CAMPUS = 'PHONE_BY_ROLE_AND_CAMPUS';
