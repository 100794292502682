export const LIST_USER = 'LIST_USER';
export const LIST_USER_INFO = 'LIST_USER_INFO';
export const LIST_USER_PAGINATION = 'LIST_USER_PAGINATION';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const PATCH_USER = 'PATCH_USER';
export const LOGGED_USER = 'LOGGED_USER';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_EMAIL = "SAVE_USER_EMAIL";
export const GET_USERS = 'GET_USERS'; 