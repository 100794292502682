import React, {useState, useEffect, useRef} from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import {useStyles, AntTab, AntTabs} from './equipmentInfo.styles';
import EquipmentForm from './equipmentForm/equipmentForm.component';
import LocationLog from './locationLog/locationlog.component';
import MasterButton from '../../utilities/button.component';
import {getEquipment, updateEquipment, updateEquipmentBadge} from '../../../actions/equipment/equipmentActions';
import {getEquipmentLocationLogsPagination, getEquipmentLocationLogs} from '../../../actions/equipment/equipmentActions';
import {createLog} from '../../../actions/logs/logsActions';
import AwesomeSpinner from '../../utilities/spinner';
import MasterAlert from '../../utilities/alert.component';
import { exportCSVFile } from '../../utilities/exportCSV';
import * as CONST  from './equipmentInfo.constants';

function EquipmentInfo (props){
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(false);
    const [tabValue, setTabValue] = useState('a');
    const [showSpinner, setShowSpinner] = useState(false);
    const [locationLogSpinner, setLocationLogSpinner] = useState(true);
    const [equipment, setEquipment] = useState({
        name: props.equipmentSelected.name,
        deviceCategory: props.equipmentSelected.device_category,
        UUID: props.equipmentSelected.UUID,
        major: props.equipmentSelected.major,
        minor: props.equipmentSelected.minor,
        controlNum: props.equipmentSelected.control_number,
        tagNumber: props.equipmentSelected.beacon_number,
        model: props.equipmentSelected.model,
        maker: props.equipmentSelected.maker,
        team: props.equipmentSelected.team,
        department: props.equipmentSelected.department,
        id: props.equipmentSelected.id,
        creationDate: props.equipmentSelected.creationDate,
        modificationDate: props.equipmentSelected.modificationDate,
        trackingId: props.equipmentSelected.trackingId
    });
    const [badgeInfo, setBadgeInfo] = useState({
      UUID: props.equipmentSelected.UUID,
      major: props.equipmentSelected.major,
      minor: props.equipmentSelected.minor,
    });
    const [formError, setFormError] = useState({
        error: false,
        deviceCategory: '',
        controlNum: '',
        tagNumber: '',
        model: '',
        maker: '',
        team: '',
        department: '',
        trackingId: ''
    });
    const [snack, setSnack] = useState({
      msg: '',
      severity: CONST.SUCCESS,
      open: false
    });


    const mounted = useRef();
    useEffect(() => {
      if (!mounted.current) {
        const {campusId, equipmentSelected: {UUID, major, minor} } = props;
          props.getEquipmentLocationLogsPagination(campusId, UUID, major, minor, 0, 5)
          .then(resp=>{
            setLocationLogSpinner(false);
          });
          mounted.current = true;
      }
    },[]);

    const changeTab = (e, newValue)=>{
       setTabValue(newValue);
    };

    const handleInputChange = (newValue,key) =>{
      if (key === CONST.DEVICE_CATEGORY) {
        let device = props.equipmentCategories.find(category => category.name === newValue);
        setEquipment({...equipment, [key]: device.id});
      } else if (key === CONST.TEAM) {
        let team = props.roles.find(category => category.role === newValue);
        setEquipment({...equipment, [key]: team._id});
      } else {
        setEquipment({...equipment, [key]: newValue});
      }
      if (newValue === '') {
        setFormError({...formError, [key]: CONST.EMPTY_FIELD_MSG});
      }
      if (newValue !== '') {
        setFormError({...formError, [key]: ''});
      }  
    };

    const handleSubmit = ()=>{
      let error = validateForm();
      if(!error){
        setShowSpinner(true);
        editEquipment();
      }
    };

    const validateForm = () =>{
      let keys = CONST.KEYS;
      let formErrors={
        error: false,
        deviceCategory: '',
        controlNum: '',
        tagNumber: '',
        model: '',
        maker: '',
        team: '',
        department: '',
        trackingId: ''
      };

      keys.map(key=>{
        if(equipment[key] === '') {
          formErrors.error = true;
          formErrors[key]= CONST.EMPTY_FIELD_MSG;
        }
      });

      setFormError({
        error: formErrors.error,
        deviceCategory: formErrors.deviceCategory,
        controlNum: formErrors.controlNum,
        tagNumber: formErrors.tagNumber,
        model: formErrors.model,
        maker: formErrors.maker,
        team: formErrors.team,
        department: formErrors.department,
        trackingId: formError.trackingId
      });

      return formErrors.error;
    };

    const editEquipment = ()=>{
      let device = props.equipmentCategories.find(category => category.id === equipment.deviceCategory);
      let equipmentInfo = {
        device_category: equipment.deviceCategory,
        control_number: equipment.controlNum,
        UUID: equipment.UUID,
        major: equipment.major,
        minor: equipment.minor,
        beacon_number: equipment.tagNumber,
        model: equipment.model,
        maker: equipment.maker,
        team: equipment.team,
        department: equipment.department,
        campusId: props.campusId,
        id: equipment.id,
        creationDate: equipment.creationDate,
        modificationDate: new Date(),
        modifiedBy: props.userId,
        trackingId: equipment.trackingId
      };
      props.updateEquipment(equipmentInfo).then(resp=>{
        props.updateEquipmentBadge(equipmentInfo,badgeInfo);
        createEquipmentLog({
          action:CONST.UPDATE_ACTION,
          description:`Equipment "${equipmentInfo.id}" updated by "${props.user.name}"`});
        setShowSpinner(false);
        setIsEdit(false);
        setEquipment({...equipment, name: device.name});
        let msg= <div className={classes.inlineContainer}>{CONST.UPDATE_MSG}</div>
        props.getEquipment(props.campusId);
        handleOpenSnackbar(CONST.SUCCESS, msg);
      }).catch(error=>{
        console.error("[EQUIPMENTINFO] An error ocurred in updateEquipment:", error)
        createEquipmentLog({
          action:CONST.UPDATE_ACTION_ERROR,
          description:`Equipment "${equipmentInfo.id}" couldn't be updated by "${props.user.name}" due endpoint ${error}`});
        setShowSpinner(false);
        let msg= <div className={classes.inlineContainer}>{CONST.UPDATE_MSG_ERROR}</div>
        handleOpenSnackbar(CONST.WARNING, msg);
      });
    };

    const createEquipmentLog = (data) =>{
      let log={
          campusId: props.campusId,
          action: data.action,
          description: data.description,
          timestamp: new Date(),
          userId: props.userId,
      };
      props.createLog(log);
    };

    const handleOpenSnackbar = (type, ms) =>{
      setSnack({open: true, msg: ms, severity: type});
    };

    const handleCloseSnackbar = () =>{
      setSnack({open: false, msg: '', severity: CONST.SUCCESS});
    };

    const generateCSV = (data)=>{
      let itemsCopy = data;
      let headers = Object.keys(data[0]);
      let fileTitle = `${props.equipmentSelected.id}-History`
      exportCSVFile(headers, itemsCopy, fileTitle, CONST.CSV_CONFIGS);
    };

    const handlePagination=(page,rows)=>{
      setLocationLogSpinner(true);
      const {campusId, equipmentSelected: {UUID, major, minor} } = props;
      props.getEquipmentLocationLogsPagination(campusId, UUID, major, minor, page, rows)
      .then(resp=>{
        setLocationLogSpinner(false);
      });
    };

    const handleExportLocationLogs=()=>{
      setLocationLogSpinner(true);
      const { campusId, equipmentSelected:{UUID, major, minor} } = props;
      props.getEquipmentLocationLogs(campusId, UUID, major, minor)
      .then(resp=>{
        setLocationLogSpinner(false);
        generateCSV(resp);
      });
    };

    const handleShowSpinner=()=>{
      setLocationLogSpinner(true);
    };

    return(
      <div id="webNavv-tableView-equipment-equipmentDetails-1.0">
        <div id="webNavv-tableView-equipment-equipmentDetails-returnToTable-1.0" className={props.open? classes.backOpen : classes.back} onClick={()=>props.handleHideForm()}>{CONST.BACK_MSG}</div>
        <div className={props.open? classes.goToMapOpen : classes.goToMap}>
            <MasterButton
              id="webNavv-tableView-equipment-equipmentDetails-findOnMapButton-1.0"
              isDisabled={props.equipmentHasLocation? false:true}
              keyLbl={'find-equipment'}
              styles={{width:'180px'}}
              label={'Find on map'}
              class={classes.containedBtn}
              buttonType={'success'}
              handleClick={()=>props.handleEquipmentLocation(equipment.id)} />
        </div>
        <div className={props.open? classes.containerOpen : classes.container}>
          <div className={classes.mainDiv}>
              <Grid container spacing={formError.error? 2 : 4} justify={"center"} alignItems={"center"}>
                  <Grid item xs={12}>
                        <div style={{display:'flex'}}>
                          <div className={classes.titleContainer}>
                          <ImageIcon className={classes.iconTitle}/>
                        </div>
                        <div  className={classes.titleLbl}>
                          <div className={classes.equipmentName}>{equipment.name}</div>
                          <div style={{display:'block'}}>
                            <div className={classes.equipmentSubtitle}>{CONST.TAG_NUMBER}</div>
                            <div className={classes.equipmentValue}>{equipment.tagNumber}</div>
                          </div>
                          <div style={{display:'block'}}>
                            <div className={classes.equipmentSubtitle}>{CONST.LOCATION}</div>
                            <div className={classes.equipmentValue} style={{fontWeight: 'bold', color: props.equipmentSelected.floorName[1]}}>{props.equipmentSelected.floorName[0]}</div>
                          </div>
                        </div>
                        {!isEdit && tabValue === 'a' &&
                          (<div className={classes.btnDivEdit}>
                            <MasterButton
                             id="webNavv-tableView-equipment-equipmentDetails-editEquipmentButton-1.0"
                             keyLbl={'edit-historyEquipment'}
                             styles={{marginLeft: '5px', width:'120px'}}
                             label={'Edit'}
                             class={classes.containedBtn}
                             buttonType={'success'}
                             handleClick={() => setIsEdit(true)} />
                          </div>)
                        }
                        {isEdit && tabValue === 'a' &&
                        (<div className={classes.btnDiv}>
                            <React.Fragment>
                              <MasterButton
                                id="webNavv-tableView-equipment-equipmentDetails-cancelButton-1.0"
                                keyLbl={'cancel-historyEquipment'}
                                label={'Cancel'}
                                buttonType={'default-flat'}
                                handleClick={() => props.handleHideForm()} />
                              <MasterButton
                                id="webNavv-tableView-equipment-equipmentDetails-saveButton-1.0"
                                keyLbl={'submit-historyEquipment'}
                                styles={{marginLeft: '10px'}}
                                label={'Save'}
                                class={classes.containedBtn}
                                buttonType={'success'}
                                handleClick={() => handleSubmit()} />
                            </React.Fragment>
                          </div>
                        )}
                        {tabValue === 'b'  &&
                          (<div className={classes.btnDivEdit}>
                            <MasterButton
                             id="webNavv-tableView-equipment-equipmentDetails-exportButton-1.0"
                             keyLbl={'export-historyEquipment'}
                             styles={{marginLeft: '5px', width:'120px'}}
                             label={'Export'}
                             class={classes.containedBtn}
                             buttonType={'success'}
                             handleClick={handleExportLocationLogs}
                             isDisabled={!!props.equipmentHistoryPagination && !!props.equipmentHistoryPagination[0] ? false : true } />
                          </div>)
                        }
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                   <AntTabs
                      value={tabValue}
                      onChange={changeTab}
                      inkBarStyle={{background: 'blue'}}>
                      <AntTab
                       id="webNavv-tableView-equipment-equipmentDetails-equipmentInfoTab-1.0"
                        style={{fontSize:'12px'}}
                        value='a'
                        label="Equipment Info"
                        index={0}
                        aria-label="equipmentInfo" />
                      <AntTab
                        id="webNavv-tableView-equipment-equipmentDetails-locationLogTab-1.0"
                        style={{fontSize:'12px'}}
                        value='b'
                        label="Location Log"
                        index={1}
                        aria-label="locationLog" />
                      {/* <AntTab style={{fontSize:'12px'}} value='c' label="Comments" index={1} aria-label="comments" /> */}
                    </AntTabs>
                  </Grid>
                  {tabValue === 'a' && (
                    <EquipmentForm
                      isEdit={isEdit}
                      formError={formError}
                      equipment={equipment}
                      handleInputChange={handleInputChange}
                      equipmentCategories={props.equipmentCategories}
                      roles={props.roles}
                    />
                  )}
                  {tabValue === 'b' && (
                    <LocationLog
                      equipmentHistoryPagination={props.equipmentHistoryPagination}
                      equipmentHistoryPaginationTotal={props.equipmentHistoryPaginationTotal}
                      handlePagination={handlePagination}
                      handleShowSpinner={handleShowSpinner}
                      showSpinner={locationLogSpinner}
                      open={props.open}
                      mapConfigs={props.mapConfigs}
                    />
                  )}
                  {/* {tabValue === 'c' &&(
                    <div></div>
                  )} */}
              </Grid>
          </div>
      </div>
      {/* <div className={classes.container}></div> */}
      <AwesomeSpinner config={'default'} loading={showSpinner} navOpen={props.open}/>
      <MasterAlert
          snackStyle={{marginTop:'5vh'}}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          openSnack={snack.open}
          handleClose={handleCloseSnackbar}
          alertStyle={{backgroundColor:'#2843A3', borderBottom:'7px solid #B5C5FF'}}
          variant={"filled"}
          type={snack.severity}
          message={snack.msg}
         />
      </div>
    );
};

const mapStateToProps = state => ({
    equipmentCategories: state.equipments.equipmentCategories,
    roles: state.acl.acls,
    campusId: state.auth.userInfo.campusId,
    open:state.views.drawerOpen,
    userId: state.auth.userId,
    user: state.user.user,
    equipmentHistory: state.equipments.equipmentHistory,
    equipmentHistoryPagination: state.equipments.equipmentHistoryPagination,
    equipmentHistoryPaginationTotal: state.equipments.equipmentHistoryPaginationTotal,
    mapConfigs: state.vies.mapConfigs
});
export default connect(mapStateToProps,{
  getEquipment,
  updateEquipment,
  updateEquipmentBadge,
  getEquipmentLocationLogs,
  getEquipmentLocationLogsPagination,
  createLog})(EquipmentInfo);