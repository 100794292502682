import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import PropTypes from 'prop-types';
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import SendIcon from 'material-ui/svg-icons/content/send';
import Dialog from 'material-ui/Dialog';
import MinimizeIcon from '@material-ui/icons/Minimize';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';

import {
    ChatBubble,
    ChatBubbleContainer,
    PopOverItem,
    lightTheme
} from 'amazon-chime-sdk-component-library-react';

import { useChatChannelState, useChatMessagingState } from '../../../Providers/ChatMessagesProvider';

import {
    Persistence,
    MessageType,
    sendChannelMessage,
    getChannelMessage,
    listChannelMembershipsForAppInstanceUser,
    listChannels,
    listChannelMessages,
    listChannelMemberships
} from '../../../api/ChimeAPI';

import { useAuthContext } from '../../../Providers/AuthProvider';

import appConfig from '../../../aws-config/Config';

import mergeArrayOfObjects from '../../../utilities/mergeArrays';

import moment from "moment";

//import debounce from 'lodash/debounce';

function ChatWindowChime(props) {
    const classes = useStyles();
    const { socket } = props;
    //const [messages, setMessages] = useState([]);
    const [open, setOpen] = useState(true);
    const [typing, setTyping] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogError, setDialogError] = useState('');
    const [memberDestiny, setMemberDestiny] = useState({ 
        userName: 'Chime User',
        arn: null
    });

    const {
        messages,
        setMessages,
    } = useChatMessagingState();

    const {
        activeChannel,
        channelList,
        setChannelList,
        setActiveChannel,
        typingIndicator,
        setTypingIndicator,
    } = useChatChannelState();

    const { member } = useAuthContext();

    const { userId } = useAuthContext().member;

    let el;

    const bottomRef = useRef(null);

    const flexStyles = `
    display: flex; 
    flex-direction: column;
    width: 100%;
  `;

    const bubbleContainerStyles = `
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    width: inherit;
    background-color: transparent;
   `;

    const bubbleStyles = `
    max-width: max-content;
    line-height: normal;
    text-align: left;
  `;

    const editBubbleStyles = `
    max-width: max-content;
    line-height: normal;
    text-align: left;
    padding: 5px;
  `;

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        if (userId) {
            const fetchChannels = async () => {
                try {
                    const userChannelMemberships = await listChannelMembershipsForAppInstanceUser(
                        userId
                    );
                    const userChannelList = userChannelMemberships.map(
                        (channelMembership) => channelMembership.ChannelSummary
                    );
                    const publicChannels = await listChannels(
                        appConfig.appInstanceArn,
                        userId
                    );

                    const channelList = mergeArrayOfObjects(userChannelList, publicChannels, 'ChannelArn');


                    const getActiveChannel = !!channelList.length ? channelList[0] : null;

                    let getMessages = [];
                    let memberList = null;

                    if (!!getActiveChannel) {
                        getMessages = await listChannelMessages(
                            getActiveChannel.ChannelArn,
                            userId
                        );
                        memberList = await listChannelMemberships(
                            getActiveChannel.ChannelArn,
                            userId
                        );
                    }

                    setChannelList(channelList);
                    if (!!getActiveChannel) {
                        setActiveChannel(getActiveChannel);
                    }

                    if (!!getMessages.Messages.length) {
                        setMessages([...getMessages.Messages]);
                    }


                    if (!!memberList && memberList.length > 0) {
                        const destiny =  memberList.filter ((item) => item.Member.Name !== props.userInfo.userName);
                        if (destiny.length > 0) {
                            setMemberDestiny({
                                userName: destiny[0].Member.Name,
                                arn: destiny[0].Member.Arn
                            })
                        }
                    }
                }
                catch (e) {
                    console.log(`Error getting channel for authenticated user with id: ${userId}`);
                }

                //await publishStatusToAllChannels();
            };
            fetchChannels();

        }
    }, []);


    useEffect(() => {
        scrollToBottom();
        const lastMessage = messages[messages.length-1];
        console.log('last message');
    }, [messages]);

    useEffect(() => {
        scrollToBottom();
        if (typingIndicator) {
          (async function x() {
            setTimeout(() => {
              setTypingIndicator(null);
            }, 3000)
          })();
        }
      }, [typingIndicator]);

    const getMessagesChime = () => {
        const messageItems = [];

        if (messages.length > 0) {
            messages.forEach((msg, index) => {
                const dateInfo = moment(msg.CreatedTimestamp).format("MM/DD/YYYY LT");
                if (msg.Sender.Name === props.userInfo.userName) {
                    messageItems.push(
                        <ChatBubbleContainer
                            actions={null}
                            key={`msg-${index}`}
                            css={bubbleContainerStyles}
                        >
                            <ChatBubble
                                variant="outgoing"
                                senderName={props.userInfo.userName}
                                timestamp={dateInfo}
                                showName={false}
                                showTail={true}
                                css={bubbleStyles}
                            >
                                {msg.Content}
                            </ChatBubble>
                        </ChatBubbleContainer>
                    );
                }
                else {
                    messageItems.push(
                        <ChatBubbleContainer
                            actions={null}
                            key={`msg-${index}`}
                            css={bubbleContainerStyles}
                        >
                            <ChatBubble
                                variant="incoming"
                                senderName={msg.Sender.Name}
                                timestamp={dateInfo}
                                showName={false}
                                showTail={true}
                                css={bubbleStyles}
                            >
                                {msg.Content}
                            </ChatBubble>
                        </ChatBubbleContainer>
                    );
                }
            });

        }

        return messageItems
    }

    const getHeader = () => {
        if (open)
            return (
                <div className={classes.chatAction} >
                    <div
                        id="webNavv-navvChat-expanded-header-minimize-1.0"
                        onClick={() => { toggleClass(); }}
                    >
                        <MinimizeIcon style={{ fontSize: 15 }} />
                    </div>
                    <div
                        id="webNavv-navvChat-expanded-header-close-1.0"
                        className={classes.closeAction}
                        onClick={() => { removeChat(); }}
                    >
                        <CloseIcon style={{ fontSize: 15 }} />
                    </div>
                </div>
            );
        else
            return (
                <div className={classes.chatAction} >
                    <div
                        id="webNavv-navvChat-expanded-header-launch-1.0"
                        onClick={() => { toggleClass(); }}
                    >
                        <LaunchIcon style={{ fontSize: 15 }} />
                    </div>
                    <div
                        id="webNavv-navvChat-expanded-header-close-1.0"
                        className={classes.closeAction}
                        onClick={() => { removeChat(); }}
                    >
                        <CloseIcon style={{ fontSize: 15 }} />
                    </div>
                </div>
            );
    }

    const toggleClass = () => {
        setOpen(!open);
    }

    const removeChat = () => {
        socket.emit('set message read', props.chat.destiny);
        props.removeChat(props.chat.destiny);
    };

    const handleRenderTyping = () => {
        return (
            <div
                id="webNavv-navvChat-expanded-messages-typing-1.0"
                className={classes.typing}
            >
                Typing...
            </div>
        );
    };

    const enterPressed =  async (event) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            sendChatMessage();
        }
        else {
            if (!!activeChannel && !!activeChannel.ChannelArn) {
                const { ChannelArn } = activeChannel;
                await sendChannelMessage(
                    ChannelArn,
                    'Typing',
                    Persistence.NON_PERSISTENT,
                    MessageType.CONTROL,
                    member
                )
                
                
                /*
                debounce(async () => {
                    await sendChannelMessage(
                        ChannelArn,
                        'Typing',
                        Persistence.NON_PERSISTENT,
                        MessageType.CONTROL,
                        member
                    )
                }, 300);
                */

                console.log('all ok');
            }
        }
    };

    const sendChatMessage = async () => {
        if (!!activeChannel) {
            const { ChannelArn } = activeChannel;

            let sendMessageResponse;
            sendMessageResponse = await sendChannelMessage(ChannelArn, currentMessage, Persistence.PERSISTENT, MessageType.STANDARD, member);
            resetState();
            if (sendMessageResponse.response.Status == 'PENDING') {
                const sentMessage = await getChannelMessage(ChannelArn, member, sendMessageResponse.response.MessageId);
                const newMessages = [...messages, sentMessage];
                setMessages(newMessages);
            }
        }
    }

    const resetState = () => {
        setCurrentMessage('');
    };

    const sendMessageByChannel = (msg) => {
        //Here the code to send message by the channel using Chime
    }

    const actions = (
        <PopOverItem
            onClick={() => console.log('Option 1 clicked')}
            children={<span>Option 1</span>}
        />
    );

    return (
        <ThemeProvider theme={lightTheme}>
            <div
                className={classes.chatWindow}
                id="webNavv-navvChat-expanded-1.0"
            >
                <div
                    className={classes.chatNormal}
                    id="webNavv-navvChat-expanded-header-1.0"
                >
                    <p id="webNavv-navvChat-expanded-header-title-1.0">
                        {memberDestiny.userName}
                    </p>
                    {getHeader()}
                </div>
                <div
                    id="webNavv-navvChat-expanded-messages-1.0"
                    className={open ? classes.chatOpen : classes.chatClosed}
                >
                    <div
                        id="webNavv-navvChat-expanded-messages-history-1.0"
                        className={classes.messageView}
                    >
                        {getMessagesChime()}
                        {typingIndicator && handleRenderTyping()}
                        <div ref={bottomRef} />
                    </div>
                    <div className={classes.messageBox}>
                        <div className={clsx(classes.searchWrapper, classes.chatWindowSize)}>
                            <input
                                id="webNavv-navvChat-expanded-messages-editor-1.0"
                                placeholder="Type Message"
                                type="text"
                                className={classes.input}
                                onChange={(input) => {
                                    setCurrentMessage(input.target.value);
                                }
                                }
                                value={currentMessage}
                                onKeyPress={(event) => {
                                    enterPressed(event);
                                }
                                }
                            />
                        </div>
                        <div
                            id="webNavv-navvChat-expanded-messages-sender-1.0"
                            className={classes.messageSendButton}
                            onClick={() => {
                                sendChatMessage()
                            }
                            }
                        >
                            <SendIcon />
                        </div>
                    </div>
                </div>
                <Dialog
                    title="Session Terminated"
                    actions={actions}
                    modal={true}
                    open={dialogOpen}
                >
                    {dialogError}
                </Dialog>
            </div >
        </ThemeProvider>
    );
};

const useStyles = makeStyles((theme) => ({
    chatWindow: {
        backgroundColor: '#FFF',
        width: 264,
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2)',
        alignSelf: 'end',
        direction: 'ltr',
        borderRadius: '10px 10px 0px 0px'
    },
    chatOpen: {
        height: 448,
        overflowY: 'auto'
    },
    chatClosed: {
        display: 'none',
    },
    chatHeader: {
        height: 48,
        backgroundColor: '#2843A3',
        color: '#FFF',
        textAlign: 'left',
        paddingTop: 1,
        paddingLeft: 16,
        fontSize: 16,
        lineHeight: 24,
        borderBottom: '1px solid rgba(0,0,0,0.2)'
    },
    chatNormal: {
        height: 48,
        backgroundColor: '#2843A3',
        color: '#FFF',
        fontWeight: 'bold',
        textAlign: 'left',
        borderRadius: '10px 10px 0px 0px',
        fontSize: 16,
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        display: 'grid',
        gridTemplateColumns: '190px 40px',
        paddingLeft: 16
    },
    closeAction: {
        marginLeft: 10
    },
    chatAction: {
        display: 'flex',
        cursor: 'pointer',
        paddingTop: 15
    },
    messageView: {
        backgroundColor: '#FFF',
        height: 390,
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        overflowY: 'auto',
        paddingTop: 10,
        paddingRight: 5,
    },
    messageBox: {
        backgroundColor: '#FFF',
        height: 32,
        padding: 8,
        position: 'fixed',
        bottom: 0
    },
    bubbleContainer: {
        bottom: 20
    },
    messageBubble: {
        minWidth: 40,
        maxWidth: 200,
        bordeRadius: '10px!important',
        padding: 10,
        overflowWrap: 'break-word',
        marginBottom: 8,
    },
    messageSent: {
        marginLeft: 30,
        backgroundColor: '#2843A3',
        color: '#FFF',
        position: 'relative',
        borderRadius: 25,
        "&:before": {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 180,
            width: 0,
            borderTop: '7px solid #2843A3',
            borderLeft: '7px solid transparent',
            borderright: '7px solid transparent',
        }
    },
    messageGot: {
        marginLeft: 5,
        color: '#000',
        backgroundColor: '#F4F4FF',
        position: 'relative',
        borderRadius: 25,
        "&:before": {
            content: '""',
            position: 'absolute',
            top: '100%',
            left: 20,
            width: 0,
            borderTop: '7px solid #F4F4FF',
            borderLeft: '7px solid transparent',
            borderright: '7px solid transparent',
        }
    },
    timeGot: {
        fontSize: 11,
        marginBottom: 8,
        textAlign: 'left',
        marginLeft: 10,
        color: 'gray',
    },
    timeSent: {
        fontSize: 11,
        marginBottom: 8,
        textAlign: 'right',
        marginRight: 8,
        color: 'gray'
    },
    typing: {
        fontSize: 11,
        marginBottom: 8,
        textAlign: 'right',
        marginRight: 8,
        color: 'gray',
        float: 'left',
        marginLeft: 10
    },
    noMessages: {
        paddingTop: 30,
        width: '100%',
        textAlign: 'center',
        color: 'gray',
    },
    unconfirmed: {
        opacity: 0.5,
    },
    searchBox: {
        backgroundColor: '#FFF',
        height: 32,
        padding: 8,
        borderBottom: '1px solid rgba(0,0,0,0.2)',
    },
    searchInput: {
        backgroundColor: 'rgba(0,0,0,0.04)',
        width: 246,
        height: '100%',
        float: 'left'
    },
    messageInput: {
        backgroundColor: 'rgba(0,0,0,0.04)',
        width: 200,
        height: '115%',
        float: 'left',
    },
    messageSendButton: {
        backgroundColor: 'rgba(0,0,0,0.0)',
        width: 30,
        height: '91%',
        float: 'left',
        marginLeft: 10,
        paddingLeft: 6.8,
        paddingTop: 7,
        cursor: 'pointer'
    },
    searchWrapper: {
        width: 190,
        float: 'left',
        height: 36,
        backgroundColor: '#f4f4ff',
        borderRadius: 10,
        color: '#000',
        display: 'flex',
        alignItems: 'center'
    },
    chatWindowSize: {
        paddingLeft: 10
    },
    input: {
        backgroundColor: 'transparent',
        border: 'none',
        margin: 0,
        height: 30,
        "&:focus": {
            outline: 'none'
        }
    }
}));

ChatWindowChime.propTypes = {
    userId: PropTypes.string.isRequired,
    access_token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    userId: state.auth.userId,
    userInfo: state.auth.userInfo,
    access_token: state.auth.token,
});

export default connect(mapStateToProps, {})(ChatWindowChime);