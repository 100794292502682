import React, { useEffect, useState } from "react";

import { useFlags } from "../firebase/firebaseProvider";
import { ascii_to_hexa } from "../utilities/asciiToHex";
import { remoteConfigEnabled } from "../firebase/remoteConfigConstants";
import _ from "lodash";

const RemoteConfig = (props) => {

    const [storeUpdated, setStoreUpdated] = useState(false);
    const [remoteConfigAvailable, setRemoteConfigAvailable] = useState(false);

    const hostname = document.location.hostname;
    let hexHostname = ascii_to_hexa(hostname);
    const ffEnv = `FF_${hexHostname}`;

    const remoteConfig = useFlags();
    let firebaseRemoteConfig = {};

    if (!_.isEmpty(remoteConfig)) {
        try {
            firebaseRemoteConfig = JSON.parse(remoteConfig[ffEnv]);
            if (!remoteConfigAvailable) {
                setRemoteConfigAvailable(true);
            }
        } catch (error) {
            // FF NOT AVAILABLE
        }
    }

    useEffect(() => {
        if (firebaseRemoteConfig.hasOwnProperty('FeatureFlagsEnabled')) {
            handleFirebaseRemoteConfigs();
        }
    }, []);

    useEffect(() => {
        if (typeof remoteConfig[ffEnv] === 'string') {
            if (remoteConfigAvailable && !storeUpdated && firebaseRemoteConfig.hasOwnProperty('FeatureFlagsEnabled')) {
                handleFirebaseRemoteConfigs();
            }
        }

    }, [remoteConfigAvailable]);

    const handleFirebaseRemoteConfigs = () => {
        let featureFlags = {
            "SpecimenCollection": {
                "JobsBoard": false,
                "JobsHistory": false,
                "Permission": false,
                "AddJobs": false,
                "ExportTraces": false
            },
            "Equipment": {
                "Permission": false,
                "MapFilter": false,
                "TableViewTable": false,
                "AdminTable": false,
                "EquipmentCategories": false
            },
            "Chat": {
                "ChatWindow": false,
                "ChatWindowChime": false,
            },
            "Superadmin": {
                "Dashboard": false,
                "Statistics": false,
                "Users": false,
                "DebugTool": false,
                "Logs": false
            }
        }

        let flagCategories = Object.keys(featureFlags);
        const adminData = localStorage.getItem('adminInfo');
        const adminInfo = !!adminData ? JSON.parse(adminData) : null;

        flagCategories.forEach(category => {
            if (featureFlags.hasOwnProperty(category)) {
                let flagSubCategories = Object.keys(featureFlags[category]);
                flagSubCategories.forEach(subCategory => {
                    let featureFlag = firebaseRemoteConfig.FeatureFlagsEnabled[remoteConfigEnabled[category][subCategory]];
                    if (!!featureFlag) {
                        const usersWithAccess = featureFlag.username;
                        featureFlags[category][subCategory] = (!!adminInfo && usersWithAccess.includes(adminInfo.username)) || usersWithAccess.includes('*');
                    }
                });
            }
        });

        // Store value in Redux !!! 
        props.setFeatureFlags(featureFlags);
        setStoreUpdated(true);
    }

    return null;
}

export default RemoteConfig;