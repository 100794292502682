import { POST_JOB,DAY_JOBS, ERROR, CLEAR_JOBS } from './types';

import axios from 'axios';
import axiosRetry from 'axios-retry';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

axiosRetry(axios, {
    retries: 3,
    retryDelay: (count) => {
      console.info(`[JOBS ACTIONS] numbers of attempts: ${count}`);
      return count * 2000;
    }
  });

export const postJob = (data) => (dispatch, getState) => {
    try{
        axios.post(`${secureUrl.hostUrl}/api/jobs?access_token=${getState().auth.token}`,data).then(resp=>{
            dispatch({
                type:POST_JOB
            })
        })
    }catch(error){
        console.error("[JOBS ACTIONS] An error ocurred in postJob: ", error);
        dispatch({
            type:ERROR,
            payload:{
                dialogError:'Unknown Error: ['+error.response.data+']'
            }
        })
    }
}

export const dayJobs = () => (dispatch, getState) => {
  return new Promise ((resolve, reject)=>{
      try{
        axios.get(`${secureUrl.hostUrl}/api/jobs/dayJobs?campusId=${encodeURIComponent(getState().auth.userInfo.campusId)}&access_token=${getState().auth.token}`)
        .then(resp=>{
            dispatch({
                type:DAY_JOBS,
                payload:resp.data
            });
            resolve(resp);
        })
      }catch(error){
        console.error("[JOBS ACTIONS] An error ocurred in dayJobs: ", error);
        reject(error);
      }
  });
}

export const getJobByTranporter = (transporterId) => (dispatch, getState) =>{
    return new Promise ((resolve, reject)=>{
        try{
          axios.get(`${secureUrl.hostUrl}/api/jobs?filter={"where":{"transporter":"${transporterId}"}}&access_token=${getState().auth.token}`)
          .then(resp=>{
              resolve(resp);
          })
        }catch(error){
          console.error("[JOBS ACTIONS] An error ocurred in getJobByTranporter: ", error);
          reject(error);
        }
    });
}

export const clearJobs = () => dispatch => {
    dispatch({
        type:CLEAR_JOBS
    })
}