import moment from "moment";
import _ from 'lodash';
const handleDefaultOrdinar=(ordinal)=>{
    if(ordinal==="outside"){
        return -100
    }
    return ordinal;
}
export const renderUserLocation=(renderUserLocationParams, handleSwitchFloor, handleFilterUsersByFloor)=>{
    const {message, viewProps, users, auth,activeUser, transporterFound, transporterPhone,activeFloor, traces, allCallouts, smallCallouts, indoorOnly, mapkit, mapLoaded} = renderUserLocationParams;
    if (traces) {
        //Remove callout and smallCallout annotations
        !!mapkit && mapkit.removeAllLocationCalloutAnnotations();
    } else {
        //Remove all callouts
        !!mapkit && mapkit.removeAllLocationTypeAnnotations();
    }

    let locationsList = [];
    let locationKeys = Object.keys(message);

    locationKeys.forEach(location=>{
        let firstName = "";
        let lastName = "";
        let userFloor = message[location].frl === "outside" || message[location].frl === null ? handleDefaultOrdinar(message[location].frl) : parseInt(message[location].frl);
        let contactUser= false;
        let deviceTag = message[location].dId;
        let userId = message[location].userId;
        let locationUser = users.find(u=> !!u && (userId === u._id || userId === u.id) ) || null;

        if (!!locationUser && auth.contact.includes(locationUser.role)) {
            firstName = locationUser.first_name;
            lastName = locationUser.last_name;
            contactUser = true;
        }

        if (contactUser && deviceTag !== 'desktop' && !!locationUser && locationUser.role_name !== 'Admin') {
            let annotationId = userId+deviceTag;
            let newDate= moment(message[location].cDate).format("MM/DD/YYYY HH:mm:ss");
            
            const locationInfo = {
                longitude: parseFloat(message[location].lg),
                latitude: parseFloat(message[location].lt),
                geolocation_status: message[location].status,
                user_id: userId,
                id: annotationId,
                status: null, //message.sCode,
                floor: handleDefaultOrdinar(userFloor),
                firstName: firstName,
                lastName: lastName,
                calloutDate: newDate,
                type:'geolocation',
                assetTag: deviceTag,
                trackDevice: false
            }
            const handleMapInteractionTypeParameters = {
                locationInfo,
                activeUser,
                transporterFound,
                transporterPhone,
                viewProps,
                activeFloor,
                traces,
                allCallouts,
                smallCallouts,
                indoorOnly,
                mapkit,
                mapLoaded
            };

            let locationsWithFormat = handleMapInteractionType(handleMapInteractionTypeParameters, handleSwitchFloor, handleFilterUsersByFloor);
            if(!!locationsWithFormat) {
                locationsList.push(locationsWithFormat);
            }
        }
    });
    if(!!locationsList.length>0 && !!Object.values(locationsList[0]).length){
        mapkit.handleUserLocationMark(locationsList);
    }
}

const handleMapInteractionType=(handleMapInteractionTypeParameters, handleSwitchFloor, handleFilterUsersByFloor)=>{
    const {locationInfo, activeUser, transporterFound, transporterPhone, viewProps, activeFloor, traces, allCallouts, smallCallouts, indoorOnly, mapkit, mapLoaded} = handleMapInteractionTypeParameters;

    let trackType='none';
    let locationsWithFormat = {};
    const annotationId = transporterFound+transporterPhone;
    const isTrackUser = transporterFound !== undefined && transporterFound !== '' && transporterFound !== false? true:false;
    const userHasLocation = !!locationInfo.longitude && !!locationInfo.latitude && locationInfo.hasOwnProperty('floor')? true:false;

    const locationTrackingDevice = userHasLocation && isTrackUser && viewProps==='transporterPhone' && locationInfo.id === annotationId;
    const locationTrackingUser = userHasLocation && isTrackUser && viewProps!=='transporterPhone' && locationInfo.user_id === transporterFound;
    const locationDefaultScenario = userHasLocation && !activeUser;

    if (locationTrackingDevice || locationTrackingUser || locationDefaultScenario) {
        if(locationTrackingDevice){
            locationInfo['trackDevice'] = true;
            trackType='transporterPhone';
            handleSwitchFloor(locationInfo.floor);
        }else if(locationTrackingUser){
            trackType='transporter';
            handleSwitchFloor(locationInfo.floor);
        }else if (locationDefaultScenario) {
            if(viewProps==='transporterPhone' && locationInfo.id !== annotationId){
                locationInfo['trackDevice'] = true;
            }
        }

        let currentFloor = '';
        let locationWithFormat = {};
        let trackAnnotation = transporterFound+transporterPhone;
        if (activeFloor) {
            currentFloor = handleFilterUsersByFloor();
        }
    
        if (mapLoaded && !!mapkit){
            locationWithFormat={
                location: locationInfo,
                trackUserId: transporterFound,
                trackAnnotationId: trackAnnotation,
                trackType: trackType,
                showTraces: traces,
                activeFloor: [activeFloor,currentFloor],
                allCallouts: allCallouts,
                smallCallouts: smallCallouts,
                indoorOnly: indoorOnly
            };
        }
        locationsWithFormat = locationWithFormat;
    }

    return locationsWithFormat;
}