import { RESET,DIRECTIONS_ERROR, GET_SPAGHETTI,GETSPACES, CLEAR_SPAGHETTI } from './types';
import axios from 'axios';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const getSpaghetti = ({jobId, jobInfo, userId, startDate}) => (dispatch, getState) =>{
    return new Promise((resolve,reject)=>{
        try{
            axios.get(secureUrl.hostUrl + '/api/locations/getLocationsDay?jobId='+jobId+'&startDate='+startDate+'&access_token=' + getState().auth.token)
            .then(resp => {
                if (resp.data.locations.length > 0) {
                    dispatch({
                        type:GET_SPAGHETTI,
                        payload:{
                            traces:resp.data.locations
                        }
                    });
                    resolve(true)
                } else {
                    console.info('[DIRECTIONS ACTIONS] No Traces to show for this Job');
                    dispatch({
                        type:DIRECTIONS_ERROR,
                        payload:'No Traces to show for this Job',
                    })
                    reject(false);
                }
            })
        }catch(error){
            console.error("[DIRECTIONS ACTIONS] An error ocurred in getSpaghetti: ", error);
            dispatch({
                type:DIRECTIONS_ERROR,
                payload:"Couldn't get jog traces",
            })
            reject(false);
        }
    })

}

export const getJobHistoryTraces = (jobId, startDate, endDate) => (dispatch, getState) =>{
    console.log("[Job to show trace]", jobId, startDate, endDate)
    return new Promise((resolve,reject)=>{
        try{
            if (!!jobId && !!startDate && !!endDate) {
                axios.get(secureUrl.hostUrl + '/api/locations/getLocationsDayDates?jobId='+jobId+'&startDate='+startDate+'&endDate='+endDate+'&access_token=' + getState().auth.token)
                .then(resp => {
                    if (resp.data.locations.length > 0) {
                        dispatch({
                            type:GET_SPAGHETTI,
                            payload:{
                                traces:resp.data.locations
                            }
                        });
                        resolve(true)
                    } else {
                        console.info('[DIRECTIONS ACTIONS] No Traces to show for this Job History');
                        resolve(false);
                    }
                });
            }
            else{
                console.error("[DIRECTIONS ACTIONS] An parameter sent to getJobHistoryTraces is null");
                reject(false);
            }
        }catch(error){
            console.error("[DIRECTIONS ACTIONS] An error ocurred in getJobHistoryTraces: ", error);
            reject(false);
        }
    })
}


export const resetNewRoute = () => dispatch => {
    dispatch({
        type: RESET
    })
}

export const clearSpaghettiTrace = () => dispatch =>{
    dispatch({
        type: CLEAR_SPAGHETTI
    })
}