export const DEFAULT_SORT='asc';
export const FIRST_NAME='first_name';
export const DELETE_USER_ACTION='Delete User';
export const RESTORE_USER_ACTION='Restore User';
export const DELETE_USER_ACTION_ERROR='Delete User Error';
export const RESTORE_USER_ACTION_ERROR='Restore User Error';
export const SNACKBAR_SUCCESS='success';
export const SNACKBAR_WARNING='warning';
export const REFRESH_CHAT_CHANNEL='refreshChat';
export const DELETE='delete';
export const RESTORE='restore';
export const DATETIME="datetime-local";
export const DATETIME_FORMAT="MM/dd/yyyy hh:mm";
export const LASTLOGIN='Lastlogin';
export const STARTDATE='startDate';
export const TABLE_ID='UT';
export const TABLE_TITLE='Users';
export const DELETE_BTN='Delete';
export const RESTORE_BTN='Restore';
export const CANCEL_BTN='Cancel';
export const FILLED='filled';
export const SPINNER_CONFIG='default';
export const DIALOG_TITLE ="Are you sure?";
export const DIALOG_DELETE_MSG=`You are going to delete this row!`;
export const DIALOG_RESTORE_MSG=`You are going to restore this row!`;
export const ROWS_PER_PAGE=[10, 30, 50, 100];
export const TABLE_OPTIONS={
  filtertype:"dropdown",
  responsive:"standard",
  selectableRows:"single",
  filename: "Users.csv",
  separator: ";"
}
export const FILTER_TYPE={
  custom: 'custom'
}
export const TABLE_EVENTS={
  changeRowsPerPage:'changeRowsPerPage',
  propsUpdate:'propsUpdate',
  rowsSelect:'rowsSelect',
  search:'search',
  filterChange:'filterChange',
  onSearchClose:'onSearchClose',
  resetFilters:'resetFilters'
};
export const STRING_FILED_TYPE={
  name:{
    id: 'name',
    label: 'Name'
  },
  role:{
    id:'role',
    label:'Team'
  },
  email:{
    id:'email',
    label:'Email'
  }
};
export const DEFAULT_DATE_FORMAT="MM/DD/YYYY HH:mm";
export const COLUMNS={
  status:{
    id:'enabled',
    no: 0,
    label:'Status'
  },
  num:{
    id:'Num',
    no: 1,
    label:'Number'
  },
  name:{
    id:'name',
    no:2,
    label:'Name'
  },
  email:{
    id:'email',
    no:3,
    label:'Email'
  },
  role:{
    id:'role',
    no:4,
    label:'Team'
  },
  lastlogin:{
    id:'lastlogin',
    no:5,
    label:'Last Login'
  }
};

export const TABLE_SORT = (data, colIndex, order) => {
  if (colIndex === 0) {
    return data.sort((a, b) => (!!a.data[colIndex] && !b.data[colIndex] ? 1 : -1) * (order ? -1 : 1));
  } else if (colIndex === 3 || colIndex === 4) {
    return data.sort((a, b) =>(a.data[colIndex].toLowerCase() < b.data[colIndex].toLowerCase() ? -1 : 1) * (order? 1 : -1));
  } else if (colIndex === 2) {
    return data.sort((a, b) => {
      let strA = a.data[colIndex].replace(/\s/g, '');
      let strB = b.data[colIndex].replace(/\s/g, '');
      return (strA.toLowerCase() < strB.toLowerCase() ? -1 : 1) * (order? 1 : -1);
    });
  } else {
    return data.sort((a, b) => (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order? 1 : -1));
  }
};

export const CUSTOM_CSV_DOWNLOAD = (buildHead, buildBody, columns, data) => {
  let csvBody = [];
  let finalColumns = [];
  let lastLoginIndex = -1;
  let numIndex=-1;
  columns.map((column, i)=>{
    if(column.name===COLUMNS.num.id){
      numIndex=i;
    }
    if(column.name===COLUMNS.lastlogin.id){
      lastLoginIndex=i;
    }
    if(column.name!==COLUMNS.num.id){
      finalColumns.push(column);
    }
    return null;
  });
  data.map(p => {
    let csvData = [];
    for (let i = 0; i <=finalColumns.length; i++) {
      if (i === lastLoginIndex){
        csvData.push(p.data[i][1]);
      }else if(i !== numIndex){
        csvData.push(p.data[i]);
      }
    }
    csvBody.push({ data: csvData });
    return null;
  });
  return `${buildHead(finalColumns)}${buildBody(csvBody)}`.trim();
};
