import { CREATE_LOG, GET_LOGS, ERROR } from './types';

import axios from 'axios';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const createLog = (data) => (dispatch, getState) => {
    const token = getState().auth.token || getState().auth.tokenMK;
    try{
        axios.post(`${secureUrl.hostUrl}/api/logs?access_token=${token}`, data).then(resp => {
            dispatch({
                type: CREATE_LOG
            })
        })
    }catch(error){
        console.error("[LOGS ACTIONS] An error ocurred in createLog: ", error);
        dispatch({
            type: ERROR,
            payload: {
                dialogError: 'Unknown Error: [' + error.response.data + ']'
            }
        })
    }
}

export const getLogsByTimeWindow = (startDate, endDate, campusId) => (dispatch, getState) => {
    const token = getState().auth.token || getState().auth.tokenMK;

    return new Promise((resolve, reject) => {
        try{
            axios.get(secureUrl.hostUrl + '/api/logs/logsDateWindow?startDate=' + startDate + '&endDate=' + endDate + '&campusId=' + campusId + '&access_token=' + token).then(resp => {
                dispatch({
                    type: GET_LOGS,
                    payload: {
                        logs: resp.data.logs
                    }
                });
                resolve(resp.data.logs);
            })
        }catch(error){
            console.error("[LOGS ACTIONS] An error ocurred in getLogsByTimeWindow: ", error);
            dispatch({
                type: ERROR,
                payload: {
                    dialogError: 'Unknown Error: [' + error + ']'
                }
            });
            reject(error);
        }
    });
}

export const getLogs = (startDate, endDate, campusId) => (dispatch, getState) => {
    const token = getState().auth.token || getState().auth.tokenMK;

    return new Promise((resolve, reject) => {
        try{
            axios.get(secureUrl.hostUrl + `/api/logs?filter={"where":{"and": [{"timestamp": {"gte":"${startDate}"}},{"timestamp": {"lte":"${endDate}"}}]},"order":"timestamp DESC"}&access_token=${token}`).then(resp => {
                resolve(resp.data);
            })
        }catch(error){
            console.error("[LOGS ACTIONS] An error ocurred in getLogs: ", error);
            reject(error);
        }
    });
}