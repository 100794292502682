import React from "react";
import { jobStatusTypesGraphQL, ERROR_TASKS, STATUS_STYLES } from "../jobsBoard.constants";

import ErrorIcon from '@material-ui/icons/Error';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const checkIfJobHasErrors = (status) =>{
    return  status === jobStatusTypesGraphQL.error || status === jobStatusTypesGraphQL.completeWithError || status === jobStatusTypesGraphQL.inProgressWithError;
}

export const isJobComplete = (status) =>{
    return status === jobStatusTypesGraphQL.complete ||  status === jobStatusTypesGraphQL.completeWithError;
}

export const isJobInProgress = (status) =>{
    return status === jobStatusTypesGraphQL.inProgress || status === jobStatusTypesGraphQL.inProgressWithError;
}

export const isJobCancelled = (currentItem) =>{
    return currentItem.status === jobStatusTypesGraphQL.error &&  (currentItem.job_error_number === ERROR_TASKS.jobCancelled.key || currentItem.job_errors.includes(ERROR_TASKS.jobCancelled.key));
}

export const isJobDelayedButNotStarted = (currentItem) =>{
    return currentItem.status === jobStatusTypesGraphQL.error && currentItem.job_error_number === ERROR_TASKS.jobStartDelay.key;
}

export const handleErrorDescription = (errorNumber) =>{
    switch (errorNumber) {
        case ERROR_TASKS.scanOverride.key:
            return ERROR_TASKS.scanOverride.description;
        case ERROR_TASKS.locationSkipped.key:
            return ERROR_TASKS.locationSkipped.description;
        case ERROR_TASKS.jobCancelled.key:
            return ERROR_TASKS.jobCancelled.description;
        case ERROR_TASKS.jobStartDelay.key:
            return ERROR_TASKS.jobStartDelay.description;
        case ERROR_TASKS.scanError.key:
          return ERROR_TASKS.scanError.description;
        default:
            return errorNumber
    }
}

export const handleJobConfigurations = (status, classes) =>{
    switch(status){
      case jobStatusTypesGraphQL.inProgress:
          return [
            {
              label: STATUS_STYLES.inProgress.label,
              color: STATUS_STYLES.inProgress.color,
              progressBarClass: classes.JobInfo_inProgressProgressBar,
              chipColor: STATUS_STYLES.inProgress.backgroundColor,
              chipClass: classes.JobInfo_CardChipInProgress,
            },
            <PlayArrowIcon style={{fill: STATUS_STYLES.inProgress.color}}/>
          ]
        case jobStatusTypesGraphQL.inProgressWithError:
          return [
            {
              label: STATUS_STYLES.inProgressWithError.label,
              color: STATUS_STYLES.inProgressWithError.color,
              progressBarClass: classes.JobInfo_errorProgressBar,
              chipColor: STATUS_STYLES.inProgressWithError.backgroundColor,
              chipClass: classes.JobInfo_CardChipInProgressWithErrors,
            },
            <ErrorIcon style={{fill: STATUS_STYLES.inProgressWithError.color}}/>
          ]
      case jobStatusTypesGraphQL.complete:
        return[
          {
            label: STATUS_STYLES.complete.label,
            color: STATUS_STYLES.complete.color,
            progressBarClass: classes.JobInfo_doneProgressBar,
            chipColor: STATUS_STYLES.complete.backgroundColor,
            chipClass: classes.JobInfo_CardChipComplete,
          },
          <CheckCircleIcon  style={{fill: STATUS_STYLES.complete.color}}/>
        ]
      case jobStatusTypesGraphQL.completeWithError:
          return[
            {
              label: STATUS_STYLES.completeWithError.label,
              color: STATUS_STYLES.completeWithError.color,
              progressBarClass: classes.JobInfo_errorProgressBar,
              chipColor: STATUS_STYLES.completeWithError.backgroundColor,
              chipClass: classes.JobInfo_CardChipCompleteWithErrors,
            },
            <ErrorIcon style={{fill: STATUS_STYLES.completeWithError.color}}/>
          ]
      case jobStatusTypesGraphQL.error:
        return[
          {
            label: STATUS_STYLES.error.label,
            color: STATUS_STYLES.error.color,
            progressBarClass: classes.JobInfo_errorProgressBar,
            chipColor: STATUS_STYLES.error.backgroundColor,
            chipClass: classes.JobInfo_CardChipError,
          },
          <ErrorIcon style={{fill: STATUS_STYLES.error.color}}/>
        ]
      default:
        return[
          {
            label: STATUS_STYLES.unassigned.label,
            color: STATUS_STYLES.unassigned.color,
            progressBarClass: classes.JobInfo_defaultProgressBar,
            chipColor: STATUS_STYLES.unassigned.backgroundColor,
            chipClass: classes.JobInfo_CardChip,
          },
          <PlayArrowIcon style={{fill: STATUS_STYLES.unassigned.color}}/>
        ]
    }
  }