import moment from "moment";
import {exportCSVFile} from "../../../utilities/exportCSV";

export const SPECIMEN_RETRIEVAL = "Specimen Retrieval";
export const ROUTE_INFO = "Route Information";
export const JOBNAME = "Job name:";
export const JOBID = "Job number:";
export const WORKER = "Worker:";
export const JOBSTATUS = "Job status:";
export const JOBTYPE = "Job type:";
export const JOBDATE = "Job date:";
export const JOBSTARTDATE = "Job start time:";
export const JOBENDDATE = "Job end time:";
export const DURATION = "Duration:";
export const ERROR_TYPE = "Error type:";
export const ERROR_DETAILS = "Error Details";
export const ERROR_TASKS= {
    jobCancelled:{
        key: 'job_cancelled',
        description: 'Job Cancelled'
    },
    jobStartDelay:{
        key: 'job_delayed',
        description: 'Job Start Delayed'
    },
    locationSkipped:{
        key: 'skip_location',
        description: 'Location Skipped'
    },
    scanOverride:{
        key: 'scan_override',
        description: 'Location Scan Override'
    },
    scanError:{
        key: 'failed_scan',
        description: 'Scan Failed'
    }
}
export const START_POINT = "Start Point";
export const statusStyles = {
    inProgress:{
        label: "InProgress",
        backgroundColor: "#E9F5FE",
        color: "#2196F3"
    },
    unassigned:{
        label: "Unassigned",
        backgroundColor: "#F1F1F1",
        color: "#757575"
    },
    complete:{
        label: "Complete",
        backgroundColor: "#ECF4EA",
        color: "#458E2D"
    },
    error:{
        label: "Error",
        backgroundColor: "#F4D7D6",
        color: "#CE4947"
    }
};

export const CSV_CONFIGS={
    fileName:'JobHistory-tasks',
    defaultName:'JobHistory-tasks.csv',
    extension:'.csv',
    blobType:'text/csv;charset=utf-8;'
};

export const handleExportTable = (tasks) => {
    let headers = Object.keys(tasks[0]);
    let fileTitle = `JobHistory-tasks-${moment(new Date()).format('MM-DD-YYYY')}`
    exportCSVFile(headers, tasks, fileTitle, CSV_CONFIGS);
};

