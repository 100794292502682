import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    HistoryTimeWindow_Paper:{
        padding: "15px",
        position: "absolute",
        zIndex: "1000",
        top: "120%",
        left: "45%",
        width: "200px"
    },
    HistoryTimeWindow_Buttons:{
        marginTop: "10px",
        float: "right",
    },
    HistoryTimeWindow_CalendarIcon:{
        position: "absolute",
        left: "85%",
        top: "-5%",
        "&:hover": {
          cursor: "pointer"
        }
    },
    HistoryTimeWindow_DateContainer:{
        marginTop: "10px",
        top: "20%",
        left: "60%",
        width: "400px",
        height: "50px"
    },
    HistoryTimeWindow_fromLabel:{
        position: "absolute",
        top: "-20%",
        left: "50%",
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "13px"
    },
    HistoryTimeWindow_durationContainer:{
        position: "absolute",
        top: "65%",
        left: "50%",
        textAlign:"center",
        width: "150px"
    },
    HistoryTimeWindow_fromInput:{
        marginLeft: "10px"
    },
    HistoryTimeWindow_toInput:{
        marginLeft: "10px",
        marginTop: "5px"
    }
}));