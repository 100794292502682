///Each function should return an object to update message

export const spaceValidation=(input)=>{
    let obj={}
     obj=(input.split("").indexOf(" ")>-1)?{fn:"spaceVal",isValid:true,message:"Input does not allow space"}:{fn:"spaceVal",isValid:false,message:""};
    return obj;
}

export const isRequired=(input)=>{
let obj={}
    obj=(input==="" || input===undefined || input==="none")?{fn:"required",isValid:true,message:"Input is required"}:{fn:"required",isValid:false,message:""};
    return obj;
};

export const convertLowerCase=(text)=>{
    if(!!text && typeof text == 'string'){
        return text.toLowerCase();
    }else{
        throw console.error("Text is not a string or shouldnt be null/undefined/empty string");
    }
}


export const validateEmail=(email)=> {
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
return re.test(String(email).toLowerCase());
}