import { DIRECT_COUNTER, DIRECT_CONTACTS, REFRESH_CONTACT } from "../actions/contactEvent/types";

const initialState = {
  directCounter: 0,
  directContacts: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DIRECT_COUNTER:
      return {
        ...state,
        directCounter: action.payload.contacts,
      };
    case DIRECT_CONTACTS:
      return {
        ...state,
        directContacts: action.payload.contacts
      };
    case REFRESH_CONTACT:
      return { ...state };
    default:
      return state;
  }
}
