import {
  SET_USER,
  ALL_USERS,
  CREATE_SUPER_ADMIN,
  GET_SUPER_ADMIN_LIST,
  USER_COUNT_BY_CAMPUS,
  PHONE_COUNT_BY_CAMPUS,
  MESSAGE_COUNT_BY_CAMPUS,
  EQUIPMENT_COUNT_BY_CAMPUS,
  USER_BY_ROLE_AND_CAMPUS,
  MESSAGE_BY_DATE_AND_CAMPUS,
  MESSAGE_BY_ADMIN,
  PHONE_BY_ROLE_AND_CAMPUS,
  FETCH_ROLES,
  INIT_STATE
} from './types.js';
import { ERROR, TOKEN, ADMIN_LOGOUT } from '../auth/types';
import axios from 'axios';
import moment from "moment";
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const saLogin = (email, pass, emailOrUser) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      let data = {
        email: email,
        password: pass
      }
      axios({
        method: 'post',
        url: secureUrl.hostUrl + '/api/super_admins/login',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: "same-origin",
        data
      }).then(async (response) => {
        const SUPER_ADMIN_TOKEN = "adminToken";
        localStorage.setItem(SUPER_ADMIN_TOKEN, response.data.id);
        dispatch({
          type: TOKEN,
          payload: {
            tokenMK: response.data.id
          }
        });
       resolve({ success: true, userId: response.data.userId, token: response.data.id });
      }).catch(err => {
        console.error("[SUPER ADMIN ACTIONS] An error ocurred in saLogin: ", err);
        reject(null);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in saLogin: ", error);
      reject(error);
      /*
      dispatch({
        type: ERROR,
        payload: 'Error on Login',
      });
      */
    }
  })
};
//Moved outside of the funcion to re-use outside. 
export const handleGetUser = (response) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        method: 'get',
        url: secureUrl.hostUrl + '/api/super_admins/' + response.data.userId,
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((res) => {
        dispatch({
          type: SET_USER,
          payload: res.data
        });
        resolve(true)
      });
    } catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in handleGetUser: ", error);
      reject(false)
    }
  });
}

export const saLogout = () => (dispatch, getState) => {
  const name = `${getState().superAdmin.user.first_name} ${getState().superAdmin.user.last_name}`;
  const {id} = getState().superAdmin.user;
  const tokenMK = localStorage.getItem("adminToken") || getState().auth.tokenMK;

  return new Promise((resolve, reject) => {
    try{
      axios({
        method: 'POST',
        url: secureUrl.hostUrl + `/api/super_admins/logout?access_token=${tokenMK}`,
        data: {
          user: name,
          id: id
        }
      }).then((resp) => {
        dispatch({
          type: ADMIN_LOGOUT
        });
      }).then((resp) => {
        resolve({ success: true });
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in saLogout: ", error);
      dispatch({
        type: ADMIN_LOGOUT
      });
      reject({ success: false, error: error });
    }

  });
};

export const sendLoginAdminParams = (token, userAdminInfo) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(
        {
          type: TOKEN,
          payload: {
            tokenMK: token
          }
        }
      );
      dispatch(
        {
          type: SET_USER,
          payload: userAdminInfo
        }
      );
      resolve({ success: true });
    } catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in sendLoginAdminParams: ", error);
      reject({ success: false, error: error });
    }
  });
};

export const setAdminToken = (token) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(
        {
          type: TOKEN,
          payload: {
            tokenMK: token
          }
        }
      );
      resolve({ success: true });
    } catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in setAdminToken: ", error);
      reject({ success: false, error: error });
    }
  });
};

export const checkUserEmail = (email) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + '/api/super_admins/findOne?filter={"where":{"email":"' + email + '"}}')
      .then(resp => {
        resolve(resp);
      }).catch(err => {
        reject(null);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in checkUserEmail: ", error);
      reject(error);
    }
  });
};

export const allUsers = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(secureUrl.hostUrl + '/api/accounts')
      .then((resp) => {
        dispatch({
          type: ALL_USERS,
          payload: resp.data
        });
        resolve({ success: true })
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in allUsers: ", error);
      window.location.href = "/";
      reject({ success: false })
    }
  })
};

export const usersCountByCampus = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let { campusList } = getState().campus;
      let usersCount = {};
      let promiseArr = campusList.map(async (campus) => {
        let query = `where={"campusId":"${campus.id}"}`;
        return usersByQuery(query).then((resp) => {
          usersCount[campus.alias] = resp.data.count;
        });
      });
      Promise.all(promiseArr).then((resultsArray) => {
        dispatch({
          type: USER_COUNT_BY_CAMPUS,
          payload: usersCount,
        });
      });
      resolve({ success: true });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in usersCountByCampus: ", error);
      reject({ success: false });
    }
  })
};

export const phonesCountByCampus = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let { campusList } = getState().campus;
      let phonesCount = {};
      let promiseArr = campusList.map(async (campus) => {
        return phonesByCampus(campus.id).then((resp) => {
          phonesCount[campus.alias] = resp.data.count;
        });
      });
      Promise.all(promiseArr).then((resultsArray) => {
        dispatch({
          type: PHONE_COUNT_BY_CAMPUS,
          payload: phonesCount,
        });
      });
      resolve({ success: true });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in  phonesCountByCampus: ", error);
      reject({ success: false });
    }
  })
};


export const messagesCountByCampus = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let { campusList } = getState().campus;
      let messagesCount = {};
      let token = getState().auth.tokenMK;

      let promiseArr = campusList.map(async (campus) => {
        let query = `where={"campusId":"${campus.id}"}&access_token=${token}`;
        return messagesCountByQuery(query).then((resp) => {
          messagesCount[campus.alias] = resp.data.count;
        });
      });
      Promise.all(promiseArr).then((resultsArray) => {
        dispatch({
          type: MESSAGE_COUNT_BY_CAMPUS,
          payload: messagesCount,
        });
      });
      resolve({ success: true });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in messagesCountByCampus: ", error);
      reject({ success: false });
    }
  })
};

export const equipmentsCountByCampus = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let { campusList } = getState().campus;
      let equipmentsCount = {};

      let promiseArr = campusList.map((campus) => {
        return equipmentsByCampus(campus.id).then((resp) => {
          equipmentsCount[campus.alias] = resp.data.count;
        });
      });
      Promise.all(promiseArr).then((resultsArray) => {
        dispatch({
          type: EQUIPMENT_COUNT_BY_CAMPUS,
          payload: equipmentsCount,
        });
      });
      resolve({ success: true });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in equipmentCountByCampus: ", error);
      reject({ success: false });
    }
  })
};

export const usersByCampusRole = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let usersCount = [[' ']];
      let { campusList } = getState().campus;
      let { aclList, roleList } = getState().superAdmin;
      campusList.forEach((v) => {
        usersCount[0].push(v.alias);
      });

      roleList.forEach((r, key, arr) => {
        let roleArray = [r];
        let promiseArray = campusList.map((campus) => {
          let query = `where={"and":[{"campusId": "${campus.id}"},{"role_name":"${r}"}]}`;
          return usersByQuery(query).then((resp) => {
            let dataResult = resp.data.count;
            roleArray.push(dataResult);
          });
        });
        Promise.all(promiseArray).then((resultsArray) => {
          usersCount.push(roleArray);
          if (key === (arr.length - 1)) {
            dispatch({
              type: USER_BY_ROLE_AND_CAMPUS,
              payload: usersCount,
            });
            resolve({ success: true });
          }
        });
      });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in usersByCampusRole: ", error);
      reject({ success: false });
    }
  })
}

export const phonesByCampusRole = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + '/api/Phones')
      .then((resp) => {

        let { campusList } = getState().campus;
        let { allUsers, roleList } = getState().superAdmin;
        let phonesCount = [[' ']];

        campusList.forEach((v) => {
          phonesCount[0].push(v.alias);
        });

        let phoneList = resp.data.map((p) => {
          const user = allUsers.find(u => u.id === p.usedBy);
          const role = user ? user.role_name : '';
          return {
            campusId: p.campusId,
            role: role,
          }
        });

        roleList.forEach((r, key, arr) => {
          let roleArray = [r];
          campusList.map((campus) => {
            let dataResult = phoneList.filter((p) => p.campusId === campus.id && p.role === r).length;
            roleArray.push(dataResult);
          });
          phonesCount.push(roleArray);
        });

        dispatch({
          type: PHONE_BY_ROLE_AND_CAMPUS,
          payload: phonesCount
        });
        resolve({ success: true });
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in phonesByCampusRole: ", error);
      reject({ success: false });
    }
  })
};


export const messagesByCampusDate = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let token = getState().auth.tokenMK;
      let messagesCount = [[' ']];
      let { campusList } = getState().campus;
      let currentMonth = moment().month();
      let year = moment().format('YYYY');
      let firstDate = moment(`${year}-01-01`).startOf('day');


      campusList.forEach((v) => {
        messagesCount[0].push(v.alias);
      });

      for (let i = 0; i < currentMonth + 1; i++) {
        let monthArray = [i + 1];
        let nextDate = moment(firstDate).add(1, 'M').startOf('day');
        let promiseArray = campusList.map((campus) => {
          let query = `where={"and": [{"campusId": "${campus.id}"},{"creationDate": {"gte":"${moment(firstDate).format()}"}},{"creationDate": {"lte":"${moment(nextDate).format()}"}}]}&access_token=${token}`;
          return messagesCountByQuery(query).then((resp) => {
            monthArray.push(resp.data.count);
          });
        });
        firstDate = moment(firstDate).add(1, 'M').startOf('day');

        Promise.all(promiseArray).then((resultsArray) => {
          messagesCount.push(monthArray);
          if (i === (currentMonth)) {
            dispatch({
              type: MESSAGE_BY_DATE_AND_CAMPUS,
              payload: messagesCount,
            });
            resolve({ success: true });
          }
        });
      }
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in messagesByCampusDate: ", error);
      reject({ success: false });
    }
  })
}

export const messagesByAdmin = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let token = getState().auth.tokenMK;
      let adminUser = "Admin";
      let userResponse = [];
      let { campusList } = getState().campus;
      let { allUsers } = getState().superAdmin;

      let promiseArray = allUsers.filter((u) => u.role_name === adminUser).map((u) => {
        let query = `where={"origin":"${u.id}"}&access_token=${token}`;
        let query1 = `where={"and":[{"destiny": "${u.id}"},{"seen":true}]}&access_token=${token}`;
        let query2 = `?filter={"where":{"origin":"${u.id}"},"limit":"1","order":"creationDate DESC"}&access_token=${token}`;
        return messagesCountByQuery(query).then(async (resp) => {
          let seen = await messagesCountByQuery(query1);
          let last = await messagesByQuery(query2);
          let messageAdmin = {
            campus: getCampusName(campusList, u.campusId),
            admin: `${u.first_name} ${u.last_name}`,
            send: resp.data.count,
            read: seen.data.count,
            lastDate: last.data.length ? moment(last.data[0].creationDate).format("MM/DD/YYYY HH:mm") : ''
          }
          userResponse.push(messageAdmin);
        });

      });
      Promise.all(promiseArray).then((resultsArray) => {
        dispatch({
          type: MESSAGE_BY_ADMIN,
          payload: userResponse,
        });
        resolve({ success: true });
      });
    }
    catch (error) {
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in messagesByAdmin: ", error);
      reject({ success: false });
    }
  })
}

const getCampusName = (campusList, id) => {
  const campus = campusList.find(campus => campus.id === id);
  const campusName = campus ? campus.name : '';
  return campusName;
};

const usersByQuery = (query) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/accounts/count?${query}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in usersByQuery: ", error);
      reject({ success: false });
    }
  });
};

const phonesByCampus = (campusId) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/Phones/count?where={"campusId":"${campusId}"}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in phonesByCampus: ", error);
      reject({ success: false });
    }
  });
};

const messagesByQuery = (query) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/messages${query}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in messagesByQuery: ", error);
      reject({ success: false });
    }
  });
};

const messagesCountByQuery = (query) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/messages/count?${query}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in messagesCountByQuery: ", error);
      reject({ success: false });
    }
  });
};

const equipmentsByCampus = (campusId) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/equipment/count?where={"campusId":"${campusId}"}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in equipmentsByCampus: ", error);
      reject({ success: false });
    }
  });
};

const getUsersByCampusRole = (campusId) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/accounts/count?where={"campusId":"${campusId}"}`)
      .then((resp) => {
        resolve(resp);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in getUsersByCampusRole: ", error);
      reject({ success: false });
    }
  });
};

export const createSuperAdmin = (body) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.post(secureUrl.hostUrl + `/api/super_admins/createSuperAdmin?req=${JSON.stringify(body)}`
      ).then(result => {
        dispatch({
          type: CREATE_SUPER_ADMIN,
          payload: result
        })
        resolve(true);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in createSuperAdmin: ", error);
      reject(error);
    }
  })
};

export const getUser = async (id) => {
  return new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: secureUrl.hostUrl + '/api/super_admins/' + id,
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        resolve(response.data);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in getUser: ", error);
      reject({success: false});
    }
  })
}

export const getSuperAdmins = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios({
        method: 'get',
        url: secureUrl.hostUrl + '/api/super_admins',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        dispatch({
          type: GET_SUPER_ADMIN_LIST,
          payload: response.data,
        })
        resolve(true);
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in getSuperAdmins: ", error);
    }
  })
}

export const patchSuperAdmin = (id, superadmin) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.patch(secureUrl.hostUrl + `/api/super_admins/${id}?access_token=${getState().auth.token}`, superadmin).then(resp => {
        if (resp.data.id) {
          /*
          let superAdminList = getState().superAdmin.superAdminList.map(e => {
            if (e._id === resp.data._id)
              let newSup = resp.data;
            return newSup;
          });
          dispatch({
            type: PATCH_SUPERADMIN,
            payload: superAdminList
          });
          */
          resolve(true);
        }
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in patchSuperAdmin: ", error);
      reject(error);
    }
  })
}

export const getUserId = (username) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/super_admins?filter={"where":{"username":"${username}"}}`)
      .then((resp) => {
        resolve({ success: true, id: resp.data[0].id })
      }).catch(err => {
        reject({ success: false, id: null })
      })
    }catch(error){
      console.error("[SUPER ADMIN ACTIONS] An error ocurred in getUserId: ", error);
      reject({ success: false, id: null })
    }
  })
}

