import React from 'react';

import Chip from '@material-ui/core/Chip';
import ExploreIcon from '@material-ui/icons/Explore';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import Subheader from "@material-ui/core/ListSubheader";

import {filterStyles} from '../styles';
import "./filters.css";
import * as CONSTS from "./filters.constants";

export const CustomCheckbox = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: "#2196F3"
    }
  },
  checked: {}
}))(Checkbox);

const ActiveMapFilters = (props) =>{
    const {classes} = props;

    return(
    <div id="webNavv-activeMap-secondaryFilters-1.0">
        <Subheader>{CONSTS.FILTERS}</Subheader>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.CURRENT_FLOOR}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-activeMap-secondaryFilters-currentFloor-1.0"
              onChange={props.handleActiveFloor}
              checked={props.activeFloor}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.SHOW_TRACES}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-activeMap-secondaryFilters-showTraces-1.0"
              onChange={props.handleTraces}
              checked={props.traces}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.INDOOR_ONLY}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-activeMap-secondaryFilters-indoorOnly-1.0"
              onChange={props.handleIndoorOnly}
              checked={props.indoorOnly}/>
          </div>
        </div>
        <div className='Filters_Container'>
          <div className='Filters_Text' >{CONSTS.ALL_CALLOUTS}</div>
          <div className='Filters_Checkbox'>
            <CustomCheckbox
              id="webNavv-activeMap-secondaryFilters-allCallouts-1.0"
              onChange={props.handleCallouts}
              checked={props.allCallouts}/>
          </div>
        </div>
        {props.allCallouts &&
          <div className='Filters_Container'>
              <div className='Filters_Text' >{CONSTS.MINIMIZE_CALLOUT}</div>
              <div className='Filters_Checkbox'>
                <CustomCheckbox
                  id="webNavv-activeMap-secondaryFilters-minimizeCallouts-1.0"
                  onChange={props.handleSmallCallouts}
                  checked={props.smallCallouts}/>
              </div>
          </div>
        }
        <Divider style={{marginTop:"15px"}}/>
        <div id="webNavv-activeMap-actions-1.0">
          <Subheader>{CONSTS.ACTIONS}</Subheader>
          <div style={{paddingLeft:'25%'}}>
            <Chip
              id="webNavv-activeMap-actions-centerMap-1.0"
              disabled={typeof props.transporterFound==='string'?true:false}
              icon={<ExploreIcon className={classes.centerIcon} />}
              onClick={props.handleCenterMap}
              label={CONSTS.CENTER_MAP}
            />
          </div>
        </div>
    </div>
    );
}


export default withStyles(filterStyles)(ActiveMapFilters);