import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PrintIcon from '@material-ui/icons/Print';
import generatePDF from "./generatePDF";
import {Checkbox} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import MasterButton from '../utilities/button.component';

const defaultToolbarStyles = {
  iconButton: {
    size: "10px"
  },
  autoRenewIcon:{
      color: "blue"
  },
  blockIcon:{
    color: "red"
  },
  snackbarStyleViaContentProps: {
    backgroundColor: "#eeeeee",
    color: "black"
  }
};

class CustomToolbar extends React.Component {
  state= {
    archiveStatus: false,
    openSnack: false,
    snackMsg: ""
  };

  handleClick = () => {
    this.props.handleChangeSwitch();
  }

  handleShowArchive=(event)=>{
    this.setState({ archiveStatus: event.target.checked });
    this.props.handleShowArchive();
  }

  handleGeneratePDF=()=>{
    if (this.props.table === 'FM')
      this.handleOpenSnackbar();
    else
      generatePDF(this.props.table, this.props.data);
  };

  handleCloseSnackbar = (type) => {
    this.setState({openSnack: false});
    if (type === 1)
      generatePDF(this.props.table, this.props.data);
  };

  handleOpenSnackbar = () => {
    let msg= <div style={{display:'inline'}}><div style={{display:'inline',fontWeight:'bold'}}>Attention: </div> Some table columns are not included in the document to print.</div>
    this.setState({openSnack: true, snackMsg: msg});
  };

  render() {
    const { classes, table, refresh } = this.props;
    let showArchiveEnabled = table==='FM' && refresh===true? false: true;
    return (
      <React.Fragment>
        <Tooltip title={"Print table"}>
          <IconButton className={classes.iconButton} onClick={this.handleGeneratePDF} >
            <PrintIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        {table==="FM"&&
        <React.Fragment>
        <Tooltip title={"Show Archive"}>
          <Checkbox
            disabled={showArchiveEnabled}
            color="primary"
            checked={this.state.archiveStatus}
            onChange={event => this.handleShowArchive(event)}
          />
        </Tooltip>
        </React.Fragment>
        }
        <Tooltip title={"Refresh Table"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
           <AutorenewIcon className={classes.autoRenewIcon} />
          </IconButton>
        </Tooltip>
        <Snackbar
          style={{marginTop:'7vh'}}
          ContentProps={{
            className: classes.snackbarStyleViaContentProps
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.openSnack}
          message={this.state.snackMsg}
          action={
            <React.Fragment>
              <MasterButton
              keyLbl={'snack-success'}
              label={'OK'}
              buttonType={'success-flat'}
              isDisabled={false}
              handleClick={()=>this.handleCloseSnackbar(1)}
              size={"small"}/>
              <MasterButton
              keyLbl={'snack-default'}
              label={'CLOSE'}
              buttonType={'default-flat'}
              isDisabled={false}
              handleClick={()=>this.handleCloseSnackbar(2)}
              size={"small"}/>
            </React.Fragment>
          }>
        </Snackbar>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);