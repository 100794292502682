import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = theme => ({
  problemIcon: {
    fill:'#FFC738',
    fontSize:'200px',
    marginLeft:'100px'
  },
  tableCell: {
    textAling: "center"
  },
  paper: {
    minWidth: "700px",
    minHeight: "100%",
  },
  containedBtn: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[800],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: lightBlue[600]
      }
    }
  },
  numLbl:{
    marginTop:"15px",
    fontSize:"14px",
    color:"gray",
    textAlign:'center'
  },
  snackMsg:{
    display:'inline',
    color:'white'
  },
  boldSnackMsg:{
    display:'inline',
    color:'white',
    fontWeight: 'bold'
  },
  textInput:{
    width: "100%",
    marginRight: "5%"
  },
  titleContainer:{
    display:'flex',
    marginTop:"20px"
  },
  titleIcon:{
    fill:'#2843A3',
    fontSize:'50px',
    opacity:0.7
  },
  titleIconDiv:{
    marginTop:"1%"
  },
  titleLbl:{
    color:'#2843A3',
    marginLeft:'1%',
  },
  statusLblContainer:{
    display: "flex",
    justifyContent: "start",
    padding: "none",
    color:"#757575"
  },
  statusLblDiv:{
    margin: '0 8px 0 0',
    height: 8,
    width: 8,
    borderRadius: 4.5,
    position: "relative",
    top: 15
  },
});