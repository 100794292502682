
import io from 'socket.io-client';
import {Firebase} from '../firebase/firebase';
import hostName from '../utilities/hostName';
class socketioConn {

constructor() {
   const pushPermission = ("Notification" in window && Notification.permission === "granted");

   this.pushNotification = pushPermission ? new Firebase() : false;
   this.secureUrl = new hostName();
   this.secureUrl.initHostName();

   console.info(`[SOCKET GEOLOCATION ENV] Socket IO Connection - ${process.env.NODE_ENV}`)
}

initSocket = async () => {
   this.lastLocationProps={rejectUnauthorized : false,path: "/ws_lastLocation/socket.io",transports: ['websocket'],forceNew: true,wsEngine: 'ws',pingTimeout: 60000,perMessageDeflate: false};
   this.chatProps={transports: ['websocket'],path:"/ws_chat/socket.io",perMessageDeflate: false};
}

getLastLocationConn = (people, teamId, userId) =>{
   return io.connect(
      window.location.hostname === 'localhost' ? `https://services-demo.navvtrack.com?teamId=${teamId}&userId=${userId}` : `${this.secureUrl.hostUrl}?teamId=${teamId}&userId=${userId}`,
      this.lastLocationProps);
}
getChatConn = (queryParam = false) => {
   const chatParams = !!queryParam ? Object.assign(this.chatProps, queryParam) : { ...this.chatProps };
   return io.connect(
      process.env.NODE_ENV == 'development' ? `https://services-demo.navvtrack.com/chat`: `${this.secureUrl.hostUrl}/chat`, 
      chatParams
   );
}
getToken = () => {
   const token = !!this.pushNotification ? this.pushNotification.getToken() : null;
   return token;
} 

}
export default socketioConn
