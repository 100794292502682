import React from "react";

import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import {ACTION_STATUS} from "../jobsBoard.constants";
import "./actionMessage.css";

const ActionMessage = (props) =>{
    const {actionStatus} = props;
    
    return(
    <React.Fragment>
        {actionStatus === ACTION_STATUS.inProgress.id && (
            <div className="ActionMessage_ActionStatusLabelContainer">
              <CachedOutlinedIcon className="ActionMessage_Icon"/>
              <p className="ActionMessage_ActionStatusLabel">{ACTION_STATUS.inProgress.label}</p>
            </div>
        )}
        {actionStatus === ACTION_STATUS.done.id && (
            <div className="ActionMessage_ActionStatusLabelContainer">
              <CheckOutlinedIcon className="ActionMessage_Icon"/>
              <p className="ActionMessage_ActionStatusLabel">{ACTION_STATUS.done.label}</p>
            </div>
        )}
        {actionStatus === '' && (
            <div className="ActionMessage_ActionStatusLabelContainer"></div>
        )}
    </React.Fragment>
    );
}

export default ActionMessage;