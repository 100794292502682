export const EMPTY_VALUE = '';
export const DEFAULT_USER_ERROR = 'User ID not Found';
export const USER_ENABLED_ERROR = 'The user is not enabled';
export const DEFAULT_PASS_ERROR = 'Wrong Password'; 
export const MAIN = {
    title: 'Welcome to NavvTrack',
    subtitle: 'INDOOR TRAFFIC CONTROL SYSTEM',
    detail: 'Putting Motion Into Healthcare Improvement and Putting Healthcare Improvement Into Motion',
};
export const ACCESS = {
    title: 'NavvTrack',
    login: 'Login',
    email: 'Email',
    password: 'Password',
    or: 'OR',
    microsoft: 'Login with Microsoft',
};
export const NOTIFICATION = {
    title: 'problems with your account?',
    subtitle: 'let us know',
};
//HARCODED VALUES FOR CHAT WITH COGNITO
export const COGNITO_USERS = ["yh.ed","yh.rt"];
export const COGNITO_PASSWORD = "Lahey123!"; 
