import React from "react";
import Chart from "react-google-charts";

import { Button, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { MESSAGES_CHAT, USERS_BY_DEPARTMENT, USERS_BY_ROLE } from "./chartsConstants";
import "./superStatistics.component.css";

import _ from "lodash";

export default class SuperStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem: null,
    };
  }

  render() {
    const handleClick = (event) => {
      this.setState({ menuItem: event.target });
    };

    const handleClose = () => {
      this.setState({ menuItem: null });
    };

    return (
      <React.Fragment>
        <div className="container">
          <div className="overallcontainer">
            <p className="overalltitle">Overall</p>
            <Button
              className="dropdownmenuday"
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{float: 'left', marginLeft: '70%'}}
              onClick={(e) => handleClick(e)}
            >
              {"Today"}
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              className="dropdownmenuday"
              id="simple-menu"
              anchorEl={this.state.menuItem}
              keepMounted
              open={Boolean(this.state.menuItem)}
              onClose={() => handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
            <div className="cardcontainer">
              <div className="overall-card">
                <p className="number">902</p>
                <div className="cardtitle">
                  <p className="number-of user-icon">Users</p>
                </div>
              </div>
              <div className="overall-card">
                <p className="number">396</p>
                <div className="cardtitle">
                  <p className="number-of cel-icon">Phones</p>
                </div>
              </div>
              <div className="overall-card">
                <p className="number">3824</p>
                <div className="cardtitle">
                  <p className="number-of msg-icon">Messages</p>
                </div>
              </div>
            </div>
          </div>
          <div className="overallchart1">
            <p className="overalltitle">Number of Messages</p>
            <Button
              className="dropdownmenuday"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => handleClick(e)}
              style={{float: 'left', marginLeft: '20%'}}
            >
              Today
            <KeyboardArrowDownIcon />
            </Button>
            <Menu
              className="dropdownmenuday"
              id="simple-menu"
              anchorEl={this.state.menuItem}
              keepMounted
              open={Boolean(this.state.menuItem)}
              onClose={() => handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
            <Chart
              width={"300px"}
              height={"230px"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={MESSAGES_CHAT}
              options={{
                chart: {
                  title: "Number of Messages ",
                  subtitle: " ",
                },
                hAxis: {
                  title: " ",
                },
                vAxis: {
                  title: " ",
                },
                colors: ["#0095FF"],
              }}
              rootProps={{ "data-testid": "1" }}
            />
          <Button variant="contained" style={{ borderRadius: '20px', marginLeft: '20%', marginTop: '-8%', height: '30px', width: '220px', backgroundColor: '#0095FF', color: 'white'}}>
            Generate Report
          </Button>
          </div>
        </div>

        <div className="container">
          <div className="bars-card-department">
            <p className="overalltitle">Users by Department</p>
            <Button
              className="dropdownmenuday1"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => handleClick(e)}
              style={{float: 'left', marginLeft: '15%'}}
            >
              1st Floor
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              className="dropdownmenuday"
              id="simple-menu"
              anchorEl={this.state.menuItem}
              keepMounted
              open={Boolean(this.state.menuItem)}
              onClose={() => handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
            <Chart
              width={"350px"}
              height={"380px"}
              chartType="BarChart"
              loader={<div>Loading Chart</div>}
              data={USERS_BY_DEPARTMENT}
              options={{
                title: "Users by department",
                width: 350,
                height: 350,
                bar: { groupWidth: "95%" },
                legend: { position: "none" },
                borderRadius: 4,
              }}
              rootProps={{ "data-testid": "6" }}
            />
          </div>
          <div className="overallchart2">
            <p className="overalltitle">Users by Role</p>
            <Button
              className="dropdownmenuday"
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{float: 'left', marginLeft: '60%'}}
              onClick={(e) => handleClick(e)}
            >
              Role
            <KeyboardArrowDownIcon />
            </Button>
            <Menu
              className="dropdownmenuday"
              id="simple-menu"
              anchorEl={this.state.menuItem}
              keepMounted
              open={Boolean(this.state.menuItem)}
              onClose={() => handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
            <Chart
              width={'100%'}
              height={'300px'}
              chartType="CandlestickChart"
              loader={<div>Loading Chart</div>}
              data={USERS_BY_ROLE}
              options={{
                legend: 'none',
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          <Button variant="contained" style={{ borderRadius: '20px', marginLeft: '35%', height: '30px', width: '220px', backgroundColor: '#0095FF', color: 'white'}}>
            Generate Report
          </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
