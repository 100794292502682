import React, { useEffect } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import lightBlue from "@material-ui/core/colors/lightBlue";

import NewUser from './newUser.component';
import NewCampusUser from './newCampusUser.component';
import { HEADER_TITLE, SWITCH_TITLE, BACK_USER_TABLE, SUPERADMIN_NAME } from './users.constants';
import usersIcon from '../../../img/multicampus.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: 40,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    marginRight: 40,
  },
  headerImage: {
    height: 35,
    width: 35,
    display: 'table-cell',
  },
  headerTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    color: '#2843A3',
    fontWeight: 'bold',
    fontSize: 23,
    marginLeft: 10,
  },
  back: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.25,
    "&:hover": {
      color: lightBlue[600],
      cursor: "pointer",
    }
  },
  switchLabelOff: {
    justifyContent: 'flex-end',
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.4)',
    }
  },
  switchLabelOn: {
    justifyContent: 'flex-end',
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: '#757575',
    }
  }
}));

const UserSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#2196F3',
    },
    '&$checked + $track': {
      backgroundColor: '#2196F3',
    },
  },
  checked: {},
  track: {},
})(Switch);

const switchTitle = SWITCH_TITLE;
const backTitle = BACK_USER_TABLE;

export default function UserAddContainer(props) {
  const classes = useStyles();
  const { backToUsers, editUserInfo } = props;
  const isEditing = Boolean(editUserInfo);
  const [switchUser, setSwitchUser] = React.useState(isEditing && editUserInfo.role !== SUPERADMIN_NAME);

  useEffect(() => {
  }, []);

  const handleChange = (event) => {
    setSwitchUser(event.target.checked);
  };

  const getTitle = () => {
    const { 
      adminAdd,
      userAdd,
      adminEdit,
      userEdit
    } = HEADER_TITLE;
    let title = '';

    if (switchUser) {
      if (isEditing) {
        title = userEdit;
      }
      else {
        title = userAdd;
      }
    }
    else {
      if (isEditing) {
        title = adminEdit;
      }
      else {
        title = adminAdd;
      }
    }

    return title;
  };

  const UserTitle = () => {
    let headerTitle = getTitle();
    return (
      <>
        <div className={classes.headerContainer}>
          <div style={{ display: 'flex' }}>
            <img
              src={usersIcon}
              className={classes.headerImage}
              alt='usersIcon'
            />
            <div className={classes.headerTitle}>{headerTitle}</div>
          </div>
          <p className={classes.back} onClick={() => backToUsers()}>{backTitle}</p>
        </div>
      </>
    )
  }

  return (
    <>
      <FormGroup className={classes.root}>
        <UserTitle />
        <FormControlLabel
          className={switchUser ? classes.switchLabelOn : classes.switchLabelOff}
          control={<UserSwitch disabled={isEditing} checked={switchUser} onChange={handleChange} name="checkedA" />}
          label={switchTitle}
        />
        <div>
          <Grid container>
            <Grid item xs={12} item style={{ display: 'flex' }}>
            {switchUser ? <NewCampusUser createUserSuccess={(msg) => {props.createUserSuccess(msg)} } editUserInfo={editUserInfo} backToUsers={backToUsers} /> : 
              <NewUser createUserSuccess={(msg) => {props.createUserSuccess(msg)} } editUserInfo={editUserInfo} backToUsers={backToUsers} />}
            </Grid>
          </Grid>
        </div>
      </FormGroup>
    </>
  );
}