import axios from "axios";
import { DIRECT_COUNTER, DIRECT_CONTACTS, REFRESH_CONTACT } from './types';
import hostName from '../../components/utilities/hostName';
const secureUrl = new hostName();
secureUrl.initHostName();

export const directContacts = (userId) => (dispatch, getState) => {
  try{
    axios
    .get(`${secureUrl.hostUrl}/api/contact_events/contacts?userId=${userId}`)
    .then((resp) => {
      dispatch({
        type: DIRECT_CONTACTS,
        payload: resp.data,
      });
    });
  }catch(error){
    console.error("[CONTACT EVENT ACTIONS] An error ocurred in directContacts: ", error);
  }

};
export const directCounter = (userId) => (dispatch, getState) => {
  try{
    axios
    .get(`${secureUrl.hostUrl}/api/contact_events/directsTotal?userId=${userId}`
    )
    .then((resp) => {
      dispatch({
        type: DIRECT_COUNTER,
        payload: resp.data,
      });
    });
  }catch(error){
    console.error("[CONTACT EVENT ACTIONS] An error ocurred in directCounter: ", error);
  }

};
export const refreshUser = (userId) => (dispatch, getState) => {
  try{
    axios
    .get(`${secureUrl.hostUrl}/api/contact_events/refresh?userId=${userId}`
    )
    .then((resp) => {
      dispatch({
        type: REFRESH_CONTACT,
        payload: resp.data,
      });
    });
  }catch(error){
    console.error("[CONTACT EVENT ACTIONS] An error ocurred in refreshUser: ", error);
  }

};