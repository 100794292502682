export const LoggedItem = {
    USER_ID: 'userId',
    USER_TOCKEN: 'userToken',
    USER_INFO: 'userInfo',
};
export const EMPTY_STRING = '';
export const DASHBOARD='Dashboard';
export const SCRIPT_SRC='https://d1p5cqqchvbqmy.cloudfront.net/web/release/mappedin-web.js';
export const REDIRECT={
    login: '/',
    dashboard: '/dashboard'
};
export const MAP='map';