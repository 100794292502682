import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = (theme) => ({
    equipmentCategory:{
        textAlign:'left'
    },
    cancelBtn: {
        marginLeft: '15px'
    },
    problemIcon: {
        fill:'#FFC738',
        fontSize:'200px',
        marginLeft:'100px'
    },
    inlineContainer: {
        display:'inline'
    },
    boldMsg:{
        display:'inline',
        fontWeight:'bold'
    },
    titleLbl: {
        color:'rgba(0, 0, 0, 0.6)',
        fontWeight: '500',
        letterSpacing:'0.25px',
        marginLeft: '10px',
        fontSize: '18px'
    },
    iconTitle: {
        color:'rgba(0, 0, 0, 0.6)',
        fontSize: '30px',
        marginTop: '15px'
    },
    dateInput: {
        width: "100%",
        marginRight: "5%"
    },
    titleContainer: {
        display:'flex',
        flexDirection:'row',
        marginTop: '10px'
    },
    formContainer: {
        justifyContent:'space-between',
        width:'70%',
        paddingBottom:'20px',
        display:'flex',
        marginTop: '10px'
    },
    containedBtn: {
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
});