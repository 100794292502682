/*
See LICENSE folder for this sample’s licensing information.

Abstract:
A level picker control that lets users choose the floor to display on the map.
*/
"use strict";
import * as CONSTS from './IMDF.constants';
import { getFloorColorByOrdinal } from '../floorColors';

export class LevelPicker {
    constructor(archive, onSelectedOrdinal, mapConfigs) {
        this.mapConfigs = mapConfigs;
        this.archive = archive;
        this.onSelectedOrdinal = onSelectedOrdinal;
        this.element = Object.assign(document.createElement(CONSTS.UL),{id:CONSTS.LEVELPICKER});
        //this.element = document.createElement(CONSTS.UL);
        //this.element.id = CONSTS.LEVELPICKER;
        this.element.style.display = CONSTS.BLOCK;
        this.currentOrdinal = undefined;
        //let mapContainer = document.getElementById(CONSTS.MAPCONTAINER);
        //mapContainer.append(this.element);
        this.levelNames = [];
        this.shortNameByOrdinal = {};
        this.loadLevelPicker();
        this.toggleShow = false;
    }

    /* Multiple levels may be related to the same ordinal and are represented by one level picker entry.
     * This sample code uses one short name and full name for each ordinal for simplicity.
     */
    loadLevelPicker() {
        this.archive.featuresByType[CONSTS.LEVEL].forEach((level) => {
            var ordinal = level.properties.ordinal;
            if (!(ordinal in this.shortNameByOrdinal)) {
                // This example chooses to display the short name in the level picker.
                var shortName = (level.properties.short_name || {}).en || ordinal.toString();
                if (shortName) {
                    var fullName = (level.properties.name || {}).en || shortName;
                    this.levelNames.push({ ordinal: ordinal, fullName: fullName, shortName: shortName });
                    this.shortNameByOrdinal[ordinal] = true;
                }
            }
        });
        /* Sort the levels in the picker so that the top floor appears at the top of the picker,
        * and the lowest floor at the bottom.
        */
        this.levelNames.sort((a, b) => {
            if (a.ordinal < b.ordinal) return 1;
            if (a.ordinal > b.ordinal) return -1;
            return 0;
        });

        var switcherBtn = document.createElement(CONSTS.BUTTON);
        switcherBtn.classList.add(CONSTS.SWITCHBUTTON);
        var text = document.createTextNode(CONSTS.X);
        switcherBtn.appendChild(text);
        this.element.appendChild(switcherBtn);
        var button = this;
        switcherBtn.addEventListener(CONSTS.CLICK, function (e) {
            button.hideList();
        });

        var label = document.createElement(CONSTS.LI);
        label.textContent = "";
        label.classList.add(CONSTS.FLOOR_SELECTED);
        // levelElement.style.borderLeft= "7px solid" + floorColor;
        label.style.display = CONSTS.NONE;
        this.element.appendChild(label);
        var lbl = this;
        label.addEventListener(CONSTS.CLICK, function (e) {
            lbl.showList();
        });

        this.levelNames.map((levelName, i) => {
            let floorColor = "";
            let floorLabel = "";
            console.log(`[levelPicker] mapConfig floors data: ${JSON.stringify(this.mapConfigs.floors)}`);
            if (!!this.mapConfigs.floors) {
                this.mapConfigs.floors.map(floor => {
                    if (floor.ordinal === levelName.ordinal) {
                        //floorColor = floor.color;
                        floorColor = getFloorColorByOrdinal(floor.ordinal);
                        floorLabel = floor.label;
                        levelName.shortName = floor.short_name;
                        levelName.fullName = floor.long_name;
                    }
                });
            }

            var levelElement = document.createElement(CONSTS.LI);
            levelElement.textContent = levelName.shortName;
            levelElement.id = levelName.ordinal;
            levelElement.classList.add(CONSTS.LEVEL_ORDINAL + levelName.ordinal);
            if (i === 0) {
                levelElement.style.paddingTop = "8px";
                levelElement.style.borderTopLeftRadius = "8px";
                levelElement.style.borderTopRightRadius = "8px";
            }
            if (i === this.levelNames.length - 1) {
                levelElement.style.paddingBottom = "8px";
                levelElement.style.borderBottomLeftRadius = "8px";
                levelElement.style.borderBottomRightRadius = "8px";
            }
            //levelElement.setAttribute(CONSTS.TITLE, levelName.fullName);
            levelElement.style.borderLeft = CONSTS.BORDER_LEFT + floorColor;
            this.element.appendChild(levelElement);
            var self = this;
            levelElement.addEventListener(CONSTS.CLICK, function (e) {
                self.selectOrdinal(levelName.ordinal);
            });
        }, this);
    }

    showList = () => {
        let matches = this.element.querySelectorAll(CONSTS.LI);
        let button = this.element.querySelector(CONSTS.BUTTON);
        let label = matches[0];

        this.toggleShow = !this.toggleShow;
        matches.forEach((item, index) => {
            if (index !== 0) {
                item.style.display = CONSTS.BLOCK;
                button.style.display = CONSTS.BLOCK;
                label.style.display = CONSTS.NONE;
            }
        });
    }

    hideList = () => {
        this.toggleShow = !this.toggleShow;
        let selected = this.element.querySelector(CONSTS.SELECTED);
        let matches = this.element.querySelectorAll(CONSTS.LI);
        let button = this.element.querySelector(CONSTS.BUTTON);
        let label = matches[0];

        if (this.toggleShow) {
            let title = selected.title;
            let text = selected.textContent;
            label.textContent = text;
            label.title = title;
            let style = getComputedStyle(selected);
            //label.style.borderLeft = style.borderLeft;  //? Only show color in border
            label.style.backgroundColor = style.borderLeftColor;
            if (!!this.mapConfigs.floors && (style.borderLeftColor === getFloorColorByOrdinal(this.mapConfigs.floors[3].ordinal) || style.borderLeftColor === getFloorColorByOrdinal(this.mapConfigs.floors[1].ordinal))) {
                label.style.color = CONSTS.DEFAULT_BORDER_COLOR;
            }

            else {
                label.style.color = CONSTS.WHITE;
            }


            matches.forEach((item, index) => {
                if (index !== 0) {
                    item.style.display = CONSTS.NONE;
                    button.style.display = CONSTS.NONE;
                    label.style.display = CONSTS.BLOCK;
                }
            });
        }
    };

    selectOrdinal = (ordinal) => {
        this.onSelectedOrdinal(ordinal, this.currentOrdinal);
        this.currentOrdinal = ordinal;
        var selected = this.element.querySelector(CONSTS.SELECTED)
        if (selected) {
            selected.classList.remove(CONSTS.SELECTED_CLASS);
            selected.style.backgroundColor = CONSTS.DEFAULT_BACKGROUND_COLOR;
        }
        this.element.querySelector(CONSTS.LEVEL_ORDINAL_CLASS + ordinal).classList.add(CONSTS.SELECTED_CLASS);

        let currentLevel = this.element.querySelector(CONSTS.LEVEL_ORDINAL_CLASS + ordinal);
        let style = getComputedStyle(currentLevel);
        currentLevel.style.backgroundColor = style.borderLeftColor;

    };

    mapAppend = () => {
        let mapContainer = document.getElementById(CONSTS.MAPCONTAINER);
        mapContainer.append(this.element);
    };

}
