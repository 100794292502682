export const INFORMATION_FORM_SUBTITLE = 'Information';
export const PASSWORD_FORM_SUBTITLE = 'Password';
export const BADGE_FORM_SUBTITLE = 'Badge';
export const LOGIN_ACTIVE_DIRECTORY = 'Login with Microsoft';
export const EMPTY_FORM_ERROR = 'empty';
export const WRONG_FORMAT_FORM_ERROR = 'incorrectFormat';
export const DUPLICATED_FORM_ERROR = 'duplicated';
export const INCORRECT_ENTRY_ERROR = 'Incorrect entry';
export const DUPLICATED_USERNAME = 'username';
export const PASS_DONOT_MATCH = "Passwords don't match!";
export const PASSWORD_ERRORS = {
    duplicated: "Email already registered!",
    empty: "Email cannot be empty!",
    incorrectFormat: "Incorrect email format!",
    username: "Username already registered!"
};
export const EDIT_USER = 'EDIT USER';
export const NEW_USER = 'NEW USER';
export const FIRST_NAME_EMPTY_ERROR = "First Name cannot be empty!";
export const LAST_NAME_EMPTY_ERROR = "Last Name cannot be empty!";
export const ROLE_EMPTY_ERROR = "Role cannot be empty!";
export const DEFAULT_PASSWORD = 'Henryford1!';
export const LOGGED_OUT = 'logged out';
export const MESSAGE = 'message';
export const SNACKBAR_SUCCES = 'success';
export const SNACKBAR_WARNING = 'warning';
export const UPDATE_USER_ACTION = 'Update User';
export const UPDATE_USER_ERROR = 'Update User Error';
export const CREATE_USER_ACTION = 'Create User';
export const CREATE_USER_ACTION_ERROR = 'Create User Error';
export const DELETE_BTN_LABEL = 'Delete';
export const CANCEL_BTN_LABEL = 'Cancel';
export const SAVE_BTN_LABEL = 'Save';
export const CHANGE_PASSWORD = 'Change Password';
export const UPLOAD_PICTURE = 'Upload';
export const BASIC_AUTH = 'Basic Auth';
export const ACTIVE_DIRECTORY = 'Microsoft';
export const RESTORE_PASSWORD = 'Basic Auth';
export const BACK_TO_TABLE = 'BACK TO TABLE';
export const BUTTON_TYPE = {
    error:'error',
    default: 'default',
    success: 'success',
    defaultFlat: 'default-flat'
};
export const UPLOAD_ICON_TYPE ='upload';
export const PASSWORD_INPUT_LABEL = 'Password';
export const CONFIRM_PASS_INPUT_LABEL = 'Confim Password';
export const FIRST_NAME_INPUT_LABEL = 'First Name';
export const LAST_NAME_INPUT_LABEL = 'Last Name';
export const EMAIL_INPUT_LABEL ='Email';
export const ROLE_INPUT_LABEL = 'Role';
export const BADGE_INPUT_LABEL ='Badge Number';
export const SPINNER_DEFAULT_CONFIG='default';
export const UPLOAD_INPUT_ID= 'avatar-image-upload';
