import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = (theme) => ({
    compositionDiv: {
        display:'inline',
        width:'90%'
    },
    trackDiv: {
        display:'inline',
        width:'90%'
    },
    buttonDiv: {
        display:'inline',
        float:'right',
        width:'50%',
        paddingBottom:'10px'
    },
    permissionDiv: {
        display:'inline',
        float:'right',
        width:'10%'
    },
    actionDiv: {
        display:'inline', width:'80%'
    },
    accessPermissionDiv: {
        display:'inline',
        width:'50%'
    },
    paperTeam: {
        maxHeight: "65vh",
        overflow: 'auto',
        boxShadow:'none'
    },
    newTeamContainer: {
        display:'inline',
        float:'right',
        width:'20%'
    },
    flexSubContainer: {
        display:'inline',
        width:'60%'
    },
    permissionLbl: {
        color:'#9E9E9E',
        letterSpacing:'0.1px'
    },
    actionLbl: {
        color:'#9E9E9E',
        letterSpacing:'0.1px',
        marginLeft:'20px'
    },
    flexContainer: {
        display:'flex'
    },
    displayButtonRegular: {
        display:'flex',
        float:'right'
    },
    displayButton:{
        display:'inline',
        marginLeft:'20px'
    },
    blockContainer: {
        display: 'block'
    },
    roleIcon: {
        margin:'10px',
        fontSize:'25px',
        fill:'#2843A3'
    },
    rolePng: {
        margin:'11px'
    },
    inlineContainer: {
        display:'inline'
    },
    boldSnackMsg: {
        display:'inline',
        fontWeight:'bold'
    },
    errorText: {
        textAlign:'center'
    },
    problemIcon: {
        fill:'#FFC738',
        fontSize:'200px',
        marginLeft:'100px'
    },
    headerIcon:{
        fill:'#2843A3',
        fontSize:'50px',
        opacity:'0.7',
        marginLeft:'5%'
    },
    iconDiv:{
        marginLeft:"5%"
    },
    teamBtn:{
        "&:hover": {
          backgroundColor: lightBlue[50],
          cursor:'pointer',
          color: '#2196F3',
          "@media (hover: none)": {
            backgroundColor: lightBlue[50]
          }
        }
    },
    containedBtn: {
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    },
});