import React from "react";
import './Autocomplete.css';
import _ from "lodash";


class Autocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: this.props.allContacts,
            suggestions:[], 
            text: "",
        };
    }
    
    onTextChanged = (e) => {
        const value = e.target.value;
        let suggestions = [];
        if (value.length > 0){
            const regex = new RegExp(`^${value}`, "i");
            const allContactsArr = this.state.contacts.map(contact => `${contact.first_name} ${contact.last_name}`);
            suggestions = (allContactsArr.sort().filter(contact => regex.test(contact) ? `${contact.first_name} ${contact.last_name}` : ""))[0] || [];  
        }
        this.setState(() => ({ suggestions, text: value  })); 
    } 

    suggestionSelected(value) {
        this.setState(() => ({
            text: value,
            suggestions: [],
        }))
        this.props.onChange ( _ , value);
    }

    renderSuggestions() {
        const{ suggestions } = this.state;
        if(suggestions.length === 0){
          return null; 
        }
        return (
            <ul>
                <li onClick={ () => this.suggestionSelected(suggestions)}>{suggestions}</li>
            </ul> 
        )
    }
    

    render(){
        const { text } = this.state;
        return(
            <div className="AutoComplete">
                <input value={text} onChange={this.onTextChanged} type="text"/>
                { this.renderSuggestions()  }
            </div>
        );
    }
}

export default Autocomplete;