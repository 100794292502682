import React, { useMemo, useState } from 'react';
import { connect } from "react-redux";
import Chart from "react-google-charts";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import { Icon } from "@material-ui/core";

import {
  usersCountByCampus,
  phonesCountByCampus,
  messagesCountByCampus,
  equipmentsCountByCampus,
  usersByCampusRole,
  messagesByCampusDate,
  messagesByAdmin,
  phonesByCampusRole,
} from '../../../actions/superAdmin/superAdminActions';
import { getAllPhones } from '../../../actions/phones/phonesActions';

import UserAdmin from './userAdmin.component';
import PhoneAdmin from './phoneAdmin.component';
import MessageAdmin from './messageAdmin.component';
import ReportCard from './reportCard.component';
import UserChart from './userChart.component';
import MessageChart from './messageChart.component';
import AwesomeSpinner from '../../utilities/spinner';
import campusIcon from './../img/multicampus.png';
import { DASHBOARD_CARD_TITLE, DASHBOARD_COMPONENT } from './superDashboard.constants';

import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
  cardRoot: {
    maxWidth: 345,
  },
  cardTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: 23,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2843A3',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 50,
  },
  userBtn: {
    height: 32,
    width: 226,
    borderRadius: 22,
    background: '#0095FF',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'normal',
    border: '0.5px solid',
    textTransform: 'unset',
    color: '#FFFFFF',
    marginTop: 5,
    '&:hover': {
      backgroundColor: '#0096DD',
    },
  },
  chartContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 50,
    justifyContent: 'space-around',
  },
  userContainer: {
    display: 'flex',
    flexGrow: 2,
    flexDirection: 'column',
    background: '#FFFFFF',
    border: '1px solid #EFF4FA',
    //width: 710,
    //height: 480,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    padding: 20,
    '&:hover': {
      border: '1px solid #2196F3',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }
  },
  messageContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    background: '#FFFFFF',
    border: '1px solid #EFF4FA',
    //width: 265,
    //height: 480,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    padding: 20,
    '&:hover': {
      border: '1px solid #2196F3',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }
  },
}));

function SuperDashboard(props) {
  const classes = useStyles();
  const { dashboard, users, phones, messages } = DASHBOARD_COMPONENT;

  const {
    usersByCampus,
    phonesByCampus,
    messagesByCampus,
    equipmentsByCampus,
  } = props;
  const [showView, setShowView] = useState(dashboard);
  const [userView, setUserView] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  useMemo(() => {
    let usersCount = props.usersCountByCampus();
    let phonesCount = props.phonesCountByCampus();
    let messagesCount = props.messagesCountByCampus();
    let equipmentsCount = props.equipmentsCountByCampus();
    let usersByCampus = props.usersByCampusRole();
    let messagesByCampus = props.messagesByCampusDate();
    let allPhones = props.getAllPhones();
    let messagesAdmin = props.messagesByAdmin();
    let phonesByCampus = props.phonesByCampusRole();

    let promiseArray = [
      usersCount,
      phonesCount,
      messagesCount,
      equipmentsCount,
      usersByCampus,
      messagesByCampus,
      allPhones,
      messagesAdmin,
      phonesByCampus,
    ];
    Promise.all(promiseArray).then((resultsArray) => {
      setDataLoaded(true);
    });
  }, []);

  const changeOption = (opt) => {
    setShowView(opt);
  }

  const Dashboard = () => {
    return (
      <>
        {dataLoaded ?
          (<div
            id="webNavv-superAdmin-dashboard-1.0"
          >
            <div className={classes.titleContainer}>
              <Icon style={{ width: 45, height: 35 }}>
                <img src={campusIcon} height={35} width={35} />
              </Icon>
              <span
                id="webNavv-superAdmin-dashboard-title-1.0"
                className={classes.titleText}
              >
                Dashboard
              </span>
            </div>
            <div className={classes.cardContainer}>
              <ReportCard title={DASHBOARD_CARD_TITLE.users} reportData={usersByCampus} />
              <ReportCard title={DASHBOARD_CARD_TITLE.phones} reportData={phonesByCampus} />
              <ReportCard title={DASHBOARD_CARD_TITLE.messages} reportData={messagesByCampus} />
              <ReportCard title={DASHBOARD_CARD_TITLE.equipments} reportData={equipmentsByCampus} />
            </div>
            <div className={classes.chartContainer}>
              <div
                id="webNavv-superAdmin-dashboard-usersGraph-1.0"
                className={classes.userContainer}
              >
                <UserChart onChangeChart={(v) => setUserView(v)} chartOption={userView} />
                <Button className={classes.userBtn} onClick={() => setShowView(userView === 0 ? users : phones)}>Generate report</Button>
              </div>

              <div
                id="webNavv-superAdmin-dashboard-messagesGraph-1.0"
                className={classes.messageContainer}
              >
                <MessageChart />
                <Button className={classes.userBtn} style={{ width: 185 }} onClick={() => setShowView('m')}>Generate More</Button>
              </div>
            </div>
          </div>) : (
            <div>
              <AwesomeSpinner config={'default'} loading={true} navOpen={true} />
            </div>
          )
        }
      </>
    );
  };

  const UserReport = () => {
    return (
      <>
        <UserAdmin goBackToDashboard={changeOption} />
      </>
    );
  };

  const PhoneReport = () => {
    return (
      <>
        <PhoneAdmin goBackToDashboard={changeOption} />
      </>
    );
  };

  const MessageReport = () => {
    return (
      <>
        <MessageAdmin goBackToDashboard={changeOption} />
      </>
    );
  };

  const renderOption = (opt) => {
    switch (opt) {
      case dashboard: return <Dashboard />
      case users: return <UserReport />
      case phones: return <PhoneReport />
      case messages: return <MessageReport />
      default: return <Dashboard />
    }
  }

  return (
    renderOption(showView)
  );
}

const mapStateToProps = state => ({
  users: state.user.users,
  usersByCampus: state.superAdmin.usersByCampus,
  phonesByCampus: state.superAdmin.phonesByCampus,
  messagesByCampus: state.superAdmin.messagesByCampus,
  equipmentsByCampus: state.superAdmin.equipmentsByCampus,
  usersCampusRole: state.superAdmin.usersByCampusRole,
  //messagesByCampusDate: state.superAdmin.messagesByCampusDate,
  user: state.superAdmin.user,
  auth: state.auth
});

export default connect(mapStateToProps, { usersCountByCampus, phonesCountByCampus, messagesCountByCampus, equipmentsCountByCampus, usersByCampusRole, messagesByCampusDate, getAllPhones, messagesByAdmin, phonesByCampusRole })(SuperDashboard);
