import Notify from 'notifyjs';

if (!Notify.needsPermission) {
    doNotification();
} else if (Notify.isSupported()) {
    Notify.requestPermission(onPermissionGranted, onPermissionDenied);
}

function onPermissionGranted() {
	console.info('[NOTIFY] Permission has been granted by the user');
	doNotification();
}

function onPermissionDenied() {
	console.warn(' [NOTIFY] Permission has been denied by the user');
}

function doNotification () {
    var myNotification = new Notify('NavvTrack', {
        body: 'Welcome to NavvTrack',
        tag: 'novatrack1',
        notifyClick: onClickNotification,
        notifyError: onErrorNotification,
        timeout: 4
    });
    myNotification.show();
}




function onClickNotification () {
    console.info('[NOTIFY] notification was clicked!');
}

function onErrorNotification () {
    console.error('[NOTIFY] Error showing notification. You may need to request permission.');
}

if (!Notify.needsPermission) {
    doNotification();
} else if (Notify.isSupported()) {
    Notify.requestPermission(onPermissionGranted, onPermissionDenied);
}
