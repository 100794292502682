import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import lightBlue from "@material-ui/core/colors/lightBlue";

import MasterButton from './button.component';
function SimpleModalPop(props){
    const classes = useStyles();

    return(
       <Dialog
        className={classes.dialog}
        open={props.openModal}
        onClose={props.handleCloseModal}>
        <div className={classes.deleteMsgContainer}>
          {props.icon}
          <div className={classes.errorTitle}>{props.title}</div>
          <p className={classes.errorText}>{props.subtitle}</p>
          <div className={classes.buttonContainer}>
            <MasterButton
              keyLbl={'success-btn'}
              label={props.buttonLabel}
              buttonType={'success'}
              isDisabled={false}
              handleClick={props.handleOption}
              size={"medium"}
              styles={{marginLeft:'30px'}}
              class={classes.containedBtn}/>
          </div>
        </div>
       </Dialog>
    );
};

export const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display:'flex',
        marginLeft:'35%',
        marginTop:'40px'
    },
    dialog: {
        marginLeft:'25vw',
        width:"750px"
    },
    deleteMsgContainer: {
        display:'inline-block',
        width:'450px',
        marginTop:'25px',
        marginBottom:'25px'
    },
    errorTitle:{
        color: "#838383",
        textAlign:'center',
        fontSize: 44,
        paddingTop:25,
    },
    errorText: {
        textAlign:'center',
        color:'#2843A3'
    },
    containedBtn: {
        color: theme.palette.getContrastText(lightBlue[600]),
        backgroundColor: lightBlue[600],
        "&:hover": {
          backgroundColor: lightBlue[800],
          "@media (hover: none)": {
            backgroundColor: lightBlue[600]
          }
        }
    }
}));

export default SimpleModalPop;