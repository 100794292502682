export const YES='Yes';
export const NO='No';
export const NA='N/A';
export const USER_INFO='User Information';
export const DEVICE_INFO='Device Information';
export const USER_LABELS={
    id: 'Id:',
    worker: 'Worker:',
    role: 'Team:',
    online: 'Online:'
};
export const DEVICE_LABELS={
    deviceTag: 'Device Tag:',
    model:'Model:',
    assignTo:'Assigned To:',
    usedBy:'Used By:',
    active:'Active:',
    label:'Label:',
    appVersion:'App Version:'
};
export const NO_INFO='No more information'