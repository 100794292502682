import { LIST_USERS } from "../actions/contactUserList/types";

const initialState = {
  usersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_USERS:
      return {
        ...state,
        usersList: action.payload,
      };
    default:
      return state;
  }
}
