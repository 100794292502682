import React from 'react';

import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {filterStyles} from '../styles';
import * as CONSTS from "./trackInfo.constants";
import _ from 'lodash';
import "./trackInfo.css";

const TrackUserInfo = (props) =>{
    const {transporterFound, phones, users, models} = props;
    let userTracked = users.find((user)=>user._id === transporterFound) || {};
    let deviceTracked = {};
    if (userTracked.device_id !== undefined && userTracked.device_id !== null)
      deviceTracked =  phones.find((phone)=>phone.UUID === userTracked.device_id.trim()) || {};

    let modelInfo = models.find(model=>model.id ===deviceTracked.model);
    let showPhoneTrackedInfo = false;
    let assignName = CONSTS.NA;
    let usedName = CONSTS.NA;
    let deviceActive = CONSTS.NO;
    if (!_.isEmpty(deviceTracked)) {
      showPhoneTrackedInfo = true;
      [assignName, usedName] = props.getDeviceTrackedInfo(deviceTracked);
      deviceActive = deviceTracked.active ? CONSTS.YES : CONSTS.NO;
    }
    let userName = `${userTracked.first_name} ${userTracked.last_name}`;
    let online = userTracked.online != true ? CONSTS.NO : CONSTS.YES;
    let label = !!deviceTracked.label ? deviceTracked.label : 'N/A';
    //let appVersion = !!deviceTracked.versionAndBuild ? deviceTracked.versionAndBuild : 'N/A';

    let appVersion = 'N/A';
    if (!!deviceTracked.versionAndBuild) {
      let phoneData = deviceTracked.versionAndBuild.split("|");
      appVersion = `v${phoneData[0]} b${phoneData[1]}`;
    }
    
    return (
      <div id="webNavv-tableView-teamManager-trackUserInfo-1.0">
        <Paper style={{maxHeight: "32vh", overflow: 'auto', boxShadow: 'none'}}>
          <Divider/>
          <div className='TrackInfo_ContainerSubheader' onClick={()=>props.setShowUserTrackedInfo(!props.showUserTrackedInfo)}>
            <div className='TrackInfo_TextSubheader' >{CONSTS.USER_INFO}</div>
          </div>
          {props.showUserTrackedInfo &&
            <React.Fragment>
              <div id="webNavv-tableView-teamManager-trackUserInfo-userName-1.0" className='TrackInfo_Container'>
                <div className='TrackInfo_TextTitle' >{CONSTS.USER_LABELS.worker}</div>
                <div className='TrackInfo_Text' >{userName}</div>
              </div>
              <div id="webNavv-tableView-teamManager-trackUserInfo-userTeam-1.0" className='TrackInfo_Container'>
                <div className='TrackInfo_TextTitle' >{CONSTS.USER_LABELS.role}</div>
                <div className='TrackInfo_Text' >{userTracked.role_name}</div>
              </div>
            </React.Fragment>
          }
          <Divider/>
          <div className='TrackInfo_ContainerSubheader' onClick={()=>props.setShowPhoneTrackedList(!props.showPhoneTrackedList)}>
            <div className='TrackInfo_TextSubheader' >{CONSTS.DEVICE_INFO}</div>
          </div>
          {props.showPhoneTrackedList && <React.Fragment>
              <div id="webNavv-tableView-teamManager-trackUserInfo-deviceTag-1.0" className='TrackInfo_Container'>
                <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.deviceTag}</div>
                <div className='TrackInfo_Text' >{userTracked.device_id}</div>
              </div>
            {showPhoneTrackedInfo ?
              <div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceModel-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.model}</div>
                  <div className='TrackInfo_Text' >{modelInfo.model}</div>
                </div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceAssignedTo-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.assignTo}</div>
                  <div className='TrackInfo_Text' >{assignName}</div>
                </div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceUsedBy-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.usedBy}</div>
                  <div className='TrackInfo_Text' >{usedName}</div>
                </div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceActive-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.active}</div>
                  <div className='TrackInfo_Text' >{deviceActive}</div>
                </div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceLabel-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.label}</div>
                  <div className='TrackInfo_Text' >{label}</div>
                </div>
                <div id="webNavv-tableView-teamManager-trackUserInfo-deviceAppVersion-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_TextTitle' >{CONSTS.DEVICE_LABELS.appVersion}</div>
                  <div className='TrackInfo_Text' >{appVersion}</div>
                </div>
              </div>
              : <div id="webNavv-tableView-teamManager-trackUserInfo-deviceInfoNotAvailable-1.0" className='TrackInfo_Container'>
                  <div className='TrackInfo_Text' >{CONSTS.NO_INFO}</div>
                </div>
            }
          </React.Fragment>
          }
        </Paper>
      </div>
    );
}


export default withStyles(filterStyles)(TrackUserInfo);