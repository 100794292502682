export default {
            origin:{
                channel : "",
                id : "111111111111111111111111",
                name :`NAVVCAST Messages`
            },
            destiny:{
                channel : "",
                id:"222222222222222222222222"
            }
}