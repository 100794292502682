export const SUCCESS='success';
export const WARNING='warning';
export const ERROR='error';
export const ROLE='role';
export const UPDATE_ACTION='Update Team';
export const UPDATE_ACTION_ERROR='Update Team Error';
export const CREATE_ACTION='Create Team';
export const CREATE_ACTION_ERROR='Create Team Error';
export const DELETE_ACTION='Delete Team';
export const DELETE_ACTION_ERROR='Delete Team Error';
export const ACCOUNTS_ASSOCIATED="The Team can't be deleted due to it has accounts associated!";
export const DELETE_MSG="Team  deleted successfully!";
export const DELETE_MSG_ERROR="The Team couldn't be deleted!";
export const ACCESS_CONTROL='Access Control';
export const TEAMS='Teams';
export const ACCESS_PERMISSIONS="Access Permissions";
export const ACTION='Action';
export const PERMISSION='Permission';
export const TRACK_PERMISSIONS='Track Permissions';
export const TEAM_COMPOSITION='Team Composition';
export const ROLEICON='M15 6a1.54 1.54 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0A1.54 1.54 0 0 1 15 6zm-1.5-5A5.55 5.55 0 0 0 8 6.5a6.81 6.81 0 0 0 .7 2.8L1 17v2h4v-2h2v-2h2l3.2-3.2a5.85 5.85 0 0 0 1.3.2A5.55 5.55 0 0 0 19 6.5 5.55 5.55 0 0 0 13.5 1z';
export const DEFAULT_VIEWS = [
    {name:"Admin Panel", id: 1, access:false},
    {name:"Active Map", id: 2, access:false},
    {name:"Table View", id: 3, access:false},
    {name:"Team Manager", id: 4, access:false},
    {name:"History Table", id: 5, access:false},
    {name:"History Map", id: 6, access:false},
    {name:"Chat View", id: 7, access:false},
    {name:"Fleet Manager", id: 8, access:false},
    {name:"Equipment", id: 9, access:false},
    {name:"Contact Panel", id: 10, access:false},
    {name:"Configuration Panel", id: 11, access:false},
    {name:"Wayfinding", id: 12, access:false},
    {name:"Navvcast", id:13, access:false},
    {name:"Workflow Automation", id:14, access:false}
];
export const VIEWS={
    equipmentMap:"Equipment",
    contactPanel:"Contact Panel",
    configurationPanel:"Configuration Panel",
    multicampusPanel:"Multicampus Panel"
};

export const USERS="Users:";
export const PHONES="Phones:";
export const EQUIPMENT="Equipment:";
export const CREATE_DATE="Create date:";
export const TRACK="Track:";
export const LAST_UPDATE="Last update:";
export const DATE_FORMAT="MM/DD/YYYY HH:mm";
export const PROPERTIES={
    createDate:"create_date",
    lastUpdate:"last_update"
}
export const ACCESS_INFO={
    DESC:[
        { 
            view_name: 'Admin Panel',
            description:'Manage the roles and teams of the current location.'
        },
        {
            view_name: 'Active Map',
            description:'View and interact with the location map.'
        },
        {
            view_name: 'Table View',
            description:''
        },
        {
            view_name: 'Team Manager',
            description:'Shows the status and location of each worker.'
        },
        {
            view_name: 'History Table',
            description:'Shows the history of all jobs information and the traces of each worker.'
        },
        {
            view_name: 'History Map',
            description:'Shows the traces history of the map.'
        },
        {
            view_name: 'Chat view',
            description:'Allows the user to send and receive chats.'
        },
        {
            view_name: 'Fleet Manager',
            description:'Shows all the devices used by location workers.'
        },
        {
            view_name: 'Equipment',
            description:'Shows Equipment tables and Equipment map.'
        },
        {
            view_name: 'Contact Panel',
            description:'Shows dashboard and contact table.'
        },
        {
            view_name: 'Configuration Panel',
            description:'Shows configuration panel.'
        },
        {
            view_name: 'Wayfinding',
            description:'Shows Mappedin Campus map.'
        },
        {
            view_name: 'Navvcast',
            description:'Users can send broadcast messages.'
        },
        {
            view_name: 'Workflow Automation',
            description:'Shows jobs board and jobs history'
        },
        {
            view_name: 'Multicampus Panel',
            description:'Shows multicampus configuration panel.'
        }
    ]
}