import React from 'react';
import {useStyles, CustomSwitch} from './styles';
import {ACCESS_INFO} from '../accesscontrol.constants';

import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Divider from 'material-ui/Divider';

function PermissionsList(props){
    const classes = useStyles();
    const handleFirstCondition = (item) =>item.id !== 3 && item.id !== 6  && item.id !== 14 && item.id !== 9;
    const shouldUserSeeWorkflowAutomationPermission = (item) => item.id === 14 && props.featureFlags.SpecimenCollection.Permission;
    const shouldUserSeeEquipmentPermission = (item) => item.id === 9 && props.featureFlags.Equipment.Permission;

    return (
      <div id="webNavv-adminPanel-accessControl-accessManager-1.0">
        {props.views.map((item, index)=>{
          if(handleFirstCondition(item) || shouldUserSeeWorkflowAutomationPermission(item) || shouldUserSeeEquipmentPermission(item)){
            var filter=props.selectedviews.filter(e=>{return e.id === item.id && e.access?true:false});
            let defaultValue = filter.length>0?filter[0].access:item.access;
            return(
            <div key={index} id={`${index}-webNavv-adminPanel-accessControl-accessManager-accessPermission-1.0`}>
            <Box textAlign='left' bgcolor='white'>
            <div className={classes.blockContainer}>
              <div className={classes.showAccessIconContainer}>
                  {props.handleAccessIcon(index)}
              </div>
              <div className={classes.showAccessDescContainer}>
                <div className={classes.showAccesItemName}>
                  <div style={{color: '#2843A3'}}>{item.name}</div>
                </div>
                <div className={classes.blockContainer}>
                  <p  className={classes.showAccessDesc}>{ACCESS_INFO.DESC[index].description}</p>
                </div>
              </div>
              <div className={classes.showAccessPermission}>
              {props.teamSelected && props.editTeam && !props.showTeamForm &&
                <CustomSwitch
                  checked={props.viewPermissions[index].access || false}
                  onChange={props.handleToggle}
                  name={(props.viewPermissions[index].id).toString()}
                />
              }
              {props.showTeamForm && !props.teamSelected &&
                <CustomSwitch
                  checked={props.viewPermissions[index].access || false}
                  onChange={props.handleToggle}
                  name={(props.viewPermissions[index].id).toString()}
                />
              }
              {props.teamSelected && !props.editTeam && defaultValue &&
                <CheckIcon className={classes.showAccessCheckIcon}/>
              }
              {props.teamSelected && !props.editTeam && !defaultValue &&
               <CloseIcon className={classes.showAccessCloseIcon}/>
              }
              {!props.showTeamForm && !props.teamSelected &&
              <CustomSwitch
                  disabled
                  checked={false}
                  name="DefaultAccess"
              />
              }
              </div>
             </div>
            </Box>
            <Divider/>
            </div>
            )
          } else {
            return null
          }
        })
        }
      </div>
    );
};

export default PermissionsList;