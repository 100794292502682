import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import _ from "lodash";

import PropTypes from "prop-types";

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import moment from "moment";

import { fetchAllACLS, fetchRoles } from "../../../actions/acl/aclActions";
import { listUsers, deleteUser, patchUser } from '../../../actions/user/userActions';
import { allUsers, getSuperAdmins } from '../../../actions/superAdmin/superAdminActions';
import { createLog } from '../../../actions/logs/logsActions';

import MUIDataTable from "mui-datatables/dist/index";
import CustomToolbarSelect from '../utils/toolbarSelect.component.js';
import MasterAlert from '../../utilities/alert.component';
import ModalProp from '../../utilities/modal.component';
import AwesomeSpinner from '../../utilities/spinner';
import UserAddContainer from './userAddContainer.component';

import { styles } from './styles';

import {
  DEFAULT_SUCCES,
  EMPTY_STRING,
  FILLED,
  SUCCESS_NOTIFICATION,
  USER_COLUMNS,
  SUPERADMIN_NAME,
  DEFAULT_DATE_FORMAT,
  DELETE_USER_ACTION,
  RESTORE_USER_ACTION,
  DIALOG_DELETE_TITLE,
  DIALOG_DELETE_MSG,
  DIALOG_RESTORE_MSG,
  DELETE_BTN,
  RESTORE_BTN,
  CANCEL_BTN,
} from './users.constants';
import campusIcon from './img/multicampus.png';

const TABLE_SORT = (data, colIndex, order) => {

  if (colIndex === 3 || colIndex === 4) {
    return data.sort((a, b) => {
      return (new Date(a.data[colIndex]).getTime() - new Date(b.data[colIndex]).getTime()) * (order === "desc" ? 1 : -1);
    });
  } else {
    return data.sort((a, b) => {
      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === "desc" ? 1 : -1);
    });
  }
};

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: EMPTY_STRING,
      last_name: EMPTY_STRING,
      email: EMPTY_STRING,
      password: EMPTY_STRING,
      confirmPassword: EMPTY_STRING,
      open: false,
      openSnack: false,
      openModal: false,
      snackMsg: EMPTY_STRING,
      snackType: EMPTY_STRING,
      editUserInfo: null,
      userDelete: null,
      userRestore: null,
      showSpinner: true,
    };
  }

  expire = false;
  showTable = false;
  tableData = [];

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: "700px !important",
          },
          paper: {
            boxShadow: "0 4px 2px -2px gray",
            paddingTop: "1px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 15,
          },
          toolButton: {
            display: "flex",
            justifyContent: "center",
            color: "#7D7D7D",
          },
        },
      },
    });

  componentDidMount = () => {
    Promise.all([this.props.fetchRoles(), this.props.fetchAllACLS(), this.props.allUsers(), this.props.getSuperAdmins()]).then(values => {
      this.setState({ showSpinner: false });
    }).catch(err => {
      console.error("[USERADMIN] An error has ocurred loading data: ", err);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    //this.props.listUsers();
  }

  UNSAFE_componentWillMount = () => {
  };

  componentWillUpdate() {
    if (this.expire) this.cleanExpiredFields();
  }

  datesCustomRender = (filterList, onChange, index, column, label, key) => {
    return (
      <div>
        <TextField
          id={key}
          label={label}
          type="datetime-local"
          format="MM/dd/yyyy hh:mm"
          InputLabelProps={{
            shrink: true,
          }}
          value={filterList[index][0] || ""}
          onChange={(event) => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: "100%", marginRight: "5%" }}
        />
      </div>
    );
  };

  backToUsers = () => {
    this.setState({ open: false, editUserInfo: null });
  };

  onCreateUserSuccess = (msg) => {
    this.setState({ open: false, openSnack: true, snackMsg: msg, snackType: DEFAULT_SUCCES });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnack: false, snackMsg: EMPTY_STRING });
  };

  getCampusName = (id) => {
    const { campusList } = this.props;
    const campus = campusList.find(campus => campus.id === id);
    const campusName = campus ? campus.name : EMPTY_STRING;

    return campusName;
  };

  editUser = (u) => {
    const { roleList, aclList } = this.props;
    u.roleList = roleList.filter(role => aclList.filter(acl => acl.campusId === u.campusId && acl.role === role).length > 0);
    this.setState({ open: true, editUserInfo: u });
  };

  createUserLog = (data) => {
    let log = {
      action: data.action,
      description: data.description,
      timestamp: new Date(),
    };
    this.props.createLog(log);
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, userDelete: null, userRestore: null });
  };

  handleDelete = (u) => {
    this.setState({ openModal: true, userDelete: u });
  };

  handleRestore = (u) => {
    this.setState({ openModal: true, userRestore: u });
  };

  actionUser = () => {
    const u = !!this.state.userDelete ? this.state.userDelete : this.state.userRestore
    //const { userDelete: u } = this.state;
    /*
    if (u.role !== SUPERADMIN_NAME) {
      const superAdminId = this.props.user.id;
      const { classes } = this.props;
      this.props.deleteUser(u.id, u.campusId).then(res => {
        this.props.allUsers();
        this.createUserLog({
          action: DELETE_USER_ACTION,
          description: `User "${u.name}" deleted by "${superAdminId}"`
        });
        let msg = <div className={classes.snackMsg}>User "<div style={{ display: 'inline', fontWeight: 'bold' }}>{`${u.name}`}</div>" deleted successfully!</div>
        this.setState({ open: false, openSnack: true, snackMsg: msg, snackType: DEFAULT_SUCCES, userDelete: null, openModal: false });
      }).catch(err => {
        this.createUserLog({
          action: DELETE_USER_ACTION,
          description: `User "${u.name}" couldn't be deleted by "${superAdminId}" due endpoint ${err}`
        });
        let msg = <div className={classes.snackMsg}>User "<div style={{ display: 'inline', fontWeight: 'bold' }}>{`${u.name}`}</div>" couldn't be deleted!</div>
        this.setState({ open: false, openSnack: true, snackMsg: msg, snackType: DEFAULT_SUCCES, userDelete: null, openModal: false });
      });
    }
    */
    if (u.role !== SUPERADMIN_NAME) {
      const superAdminId = this.props.user.id;
      const { classes, user } = this.props;
      const completeName = `${user.first_name} ${user.last_name}`;
      const bodyInfo = {
        email: u.email,
        enabled: !this.state.userDelete
      };
      const textAction = !!this.state.userDelete ? "deleted" : "restored"
      this.props.patchUser(u.id, bodyInfo).then(res => {
        this.props.allUsers();
        this.createUserLog({
          action: !!this.state.userDelete ? DELETE_USER_ACTION : RESTORE_USER_ACTION,
          description: `User "${u.name}" ${textAction} by super admin "${completeName}"`,
          userId: superAdminId
        });
        let msg = <div className={classes.snackMsg}>User "<div style={{ display: 'inline', fontWeight: 'bold' }}>{`${u.name}`}</div>" {textAction} successfully!</div>
        this.setState({ open: false, openSnack: true, snackMsg: msg, snackType: DEFAULT_SUCCES, userDelete: null, userRestore: null, openModal: false });
      }).catch(err => {
        this.createUserLog({
          action: !!this.state.userDelete ? DELETE_USER_ACTION : RESTORE_USER_ACTION,
          description: `User "${u.name}" couldn't be ${textAction} by super admin "${completeName}" due endpoint ${err}`,
          userId: superAdminId
        });
        let msg = <div className={classes.snackMsg}>User "<div style={{ display: 'inline', fontWeight: 'bold' }}>{`${u.name}`}</div>" couldn't be {textAction}!</div>
        this.setState({ open: false, openSnack: true, snackMsg: msg, snackType: DEFAULT_SUCCES, userDelete: null, userRestore: null, openModal: false });
      });
    }
  };

  accountStatusRender = (value, tableMeta, updateValue) => {
    const { classes } = this.props;

    return (
      <div className={classes.statusLblContainer}>
        <div className={classes.statusLblDiv} style={{ backgroundColor: value ? "green" : "red" }}></div>
        {value ? "Enabled" : "Disabled"}
      </div>)
  };

  render() {

    const { classes } = this.props;

    let alertColor = { backgroundColor: '#00C614', borderBottom: '7px solid #008B0E' };

    let superadminData = this.props.superAdminList.map((user) => ({
      enabled: true,
      name: `${user.first_name} ${user.last_name}`.trim(),
      campus: 'All',
      role: SUPERADMIN_NAME,
      email: user.email.trim(),
      lastLogin: user.last_login ? moment(user.last_login).format(DEFAULT_DATE_FORMAT) : EMPTY_STRING,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name
    }));

    let userData = this.props.users.filter(u => !u.isSuperadmin).map((user) => ({
      enabled: user.enabled,
      name: `${user.first_name} ${user.last_name}`.trim(),
      campus: this.getCampusName(user.campusId),
      role: user.role_name || EMPTY_STRING,
      email: user.email.trim(),
      lastLogin: user.last_login ? moment(user.last_login).format(DEFAULT_DATE_FORMAT) : EMPTY_STRING,
      id: user.id,
      roleId: user.role,
      campusId: user.campusId,
      firstName: user.first_name,
      lastName: user.last_name
    }));

    let data = [...superadminData, ...userData];

    this.showTable = true;

    const columns = [
      {
        name: "enabled",
        label: USER_COLUMNS.status,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          /*
          customHeadRender: (value, tableMeta)=>{
            return (<div
              className="MuiTableCell-root MuiTableCell-head"
              style={{width:'auto',top:"0px", left:"0px"}}
              >{USER_COLUMNS.status}</div>)
          },
          */
          customBodyRender: (value, tableMeta, updateValue) => {
            return this.accountStatusRender(value, tableMeta, updateValue);
          }
        }
      },
      {
        name: "name",
        label: USER_COLUMNS.name,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "campus",
        label: USER_COLUMNS.campus,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "role",
        label: USER_COLUMNS.role,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "email",
        label: USER_COLUMNS.email,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      },
      {
        name: "lastLogin",
        label: USER_COLUMNS.login,
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
        }
      }
    ];

    const options = {
      tableId: "webNavv-superAdmin-users-usersTable-1.0",
      filter: true,
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 30, 50, 100],
      selectableRowsHeader: false,
      selectableRows: 'single',
      search: true,
      print: false,
      filter: false,
      viewColumns: false,
      toolbar: {
        filterTable: "Filter Table",
      },
      textLabels: {
        toolbar: {
          downloadCsv: "Download",
        },
      },
      downloadOptions: {
        filename: 'Users.csv',
      },
      setRowProps: (row, dataIndex, rowIndex) => {
        if (!row[0]) {
          return {
            style: { backgroundColor: "red" }
          };
        }
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          data={data}
          setSelectedRows={setSelectedRows}
          edit={this.editUser}
          showDelete={this.handleDelete}
          showRestore={this.handleRestore}
        />
      )
    };

    return (
      <div>
        {!this.state.open ?
          (<div id="webNavv-superAdmin-users-1.0">
            <div
              id="webNavv-superAdmin-users-usersTable-title-1.0"
              className={classes.titleContainer}
            >
              <Icon style={{ width: 45, height: 35 }}>
                <img src={campusIcon} height={35} width={35} />
              </Icon>
              <span className={classes.titleText}>Users</span>
            </div>
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </MuiThemeProvider>
            <Tooltip title={"Add User"}>
              <FloatingActionButton
                backgroundColor={'#039be5'}
                //className="add-button"
                className={classes.addBtn}
                onClick={() =>
                  this.setState({
                    open: true
                  })
                }
              >
                <ContentAdd />
              </FloatingActionButton>
            </Tooltip>
            <MasterAlert
              snackStyle={{ marginTop: '5vh' }}
              anchor={{ vertical: 'top', horizontal: 'right' }}
              openSnack={this.state.openSnack}
              handleClose={this.handleCloseSnackbar}
              alertStyle={alertColor}
              variant={FILLED}
              type={this.state.snackType}
              message={this.state.snackMsg}
            />
            <ModalProp
              handleCloseModal={this.handleCloseModal}
              openModal={this.state.openModal}
              icon={<ReportProblemIcon className={classes.problemIcon} />}
              title={DIALOG_DELETE_TITLE}
              subtitle={!!this.state.userDelete ? DIALOG_DELETE_MSG : DIALOG_RESTORE_MSG}
              handleFirstOption={this.actionUser}
              handleSecondOption={this.handleCloseModal}
              button1Label={!!this.state.userDelete ? DELETE_BTN : RESTORE_BTN}
              button2Label={CANCEL_BTN}
            />
          </div>) : (
            <>
              <UserAddContainer backToUsers={this.backToUsers} createUserSuccess={(msg) => { this.onCreateUserSuccess(msg) }} editUserInfo={this.state.editUserInfo} />
            </>
          )}
        <AwesomeSpinner config={'default'} loading={this.state.showSpinner} navOpen={this.props.drawerOpen} />
      </div>
    );
  }
}

UserAdmin.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  user: state.superAdmin.user,
  users: state.superAdmin.allUsers,
  campusList: state.campus.campusList,
  superAdminList: state.superAdmin.superAdminList,
  roleList: state.superAdmin.roleList,
  aclList: state.superAdmin.aclList,
  drawerOpen: state.views.drawerOpen,
});

export default withStyles(styles)(
  connect(mapStateToProps, { fetchRoles, fetchAllACLS, listUsers, allUsers, getSuperAdmins, deleteUser, patchUser, createLog })(withRouter(UserAdmin))
);