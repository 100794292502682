export const styles = () => ({
    tableTitleContainer:{
        display:'flex',
        marginTop:'20px'
    },
    tableTitleIcon:{
        fill:'#2843A3',
        fontSize:'50px',
        opacity:0.7
    },
    tableTitleIconDiv:{
        marginTop:'1%'
    },
    tableTitleLbl:{
        color:'#2843A3',
        marginLeft:'1%',
    },
    jobLbl:{
        marginLeft:"50%",
        marginTop:"15px",
        fontSize:"14px",
        color:"gray"
    },
    textInput:{
        width: "100%",
        marginRight: "5%"
    },
    formControl:{
        marginLeft: '0px',
        marginTop:'5px'
    }
});